import React, { useContext } from 'react';

import { DateTime } from 'luxon';

import { UserDataContext } from 'lane-shared/contexts';
import { getCachedEssensysReservableValue } from 'lane-shared/helpers/integrations/Essensys';
import { DAY_IN_MINUTES } from 'lane-shared/helpers/timeRangeSlider';
import {
  useReservableInput,
  useResetDateRangeForDayPass,
} from 'lane-shared/hooks';
import { useWeekDayUnavailable } from 'lane-shared/hooks/features/reservable/useWeekDayUnavailable';
import {
  ReservableUiTypesEnum,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import TimeRangePicker from 'components/form/DatePickers/TimeRangePicker';
import TimeSlotsPicker from 'components/form/DatePickers/TimeSlotsPicker';
import { DatePickerButton, DateRangePickerButton } from 'design-system-web';

import { ReservableFeatureProps } from './ReservableInput';
import { useReservableWaitlist } from 'lane-shared/hooks/useReservableWaitlist';

export default function LegacyReservableInput({
  className,
  style,
  value,
  existingValue,
  content,
  disabled = false,
  forAdmin = false,
  onInput,
}: ReservableFeatureProps) {
  const { user } = useContext(UserDataContext);

  const userGroupRoleIds =
    user?.roles?.map(({ groupRole }) => groupRole._id) || [];

  const {
    reservableFeature,
    timeAvailabilityFeature,
    defaultRange,
    loading,
    timeZone,
    referenceDate,
    minDate,
    maxDate,
    maxSlots,
    setReferenceDate,
    minTime,
    maxTime,
    unavailableMonthlyRanges,
    unavailableDateRanges,
    handleChangeDateRange,
    reservableUnavailableDateRanges,
    monthlyTimeAvailabilityUnavailableDateRanges,
  } = useReservableInput({ value, content, forAdmin, onInput, existingValue });

  const isWaitlistFeatureEnabled = useReservableWaitlist();
  const isWaitlistEnabled =
    isWaitlistFeatureEnabled && (reservableFeature?.useWaitlist ?? false);

  const { unavailableWeekdays } = useWeekDayUnavailable({
    timeAvailabilityFeature,
    userGroupRoleIds,
  });

  useResetDateRangeForDayPass(
    reservableFeature?.unitType,
    value,
    handleChangeDateRange,
    defaultRange
  );

  const cachedEssensysReservableValue = getCachedEssensysReservableValue()
    ?.reservation;

  // depending on the unit types we will display different components here.
  switch (reservableFeature?.unitType) {
    case ReservableUnitTypesEnum.Minutes: {
      if (reservableFeature?.uiType === ReservableUiTypesEnum.TimeSlots) {
        return (
          <TimeSlotsPicker
            testId="TimeSlotPicker"
            className={className}
            loading={loading}
            style={style}
            value={value}
            existingValue={existingValue}
            timeZone={timeZone}
            disabled={disabled}
            onChange={handleChangeDateRange}
            referenceDate={referenceDate}
            onDayChange={value => setReferenceDate(value)}
            slotSize={reservableFeature.units}
            maxSlots={maxSlots}
            showDatePicker
            minDate={minDate}
            maxDate={maxDate}
            minTime={forAdmin ? 0 : minTime}
            maxTime={forAdmin ? DAY_IN_MINUTES : maxTime}
            timeAvailabilityFeature={timeAvailabilityFeature}
            unavailableDateRanges={unavailableMonthlyRanges}
            monthlyTimeAvailabilityUnavailableDateRanges={
              monthlyTimeAvailabilityUnavailableDateRanges
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
            hasCustomTimeSlots={reservableFeature.useCustomTimeSlots}
            hasWaitlistEnabled={isWaitlistEnabled}
            customTimeSlots={reservableFeature.customTimeSlots}
            disabledWeekDays={unavailableWeekdays}
            contentId={content?._id}
            maxQuantityPerSlotPerUser={
              reservableFeature.maxQuantityPerSlotPerUser
            }
          />
        );
      }
      return (
        <TimeRangePicker
          testId="TimeRangePicker"
          className={className}
          style={style}
          value={cachedEssensysReservableValue || value || defaultRange}
          existingValue={existingValue}
          disabled={disabled}
          loading={loading}
          minRangeSize={reservableFeature.units}
          maxRangeSize={reservableFeature.units * maxSlots}
          minTime={forAdmin ? 0 : minTime}
          maxTime={forAdmin ? DAY_IN_MINUTES : maxTime}
          minDate={minDate}
          maxDate={maxDate}
          slotSize={reservableFeature.units}
          timeZone={timeZone}
          showTimeSlider
          displayAllOptions={false}
          timeAvailabilityFeature={timeAvailabilityFeature}
          unavailableDateRanges={Array.from(
            new Set([
              ...unavailableMonthlyRanges,
              ...unavailableDateRanges,
              ...reservableUnavailableDateRanges,
            ])
          )}
          onChange={handleChangeDateRange}
          disabledWeekDays={unavailableWeekdays}
        />
      );
    }
    case ReservableUnitTypesEnum.Days: {
      const sharedProps = {
        className,
        style,
        timeZone,

        minDate,
        maxDate,
        minRangeSize: reservableFeature?.units,
        maxRangeSize: reservableFeature?.units * maxSlots,
        unavailableDateRanges: unavailableMonthlyRanges,
        disabledWeekDays: unavailableWeekdays,
        onFocusChange: (date: any) => setReferenceDate(date),
      };
      return maxSlots === 1 ? (
        <DatePickerButton
          {...sharedProps}
          onChange={(date: any) =>
            handleChangeDateRange({
              startDate: DateTime.fromJSDate(date)
                .setZone(timeZone)
                .startOf('day')
                .toJSDate(),
              endDate: DateTime.fromJSDate(date)
                .setZone(timeZone)
                .endOf('day')
                .toJSDate(),
            })
          }
          value={value?.startDate || defaultRange.startDate}
        />
      ) : (
        <DateRangePickerButton
          {...sharedProps}
          onChange={(date: any) => handleChangeDateRange(date)}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
          startDate={value?.startDate || defaultRange.startDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null | undefined' is not assignable t... Remove this comment to see the full error message
          endDate={value?.endDate || defaultRange.endDate}
          existingValue={existingValue}
          longFormat
        />
      );
    }
    case ReservableUnitTypesEnum.Weeks:
    case ReservableUnitTypesEnum.Months:
    default:
      // todo: not supported yet.
      return null;
  }
}

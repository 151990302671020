import React, { useEffect } from 'react';

import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import styles from '../styles.scss';
import { WizardStepOneParams } from '../WizardStepOne';

export function RiseMigration({
  currentValue,
  onChangeDataSourceLocation,
  onAddDataSourceLocation,
}: WizardStepOneParams) {
  const { t } = useTranslation();
  const { sourceLocations } = currentValue;

  // Only allow for one building to be specified
  useEffect(() => {
    if (sourceLocations.length === 0) {
      onAddDataSourceLocation();
    }
  }, [sourceLocations, onAddDataSourceLocation]);

  return (
    <Input
      className={styles.SourceInput}
      value={sourceLocations?.[0]?.value}
      placeholder={t(
        'web.admin.channel.autoSetupWizard.riseMigrationPlaceholder'
      )}
      onChange={(value: string) =>
        onChangeDataSourceLocation({ index: 0, value })
      }
    />
  );
}

import React from 'react';

import cx from 'classnames';

import Toggle from 'components/form/Toggle';
import ValidationMessage from 'components/general/ValidationMessage';

import styleMap from '../../styleMap';

import styles from './ToggleInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function ToggleInputBlock({
  value,
  disabled = false,
  onInput = () => {},
  isValid = true,
  validationMessage = 'Required.',
  style,
  className,
}: any) {
  return (
    <div
      className={cx(styles.ToggleInputBlock, className)}
      style={styleMap(false, style)}
    >
      <Toggle value={value} onChange={onInput} disabled={disabled} />
      {!isValid && <ValidationMessage errors={[validationMessage]} />}
    </div>
  );
}

export default ToggleInputBlock;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import { InvoicesTable } from '../InvoicesTable';

export function Invoices() {
  const { t } = useTranslation();

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.charges.invoices')}
        headerLevel="h3"
      />
      <InvoicesTable />
    </AdminPage>
  );
}

import React, { useEffect, useState } from 'react';

import { CreatedBy, Loading } from 'components';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getClient } from 'lane-shared/apollo';
import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  mapDisabledPermissions,
} from 'lane-shared/helpers/getOrganizedPermissions';
import {
  useGroupRoleQuery,
  useChannelServiceRequestFeaturesContext,
} from 'lane-shared/hooks';
import { useFilteredPermissions } from '../hooks/useFilteredPermissions';
import ErrorMessage from 'components/general/ErrorMessage';
import ValidationMessage from 'components/general/ValidationMessage';
import {
  ConfigureMaxUsers,
  HeaderEditTeam,
  ListOfPermissions,
  TogglePublicTeam,
} from 'components/lane/Team/';
import AdminPage from 'components/layout/AdminPage';

import styles from './styles.scss';

const countQuery = gql`
  query CountGroupRoles($id: UUID!) {
    channelGroupRolesUserCount(_id: $id) {
      _id
      count
    }
  }
`;

type QueryParams = {
  groupRoleId: string;
};

function TeamEdit({ channel }: { channel: any }) {
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const { groupRoleId } = useParams<QueryParams>();
  const [groupRole, setGroupRole] = useState<any>(null);
  const [validation] = useState(null);
  const [error, setError] = useState<Error | null>(null);
  const [sending, setSending] = useState(false);
  const groupRoleResults = useGroupRoleQuery({ groupRoleId });
  const loading = groupRoleResults?.loading;
  const filteredPermissions = useFilteredPermissions();

  if (!serviceRequestFeatures.createServiceRequest) {
    mapDisabledPermissions(filteredPermissions, 'work orders');
  }

  const countsQueryResults = useQuery(countQuery, {
    client: getClient(),
    skip: !channel?._id,
    variables: {
      id: channel?._id,
    },
  });

  function updateGroupRole(props: any) {
    setGroupRole({
      ...groupRole,
      ...props,
    });
  }

  useEffect(() => {
    if (groupRoleResults?.groupRole) {
      setGroupRole(groupRoleResults.groupRole);
    }
  }, [groupRoleResults?.groupRole, channel?._id, groupRole?._id]);

  if (groupRoleResults?.error) {
    return <ErrorMessage error={groupRoleResults.error} />;
  }

  if (!groupRole) {
    return <Loading fullscreen />;
  }

  const isSystemCreatedTeam = [
    SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER,
    SPECIAL_GROUP_ROLES.ADMIN,
    SPECIAL_GROUP_ROLES.ACCESS_MANAGER,
    SPECIAL_GROUP_ROLES.COMPANY_ACCESS_ADMIN,
  ].includes(groupRole.name);

  const counts = countsQueryResults?.previousData?.channelGroupRolesUserCount?.find(
    (c: any) => c?._id === groupRole?._id
  );

  const errors =
    (validation as any)?.inner?.map((err: any) => err.message) || [];

  return (
    <AdminPage className={styles.TeamEdit}>
      <HeaderEditTeam
        groupRole={groupRole}
        sending={sending}
        loading={loading}
        channelSlug={channel?.slug}
        isAdminOrWorplaceMember={isSystemCreatedTeam}
        updateGroupRole={updateGroupRole}
        setSending={setSending}
        setError={setError}
        setGroupRole={setGroupRole}
        refetchGroupRole={() =>
          groupRoleResults?.refetch({
            variables: {
              id: groupRoleId,
            },
          })
        }
        counts={counts}
      />

      <ErrorMessage error={error} />
      <ValidationMessage errors={errors} />

      <TogglePublicTeam
        isPublic={groupRole.isPublic}
        updateGroupRole={updateGroupRole}
      />

      <ConfigureMaxUsers
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
        validation={validation}
      />

      <ListOfPermissions
        permissions={filteredPermissions}
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
        isAdminOrWorplaceMember={isSystemCreatedTeam}
      />

      <CreatedBy object={groupRole} forAdmin />
    </AdminPage>
  );
}

export default TeamEdit;

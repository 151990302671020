import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  CurrentChannelIntegration,
  HIDTemplatesMap,
  SavedCredentialTemplates,
  SelectorProperties,
} from '../types';
import { HIDCredTemplateStrategy } from '../../AccessManagementEditor/credentialTemplate';
import { useAccessManagementIntegration } from './useAccessManagmentIntegration';
import { getTemplateLabel } from '../helpers';

export function useConnectedCredentialTemplate(
  strategy: HIDCredTemplateStrategy,
  hidTemplatesMap: HIDTemplatesMap
): [
  SelectorProperties[],
  React.Dispatch<React.SetStateAction<SelectorProperties[]>>
] {
  const [
    connectedCredentialTemplateSelector,
    setCredentialTemplateSelector,
  ] = useState<SelectorProperties[]>([]);
  const currentChannelIntegration = useAccessManagementIntegration();

  useEffect(() => {
    if (!hidTemplatesMap) return;

    const savedConnectedCredentialTemplates: SavedCredentialTemplates[] =
      currentChannelIntegration?.channelIntegration?.settings
        ?.identityProviderSettings?.connectedCredentialTemplates || [];

    let savedTemplates: SelectorProperties[] = [];

    if (strategy === HIDCredTemplateStrategy.Both) {
      const transformedConnectedCredentialTemplates = prepareConnectedCredentialTemplates(
        savedConnectedCredentialTemplates,
        hidTemplatesMap
      );

      const transformedPartNumbers = prepareConnectedPartNumbers(
        currentChannelIntegration
      );

      savedTemplates = normalizeSavedConnectedTemplates(
        transformedConnectedCredentialTemplates,
        transformedPartNumbers
      );
    }

    if (strategy === HIDCredTemplateStrategy.HID30) {
      savedTemplates = prepareConnectedCredentialTemplates(
        savedConnectedCredentialTemplates,
        hidTemplatesMap
      );
    }

    if (strategy === HIDCredTemplateStrategy.HID22) {
      savedTemplates = prepareConnectedPartNumbers(currentChannelIntegration);
    }

    setCredentialTemplateSelector(() => {
      return savedTemplates;
    });
  }, [currentChannelIntegration, strategy, hidTemplatesMap]);

  return [connectedCredentialTemplateSelector, setCredentialTemplateSelector];
}

function prepareConnectedCredentialTemplates(
  savedConnectedCredentialTemplates: SavedCredentialTemplates[],
  hidTemplatesMap: HIDTemplatesMap
) {
  const connectedCredentialTemplatesMap: { [key: string]: string[] } = {};
  savedConnectedCredentialTemplates.forEach(template => {
    const templateName = getTemplateLabel(
      hidTemplatesMap,
      template.credentialTemplateId,
      HIDCredTemplateStrategy.HID30
    );
    if (templateName !== '') {
      if (connectedCredentialTemplatesMap[template.vtsOrganizationId]) {
        connectedCredentialTemplatesMap[template.vtsOrganizationId].push(
          templateName
        );
      } else {
        connectedCredentialTemplatesMap[template.vtsOrganizationId] = [
          templateName,
        ];
      }
    }
  });
  return Object.entries(connectedCredentialTemplatesMap).map(
    ([channel, connectedTemplates]) => {
      return {
        id: uuidv4(),
        channel,
        templates: connectedTemplates.map(template => {
          return {
            label: template,
            value: template,
          };
        }),
      };
    }
  ) as SelectorProperties[];
}

function prepareConnectedPartNumbers(
  currentChannelIntegration: CurrentChannelIntegration
) {
  const partNumbersMap: { [key: string]: string[] } = {};
  const partNumbers =
    currentChannelIntegration?.channelIntegration?.settings
      ?.identityProviderSettings?.connectedPartNumbers || [];
  partNumbers.forEach(
    ({
      vtsOrganizationId,
      partNumber,
    }: {
      vtsOrganizationId: string;
      partNumber: string;
    }) => {
      if (partNumbersMap[vtsOrganizationId]) {
        partNumbersMap[vtsOrganizationId].push(partNumber);
      } else {
        partNumbersMap[vtsOrganizationId] = [partNumber];
      }
    }
  );

  return Object.entries(partNumbersMap).map(([channel, partNumbers]) => {
    return {
      id: uuidv4(),
      channel,
      templates: partNumbers.map(partNumber => {
        return {
          label: partNumber,
          value: partNumber,
        };
      }),
    };
  }) as SelectorProperties[];
}

// return intersection of HID 3.0 and 2.2 templates/part numbers
function normalizeSavedConnectedTemplates(
  connectedCredentialTemplates: SelectorProperties[],
  connectedPartNumbers: SelectorProperties[]
) {
  // if the number of HID 3.0 templates is greater than the number of HID 2.2 templates
  if (connectedCredentialTemplates.length > connectedPartNumbers.length) {
    const normalizedConnectedCredentialTemplates: SelectorProperties[] = [
      ...connectedCredentialTemplates,
    ];

    // find the partNumber that is not in the normalizedCredentialTemplates
    // and push it to the normalizedCredentialTemplates
    connectedPartNumbers.forEach(partNumber => {
      const channelIndex = normalizedConnectedCredentialTemplates.findIndex(
        template => template.channel === partNumber.channel
      );
      if (channelIndex === -1) {
        normalizedConnectedCredentialTemplates.push(partNumber);
      }
    });

    return normalizedConnectedCredentialTemplates;
  }

  const normalizedConnectedCredentialTemplates: SelectorProperties[] = [
    ...connectedPartNumbers,
  ];

  connectedCredentialTemplates.forEach(connTemplate => {
    const channelIndex = normalizedConnectedCredentialTemplates.findIndex(
      template => template.channel === connTemplate.channel
    );
    if (channelIndex === -1) {
      normalizedConnectedCredentialTemplates.push(connTemplate);
    }
  });

  return normalizedConnectedCredentialTemplates;
}

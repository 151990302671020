import React from 'react';

import { useTranslation } from 'react-i18next';
import { L, M } from 'components/typography';
import { Line } from 'components';
import useUserLocale from 'hooks/useUserLocale';

import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';
import {
  Currency,
  currencyToPaymentCurrency,
  CreditBundle,
} from 'lane-shared/domains/billingPayments/types';

import styles from './styles.scss';

export function CreateCreditBundleSummary({
  creditBundle,
  currency,
}: {
  creditBundle: CreditBundle;
  currency: Currency;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const priceInput = {
    rate: String(creditBundle.rate || 0),
    tax: {
      value: String(creditBundle.taxValue || 0),
      type: creditBundle.taxType,
    },
  };
  const priceComponents = priceInputToComponents(priceInput);
  const paymentCurrency = currencyToPaymentCurrency(currency);
  const { rate, grossTax, netPrice, formatPrice } = getPricing({
    currency: paymentCurrency,
    locale,
    priceComponents,
  });

  return (
    <div className={styles.summaryGroup}>
      <L mb={5} bold className={styles.summaryTitle}>
        {t('abp.creditBundle.createCreditBundle.pricingSummary')}
      </L>
      <div className={styles.summaryBorder}>
        <div className={styles.summaryItem}>
          <M>{t('abp.creditBundle.createCreditBundle.rate')}</M>
          <M className={`${rate < 0 ?? styles.error}`}>
            {formatPrice(rate, 'narrowSymbol')}
          </M>
        </div>
        <div className={styles.summaryItem}>
          <M>{t('abp.creditBundle.createCreditBundle.tax')}</M>
          <M>{formatPrice(grossTax, 'narrowSymbol')}</M>
        </div>
        <Line />
        <div className={styles.totalItem}>
          <L bold>{t('abp.creditBundle.createCreditBundle.total')}</L>
          <L>
            <L className={styles.totalCurrency}>{paymentCurrency}</L>
            <L className={styles.totalAmount}>
              {formatPrice(netPrice, 'narrowSymbol')}
            </L>
          </L>
        </div>
      </div>
    </div>
  );
}

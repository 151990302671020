import React, { useState, useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/browser';

import { ChannelsContext, ThemeContext } from 'lane-shared/contexts';
import * as BuildingEnginesClassic from 'lane-shared/helpers/integrations/BuildingEnginesClassic';
import { getToken } from 'lane-shared/helpers/integrations/BuildingEnginesClassic/handleToken';

import ValidatedDropdown from 'components/form/ValidatedDropdown';
import ValidatedTextArea from 'components/form/ValidatedTextArea';

import history from '../../../helpers/history';
import Loading from '../../general/Loading';
import { Button, ErrorMessage } from '../../general/index';
import Login from './Login';

import styles from './RequestForm.scss';

function Form({ content }: any) {
  const theme = useContext(ThemeContext);
  const { refetchFocus } = useContext(ChannelsContext);
  const { t } = useTranslation();
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storedToken, setStoredToken] = useState<any | undefined>(undefined);

  const {
    workOrder,
    updateWorkOrder,
    setToken,
    requestTypes,
    spaces,
    validate,
    loading,
    floors,
  } = BuildingEnginesClassic.useWorkOrder({ content });

  const [error, setError] = useState(null);

  async function submit() {
    setHasAttemptedSubmit(true);

    if (!validate()) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const currentFloor = floors.find(({ suites = [] }) =>
        suites.some(({ id }: any) => id === workOrder.spaceId)
      );

      const currentIssueType = requestTypes.find(
        ({ value }) => value === workOrder.requestTypeId
      );

      await BuildingEnginesClassic.requestHelpers.submitWorkorder(
        content.integration.settings.baseUrl,
        storedToken.tenx,
        storedToken.user,
        content.integration.settings.buildingId,
        workOrder.spaceId,
        {
          id: currentFloor.id,
          name: currentFloor.name,
        },
        {
          id: currentIssueType.value,
          primary_type: currentIssueType.primary_type,
          name: currentIssueType.label,
        },
        workOrder.description
      );

      refetchFocus();

      setOrderSubmitted(true);

      await window.Alert.alert({
        title: t('Success!'),
        message: t('Your work order was successfully submitted'),
      });

      history.goBack();
    } catch (err) {
      setIsSubmitting(false);
      setOrderSubmitted(false);
      setError(err);
      Sentry.captureException(err);
    }
  }

  useEffect(() => {
    async function tokenHandler() {
      const token = await getToken();
      setToken(token);
      setStoredToken(token);
    }

    tokenHandler();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <h3 style={{ color: theme.primary }}>{t('Create Work Order')}</h3>
      <div className={styles.form}>
        <h2>{t('Enter Service Request Details')}:</h2>
        <ValidatedDropdown
          name="spaceId"
          validation={BuildingEnginesClassic.validationShape.spaceId}
          isPristine={!hasAttemptedSubmit}
          className={styles.dropdown}
          placeholder={t('Floor (Required)')}
          value={workOrder.spaceId}
          items={spaces}
          onValueChange={value => updateWorkOrder({ spaceId: value })}
        />
        <h2>{t('Request')}:</h2>
        <ValidatedDropdown
          name="requestTypeId"
          className={styles.dropdown}
          validation={BuildingEnginesClassic.validationShape.requestTypeId}
          isPristine={!hasAttemptedSubmit}
          placeholder={t('Service Request Type (Required)')}
          value={workOrder.requestTypeId}
          items={requestTypes}
          onValueChange={value => updateWorkOrder({ requestTypeId: value })}
        />
        <ValidatedTextArea
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ name: string; placeholder: string | undefi... Remove this comment to see the full error message
          name="description"
          placeholder={t('Description (Required)')}
          onChange={value => updateWorkOrder({ description: value })}
          minRows={5}
          value={workOrder.description}
          className={styles.textArea}
          validation={BuildingEnginesClassic.validationShape.description}
          isPristine={!hasAttemptedSubmit}
        />
        <Button
          className={styles.button}
          onClick={submit}
          loading={isSubmitting}
          disabled={isSubmitting || orderSubmitted}
        >
          {t('Submit')}
        </Button>
      </div>
    </>
  );
}

export default function RequestForm({ content }: any) {
  return (
    <Login content={content}>
      <div className={styles.container}>
        <Form content={content} />
      </div>
    </Login>
  );
}

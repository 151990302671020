import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Switch, FormControlLabel } from '@material-ui/core';

import { NotFoundError } from 'activate-errors';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { useEssensysUserMetadata } from 'lane-shared/hooks';
import useInvoice from 'lane-shared/hooks/useInvoice';

import { Input } from 'components/form';
import { Text } from 'components/typography';

import Button from '../../general/Button';
import ErrorMessage from '../../general/ErrorMessage';
import EssensysPaymentMethodSelect from '../../payments/EssensysPaymentMethodSelect';
import PlacePayPaymentModal from '../../payments/PlacePayPaymentModal';

import styles from './Invoice.scss';

export default function Invoice({ invoiceId }: any) {
  const { t } = useTranslation();

  const {
    invoice,
    loading,
    submitting,
    pdfUrl,
    formattedStartDate,
    formattedEndDate,
    isPlacePayOpen,
    isPayDisabled,
    placePayRef,
    essensysPaymentSettings,
    selectedCard,
    selectedPaymentAccount,
    partialAmount,
    isPartialAmount,
    paymentAccounts,

    // Errors
    error,
    paymentError,
    partialPaymentError,
    essensysPaymentSettingsError,
    operatePaymentAccountError,

    // functions
    submitPayment,
    formatter,
    setPartialAmount,
    setIsPartialAmount,
    setIsPlacePayOpen,
    setSelectedPaymentAccount,
    setSelectedCard,
    setPartialPaymentError,
  } = useInvoice({
    invoiceId,
    onPaymentSuccess: () =>
      window.Toast.show(<p>{t('Payment successful!')}</p>),
    onPaymentFailed: err =>
      window.Alert.alert({
        title: t('Payment failed!'),
        message: t('Please see the error below and try again.'),
        error: err,
      }),
  });

  const { isEssensysAdmin } = useEssensysUserMetadata();

  const placePayAmount = isPartialAmount
    ? parseFloat(partialAmount || '0')
    : invoice.outstanding;

  if (error && (error as any).graphQLErrors) {
    if (
      (error as any).graphQLErrors?.some(
        (err: any) =>
          err.extensions?.exception?.code === NotFoundError.errorName
      )
    ) {
      return <ErrorMessage error={error} />;
    }
  }

  return (
    <div className={styles.container}>
      <h3>Invoice</h3>
      <ErrorMessage error={error} />
      <ErrorMessage error={paymentError} />
      <ErrorMessage error={operatePaymentAccountError} />
      <div className={styles.line} />
      <div className={styles.innerContainer}>
        <div className={cx(styles.column, styles.receipt)}>
          <div className={styles.row}>
            <p className={styles.left}>{t('Invoice Number')}</p>
            <p className={styles.right}>{invoice.number}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{t('Issued Date')}</p>
            <p className={styles.right}>{formattedStartDate}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{t('Due Date')}</p>
            <p className={styles.right}>{formattedEndDate}</p>
          </div>
          {invoice.bfwd ? (
            <div className={styles.row}>
              <p className={styles.left}>{t('Balance Forward')}</p>
              <p className={styles.right}>{formatter(invoice.bfwd)}</p>
            </div>
          ) : null}
          <div className={styles.row}>
            <p className={styles.left}>{t('Total')}</p>
            <p className={styles.right}>{formatter(invoice.gross)}</p>
          </div>
          <div className={styles.row}>
            <p className={styles.left}>{t('Outstanding')}</p>
            <p className={styles.right}>{formatter(invoice.outstanding)}</p>
          </div>
          {!invoice?.paid && (
            <>
              {!loading ? (
                <EssensysPaymentMethodSelect
                  forInvoice
                  essensysInfo={essensysPaymentSettings}
                  essensysError={essensysPaymentSettingsError}
                  // @ts-expect-error ts-migrate(2739) FIXME: Type '{ total: number; }' is missing the following... Remove this comment to see the full error message
                  paymentDetails={{
                    total: invoice?.gross || 0,
                  }}
                  selectedCard={selectedCard}
                  paymentAccounts={paymentAccounts}
                  selectedPaymentAccount={selectedPaymentAccount}
                  onPaymentAccountSelected={paymentAccount =>
                    setSelectedPaymentAccount(paymentAccount)
                  }
                  onCardSelected={card => setSelectedCard(card)}
                  isEssensysAdmin={isEssensysAdmin}
                />
              ) : null}
              <FormControlLabel
                control={
                  <Switch
                    checked={!isPartialAmount}
                    onChange={() => setIsPartialAmount(prev => !prev)}
                    color="primary"
                  />
                }
                label={<Text>{t('Pay full amount outstanding')}</Text>}
              />
              {isPartialAmount && (
                <Input
                    className={styles.amountInput}
                    icon="DollarSign"
                    value={partialAmount}
                    placeholder={t('Enter payment amount')}
                    onChange={value => {
                      setPartialPaymentError(null);
                      setPartialAmount(value);
                    }}
                    error={
                      partialPaymentError ? [partialPaymentError.message] : []
                    }
                    type="number"
                />
              )}
            </>
          )}
          <div className={cx(styles.row, styles.buttonContainer)}>
            <Button
              onClick={submitPayment}
              loading={loading || submitting}
              className={cx(
                styles.button,
                isPayDisabled ? styles.grayButton : styles.blackButton
              )}
              disabled={isPayDisabled}
            >
              {t(invoice.paid ? 'Paid' : 'Pay')}
            </Button>
            {pdfUrl && (
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  loading={loading}
                  className={styles.button}
                  disabled={loading}
                >
                  {t('Download Invoice')}
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={selectedPaymentAccount?._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        payeeId={essensysPaymentSettings?.orgpayeeid}
        amount={placePayAmount * CURRENCY_CONVERSION}
        description={invoice.description}
        onClose={() => {
          setIsPlacePayOpen(false);
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
          setIsPlacePayOpen(false);
        }}
      />
    </div>
  );
}

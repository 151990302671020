import React, { useContext } from 'react';

import { ContentPage, ErrorPage, Loading } from 'components';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { useContentQuery } from 'lane-shared/hooks';

export default function Profile({ channel }: any) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const contentId = channel?.profile?.content?._id;

  const {
    content,
    error,
    getContentInteractions: refetchInteractions,
  } = useContentQuery({
    forPublic: !user,
    includeInteractions: true,
    contentId,
  });

  if (error && !contentId) {
    return <ErrorPage error={error} />;
  }

  // Profile not setup
  if (!contentId) {
    return (
      <ErrorPage error={new Error(t('This profile hasn’t been setup yet.'))} />
    );
  }

  if (!content) {
    return <Loading />;
  }

  return (
    <ContentPage
      content={content}
      onInteractionCreated={() => refetchInteractions()}
    />
  );
}

export const editEvents = {
  onMouseOut: 'onBlockMouseOut',
  onMouseOver: 'onBlockMouseOver',
  onClick: 'onBlockClick',
  onDrag: 'onBlockDrag',
  onDragStart: 'onBlockDragStart',
  onDragEnter: 'onBlockDragEnter',
  onDragOver: 'onBlockDragOver',
  onDragLeave: 'onBlockDragLeave',
  onDrop: 'onBlockDrop',
};

import { useMutation } from '@apollo/client';

import { sendMagicLink as sendMagicLinkMutation } from 'lane-shared/graphql/user';

type Props = {
  onSuccess: () => void;
  onError: () => void;
};

function useMagicLinkSignIn({ onSuccess, onError }: Props) {
  const [sendMagicLink, { loading }] = useMutation(sendMagicLinkMutation, {
    onCompleted: () => {
      onSuccess();
    },
    onError,
  });

  async function handleSendMagicLink(email: string) {
    await sendMagicLink({
      variables: {
        email,
      },
    });
  }

  return {
    sendMagicLink: handleSendMagicLink,
    isLoading: loading,
  };
}

export default useMagicLinkSignIn;

import React from 'react';
import { Button, Icon, P } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import styles from './NoItemsWithNewItemCta.scss';

interface Props {
  iconName: string;
  titleText: string;
  subtitleText: string;
  buttonCtaText: string;
  buttonCtaOnClick: () => void;
}

export function NoItemsWithNewItemCta({
  iconName,
  titleText,
  subtitleText,
  buttonCtaText,
  buttonCtaOnClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.noBundlesContainer}>
      <div className={styles.iconCircleBackground}>
        <Icon name={iconName} size="large" testId="noItemsIcon" />
      </div>
      <P level="h4">{t(titleText)}</P>
      <P level="p">{t(subtitleText)}</P>
      <Button size="large" onClick={buttonCtaOnClick}>
        {t(buttonCtaText)}
      </Button>
    </div>
  );
}

import { floorErrorCodeTypes } from 'activate-errors';

import { Item } from 'components/form/Dropdown/Dropdown';

// TODO: Maybe use this instead of redeclaring input locally?
// import { FloorInput } from 'graphql-resolver-contracts';

export type FloorInputFields = {
  index?: number;
  name?: string;
  units?: Item<string>[];
};

export type FloorInputFieldKey = keyof FloorInputFields;

type ServerErrorCodeToFieldMappingType = {
  [key: string]: FloorInputFieldKey;
};

export const ServerErrorCodeToFieldMapping: ServerErrorCodeToFieldMappingType = {
  [floorErrorCodeTypes.floorNameAlreadyExistsError.code]: 'name',
  [floorErrorCodeTypes.floorNameMinimumCharacterError.code]: 'name',
};

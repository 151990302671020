import React, { useState, useMemo } from 'react';

import { Button, Input, MultiselectField } from 'components';
import { useTranslation } from 'react-i18next';
import { object, string, ValidationError } from 'yup';

import { useMutation } from '@apollo/client';

import { floorErrorCodeTypes } from 'activate-errors';
import {
  GetFloorQueryResponse,
  updateFloor,
  UpdateFloorMutationResponse,
} from 'lane-shared/graphql/floors';
import {
  getAggregatedValidationMessage,
  getErrorCodes,
  getErrorMessagesFromCodes,
} from 'lane-shared/helpers';

// import { dates } from 'lane-shared/helpers/constants';
// import { dateFormatter } from 'lane-shared/helpers/formatters';
import { PageNavigationAlert } from 'components/general';
import { H4 } from 'components/typography';

import { useGetUnitsFieldOptions } from '../hooks';
import { FloorInputFields } from '../types';

import styles from './styles.scss';
import { ChannelExperienceTypeEnum } from 'lane-shared/types/ChannelType';
import { getSharedTranslationKeys } from '../utils';

type Props = {
  channelId: string;
  channelExperienceType: ChannelExperienceTypeEnum | null | undefined;
  floor?: GetFloorQueryResponse['floor'];
  setEdit: (value: boolean) => void;
  setIsFloorUpdated: (value: boolean) => void;
};

export const EditFloor = ({
  channelId,
  channelExperienceType,
  floor,
  setEdit,
  setIsFloorUpdated,
}: Props) => {
  const { t } = useTranslation();
  const { createPage } = getSharedTranslationKeys(channelExperienceType);
  const [editFloor, setEditFloor] = useState<FloorInputFields>({
    index: floor?.index,
    name: floor?.name,
    units: floor?.units.map((unit: any) => ({
      label: unit.name,
      value: unit.id,
    })),
  });
  const [
    updateFloorFields,
    setUpdateFloorFields,
  ] = useState<FloorInputFields>();
  const [
    validationError,
    setValidationError,
  ] = useState<ValidationError | null>(null);

  const [updateFloorMutation] = useMutation<UpdateFloorMutationResponse>(
    updateFloor
  );

  const { options } = useGetUnitsFieldOptions(channelId);

  const floorInputLabel = t('web.admin.channel.floors.create.form.floor.label');

  const floorMinValidationMessage = t(
    'web.admin.channel.floors.create.form.floor.validation.min'
  );

  const isPristine = useMemo(() => {
    return (
      updateFloorFields === undefined ||
      (updateFloorFields.index === undefined &&
        updateFloorFields.name?.length === 0)
    );
  }, [updateFloorFields]);

  const getValidationMessage = (path: string) => {
    const validationMessage = getAggregatedValidationMessage(
      validationError,
      path
    );
    if (!validationMessage) {
      return null;
    }

    return [validationMessage];
  };

  const floorValidator = object().shape({
    name: string()
      .label(floorInputLabel)
      .trim()
      .required()
      .min(1, floorMinValidationMessage),
  });

  const handleOnChange = (value: any, fieldName: string) => {
    setEditFloor((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));

    // To track just changed values
    setUpdateFloorFields((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleOnUpdate = async () => {
    try {
      floorValidator.validateSync(
        { name: editFloor.name },
        { abortEarly: false }
      );

      setValidationError(null);
      await updateFloorMutation({
        variables: {
          propertyId: channelId,
          floorId: floor?.id,
          floor: {
            ...editFloor,
            index: Number(editFloor.index),
            units: editFloor.units?.map(unit => unit.value),
          },
        },
      });

      window.Toast.show(
        t('web.pages.portal.admin.floors.details.edit.successToast')
      );

      setEdit(false);
      setIsFloorUpdated(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setValidationError(error);
      } else {
        const codes = getErrorCodes(error);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          floorErrorCodeTypes,
          t('shared.floors.errors.generic')
        );

        window.Toast.show(errorMessage);
      }
    }
  };

  const handleOnCancel = async () => {
    setEdit(false);
  };

  const editCTAs = () => {
    return (
      <>
        <Button
          testId="saveFloorButton"
          variant="contained"
          disabled={isPristine}
          onClick={handleOnUpdate}
        >
          {t('web.pages.portal.admin.floors.details.edit.saveButton')}
        </Button>
        <Button
          testId="cancelButton"
          variant="outlined"
          onClick={handleOnCancel}
        >
          {t('web.pages.portal.admin.floors.details.edit.cancelButton')}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.floorEdit}>
      <PageNavigationAlert when={!isPristine} testId="pageNavigationAlert" />
      <div className={styles.header}>
        <H4>{t('web.pages.portal.admin.floors.details.header')}</H4>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.leftCard}>
          <div>
            <Input
              fieldName="index"
              label={t('web.admin.channel.floors.create.form.floor.label')}
              fixedLabel
              testId="indexInput"
              value={editFloor.index}
              error={getValidationMessage('index')}
              onChange={value => handleOnChange(value, 'index')}
              placeholder={t(
                'web.admin.channel.floors.create.form.floor.placeholder'
              )}
              isRequired
            />
          </div>
          <div>
            <Input
              fieldName="name"
              label={t('web.pages.portal.admin.floors.details.displayName')}
              fixedLabel
              testId="nameInput"
              value={editFloor.name}
              error={getValidationMessage('name')}
              onChange={value => handleOnChange(value, 'name')}
              placeholder={t(
                'web.admin.channel.floors.create.form.name.placeholder'
              )}
            />
          </div>
          <div>
            <MultiselectField
              label={t(createPage.form.space.label)}
              fixedLabel
              isFullWidth
              isSearchable
              testId="unitsDropdown"
              items={options}
              onChange={value => handleOnChange(value, 'units')}
              value={editFloor.units}
              placeholder={t(createPage.form.space.placeholder)}
              noOptionsMessage={t(createPage.form.space.notFound)}
              doTranslation={false}
            />
          </div>
        </div>
        {/* <div className={styles.rightCard}>
          <Label className={styles.label}>
            {t('web.pages.portal.admin.floors.details.dateCreated')}
          </Label>
          <M>
            {dateFormatter(
              floor?.createdAtDatetime!,
              dates.LONG_MONTH_DATE_YEAR
            )}
          </M>
        </div> */}
      </div>
      <div className={styles.buttonContainer}>{editCTAs()}</div>
    </div>
  );
};

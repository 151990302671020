import React, { useState } from 'react';

import { useMutation } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import {
  updateUserGrantSuper,
  updateUserRevokeSuper,
} from 'lane-shared/graphql/user';
import { UserLoginStatusEnum } from 'lane-shared/types/UserLogin';

import Button from 'components/general/Button';
import { Modal } from 'components/lds';

import { PortalManagementUserEditType } from '../types';

import styles from '../styles.scss';

enum SuperUserActionLabel {
  Grant = 'Grant',
  Remove = 'Remove',
}

export const SuperUserButton = ({
  user,
  parentLoading,
}: {
  user: PortalManagementUserEditType;
  parentLoading: boolean;
}): JSX.Element | null => {
  const [loading, setLoading] = useState(parentLoading);
  const [isSuperOpen, setIsSuperOpen] = useState(false);
  const [requirementsWarnings, setRequirementsWarnings] = useState<
    JSX.Element[]
  >([]);

  const [mutateUser, { loading: mutationLoading }] = useMutation(
    user.isSuperUser ? updateUserRevokeSuper : updateUserGrantSuper,
    {
      client: getAdminClient() as any, // TODO: apollo typings
      refetchQueries: ['getUser'],
      variables: {
        userId: user._id,
      },
      onCompleted: () =>
        window.Toast.show(
          `Super User has been ${
            actionLabel === SuperUserActionLabel.Grant
              ? 'granted to'
              : 'removed from'
          } ${user.profile.name}`
        ),
      onError: error =>
        window.Alert.alert({
          title: 'Failed to update user',
          error,
        }),
    }
  );

  const actionLabel = !user.isSuperUser
    ? SuperUserActionLabel.Grant
    : SuperUserActionLabel.Remove;
  const buttonLabel = `${actionLabel} Super User Access`;

  return (
    <>
      <Button
        loading={loading}
        onClick={() => {
          setIsSuperOpen(true);
          setRequirementsWarnings(checkRequirementsWarnings(user));
        }}
      >
        {buttonLabel}
      </Button>
      <Modal
        className={styles.window}
        isOpen={isSuperOpen}
        title="Super User Access"
        onClose={() => setIsSuperOpen(false)}
        actions={
          <>
            <Button loading={loading} onClick={() => setIsSuperOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={requirementsWarnings.length > 0}
              loading={loading}
              variant="contained"
              testId="superUserButtonConfirm"
              onClick={() => {
                setLoading(mutationLoading);
                mutateUser();
                setIsSuperOpen(false);
              }}
            >
              {buttonLabel}
            </Button>
          </>
        }
      >
        {modalCopy(user, actionLabel, requirementsWarnings)}
      </Modal>
    </>
  );
};

export const checkRequirementsWarnings = (
  user: PortalManagementUserEditType
): JSX.Element[] => {
  const warnings: JSX.Element[] = [];

  const primaryLogin = user.logins.find(({ isPrimary }) => isPrimary);
  const pendingLogins = user.logins.filter(
    ({ status }) => status !== UserLoginStatusEnum.Verified
  );

  const primaryLoginIsVTS = primaryLogin?.key.includes('@vts.com');
  if (!user.isSuperUser && !primaryLoginIsVTS) {
    warnings.push(vtsEmailWarning);
  }

  const superTagExists = primaryLogin?.key.includes('+super');
  if (!user.isSuperUser && !superTagExists) {
    warnings.push(superTagEmailWarning);
  }

  const loginsAreVerified = user.logins.length && pendingLogins.length === 0;
  if (!user.isSuperUser && !loginsAreVerified) {
    warnings.push(verifiedEmailWarning);
  }

  return warnings;
};

const grantCopy = () => (
  <span>
    <strong>Note:</strong> Enabling super user access will allow advanced
    settings and controls for this user.
    <br />
    <br />
  </span>
);

const revokeCopy = () => (
  <span>
    <strong>Note:</strong> Disabling super user access will remove advanced
    settings and controls for this user.
    <br />
    <br />
  </span>
);

export const superTagEmailWarning = (
  <li key="superTagEmailWarning">
    <span style={{ color: 'red' }}>
      The user must have the{' '}
      <strong>
        <kbd>+super</kbd>
      </strong>{' '}
      email tag in their primary login. Please create a new super user account
      for them.
    </span>
  </li>
);

export const verifiedEmailWarning = (
  <li key="verifiedEmailWarning">
    <span style={{ color: 'red' }}>
      The user must verify <strong>all</strong> logins. Please have the user
      verify their login.
    </span>
  </li>
);

export const vtsEmailWarning = (
  <li key="vtsEmailWarning">
    <span style={{ color: 'red' }}>
      The users primary email must be within the <kbd>@vts.com</kbd> domain.
    </span>
  </li>
);

export const confirmCopy = (
  user: PortalManagementUserEditType,
  actionLabel: string
) => (
  <span>
    Are you sure you want to {actionLabel.toLowerCase()} Super User permissions{' '}
    {actionLabel === SuperUserActionLabel.Grant ? 'to' : 'from'}{' '}
    <strong>{user.profile.email}</strong>?
    <br />
    <br />
  </span>
);

const requirementsCopy = (requirementsWarnings: JSX.Element[]) => (
  <>
    <span style={{ color: 'red' }}>
      Before continuing, the following requirements must be met:
    </span>
    <ul>{requirementsWarnings}</ul>
  </>
);

export const modalCopy = (
  user: PortalManagementUserEditType,
  actionLabel: string,
  requirementsWarnings: JSX.Element[]
): JSX.Element => (
  <span>
    {!requirementsWarnings.length
      ? confirmCopy(user, actionLabel)
      : requirementsCopy(requirementsWarnings)}
    {!user.isSuperUser ? grantCopy() : revokeCopy()}
  </span>
);

import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import updateUserContentInteraction from 'lane-shared/graphql/mutation/updateUserContentInteraction';
import { getInteractionsOnContent } from 'lane-shared/graphql/query';
import ShopifyBurstContextProvider, {
  BurstFlowType,
} from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import { PublicUserType } from 'lane-shared/hooks/usePublicUserQuery';
import { IWizardState } from 'lane-shared/hooks/useWizard';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { ContentType } from 'lane-shared/types/content/Content';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';
import {
  BurstDraftInteractionData,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import Button from 'components/general/Button';
import BurstAdminEditFlow from 'components/integrations/ShopifyBurst/components/BurstAdminEditFlow';
import BurstReadOnlyView from 'components/integrations/ShopifyBurst/components/ReviewAndSubmit/BurstReadOnlyView';
import exportBurstDataToCSV from 'components/integrations/ShopifyBurst/helpers/exportBurstDataToCSV';
import exportBurstRsvpsToCSV from 'components/integrations/ShopifyBurst/helpers/exportBurstRsvpsToCSV';
import Flex from 'components/layout/Flex';
import { H4 } from 'components/typography';

type Props = {
  interactionUser: PublicUserType;
  burstInteraction: {
    _id: LaneType.UUID;
    _created: string;
    _updated: string;
    _createdBy: {
      _id: LaneType.UUID;
    };
    _updatedBy: {
      _id: LaneType.UUID;
    };
    user: {
      _id: LaneType.UUID;
      name: string;
      profile: {
        _id: LaneType.UUID;
        _created: string;
        _updated: string;
        name: string;
        description: string;
        email: LaneType.Email;
        phone: LaneType.PhoneNumber;
        image: LaneType.UUID | null;
        wordmark: LaneType.UUID | null;
        color: LaneType.Color | null;
        isPublic: boolean;
        logo: LaneType.UUID | null;
        backgroundColor: LaneType.Color | null;
      };
    };
    channel: {
      _id: LaneType.UUID;
    };
    content: {
      _id: LaneType.UUID;
    };
    startDate: string;
    endDate: string;
    geo: GeoCoordinateType;
    data: { _ShopifyBurst: ShopifyBurstInteractionType };
    state: any;
    features: any;
    contentData: ContentType;
    version: number;
    status: string;
    lastStatus: string;
  };
};

const AUTO_SAVE_THROTTLE = 1000;

export default function BurstAdminUCIEditView({
  burstInteraction,
  interactionUser,
}: Props) {
  const { t } = useTranslation();
  const isSavingRef = useRef(false);
  const [isEditingBurst, setIsEditingBurst] = useState(false);

  const burstData = burstInteraction?.data?._ShopifyBurst;
  const rsvpContentId = burstData?.rsvpContentId;

  const { data: rsvpInteractionsData } = useQuery(getInteractionsOnContent, {
    skip: !rsvpContentId,
    variables: {
      contentId: rsvpContentId,
      pagination: {
        start: 0,
        perPage: 1000,
      },
    },
    fetchPolicy: 'no-cache',
  });

  async function onBurstEdited(data: BurstDraftInteractionData) {
    if (isSavingRef.current || !data) {
      return;
    }

    try {
      isSavingRef.current = true;

      await getClient().mutate({
        mutation: updateUserContentInteraction,
        refetchQueries: ['adminInteraction'],
        variables: {
          interaction: {
            _id: burstInteraction._id,
            data: {
              [`_${IntegrationProviderEnum.ShopifyBurst}`]: data,
            },
          },
        },
      });

      window.Toast.show('Auto-saved');
    } catch (error) {
      window.Alert.alert({
        title: t('Auto-save failed'),
        error,
      });
    } finally {
      isSavingRef.current = false;
    }
  }

  const debouncedOnBurstEdited = useDebouncedCallback(
    onBurstEdited,
    AUTO_SAVE_THROTTLE
  ).callback;

  async function onCompleteBurstEdit(
    _wizardState: IWizardState<BurstDraftInteractionData>
  ) {
    debouncedOnBurstEdited(_wizardState.data);
    setIsEditingBurst(false);
  }

  function exportBurstToCSVFile() {
    const rsvpInteractions =
      rsvpInteractionsData?.interactionsOnContent.items || [];

    exportBurstDataToCSV(burstData!, interactionUser);
    exportBurstRsvpsToCSV(burstData!, rsvpInteractions);
  }

  return (
    <ShopifyBurstContextProvider
      contentId={burstInteraction.contentData._id}
      burstFlowType={BurstFlowType.AdminEdit}
    >
      <Flex direction="row">
        <Flex>
          <H4 mt={2}>Burst Details</H4>
        </Flex>
        <Flex mb={2} style={{ marginLeft: 'auto' }}>
          {isEditingBurst ? (
            <Button onClick={() => setIsEditingBurst(false)}>
              {t('End Edit')}
            </Button>
          ) : (
            <Button onClick={() => setIsEditingBurst(true)}>
              {t('Edit (Auto-Save)')}
            </Button>
          )}
        </Flex>
      </Flex>
      {isEditingBurst ? (
        <BurstAdminEditFlow
          burstContent={burstInteraction.contentData}
          burstData={burstData!}
          onDataUpdated={debouncedOnBurstEdited}
          onComplete={onCompleteBurstEdit}
        />
      ) : (
        <>
          <BurstReadOnlyView
            burstData={burstData!}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id: string | undefined; }' is not assigna... Remove this comment to see the full error message
            rsvpContent={{ _id: rsvpContentId }}
            onDataUpdated={onBurstEdited}
            isAdminView
          />
          <Flex direction="column">
            <Button onClick={exportBurstToCSVFile}>
              {t('Export Burst As CSV')}
            </Button>
          </Flex>
        </>
      )}
    </ShopifyBurstContextProvider>
  );
}

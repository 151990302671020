import { createContext } from 'react';

import { ChannelType } from '../../types/ChannelType';
import { OnboardingContextProvider } from './OnboardingContextProvider';
import { useOnboardingContext } from './useOnboardingContext';

export type OnboardingData = {
  fullName: string;
  marketingOptIn: boolean;
  building: ChannelType;
  company?: ChannelType;
};

export type OnboardingProfile = {
  fullName: string | undefined;
  isMarketingOptInSelected: boolean;
};

export enum OnboardingState {
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
}

export type OnboardingContextType = {
  data: {
    profile: OnboardingProfile;
    building?: ChannelType;
    company?: ChannelType;
  };
  errors: {
    completionError: string | undefined;
  };
  onboardingState: OnboardingState;
  handlers: {
    updateProfile: ({
      fullName,
      marketingOptIn,
    }: {
      fullName: string;
      marketingOptIn: boolean;
    }) => void;
    completeOnboarding: (data: OnboardingData) => Promise<void>;
    onBuildingOrCompanySelectionChange: () => void;
  };
};

export const OnboardingContext = createContext<
  OnboardingContextType | undefined
>(undefined);

export { OnboardingContextProvider, useOnboardingContext };

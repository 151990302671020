import { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';

import {
  listPropertyFloors,
  ListPropertyFloorsQueryResponse,
} from 'lane-shared/graphql/floors';

import { Item } from 'components/form/Dropdown/Dropdown';

export const useGetFloorFieldOptions = (propertyId: string) => {
  const [options, setOptions] = useState<Item<string>[]>([]);
  const { loading, error, data } = useQuery<ListPropertyFloorsQueryResponse>(
    listPropertyFloors,
    {
      variables: {
        propertyId,
      },
    }
  );

  useEffect(() => {
    if (data?.property?.floors?.length) {
      const formattedFloors = data.property.floors.map((floor: any) => ({
        label: floor.name,
        value: floor.id,
      }));

      setOptions(formattedFloors);
    }
  }, [data]);

  return {
    loading,
    error,
    options,
  };
};

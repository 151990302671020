import React from 'react';

import cx from 'classnames';
import { Marker } from 'react-google-maps';

import LocationBaseType from 'lane-shared/properties/baseTypes/Location';
import { LocationType } from 'lane-shared/types/LocationType';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import Map from '../../../general/Map';
import styleMap from '../styleMap';

import styles from './MapBlock.scss';

type BlockProps = WebBlockProps & {
  title: string;
  marker: boolean;
  location: LocationType;
  zoom: number;
};

const options = {
  streetViewControl: false,
  scaleControl: false,
  zoomControl: false,
  panControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
};

export default function MapBlock({
  style,
  className,
  title,
  marker,
  location,
  zoom,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  const position = {
    lat: location ? location.latitude : LocationBaseType.default.latitude,
    lng: location ? location.longitude : LocationBaseType.default.longitude,
  };

  return (
    <div
      className={cx(styles.MapBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <Map center={position} zoom={zoom || 10} options={options}>
        {marker && <Marker position={position} title={title} />}
      </Map>
      <div className={styles.wrapper} />
    </div>
  );
}

import { getClient } from 'lane-shared/apollo';
import gql from 'graphql-tag';

import {
  ExternalPayerType,
  InvoiceeInfo,
} from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';

export async function getCustomerNameById(
  id: string,
  type: ExternalPayerType
): Promise<InvoiceeInfo> {
  const payer: InvoiceeInfo = {
    _id: id,
    name: '',
    type,
  };

  const uuid = convertToUUID(id);

  if (type === ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY) {
    const response = await getClient().query({
      query: gql`
        query Channel($id: UUID) {
          channel(_id: $id) {
            _id
            name
          }
        }
      `,
      variables: {
        id: uuid,
      },
    });

    payer.name = response?.data?.channel?.name;
  } else if (type === ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER) {
    const response = await getClient().query({
      query: gql`
        query User($id: UUID!) {
          user(_id: $id) {
            _id
            name
          }
        }
      `,
      variables: {
        id: uuid,
      },
    });

    payer.name = response?.data?.user?.name;
  }

  return payer;
}

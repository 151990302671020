import React from 'react';

import cx from 'classnames';

import { imageUrl } from 'lane-shared/helpers/formatters';
import { DocumentType } from 'lane-shared/types/DocumentType';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';

import styles from './ImageBlock.scss';

type BlockProps = WebBlockProps & {
  image: string | DocumentType;
  sizing: 'contain' | 'cover';
};

export default function ImageBlock({
  className,
  style,
  image,
  sizing = 'contain',
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  const imageStyle = {
    backgroundImage: `url(${imageUrl(image)})`,
    backgroundSize: sizing,
    margin: '0 auto',
    ...styleMap(false, style),
  };

  // This works differently on different platforms. But here on Web we will parse this Media prop
  return (
    <div
      className={cx(styles.ImageBlock, className)}
      style={imageStyle}
      {...props}
    />
  );
}

import React from 'react';

import {
  Charge,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { styles } from './ChargeSummaryPDFExport.styles';

import { View, Text, Svg, Line } from '@react-pdf/renderer';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';
import { getDraftTotalAmounts } from 'lane-shared/domains/billingPayments/helpers/getDraftTotalAmounts';

type Props = {
  charge: Charge;
  labels: { [key: string]: string };
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
};
export function ChargeSummaryPDFExport({
  charge,
  labels,
  currency,
  locale,
}: Props) {
  const twoDecimalsWithCurrencySymbol = currencyFormatter({
    currency,
    minimumFractionDigits: 2,
  });

  const { subTotal, tax, total } = getDraftTotalAmounts(
    charge.items,
    currency,
    locale
  );

  return (
    <View style={styles.ChargeSummary}>
      <Text style={styles.Heading}>{labels.summary}</Text>
      <View style={styles.ChargeTotalRow}>
        <Text>{labels.subtotal}</Text>
        <Text style={styles.ChargeTotalValue}>
          {twoDecimalsWithCurrencySymbol(subTotal)}
        </Text>
      </View>
      <View style={styles.ChargeTotalRow}>
        <Text>{labels.tax}</Text>
        <Text style={styles.ChargeTotalValue}>
          {twoDecimalsWithCurrencySymbol(tax)}
        </Text>
      </View>
      <Svg height="1" width="504" style={styles.Line}>
        <Line x1="1100" y1="0" x2="0" y2="0" strokeWidth={1} stroke="#ECEDEF" />
      </Svg>
      <View style={styles.ChargeTotalRow}>
        <Text>{labels.total}</Text>
        <Text style={styles.ChargeTotalValue}>
          {twoDecimalsWithCurrencySymbol(total)}
        </Text>
      </View>
    </View>
  );
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import { WizardStepProps } from 'lane-shared/hooks/useWizard';
import {
  BurstDraftInteractionData,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Button } from 'components/general';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { H5, M, XS } from 'components/typography';

import AddShopifolkModal from '../../components/AddShopifolkModal';
import BurstButtonGroup from '../../components/BurstButtonGroup';
import BurstCreateHeader from '../../components/BurstCreateHeader';
import Container from '../../components/Container';
import { useModalContext } from 'contexts/ModalContext';

import styles from './BurstGuests.scss';

export default function BurstGuests({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
}: WizardStepProps<BurstDraftInteractionData>) {
  const { data } = wizardState;
  const { t } = useTranslation();
  const { showModal, closeModal } = useModalContext();
  const { maxCapacity } = useShopifyBurstContext();

  const attendees = data.attendees || [];

  function updateGuestList(attendees: PublicUserType[]) {
    onDataUpdated({
      attendees,
    });

    closeModal();
  }

  function removeGuest(guest: PublicUserType) {
    onDataUpdated({
      attendees: attendees ? attendees.filter(g => g._id !== guest._id) : [],
    });
  }

  function openShopifolkModal() {
    showModal(
      <AddShopifolkModal
        modalTitle="Invite guests"
        actionButtonTitle="Add guests"
        maxCapacityAlertTitle="You have reached the limit of attendees for this Burst's location."
        maxCapacityToastTitle="You have reached the limit of {{maxCapacity}} attendees for this Burst's location."
        maxCapacity={maxCapacity}
        onClose={closeModal}
        updateGuestList={updateGuestList}
        attendees={attendees}
      />
    );
  }

  return (
    <>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={content}
        title={t(ShopifyBurstPageTitle.BurstGuests)}
      />
      <Container style={{ maxWidth: '50em', paddingBottom: '0' }}>
        <H5 mb={2}>{t`Who is invited to your burst?`}</H5>
        <M variant="secondary" mb={5}>
          {t`Note: all participants will receive an email notification once the burst has been reviewed by your M6+ approver and the Burst Agency. This process can take up to 8 business days.`}
        </M>
        <Flex justify="center" mb={5}>
          <Button onClick={openShopifolkModal}>{t('Add Shopifolk')}</Button>
        </Flex>
        <Flex
          direction="column"
          align="center"
          m={[5, 0]}
          gap={4}
          className={styles.guestList}
        >
          {(data.attendees || []).map(user => (
            <CircleListView
              key={user._id}
              image={imageUrl(user.profile.image)}
              logo={imageUrl(user.profile.logo)}
              name={getDisplayName(user)}
              className={styles.guest}
              onClick={() => removeGuest(user)}
              isCancellable
            >
              <H5 bold>{getDisplayName(user)}</H5>
              <XS variant="secondary">{user.profile.email}</XS>
            </CircleListView>
          ))}
        </Flex>

        <BurstButtonGroup
          buttons={[
            { title: 'Prev', onClick: goToPrevStep },
            { title: 'Next', onClick: goToNextStep },
          ]}
          disabled={attendees.length === 0}
        />
      </Container>
    </>
  );
}

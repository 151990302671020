import React, { useEffect, useMemo, useState, useContext } from 'react';

import { Icon } from 'design-system-web';
import { ErrorMessage, Input, Loading } from 'components';
import gql from 'graphql-tag';
import { DateTime, Duration } from 'luxon';
import { stringify } from 'query-string';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useQuery } from '@apollo/client';

import { getBatchPublicClient, getClient } from 'lane-shared/apollo';
import { appUrl, routes } from 'lane-shared/config';
import { AnalyticsContext } from 'lane-shared/contexts';
import { deleteUserGroupRole } from 'lane-shared/graphql/mutation';
import { queryChannelUsersByGroupRole } from 'lane-shared/graphql/query';
import { emitMemberDeletedFromGroupRole } from 'lane-shared/helpers/analytics/emitGroupRole';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { UserStatusEnum } from 'lane-shared/types/User';
import {
  UserLoginStatusEnum,
  UserLoginType,
} from 'lane-shared/types/UserLogin';

import { Button } from 'components/general';
import { Flex } from 'components/layout';
import { PaginatedTable, Row } from 'components/lds/table';
import { UrlSearchParamsWithPages } from 'components/lds/table/types';
import { M } from 'components/typography';

import downloadFile from 'helpers/downloadFile';
import useQueryString from 'hooks/useQueryString';

import styles from './TeamMembers.scss';

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

const sendCompleteSignupMutation = gql`
  mutation sendCompleteSignup($userGroupRoleId: UUID!) {
    sendCompleteSignup(userGroupRoleId: $userGroupRoleId)
  }
`;

type Props = {
  channelId: string;
  groupRole: {
    _id: string;
    name: string;
  };
};

const INITIAL_SEARCH_PARAMS = {
  page: 0,
  perPage: PER_PAGE,
  total: 0,
  sortKey: 'name',
  dir: 'asc',
};

export default function TeamMembers({ groupRole, channelId }: Props) {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [
    searchParams,
    setSearchParams,
  ] = useQueryString<UrlSearchParamsWithPages>(INITIAL_SEARCH_PARAMS);
  const { id: channelSlug } = useParams<{ id: string }>();
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);
  const [selectedRows, setSelectedRows] = useState(
    () => new Map<string, Row>()
  );
  const history = useHistory();
  const analytics = useContext(AnalyticsContext);

  const { data, error, refetch: refetchUsers } = useQuery(
    queryChannelUsersByGroupRole,
    {
      skip: !groupRole?._id,
      variables: {
        groupRoleId: groupRole?._id,
        search: {
          ...(searchParams.sortKey
            ? { sortBy: { key: searchParams.sortKey, dir: searchParams.dir } }
            : {}),
          ...(debouncedSearch
            ? {
                user: {
                  search: {
                    type: 'like',
                    value: search,
                  },
                },
              }
            : {}),
        },
        pagination: {
          start: searchParams.page * PER_PAGE,
          perPage: PER_PAGE,
        },
      },
    }
  );

  useEffect(() => {
    setSearchParams({ page: 0 });
  }, [search, searchParams.sortKey, searchParams.dir]);

  const totalUsers = data?.channelUsersByGroupRole?.pageInfo?.total;

  useEffect(() => {
    if (totalUsers) {
      setSearchParams({ total: totalUsers });
    }
  }, [data?.channelUsersByGroupRole?.pageInfo]);

  useEffect(() => {
    if (!history?.location?.search?.includes('sortKey')) {
      setSearchParams(INITIAL_SEARCH_PARAMS);
    }
  }, [history?.location?.search]);

  const userGroupRoles = data?.channelUsersByGroupRole?.items || [];

  function userEmailRenderer(userLogin: UserLoginType) {
    if (!userLogin) {
      return;
    }
    const isVerified = userLogin.status === UserLoginStatusEnum.Verified;
    return (
      <div className={styles.userEmailCell}>
        {userLogin.key}{' '}
        {isVerified && (
          <Icon
            testId="verified-badge-icon"
            set={ICON_SET_FONTAWESOME}
            type="far"
            name="badge-check"
            className={styles.icon}
          />
        )}
      </div>
    );
  }

  function userActivityRenderer(user: any) {
    if (!user) {
      return;
    }
    const lastSeen: DateTime = DateTime.fromISO(user.lastSeen);
    const now: DateTime = DateTime.local();
    const timeDiff: Duration = now.diff(lastSeen, ['minute']);

    if (user.status === UserStatusEnum.Disabled) {
      return UserStatusEnum.Disabled;
    }

    if ((timeDiff as any).values.minutes <= 2) {
      return t(
        'web.admin.channel.teamManagement.team.view.tabMembers.table.activity.online'
      );
    }

    return fromNow(user.lastSeen, undefined, i18n.language);
  }

  const tableRows = useMemo<Row[]>(
    () =>
      userGroupRoles.map((userGroupRole: any) => ({
        id: userGroupRole._id,

        items: [
          {
            type: 'string',
            key: 'name',
            value: userGroupRole.user.profile.name,
            renderer: (name: string) => (
              <Link
                to={generatePath(routes.channelAdminTeamMember, {
                  id: channelSlug,
                  userId: userGroupRole.user._id,
                })}
              >
                {name}
              </Link>
            ),
          },
          {
            type: 'string',
            key: 'email',
            value: (userGroupRole.user.logins || []).find(
              (login: UserLoginType) => login.isPrimary
            ),
            renderer: userEmailRenderer,
          },
          {
            type: 'string',
            key: 'company',
            value: userGroupRole.relatedChannels
              .map((channel: any) => channel?.name ?? '')
              .join(', '),
          },
          {
            type: 'date',
            key: '_created',
            value: userGroupRole.user._created,
            renderer: simpleDate,
          },
          {
            type: 'string',
            key: 'status',
            value: userGroupRole.user.status,
          },
          {
            type: 'string',
            key: 'activity',
            value: userGroupRole.user,
            renderer: userActivityRenderer,
          },
        ],

        actions: [
          {
            iconName: 'times-circle',
            label: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.button.removeUser'
            ),
            onClick: () => removeUser(userGroupRole),
            isDelete: true,
          },
          {
            iconName: 'envelope',
            label: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.button.sendInvite'
            ),
            onClick: () => sendCompleteSignup(userGroupRole),
          },
        ],
      })),
    [userGroupRoles]
  );

  async function sendCompleteSignup(userGroupRole: any) {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendInvite.prompt.title',
          {
            profileName: userGroupRole.user.profile.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendInvite.prompt.message',
          {
            profileName: userGroupRole.user.profile.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      await getClient().mutate({
        mutation: sendCompleteSignupMutation,
        variables: {
          userGroupRoleId: userGroupRole._id,
        },
      });
      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabMembers.button.sendInvite.success.message'
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendInvite.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    setLoading(false);
  }

  async function bulkSendCompleteSignup() {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendBulkInvite.prompt.title',
          {
            totalUsers: selectedRows.size,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendBulkInvite.prompt.message',
          {
            totalUsers: selectedRows.size,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      await Promise.all(
        Array.from(selectedRows.keys()).map(id =>
          getBatchPublicClient().mutate({
            mutation: sendCompleteSignupMutation,
            variables: {
              userGroupRoleId: id,
            },
          })
        )
      );

      setSelectedRows(new Map());
      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabMembers.button.sendBulkInvite.success.message',
            {
              totalUsers: selectedRows.size,
            }
          )}
        </p>
      );
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.sendBulkInvite.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    await refetchUsers();
    setSelectedRows(new Map());
    setLoading(false);
  }

  async function removeUser(userGroupRole: any) {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.prompt.title',
          {
            userName: userGroupRole.user.profile.name,
            userGroup: groupRole.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.prompt.message',
          {
            userName: userGroupRole.user.profile.name,
            userGroup: groupRole.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      await getClient().mutate({
        refetchQueries: ['queryChannelUsersByGroupRole'],
        mutation: deleteUserGroupRole,
        variables: {
          id: userGroupRole._id,
        },
      });
      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.success.message',
            {
              userName: userGroupRole.user.profile.name,
            }
          )}
        </p>
      );
      emitMemberDeletedFromGroupRole({
        userId: userGroupRole.user._id,
        groupRoleId: groupRole?._id,
        groupRoleName: groupRole?.name,
        analytics,
      });
      setSelectedRows(new Map());
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeUser.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.common.errorMessage.body'
        ),
        error: err,
      });
    }

    setLoading(false);
  }

  async function bulkRemove() {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeBulkUser.prompt.title',
          {
            totalUsers: selectedRows.size,
            userGroup: groupRole.name,
          }
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeBulkUser.prompt.message',
          {
            totalUsers: selectedRows.size,
            userGroup: groupRole.name,
          }
        ),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    try {
      await Promise.all(
        Array.from(selectedRows.keys()).map(id => {
          getBatchPublicClient().mutate({
            mutation: deleteUserGroupRole,
            variables: {
              id,
            },
          });
          emitMemberDeletedFromGroupRole({
            userId: (selectedRows
              .get(id)
              ?.items.find(item => item.key === 'activity') as any)?.value?._id,
            groupRoleId: groupRole?._id,
            groupRoleName: groupRole?.name,
            analytics,
          });
        })
      );

      window.Toast.show(
        <p>
          {t(
            'web.admin.channel.teamManagement.team.view.tabMembers.button.removeBulkUser.success.message',
            {
              totalUsers: selectedRows.size,
            }
          )}
        </p>
      );
      await refetchUsers();
      setSelectedRows(new Map());
    } catch (err) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.removeBulkUser.errorMessage.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.common.errorMessage.body'
        ),
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  async function exportToCsv() {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.title'
        ),
        message: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.message',
          {
            totalUsers:
              totalUsers === selectedRows.size || selectedRows.size === 0
                ? t(
                    'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.all'
                  )
                : selectedRows.size,
            userOrUsers:
              selectedRows.size === 1 && totalUsers !== 1
                ? t(
                    'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.user'
                  )
                : t(
                    'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.users'
                  ),
            userGroup: groupRole.name,
          }
        ),
        cancelText: '',
        confirmText: t(
          'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv.prompt.confirmButton'
        ),
      });
      const url = `${appUrl}${generatePath(routes.exportGroupRoleMembers, {
        channelId,
        groupRoleId: groupRole._id,
      })}?${stringify({
        userGroupRoleIds:
          selectedRows.size > 0 ? [...selectedRows.keys()] : undefined,
      })}`;
      await downloadFile(url);

      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return (
    <div className={styles.TeamMembers}>
      <Flex justify="space-between">
        <Input
          className={styles.input}
          icon="search"
          value={search}
          onChange={search => {
            setSearch(search);
          }}
          placeholder="Search users…"
        />
        <Flex align="center" gap={4}>
          {selectedRows.size > 0 && (
            <>
              <M variant="secondary" className={styles.totalUsers}>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.selectedUsers.count',
                  {
                    totalUsers: selectedRows.size,
                  }
                )}
              </M>
              <Button onClick={bulkRemove}>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.button.removeBulkUser'
                )}
              </Button>
              <Button onClick={bulkSendCompleteSignup}>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.button.sendBulkInvite'
                )}
              </Button>
            </>
          )}
          <Button
            startIcon={
              <Icon set={ICON_SET_FONTAWESOME} type="far" name="file-export" />
            }
            loading={loading}
            onClick={exportToCsv}
            testId="buttonExportMembers"
          >
            {t(
              'web.admin.channel.teamManagement.team.view.tabMembers.button.exportToCsv'
            )}
          </Button>
        </Flex>
      </Flex>

      <ErrorMessage error={error} />

      <PaginatedTable
        columns={[
          {
            header: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.name'
            ),
            key: 'name',
          },
          {
            header: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.primaryEmail'
            ),
            key: 'email',
            disableSort: true,
          },
          {
            header: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.company'
            ),
            key: 'company',
            disableSort: true,
          },
          {
            header: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.joinedDate'
            ),
            key: '_created',
          },
          {
            header: t(
              'web.admin.channel.teamManagement.team.view.tabMembers.table.activity'
            ),
            key: 'activity',
          },
        ]}
        withActions
        rows={tableRows}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ columns: ({ header: string; key: string; }... Remove this comment to see the full error message
        selectedRows={selectedRows}
        onRowSelect={setSelectedRows}
      />

      {loading && <Loading className={styles.loading} />}
    </div>
  );
}

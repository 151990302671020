import React, { useEffect, useContext } from 'react';

import cx from 'classnames';
import { ControlMenu, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';

import { AlertType } from 'components/lds';
import { H3, M } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import { OnboardingCompanyInput } from './OnboardingCompanyInput';
import SignUpSubmitButton from './SignUpSubmitButton';

import styles from './Signup.scss';

export default function SignUpCompany({ onNext }: any) {
  const simpleTrack = useSimpleTrack();
  const { locationHelper } = useLocation();
  const {
    translationKeys: { noRecognizedOptionLabel },
    isContinueAsGuestVisible,
  } = useContinueAsGuest();
  const { t } = useTranslation();

  const { whitelabel } = useContext(AppContext);
  const {
    signupError,
    name,
    parentCompanySearch,
    parentCompany,
    company,
    building,
    isLocked,
    companySearch,
    companyLocationsResult,
    companyParentsResult,
    updateSignUp,
  } = useSignUpContext();

  useEffect(() => {
    locationHelper.startWatching({
      showRequestModal: true,
      availability: LocationAvailabilityEnum.Foreground,
      disableLocationPrecision: whitelabel.disableLocationPrecision,
    });
  }, []);

  const handleCompanySearchTextChange = (companySearch: string) =>
    updateSignUp({ companySearch });

  const handleCompanySelectionChange = (
    company: ChannelType | null,
    building: ChannelType | null
  ) => updateSignUp({ company, building });

  const handleParentCompanySearchTextChange = (parentCompanySearch: string) =>
    updateSignUp({ parentCompanySearch });

  const handleParentCompanySelectionChange = (parentCompany: ChannelType) =>
    updateSignUp({ parentCompany });

  const handleSelectionReset = () =>
    updateSignUp({
      parentCompany: null,
      company: null,
      building: null,
    });

  return (
    <div className={cx(styles.formContents, styles.centeredVertically)}>
      <div style={{ width: '100%' }}>
        <H3 bold mb={4} className={welcomeFormStyles.formTitle}>
          {t(`Welcome {{- name}},`, { name })}
          <br />
          {t('join your community.')}
        </H3>
        <ErrorMessage
          color={
            companyLocationsResult.isSignUpBlocked
              ? AlertType.error
              : AlertType.warning
          }
          error={signupError}
          className={styles.errorMessage}
        />
        <br />
        <OnboardingCompanyInput
          data={{
            signupError,
            company,
            building,
            parentCompanySearch,
            companyParentsResult,
            companySearch,
            parentCompany,
            companyLocationsResult,
          }}
          config={{
            isLocked,
          }}
          handlers={{
            resetSelection: handleSelectionReset,
            onParentCompanySearchTextChange: handleParentCompanySearchTextChange,
            onParentCompanySelectionChange: handleParentCompanySelectionChange,
            onCompanySearchTextChange: handleCompanySearchTextChange,
            onCompanySelectionChange: handleCompanySelectionChange,
          }}
          ContinueButton={
            <SignUpSubmitButton
              disabled={Boolean(companyLocationsResult.isSignUpBlocked)}
              onNext={() => {
                simpleTrack('onboarding.companyAndBuilding.createAccount', {
                  buildingId: building?._id,
                  companyId: company?._id,
                });
                onNext();
              }}
            />
          }
        />
      </div>
      {!parentCompany && isContinueAsGuestVisible && (
        <ControlMenu className={cx(styles.menu, styles.footerContent)}>
          <Link
            to={routes.signUpBuilding}
            onClick={() => {
              simpleTrack('onboarding.companyAndBuilding.noCompany');
            }}
          >
            <M
              variant="secondary"
              size="large"
              bold
              className={styles.footerLink}
              data-test="buildingLink"
            >
              {t(noRecognizedOptionLabel)}
            </M>
          </Link>
        </ControlMenu>
      )}
    </div>
  );
}

import React from 'react';

import { ChannelIntegrationEditorProps } from './ChannelIntegrationEditorProps';
import { SSOIntegrationEditor } from './SSOIntegrationEditor/SSOIntegrationEditor';
import gql from 'graphql-tag';
import { SAMLServiceIDP } from 'lane-shared/helpers/integrations/SAML/definition';

const IDP = { idp: SAMLServiceIDP.Auth0 };

export const queryAllServiceProviders = gql`
  query samlServiceProviderDefinitionsByIdp($idp: String!) {
    samlServiceProviderDefinitionsByIdp(idp: $idp) {
      id
      name
      entityId
      customFields
      idp
    }
  }
`;

export const createNewProviderDefinition = gql`
  mutation createSAMLServiceProviderDefinition(
    $provider: SAMLServiceProviderDefinitionInput!
    $channelId: UUID!
  ) {
    createSAMLServiceProviderDefinition(
      SAMLServiceProviderDefinition: $provider
      channelId: $channelId
    ) {
      id
      name
      entityId
      customFields
      idp
    }
  }
`;
const QUERY_DATA_KEY = 'samlServiceProviderDefinitionsByIdp';
const CREATE_DATA_KEY = 'createSAMLServiceProviderDefinition';
const EDITOR = 'Auth0SAML';

export function Auth0SAMLIntegrationEditor({
  channelIntegration,
  onUpdateChannelIntegration,
}: ChannelIntegrationEditorProps) {
  return (
    <SSOIntegrationEditor
      channelIntegration={channelIntegration}
      onUpdateChannelIntegration={onUpdateChannelIntegration}
      integrationQuery={queryAllServiceProviders}
      integrationQueryVariable={IDP}
      createIntegrationQuery={createNewProviderDefinition}
      queryDataKey={QUERY_DATA_KEY}
      createDataKey={CREATE_DATA_KEY}
      defaultMutationValue={IDP}
      editorType={EDITOR}
    />
  );
}

import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { useSimpleTrack } from 'lane-shared/hooks/useSimpleTrack';

import { Button } from 'design-system-web';
import { HookFormInput } from 'components/reactHookForm';
import { H4, S } from 'components/typography';

import styles from './EmailForm.scss';

type FormData = {
  email: string;
};

type Props = {
  isLoading: boolean;
  email?: string;
  onSubmit: (email: string) => void;
};

export function EmailForm({ isLoading, email, onSubmit }: Props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      email,
    },
  });
  const simpleTrack = useSimpleTrack();
  const { t } = useTranslation();

  const { whitelabel } = useContext(AppContext);

  const TRANSLATION_KEYS = {
    title: 'shared.identifyUser.loginEmail.connectToWhitelabel',
    subtitle: 'shared.identifyUser.loginEmail.subtitle',
    emailLabel: 'shared.identifyUser.loginEmail.emailLabel',
    button: 'shared.identifyUser.loginEmail.button',
    requiredFieldError: 'shared.identifyUser.loginEmail.requiredField.error',
  };

  const handleFormSubmission = (
    { email }: FormData,
    method: 'bySubmit' | 'byTouch'
  ) => {
    const trimmedEmail = email.trim();

    simpleTrack('onboarding.identifier.continue', {
      method,
    });

    onSubmit(trimmedEmail);
  };

  const emailFieldError =
    errors.email?.type === 'required'
      ? [t(TRANSLATION_KEYS.requiredFieldError)]
      : null;

  return (
    <div className={styles.emailForm}>
      <H4 className={styles.title} mb={4}>
        {t(TRANSLATION_KEYS.title, { whiteLabelName: whitelabel.name })}
      </H4>
      <S>{t(TRANSLATION_KEYS.subtitle)}</S>
      <div className={styles.formContainer}>
        <form
          onSubmit={handleSubmit(data =>
            handleFormSubmission(data, 'bySubmit')
          )}
        >
          <HookFormInput
            isRequired
            control={control}
            type="email"
            name="email"
            disabled={Boolean(email)}
            label={TRANSLATION_KEYS.emailLabel}
            errorMessage={emailFieldError}
          />
          <Button
            loading={isLoading}
            fullWidth
            size="large"
            type="button"
            onClick={handleSubmit(data =>
              handleFormSubmission(data, 'byTouch')
            )}
          >
            {t(TRANSLATION_KEYS.button)}
          </Button>
        </form>
      </div>
    </div>
  );
}

import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import TemplateEdit from 'components/lane/TemplateEdit';

import ChannelTemplateLibrary from '../../templates/ChannelTemplateLibrary';

/**
 * @TODO: bring all the Template components in this folder
 * @TODO: verify all links are working
 */
export default function TemplateLibraryIndex() {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <ChannelTemplateLibrary />
      </Route>

      <Route exact path={`${path}/new`}>
        <TemplateEdit forCreate />
      </Route>

      <Route exact path={`${path}/:templateId/edit`}>
        <TemplateEdit forEdit />
      </Route>
    </>
  );
}

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { cleanupDraftBurst } from 'lane-shared/graphql/integrations/ShopifyBurst/burstDraft';
import validateShopifyIntegrationConfig from 'lane-shared/graphql/integrations/ShopifyBurst/validateShopifyIntegrationConfig';
import { getMessagesFromError } from 'lane-shared/helpers';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import {
  useWizard,
  WizardStepComponent,
  WizardStepProps,
} from 'lane-shared/hooks/useWizard';
import {
  BurstDraftInteractionData,
  CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { history } from 'lane-web/src/helpers';

import Button from 'components/general/Button';
import {
  shopifyBurstPages,
  shopifyDiyBurstPages,
} from 'components/integrations/ShopifyBurst/shopifyBurstCreatePages';
import { Flex } from 'components/layout';
import { Modal } from 'components/lds';
import { S } from 'components/typography';

import useQueryString from 'hooks/useQueryString';

import styles from './BurstCreateFlow.scss';

export type BurstCreatePageProps = WizardStepProps<BurstDraftInteractionData>;

type Props = {
  style?: React.CSSProperties;
  className?: string;
};

function handleAPIError(e: Error) {
  window.Toast.show(getMessagesFromError(e));
}

export default function BurstCreateFlow({ className, style }: Props) {
  const { t } = useTranslation();
  const [query, makeUrl] = useQueryString();
  const [pages, setPages] = useState<
    WizardStepComponent<BurstDraftInteractionData>[]
  >(shopifyBurstPages);

  const {
    burstWizardState,
    burstContent,
    updateWizardState,
    contentId,
    burstIntegrationSettings,
    diyQuestionsContent,
    specialRequestContent,
    questionnaireContent,
    burstNameContent,
    acknowledgementContent,
  } = useShopifyBurstContext();

  useEffect(() => {
    const shopifyConfigValidation = validateShopifyIntegrationConfig(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ShopifyBurst | null | undefined'... Remove this comment to see the full error message
      burstIntegrationSettings
    );

    if (shopifyConfigValidation.result === 'ok') {
      console.info('All Shopify Burst Integration configurations are ok');
    } else {
      console.warn(shopifyConfigValidation.errorMessage);
    }
  }, [burstIntegrationSettings]);

  const initialWizardState = {
    currentStepIndex: query?.step ? Number(query.step) - 1 : 0,
    data: {
      schemaVersion: CURRENT_SHOPIFY_BURST_INTERACTION_DATA_SCHEMA_VERSION,
    } as BurstDraftInteractionData,
  };

  const {
    goToPrevStep,
    goToNextStep,
    goToStep,
    goToStepByName,
    setData,
    wizardState,
    resetWizard,
  } = useWizard<BurstDraftInteractionData>({
    scenes: pages,
    initialWizardState,
    onDataUpdated: data =>
      updateWizardState({
        data,
        currentStepIndex: wizardState.currentStepIndex,
      }),
    keepInData: {
      specialRequestContent,
      questionnaireContent,
      diyQuestionsContent,
      burstNameContent,
      acknowledgementContent,
    },
  });

  const [isModalOpen, setIsModalOpen] = useState<Boolean>(false);

  const [draftLoaded, setDraftLoaded] = useState<Boolean>(false);

  const [latestUpdated, setLatestUpdated] = useState<Date | null>(null);

  useEffect(() => {
    if (draftLoaded) {
      return;
    }

    if (burstWizardState?.data) {
      setIsModalOpen(true);
      setDraftLoaded(true);
    }
  }, [burstWizardState]);

  useEffect(() => {
    if (!query.step) {
      makeUrl({ step: 1 });
    }
  }, []);

  useEffect(() => {
    setPages(
      wizardState.data.isDiyBurst === true
        ? shopifyDiyBurstPages
        : shopifyBurstPages
    );
  }, [wizardState.data.isDiyBurst]);

  useEffect(() => {
    makeUrl({ step: wizardState.currentStepIndex + 1 });
  }, [wizardState.currentStepIndex]);

  async function onNextStep() {
    if (!latestUpdated || latestUpdated < wizardState.data.updatedAt!) {
      window.Toast.show(t`Changes saved`);
      setLatestUpdated(wizardState.data.updatedAt!);
    }

    goToNextStep();
  }

  function closeModal() {
    setIsModalOpen(false);
    history.push(routes.home);
  }

  async function createNewHandler() {
    if (contentId && burstWizardState?.data) {
      try {
        await cleanupDraftBurst(contentId, burstWizardState?.data);
        setIsModalOpen(false);
        goToStep(0);
      } catch (e) {
        handleAPIError(e);
      }
    }
  }

  function keepBurstHandler() {
    if (burstWizardState) {
      setData(burstWizardState.data);
    }
    setIsModalOpen(false);
  }

  return (
    <div className={className} style={style}>
      <Flex direction="column">
        {pages.map(
          (Scene, index) =>
            index === wizardState.currentStepIndex && (
              <Scene
                key={Scene.name}
                index={index}
                totalSteps={pages.length}
                content={burstContent}
                wizardState={wizardState}
                goToPrevStep={goToPrevStep}
                goToNextStep={onNextStep}
                onDataUpdated={setData}
                goToStep={goToStep}
                goToStepByName={goToStepByName}
                resetWizard={resetWizard}
              />
            )
        )}
      </Flex>
      <Modal
        isOpen={isModalOpen.valueOf()}
        size="small"
        onClose={closeModal}
        title={t('Draft in progress')}
      >
        <Flex className={styles.burstDraftResumeModal} direction="column">
          <S variant="secondary" mb={4}>
            {t('You have unfinished changes.')}
          </S>
          <S variant="secondary" mb={4}>
            {t('Do you want to discard these changes and create a new Burst?')}
          </S>
          <Flex className={styles.actionButtonRow} justify="center" gap={4}>
            <Button
              className={styles.actionButton}
              size="medium"
              onClick={keepBurstHandler}
            >
              {t('Keep Burst')}
            </Button>
            <Button
              variant="contained"
              size="medium"
              onClick={createNewHandler}
            >
              {t('Create New')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </div>
  );
}

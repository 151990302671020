import React, { useState } from 'react';

import { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';

import SignupV2 from './SignupV2';
import WelcomeTemplate from './WelcomeTemplate';

export default function SignUpPage({ history }: any) {
  const [viewState, setViewState] = useState<string>(history.location.pathname);
  const { inviteId } = useSignUpContext();

  const SLIDES = {
    [routes.signUp]: {
      title: inviteId ? 'Join your community' : 'Tap into your workplace',
      subTitle:
        'Access your building, book rooms, discover amenities, and join our community.',
    },
  };

  return (
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    <WelcomeTemplate portalSize="small" slide={SLIDES[viewState]}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ signUpState: string; setSignUpState: Dispa... Remove this comment to see the full error message */}
      <SignupV2 signUpState={viewState} setSignUpState={setViewState} />
    </WelcomeTemplate>
  );
}

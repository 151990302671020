import React, { Dispatch, SetStateAction } from 'react';

import { Checkbox, Radio } from 'components';
import { useTranslation } from 'react-i18next';
import {
  RiseMigrationFeaturesListType,
  RiseMigrationFeaturesType,
} from '../../types';

import { AllowedFeatures as RiseMigrationAllowedFeatures } from 'graphql-query-contracts/src/__generated__/graphql';

import styles from '../../styles.scss';

export function MigrateUsersAndRedirect({
  features,
  allowedFeatures,
  setSelected,
  isFirstMigration,
  feature,
  redirectUrl,
  setRedirectUrl,
  redirectLinks,
}: {
  features: RiseMigrationFeaturesType;
  allowedFeatures?: RiseMigrationAllowedFeatures;
  setSelected: (key: string) => void;
  isFirstMigration: boolean;
  feature: RiseMigrationFeaturesListType;
  redirectUrl: string | null;
  setRedirectUrl: Dispatch<SetStateAction<string | null>>;
  redirectLinks: string[];
}) {
  const { t } = useTranslation();
  const { key, name, description } = feature;
  const value = features[key as keyof RiseMigrationFeaturesType];
  const isSelected = Boolean(value);
  const isDisabled = allowedFeatures
    ? !allowedFeatures[key as keyof RiseMigrationAllowedFeatures]
    : false;

  const isOnlyOneLink = redirectLinks.length === 1;
  const urlValue = isOnlyOneLink ? redirectLinks[0] : redirectUrl;

  const redirectDescription =
    (redirectUrl || isOnlyOneLink) && isSelected
      ? t('web.admin.channel.autoSetupWizard.features.migrateUsersRedirect', {
          link: urlValue,
        })
      : '';

  const descriptionString = isFirstMigration
    ? description
    : redirectDescription;

  const UrlRadios = () => {
    return (
      <>
        {redirectLinks.map((url, index) => {
          return (
            <Radio
              key={`${url}-${index}`}
              selected={urlValue ?? undefined}
              value={url}
              name={url}
              onChange={v => setRedirectUrl(v)}
              text={url}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <Checkbox
        key={key}
        text={name}
        value={key}
        selected={isSelected}
        onChange={() => {
          setSelected(key);
          if (!isSelected) {
            setRedirectUrl(null);
          }
        }}
        description={descriptionString}
        disabled={isDisabled}
      />
      {isSelected && (
        <div className={styles.Redirect}>
          <UrlRadios />
        </div>
      )}
    </>
  );
}

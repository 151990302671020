import gql from 'graphql-tag';

export default gql`
  query contentByAudience(
    $audienceId: UUID!
    $pagination: PaginationInput
    $search: ContentSearch
  ) {
    contentByAudience(
      audienceId: $audienceId
      pagination: $pagination
      search: $search
    ) {
      items {
        _id
        _created
        _updated
        name
        name_l10n
        description
        description_l10n
        subtitle
        subtitle_l10n
        slug
        type
        tags
        category
        reportingGroup
        logo
        color
        geo
        isInteractive
        backgroundImage
        icon
        iconSet
        iconWeight
        backgroundColor
        liveDate
        startDate
        endDate
        unpublishDate
        interactionEndDate
        state
        channel {
          _id
        }
        version
        renderer
      }
      pageInfo {
        total
        start
        perPage
      }
    }
  }
`;

export type FontStyleType = {
  name: string;
  weight?: string;
  url?: string;
  family: string;
};

export type FontType = {
  fontFamily: string;
  fontStyles: {
    [key: string]: FontStyleType;
  };
};

export const fonts: Record<string, FontType> = {
  inter: {
    fontFamily: 'Inter',
    fontStyles: {
      light: {
        family: 'Inter',
        name: 'Inter-Light',
        weight: '300',
      },
      regular: {
        family: 'Inter',
        name: 'Inter-Regular',
        weight: '400',
      },
      medium: {
        family: 'Inter',
        name: 'Inter-Medium',
        weight: '500',
      },
      semibold: {
        family: 'Inter',
        name: 'Inter-Bold',
        weight: '600',
      },
      lightItalic: {
        family: 'Inter',
        name: 'Inter-LightItalic',
        weight: '300',
      },
      italic: {
        family: 'Inter',
        name: 'Inter-Italic',
        weight: '400',
      },
      mediumItalic: {
        family: 'Inter',
        name: 'Inter-MediumItalic',
        weight: '500',
      },
      semiboldItalic: {
        family: 'Inter',
        name: 'Inter-BoldItalic',
        weight: '600',
      },
    },
  },
  questrial: {
    fontFamily: 'Questrial',
    fontStyles: {
      light: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '300',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      regular: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '400',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      medium: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '500',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      semibold: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '600',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      lightItalic: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '300',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      italic: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '400',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      mediumItalic: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '500',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
      semiboldItalic: {
        family: 'Questrial',
        name: 'Questrial-Regular',
        weight: '600',
        url:
          'https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70viFluW44JQ.woff2',
      },
    },
  },
};

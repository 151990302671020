import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';

import { useMutation } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import { enableUser } from 'lane-shared/graphql/user';
import { UserStatusEnum } from 'lane-shared/types/User';

import Button from 'components/general/Button';
import ContextMenu, { ModalPositionEnum } from 'components/general/ContextMenu';

import { PortalManagementUserEditType } from '../types';
import { DisableUserModal } from './DisableUserModal';

import styles from '../styles.scss';

export enum ModalType {
  Default = 'Default',
  Disable = 'Disable',
  Delete = 'Delete',
}

export const DisableUserButton = ({
  user,
  parentLoading,
}: {
  user: PortalManagementUserEditType;
  parentLoading: boolean;
}): JSX.Element | null => {
  const [loading, setLoading] = useState(parentLoading);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(ModalType.Default);
  const [disableDropdown, setDisableDropdown] = useState(false);

  // The dropdown shouldn't when the user is disabled
  // only when the user status is active
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const userStatus = {
    disabled: user.status === UserStatusEnum.Disabled,
    deleted:
      user.status === UserStatusEnum.Disabled &&
      [user.profile?.name, user?.name].includes('Deleted User'),
  };

  useEffect(() => {
    if (userStatus.disabled) {
      setDisableDropdown(true);
    } else {
      setDisableDropdown(false);
    }
  }, [userStatus.disabled]);

  const [enableUserMutation, { loading: mutationLoading }] = useMutation(
    enableUser,
    {
      client: getAdminClient() as any,
      refetchQueries: ['getUser'],
      variables: {
        userId: user._id,
      },
      onCompleted: () =>
        window.Toast.show(
          `${user.profile.name} has been successfully re-enabled.`
        ),
      onError: error =>
        window.Alert.alert({
          title: 'Failed to enable user',
          error,
        }),
    }
  );

  const buttonLabel = userStatus.disabled ? 'Enable User' : 'Disable User';

  const handleButtonClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const enableUser = () => {
      setLoading(mutationLoading);
      enableUserMutation();
    };

    // If the user is disabled, enable them
    // otherwise open the dropdown and alow the SU to either disable or delete the user
    return userStatus.disabled ? enableUser() : setDisableDropdown(false);
  };

  const dropdownItems = [
    {
      label: 'Disable only',
      onClick: () => {
        setOpenModal(true);
        setModalType(ModalType.Disable);
      },
    },
    {
      label: 'Disable and delete PII',
      onClick: () => {
        setOpenModal(true);
        setModalType(ModalType.Delete);
      },
    },
  ];

  return (
    <>
      {/* Don't render button if the user is deleted */}
      {!userStatus.deleted && (
        <>
          <ContextMenu
            autoFocus={false}
            align={ModalPositionEnum.Dropdown}
            items={
              <div className={styles.actions}>
                {dropdownItems.map((action, i) => (
                  <button
                    key={i}
                    className={styles.actionButton}
                    onClick={action.onClick}
                  >
                    {action.label}
                  </button>
                ))}
              </div>
            }
            hasOpaqueBackground={false}
            disabled={disableDropdown}
            autoClose
            onMenuOpen={() => setDropdownOpen(true)}
            style={{ flex: 'none' }}
          >
            <Button
              startIcon={
                <Icon name={dropdownOpen ? 'chevron-down' : 'chevron-up'} />
              }
              onClick={e => handleButtonClick(e!)}
            >
              {buttonLabel}
            </Button>
          </ContextMenu>
          <DisableUserModal
            user={user}
            modalType={modalType}
            parentLoading={loading}
            isModalOpen={openModal}
            setIsModalOpen={setOpenModal}
            setDropdownOpen={setDropdownOpen}
          />
        </>
      )}
    </>
  );
};

import React from 'react';

import cx from 'classnames';

import styles from './InfoRow.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  isId?: boolean;
  isColumn?: boolean;
  label: string;
};

export default function InfoRow({
  className,
  style,
  label,
  isId,
  isColumn,
  children,
}: Props) {
  return (
    <div className={cx(styles.InfoRow, className)} style={style}>
      <div className={styles.property} data-is-column={isColumn}>
        <label>{label}</label>
        <span data-is-id={isId}>{children}</span>
      </div>
    </div>
  );
}

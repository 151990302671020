import React from 'react';

import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import useUserLocale from 'hooks/useUserLocale';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';

import {
  ProductItem,
  ChargePartial,
  CurrencyFormatMap,
  currencyFromJSON,
  currencyToPaymentCurrency,
  TaxPolicy,
} from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { Table, Column } from 'design-system-web';

export function ItemsTable({
  channelId,
  charge,
}: {
  channelId: string;
  charge: ChargePartial;
}) {
  const { t } = useTranslation();
  const [isAdminView] = useIsAdminView();

  const currencyCode = CurrencyFormatMap[currencyFromJSON(charge.currency)];
  const formatCurrency = currencyFormatter({
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });
  const chargeIsVoided = charge.status === 'VOIDED';
  const locale = useUserLocale();

  const { taxDetails } = useBillingPaymentSettingsForChannel({
    channelId,
  });
  const taxInclusive = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;

  const items = charge.items.map(item => {
    const params = {
      currency: currencyToPaymentCurrency(charge.currency),
      locale,
      priceComponents: item.product,
    };

    const { grossTax, grossMarkup, listPrice, netPrice } = getPricing(params);

    const product = {
      ...item.product,
      grossTax,
      grossMarkup,
      listPrice,
      netPrice: taxInclusive ? netPrice : listPrice,
    };

    return { product, quantity: item.quantity } as ProductItem;
  });

  const voidedCell = (value: any) => <s>{value}</s>;
  const normalCell = (value: any) => <span>{value}</span>;

  const renderAmountCell = (cell: number) => {
    const value = formatCurrency(cell);
    return chargeIsVoided ? voidedCell(value) : normalCell(value);
  };

  const renderTotalPriceCell = (cell: number, row: ProductItem) => {
    const total = cell * row.quantity;
    const value = formatCurrency(total);
    return chargeIsVoided ? voidedCell(value) : normalCell(value);
  };

  const itemColumns: Column<any>[] = [
    {
      key: 'product.name',
      header: t('abp.productsServices.table.productName'),
      renderCell: chargeIsVoided ? voidedCell : normalCell,
    },
    {
      key: 'quantity',
      header: t('abp.productsServices.table.quantity'),
      renderCell: chargeIsVoided ? voidedCell : normalCell,
    },
  ];

  if (isAdminView) {
    itemColumns.push(
      {
        key: 'product.amount',
        header: t('abp.productsServices.table.rate'),
        renderCell: renderAmountCell,
      },
      {
        key: 'product.grossMarkup',
        header: t('abp.productsServices.table.markup'),
        renderCell: renderAmountCell,
      }
    );
  } else {
    itemColumns.push({
      key: 'product.listPrice',
      header: t('abp.productsServices.table.price'),
      renderCell: renderAmountCell,
    });
  }

  itemColumns.push(
    {
      key: 'product.grossTax',
      header: taxDetails?.taxName || t('abp.productsServices.table.tax'),
      renderCell: renderAmountCell,
    },
    {
      key: 'product.netPrice',
      header: t('abp.productsServices.table.amount'),
      renderCell: renderTotalPriceCell,
    }
  );

  return (
    <div>
      {items.length > 0 && (
        <Table columns={itemColumns} data={items} disableSorting />
      )}
    </div>
  );
}

import React from 'react';

import styles from './PrintingCreditListView.scss';

export default function PrintingListView({
  title = '',
  credits = [],
}: {
  title?: string;
  credits?: any[];
}) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <h3>{title}</h3>
        {credits?.map(credit => (
          <span className={styles.credit} key={(credit as any).name}>
            {(credit as any).name}:{' '}
            <strong>{(credit as any).amount || '0'}</strong>
          </span>
        ))}
      </div>
    </div>
  );
}

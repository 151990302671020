import React, { useState } from 'react';

import { useCookies } from 'react-cookie';

import { SignInError, useSignIn } from '../hooks/useSignIn';
import LoginForm from './LoginForm';
import { emitAuthEventAndSetCookie } from './helpers';

import styles from './index.scss';

export default function LoginManual({
  userEmail,
  onError,
  onSuccess,
}: {
  userEmail: string;
  onError: () => void;
  onSuccess: () => void;
}) {
  const [error, setError] = useState<SignInError | undefined>(undefined);

  const [, setCookie] = useCookies(['jti', 'token']);
  const { signInUser, isLoading: isLoadingSignIn } = useSignIn({
    onError: (signInError: SignInError | undefined) => {
      if (signInError === SignInError.SERVER_ERROR) {
        onError();
      } else {
        setError(signInError);
      }
    },
  });

  async function handleSubmit(email: string, password: string) {
    setError(undefined);

    const data = await signInUser(email, password);

    if (data) {
      await emitAuthEventAndSetCookie(data, setCookie);
      onSuccess();
    }
  }

  return (
    <div className={styles.container}>
      <LoginForm
        onSubmit={(email, password) => handleSubmit(email, password)}
        isLoading={isLoadingSignIn}
        userEmail={userEmail}
        error={{
          isIncorrectPassword: error === SignInError.INVALID_PASSWORD,
        }}
      />
    </div>
  );
}

import React, { useMemo, useEffect, useContext, useState } from 'react';

import { Flex } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useQuery, useLazyQuery } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';
import { hasPermission } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS } from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { Column, Table, PageSizeType } from 'design-system-web';

import ServiceRequestStatusDropdown from 'lane-web/src/pages/portal/admin/channel/service-requests/details/components/StatusDropdown';

import {
  adminSearchServiceRequestsQuery,
  getServiceRequestContentId,
} from 'graphql-queries';
import { H4 } from 'components/typography';

import styles from './styles.scss';

const PER_PAGE = 50;

export function TinyServiceRequestList({
  channel,
  searchID,
  showTitle = false,
}: any) {
  const { t } = useTranslation();

  const { user } = useContext(UserDataContext);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<PageSizeType>(PER_PAGE);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<string>('desc');

  const {
    data: serviceRequestContentData,
    loading: workOrderLoading,
  } = useQuery(getServiceRequestContentId, {
    variables: {
      channelId: channel?._id,
    },
  });

  const serviceRequestContentId =
    serviceRequestContentData?.serviceRequestContentId;
  const contentId = serviceRequestContentId
    ? convertToUUID(serviceRequestContentId)
    : undefined;

  const [fetchServiceRequests, { data, loading }] = useLazyQuery(
    adminSearchServiceRequestsQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    fetchServiceRequests({
      variables: {
        contentId,
        channelIds: [channel?._id],
        search: {
          sortBy: {
            key: sortBy,
            dir: sortDirection,
          },
        },
        pagination: {
          start: ((page || 0) as number) * perPage,
          perPage,
        },
        filter: {
          equipments: [searchID],
        },
      },
      fetchPolicy: 'network-only',
    });
  }, [contentId, page, perPage, sortBy, sortDirection, fetchServiceRequests]);

  const serviceRequests =
    data?.adminSearchServiceRequests?.serviceRequests || [];

  const totalServiceRequests =
    data?.adminSearchServiceRequests?.pageInfo?.total;

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  const columns: Column<any>[] = [
    {
      key: 'user_friendly_id',
      header: t('web.admin.serviceRequest.id'),
      renderCell: (user_friendly_id: string, row: any) => (
        <Link
          to={`/l/channel/${channel?.slug}/admin/work-orders/service-requests/${row.id}/view`}
        >
          {user_friendly_id}
        </Link>
      ),
    },
    {
      key: 'category',
      header: t('web.admin.serviceRequest.category'),
      type: 'text',
    },
    {
      key: 'issue',
      header: t('web.admin.serviceRequest.issue'),
      type: 'text',
    },
    isPropertyChannel
      ? {
          key: 'company',
          header: t('web.admin.serviceRequest.company'),
          type: 'text',
        }
      : {
          key: 'created_by',
          header: t('web.admin.serviceRequest.createdBy'),
          type: 'text',
        },
    {
      key: 'location',
      header: t('web.admin.serviceRequest.location'),
      type: 'text',
    },
    {
      key: 'created_at',
      header: t('web.admin.serviceRequest.createdAt'),
      type: 'date',
    },
    {
      key: 'status',
      header: t('web.admin.serviceRequest.status'),
      type: 'text',
      renderCell: serviceRequest => (
        <ServiceRequestStatusDropdown
          serviceRequestData={serviceRequest}
          isServiceRequestList
          disableEdit={
            !(
              user?.isSuperUser ||
              hasPermission(
                user?.roles,
                [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_EDIT_STATUS],
                channel?._id,
                false
              )
            )
          }
        />
      ),
    },
  ];

  const tableRows = useMemo(() => {
    const rows = serviceRequests.map((serviceRequest: any) => {
      const row: { [key: string]: any } = {
        id: serviceRequest.serviceRequestId,
        category: serviceRequest.category,
        issue: serviceRequest.issue,
        company: serviceRequest.company?.name,
        created_by: serviceRequest.createdBy?.name,
        location: serviceRequest.location,
        created_at: serviceRequest.createdAt,
        status: serviceRequest,
        user_friendly_id: serviceRequest.userFriendlyID,
      };
      return row;
    });
    return rows;
  }, [serviceRequests]);

  return (
    <Flex direction="column" className={styles.tinyServiceRequestList} gap={5}>
      {showTitle && <H4>{t('web.admin.service-requests')}</H4>}
      <Table
        maxHeight="40vh"
        isLoading={loading || workOrderLoading}
        columns={columns}
        data={tableRows}
        totalRows={Number(totalServiceRequests)}
        pagination="server"
        onPageChange={(page: number) => {
          setPage(page);
        }}
        onPageSizeChange={(pageSize: PageSizeType) => {
          setPerPage(pageSize);
        }}
        onSortChange={(sort: any) => {
          if (sort?.id) {
            setSortBy(sort?.id || 'created_at');
          }
          if (sort?.direction) {
            setSortDirection(sort?.direction || 'desc');
          }
        }}
      />
    </Flex>
  );
}

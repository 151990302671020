import { DateTime, Zone } from 'luxon';

export function formatISODateToMonthDayYear(
  startDate: string,
  timeZone: string | Zone
): string {
  const startTime = DateTime.fromISO(startDate).setZone(timeZone);
  const formattedStartDate = startTime.toFormat('MMMM dd, yyyy');

  return formattedStartDate;
}

export function formatTime(
  startDate: string,
  endDate: string,
  timeZone: string | Zone
): string {
  const startTime = DateTime.fromISO(startDate).setZone(timeZone);
  const endTime = DateTime.fromISO(endDate).setZone(timeZone);
  const formattedStartTime = startTime.toFormat('h:mm a');
  const formattedEndTime = endTime.toFormat('h:mm a');

  return `${formattedStartTime} - ${formattedEndTime}`;
}

import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { PropertiesInterface } from 'lane-shared/types/properties/Property';

import PropertyComponent from 'components/builder/properties/input/Property';
import { H4 } from 'components/typography';

import { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { CheckPermissionsButton } from './CheckPermissionsButton/CheckPermissionsButton';
import { IntegrationType } from 'lane-shared/helpers/constants/integrations/Yardi';

import styles from './YardiIntegrationEditor.scss';

export const Yardi = ({
  channelIntegration,
  onUpdateChannelIntegration,
  definition,
  channel,
}: ChannelIntegrationEditorProps) => {
  const { t } = useTranslation();
  const settings = channelIntegration?.settings;
  const properties = definition?.properties;
  const requiredPropertiesForPermissions = [
    'url',
    'serverName',
    'databaseName',
    'propertyId',
    'financialUsername',
    'financialPassword',
  ];

  useEffect(() => {
    isCheckPermissionDisabled(properties, settings);
  });

  const isFinancialsEnabled = (
    properties: any,
    settings: any,
    key: string
  ): boolean => {
    const financialProperty = properties[key];

    if (!financialProperty) {
      return false;
    }

    const { financialEnabled } = settings || {};
    if (financialEnabled) {
      properties.financialUsername.validators.push({
        name: 'Required',
        value: true,
      });
      properties.financialPassword.validators.push({
        name: 'Required',
        value: true,
      });
      return true;
    }
    if (
      properties?.financialUsername?.validators.length ||
      properties?.financialPassword?.validators.length
    ) {
      properties.financialUsername.validators = [];
      properties.financialPassword.validators = [];
    }
    return false;
  };

  const isSyncPastUsersEnabled = (
    properties: any,
    settings: any,
    key: string
  ): boolean => {
    const syncPastUsersProperty = properties[key];

    if (!syncPastUsersProperty) {
      return false;
    }
    const { syncPastUsersEnabled } = settings || {};
    if (syncPastUsersEnabled) {
      properties.daysPostMoveOut.validators.push(
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        }
      );
      if (Number.isNaN(settings.daysPostMoveOut)) {
        settings.daysPostMoveOut = undefined;
      }
      return true;
    }
    if (properties?.daysPostMoveOut?.validators.length) {
      properties.daysPostMoveOut.validators = [];
    }
    return false;
  };
  const isSyncFutureUsersEnabled = (
    properties: any,
    settings: any,
    key: string
  ): boolean => {
    const syncFutureUsersProperty = properties[key];

    if (!syncFutureUsersProperty) {
      return false;
    }
    const { syncFutureUsersEnabled } = settings || {};
    if (syncFutureUsersEnabled) {
      properties.daysPriorMoveIn.validators.push(
        {
          name: 'Required',
          value: true,
        },
        {
          name: 'Min',
          value: 1,
        }
      );
      if (Number.isNaN(settings.daysPriorMoveIn)) {
        settings.daysPriorMoveIn = undefined;
      }
      return true;
    }
    if (properties?.daysPriorMoveIn?.validators.length) {
      properties.daysPriorMoveIn.validators = [];
    }
    return false;
  };

  function isCheckPermissionDisabled(
    properties: PropertiesInterface,
    settings: ChannelIntegrationEditorProps['channelIntegration']['settings']
  ): boolean {
    if (
      !requiredPropertiesForPermissions.every(
        property => property in properties
      )
    ) {
      return true;
    }

    return requiredPropertiesForPermissions.some(key => !settings?.[key]);
  }

  return (
    <>
      <H4 mb={4}>{t('web-admin-integrations-yardi-settings-header')}</H4>
      <div>
        {Object.entries(properties).map(([key, property]) => {
          if (
            [
              'financialUsername',
              'financialPassword',
              'checkPermissionsButton',
            ].includes(key) &&
            !isFinancialsEnabled(properties, settings, key)
          ) {
            return null;
          }
          if (
            ['daysPostMoveOut'].includes(key) &&
            !isSyncPastUsersEnabled(properties, settings, key)
          ) {
            return null;
          }
          if (
            ['daysPriorMoveIn'].includes(key) &&
            !isSyncFutureUsersEnabled(properties, settings, key)
          ) {
            return null;
          }
          return (
            <div key={key}>
              <div className={styles.property}>
                {key === 'checkPermissionsButton' &&
                isFinancialsEnabled(properties, settings, key) ? (
                  <CheckPermissionsButton
                    channel={channel}
                    provider={IntegrationType.Residential}
                    settings={settings}
                    disabled={isCheckPermissionDisabled(properties, settings)}
                    requiredPropertiesForPermissions={
                      requiredPropertiesForPermissions
                    }
                    isFinancialsEnabled={isFinancialsEnabled(
                      properties,
                      settings,
                      key
                    )}
                  />
                ) : (
                  <PropertyComponent
                    object={channelIntegration.settings}
                    channel={channel}
                    label={property.friendlyName || property.name || key}
                    property={property}
                    propertyKey={key}
                    value={channelIntegration.settings?.[key]}
                    onChange={(value: any) =>
                      onUpdateChannelIntegration({
                        settings: {
                          ...channelIntegration.settings,
                          [key]: value,
                        },
                      })
                    }
                    onPropertyChange={() => null}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

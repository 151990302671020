import { StyleSheet } from '@react-pdf/renderer';

import { spacing, colors } from 'lane-shared/config';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: spacing[2],
  },
  line: {
    marginTop: 25,
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 15,
    marginBottom: 17,
    margin: 8,
  },
  subheadings: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: 'ultrabold',
  },
  subHeadingValues: {
    fontFamily: 'Helvetica',
    fontSize: 9,
    fontWeight: 'bold',
    color: 'rgb(128,128,128)',
    marginTop: 7,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 40,
    left: 0,
    right: 25,
    textAlign: 'right',
    color: 'grey',
  },
  text: {
    margin: 20,
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
  },
  rowFieldsContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 12,
    marginLeft: 8,
  },
  byLine: {
    color: colors.gray,
    margin: 8,
  },
  columnFieldContainer: {
    width: '20%',
  },
  fieldContainer: {
    marginTop: 12,
    marginLeft: 8,
  },
});

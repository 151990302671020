import React from 'react';

import {
  ProductItem,
  SupportedPaymentCurrency,
  TaxAmountType,
} from 'lane-shared/domains/billingPayments/types';

import { styles } from './PriceExceptionPDFExport.styles';

import { View, Text, Svg, Path, G } from '@react-pdf/renderer';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { SupportedLocaleEnum } from 'localization';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';

type Props = {
  item: ProductItem;
  labels: { [key: string]: string };
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
};

const ArrowSvg = (
  <Svg height="16" width="12">
    <G transform="scale(0.015)">
      <Path
        strokeWidth={0.1}
        fill="#2F3946"
        stroke="#2F3946"
        d="M216.464 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887L209.393 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L233.434 36.465c-4.686-4.687-12.284-4.687-16.97 0z"
      />
    </G>
  </Svg>
);

const ExclamationCircleSvg = (
  <Svg height="16" width="16">
    <G transform="scale(0.025)">
      <Path
        strokeWidth={0.1}
        fill="#2F3946"
        stroke="#2F3946"
        d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"
      />
    </G>
  </Svg>
);

export function PriceExceptionPDFExport({
  item,
  labels,
  currency,
  locale,
}: Props) {
  const twoDecimalsWithCurrencySymbol = currencyFormatter({
    currency,
    minimumFractionDigits: 2,
  });

  const { product } = item;

  const priceComponents = productToPriceComponents(product);
  const { listPrice } = getPricing({
    currency,
    locale,
    priceComponents,
  });

  if (!product.originalPrice) return null;

  const { listPrice: originaListPrice } = getPricing({
    currency,
    locale,
    priceComponents: {
      amount: product.originalPrice.amount,
      markup: product.originalPrice.markup ?? undefined,
      tax: product.originalPrice.tax ?? undefined,
    },
  });

  const formattedPercentTax = `${product.tax?.value ?? 0}%`;

  return (
    <View style={styles.PriceException}>
      <View style={styles.PriceExceptionIconContainer}>
        <View style={styles.ExclamationCiricle}>{ExclamationCircleSvg}</View>
      </View>
      <View style={styles.PriceExceptionTextContainer}>
        <Text style={styles.PriceExceptionLabel}>{labels.priceException}</Text>
        <View style={styles.PriceExceptionText}>
          <Text>{labels.priceExceptionRate}</Text>
          <Text style={styles.OriginalRate}>
            {twoDecimalsWithCurrencySymbol(originaListPrice)}
          </Text>
          <View style={styles.Arrow}>{ArrowSvg}</View>
          <Text>
            {twoDecimalsWithCurrencySymbol(listPrice)}
            {labels.priceExceptionTax}{' '}
            {item.product.tax?.taxAmountType ===
            TaxAmountType.TaxAmountTypeFixed
              ? twoDecimalsWithCurrencySymbol(product.tax?.value ?? 0)
              : formattedPercentTax}
          </Text>
        </View>
      </View>
    </View>
  );
}

import { DateTime } from 'luxon';

import { getReservableBestRule as getReservableBestRuleByGroupRole } from 'lane-shared/helpers/features';
import {
  ReservableFeatureProperties,
  ReservableFeatureRuleType,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import { DateRangeType } from '../../../types/baseTypes/DateRangeType';

function parseDateTime(
  date: string | Date | DateTime | null | undefined
): DateTime | null {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  return null;
}

function parseDateRange(
  dateRange: DateRangeType | null | undefined
): [start: DateTime | undefined, end: DateTime | undefined] {
  const { startDate, endDate, startTime, endTime } = dateRange ?? {};
  const parsedStartDate = parseDateTime(startDate) ?? parseDateTime(startTime);
  const parsedEndDate = parseDateTime(endDate) ?? parseDateTime(endTime);

  return [parsedStartDate ?? undefined, parsedEndDate ?? undefined];
}

function getDateFromDuration(unit: string, value: number, timeZone: string) {
  const now = DateTime.now().setZone(timeZone);

  return now.plus({
    [unit]: value,
  });
}

function getDateFromRule(
  rule: ReservableFeatureRuleType | undefined,
  timeZone: string,
  mode: 'before' | 'after'
) {
  if (!rule) {
    return undefined;
  }

  if (mode === 'before' && rule.unitsBeforeStart) {
    return getDateFromDuration(
      rule.unitsBeforeStartType,
      rule.unitsBeforeStart,
      timeZone
    );
  }

  if (mode === 'after' && rule.unitsAfterNow) {
    return getDateFromDuration(
      rule.unitsAfterNowType,
      rule.unitsAfterNow,
      timeZone
    );
  }

  return undefined;
}

function getMinMaxDateByGroupRole({
  dateRange,
  rule,
  timeZone,
}: {
  rule: ReservableFeatureRuleType | undefined;
  dateRange: DateRangeType | null | undefined;
  timeZone: string;
}) {
  const [start, end] = parseDateRange(dateRange);

  const earliestBookableDate = getDateFromRule(rule, timeZone, 'after');
  const latestBookableDate = getDateFromRule(rule, timeZone, 'before');
  const window = {
    start: earliestBookableDate ?? start,
    end: latestBookableDate ?? end,
  };

  if (start && earliestBookableDate && start > earliestBookableDate) {
    window.start = start;
  }

  if (end && latestBookableDate && end > latestBookableDate) {
    window.end = end;
  }

  return window;
}

interface Props {
  reservableFeature: ReservableFeatureProperties;
  userGroupRoleIds: string[];
  timeZone?: string;
}

export function useReservableAvailableWindow({
  reservableFeature,
  userGroupRoleIds,
  timeZone = 'UTC',
}: Props) {
  const bestRule = getReservableBestRuleByGroupRole(
    reservableFeature,
    userGroupRoleIds
  );

  const { start, end } = getMinMaxDateByGroupRole({
    rule: bestRule,
    timeZone,
    dateRange: reservableFeature?.dateRange,
  });

  return {
    minDate: start?.toJSDate(),
    maxDate: end?.toJSDate(),
  };
}

import { useTranslation } from 'react-i18next';
import { useChannelAdminContext } from 'hooks';
import { useHistory } from 'react-router-dom';
import { routes } from 'lane-shared/config';
import { Exception } from 'graphql-query-contracts/src/__generated__/graphql';

export interface ProductActions {
  label: string;
  onSelect: Function;
}

export function useGetActionsForExceptions({
  onDelete,
}: {
  onDelete: (exception: Exception) => void;
}): ProductActions[] {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const history = useHistory();
  const channel_slug = channel?.slug || '';

  const editExceptionAction = (exception: Exception) => {
    history.push(
      routes.channelAdminEditExceptionForm
        .replace(':id', channel_slug)
        .replace(':exceptionId', exception.id)
    );
  };

  const actions = [
    {
      label: t('abp.actions.editException'),
      onSelect: (exception: Exception) => editExceptionAction(exception),
    },
    {
      label: t('abp.actions.deleteException'),
      onSelect: (exception: Exception) => onDelete(exception),
    },
  ];
  return actions;
}

import React from 'react';

import cx from 'classnames';

import Dropdown from '../../../../form/Dropdown';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function DropDownInputBlock({
  keyPass = '',
  options = [],
  selected = null,
  style = {},
  onInput = () => {},
  disabled = false,
  isValid = true,
  placeholder = 'Select',
  validationMessage = 'Required.',
  className,
}: any) {
  return (
    <div className={cx(className)} style={styleMap(false, style)}>
      <Dropdown
        placeholder={placeholder}
        name={keyPass}
        onValueChange={value => onInput({ value })}
        items={options.map((option: any) => ({
          label: option.name,
          value: option.value,
        }))}
        value={selected}
        disabled={disabled}
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { v4 as uuid } from 'uuid';

import ChannelIntegrationEdit from 'components/lane/ChannelIntegrationEdit';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

export default function ChannelIntegrationNew() {
  const { channel } = useChannelAdminContext();
  const [channelIntegration, setChannelIntegration] = useState(null);

  useEffect(() => {
    if (channel?._id) {
      setChannelIntegration({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: string; channel: { _id: s... Remove this comment to see the full error message
        _id: uuid(),
        channel: {
          _id: channel._id,
        },
        integration: null,
        settings: {},
      });
    }
  }, [channel?._id]);

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'ChannelInte... Remove this comment to see the full error message
    <ChannelIntegrationEdit forCreate channelIntegration={channelIntegration} />
  );
}

import React from 'react';

import { ContentRenderer, Flex } from 'components';
import { useTranslation } from 'react-i18next';

import useBurstCustomPage from 'lane-shared/hooks/useBurstCustomPage';
import { BurstCustomPageRendererProps } from 'lane-shared/types/integrations/ShopifyBurst';

import BurstCreateHeader from 'components/integrations/ShopifyBurst/components/BurstCreateHeader';
import Container from 'components/integrations/ShopifyBurst/components/Container';

import BurstButtonGroup from '../components/BurstButtonGroup';

/**
 * NOTE: we expect this component to receive either interactionKey or
 * both wizardStateToInteractionAdapter and interactionToWizardStateAdapter
 */
export default function BurstCustomPageRenderer({
  title,
  wizardState,
  content: burstContent,
  onDataUpdated,
  goToPrevStep,
  goToNextStep,
  contentKey,
  headerComponent,
  isNextButtonDisabled = false,
  hasPreviousButton = true,
  // without this object spread union types will not work properly
  ...rest
}: BurstCustomPageRendererProps) {
  const content = wizardState.data[contentKey];
  const {
    onNextStep,
    interactionUpdatedHandler,
    interaction,
    validation,
    submitAttempted,
  } = useBurstCustomPage({
    content,
    wizardState,
    onDataUpdated,
    goToNextStep,
    ...rest,
  });
  const { t } = useTranslation();

  return (
    <>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={burstContent}
        title={title}
      />
      <Container>
        {headerComponent}
        <Flex direction="column">
          <ContentRenderer
            content={content}
            interaction={interaction}
            onInteractionUpdated={interactionUpdatedHandler}
            submitAttempted={submitAttempted}
            dataValidation={validation}
            forAdmin={false}
            hideSubmissionBlocks
          />
          <BurstButtonGroup
            disabled={isNextButtonDisabled}
            buttons={
              hasPreviousButton
                ? [
                    { title: t('Prev'), onClick: goToPrevStep },
                    { title: t('Next'), onClick: onNextStep },
                  ]
                : [{ title: t('Next'), onClick: onNextStep }]
            }
          />
        </Flex>
      </Container>
    </>
  );
}

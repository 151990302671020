import React from 'react';

import { ControlMenu, Loading, Button } from 'components';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';

import { getAdminClient } from 'lane-shared/apollo';
import { PublicUserFragment } from 'lane-shared/graphql/fragments';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';

import { AdminPage } from 'components/layout';

import styles from './styles.scss';

const integrationsQuery = gql`
  ${PublicUserFragment}

  query getIntegrations {
    integrations {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      name
      category
      type
      properties
      platforms
    }
  }
`;

export default function Integrations() {
  return (
    <AdminPage className={styles.Integrations}>
      <ControlMenu>
        <Link to="integrations/new">
          <Button variant="contained">Create</Button>
        </Link>
      </ControlMenu>

      <Query
        query={integrationsQuery}
        fetchPolicy="network-only"
        client={getAdminClient()}
      >
        {({ data }: { data: any }) => {
          if (!data || !data.integrations) {
            return <Loading />;
          }

          const integrations = data.integrations || [];

          return (
            <table>
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Platforms</th>
                  <th>Category</th>
                </tr>
              </thead>
              <tbody>
                {integrations.map((integration: any) => (
                  <tr key={integration._id}>
                    <td>{simpleDate(integration._created)}</td>
                    <td>{fromNow(integration._updated)}</td>
                    <td>
                      <Link to={`integrations/${integration._id}/edit`}>
                        {integration.name}
                      </Link>
                    </td>
                    <td>{integration.type}</td>
                    <td>{integration.platforms.join(', ')}</td>
                    <td>{integration.category}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      </Query>
    </AdminPage>
  );
}

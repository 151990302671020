import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useQuery } from '@apollo/client';

import { fromNow } from 'lane-shared/helpers/formatters';
import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { useLibraryPattern } from 'lane-shared/hooks';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import Pagination from 'components/general/Pagination';
import TableEmptyState from 'components/general/TableEmptyState';
import Grid, { Col } from 'components/lds/Grid';
import { Text } from 'components/typography';

import getDatasetSchemasOnChannel from '../queries/getDatasetSchemasOnChannel';
import NewDataLibraryModal from './NewDataLibraryModal';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import useQueryString from 'hooks/useQueryString';

import styles from './DataLibrary.scss';

const DEBOUNCE_THROTTLE = 500;
const PER_PAGE = 25;

/**
 * Displays all created datasetSchemas for this channel
 */
export default function DataLibrary() {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);
  const { url } = useRouteMatch();

  const [query, goToUrl] = useQueryString({});

  const { channel } = useChannelAdminContext();
  const library = {
    type: 'Channel',
    _id: channel?._id,
    name: getDisplayName(channel),
  };

  const { selectedLibrary } = useLibraryPattern({
    libraries: null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; _id: string | undefined; nam... Remove this comment to see the full error message
    library,
    storageKey: library?._id,
  });

  const { data, loading, error } = useQuery(getDatasetSchemasOnChannel, {
    skip: !selectedLibrary?._id,
    variables: {
      pagination: {
        start: 0,
        perPage: PER_PAGE,
      },
      channelId: selectedLibrary?._id,
      search: {
        name: { type: 'like', value: debouncedSearch },
        sortBy: { key: '_created', dir: 'desc' },
      },
    },
  });

  function handleClickNew() {
    setIsModalOpen(true);
  }

  const datasetSchemas = (data?.datasetSchemas?.items || [])
    .map((item: any) => item.datasetSchema)
    .filter(Boolean);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!channel) {
    return <></>;
  }

  return (
    <>
      <Grid className={styles.container}>
        <Col columns={[1, -1]} className={styles.searchBar}>
          <Input
            icon="search"
            placeholder="Search"
            className={styles.searchInput}
            onChange={setSearch}
            value={search}
          />
          <Button variant="contained" onClick={handleClickNew}>
            {t('New')}
          </Button>
        </Col>
        <Col columns={[1, -1]}>
          {data?.datasetSchemas && (
            <Pagination
              total={data.datasetSchemas.pageInfo.total}
              pagesToShow={3}
              perPage={PER_PAGE}
              page={Number(query.page) - 1}
              onPage={page => goToUrl({ page: page + 1 })}
            />
          )}
        </Col>

        <Col columns={[1, -1]}>
          {data && datasetSchemas.length === 0 && (
            <TableEmptyState
                className={styles.emptyTable}
                message={
                  <>
                    <Text size="large">{t('Work smartly.')}</Text>
                    <br />
                    <Text size="large">
                      {t(
                        'Create a batch content template to view the associated database here.'
                      )}
                    </Text>
                  </>
                }
            />
          )}
          {datasetSchemas.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>{t('Name')}</th>
                  <th>{t('Description')}</th>
                  <th>{t('Last Update')}</th>
                </tr>
              </thead>
              <tbody>
                {datasetSchemas.map((datasetSchema: any) => (
                  <tr key={datasetSchema._id}>
                    <td>
                      <Link to={`${url}/${datasetSchema._id}`}>
                        {datasetSchema.name}
                      </Link>
                    </td>
                    <td>{datasetSchema.description}</td>
                    <td>{fromNow(datasetSchema._updated)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {loading && <Loading className={styles.loading} />}
        </Col>
      </Grid>
      <NewDataLibraryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        channel={channel}
      />
    </>
  );
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { STATUS_MAP } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types/VisitTypes';
import type { PillColor } from 'lane-shared/types/general/pill';

import Pill from 'components/general/Pill';

type Props = {
  visitStatus: string;
};

export function StatusPill({ visitStatus }: Props) {
  const { t } = useTranslation();
  const color = STATUS_MAP[visitStatus]!.color;
  const text = t(STATUS_MAP[visitStatus]!.label);

  return (
    <Pill
      text={text}
      size="small"
      color={color as PillColor}
      iconName="circle"
      iconColor={color}
    />
  );
}

import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import Image from 'components/general/Image';
import { S, H3, M } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import styles from './MagicLink.scss';

type Props = {
  className?: string;
  error: Error | null;
  image: string;
  header: string;
  body: string;
  navigateBack?: () => void;
  sendMagicLink?: () => void;
  navigateToManualLogin?: () => void;
  loading: boolean;
};

export default function InfoViewBeforeAction({
  className,
  image,
  header,
  body,
  navigateBack,
  error,
  loading,
  sendMagicLink,
  navigateToManualLogin,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.magicLinkForm, className)}>
      <Button
        variant="text-icon"
        size="small"
        color="inherit"
        className={styles.returnButton}
        startIcon={<Icon name="chevron-left" type="far" set="FontAwesome" />}
        onClick={navigateBack}
      >
        <S className={styles.darkGreyText}>{t('Back')}</S>
      </Button>

      <Image style={{ width: '80px' }} src={image} alt="magic-link-img" />
      <H3 mt={6} mb={4}>
        {t(header)}
      </H3>

      <M mb={6}>{t(body)}</M>

      <Button
        loading={loading}
        onClick={sendMagicLink}
        variant="contained"
        className={welcomeFormStyles.actionButton}
      >
        {t('Email magic link')}
      </Button>
      <br />
      <Button
        testId="LogInManually"
        onClick={navigateToManualLogin}
        className={welcomeFormStyles.actionButton}
      >
        {t('Log in manually')}
      </Button>

      <ErrorMessage className={welcomeFormStyles.errorMessage} error={error} />
    </div>
  );
}

import React from 'react';

import { useQuery } from '@apollo/client';

import { getPublicUser } from 'lane-shared/graphql/query';

import UserThumbnail, {
  UserThumbnailBaseProps,
} from 'lane-web/src/components/lane/UserThumbnail';

export interface MemberServiceRequestDetailProps
  extends UserThumbnailBaseProps {
  id?: String;
  className?: string;
  style?: React.CSSProperties;
  labelPosition?: 'right' | 'bottom';
  splitName?: boolean;
  onClick?: (e: any) => void;
}

export const UserThumbnailWithLoader: React.FC<MemberServiceRequestDetailProps> = ({
  id,
  className,
  labelPosition,
  splitName,
}) => {
  const { data } = useQuery(getPublicUser, {
    variables: {
      id,
    },
  });

  return (
    <UserThumbnail
      className={className}
      user={data?.user}
      labelPosition={labelPosition}
      splitName={splitName}
    />
  );
};

import { gql } from '@apollo/client';

export const getRecurringChargeQuery = gql`
  query GetRecurringCharge(
    $getRecurringChargeRequest: GetRecurringChargeRequest!
  ) {
    accounts {
      getRecurringCharge(
        getRecurringChargeRequest: $getRecurringChargeRequest
      ) {
        recurringCharge {
          charge {
            amount
            currency
            description
            externalPayerId
            externalPayerType
            name
            items {
              product {
                amount
                name
                markup {
                  value
                  markupAmountType
                }
                tax {
                  taxAmountType
                  value
                }
              }
              quantity
            }
          }
          recurrence {
            recurrenceId
            startDate
            endDate
            recurrenceInterval
            isActive
            updatedAt
            updatedBy
            anyoneCanCancel
            lastOccurrence
          }
        }
      }
    }
  }
`;

import React, { useMemo, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  RegionValue,
  LocationValue,
  useLocationFilterHandlers,
  useLocationFilterOptionsBySearch,
  useSelectedLocationFilterLabelData,
} from 'lane-shared/hooks/integrations/shopify/locationFilter';
import { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

import { Checkbox } from 'components/form';
import { Button } from 'components/general';
import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';

import styles from './LocationFilter.scss';

interface Props {
  onChange: (value: (LocationValue | RegionValue)[]) => void;
  selectedLocations: (RegionValue | LocationValue)[];
  filterOptions: (RegionValue | LocationValue)[];
  ports: ShopifyChannelType[];
  className?: string;
}

export default function LocationFilter({
  onChange,
  selectedLocations,
  filterOptions,
  className,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const filtersToShow = useLocationFilterOptionsBySearch(
    filterOptions,
    searchValue
  );

  const selectedPortLabelData = useSelectedLocationFilterLabelData(
    selectedLocations
  );

  const selectedIds = useMemo(
    () => selectedLocations?.map(selectedItem => selectedItem?._id),
    [selectedLocations]
  );

  const { onLocationChange, onRegionChange } = useLocationFilterHandlers(
    filterOptions,
    selectedIds,
    selectedLocations,
    onChange
  );

  function clear() {
    setSearchValue('');
    onChange([]);
  }

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          onDelete={() => setOpen(!isOpen)}
          selected={!!selectedLocations?.length}
          deleteIcon={<Icon name="angle-down" set={ICON_SET_FONTAWESOME} />}
          label={t(selectedPortLabelData.label, {
            count: selectedPortLabelData.count,
          })}
          className={className}
          selectedIcon={null}
        />
      }
    >
      <div className={styles.OptionsMenu}>
        <Input
          icon="search"
          value={searchValue}
          onChange={value => setSearchValue(value)}
          placeholder={t('Location')}
          showClear
          className={styles.search}
        />

        <div className={styles.itemList}>
          {filtersToShow.length ? (
            filtersToShow.map(region => (
              <>
                <div key={region._id} className={styles.locationItemContainer}>
                  <Checkbox<RegionValue>
                    onChange={onRegionChange}
                    value={region}
                    name={region.label}
                    selected={selectedIds.includes(region._id)}
                  />
                  <span>{region.label}</span>
                </div>
                {(region as any)?.locations?.map((location: any) => (
                  <div
                    key={location.label}
                    className={cx(
                      styles.location,
                      styles.locationItemContainer,
                      location.hidden && styles.hidden
                    )}
                  >
                    <Checkbox<LocationValue>
                      onChange={onLocationChange}
                      value={location}
                      name={location.label}
                      selected={selectedIds.includes(location._id)}
                    />
                    {location.label}
                  </div>
                ))}
              </>
            ))
          ) : (
            <div className={cx(styles.option)}>
              <span className={styles.optionText}>{t('No results')}</span>
            </div>
          )}
        </div>
        <hr className={styles.line} />
        <Button className={styles.clearButton} onClick={clear}>
          {t('Clear')}
        </Button>
      </div>
    </DropdownContextMenu>
  );
}

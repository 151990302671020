// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useRef, useEffect } from 'react';

import Features from 'lane-shared/renderers/v5/features';

import { ReservableUiTypesEnum } from '../types/features/ReservableFeatureProperties';
import { useReservableTimePickerEnabled } from './useReservableTimePickerEnabled';

export function useSetReservableDateRangeUiType() {
  const defaultUiTypeRef = useRef<any>(
    Features.Reservable?.properties?.uiType?.default
  );

  const isTimePickerEnabled = useReservableTimePickerEnabled();

  useEffect(() => {
    if (Features.Reservable.properties?.uiType?.default) {
      if (isTimePickerEnabled) {
        Features.Reservable.properties.uiType.default =
          ReservableUiTypesEnum.TimePicker;
      } else {
        Features.Reservable.properties.uiType.default =
          defaultUiTypeRef.current;
      }
    }

    return () => {
      if (Features.Reservable.properties?.uiType?.default) {
        Features.Reservable.properties.uiType.default =
          defaultUiTypeRef.current;
      }
    };
  }, [isTimePickerEnabled]);
}

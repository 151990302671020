import gql from 'graphql-tag';

export default gql`
  mutation createMetatag($libraryItem: LibraryItemInput!) {
    createMetatag(libraryItem: $libraryItem) {
      _id
      metatag {
        _id
        name
      }
    }
  }
`;

import React, { useContext, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import {
  PIN_CIRCLE,
  PIN_IMAGE,
  PIN_LIST_VIEW,
  PIN_SQUARE,
  PIN_STANDARD,
} from 'lane-shared/helpers/constants/content';
import { useFlag, useSectionQuery } from 'lane-shared/hooks';
import useLocation from 'lane-shared/hooks/location/useLocation';
import { useMultiLanguage } from 'lane-shared/hooks/useMultiLanguage';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { LocationAvailabilityEnum } from 'lane-shared/types/LocationType';
import { FlexDirectionEnum, FlexWrapEnum } from 'lane-shared/types/blocks/Flex';
import { ContentType } from 'lane-shared/types/content/Content';
import { PresetContentSort } from 'lane-shared/types/filters/PresetContentSort';
import { ContentGroupByEnum } from 'lane-shared/types/filters/SearchOptions';

import ChannelHeader from 'components/lane/ChannelHeader';
import ContentImagePin from 'components/lane/ContentImagePin';
import { H4 } from 'components/typography';

import ContentCard from '../../../cards/ContentCard';
import Button from '../../../general/Button';
import EmptyState from '../../../general/EmptyState';
import Loading from '../../../general/Loading';
import SearchBarLegacy from '../../../general/SearchBarLegacy';
import CirclePin from '../../../lane/CirclePin';
import ContentLink from '../../../lane/ContentLink';
import ContentPin from '../../../lane/ContentPin';
import SectionSearchOptionsMenu from '../../../lane/SectionSearchOptionsMenu';
import SquarePin from '../../../lane/SquarePin';
import Modal from '../../../lds/Modal';
import { WebBlockProps } from '../WebBlockProps';
import ListHeader from './components/ListHeader';
import useEditModeProps from './useEditModeProps';
import { TagBar } from 'lane-web/src/pages/portal/section/TagBar';
import styles from './SectionContentListBlock.scss';
import { SectionSearchBar } from 'components/general/SectionSearchBar';
import { explodePresetContentFilters } from 'lane-shared/helpers/content';
import { cloneDeep } from 'lodash';

type BlockProps = WebBlockProps & {
  listView: string;
  showFilters: boolean;
  section: DocumentType;
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  perPage: number;
  flexDirection: FlexDirectionEnum;
  flexWrap: FlexWrapEnum;
  onContentClick?: (
    event: React.MouseEvent<HTMLAnchorElement>,
    content: ContentType
  ) => void;
  transformContent: (content: any) => Record<string, unknown>;
};

export default function SectionContentListBlock({
  style,
  className,
  listView,
  showFilters,
  section,
  header,
  showViewAll,
  autoHide,
  perPage,
  flexDirection,
  flexWrap,
  theme,
  onContentClick,
  transformContent,
  ...otherProps
}: BlockProps) {
  const { translate } = useMultiLanguage();
  const props = useEditModeProps(otherProps);
  const { t } = useTranslation();
  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  const sectionUIMemberUpdate = useFlag(
    FeatureFlag.SectionUIMemberUpdate,
    false
  );

  const isNewSectionToggleEnabled = useFlag(
    FeatureFlag.SectionContentRework,
    false
  );

  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTagFilters, false);

  if (props['data-is-edit-mode'] && !props['data-preview-language']) {
    header =
      translate({
        model: {
          ...otherProps,
          header,
        },
        columns: ['header'],
        undoTranslation: true,
      })?.header || header;
  }

  const { location, locationHelper } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { whitelabel } = useContext(AppContext);

  useEffect(() => {
    async function requestLocation() {
      await locationHelper.startWatching({
        showRequestModal: true,
        availability: LocationAvailabilityEnum.Foreground,
        disableLocationPrecision: whitelabel.disableLocationPrecision,
      });
    }
    requestLocation();
  }, []);

  const {
    loading,
    isHidden,
    searchOptions,
    editingSearchOptions,
    section: _section,
    hasFilters,
    hasSearch,
    hasChannelSelector,
    hasChannelLocationsSelector,
    hasEventDateSelector,
    selectedChannel,
    updateSelectedChannel,
    sectionContent: initialSectionContent,
    sectionContentGroups,
    channels,
    updateSearchOptions,
    resetSearchOptions,
    applySearchOptions,
  } = useSectionQuery({
    autoHide,
    autoSearch: false,
    sectionId: section?._id,
    pollInterval: POLL_INTERVAL,
    location,
  });

  let baseSectionContent = initialSectionContent;
  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    baseSectionContent = translate({
      model: cloneDeep(baseSectionContent),
      columns: ['content.name', 'content.description'],
      previewLanguage: props['data-preview-language'],
    });
  }

  const baseSectionContentFiltered = baseSectionContent.slice(0, perPage);

  const sectionContent = transformContent
    ? transformContent(baseSectionContentFiltered)
    : baseSectionContentFiltered;

  const channelTimeZone = useMemo(() => {
    if (!_section) {
      return undefined;
    }
    return getTimeZoneByGeoLocation({
      longitude: _section?.channel?.address?.geo?.[0],
      latitude: _section?.channel?.address?.geo?.[1],
    });
  }, [_section]);

  function searchChangeHandler(search: string) {
    updateSearchOptions({ search });
    applySearchOptions();
  }

  function openModalHandler() {
    setIsOpen(true);
  }

  function closeModalHandler() {
    setIsOpen(false);
  }

  const { filterByEventDate } = explodePresetContentFilters(
    searchOptions?.filters
  );

  const isSortedByDistance = Boolean(
    _section?.sorts?.includes(PresetContentSort.Location)
  );

  function resetSearchOptionsHandler() {
    resetSearchOptions();
    applySearchOptions();
    closeModalHandler();
  }

  function applySearchOptionsHandler() {
    updateSearchOptions({
      areFiltersApplied: true,
    });

    applySearchOptions();

    closeModalHandler();
  }

  function renderContentList(arr: any) {
    return arr.map(({ content }: any) => {
      const onClick = onContentClick
        ? (event: React.MouseEvent<HTMLAnchorElement>) =>
            onContentClick(event, content)
        : undefined;

      switch (listView) {
        case PIN_CIRCLE:
          return (
            <ContentLink onClick={onClick} content={content} key={content._id}>
              <CirclePin content={content} />
            </ContentLink>
          );
        case PIN_SQUARE:
          return (
            <ContentLink onClick={onClick} content={content} key={content._id}>
              <SquarePin content={content} className={styles.contentPin} />
            </ContentLink>
          );
        case PIN_LIST_VIEW:
          return (
            <ContentLink onClick={onClick} content={content} key={content._id}>
              <ContentCard
                className={cx(styles.ContentCard, styles.contentPin)}
                content={content}
                showDistance={isSortedByDistance}
                location={location}
              />
            </ContentLink>
          );
        case PIN_IMAGE:
          return (
            <ContentLink onClick={onClick} content={content} key={content._id}>
              <ContentImagePin
                className={styles.newContentPin}
                content={content}
              />
            </ContentLink>
          );
        case PIN_STANDARD:
        default:
          return (
            <ContentLink onClick={onClick} content={content} key={content._id}>
              <ContentPin className={styles.contentPin} content={content} />
            </ContentLink>
          );
      }
    });
  }

  function renderContent() {
    const wrapperClasses = cx(
      styles.content,
      styles[flexDirection],
      styles[flexWrap],
      loading && styles.contentLoading
    );

    if (loading && !sectionContent) {
      return (
        <div className={wrapperClasses}>
          <Loading />
        </div>
      );
    }

    if (!loading && !((sectionContent as any)?.length > 0)) {
      return (
        <div className={wrapperClasses}>
          <EmptyState showIcon={false}>
            {t(
              'web.admin.channel.content.layout.editor.components.sectionContentList.noResults'
            )}
          </EmptyState>
        </div>
      );
    }

    if (_section?.groupBy === ContentGroupByEnum.Category) {
      if (!sectionContentGroups) {
        return <div className={wrapperClasses} />;
      }

      return Object.entries(sectionContentGroups).map(([category, arr]) => (
        <div key={category}>
          <H4 className={styles.categoryHeader}>{category}</H4>
          <div className={wrapperClasses}>{renderContentList(arr)}</div>
        </div>
      ));
    }

    if (_section?.groupBy === ContentGroupByEnum.Channel) {
      if (!sectionContentGroups) {
        return <div className={wrapperClasses} />;
      }

      return Object.entries(sectionContentGroups).map(([channelId, arr]) => (
        <div key={channelId}>
          <ChannelHeader
            className={styles.channelHeader}
            channelId={channelId}
          />
          <div className={wrapperClasses}>{renderContentList(arr)}</div>
        </div>
      ));
    }

    return (
      <div className={wrapperClasses}>{renderContentList(sectionContent)}</div>
    );
  }

  if (!props['data-is-edit-mode'] && isHidden) {
    return null;
  }

  const legacySearchBar = (
    <SearchBarLegacy
      className={styles.searchBar}
      searchOptions={editingSearchOptions}
      hasChannelSelector={hasChannelSelector}
      hasChannelLocationsSelector={hasChannelLocationsSelector}
      channels={channels}
      selectedChannelId={selectedChannel?._id || (section as any)?.channel?._id}
      allLocationsSelected={Boolean(
        selectedChannel?._id === _section?.channel?._id
      )}
      showFilters={hasFilters}
      showSearch={hasSearch}
      showAllLocations
      isFiltersEnabled={Boolean(
        searchOptions?.search || searchOptions?.areFiltersApplied
      )}
      onChannelSelected={channel => {
        updateSelectedChannel(channel, otherProps.blockId);
      }}
      onAllLocationsSelected={() => {
        updateSelectedChannel(
          isNewSectionToggleEnabled ? null : _section?.channel,
          otherProps.blockId
        );
      }}
      onSearchOptionsUpdated={update => {
        updateSearchOptions(update);
        applySearchOptions();
      }}
      onSearchChange={searchChangeHandler}
      onOpenFilters={openModalHandler}
    />
  );

  const searchBar = (
    <SectionSearchBar
      searchOptions={editingSearchOptions}
      hasChannelSelector={hasChannelSelector}
      hasChannelLocationsSelector={hasChannelLocationsSelector}
      hasEventDateSelector={hasEventDateSelector}
      channels={channels}
      selectedChannelId={
        selectedChannel?._id ||
        (isNewSectionToggleEnabled ? null : (section as any)?.channel?._id)
      }
      allLocationsSelected={Boolean(
        selectedChannel?._id === _section?.channel?._id
      )}
      showFilters={hasFilters}
      showSearch={hasSearch}
      showAllLocations
      isFiltersEnabled={Boolean(
        searchOptions?.search || searchOptions?.areFiltersApplied
      )}
      onChannelSelected={channel => {
        updateSelectedChannel(channel, otherProps.blockId);
      }}
      onAllLocationsSelected={() => {
        updateSelectedChannel(
          isNewSectionToggleEnabled ? null : _section?.channel,
          otherProps.blockId
        );
      }}
      onSearchOptionsUpdated={update => {
        updateSearchOptions(update);
        applySearchOptions();
      }}
      onSearchChange={searchChangeHandler}
      onOpenFilters={openModalHandler}
    />
  );

  const displayFilters =
    (hasSearch ||
      hasFilters ||
      hasChannelSelector ||
      hasChannelLocationsSelector ||
      filterByEventDate) &&
    showFilters;

  return (
    <div
      className={cx(styles.SectionContentListBlock, className)}
      style={style}
      {...props}
    >
      <ListHeader
        theme={theme}
        header={header}
        link={routes.section.replace(':sectionId', section?._id)}
        viewAll={showViewAll}
        totalItems={0}
        currentItems={0}
      />

      {displayFilters && sectionUIMemberUpdate && searchBar}
      {displayFilters && !sectionUIMemberUpdate && legacySearchBar}

      {areContentTagsEnabled &&
        _section?.tagsOnSection &&
        _section?.tagsOnSection.length > 0 && (
          <TagBar
            tagsOnSection={_section?.tagsOnSection}
            searchOptions={searchOptions}
            updateSearchOptions={updateSearchOptions}
            applySearchOptions={applySearchOptions}
          />
        )}
      <div className={styles.contentWrapper}>
        {renderContent()}
        {hasFilters && (
          <Loading className={cx(styles.loading, loading && styles.visible)} />
        )}
      </div>
      <div className={styles.wrapper} />
      <Modal
        size="large"
        className={cx(styles.wrapper)}
        onClose={closeModalHandler}
        isOpen={isOpen}
        actions={
          <>
            <Button onClick={resetSearchOptionsHandler}>
              {t(
                'web.admin.channel.content.layout.editor.components.sectionContentList.buttons.clear'
              )}
            </Button>
            <Button onClick={applySearchOptionsHandler} variant="contained">
              {t(
                'web.admin.channel.content.layout.editor.components.sectionContentList.buttons.apply'
              )}
            </Button>
          </>
        }
      >
        <SectionSearchOptionsMenu
          timeZone={channelTimeZone}
          className={styles.filters}
          metatags={_section?.sectionMetatags?.map(
            sectionMetatag => sectionMetatag.metatag
          )}
          filters={_section?.filters}
          searchOptions={editingSearchOptions}
          onSearchOptionsUpdated={updateSearchOptions}
        />
      </Modal>
    </div>
  );
}

import React, { useContext } from 'react';

import { TabStrip, Loading, Flex } from 'components';

import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { H3 } from 'components/typography';
import { getClient } from 'lane-shared/apollo';
import { toSchema, castForUpdate } from 'lane-shared/helpers';
import { useSectionContentForAdmin } from 'lane-shared/hooks';
import { BreadCrumbs } from 'components/lds';
import { UCIs } from 'components/interactions/UCIs';
import { routes } from 'lane-shared/config';
import ChannelAdminContext from '../../ChannelAdminContext';
import { Appearance } from './tabs/Appearance';
import styles from './styles.scss';
import { Preview } from './tabs/Preview';
import { VIEW_LIST, VIEW_GRID, SORT_NAME, VIEW_TYPE } from '../constants';
import type { MenuItemType } from 'design-system-web/components/PopupMenu/PopupMenu';
import { PopupButton } from 'design-system-web';
import { useUpdateSection } from 'lane-shared/hooks/useUpdateSection';
import { deleteSection } from 'lane-shared/graphql/mutation';

const sortOrders = ['asc', 'desc'];
const [SORT_ASC, SORT_DESC] = sortOrders;

export function SectionView() {
  const { sectionId } = useParams<{ sectionId: string }>();
  const { channel, refetchChannel } = useContext(ChannelAdminContext);

  const { section, loading, refetch } = useSectionContentForAdmin({
    sectionId,
    searchOptions: {
      areFiltersApplied: false,
      search: '',
      filters: [],
      sorts: [],
      metatagFilters: [],
      channelId: null,
    },
    fetchPolicy: 'network-only',
  });

  delete section?.channel?.settings;

  if (!channel || !section) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <SectionViewBody
      channel={channel}
      section={section}
      loading={loading}
      refetch={refetch}
      refetchChannel={refetchChannel}
    />
  );
}

function SectionViewBody({
  channel,
  section,
  loading,
  refetch,
  refetchChannel,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const availableTabs = [
    t('web.admin.content.sections.view.availableTabs.preview'),
    t('web.admin.content.sections.view.availableTabs.appearance'),
    t('web.admin.content.sections.view.availableTabs.interactions'),
  ];
  const [TAB_PREVIEW, TAB_APPEARANCES, TAB_INTERACTIONS] = availableTabs;

  const [query, goToUrl, makeUrl] = useQueryString({
    view: VIEW_GRID,
    sort: SORT_NAME,
    order: SORT_ASC,
    tab: TAB_PREVIEW,
  });

  const selectedTab = (query.tab || TAB_PREVIEW) as string;
  const selectedView = query.view || VIEW_LIST;
  const selectedSort = query.sort || SORT_NAME;
  const selectedOrder = query.order || SORT_ASC;
  const reverseOrder = selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC;

  const { setError, setUpdating } = useUpdateSection(section, refetch);

  async function removeSection() {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.sections.edit.removeSection.title', {
          sectionName: section?.name,
        }),
        message: t('web.admin.content.sections.edit.removeSection.message', {
          sectionName: section?.name,
        }),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    try {
      setError(null);
      setUpdating(true);

      await getClient().mutate({
        mutation: deleteSection,
        variables: { id: section?._id },
      });

      refetchChannel();

      history.push(sectionIndexRoute);
      // tell the menu to update.
    } catch (err) {
      setError(err);
    }

    setUpdating(false);
  }

  const sectionIndexRoute = routes.channelAdminSections.replace(
    ':id',
    channel?.slug!
  );

  const breadCrumbs = [
    {
      label: t('web.admin.content.sections.breadcrumbs.index'),
      url: sectionIndexRoute,
    },
    {
      label: section?.name,
    },
  ];

  const actions = [
    {
      label: t('Edit'),
      onSelect: _event =>
        history.push(
          routes.channelAdminSectionsEdit
            .replace(':id', channel?.slug)
            .replace(':sectionId', section?._id)
        ),
    },
    {
      label: t('Delete'),
      onSelect: _event => removeSection(),
    },
  ] as MenuItemType[];

  return (
    <div className={styles.SectionView}>
      <div className={styles.topContainer}>
        <BreadCrumbs links={breadCrumbs} />
      </div>
      <Flex justify="space-between">
        <H3>{section?.name}</H3>
        <Flex gap={5}>
          <PopupButton items={actions} variant="secondary" size="large">
            {t('web.pages.portal.admin.library.tags.show.actionsButtonLabel')}
          </PopupButton>
        </Flex>
      </Flex>
      <TabStrip
        disabled={loading}
        tabs={availableTabs.map(toSchema)}
        selected={toSchema<string>(selectedTab)}
        onSelectTab={tab => goToUrl({ tab: tab.value })}
        fullWidth
        skipLabelTranslation
      />
      <div className={styles.activeTabWrapper}>
        {selectedTab === TAB_INTERACTIONS && (
          <UCIs channelId={channel?._id} sectionId={section?._id} />
        )}

        {selectedTab === TAB_PREVIEW && (
          <Preview
            section={castForUpdate(section)}
            channel={channel}
            selectedOrder={selectedOrder as string}
            selectedSort={selectedSort as string}
            reverseOrder={reverseOrder}
            makeUrl={makeUrl}
            selectedView={selectedView as VIEW_TYPE}
          />
        )}

        {selectedTab === TAB_APPEARANCES && (
          <Appearance channel={channel} section={castForUpdate(section)} />
        )}
      </div>
    </div>
  );
}

import { ControlMenu } from 'components';
import { Button } from 'design-system-web';
import {
  checkIfSourceLocationsAreValid,
  getRiseFeaturesAreValid,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/helpers';
import {
  AutoSetupConfig,
  DataSourceEnum,
  RiseMigrationFeaturesType,
  SourceLocation,
  Template,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/types';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutoSetupNavigation } from './hooks/useAutoSetupNavigation';

import styles from './styles.scss';

type Props = {
  isFirstStep: boolean;
  isSecondStep: boolean;
  isLastStep: boolean;
  sourceLocations: SourceLocation[];
  config: AutoSetupConfig;
  error: string;
  loading: boolean;
  features: RiseMigrationFeaturesType;
  templateValidation: string;
  channelSlugForNavigation: string;
  buttonNext: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  onSubmit: () => void;
  submitCesAutoSetup: () => void;
};

export function WizardNavigationButtons({
  isFirstStep,
  isSecondStep,
  isLastStep,
  sourceLocations,
  config,
  error,
  templateValidation,
  loading,
  features,
  channelSlugForNavigation,
  buttonNext,
  setCurrentStep,
  onSubmit,
  submitCesAutoSetup,
}: Props) {
  const { t } = useTranslation();
  const { goToPropertiesPage, goToRecentJobsPage } = useAutoSetupNavigation();

  const backButtonText = t`Back`;
  const recentJobsButtonText = t`web.admin.channel.autoSetupWizard.recentJobs`;
  const confirmSetupButtonText = t`web.admin.channel.autoSetupWizard.confirmSetup`;
  const saveAndNextButtonText = t`web.admin.channel.autoSetupWizard.saveAndNext`;
  const cancelButtonText = t`abp.cancel`;

  const { dataSource, templateId, templateType } = config;

  const onBackButtonPressed = (): void => {
    if (!isFirstStep) setCurrentStep(cv => cv - 1);
  };

  const isNextButtonDisabled = (): boolean => {
    if (error) {
      return true;
    }

    if (
      isFirstStep &&
      !checkIfSourceLocationsAreValid(sourceLocations) &&
      dataSource !== DataSourceEnum.CES
    ) {
      return true;
    }

    if (isSecondStep) {
      if (dataSource === DataSourceEnum.RISE) {
        return !getRiseFeaturesAreValid(features);
      }
      if (dataSource === DataSourceEnum.CES) {
        return false;
      }
      return (
        (templateType === Template.EXISTING && !templateId) ||
        Boolean(templateValidation) ||
        templateId === ''
      );
    }

    return false;
  };

  const submit = (): void => {
    if (dataSource === DataSourceEnum.CES) {
      // hint: values to submitCesAutoSetup are passed via closure at index.tsx, one level above
      submitCesAutoSetup();
    } else {
      onSubmit();
    }
  };

  return (
    <>
      {isFirstStep && (
        <ControlMenu className={styles.Controls}>
          <ControlMenu className={styles.Right}>
            <Button onClick={() => goToRecentJobsPage()} variant="text">
              {recentJobsButtonText}
            </Button>
            <Button
              onClick={() => goToPropertiesPage(channelSlugForNavigation)}
              variant="secondary"
            >
              {cancelButtonText}
            </Button>

            <Button
              onClick={buttonNext}
              disabled={isNextButtonDisabled()}
              testId="button-next"
            >
              {saveAndNextButtonText}
            </Button>
          </ControlMenu>
        </ControlMenu>
      )}
      {isSecondStep && (
        <ControlMenu className={styles.Controls}>
          <Button
            className={styles.Left}
            onClick={onBackButtonPressed}
            variant="secondary"
          >
            {backButtonText}
          </Button>
          <ControlMenu className={styles.Right}>
            {dataSource !== DataSourceEnum.CES && (
              <Button
                variant="text"
                onClick={() => goToRecentJobsPage(channelSlugForNavigation)}
              >
                {recentJobsButtonText}
              </Button>
            )}
            <Button
              onClick={() => goToPropertiesPage(channelSlugForNavigation)}
              variant="secondary"
            >
              {cancelButtonText}
            </Button>

            <Button
              onClick={buttonNext}
              disabled={isNextButtonDisabled()}
              testId="button-next"
            >
              {saveAndNextButtonText}
            </Button>
          </ControlMenu>
        </ControlMenu>
      )}
      {isLastStep && (
        <ControlMenu className={styles.Controls}>
          <Button
            className={styles.Left}
            onClick={onBackButtonPressed}
            variant="secondary"
          >
            {backButtonText}
          </Button>
          <ControlMenu className={styles.Right}>
            {dataSource !== DataSourceEnum.CES && (
              <Button variant="text" onClick={() => goToRecentJobsPage()}>
                {recentJobsButtonText}
              </Button>
            )}
            <Button
              onClick={() => goToPropertiesPage(channelSlugForNavigation)}
              variant="secondary"
            >
              {cancelButtonText}
            </Button>

            <Button
              onClick={submit}
              loading={loading}
              testId="confirm-autosetup"
            >
              {confirmSetupButtonText}
            </Button>
          </ControlMenu>
        </ControlMenu>
      )}
    </>
  );
}

import React from 'react';

import { Line } from 'components';
import { useTranslation } from 'react-i18next';

import {
  SupportedPaymentCurrency,
  NewProductDetails,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';

import { H4, L, M } from 'components/typography';

import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers/priceInputToComponents';
import useUserLocale from 'hooks/useUserLocale';

import styles from './CreateProductForm.scss';

export function CreateProductSummary({
  productDetails,
  currency,
  inclusivePricing = false,
}: {
  productDetails: NewProductDetails;
  currency: SupportedPaymentCurrency;
  inclusivePricing?: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const priceComponents = priceInputToComponents(productDetails);
  const { rate, grossMarkup, grossTax, netPrice, formatPrice } = getPricing({
    currency,
    locale,
    priceComponents,
    inclusivePricing,
  });

  return (
    <div className={styles.createProductSummaryGroup}>
      <div className={styles.summaryBorder}>
        <M mb={5} className={styles.summaryTitle}>
          {t('abp.productsServices.createProduct.summary.title', {
            currency,
          })}
        </M>
        <div className={styles.summaryItem}>
          <M>{t('abp.productsServices.createProduct.rate.label')}</M>
          <M className={`${rate < 0 ? styles.error : ''}`}>
            {formatPrice(rate, 'narrowSymbol')}
          </M>
        </div>
        <div className={styles.summaryItem}>
          <M>{t('abp.productsServices.createProduct.markup.label')}</M>
          <M>{formatPrice(grossMarkup, 'narrowSymbol')}</M>
        </div>
        <div className={styles.summaryItem}>
          <M>{t('abp.productsServices.createProduct.tax.label')}</M>
          <M>{formatPrice(grossTax, 'narrowSymbol')}</M>
        </div>
        <Line />
        <div className={styles.totalItem}>
          <H4>{t('Total')}</H4>
          <L>
            <L className={styles.totalCurrency}>{currency}</L>
            <L className={styles.totalAmount}>
              {formatPrice(netPrice, 'narrowSymbol')}
            </L>
          </L>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { Loading, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { getClient } from 'lane-shared/apollo';
import { useHistory } from 'react-router-dom';
import { BreadCrumbs } from 'components/lds';
import { routes } from 'lane-shared/config';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { TagForm } from './TagForm';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';
import { validateTag } from 'lane-shared/validation';

import { createTag } from 'lane-shared/graphql/mutation';

import styles from './Tags.scss';

const defaultTagData = {
  name: '',
  name_l10n: {},
};

export const TagNew = () => {
  const { t } = useTranslation();

  const { channel } = useChannelAdminContext();
  const history = useHistory();
  const [error, setError] = useState('');

  if (!channel) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  const createTagMutation = async ({
    name,
    name_l10n,
  }: {
    name: string;
    name_l10n?: LocalizationColumnType;
  }) => {
    const { tagNameValidator } = validateTag(t);

    const validate = async () => {
      await tagNameValidator.validate(name);
    };

    try {
      await validate();
      const { data } = await getClient().mutate({
        mutation: createTag,
        variables: {
          tag: {
            name,
            name_l10n,
            channelId: channel?._id,
          },
        },
      });

      window.Toast.show(
        t('web.pages.portal.admin.library.tags.new.createSuccess')
      );

      history.push(
        `${routes.channelAdminLibraryTagShow
          .replace(':id', channel?.slug!)
          .replace(':tagId', data.createTag.id)}`
      );
    } catch (e) {
      setError(e);
      console.error(e);
    }
  };

  const breadCrumbs = [
    {
      label: t('Tags'),
      url: `${routes.channelAdminLibraryTags.replace(':id', channel?.slug!)}`,
    },
    {
      label: t('web.pages.portal.admin.library.tags.createBreadCrumb'),
    },
  ];

  return (
    <div className={styles.container}>
      {error && <ErrorMessage error={error} />}
      <div className={styles.topContainer}>
        <BreadCrumbs links={breadCrumbs} />
      </div>
      <TagForm
        channel={channel}
        tag={defaultTagData}
        saveAction={createTagMutation}
      />
    </div>
  );
};

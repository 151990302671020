import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { M } from '../typography/index';
import CircleListView from './CircleListView';

type GroupRoleCircleListViewProps = {
  groupRole:
    | {
        name: string;
        channel?: {
          _hasAdmin?: boolean;
          name: string;
          profile?: {
            name: string | null | undefined;
            image: LaneType.UUID;
            logo: LaneType.UUID;
          };
        };
      }
    | null
    | undefined;
  className?: string;
  style?: React.CSSProperties;
  description?: string;
  showIfAdmin?: boolean;
  showAddress?: string;
};

export function GroupRoleCircleListView({
  groupRole,
  className,
  style,
  description,
  showIfAdmin,
}: GroupRoleCircleListViewProps) {
  const name = getDisplayName(groupRole);
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();
  const displayAdmin =
    showIfAdmin && (user?.isSuperUser || groupRole?.channel?._hasAdmin);

  return (
    <CircleListView
      image={imageUrl(groupRole?.channel?.profile?.image)}
      logo={imageUrl(groupRole?.channel?.profile?.logo)}
      name={name}
      className={className}
      style={style}
    >
      <M mb={displayAdmin ? 1 : 0}>{name}</M>
      {displayAdmin && <span>{t('Admin')}</span>}
      {description && <M variant="secondary">{description}</M>}
    </CircleListView>
  );
}

import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useMemberInfoQuery } from 'lane-shared/hooks';

import CircleListView from 'components/lane/CircleListView';
import ProfileQRCard from 'components/lane/ProfileQRCard';

import styles from './UserProfile.scss';

export default function UserProfile({ match }: any) {
  const { user, channels } = useMemberInfoQuery({
    userId: match?.params?.id,
  });

  return (
    <div className={styles.container}>
      <div className={styles.cardContainer}>
        <ProfileQRCard user={user} />
      </div>
      <ul className={styles.channels}>
        {channels.map(channel => (
          <CircleListView
            key={channel._id}
            className={styles.memberListView}
            image={imageUrl(channel.profile.image)}
            logo={imageUrl(channel.profile.logo)}
          >
            <div className={styles.listViewInner}>
              <Link to={routes.channel.replace(':id', channel.slug)}>
                <h3>{getDisplayName(channel)}</h3>
              </Link>
            </div>
          </CircleListView>
        ))}
      </ul>
    </div>
  );
}

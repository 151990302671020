import gql from 'graphql-tag';

export default gql(`
  fragment FullChannelContractFragment on ChannelContract {
    _id
    _created
    _updated
    name
    lastContact
    settings
    _createdBy {
      ...PublicUserFragment
    }
    _updatedBy {
      ...PublicUserFragment
    }
    channel {
      _id
      name
      slug
      profile {
        ...ProfileFragment
      }
    }
    contactUser {
      _id
      _created
      _updated
      status
      name
      profile {
        ...ProfileFragment
      }
    }
    salesUser {
      _id
      _created
      _updated
      status
      name
      profile {
        ...ProfileFragment
      }
    }
    managerUser {
      _id
      _created
      _updated
      status
      name
      profile {
        ...ProfileFragment
      }
    }
    supportUser {
      _id
      _created
      _updated
      status
      name
      profile {
        ...ProfileFragment
      }
    }
    lineItems {
      _id
      _created
      _updated
      name
      _createdBy {
      ...PublicUserFragment
      }
      _updatedBy {
      ...PublicUserFragment
      }
      channelContract {
        _id 
      }
      unitType
      units
      pricePerUnit
      currency
      subscriptionStartDate
      estimatedSubscriptionStartDate
      termLengthMonths
      settings
    }
  }
`);

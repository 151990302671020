import React, { useEffect, useState } from 'react';

import { Button, Flex } from 'lane-web/src/components';
import { useTranslation } from 'react-i18next';

import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { Icon } from 'design-system-web';
import { Equipment } from 'graphql-query-contracts';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { v4 as uuid } from 'uuid';

import MeterReadingModal from '../../../form/details/MeterReadingModal';
import { MeterReadingTable } from '../../components/MeterReadingTable/MeterReadingTable';
import styles from './MeterReadings.scss';

export interface EquipmentDetailsProps {
  channel?: UseChannelForAdminQueryResponse['channel'];
  equipment?: Equipment | null;
}
export function MeterReadingsTab({
  channel,
  equipment,
}: EquipmentDetailsProps) {
  const { t } = useTranslation();

  const [meterReadingSettings, setMeterReadingSettings] = useState<any[]>([]);
  const [refetching, setRefetching] = useState<boolean>(false);

  const [
    meterReadingValueModalOpen,
    setMeterReadingValueModalOpen,
  ] = useState<boolean>(false);

  const [meterReadingValues, setMeterReadingValues] = useState<any[]>([
    { id: uuid(), source_type: MeterReadingValueType.ADHOC },
  ]);

  useEffect(() => {
    // set meter reading settings from form data
    if (
      equipment &&
      equipment?.meterReadingSettings &&
      equipment?.meterReadingSettings.length > 0
    ) {
      setMeterReadingSettings(
        equipment?.meterReadingSettings.map((meterReadingSetting: any) => {
          return {
            id: meterReadingSetting.id,
            unit: meterReadingSetting.unit,
            name: meterReadingSetting.name,
            enableNotification: meterReadingSetting.enableNotification,
            notificationTrigger: meterReadingSetting.notificationTrigger,
            upperBound: meterReadingSetting.upperBound,
            lowerBound: meterReadingSetting.lowerBound,
            notificationTypes: meterReadingSetting.notificationTypes,
            equipmentId: meterReadingSetting.equipmentId,
          };
        })
      );
    }
  }, [equipment?.id, JSON.stringify(equipment?.meterReadingSettings)]);

  const refetch = () => {
    setRefetching(true);
  };

  const setModalOpen = (isModalOpen: boolean) => {
    setMeterReadingValues([
      { id: uuid(), source_type: MeterReadingValueType.ADHOC },
    ]);
    setMeterReadingValueModalOpen(isModalOpen);
  };

  return (
    <Flex direction="column" className={styles.EquipmentDetailsPage} gap={5}>
      <Flex>
        <Button
          variant="outlined"
          startIcon={<Icon name="plus" />}
          onClick={() => setMeterReadingValueModalOpen(true)}
          fullWidth={false}
          size="small"
          testId="add-option"
        >
          {t('web.admin.serviceRequest.equipment.meterReadingValues.add')}
        </Button>
      </Flex>
      <Flex direction="column" gap={5}>
        {meterReadingSettings?.map((meterReadingSetting, index) => (
          <Flex
            className={styles.MeterReadingsDetailsBlocks}
            key={`meterreading-${index}`}
          >
            <MeterReadingTable
              channel={channel}
              meterReadingSetting={meterReadingSetting}
              refetching={refetching}
              setRefetching={setRefetching}
            />
          </Flex>
        ))}
      </Flex>

      <MeterReadingModal
        meterReadingSettings={meterReadingSettings}
        source_type={MeterReadingValueType.ADHOC}
        channel={channel}
        meterReadingValueModalOpen={meterReadingValueModalOpen}
        setMeterReadingValueModalOpen={setModalOpen}
        startingMeterReadingValues={meterReadingValues}
        refetch={refetch}
      />
    </Flex>
  );
}

export default MeterReadingsTab;

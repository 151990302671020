import React from 'react';

import { useQuery } from '@apollo/client';

import { getChannelIntegration } from 'lane-shared/graphql/query';

import ErrorMessage from 'components/general/ErrorMessage';
import ChannelIntegrationEdit from 'components/lane/ChannelIntegrationEdit';

export default function EditChannelIntegrationPage({
  channelIntegrationId,
}: any) {
  const { data, error } = useQuery(getChannelIntegration, {
    fetchPolicy: 'network-only',
    skip: !channelIntegrationId,
    variables: {
      id: channelIntegrationId,
    },
  });

  const channelIntegration = data?.channelIntegration;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ channelIntegration: any; forCreate: false;... Remove this comment to see the full error message
    <ChannelIntegrationEdit
      channelIntegration={channelIntegration}
      forCreate={false}
    />
  );
}

import React from 'react';
import cx from 'classnames';
import {
  ServiceRequestAction,
  ServiceRequestAuditLog,
  TaskAction,
  TaskAuditLog,
} from 'graphql-query-contracts';
import { dateFormatter, mediaUrl } from 'lane-shared/helpers/formatters';
import { Thumbnail } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import styles from './ActivityLog.scss';

import {
  LONG_MONTH_DATE_YEAR,
  SHORT_TIME_WITH_TZ,
} from 'lane-shared/helpers/constants/dates';

const ARROW = '->';
const MAX_CHANGE_LENGTH = 50;

export function ActivityLog({
  auditLog,
}: {
  auditLog: ServiceRequestAuditLog | TaskAuditLog;
}) {
  const { t } = useTranslation();

  const renderActivityLogChange = (
    action: ServiceRequestAction | TaskAction
  ) => {
    if (action.field === 'equipment') {
      const equipment = action.currentState?.split(',');
      if (!equipment) return null;
      return (
        <div className={styles.change}>
          <p>
            {equipment.map(item => (
              <li key={item}>{item}</li>
            ))}
          </p>
        </div>
      );
    }
    if (
      (action.previousState &&
        action.previousState.length > MAX_CHANGE_LENGTH) ||
      (action.currentState && action.currentState.length > MAX_CHANGE_LENGTH)
    ) {
      return (
        <div className={styles.change}>
          <p>{action.previousState}</p>
          {ARROW}
          <p>{action.currentState}</p>
        </div>
      );
    }

    return (
      <div className={styles.change}>
        {action.previousState} {ARROW} {action.currentState}
      </div>
    );
  };

  const renderActivityLogItem = (
    action: ServiceRequestAction | TaskAction,
    key: string
  ) => {
    return (
      <div className={styles.activityLogItem} key={key}>
        <p>{action.title}</p>
        {renderActivityLogChange(action)}
      </div>
    );
  };

  return (
    <div className={cx(styles.cell, styles.activityLog)}>
      <div className={cx(styles.cell, styles.memberCell)}>
        <div className={styles.member}>
          <div className={styles.memberThumbnail}>
            {auditLog.userName && (
              <Thumbnail
                src={mediaUrl(auditLog.userImageUrl)}
                name={auditLog.userName}
              />
            )}
          </div>
          <div className={styles.memberName}>
            {auditLog.userName
              ? auditLog.userName
              : t('web.admin.workOrder.serviceRequest.auditLog.automatic')}
          </div>
        </div>
      </div>
      <div className={cx(styles.cell, styles.changeCell)}>
        {auditLog.actions?.map(action =>
          renderActivityLogItem(action, `${action.field}${action.title}`)
        )}
      </div>
      <div className={cx(styles.cell, styles.updatedAtCell)}>
        <div>{dateFormatter(auditLog.date, LONG_MONTH_DATE_YEAR)}</div>
        <div className={styles.time}>
          {dateFormatter(auditLog.date, SHORT_TIME_WITH_TZ)}
        </div>
      </div>
    </div>
  );
}

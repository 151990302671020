import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Icon } from 'design-system-web';
import { isEmpty, isEqual } from 'lodash';

import { routes } from 'lane-shared/config';
import {
  Flex,
  CreatedBy,
  Loading,
  Tooltip,
  FloorsAndSuitesSelector,
} from 'lane-web/src/components';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import { ButtonType } from 'lane-web/src/components/layout/PageHeader';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { H4, S } from 'components/typography';

import { Alert } from 'components/lds';

import styles from './styles.scss';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChannelType } from 'lane-shared/types/ChannelType';
import {
  OFFICE_TYPES,
  RETAIL_TYPES,
} from 'lane-shared/helpers/constants/channel';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_PROPERTY_MANAGE_COMPANY,
  PERMISSION_PROPERTY_MANAGE_RETAIL,
} from 'lane-shared/helpers/constants/permissions';
import { UserDataContext } from 'lane-shared/contexts';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { relateSpacesToTenant } from 'lane-shared/graphql/tenant';

import { RelateSpaceToTenantMutation } from 'graphql-query-contracts';
import { useFetchSuitesByTenant } from './hooks/useFetchTenantSuites';
import { useFetchRelationships } from './hooks/useFetchRelationships';
import { useCreateTenant } from './hooks/useCreateTenant';
import { useProcessSuiteList } from './hooks/useProcessSuiteList';

const TOASTER_SECONDS = 5000;

type Space = {
  floorIds: string[];
  suiteIds: string[];
};

export function TenantDetails() {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { user } = useContext(UserDataContext);
  const [isFloorSuiteEditable, setFloorSuiteEnabled] = useState(false);
  const [spaceDetails, setSpaceDetails] = useState<Space>({
    floorIds: [],
    suiteIds: [],
  });
  const { tenantId } = useParams<{ tenantId: string }>();

  const [
    relateSpaceToTenantMutation,
  ] = useMutation<RelateSpaceToTenantMutation>(relateSpacesToTenant);
  const { data, error } = useFetchRelationships(channel?._id, tenantId);
  const tenant = data?.channelsByRelationship?.items?.[0]
    ?.channel as ChannelType;
  const {
    suitesData,
    fetchSuites,
    retryFetchSuites,
    retryFetchSuitesFailed,
    isLoadingSuites,
  } = useFetchSuitesByTenant(tenant?.parent?._id, channel?._id);
  const {
    floorNames,
    suiteNames,
    suitesAsLabelValue,
    floorsAsLabelValue,
    selectedFloorIds,
    selectedSuitesIds,
  } = useProcessSuiteList(suitesData);

  useCreateTenant(tenant?.parent?._id, channel?._id);

  useEffect(() => {
    setSpaceDetails({
      suiteIds: selectedSuitesIds,
      floorIds: selectedFloorIds,
    });
  }, [selectedFloorIds, selectedSuitesIds]);

  if (error) {
    window.Toast.show(
      t('web.admin.channel.settings.tenantManagement.tenant.details.error'),
      TOASTER_SECONDS
    );
    return null;
  }

  if (!tenant) {
    return <Loading fullscreen />;
  }

  function goToChannel(rel: any) {
    const hasRetailAdmin =
      RETAIL_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_RETAIL,
      ]);
    const hasOfficeAdmin =
      OFFICE_TYPES.includes(rel.type) &&
      hasPermission(channel?.roles, [
        PERMISSION_ADMIN,
        PERMISSION_PROPERTY_MANAGE_COMPANY,
      ]);

    let route: string;
    if (user?.isSuperUser || hasRetailAdmin || hasOfficeAdmin) {
      route = routes.channelAdmin.replace(':id', rel.slug);
    } else {
      route = routes.channel.replace(':id', rel.slug);
    }
    history.push(route);
  }

  function onChannelUpdated(props: Space) {
    setSpaceDetails(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  const handleSaveSpaces = async () => {
    try {
      const newSelectedSuiteIds = spaceDetails.suiteIds || [];
      const suiteIdsToRemove =
        selectedSuitesIds.filter(id => !newSelectedSuiteIds.includes(id)) || [];

      await relateSpaceToTenantMutation({
        variables: {
          tenantId: convertToUUID(tenant?.parent?._id),
          propertyId: convertToUUID(channel?._id),
          suitesToAdd: newSelectedSuiteIds,
          suitesToRemove: suiteIdsToRemove,
        },
      });
      setFloorSuiteEnabled(false);
      if (
        !isEmpty(suiteIdsToRemove) ||
        !isEqual(selectedSuitesIds, newSelectedSuiteIds)
      ) {
        retryFetchSuites(tenant?.parent?._id, channel?._id, suitesData, 0);
      } else {
        fetchSuites(tenant?.parent?._id, channel?._id);
      }
    } catch (error) {
      window.Toast.show(
        t(
          'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.errorToast'
        )
      );
    }
  };

  const actionButton = {
    label: `${t(
      'web.admin.channel.settings.tenantManagement.tenant.details.goto'
    )} ${tenant?.name}`,
    type: 'primary' as ButtonType,
    onClick: () => goToChannel(tenant),
  };

  const tenantAddress = (
    <span className="tenant-location">
      <S className="block">{tenant?.address?.street1}</S>
      <S className="block">{tenant?.address?.street2}</S>
      <S>
        {tenant?.address?.city}, {tenant?.address?.state} ,{' '}
        {tenant?.address?.code}
      </S>
    </span>
  );

  const createdBy = {
    _createdBy: tenant._createdBy,
    _updatedBy: tenant._updatedBy,
    _created: tenant._created,
    _updated: tenant._updated,
  } as any;

  const showLoadingWhileRetryingFetchingSuites = isLoadingSuites && (
    <Flex direction="row" justify="flex-start" align="center">
      <span className="mr-2">
        {' '}
        {t(
          'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.loading'
        )}{' '}
      </span>
      <Loading style={{ fontSize: '1rem' }} />
    </Flex>
  );

  return (
    <AdminPage>
      <PageHeader
        header={tenant?.name}
        headerLevel="h3"
        actionButtons={[actionButton]}
        externalPadding={[0, 0]}
        breadcrumbs={[
          {
            label: t('web.admin.channel.settings.tenantManagement.name'),
            url: routes.channelAdminRelationships.replace(
              ':id',
              channel?.slug || ''
            ),
          },
          {
            label: `${tenant?.name || ''} ${t(
              'web.admin.channel.settings.tenantManagement.tenant.details'
            )}`,
          },
        ]}
        description={tenantAddress}
      />

      <div className="mt-4">
        <div className={styles.section}>
          <Flex align="center" mb={4}>
            <H4>
              {t`web.admin.channel.settings.tenantManagement.tenant.details.overview`}
            </H4>
            <Tooltip
              TooltipComponent={
                <>{t`web.admin.channel.settings.tenantManagement.tenant.details.overview.tooltip`}</>
              }
              placement="right"
            >
              <Icon
                className="cursor-pointer"
                name="info-circle"
                type="far"
                set={ICON_SET_FONTAWESOME}
                size="medium"
              />
            </Tooltip>
          </Flex>
          <Flex direction="column">
            <Flex className={styles.rows}>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.activeMembers'
                  )}{' '}
                </span>
                <span>{tenant?.stats?.subscribers}</span>
              </Flex>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.type'
                  )}
                </span>
                <span>{tenant?.type}</span>
              </Flex>
              <Flex className={styles.field}>
                <span>
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.squareFootage'
                  )}
                </span>
                <span>
                  {tenant?.stats?.sf}{' '}
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.sqFt'
                  )}
                </span>
              </Flex>
            </Flex>
          </Flex>
        </div>
      </div>

      <div className="mt-2">
        <div className={styles.section}>
          {isFloorSuiteEditable ? (
            <>
              <FloorsAndSuitesSelector
                suites={suitesAsLabelValue}
                floors={floorsAsLabelValue}
                direction="column"
                onFloorsAndSuitesUpdate={({ floorIds, suiteIds }: Space) =>
                  onChannelUpdated({ floorIds, suiteIds })
                }
              />
              <Flex gap={5} className="mt-4">
                <Button
                  variant="primary"
                  size="medium"
                  onClick={handleSaveSpaces}
                >
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.save'
                  )}
                </Button>
                <Button
                  variant="secondary"
                  size="medium"
                  onClick={() => setFloorSuiteEnabled(false)}
                >
                  {t(
                    'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.cancel'
                  )}
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <Flex direction="row" justify="space-between">
                <H4
                  mb={4}
                >{t`web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.title`}</H4>
                {!isFloorSuiteEditable && user?.isSuperUser && (
                  <Button
                    variant="secondary"
                    data-test="edit-floors-suites"
                    size="medium"
                    className="!px-2"
                    onClick={() => setFloorSuiteEnabled(true)}
                  >
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.edit'
                    )}
                  </Button>
                )}
              </Flex>
              {retryFetchSuitesFailed && (
                <Flex direction="row" justify="flex-start" className="mb-3">
                  <Alert type="error">
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.update.retryError'
                    )}
                  </Alert>
                </Flex>
              )}
              <Flex direction="row" justify="flex-start">
                <Flex direction="column" className="w-1/3" gap={3}>
                  <b>
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.floor'
                    )}{' '}
                  </b>
                  {showLoadingWhileRetryingFetchingSuites || (
                    <span data-test="floor-names">{floorNames}</span>
                  )}
                </Flex>
                <Flex direction="column" className="w-1/3 ml-2" gap={3}>
                  <b>
                    {t(
                      'web.admin.channel.settings.tenantManagement.tenant.details.floorAndSuite.suite'
                    )}
                  </b>
                  {showLoadingWhileRetryingFetchingSuites || (
                    <span data-test="suites-names">{suiteNames}</span>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </div>
      </div>
      <div className={styles.createdByFooter}>
        <CreatedBy object={createdBy} forAdmin />
      </div>
    </AdminPage>
  );
}

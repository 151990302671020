import React, { useState } from 'react';

import { AdminPage } from 'components';
import gql from 'graphql-tag';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { publishDraft, unPublishContent } from 'lane-shared/graphql/mutation';
import { getDraftContent } from 'lane-shared/graphql/query';
import getTimeZoneByGeoLocation from 'lane-shared/helpers/getTimeZoneByGeoLocation';

import ContentCard from 'components/cards/ContentCard';
import Button from 'components/general/Button';
import ErrorMessage from 'components/general/ErrorMessage';
import IconButton from 'components/general/IconButton';
import TabStrip from 'components/general/TabStrip';
import Tooltip from 'components/general/Tooltip';
import ContentRenderer from 'components/lane/ContentRenderer';
import ContentTargetingDetails from 'components/lane/ContentTargetingDetails';
import Box from 'components/layout/Box';
import Flex from 'components/layout/Flex';
import { BreadCrumbs, Grid, Col } from 'components/lds';
import { H4 } from 'components/typography';

import getContentLink from '../content-center/helpers/getContentLink';
import GeneratedChildrenList from './components/GeneratedChildrenList';
import GeneratorContentCount from './components/GeneratorContentCount';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './AdminViewGenerator.scss';

type Tab = 'preview' | 'targeting';

const tabs = [
  { value: 'preview', label: 'Preview' },
  { value: 'targeting', label: 'Targeting' },
];

const getDatasetSchemaAndGeneratedContent = gql`
  query getDraftContentForViewGenerator(
    $id: UUID!
    $generatedContentSearch: String
    $generatedContentPagination: PaginationInput
  ) {
    draftContent(_id: $id) {
      _id
      name
      geo
      generator {
        _id
        datasetSchema {
          _id
        }
      }
      generatedChildren(
        search: { name: { type: like, value: $generatedContentSearch } }
        pagination: $generatedContentPagination
      ) {
        pageInfo {
          total
          start
          perPage
        }
        items {
          _id
          name
          _updated
          publishedAt
          isPublished
        }
      }
    }
  }
`;

const PER_PAGE = 25;

type LoadingState = 'publishing' | 'unpublishing' | false;

/**
 * View and manage the generated content of a generator template.
 *
 * @TODO: https://linear.app/joinlane/issue/PEA-615/improve-publishingunpublishing-of-batch-content
 */
export default function AdminViewGenerator() {
  const { channel } = useChannelAdminContext();
  const [generatedContentPage, setGeneratedContentPage] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState<LoadingState>(false);
  const { contentId, id: channelSlug } = useParams<{
    id: string;
    contentId: string;
  }>();
  const [selectedContentId, setSelectedContentId] = useState(contentId);
  const [searchInput, setSearchInput] = useState('');
  const [debouncedSearchInput] = useDebounce(searchInput, 300);
  const [error, setError] = useState<Error | null>(null);
  const {
    data: generatorContentData,
    refetch: refetchGeneratorContent,
  } = useQuery(getDatasetSchemaAndGeneratedContent, {
    variables: {
      id: contentId,
      generatedContentSearch: debouncedSearchInput,
      generatedContentPagination: {
        perPage: PER_PAGE,
        start: PER_PAGE * generatedContentPage,
      },
    },
  });
  const { data: selectedContentData } = useQuery(getDraftContent, {
    variables: {
      id: selectedContentId,
    },
  });
  const [selectedTab, setSelectedTab] = useState<Tab>('preview');
  const timeZone = getTimeZoneByGeoLocation({
    latitude: generatorContentData?.draftContent?.geo[1],
    longitude: generatorContentData?.draftContent?.geo[0],
  });

  function onSelectContent(id: string) {
    setSelectedContentId(id);
  }

  function onSearch(input: string) {
    setSearchInput(input);
  }

  async function batchUnpublishContent() {
    setLoadingStatus('unpublishing');
    try {
      const { data } = await getClient().query({
        query: getDatasetSchemaAndGeneratedContent,
        fetchPolicy: 'network-only',
        variables: {
          id: generatorContentData.draftContent._id,
          generatedContentPagination: undefined,
        },
      });

      const contentToUnpublish = (
        data.draftContent.generatedChildren?.items || []
      ).filter((child: any) => child?.isPublished);

      if (contentToUnpublish.length === 0) {
        window.Toast.show(`All generated content is already unpublished.`);
        return;
      }

      const batchMutation = contentToUnpublish
        .map(
          (child: any) =>
            `unPublishChild${child._id}: unPublishContent(_id: "${child._id}")`
        )
        .join('\n');

      const batchUnPublishMutation = gql`
              mutation batchUnPublishContent {
                  ${batchMutation}
              }`;
      await getClient().mutate({
        mutation: batchUnPublishMutation,
      });
      window.Toast.show(`Successfully unpublished all generated content.`);
      refetchGeneratorContent();
    } catch (error) {
      setError(error);
    } finally {
      setLoadingStatus(false);
    }
  }

  async function batchPublishContent() {
    setLoadingStatus('publishing');
    try {
      const { data } = await getClient().query({
        query: getDatasetSchemaAndGeneratedContent,
        fetchPolicy: 'network-only',
        variables: {
          id: generatorContentData.draftContent._id,
          generatedContentPagination: undefined,
        },
      });

      const contentToPublish = (
        data.draftContent.generatedChildren?.items || []
      ).filter(
        (childDraftContent: any) =>
          (childDraftContent.publishedAt || '') < childDraftContent._updated
      );

      if (contentToPublish.length === 0) {
        window.Toast.show(`All generated content is already published.`);
        return;
      }

      const batchMutation = contentToPublish
        .map(
          (child: any) =>
            `publishChild${child._id}: publishDraft(_id: "${child._id}") { _id }`
        )
        .join('\n');

      const batchPublishMutation = gql`
              mutation batchPublishContent {
                  ${batchMutation}
              }`;

      await getClient().mutate({
        mutation: batchPublishMutation,
        variables: {
          id: generatorContent._id,
        },
      });
      window.Toast.show(`Successfully published all generated content.`);
      refetchGeneratorContent();
    } catch (error) {
      setError(error);
    } finally {
      setLoadingStatus(false);
    }
  }

  function updateChildContent(
    action: 'publish' | 'unpublish',
    childContent: any
  ) {
    return async () => {
      try {
        await getClient().mutate({
          mutation: action === 'publish' ? publishDraft : unPublishContent,
          variables: {
            id: childContent._id,
          },
        });
        refetchGeneratorContent();
        const toastMessage =
          action === 'publish'
            ? ' Successfully published content.'
            : 'Successfully unpublished content.';
        window.Toast.show(toastMessage);
      } catch (error) {
        setError(error);
      }
    };
  }

  const generatorContent = generatorContentData?.draftContent;
  const childContent = selectedContentData?.draftContent;
  const contentToRender = childContent ?? generatorContentData;

  const generatedChildrenExist =
    generatorContent?.generatedChildren?.pageInfo?.total > 0;

  if (error) {
    return (
      <Flex justify="center" p={[10, 0]} width="full">
        <ErrorMessage error={error} />
      </Flex>
    );
  }

  return (
    <AdminPage>
      <Grid>
        <Col columns={[1, 8]} mb={6}>
          <BreadCrumbs
            links={[
              {
                label: 'My Content',
                url: routes.channelAdminContentCenter.replace(
                  ':id',
                  channelSlug
                ),
              },
              { label: generatorContent?.name },
            ]}
          />
        </Col>
        <Col columns={[8, 13]}>
          <Flex justify="flex-end" gap={4} align="center">
            <Link
              to={getContentLink({
                slug: channelSlug,
                contentId: generatorContent?._id,
                forDrafts: true,
              })}
            >
              <Tooltip TooltipComponent="Edit template" placement="bottom">
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; type: "far"; set: string; }'... Remove this comment to see the full error message */}
                <IconButton icon="edit" type="far" set="FontAwesome" />
              </Tooltip>
            </Link>
            <Link
              to={routes.channelAdminDatasetLibrary
                .replace(':id', channelSlug)
                .replace(
                  ':datasetSchemaId',
                  generatorContent?.generator?.datasetSchema?._id
                )}
            >
              <Tooltip TooltipComponent="View database" placement="bottom">
                {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; set: string; type: "far"; }'... Remove this comment to see the full error message */}
                <IconButton icon="database" set="FontAwesome" type="far" />
              </Tooltip>
            </Link>
            <Button
              variant="outlined"
              onClick={batchUnpublishContent}
              loading={loadingStatus === 'unpublishing'}
              disabled={!generatedChildrenExist}
            >
              Unpublish all
            </Button>
            <Button
              variant="contained"
              onClick={batchPublishContent}
              loading={loadingStatus === 'publishing'}
              disabled={!generatedChildrenExist}
            >
              Publish all
            </Button>
          </Flex>
        </Col>
        <Col columns={[1, -1]} mb={6}>
          <Flex align="center" gap={4}>
            <H4>{generatorContent?.name}</H4>
            <GeneratorContentCount
              count={generatorContent?.generatedChildren?.pageInfo?.total}
            />
          </Flex>
        </Col>

        <Col columns={[1, 4]}>
          <GeneratedChildrenList
            searchInput={searchInput}
            onSearch={onSearch}
            generatedContentPage={generatedContentPage}
            selectedContentId={selectedContentId}
            generatorContentTemplate={generatorContentData?.draftContent}
            onSelectContent={onSelectContent}
            setGeneratedContentPage={setGeneratedContentPage}
          />
        </Col>
        <Col columns={[4, 13]}>
          <Flex justify="space-between" align="center">
            <H4 mb={6} bold>
              {selectedContentId === contentId
                ? 'Template'
                : childContent?.name}
            </H4>
            {selectedContentId !== contentId && (
              <Button
                onClick={
                  childContent?.isPublished
                    ? updateChildContent('unpublish', childContent)
                    : updateChildContent('publish', childContent)
                }
              >
                {childContent?.isPublished ? 'Unpublish' : 'Publish'}
              </Button>
            )}
          </Flex>
          <Flex justify="flex-start">
            <TabStrip
              tabs={tabs}
              selected={{ value: selectedTab }}
              onSelectTab={tab => setSelectedTab(tab.value as Tab)}
              className={styles.tabStrip}
            />
          </Flex>
          {selectedTab === 'preview' && contentToRender && (
            <Flex direction="column" gap={6}>
              <ContentCard content={contentToRender} />
              <Box className={styles.renderedContent}>
                <ContentRenderer content={contentToRender} forAdmin />
              </Box>
            </Flex>
          )}
          {selectedTab === 'targeting' && (
            <ContentTargetingDetails
              content={contentToRender}
              timeZone={timeZone}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType | null' is not assignable to typ... Remove this comment to see the full error message
              channel={channel}
            />
          )}
        </Col>
      </Grid>
    </AdminPage>
  );
}

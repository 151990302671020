import { Button, M } from 'design-system-web';
import { ErrorMessage } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './invoiceAutopayment.scss';

export function TurnOffAutopaymentModal({
  closeModal,
  removeAutopayment,
  existingAutopaymentMethod,
}: {
  closeModal: () => void;
  removeAutopayment: (existingAutoPaymentMethod: any) => Promise<void>;
  existingAutopaymentMethod: string;
}) {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const handleOnRemove = async () => {
    setIsLoading(true);
    try {
      await removeAutopayment(existingAutopaymentMethod);
      closeModal();
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <M level="h3">
        {t(
          'abp.routes.accountInvoices.autopayment.modal.turnOffAutomaticPaymentsTitle'
        )}
      </M>
      <M mb={5} mt={5}>
        {t(
          'abp.routes.accountInvoices.autopayment.modal.turnOffAutomaticPaymentsSubTitle'
        )}
      </M>
      <div className={styles.turnOffAutopaymentButtonGroup}>
        <Button
          size="large"
          variant="secondary"
          onClick={() => closeModal()}
          testId="TurnOffCancelButton"
        >
          {t('Cancel')}
        </Button>
        <Button
          size="large"
          onClick={() => handleOnRemove()}
          loading={isLoading}
          testId="TurnOffOkButton"
        >
          {t('abp.routes.accountInvoices.autopayment.modal.turnOffButton')}
        </Button>
      </div>
      <div className={styles.errorMessageContainer}>
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'design-system-web';
import { Flex } from 'components';
import { H4 } from 'components/typography';

import styles from './CustomizationPanel.scss';

export const CustomizationPanel = ({
  buttonAction,
  children,
  disabled,
}: {
  disabled: boolean;
  buttonAction: () => void;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.customizations}>
      <Flex m={5} direction="column" justify="flex-start" align="flex-start">
        <H4 mb={5}>{t('web.admin.section.appearance.customizations')}</H4>
        {children}
      </Flex>
      <div className={styles.pageDivider} />
      <div className={styles.footer}>
        <Button
          onClick={buttonAction}
          disabled={disabled}
          fullWidth
          size="large"
          variant="primary"
          testId="sectionCreateButton"
        >
          {t('Save changes')}
        </Button>
      </div>
    </div>
  );
};

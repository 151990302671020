import React from 'react';

import { Loading, ControlMenu, Button } from 'components';
import { Link } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';

import { routes } from 'lane-shared/config';
import { getWhitelabels } from 'lane-shared/graphql/query';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';

import { AdminPage } from 'components/layout';

import styles from './styles.scss';

function Whitelabels() {
  return (
    <AdminPage className={styles.Whitelabels}>
      <ControlMenu>
        <Link to={routes.portalManagementWhitelabelsNew}>
          <Button testId="newWhitelabel" variant="contained">
            New
          </Button>
        </Link>
      </ControlMenu>
      <Query query={getWhitelabels} fetchPolicy="network-only">
        {({ data }: any) => {
          if (!data || !data.whitelabels) {
            return <Loading />;
          }

          const whitelabels = data.whitelabels || [];

          return (
            <table>
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {whitelabels.map((whitelabel: any) => (
                  <tr key={whitelabel._id}>
                    <td>{simpleDate(whitelabel._created)}</td>
                    <td>{fromNow(whitelabel._updated)}</td>
                    <td>
                      <Link to={`whitelabels/${whitelabel._id}/edit`}>
                        {whitelabel.name}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        }}
      </Query>
    </AdminPage>
  );
}

export default Whitelabels;

import React, { useEffect, useState } from 'react';

import { Button } from 'design-system-web';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import { useTranslation } from 'react-i18next';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ErrorMessage } from 'lane-web/src/components/general';
import { routes } from 'lane-shared/config';

import {
  useBillingPaymentSettingsForChannel,
  useCreateCreditBundle,
} from 'lane-shared/domains/billingPayments/hooks';
import {
  CreditBundle,
  TAX_VALUE_CONVERSION,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { CreateCreditBundleFormInputFields } from './CreateCreditBundleFormInputFields';
import { CreateCreditBundleSummary } from './CreateCreditBundleSummary';
import styles from './styles.scss';
import { isNumberTwoDecimalPlaces } from '../products-services/helpers';
import { useHistory } from 'react-router-dom';

export function CreateCreditBundleForm({
  channel,
}: {
  channel: Pick<ChannelType, '_id' | 'slug'> | undefined;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    paymentSettingsError,
    currency,
    taxDetails,
  } = useBillingPaymentSettingsForChannel({ channelId: channel?._id });
  const { creditBundleError, createCreditBundle } = useCreateCreditBundle({
    channel,
  });

  const [creditBundle, setCreditBundle] = useState<CreditBundle>(
    {} as CreditBundle
  );

  const [disableSave, setDisableSave] = useState<boolean>(true);

  const creditBundlesLink = routes.channelAdminCreditBundles.replace(
    ':id',
    channel?.slug || ''
  );

  const checkCanSave = () => {
    // Check if rate and taxValue are numbers with two or fewer decimal places
    const validDecimalPlaces =
      isNumberTwoDecimalPlaces(creditBundle.rate) &&
      isNumberTwoDecimalPlaces(creditBundle.taxValue);

    // Check if name is not empty
    const hasName = creditBundle?.name?.length > 0;

    // Check if rate is greater than 0
    const hasRate = (creditBundle?.rate || 0) >= 0;

    // Check if numberOfCredits is greater than 0
    const hasCredits = (creditBundle?.numberOfCredits || 0) > 0;

    // Return true if all conditions are met
    return validDecimalPlaces && hasName && hasRate && hasCredits;
  };

  const updateCreditBundle = (c: CreditBundle) => {
    setCreditBundle(c);
  };

  const handleSave = async () => {
    if (
      checkCanSave() &&
      (await createCreditBundle(
        creditBundle,
        currencyToPaymentCurrency(currency)
      ))
    ) {
      // clear fields after success
      handleCancel();
      window.Toast.show(t('abp.creditBundle.createCreditBundle.success'), 5000);
    }
  };

  const handleCancel = () => {
    // revert to defaults from settings
    if (taxDetails) {
      setCreditBundle({
        taxType: taxDetails.taxAmountType,
        taxValue: taxDetails.taxAmountValue / TAX_VALUE_CONVERSION || 0,
      } as CreditBundle);
    } else {
      setCreditBundle({} as CreditBundle);
    }
    history.push(creditBundlesLink);
  };

  useEffect(() => {
    if (taxDetails) {
      setCreditBundle({
        ...creditBundle,
        taxType: taxDetails.taxAmountType,
        taxValue: taxDetails.taxAmountValue / TAX_VALUE_CONVERSION || 0,
      });
    }
  }, [taxDetails]);

  useEffect(() => {
    if (checkCanSave()) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [creditBundle]);

  return (
    <AdminPage>
      <PageHeader
        header={t('abp.creditBundle.createCreditBundle.header')}
        externalPadding={[3, 0]}
        breadcrumbs={[
          {
            label: t('abp.creditBundle.breadcrumbs.creditBundles'),
            url: creditBundlesLink,
          },
          { label: t('abp.creditBundle.breadcrumbs.createCreditBundle') },
        ]}
      />
      <div className={styles.createCreditBundlePage}>
        <CreateCreditBundleFormInputFields
          creditBundle={creditBundle}
          updateCreditBundle={updateCreditBundle}
          currency={currency}
        />
        <CreateCreditBundleSummary
          creditBundle={creditBundle}
          currency={currency}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          size="large"
          className={styles.button}
          disabled={disableSave}
          variant="primary"
          onClick={handleSave}
          testId="saveCreditBundle"
        >
          {t('abp.creditBundle.createCreditBundle.save')}
        </Button>
        <Button
          size="large"
          className={styles.button}
          variant="secondary"
          onClick={handleCancel}
          testId="cancelCreditBundle"
        >
          {t('abp.cancel')}
        </Button>
      </div>
      {paymentSettingsError && (
        <div className={styles.errorContainer}>
          <ErrorMessage error={t(paymentSettingsError.message)} />
        </div>
      )}
      {creditBundleError && (
        <div className={styles.errorContainer}>
          <ErrorMessage error={t(creditBundleError.message)} />
        </div>
      )}
    </AdminPage>
  );
}

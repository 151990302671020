import { GeoCoordinateType } from '../../types/baseTypes/GeoTypes';

export default function computeRectangle(
  map: unknown,
  center: [number, number],
  size: [number, number]
): GeoCoordinateType[] {
  const cUL = (map as any)
    .unproject([center[0] - size[0] / 2, center[1] - size[1] / 2])
    .toArray();

  const cUR = (map as any)
    .unproject([center[0] + size[0] / 2, center[1] - size[1] / 2])
    .toArray();

  const cLR = (map as any)
    .unproject([center[0] + size[0] / 2, center[1] + size[1] / 2])
    .toArray();

  const cLL = (map as any)
    .unproject([center[0] - size[0] / 2, center[1] + size[1] / 2])
    .toArray();

  return [cUL, cUR, cLR, cLL, cUL];
}

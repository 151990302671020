import React, { useMemo, useState } from 'react';

import { Icon } from 'design-system-web';
import { Button, Loading, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { unitErrorCodeTypes } from 'activate-errors';
import { getUnit, GetUnitQueryResponse } from 'lane-shared/graphql/units';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';
// import { dates } from 'lane-shared/helpers/constants';
// import { dateFormatter } from 'lane-shared/helpers/formatters';
import { useFlag } from 'lane-shared/hooks';
import {
  ChannelType,
  ChannelExperienceTypeEnum,
} from 'lane-shared/types/ChannelType';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { Label } from 'components/general';
import { AdminPage, PageHeader } from 'components/layout';
import { H4, M } from 'components/typography';

import { ResidentsList } from '../../residents/ResidentsList';
import { EditUnit } from '../edit';
import { useDeleteUnit } from '../hooks/useDeleteUnit';

import styles from './styles.scss';
import { TenantsList } from '../../tenants/TenantsList';
import { useRemoveTenant } from '../hooks/useRemoveTenant';
import { getSharedTranslationKeys } from '../utils';

type Props = {
  channel: ChannelType;
};

export const UnitDetails = ({ channel }: Props) => {
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const isAddManualResidentsEnabled = useFlag(
    FeatureFlag.AddManualResidents,
    false
  );
  const isTenantManagementEnabled = useFlag(
    FeatureFlag.TenantManagement,
    false
  );
  const { t } = useTranslation();
  const { detailsPage } = getSharedTranslationKeys(channel.experienceType);
  const [edit, setEdit] = useState(false);
  const { unitId } = useParams<{ unitId: string }>();
  const { handleDelete } = useDeleteUnit();
  const { handleRemove } = useRemoveTenant();
  const [isUnitUpdated, setIsUnitUpdated] = useState(false);
  const [removedTenants, setRemovedTenants] = useState<{
    [key: string]: boolean;
  }>({});

  const {
    data: unitData,
    error,
    loading,
    refetch,
  } = useQuery<GetUnitQueryResponse>(getUnit, {
    fetchPolicy: 'network-only',
    variables: {
      propertyId: channel._id,
      unitId,
    },
    notifyOnNetworkStatusChange: true,
  });

  const unit = unitData?.unit;

  if (isUnitUpdated) {
    refetch();
    setIsUnitUpdated(false);
  }

  // const lastUpdatedBy = unit?.updatedByUser?.name || unit?.createdByUser?.name;

  const getErrorMessage = (error: Error) => {
    const codes = getErrorCodes(error);
    const errorMessage = getErrorMessagesFromCodes(
      codes,
      unitErrorCodeTypes,
      t('shared.units.errors.generic')
    );
    return errorMessage;
  };

  const handleRemoveTenant = async (id: string, name: string) => {
    if (!unit) return;
    const isRemoved = await handleRemove({
      id,
      name,
      unitId,
      unitName: unit.name,
    });

    if (isRemoved) {
      setRemovedTenants(prevData => ({ ...prevData, [id]: true }));
    }
  };

  const tenantsToDisplay = useMemo(() => {
    return unit?.tenants.filter(tenant => !removedTenants[tenant.id]) ?? [];
  }, [removedTenants, unit?.tenants]);

  const basePageHeaderProps = {
    header: unit?.name,
    headerLevel: 'h3' as 'h3',
    breadcrumbs: [
      {
        label: t(detailsPage.breadcrumbs.back),
        url: `${routes.channelAdminUnitsListView.replace(
          ':id',
          channel?.slug!
        )}`,
      },
      {
        label: t(detailsPage.breadcrumbs.current),
      },
    ],
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  if (!unit || error !== undefined) {
    return (
      <>
        <div>
          <PageHeader {...basePageHeaderProps} />
        </div>
        <ErrorMessage
          error={getErrorMessage(error ?? new Error('Unit Not Found'))}
          className={styles.errorMessage}
          fullWidth
        />
      </>
    );
  }

  // TODO: Add created / updated information back

  // const created = dateFormatter(
  //   unit?.createdAtDatetime!,
  //   dates.LONG_MONTH_DATE_YEAR
  // );

  // const updated = dateFormatter(
  //   unit?.updatedAtDatetime! || unit?.createdAtDatetime!,
  //   dates.LONG_DAY_MONTH_YEAR
  // );

  // const lastUpdatedInfo = lastUpdatedBy
  //   ? t('web.pages.portal.admin.units.details.lastUpdated', {
  //       lastUpdated: updated,
  //       updatedBy: lastUpdatedBy,
  //     })
  //   : t('web.pages.portal.admin.units.details.lastUpdatedNoUser', {
  //       lastUpdated: updated,
  //     });

  const fullPageHeaderProps = {
    ...basePageHeaderProps,
    // description: lastUpdatedInfo,
    actionButtons: [
      {
        label: t(detailsPage.deleteButton),
        type: 'secondary' as const,
        testId: 'deleteUnit',
        onClick: () => {
          if (!unit) return;
          handleDelete(unit?.id, unit?.name || '');
        },
      },
    ],
  };

  if (!unitsFloorsEnabled) {
    return null;
  }

  const isMFExperience =
    channel.experienceType === ChannelExperienceTypeEnum.multifamily;

  const isOfficeExperience =
    channel.experienceType === ChannelExperienceTypeEnum.office;

  const shouldDisplayResidentsList =
    isAddManualResidentsEnabled && isMFExperience;

  const shouldDisplayTenantsList =
    isTenantManagementEnabled && isOfficeExperience;

  return (
    <AdminPage className={styles.adminPage}>
      <div className={styles.pageHeader}>
        <PageHeader {...fullPageHeaderProps} />
      </div>
      {!edit ? (
        <>
          <div className={styles.unitDetails}>
            <div className={styles.header}>
              <H4>{t(detailsPage.header)}</H4>
              <Button
                startIcon={<Icon name="pencil" />}
                size="small"
                testId="editUnit"
                onClick={() => setEdit(true)}
              >
                {t('web.pages.portal.admin.units.details.edit')}
              </Button>
            </div>
            <div className={styles.body}>
              <div className={styles.unitFloorContainer}>
                <div className={styles.childWidth}>
                  <Label className={styles.label}>
                    {t(detailsPage.name.label)}
                  </Label>
                  <M>{unit?.name}</M>
                </div>
                <div className={styles.childWidth}>
                  <Label className={styles.label}>
                    {t('web.pages.portal.admin.units.details.floorNumber')}
                  </Label>
                  <M>
                    {unit?.floors
                      .map((f: { name: string }) => f.name)
                      .join(', ') || '-'}
                  </M>
                </div>
              </div>
              <div>
                <Label className={styles.label}>
                  {t('web.pages.portal.admin.units.details.description')}
                </Label>
                <M>{unit?.description || '-'}</M>
              </div>
              {/* <div>
                    <Label className={styles.label}>
                      {t('web.pages.portal.admin.units.details.dateCreated')}
                    </Label>
                    <M>{created}</M>
                  </div> */}
            </div>
          </div>
          {shouldDisplayResidentsList && (
            <ResidentsList residents={unit.people} />
          )}
          {shouldDisplayTenantsList && (
            <TenantsList
              tenants={tenantsToDisplay}
              propertyChannelSlug={channel.slug}
              removeTenant={handleRemoveTenant}
            />
          )}
        </>
      ) : (
        <EditUnit
          channelId={channel._id}
          channelExperienceType={channel.experienceType}
          unit={unit}
          setEdit={setEdit}
          setIsUnitUpdated={setIsUnitUpdated}
        />
      )}
    </AdminPage>
  );
};

import React, { useMemo } from 'react';

import cx from 'classnames';
import { ErrorMessage, UserContentInteractionReceipt } from 'components';
import { Route } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useFlag, useInteractionQuery } from 'lane-shared/hooks';
import { useInteractionAnalytics } from 'lane-shared/hooks/analytics';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { NewUserContentInteractionReceipt } from 'components/lane/NewUserContentInteractionReceipt';
import QRCodeCheckInOutUCIReceipt from 'components/lane/userContentInteractionReceipts/QRCodeCheckInOutUCIReceipt';

import InteractionEdit from './edit';

import styles from './styles.scss';

export default function InteractionPage({ match }: any) {
  const { interaction, error } = useInteractionQuery({
    interactionId: match?.params?.id,
  });
  const features = useMemo(
    () => explodeFeatures(interaction?.contentData?.features),
    [interaction?._id]
  );
  const showNewUserContentInteractionReceipt = useFlag(
    FeatureFlag.NewUserContentInteractionReceipt,
    false
  );

  const checkInRule = features.qrCodeCheckinFeature?.checkIn;
  const checkOutRule = features.qrCodeCheckinFeature?.checkOut;
  const isCheckInOut =
    (checkInRule && Object.keys(checkInRule).length) ||
    (checkOutRule && Object.keys(checkOutRule).length);

  useInteractionAnalytics('details', interaction);

  return (
    <>
      <ErrorMessage error={error} />
      <Route
        exact
        path={routes.interactionEdit}
        render={() => <InteractionEdit interaction={interaction} />}
      />
      <Route
        exact
        path={routes.interaction}
        render={() => (
          <div
            className={cx(styles.ContentInteraction, {
              [styles.ContentInteractionShopifyBurst]:
                interaction?.data &&
                Boolean('_ShopifyBurst' in interaction.data),
            })}
          >
            {showNewUserContentInteractionReceipt ? (
              <NewUserContentInteractionReceipt
                className={!isCheckInOut && styles.receipt}
                interaction={interaction}
              />
            ) : (
              <UserContentInteractionReceipt
                className={!isCheckInOut && styles.receipt}
                interaction={interaction}
              />
            )}
          </div>
        )}
      />
      <Route
        exact
        path={routes.contentFeatureQRCodeCheckInInteraction}
        render={() => (
          <div className={cx(styles.ContentInteraction, styles.receipt)}>
            <QRCodeCheckInOutUCIReceipt interaction={interaction} />
          </div>
        )}
      />
      <Route
        exact
        path={routes.contentFeatureQRCodeCheckOutInteraction}
        render={() => (
          <div className={cx(styles.ContentInteraction, styles.receipt)}>
            <QRCodeCheckInOutUCIReceipt interaction={interaction} />
          </div>
        )}
      />
    </>
  );
}

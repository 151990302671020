import React from 'react';

import { Icon } from 'design-system-web';

import styles from './EmailChip.scss';

type Props = {
  email: string;
  removeEmail: (email: string) => void;
  isEditableVisit: boolean | undefined;
};

export default function EmailChip({
  email,
  removeEmail,
  isEditableVisit,
}: Props) {
  return (
    <div className={styles.emailChip}>
      <span>{email}</span>
      {!isEditableVisit && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => {
            removeEmail(email);
          }}
        >
          <Icon className={styles.icon} name="times" />
        </div>
      )}
    </div>
  );
}

import React from 'react';

import { spacing } from 'lane-shared/config';

import { Flex } from 'components/layout';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

const MAX_WIDTH = '60em';

/**
 * Container that centers content in the Burst creation flow.
 */
export default function Container({ className, children, style }: Props) {
  return (
    <Flex
      direction="column"
      style={{
        margin: '0 auto',
        width: '100%',
        maxWidth: MAX_WIDTH,
        paddingBottom: spacing[10],
        ...style,
      }}
      className={className}
    >
      {children}
    </Flex>
  );
}

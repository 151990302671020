import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { ChannelManagementModal } from './ChannelManagementModal';

type ChannelTypeChangeModalProps = {
  selectedChannelNames: string[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  updateChannelTypes: () => Promise<void>;
};

export default function ChannelTypeChangeModal({
  selectedChannelNames,
  isOpen,
  setIsOpen,
  updateChannelTypes,
}: ChannelTypeChangeModalProps) {
  const { t } = useTranslation();
  return (
    <ChannelManagementModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t(
        'web.admin.portalManagement.channelManagement.changeType.modal.title'
      )}
      description={t(
        'web.admin.portalManagement.channelManagement.changeType.modal.description',
        { channelNames: selectedChannelNames.join(', ') }
      )}
      onClick={() => updateChannelTypes()}
    />
  );
}

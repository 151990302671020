import * as yup from 'yup';

import Types from '../../properties/Types';
import UUID from '../../properties/baseTypes/UUID';
import { TYPE_GROUP_LANE } from '../../properties/constants';
import createShapeFromProperties from '../../properties/createShapeFromProperties';
import { DocumentType } from '../../types/DocumentType';
import { ObjectTypeBase } from '../../types/baseTypes/ObjectTypeBase';
import { PropertiesInterface } from '../../types/properties/Property';
import {
  EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_CONTENT_FEATURE_INVENTORY_TAKEN,
  EVENT_CONTENT_FEATURE_INVENTORY_RETURNED,
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED,
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED,
  EVENT_CONTENT_FEATURE_QUANTITY_TAKEN,
  EVENT_CONTENT_FEATURE_QUANTITY_EMPTY,
  EVENT_CONTENT_FEATURE_RESET_RESET,
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_CHANNEL_CREATED,
  EVENT_CONTENT_STATIC_PUBLISH,
  EVENT_CONTENT_PUBLISHED,
  EVENT_CONTENT_UNPUBLISHED,
  EVENT_CHANNEL_ROLE_INVITE_CLAIMED,
  EVENT_CHANNEL_RELATIONSHIP_CREATED,
  EVENT_CHANNEL_SECTION_CREATED,
  EVENT_CHANNEL_RELATIONSHIP_DELETED,
  EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED,
  EVENT_CHANNEL_ROLE_JOINED,
  EVENT_CHANNEL_ROLE_LEFT,
  EVENT_CHANNEL_UPDATED,
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_CREATED,
  EVENT_CONTENT_DELETED,
  EVENT_CONTENT_STARTED,
  EVENT_CONTENT_ENDED,
  EVENT_CONTENT_ENDED_INTERACTION_DATE,
  EVENT_CONTENT_PLACEMENT_CREATED, // may exist
  EVENT_CONTENT_PLACEMENT_DELETED, // may exist
  EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_CONTENT_UPDATED,
  EVENT_CONTENTTEMPLATE_CREATED, // may exist
  EVENT_CONTENTTEMPLATE_DELETED, // may exist
  EVENT_CONTENTTEMPLATE_UPDATED, // may exist
  EVENT_CHANNEL_INTEGRATION_CREATED,
  EVENT_CHANNEL_INTEGRATION_DELETED,
  EVENT_CHANNEL_INTEGRATION_UPDATED,
  EVENT_CHANNEL_ROLE_INVITE_DELETED,
  EVENT_CHANNEL_ROLE_INVITE_RESENT,
  EVENT_CHANNEL_ROLE_SIGNUP_SENT,
  EVENT_CHANNEL_ROLE_INVITE_SENT,
  EVENT_LIBRARY_ITEM_CREATED, // may exist
  EVENT_MEDIA_CREATED, // may exist
  EVENT_LIBRARY_ITEM_DELETED, // may exist
  EVENT_CHANNEL_PAYMENT_ADDED,
  EVENT_CHANNEL_PAYMENT_REMOVED,
  EVENT_CHANNEL_MERCHANT_UPDATED,
  EVENT_CHANNEL_ROLE_CREATED,
  EVENT_CHANNEL_ROLE_DELETED,
  EVENT_CHANNEL_ROLE_UPDATED,
  EVENT_CHANNEL_SECTION_UPDATED,
  EVENT_CHANNEL_SECTION_DELETED,
  EVENT_WEBHOOK_CREATED,
  EVENT_WEBHOOK_DELETED,
  EVENT_WEBHOOK_UPDATED,
} from '../constants/events';

export enum WebhookStatusEnum {
  Live = 'Live',
  Disabled = 'Disabled',
}

export type WebhookType = DocumentType & {
  description: String;
  url: string;
  status: WebhookStatusEnum;
  version: string;
  events: string[];
  channel: { _id: string };
  signingKey: string;
};

export const EVENTS_WITH_CHANNEL_IDS = [
  EVENT_CONTENT_INTERACTION_STARTED,
  EVENT_CONTENT_INTERACTION_ENDED,
  EVENT_CONTENT_INTERACTION_FEATURE_CANCELLED,
  EVENT_CONTENT_FEATURE_INVENTORY_TAKEN,
  EVENT_CONTENT_FEATURE_INVENTORY_RETURNED,
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_CREATED,
  EVENT_CONTENT_INTERACTION_FEATURE_PAYMENT_REFUNDED,
  EVENT_CONTENT_FEATURE_QUANTITY_TAKEN,
  EVENT_CONTENT_FEATURE_QUANTITY_EMPTY,
  EVENT_CONTENT_FEATURE_RESET_RESET,
  EVENT_CONTENT_INTERACTION_STATUSCHANGE,
  EVENT_CHANNEL_CREATED,
  EVENT_CONTENT_STATIC_PUBLISH,
  EVENT_CONTENT_PUBLISHED,
  EVENT_CONTENT_UNPUBLISHED,
  EVENT_CHANNEL_ROLE_INVITE_CLAIMED,
  EVENT_CHANNEL_RELATIONSHIP_CREATED,
  EVENT_CHANNEL_SECTION_CREATED,
  EVENT_CHANNEL_RELATIONSHIP_DELETED,
  EVENT_CHANNEL_WORKPLACE_MEMBER_JOINED,
  EVENT_CHANNEL_ROLE_JOINED,
  EVENT_CHANNEL_ROLE_LEFT,
  EVENT_CHANNEL_UPDATED,
  EVENT_CONTENT_INTERACTION_CREATED,
  EVENT_CONTENT_STARTED,
  EVENT_CONTENT_ENDED,
  EVENT_CONTENT_ENDED_INTERACTION_DATE,
  EVENT_CONTENT_CREATED,
  EVENT_CONTENT_DELETED,
  EVENT_CONTENT_PLACEMENT_CREATED, // may exist
  EVENT_CONTENT_PLACEMENT_DELETED, // may exist
  EVENT_CONTENT_INTERACTION_UPDATED,
  EVENT_CONTENT_UPDATED,
  EVENT_CONTENTTEMPLATE_CREATED, // may exist
  EVENT_CONTENTTEMPLATE_DELETED, // may exist
  EVENT_CONTENTTEMPLATE_UPDATED, // may exist
  EVENT_CHANNEL_INTEGRATION_CREATED,
  EVENT_CHANNEL_INTEGRATION_DELETED,
  EVENT_CHANNEL_INTEGRATION_UPDATED,
  EVENT_CHANNEL_ROLE_INVITE_DELETED,
  EVENT_CHANNEL_ROLE_INVITE_RESENT,
  EVENT_CHANNEL_ROLE_SIGNUP_SENT,
  EVENT_CHANNEL_ROLE_INVITE_SENT,
  EVENT_LIBRARY_ITEM_CREATED, // may exist
  EVENT_MEDIA_CREATED, // may exist
  EVENT_LIBRARY_ITEM_DELETED, // may exist
  EVENT_CHANNEL_PAYMENT_ADDED,
  EVENT_CHANNEL_PAYMENT_REMOVED,
  EVENT_CHANNEL_MERCHANT_UPDATED,
  EVENT_CHANNEL_ROLE_CREATED,
  EVENT_CHANNEL_ROLE_DELETED,
  EVENT_CHANNEL_ROLE_UPDATED,
  EVENT_CHANNEL_SECTION_UPDATED,
  EVENT_CHANNEL_SECTION_DELETED,
  EVENT_WEBHOOK_CREATED,
  EVENT_WEBHOOK_DELETED,
  EVENT_WEBHOOK_UPDATED,
];

EVENTS_WITH_CHANNEL_IDS.sort();

const properties: PropertiesInterface = {
  _id: {
    type: 'UUID',
    hidden: true,
  },
  channel: {
    hidden: true,
    type: 'Channel',
    validators: [
      {
        name: 'Required',
        value: true,
      },
    ],
  },
  name: {
    type: 'String',
    friendlyName: 'Webhook Name',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 64,
      },
      {
        name: 'Min',
        value: 1,
      },
    ],
  },
  description: {
    type: 'LongText',
    friendlyName: 'Webhook Description',
    validators: [
      {
        name: 'Max',
        value: 4096,
      },
    ],
  },
  url: {
    type: 'Url',
    friendlyName: 'Webhook Url',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'Max',
        value: 4096,
      },
      {
        name: 'Min',
        // smallest possible url
        value: 'https://x.co'.length,
      },
    ],
  },
  status: {
    type: 'String',
    friendlyName: 'Webhook Status',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: Object.values(WebhookStatusEnum),
      },
    ],
  },
  events: {
    type: 'String',
    friendlyName: 'Events',
    isArray: true,
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: EVENTS_WITH_CHANNEL_IDS,
      },
    ],
  },
  version: {
    type: 'String',
    friendlyName: 'API Version',
    validators: [
      {
        name: 'Required',
        value: true,
      },
      {
        name: 'In',
        value: ['v5'],
      },
    ],
  },
  signingKey: {
    type: 'String',
    friendlyName: 'Signing Key',
    validators: [],
    editable: false,
  },
};

export class WebhookTypeDefinition extends ObjectTypeBase {
  group = TYPE_GROUP_LANE;

  name = 'Webhook';

  friendlyName = 'Webhook';

  superUserOnly = false;

  contexts = [];

  get example(): any {
    return {
      _id: UUID.example,
      name: 'Webhook',
    };
  }

  get default(): any {
    return null;
  }

  buildSchema() {
    return yup.object().shape(createShapeFromProperties(properties)).nullable();
  }

  properties = properties;
}

const definition = new WebhookTypeDefinition();

Types.addType(definition);
export default definition;

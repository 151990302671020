import React from 'react';
import { Flex, AddAttachment } from 'lane-web/src/components';
import { H4, P } from 'components/typography';
import { Icon } from 'design-system-web';
import cx from 'classnames';
import styles from './Details.scss';
import { useTranslation } from 'react-i18next';
import { UserThumbnailWithLoader } from 'components/lane/UserThumbnailWithLoader';
import {
  PERMISSION_WORK_ORDERS_MODULE_ACCESS,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE,
  PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW,
} from 'lane-shared/helpers/constants/permissions';
import { hasPermission } from 'lane-shared/helpers';
import {
  AttachmentDocumentContentTypeEnum,
  AttachmentImageContentTypeEnum,
  AttachmentVariantEnum,
} from 'lane-shared/types/attachment';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { EntityNotesAndHours } from 'lane-web/src/domains/workOrder/serviceRequest/components/EntityNotesAndHours';
import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { AmazonS3Buckets } from 'lane-shared/types/media';

export function DetailsTab({
  channel,
  user,
  serviceRequestData,
  serviceRequestFeatures,
  onServiceRequestUpdated,
}: any) {
  const { t } = useTranslation();

  const hasAttachFilesPermission =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ATTACH_FILE],
      channel?._id,
      false
    );
  const isAdmin =
    user?.isSuperUser ||
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_SERVICE_REQUEST_VIEW],
      channel?._id
    );
  const isStaffUser =
    !isAdmin &&
    hasPermission(
      user?.roles,
      [PERMISSION_WORK_ORDERS_MODULE_ACCESS],
      channel?._id
    );

  const isPropertyChannel = channel?.type === ActiveChannelTypeEnum.Property;

  return (
    <div className={styles.DetailsTab}>
      <div className={styles.serviceRequestData}>
        <H4 mb={4}>{t`web.admin.serviceRequest.details`}</H4>
        {serviceRequestData.isBillable && (
          <Flex direction="column" width="full">
            <Flex
              direction="column"
              width="full"
              className={styles.ServiceRequestDataRows}
            >
              <Flex
                className={cx(
                  styles.ServiceRequestDataRow,
                  styles.ServiceRequestBillable
                )}
                width="full"
              >
                <span>{t('web.admin.serviceRequest.billable')}</span>
                <span className={styles.BillableInput}>
                  <Icon className={styles.Icon} name="check" />
                  {t('web.admin.serviceRequest.billable.yes')}
                </span>
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex direction="column">
          <Flex className={styles.ServiceRequestDataRows}>
            {isPropertyChannel && (
              <Flex className={styles.ServiceRequestDataRow}>
                <span>{t('web.admin.serviceRequest.company')} </span>
                <span>{serviceRequestData.company?.name}</span>
              </Flex>
            )}
            <Flex className={styles.ServiceRequestDataRow}>
              <span>{t('web.admin.serviceRequest.submittedBy')}</span>
              {/* <span>{serviceRequestData.createdBy}</span> */}
              <div className={styles.users}>
                <UserThumbnailWithLoader
                  className={styles.user}
                  id={serviceRequestData?.createdBy?.id}
                  labelPosition="right"
                  splitName={false}
                />
              </div>
            </Flex>
          </Flex>
          <Flex className={styles.ServiceRequestDataRows}>
            <Flex className={styles.ServiceRequestDataRow}>
              <span>{t('web.admin.serviceRequest.category')}</span>
              <span>{serviceRequestData.category}</span>
            </Flex>
            <Flex className={styles.ServiceRequestDataRow}>
              <span>{t('web.admin.serviceRequest.issue')}</span>
              <span>{serviceRequestData.issue}</span>
            </Flex>
            {isPropertyChannel && (
              <>
                <div className={styles.ServiceRequestDataRow} />
                <Flex className={styles.ServiceRequestDataRow}>
                  <span>{t('web.admin.serviceRequest.floor')}</span>
                  <span>{serviceRequestData.floor}</span>
                </Flex>
                {serviceRequestData.suite && (
                  <Flex className={styles.ServiceRequestDataRow}>
                    <span>{t('web.admin.serviceRequest.suite')}</span>
                    <span>{serviceRequestData.suite}</span>
                  </Flex>
                )}
              </>
            )}
            <Flex className={styles.ServiceRequestDataRow}>
              <span>{t('web.admin.serviceRequest.location')}</span>
              <span>{serviceRequestData.location}</span>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" width="full">
          <Flex
            direction="column"
            width="full"
            className={styles.ServiceRequestDataRows}
          >
            <Flex className={styles.ServiceRequestDataRow} width="full">
              <span>{t('web.admin.serviceRequest.description')}</span>
              <P>{serviceRequestData.description}</P>
            </Flex>
            {serviceRequestData.customQuestions &&
              serviceRequestData.customQuestions.map(
                (customQuestion: any, index: number) => (
                  <Flex
                    className={styles.ServiceRequestDataRow}
                    width="full"
                    key={index}
                  >
                    <span>{customQuestion.Question}</span>
                    <P>{customQuestion.Answer}</P>
                  </Flex>
                )
              )}
          </Flex>
        </Flex>
      </div>
      {serviceRequestFeatures.updateServiceRequest && (
        <Flex className={styles.serviceRequestData} direction="column">
          {serviceRequestData.attachmentRefId && (
            <Flex
              direction="column"
              width="full"
              className={styles.ServiceRequestAttachments}
            >
              <H4>{t('web.admin.serviceRequest.attachment.heading')}</H4>
              {hasAttachFilesPermission ? (
                <span className={styles.ServiceRequestAttachmentsDisclaimer}>
                  {t('web.admin.serviceRequest.attachment.disclaimer')}
                </span>
              ) : null}
              <AddAttachment
                entityId={serviceRequestData.attachmentRefId}
                entityType={ContentTypeEnum.WorkOrder}
                editMode={false}
                variant={AttachmentVariantEnum.WorkOrder}
                preventDelete={isStaffUser}
                canAddAttachments={hasAttachFilesPermission}
                acceptedFileTypes={[
                  ...Object.values(AttachmentImageContentTypeEnum),
                  AttachmentDocumentContentTypeEnum.pdf,
                ]}
                s3Bucket={AmazonS3Buckets.Activate}
              />
            </Flex>
          )}
        </Flex>
      )}
      <EntityNotesAndHours
        entityData={serviceRequestData}
        onEntityUpdated={onServiceRequestUpdated}
        hideHours
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Toggle } from 'components';
import { M } from 'components/typography';

import styles from './TaxAndBusinessNumbers.styles.scss';

const TRANSLATION_KEYS = {
  sectionName: 'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers',
  businessNumberToggleLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.businessNumber.toggle.label',
  taxNumberToggleLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.taxNumber.toggle.label',
  businessNumberDisplayNameLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.businessNumber.displayName.label',
  businessNumberValueLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.businessNumber.label',
  taxNumberDisplayNameLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.taxNumber.displayName.label',
  taxNumberValueLabel:
    'abp.billingAndPaymentSettings.tax.taxAndBusinessNumbers.taxNumber.label',
} as const;

type DisplayNameAndTaxNumberInputsProps = {
  displayNameLabel: string;
  numberValueLabel: string;
  displayNameValue: string;
  numberValue: string;
  onNameChange: (value: string) => void;
  onNumberChange: (value: string) => void;
};

function DisplayNameAndTaxNumberInputs({
  displayNameLabel,
  numberValueLabel,
  displayNameValue,
  numberValue,
  onNameChange,
  onNumberChange,
}: DisplayNameAndTaxNumberInputsProps) {
  const handleNameChange = (value: string) => {
    onNameChange(value);
  };

  const handleNumberChange = (value: string) => {
    onNumberChange(value);
  };

  return (
    <>
      <Input
        label={displayNameLabel}
        type="text"
        fixedLabel
        isRequired
        value={displayNameValue}
        onChange={handleNameChange}
      />
      <br />
      <Input
        label={numberValueLabel}
        type="text"
        fixedLabel
        isRequired
        value={numberValue}
        onChange={handleNumberChange}
      />
    </>
  );
}

type Info = {
  name: string;
  number: string;
};

type TaxAndBusinessNumbersProps = {
  businessInfo: Info;
  taxInfo: Info;
  onBusinessNumberChange: (data: Info) => void;
  onTaxNumberChange: (data: Info) => void;
};

export function TaxAndBusinessNumbers({
  businessInfo,
  taxInfo,
  onBusinessNumberChange,
  onTaxNumberChange,
}: TaxAndBusinessNumbersProps) {
  const { t } = useTranslation();

  const [showBusinessNumberInputs, setShowBusinessNumberInputs] = useState(
    false
  );
  const [showTaxNumberInputs, setShowTaxNumberInputs] = useState(false);

  useEffect(() => {
    setShowBusinessNumberInputs(!!(businessInfo.name || businessInfo.number));
  }, [businessInfo.name, businessInfo.number]);

  useEffect(() => {
    setShowTaxNumberInputs(!!(taxInfo.name || taxInfo.number));
  }, [taxInfo.name, taxInfo.number]);

  const toggleBusinessNumberInputs = () => {
    onBusinessNumberChange({ name: '', number: '' });
    setShowBusinessNumberInputs(!showBusinessNumberInputs);
  };

  const toggleTaxNumberInputs = () => {
    onTaxNumberChange({ name: '', number: '' });
    setShowTaxNumberInputs(!showTaxNumberInputs);
  };

  const handleBusinessNameChange = (name: string) => {
    onBusinessNumberChange({ ...businessInfo, name });
  };

  const handleBusinessNumberChange = (number: string) => {
    onBusinessNumberChange({ ...businessInfo, number });
  };

  const handleTaxNameChange = (name: string) => {
    onTaxNumberChange({ ...taxInfo, name });
  };

  const handleTaxNumberChange = (number: string) => {
    onTaxNumberChange({ ...taxInfo, number });
  };

  return (
    <>
      <M mt={2} className={styles.sectionLabel}>
        <label>{t(TRANSLATION_KEYS.sectionName)}</label>
      </M>
      <div>
        <div>
          <Toggle
            text={t(TRANSLATION_KEYS.businessNumberToggleLabel)}
            onChange={toggleBusinessNumberInputs}
            value={showBusinessNumberInputs}
          />
          {showBusinessNumberInputs && (
            <div className={styles.inputsContainer}>
              <DisplayNameAndTaxNumberInputs
                displayNameLabel={t(
                  TRANSLATION_KEYS.businessNumberDisplayNameLabel
                )}
                displayNameValue={businessInfo.name}
                numberValueLabel={t(TRANSLATION_KEYS.businessNumberValueLabel)}
                numberValue={businessInfo.number}
                onNameChange={handleBusinessNameChange}
                onNumberChange={handleBusinessNumberChange}
              />
            </div>
          )}
        </div>
        <div className={styles.toggleContainer}>
          <Toggle
            text={t(TRANSLATION_KEYS.taxNumberToggleLabel)}
            onChange={toggleTaxNumberInputs}
            value={showTaxNumberInputs}
          />
          {showTaxNumberInputs && (
            <div className={styles.inputsContainer}>
              <DisplayNameAndTaxNumberInputs
                displayNameLabel={t(TRANSLATION_KEYS.taxNumberDisplayNameLabel)}
                displayNameValue={taxInfo.name}
                numberValueLabel={t(TRANSLATION_KEYS.taxNumberValueLabel)}
                numberValue={taxInfo.number}
                onNameChange={handleTaxNameChange}
                onNumberChange={handleTaxNumberChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

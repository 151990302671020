import React, { useContext, useEffect } from 'react';

import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getMemberInfo } from 'lane-shared/graphql/channel';
import { residentIdForUser } from 'graphql-queries';
import { LONG_DATE } from 'lane-shared/helpers/constants/dates';
import {
  dateFormatter,
  fromNow,
  simpleDate,
} from 'lane-shared/helpers/formatters';
import { byName } from 'lane-shared/helpers/sort';
import { collapseMemberChannels } from 'lane-shared/helpers/user';
import { useInteractionsOnChannelQuery, useFlag } from 'lane-shared/hooks';

import { Loading, TabStrip } from 'lane-web/src/components/general';
import { ProfileQRCard } from 'lane-web/src/components/lane';
import UserChannelGroupRoleListView from 'lane-web/src/components/lane/UserChannelGroupRoleListView';
import { AdminPage } from 'lane-web/src/components/layout';

import { LookerIframeWidget } from 'components/reports/LookerIframeWidget';

import BuildingAccess from './BuildingAccess';
import { LookerReportType } from 'graphql-query-contracts';

import ResidentProfile from './ResidentProfile';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ChannelExperienceTypeEnum } from 'lane-shared/types/ChannelType';
import { PrimaryLocation } from './components/PrimaryLocation';
import { PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE } from 'lane-shared/helpers/constants/permissions';
import hasPermission from 'lane-shared/helpers/hasPermission';

import styles from './ChannelAdminMember.scss';

function ChannelAdminMember({ channel }: any) {
  const { t, i18n } = useTranslation();
  const { user: adminUser } = useContext(UserDataContext);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
  const { id: channelId, userId } = useParams();
  const { data, loading: isMemberInfoLoading, refetch } = useQuery(
    getMemberInfo,
    {
      variables: {
        id: userId,
      },
      fetchPolicy: 'network-only',
    }
  );

  const residentIdForUserQuery = useQuery(residentIdForUser, {
    variables: {
      userId,
      propertyId: channel?._id,
    },
    fetchPolicy: 'network-only',
  });

  const isLoading = isMemberInfoLoading || residentIdForUserQuery.loading;

  const { interactions } = useInteractionsOnChannelQuery({
    channelId: channel?._id,
    search: {
      user: {
        _id: userId,
      },
    },
    perPage: 25,
  });

  const user = data?.user;
  const channels = collapseMemberChannels(data?.memberGroupRoles || []).sort(
    byName
  );
  const cesPersonId = data?.person?.id;
  const canUpdatePrimaryLocation =
    adminUser?.isSuperUser ||
    hasPermission(adminUser?.roles, [PERMISSION_GROUP_PRIMARY_LOCATION_UPDATE]);

  const isAddManualResidentsEnabled = useFlag(
    FeatureFlag.AddManualResidents,
    false
  );

  const isIntuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );

  const isMFExperience =
    channel?.experienceType === ChannelExperienceTypeEnum.multifamily;
  const isResident =
    isMFExperience && !!residentIdForUserQuery.data?.resident?.id;
  const isResidentProfileTabEnabled = isAddManualResidentsEnabled && isResident;

  const PermissionGroupsComponent = (
    <ul>
      {channels.map(channel => (
        <UserChannelGroupRoleListView
          key={channel._id}
          channel={channel}
          user={user}
          adminUser={adminUser}
          refetch={refetch}
        />
      ))}
    </ul>
  );

  const TabsMap = Object.freeze({
    residentprofile: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabResidentProfile',
      value: 'ResidentProfile' as const,
      Component: (
        <ul>
          <ResidentProfile propertyId={channel?._id} personId={cesPersonId} />
        </ul>
      ),
    },
    teams: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.teams',
      value: 'Teams' as const,
      Component: PermissionGroupsComponent,
    },
    permissiongroups: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.permissionGroups',
      value: 'PermissionGroups' as const,
      Component: PermissionGroupsComponent,
    },
    activity: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity',
      value: 'Activity' as const,
      Component: (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity.table.created'
                )}
              </th>
              <th>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity.table.updated'
                )}
              </th>
              <th>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity.table.id'
                )}
              </th>
              <th>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity.table.content'
                )}
              </th>
              <th>
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabActivity.table.status'
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {interactions.map((interaction: any) => (
              <tr key={interaction._id}>
                <td>{simpleDate(interaction._created)}</td>
                <td>
                  {fromNow(interaction._updated, undefined, i18n.language)}
                </td>
                <td style={{ fontFamily: 'monospace' }}>
                  <Link
                    to={routes.channelAdminInteraction
                      .replace(':id', channelId)
                      .replace(':interactionId', interaction._id)}
                  >
                    {interaction._id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={routes.channelAdminContent
                      .replace(':id', channelId)
                      .replace(':contentId', interaction.contentData._id)}
                  >
                    {interaction.contentData.name}
                  </Link>
                </td>
                <td>{interaction.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ),
    },
    analytics: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabAnalytics',
      value: 'Analytics' as const,
      Component: (
        <LookerIframeWidget
          title={t('web.admin.channel.insightsReports.report.title')}
          reportType={LookerReportType.UserEngagementAnalytics}
          channelId={channel?._id}
          channelName={channel?.name}
          style={{
            iFrameHeight: '40em',
          }}
        />
      ),
    },
    buildingaccess: {
      label:
        'web.admin.channel.teamManagement.team.view.tabMembers.member.view.tabBuildingAccess',
      value: 'BuildingAccess' as const,
      Component: (
        <ul>
          <BuildingAccess user={user} />
        </ul>
      ),
    },
  });

  type TeamsOrPermissionGroupsTab =
    | typeof TabsMap.teams
    | typeof TabsMap.permissiongroups;
  let teamsOrPermissionGroupsTab: TeamsOrPermissionGroupsTab = TabsMap.teams;

  if (isIntuitiveUserManagementEnabled && isMFExperience) {
    teamsOrPermissionGroupsTab = TabsMap.permissiongroups;
  }

  const defaultTab = isResidentProfileTabEnabled
    ? TabsMap.residentprofile.value
    : teamsOrPermissionGroupsTab.value;

  const [query, goToUrl] = useQueryString();

  useEffect(() => {
    if (!isLoading && !query.tab) {
      // Default selected tab
      goToUrl({ tab: defaultTab });
    }
  }, [isLoading, query.tab, goToUrl, defaultTab]);

  type Tab = typeof TabsMap[keyof typeof TabsMap]['value'];

  const selectedTab: Tab | undefined =
    typeof query.tab === 'string'
      ? (query.tab.toLowerCase().split(' ').join('') as Tab)
      : undefined;

  type TabList = typeof TabsMap[keyof typeof TabsMap][];

  const availableTabs: TabList = [
    teamsOrPermissionGroupsTab,
    TabsMap.activity,
    TabsMap.analytics,
    TabsMap.buildingaccess,
  ];

  if (isResidentProfileTabEnabled) {
    availableTabs.unshift(TabsMap.residentprofile);
  }

  return (
    <AdminPage>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.ChannelAdminMember}>
          <ProfileQRCard user={user} hideQR={isAddManualResidentsEnabled} />
          <div className={styles.info}>
            {adminUser?.isSuperUser && (
              <Link
                to={generatePath(routes.portalManagementUserEdit, {
                  userId: user._id,
                })}
              >
                {t(
                  'web.admin.channel.teamManagement.team.view.tabMembers.member.view.button.manageUser'
                )}
              </Link>
            )}

            <br />

            <h2>
              {t(
                'web.admin.channel.teamManagement.team.view.tabMembers.member.view.info.joined'
              )}{' '}
              {dateFormatter(user._created, LONG_DATE)}
            </h2>
            <h2>
              {t(
                'web.admin.channel.teamManagement.team.view.tabMembers.member.view.info.lastSeen'
              )}{' '}
              {fromNow(user.lastSeen, undefined, i18n.language)}
            </h2>
            {user.profile.description && <p>{user.profile.description}</p>}
          </div>

          {canUpdatePrimaryLocation && (
            <div className={styles.primaryLocation}>
              <PrimaryLocation
                data={{
                  userId: user._id,
                  primaryLocation: user.primaryLocation,
                  channels,
                }}
              />
            </div>
          )}

          <TabStrip
            disabled={isLoading}
            tabs={availableTabs}
            selected={
              selectedTab
                ? TabsMap[selectedTab.toLowerCase() as keyof typeof TabsMap]
                : null
            }
            onSelectTab={tab => goToUrl({ tab: tab.value })}
          />
          <div className={styles.main}>
            {selectedTab
              ? TabsMap[selectedTab.toLowerCase() as keyof typeof TabsMap]
                  .Component
              : null}
          </div>
        </div>
      )}
    </AdminPage>
  );
}

export default ChannelAdminMember;

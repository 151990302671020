import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { NotFoundError } from 'activate-errors';

const PROVIDERS = {
  HID_2_2: 'HID_2_2',
  CCURE_2_8__2_9: 'CCURE_2_8__2_9',
  HID_3_0: 'HID_3_0',
  BRAXOS_1_0: 'BRAXOS_1_0',
  GENEA_2_0: 'GENEA_2_0',
  OPENPATH_1_0: 'OPENPATH_1_0',
  BRIVO_1_0: 'BRIVO_1_0',
  MAXXESS_6_0: 'MAXXESS_6_0',
  GALLAGHER_8_9: 'GALLAGHER_8_9',
  DSX_6_0: 'DSX_6_0',
  GENETEC_5_9: 'GENETEC_5_9',
  SIPASS_1_0: 'SIPASS_1_0',
  SALTO_SVN_1_35: 'SALTO_SVN_1_35',
  KASTLE: 'KASTLE_1_0',
};

export const queryCCureConfigMetadata = gql`
  query getConfigMetadataForCCure(
    $channelId: UUID!
    $provider: String!
    $username: String
    $password: String
    $clientVersion: String
    $apiUrl: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      username: $username
      password: $password
      clientVersion: $clientVersion
      apiUrl: $apiUrl
    ) {
      data
    }
  }
`;

export const queryGeneaConfigMetadata = gql`
  query getConfigMetadataForGenea(
    $channelId: UUID!
    $provider: String!
    $apiKey: String
    $locationUuid: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      apiKey: $apiKey
      locationUuid: $locationUuid
    ) {
      data
    }
  }
`;

export const queryBraxosConfigMetadata = gql`
  query getConfigMetadataForBraxos(
    $channelId: UUID!
    $provider: String!
    $connectionId: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      connectionId: $connectionId
    ) {
      data
    }
  }
`;

export const queryOpenpathConfigMetadata = gql`
  query getConfigMetadataForOpenpath(
    $channelId: UUID!
    $provider: String!
    $serverUrl: String
    $username: String
    $password: String
    $organizationId: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      serverUrl: $serverUrl
      username: $username
      password: $password
      organizationId: $organizationId
    ) {
      data
    }
  }
`;

export const queryBrivoConfigMetadata = gql`
  query getConfigMetadataForBrivo(
    $channelId: UUID!
    $provider: String!
    $username: String
    $password: String
    $apiKey: String
    $clientId: String
    $clientSecret: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      username: $username
      password: $password
      apiKey: $apiKey
      clientId: $clientId
      clientSecret: $clientSecret
    ) {
      data
    }
  }
`;

export const queryMaxxessConfigMetadata = gql`
  query getConfigMetadataForMaxxess(
    $channelId: UUID!
    $provider: String!
    $username: String
    $password: String
    $serverUrl: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      username: $username
      password: $password
      serverUrl: $serverUrl
    ) {
      data
    }
  }
`;

export const queryGallagherConfigMetadata = gql`
  query getConfigMetadataForGallagher(
    $channelId: UUID!
    $provider: String!
    $apiUrl: String
    $apiKey: String
    $clientCertificate: String
    $privateKey: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      apiUrl: $apiUrl
      apiKey: $apiKey
      clientCertificate: $clientCertificate
      privateKey: $privateKey
    ) {
      data
    }
  }
`;

export const queryDsxConfigMetadata = gql`
  query getConfigMetadataForDsx(
    $channelId: UUID!
    $provider: String!
    $username: String
    $password: String
    $serverUrl: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      username: $username
      password: $password
      serverUrl: $serverUrl
    ) {
      data
    }
  }
`;

export const queryGenetecConfigMetadata = gql`
  query getConfigMetadataForGenetec(
    $channelId: UUID!
    $provider: String!
    $username: String
    $password: String
    $serverUrl: String
    $cardholderGroupsFilterEnabled: Boolean
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      username: $username
      password: $password
      serverUrl: $serverUrl
      cardholderGroupsFilterEnabled: $cardholderGroupsFilterEnabled
    ) {
      data
    }
  }
`;

export const queryHIDConfigMetadata = gql`
  query getConfigMetadataForHID(
    $channelId: UUID!
    $provider: String!
    $clientId: String
    $clientSecret: String
    $organizationId: String
    $applicationId: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      clientId: $clientId
      clientSecret: $clientSecret
      organizationId: $organizationId
      applicationId: $applicationId
    ) {
      data
    }
  }
`;

export const querySaltoSvnConfigMetadata = gql`
  query getConfigMetadataForSaltoSvn(
    $channelId: UUID!
    $provider: String!
    $serverUrl: String
    $username: String
    $password: String
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      serverUrl: $serverUrl
      username: $username
      password: $password
    ) {
      data
    }
  }
`;

export const queryKastleConfigMetadata = gql`
  query getConfigMetadataForKastle(
    $channelId: UUID!
    $provider: String!
    $visitorConnectUsername: String
    $visitorConnectPassword: String
    $visitorConnectOwnerGuid: String
    $visitorManagementEnabled: Boolean
  ) {
    getConfigMetadata(
      channelId: $channelId
      provider: $provider
      visitorConnectUsername: $visitorConnectUsername
      visitorConnectPassword: $visitorConnectPassword
      visitorConnectOwnerGuid: $visitorConnectOwnerGuid
      visitorManagementEnabled: $visitorManagementEnabled
    ) {
      data
    }
  }
`;

export async function getProviderMetadata(
  provider: string,
  channelId: string,
  settings: any
) {
  let result;
  try {
    if (provider === PROVIDERS.HID_2_2) {
      result = await getClient().query({
        query: queryHIDConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          clientId: settings.clientId,
          clientSecret: settings.clientSecret,
          organizationId: settings.organizationId,
          applicationId: settings.applicationId,
        },
      });
    }

    if (provider === PROVIDERS.HID_3_0) {
      result = await getClient().query({
        query: queryHIDConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          clientId: settings.clientId,
          clientSecret: settings.clientSecret,
          organizationId: settings.organizationId,
          applicationId: settings.applicationId,
        },
      });
    }

    if (provider === PROVIDERS.CCURE_2_8__2_9) {
      result = await getClient().query({
        query: queryCCureConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          username: settings.username,
          password: settings.password,
          clientVersion: settings.clientVersion?.value,
          apiUrl: settings.apiUrl,
        },
      });
    }

    if (provider === PROVIDERS.GENEA_2_0) {
      result = await getClient().query({
        query: queryGeneaConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          apiKey: settings.apiKey,
          locationUuid: settings.locationUuid?.value,
        },
      });
    }

    if (
      provider === PROVIDERS.BRAXOS_1_0 ||
      provider === PROVIDERS.SIPASS_1_0
    ) {
      result = await getClient().query({
        query: queryBraxosConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          connectionId: settings.connectionId,
        },
      });
    }

    if (provider === PROVIDERS.OPENPATH_1_0) {
      result = await getClient().query({
        query: queryOpenpathConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          serverUrl: settings.serverUrl,
          username: settings.username,
          password: settings.password,
          organizationId: settings.organizationId,
        },
      });
    }

    if (provider === PROVIDERS.BRIVO_1_0) {
      result = await getClient().query({
        query: queryBrivoConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          username: settings.username,
          password: settings.password,
          apiKey: settings.apiKey,
          clientId: settings.clientId,
          clientSecret: settings.clientSecret,
        },
      });
    }

    if (provider === PROVIDERS.MAXXESS_6_0) {
      result = await getClient().query({
        query: queryMaxxessConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          username: settings.username,
          password: settings.password,
          serverUrl: settings.serverUrl,
        },
      });
    }

    if (provider === PROVIDERS.GALLAGHER_8_9) {
      result = await getClient().query({
        query: queryGallagherConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          apiUrl: settings.apiUrl,
          apiKey: settings.apiKey,
          clientCertificate: settings.clientCertificate,
          privateKey: settings.privateKey,
        },
      });
    }

    if (provider === PROVIDERS.DSX_6_0) {
      result = await getClient().query({
        query: queryDsxConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          username: settings.username,
          password: settings.password,
          serverUrl: settings.serverUrl,
        },
      });
    }

    if (provider === PROVIDERS.GENETEC_5_9) {
      result = await getClient().query({
        query: queryGenetecConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          username: settings.username,
          password: settings.password,
          serverUrl: settings.serverUrl,
          cardholderGroupsFilterEnabled: settings.cardholderGroupsFilterEnabled,
        },
      });
    }

    if (provider === PROVIDERS.SALTO_SVN_1_35) {
      result = await getClient().query({
        query: querySaltoSvnConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          serverUrl: settings.serverUrl,
          username: settings.username,
          password: settings.password,
        },
      });
    }

    if (provider === PROVIDERS.KASTLE) {
      result = await getClient().query({
        query: queryKastleConfigMetadata,
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        variables: {
          channelId,
          provider,
          visitorConnectUsername: settings.visitorConnectUsername,
          visitorConnectPassword: settings.visitorConnectPassword,
          visitorConnectOwnerGuid: settings.visitorConnectOwnerGuid,
          visitorManagementEnabled: settings.visitorManagementEnabled,
        },
      });
    }

    if (result?.errors && result?.errors?.length > 0) {
      return Promise.reject(result?.errors[0]);
    }

    const metadata = result?.data?.getConfigMetadata?.data;

    if (!metadata) {
      return Promise.reject(new NotFoundError());
    }

    return metadata;
  } catch (err) {
    Promise.reject(err);
  }
}

import * as formatters from '../../helpers/formatters';
import Types from '../../properties/Types';
import Features from './features';

export default function ({
  input,
  dest,
  props = {},
  state = {},
  features = {},
  data = {},
  propDefs = {},
  editMode,
  dataDefs = {},
  $,
}: any) {
  if (dest.op) {
    if (!dest.function) {
      // eslint-disable-next-line no-new-func
      dest.function = new Function(
        'input',
        'dest',
        'props',
        'state',
        'features',
        'data',
        'propDefs',
        'editMode',
        'dataDefs',
        '$',
        'formatters',
        `"use strict"; return (${dest.op});`
      );
    }

    let returnValue = null;

    try {
      returnValue = dest.function(
        input,
        dest,
        props,
        state,
        features,
        data,
        propDefs,
        editMode,
        dataDefs,
        $,
        formatters
      );
    } catch (err) {
      // if an error is thrown by the custom javascript for setting, it is
      // better to catch it and set null then to crash the whole application
      // because there is no way to surface this error the end user we
      // have to fail silently here.
    }

    return returnValue;
  }

  let source;
  let def; // eslint-disable-line no-unused-vars
  const parts = dest.name.split('.');

  switch (dest.source) {
    case 'feature':
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      def = Features[dest.featureName].interactionData[parts[0]];
      if (!features[dest.featureName]) {
        features[dest.featureName] = {};
      }
      source = features[dest.featureName];
      break;
    case 'props':
      def = propDefs[parts[0]];
      source = props;
      break;
    case 'data':
      def = dataDefs[parts[0]];
      source = data;
      break;
    case 'state':
    default:
      console.warn(`${dest.source} not yet implemented as a source.`);
      return null;
  }

  const type = Types.getType(def.type);

  let subDest = source;
  const name = parts[parts.length - 1];

  for (let i = 0; i < parts.length - 2; i++) {
    if (!subDest) {
      break;
    }

    subDest = subDest[parts[i]];
  }

  let serialized = input;

  if (type.serialize) {
    try {
      serialized = type.serialize(input);
    } catch (err) {
      console.log(err);
    }
  }

  subDest[name] = serialized;
}

import React from 'react';

import { Button } from 'components';
import { Trans, useTranslation } from 'react-i18next';

import { H4, S } from 'components/typography';

import styles from './LoginMagicLinkSent.scss';

const TRANSLATION_KEYS = {
  title: 'web.login.magicLink.sent.title',
  description: 'web.login.magicLink.sent.description',
  sentMessage: 'web.login.magicLink.sent.message',
  resendButton: 'web.login.magicLink.sent.resendButton',
};

type Props = {
  isLoading: boolean;
  userEmail: string;
  onResend: () => void;
};

function LoginMagicLinkSent({ isLoading, userEmail, onResend }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.titleContainer}>
        <H4 mb={4}>{t(TRANSLATION_KEYS.title)}</H4>
      </div>
      <S>
        <Trans
          i18nKey={TRANSLATION_KEYS.description}
          values={{
            email: userEmail,
          }}
        />
      </S>
      <br />
      <br />
      <S>{t(TRANSLATION_KEYS.sentMessage)}</S>
      <div className={styles.buttonContainer}>
        <Button
          interfaceStyle="light"
          variant="outlined"
          color="inherit"
          type="button"
          size="large"
          onClick={onResend}
          loading={isLoading}
        >
          {t(TRANSLATION_KEYS.resendButton)}
        </Button>
      </div>
    </>
  );
}

export default LoginMagicLinkSent;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Dropdown, Label, ValidationMessage } from 'lane-web/src/components';

import styles from './AssignedMemberDropdown.scss';

function AssignedMemberDropdown({
  users,
  setAssignedMember,
  assignedMember,
  label,
  error,
  disabled,
  hideLabel = false,
  doTranslate = true,
  placeholder,
  testId,
}: any) {
  const { t } = useTranslation();

  const displayedLabel = label ?? t('web.admin.serviceRequest.assignee');

  return (
    <div className={styles.AssignedMemberDropdown}>
      {!hideLabel && <Label mt={0}>{displayedLabel}</Label>}
      <Dropdown
        disabled={disabled}
        isSearchable
        value={assignedMember?.value}
        items={users || []}
        onChange={(value: any) => {
          setAssignedMember(value);
        }}
        placeholder={placeholder}
        testId={testId}
        doTranslation={doTranslate}
      />
      {error && (
        <ValidationMessage
          className={styles.ErrorMessage}
          errors={error}
          withoutIcon
        />
      )}
    </div>
  );
}

export default AssignedMemberDropdown;

import React from 'react';

import { VisitorType } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types/VisitTypes';

import { StatusPill } from './StatusPill';

import styles from './VisitorCard.scss';

type Props = {
  visitor: VisitorType;
};

export const VisitorCard = ({ visitor }: Props) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.info}>
        <span className={styles.name}>
          {' '}
          {visitor.first_name} {visitor.last_name}{' '}
        </span>
        <span className={styles.extraInfo}> {visitor.email} </span>
        <span className={styles.extraInfo}> {visitor.phone} </span>
      </div>
      <div>
        <span>
          {' '}
          <StatusPill visitStatus={visitor.status!} />{' '}
        </span>
      </div>
    </div>
  );
};

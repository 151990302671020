import { StyleSheet } from '@react-pdf/renderer';

import { spacing, colors } from 'lane-shared/config';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    margin: spacing[5],
  },
  line: {
    marginTop: spacing[3],
    marginLeft: spacing[2],
  },
  header: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 15,
    marginBottom: spacing[4],
    margin: spacing[2],
  },
  subheadings: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: 'heavy',
    margin: spacing[2],
  },
  subHeadingValues: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 'extrabold',
    color: 'rgb(128,128,128)',
  },
  serviceRequestDescriptionText: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 'extrabold',
  },
  pageNumber: {
    fontFamily: 'Helvetica',
    position: 'absolute',
    fontSize: 12,
    bottom: spacing[7],
    left: 0,
    right: spacing[7],
    textAlign: 'right',
    color: colors.neutral.grey700,
  },
  serviceRequestCustomData: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    marginLeft: spacing[2],
    marginTop: spacing[1],
  },
  serviceRequestCustomDataValue: {
    fontFamily: 'Helvetica',
    fontSize: 10,
    fontWeight: 'extrabold',
    marginLeft: spacing[2],
    marginTop: spacing[1],
    color: 'rgb(128,128,128)',
  },
});

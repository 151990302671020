import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { TabForm, TabCenter } from './components';

export default function TabCenterRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={routes.channelAdminTabs}
        render={() => <TabCenter />}
      />

      <Route
        exact
        path={routes.channelAdminTabsNew}
        render={() => <TabForm formFor="create" />}
      />

      <Route
        exact
        path={routes.channelAdminTabsEdit}
        render={({ match }) => (
          <TabForm formFor="edit" channelPageId={match.params.tabId} />
        )}
      />
    </Switch>
  );
}

import React from 'react';

import { Radio } from 'components';
import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { H3, P } from 'components/typography';

import { DATA_SOURCES } from 'pages/portal/admin/channel/children/auto-setup-wizard/constants';
import {
  DataSourceType,
  DataSourceEnum,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import { RiseMigration } from './sources/RiseMigration';
import { SFTP } from './sources/SFTP';
import { UploadCSV } from './sources/UploadCSV';

import styles from './styles.scss';
import { SourceLocation, SourceLocations } from './types';

export type WizardStepOneParams = {
  onChangeDataSource: (value: DataSourceEnum) => void;
  onAddDataSourceLocation: () => void;
  onChangeDataSourceLocation: (sourceLocation: SourceLocation) => void;
  onDeleteSourceLocation: (index: number) => void;
  currentValue: {
    dataSource: DataSourceEnum | null;
    sourceLocations: SourceLocations;
  };
  loading: boolean;
  setLoading: (state: boolean) => void;
};

export function WizardStepOne(selectSourceParams: WizardStepOneParams) {
  const { t } = useTranslation();
  const csvUploadEnabled = useFlag<boolean>(
    FeatureFlag.CSVUploadsAutoSetup,
    false
  );
  const riseMigrationEnabled = useFlag<boolean>(
    FeatureFlag.RiseMigrationAutoSetup,
    false
  );
  const cesMigrationEnabled = useFlag<boolean>(FeatureFlag.CESAutoSetup, false);

  const { onChangeDataSource, currentValue } = selectSourceParams;
  const { dataSource } = currentValue;

  function getIsRadioDisabled({ value }: DataSourceType) {
    if (value === DataSourceEnum.CSV) {
      return !csvUploadEnabled;
    }
    if (value === DataSourceEnum.RISE) {
      return !riseMigrationEnabled;
    }

    if (value === DataSourceEnum.CES) {
      return !cesMigrationEnabled;
    }

    return false;
  }

  function getComponentForDataSource(dataSource: DataSourceEnum) {
    switch (dataSource) {
      case DataSourceEnum.CSV:
        return <UploadCSV {...selectSourceParams} />;
      case DataSourceEnum.sFTP:
        return <SFTP {...selectSourceParams} />;
      case DataSourceEnum.RISE:
        return <RiseMigration {...selectSourceParams} />;
      default:
        return null;
    }
  }

  return (
    <section className={styles.Section} data-test="wizard-select-source-page">
      <H3>{t`web.admin.channel.autoSetupWizard.selectASource`}</H3>
      <P mb={2}>
        {t`web.admin.channel.autoSetupWizard.selectASourceDescription`}
      </P>
      {DATA_SOURCES.map((sourceParams: DataSourceType) => {
        const { value, text } = sourceParams;
        const disabled = getIsRadioDisabled(sourceParams);

        return (
          <span key={`wrapper-${value}`}>
            <Radio<DataSourceEnum>
              {...{ value, text, disabled }}
              key={`radio-${value}`}
              selected={dataSource ?? undefined}
              onChange={(value: DataSourceEnum) => onChangeDataSource(value)}
              className={styles.Radio}
            />
            {value === dataSource && getComponentForDataSource(dataSource)}
          </span>
        );
      })}
    </section>
  );
}

import React from 'react';

import { toSchema } from 'lane-shared/helpers';

import { CONTENT_CATEGORIES } from 'lane-shared/types/content/ContentCategoryEnum';
import { Flex } from 'components/layout';
import { useTranslation } from 'react-i18next';

import MultiselectField from 'components/form/MultiselectField';
import type { SectionType } from 'lane-shared/types/sections/SectionType';

import Radio from 'components/form/Radio';
import type { ContentTag } from 'lane-shared/types/content/ContentTag';
import styles from './styles.scss';

const CONTENT_CATEGORIES_WITH_SELECT_ALL = [
  ...CONTENT_CATEGORIES,
  {
    label: 'web.admin.section.contentTypes.selectAll',
    value: 'All',
  },
];

type TagsAndCategoriesMultiselectProps = {
  isContentTagsSelected: boolean;
  setIsContentTagsSelected: (value: boolean) => void;
  onSectionUpdated: (data: Partial<SectionType>) => void;
  section: SectionType;
  tagsLoading: boolean;
  tags: ContentTag[];
};

const CONTENT_TAGS_SELECTED = 'contentTags';
const CONTENT_CATEGORIES_SELECTED = 'contentCategories';

export const TagsAndCategoriesMultiselect = ({
  isContentTagsSelected,
  setIsContentTagsSelected,
  onSectionUpdated,
  section,
  tagsLoading,
  tags,
}: TagsAndCategoriesMultiselectProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex justify="space-between">
        <Radio
          selected={
            isContentTagsSelected
              ? CONTENT_TAGS_SELECTED
              : CONTENT_CATEGORIES_SELECTED
          }
          value={CONTENT_TAGS_SELECTED}
          name="contentWithTags"
          onChange={() => {
            setIsContentTagsSelected(true);
            onSectionUpdated({
              query: {
                ...section.query,
                contentCategories: [],
              },
            });
          }}
          doTranslate={false}
          text={t('web.admin.section.contentTags')}
        />
        <MultiselectField
          placeholder={t('web.admin.section.selectTags')}
          disabled={!isContentTagsSelected}
          testId="tagSelect"
          isLoading={tagsLoading}
          items={tags?.map(({ id, name }: ContentTag) => ({
            value: id,
            label: name,
          }))}
          value={section.tagsOnSection?.map(
            ({ id, name }: Partial<ContentTag>) => ({
              value: id,
              label: name,
            })
          )}
          className={styles.multiselect}
          onChange={contentTags => {
            onSectionUpdated({
              tagsOnSection: contentTags.map(({ label, value }) => ({
                id: value,
                name: label,
              })),
            });
          }}
          doTranslation={false}
        />
      </Flex>

      <Flex justify="space-between" m={[3, 0, 0, 0]}>
        <Radio
          selected={
            isContentTagsSelected
              ? CONTENT_TAGS_SELECTED
              : CONTENT_CATEGORIES_SELECTED
          }
          value={CONTENT_CATEGORIES_SELECTED}
          name="contentWithCategories"
          onChange={() => {
            setIsContentTagsSelected(false);
            onSectionUpdated({
              query: {
                ...section.query,
              },
              tagsOnSection: [],
            });
          }}
          text={t('web.admin.section.contentCategories')}
          doTranslate={false}
        />
        <MultiselectField
          disabled={isContentTagsSelected}
          placeholder={t('web.admin.section.selectCategories')}
          testId="categorySelect"
          items={CONTENT_CATEGORIES_WITH_SELECT_ALL}
          value={section.query!.contentCategories?.map(toSchema)}
          className={styles.multiselect}
          onChange={contentCategories => {
            if (contentCategories.some(c => c.value === 'All')) {
              contentCategories = CONTENT_CATEGORIES;
            }

            onSectionUpdated({
              query: {
                ...section.query,
                contentCategories: contentCategories.map(({ value }) => value),
              },
            });
          }}
        />
      </Flex>
    </>
  );
};

export const CategoriesMultiselect = ({
  isContentTagsSelected,
  onSectionUpdated,
  section,
}: Pick<
  TagsAndCategoriesMultiselectProps,
  'isContentTagsSelected' | 'onSectionUpdated' | 'section'
>) => {
  const { t } = useTranslation();

  return (
    <MultiselectField
      disabled={isContentTagsSelected}
      placeholder={t('Select categories')}
      testId="categorySelect"
      items={CONTENT_CATEGORIES_WITH_SELECT_ALL}
      value={section.query!.contentCategories?.map(toSchema)}
      className={styles.multiselect}
      onChange={contentCategories => {
        if (contentCategories.some(c => c.value === 'All')) {
          contentCategories = CONTENT_CATEGORIES;
        }

        onSectionUpdated({
          query: {
            ...section.query,
            contentCategories: contentCategories.map(({ value }) => value),
          },
        });
      }}
    />
  );
};

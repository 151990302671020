// TODO: Remove these once custom statuses are introduced
export enum TaskStatusEnum {
  Created = 'Created',
  InProgress = 'In Progress',
  OnHold = 'On Hold',
  Withdrawn = 'Withdrawn',
  Completed = 'Completed',
}

export const ACTIVE_TASK_STATUSES = [
  TaskStatusEnum.Created,
  TaskStatusEnum.InProgress,
  TaskStatusEnum.OnHold,
];

export const INACTIVE_TASK_STATUSES = [
  TaskStatusEnum.Withdrawn,
  TaskStatusEnum.Completed,
];

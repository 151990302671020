import React, { useState } from 'react';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';
import { H5 } from 'components/typography';
import type { Tag, NewTagData } from './types';
import type { ChannelType } from 'lane-shared/types/ChannelType';
import { Button } from 'design-system-web';
import Tooltip from 'components/general/Tooltip';
import { MultiLanguageWrapper } from 'components/general/MultiLanguageWrapper';

import styles from './Tags.scss';

export const TagForm = ({
  channel,
  tag,
  saveAction,
}: {
  channel: ChannelType;
  tag: NewTagData | Tag;
  saveAction: (tag: NewTagData) => void;
}) => {
  const { t } = useTranslation();

  const [tagData, setTag] = useState<NewTagData>(tag);

  const creating = 'id' in tag;

  const nameInput = ({
    disabled = false,
    valueGetter,
    onChangeUpdates,
    languageFriendlyName,
  }: {
    disabled?: boolean;
    valueGetter: () => string;
    onChangeUpdates: (any: any) => any;
    languageFriendlyName?: string;
  }) => (
    <Input
      disabled={disabled}
      testId={`tagNameInput-${languageFriendlyName}`}
      placeholder="Tag Name"
      value={valueGetter()}
      onChange={name => {
        const updates = onChangeUpdates(name);
        setTag({
          ...tagData,
          ...updates,
        });
      }}
    />
  );

  const disabledNameInput = ({
    valueGetter,
    onChangeUpdates,
    languageFriendlyName,
  }: {
    onChangeUpdates: (any: any) => any;
    valueGetter: () => string;
    languageFriendlyName?: string;
  }) => (
    <Tooltip
      placement="top"
      TooltipComponent={t(
        'web.pages.portal.admin.library.tags.update.disableNameReason'
      )}
    >
      {nameInput({
        disabled: true,
        valueGetter,
        onChangeUpdates,
        languageFriendlyName,
      })}
    </Tooltip>
  );

  return (
    <div>
      <MultiLanguageWrapper channel={channel} model={tagData} column="name">
        {({
          labelMaker,
          onChangeUpdates,
          valueGetter,
          isPrimary,
          languageFriendlyName,
        }) => (
          <>
            <H5 className={styles.fieldLabel}>
              {labelMaker({
                label: t('Name'),
                required: true,
              })}
            </H5>
            {creating && isPrimary
              ? disabledNameInput({
                  valueGetter,
                  onChangeUpdates,
                  languageFriendlyName,
                })
              : nameInput({
                  valueGetter,
                  onChangeUpdates,
                  languageFriendlyName,
                })}
          </>
        )}
      </MultiLanguageWrapper>
      <Button
        onClick={() => saveAction(tagData)}
        size="large"
        variant="primary"
        testId="tagFormSubmitButton"
      >
        {creating ? t('Update') : t('Create')}
      </Button>
    </div>
  );
};

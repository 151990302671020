import React, { ReactNode } from 'react';

import styles from './UserIdentificationAndOnboarding.scss';

import fallbackLogo from 'static/img/vts-logo.png';

interface OnboardingPageTemplateProps {
  name: string;
  logo: string;
  children: ReactNode;
}

export const UserIdentificationAndOnboardingTemplate = ({
  name,
  logo,
  children,
}: OnboardingPageTemplateProps) => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <img src={logo || fallbackLogo} alt={name} className={styles.logo} />
        {children}
      </div>
    </div>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import styles from './AcessControllMapping.scss';
import unlockIcon from '../../../../static/img/unlock-icon.svg';
import MultiselectField from 'components/form/MultiselectField';
import { Button, Icon } from 'design-system-web';
import type { ChannelIntegrationEditorProps } from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import { useTranslation } from 'react-i18next';
import { ValidationErrorContext } from 'lane-shared/contexts';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { getBuildingTenantChannels } from 'lane-shared/graphql/query';
import { useLazyQuery } from '@apollo/client';
import {
  AccessControlServiceEntity,
  IntegrationPropertyType,
} from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import { convertToUUID } from 'lane-shared/helpers/convertId';

type TenantKeyEnum = 'geneaTenantIDs' | 'vtsTenantIDs';

type PropertyProps = {
  property: IntegrationPropertyType;
  label?: string;
  onChange: (value: any) => void;
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  forCreate?: boolean;
  metadataLoading?: boolean;
  propertyKey: string;
  tenantKey: TenantKeyEnum;
  acsProviderValue: string | undefined;
  channel: ChannelIntegrationEditorProps['channel'];
};

interface Option {
  label: string;
  value: string;
}

interface Group {
  accessGroupIDs: Option[] | any[];
  [key: string]: Option[] | any[] | undefined;
}

export const AccessControlMapping = ({
  property,
  label,
  onChange,
  settings,
  metadataLoading,
  propertyKey,
  tenantKey,
  acsProviderValue,
  channel,
}: PropertyProps) => {
  const { t } = useTranslation();

  const [getBuildingTenantChannelList] = useLazyQuery(
    getBuildingTenantChannels
  );

  const [ACGGroup, setACGGroup] = useState<Group[]>([]);
  const [ACGList, setACGList] = useState([]);
  const [IGSTenantList, setIGSTenantList] = useState([]);

  const acgEnhancementEnabled = useFlag(
    FeatureFlag.TenantConfigurationEnhancements,
    false
  );

  const errors = useContext(ValidationErrorContext);
  const acgMappedError = errors?.inner?.find(
    error => error?.path === propertyKey
  );

  const initialGroup = {
    accessGroupIDs: [],
    [tenantKey]: [],
  };

  useEffect(() => {
    if (!ACGGroup?.length) {
      ACGGroup.push(initialGroup);
      setACGGroup(ACGGroup);
      if (!settings?.visitorAccessGroupMappings?.length) {
        onChange([initialGroup]);
      }
    }
  }, []);

  const fetchTenantList = async () => {
    if (!metadataLoading) {
      setACGList(property.accessGroupIDs);
      // For genetec and sipass, calling getBuildingTenantChannelList query when enabled visitor management toggle
      if (
        acsProviderValue === AccessControlServiceEntity.Genetec ||
        acsProviderValue === AccessControlServiceEntity.Sipass
      ) {
        const buildingTenantData = await getBuildingTenantChannelList({
          variables: { channelId: convertToUUID(channel._id) },
        });
        if (buildingTenantData?.data?.getBuildingTenantChannels) {
          const buildingTenantList = buildingTenantData.data.getBuildingTenantChannels.map(
            (tenant: { channelId: string; name: string }) => ({
              label: `${tenant.name}`,
              value: convertToUUID(`${tenant.channelId}`),
            })
          );
          setIGSTenantList(buildingTenantList);
        }
      } else {
        setIGSTenantList(property[tenantKey]);
      }
    }
  };

  useEffect(() => {
    fetchTenantList();
  }, [metadataLoading, settings.locationUuid]);

  useEffect(() => {
    const mappedGroups = settings?.visitorAccessGroupMappings?.map(
      (group: { [x: string]: string[]; accessGroupIDs: string[] }) => ({
        accessGroupIDs: group?.accessGroupIDs?.map((id: string) => {
          const groupObject: Option | any = ACGList?.find(
            (item: Option) => item?.value === id
          );
          return { label: groupObject?.label, value: id };
        }),
        [tenantKey]: group?.[tenantKey]?.map((id: string) => {
          const tenantObject: Option | any = IGSTenantList?.find(
            (item: Option) => convertToUUID(item?.value) === convertToUUID(id)
          );
          return { label: tenantObject?.label, value: convertToUUID(id) };
        }),
      })
    );
    if (mappedGroups?.length) {
      setACGGroup(mappedGroups);
    }
  }, [metadataLoading, settings?.visitorAccessGroupMappings]);

  function removeIgsTenantIdsFromACG(igsValue: string) {
    const updatedVisitorAccessGroupMappings = settings.visitorAccessGroupMappings.map(
      (mapping: any) => {
        // Filter out the igsValue from geneaTenantIDs
        const filteredGeneaTenantIDs = mapping.geneaTenantIDs.filter(
          (geneaTenantID: string) => geneaTenantID !== igsValue
        );

        // Return a new mapping object with the updated geneaTenantIDs
        return {
          ...mapping,
          geneaTenantIDs: filteredGeneaTenantIDs,
        };
      }
    );
    return updatedVisitorAccessGroupMappings;
  }

  useEffect(() => {
    if (acgEnhancementEnabled) {
      const count = settings?.tenantMappings?.length;
      if (count > 0) {
        const updatedVisitorAccessGroupMappings = removeIgsTenantIdsFromACG(
          settings?.tenantMappings[count - 1]?.prevIgsValue
        );
        onChange(updatedVisitorAccessGroupMappings);
      }
    }
  }, [acgEnhancementEnabled, settings?.tenantMappings]);

  const handleMultiSelectDropdownChanges = (
    selectedOptions: any,
    id: number,
    key: string
  ) => {
    const updatedGroups = [...ACGGroup];
    updatedGroups[id] = {
      ...updatedGroups[id],
      [key]: selectedOptions,
    };
    setACGGroup(updatedGroups);

    const AccessMappingPayload: Group[] = [];

    updatedGroups.forEach(group => {
      AccessMappingPayload.push({
        accessGroupIDs:
          group?.accessGroupIDs?.map(({ value }: Option) => value) || [],
        [tenantKey]: group[tenantKey]?.map(({ value }: Option) => value) || [],
      });
    });
    onChange(AccessMappingPayload);
  };

  const handleAddGroup = () => {
    const groupData = ACGGroup?.length ? [...ACGGroup] : [];
    groupData.push(initialGroup);
    setACGGroup(groupData);

    const AccessMappingPayload: Group[] = [];
    groupData.forEach(group => {
      AccessMappingPayload.push({
        accessGroupIDs:
          group.accessGroupIDs?.map(({ value }: Option) => value) || [],
        [tenantKey]: group[tenantKey]?.map(({ value }: Option) => value) || [],
      });
    });
    onChange(AccessMappingPayload);
  };

  const handleDeleteGroup = (id: number) => {
    const groupData = [...ACGGroup];
    groupData.splice(id, 1);
    setACGGroup(groupData);

    const AccessMappingPayload: Group[] = [];

    groupData.forEach(group => {
      AccessMappingPayload.push({
        accessGroupIDs:
          group.accessGroupIDs?.map(({ value }: Option) => value) || [],
        [tenantKey]: group[tenantKey]?.map(({ value }: Option) => value) || [],
      });
    });
    onChange(AccessMappingPayload);
  };

  return (
    <div className={styles.accessControlMapping}>
      <div className={styles.parentTitle}>{label}</div>
      {ACGGroup?.map((item, index) => {
        const acgError = errors?.inner?.find(
          error => error?.path === `${propertyKey}[${index}].accessGroupIDs`
        );
        const tenantError = errors?.inner?.find(
          error => error?.path === `${propertyKey}[${index}].${tenantKey}`
        );

        return (
          <div key={index} data-test={`group-${index}`}>
            <div className={styles.mappingBox}>
              <div className={styles.innerBox}>
                <img src={unlockIcon as string} alt="unlockIcon" />
                <div className={styles.group}>Access control group</div>
              </div>
              <div className={styles.mt24}>
                <div className={styles.dropdownLabel}>
                  Access control groups
                </div>
                <MultiselectField
                  errors={acgError?.errors}
                  isFullWidth
                  key={index}
                  value={ACGList?.length ? item?.accessGroupIDs : []}
                  name="ACG"
                  testId={`ACG-${index}`}
                  items={ACGList}
                  onChange={selectedValue => {
                    handleMultiSelectDropdownChanges(
                      selectedValue,
                      index,
                      'accessGroupIDs'
                    );
                  }}
                  placeholder="Select 1 or more groups"
                />
              </div>
              <div className={styles.mt24}>
                <div className={styles.dropdownLabel}>
                  Apply to these tenants
                </div>
                <MultiselectField
                  isFullWidth
                  name="IGS"
                  testId={`Tenant-${index}`}
                  value={IGSTenantList?.length ? item[tenantKey] : []}
                  errors={tenantError?.errors}
                  items={IGSTenantList}
                  onChange={selectedValue =>
                    handleMultiSelectDropdownChanges(
                      selectedValue,
                      index,
                      tenantKey
                    )
                  }
                  placeholder="Select 1 or more tenants"
                />
              </div>
              <div className={styles.mt24}>
                <Button
                  className={styles.deleteButton}
                  variant="text"
                  size="medium"
                  onClick={() => handleDeleteGroup(index)}
                  testId={`acg-delete-${index}`}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        );
      })}
      <div className={styles.mt24}>
        <Button
          startIcon={<Icon name="plus" />}
          variant="secondary"
          size="medium"
          testId="addProductServiceButton"
          onClick={handleAddGroup}
        >
          {ACGGroup?.length > 0
            ? 'Add another group'
            : 'Add access control group'}
        </Button>
        {acgMappedError?.message && (
          <p className={styles.error} data-test="errorMessage">
            {t(acgMappedError?.message)}
          </p>
        )}
      </div>
    </div>
  );
};

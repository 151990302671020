import React, { useContext } from 'react';

import { Route, Switch } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { COMMUNITY_CHANNEL } from 'lane-shared/helpers/constants/ids';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { NavLinks } from 'components/general';
import TemplateEdit from 'components/lane/TemplateEdit';

import AddSubscription from './AddSubscription';
import { BillingAndPayments } from './BillingAndPayments';
import PrivacyAndTermsAndConditions from './PrivacyAndTermsAndConditions';
import RecentActivity from './RecentActivity';
import Settings from './Settings';
import SwitchSubscription from './SwitchSubscription';
import MyMedia from './media';
import MediaEdit from './media/edit';
import profileRoutes, { privacyAndSubscriptionRoutes } from './profileRoutes';
import MyTemplates from './templates';
import MyTemplateNew from './templates/new';

import styles from './styles.scss';

export default function MyProfile() {
  const { user } = useContext(UserDataContext);
  const isPrivacyPolicyAndTermsEnabled = useFlag(
    FeatureFlag.PrivacyPolicyAndTermsEnabled,
    true
  );

  const menuItems = [...profileRoutes];

  if (isPrivacyPolicyAndTermsEnabled) {
    menuItems.push(...privacyAndSubscriptionRoutes);
  }

  return (
    <div className={styles.MyProfile}>
      <NavLinks routes={menuItems} />
      <div className={styles.body}>
        <Switch>
          <Route exact path={routes.me} component={Settings} />
          {/* QR code is in Settings */}
          <Route exact path={routes.meQRCode} component={Settings} />

          <Route
            exact
            path={routes.meMedia}
            render={({ history }) => <MyMedia user={user} history={history} />}
          />

          <Route
            exact
            path={routes.meMediaEdit}
            render={({ history, match }) => (
              <MediaEdit match={match} history={history} />
            )}
          />

          <Route
            exact
            path={routes.meTemplates}
            render={({ history }) => (
              <MyTemplates user={user} history={history} />
            )}
          />

          <Route
            exact
            path={routes.meTemplateNew}
            render={({ match, history }) => (
              <MyTemplateNew
                className={styles.templateNew}
                match={match}
                history={history}
                user={user}
                library={{
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '"User"' is not assignable to type 'LibraryTy... Remove this comment to see the full error message
                  type: 'User',
                  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                  _id: user._id,
                  name: getDisplayName(user),
                }}
                channel={{
                  _id: COMMUNITY_CHANNEL,
                  name: 'Community',
                }}
                forCreate
              />
            )}
          />

          <Route
            exact
            path={routes.meTemplateEdit}
            render={({ match, history }) => (
              <TemplateEdit
                className={styles.templateEdit}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; match: match<{ templateId:... Remove this comment to see the full error message
                match={match}
                history={history}
                user={user}
                channel={{
                  _id: COMMUNITY_CHANNEL,
                  name: 'Community',
                }}
                forEdit
              />
            )}
          />

          <Route
            exact
            path={routes.meSubscriptions}
            render={() => <SwitchSubscription />}
          />

          <Route
            path={routes.meBillingAndPayments}
            render={() => <BillingAndPayments />}
          />

          <Route
            exact
            path={routes.meRecentActivity}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ user: UserType | null; }' is not assignabl... Remove this comment to see the full error message
            render={() => <RecentActivity user={user} />}
          />

          <Route
            exact
            path={routes.meSubscriptionAdd}
            component={AddSubscription}
          />

          <Route
            exact
            path={routes.privacyTermsAndCondition}
            component={PrivacyAndTermsAndConditions}
          />
        </Switch>
      </div>
    </div>
  );
}

import React from 'react';

import cx from 'classnames';

import { longAddress } from 'lane-shared/helpers/formatters';

import PlacesAutocomplete from '../../../../general/PlacesAutocomplete';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './AddressInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function AddressInputBlock({
  address,
  placeholder = 'Enter an address…',
  disabled = false,
  onInput = () => {},
  isValid = true,
  validationMessage = 'Required.',
  className,
  style,
}: any) {
  return (
    <>
      <div
        className={cx(styles.AddressInputBlock, className)}
        style={styleMap(false, style)}
        placeholder={placeholder}
      >
        {address && (
          <div className={styles.selected}>{longAddress(address)}</div>
        )}

        <PlacesAutocomplete
          placeholder={placeholder}
          disabled={disabled}
          value=""
          onChange={onInput}
        />
      </div>

      {!isValid && <ValidationMessage errors={[validationMessage]} />}
    </>
  );
}

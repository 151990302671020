import React from 'react';

import { ChargePartial } from 'lane-shared/domains/billingPayments/types';

import { H5, M } from 'components/typography';
import { ItemsTable } from '../../ItemsTable';

import styles from './styles.scss';

export function ChargeCard({
  channelId,
  charge,
}: {
  channelId: string;
  charge: ChargePartial;
}) {
  return (
    <div className={styles.cardWrapper}>
      {charge.status === 'VOIDED' ? (
        <div className={styles.ChargeHeader}>
          <H5>
            <s>{charge.name}</s>
          </H5>
          <M>
            <s>{charge.description}</s>
          </M>
        </div>
      ) : (
        <div className={styles.ChargeHeader}>
          <H5>{charge.name}</H5>
          <M>{charge.description}</M>
        </div>
      )}

      {charge.items.length > 0 && (
        <ItemsTable channelId={channelId} charge={charge} />
      )}
    </div>
  );
}

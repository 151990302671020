import gql from 'graphql-tag';

import {
  FullWebhookFragment,
  PublicUserFragment,
} from 'lane-shared/graphql/fragments';

export default gql`
  ${PublicUserFragment}
  ${FullWebhookFragment}

  mutation createWebhook($libraryItem: LibraryItemInput!) {
    createWebhook(libraryItem: $libraryItem) {
      _id
      webhook {
        ...FullWebhookFragment
      }
    }
  }
`;

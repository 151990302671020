import React from 'react';

import { Input, Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import CardContainer from 'components/cards/CardContainer';
import { H5, M, S } from 'components/typography';

import styles from './styles.scss';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useFlag } from 'lane-shared/hooks';
import { TFunction } from 'i18next';

interface InvoiceProps {
  invoiceDueInDays: number | null;
  updateInvoiceDueInDays: (days: number | null) => void;
  invoiceDisplayName: string | null;
  updateInvoiceDisplayName: (value: string) => void;
  isInvoicingDisabled: boolean;
  updateIsInvoicingDisabled: (value: boolean) => void;
}

const MIN_DUE_DATE = 1;
const MAX_DUE_DATE = 999;

export function Invoice({
  invoiceDueInDays,
  updateInvoiceDueInDays,
  invoiceDisplayName,
  updateInvoiceDisplayName,
  isInvoicingDisabled,
  updateIsInvoicingDisabled,
}: InvoiceProps) {
  const { t } = useTranslation();
  const InvoiceDisablingFlag = useFlag(FeatureFlag.InvoiceDisabling, false);

  const handleChange = (value: number) => {
    // delete value
    if (value === 0) {
      updateInvoiceDueInDays(null);
      return;
    }

    if (MIN_DUE_DATE <= value && value <= MAX_DUE_DATE) {
      updateInvoiceDueInDays(value);
    }
  };

  const handleToggleInvoice = async () => {
    try {
      // if the user is trying to disable invoicing, show a confirmation dialog
      if (!isInvoicingDisabled) {
        await window.Alert.confirm(disableInvoiceConrimationDialog(t));
      }
    } catch (err) {
      // user clicked cancel
      return;
    }

    // the toggle inverts the isInvoicingDisabled group setting
    updateIsInvoicingDisabled(!isInvoicingDisabled);
  };

  const showInvoiceSettings = InvoiceDisablingFlag
    ? !isInvoicingDisabled
    : true;

  return (
    <CardContainer className={styles.PaymentSettings}>
      <div className={styles.SectionHeader}>
        <H5>{t('abp.billingAndPaymentSettings.invoicesHeader')}</H5>
      </div>

      {InvoiceDisablingFlag && (
        <div className={styles.InvoiceToggleContainer}>
          <Toggle
            value={!isInvoicingDisabled}
            onChange={handleToggleInvoice}
            testId="invoiceEnableToggle"
          />
          <div className={styles.InvoiceToggleDescription}>
            <M mt={2} className={styles.FieldLabel}>
              <label>
                {t('abp.billingAndPaymentSettings.turnOnInvoicing')}
              </label>
            </M>
            <M mt={2} className={styles.FieldLabel}>
              <S>
                {t('abp.billingAndPaymentSettings.turnOnInvoicingDescription')}
              </S>
            </M>
          </div>
        </div>
      )}

      {showInvoiceSettings && (
        <>
          <div className={styles.SectionContainer}>
            <M mt={2} className={styles.FieldLabel}>
              <label>
                {t('abp.billingAndPaymentSettings.invoiceDisplayName')}
              </label>
            </M>

            <div className={styles.InputContainer}>
              <Input
                type="text"
                value={invoiceDisplayName}
                onChange={value => updateInvoiceDisplayName(value)}
                testId="invoiceDisplayNameInput"
              />
            </div>
            <M mt={2} className={styles.FieldLabel}>
              <S>
                {t(
                  'abp.billingAndPaymentSettings.invoiceDisplayNameDescription'
                )}
              </S>
            </M>
          </div>

          <div className={styles.SectionContainer}>
            <M mt={2} className={styles.FieldLabel}>
              <label>{t('abp.billingAndPaymentSettings.dueDate')}</label>
            </M>

            <div className={styles.InputContainer}>
              <Input
                type="number"
                value={invoiceDueInDays}
                onChange={value => handleChange(Number(value))}
                min={MIN_DUE_DATE}
                max={MAX_DUE_DATE}
                testId="invoiceDueInDaysInput"
              />
              <M className={styles.InputExtension}>
                {t(
                  'abp.billingAndPaymentSettings.invoiceDueInDaysInputDescription'
                )}
              </M>
            </div>
            <M mt={2} className={styles.FieldLabel}>
              <S>
                {t('abp.billingAndPaymentSettings.invoiceDueInDaysDescription')}
              </S>
            </M>
          </div>
        </>
      )}
    </CardContainer>
  );
}

function disableInvoiceConrimationDialog(
  t: TFunction<'translation', undefined>
) {
  return {
    title: t('abp.billingAndPaymentSettings.turnOffInvoicingMessageTitle'),
    message: (
      <>
        <p>
          {t('abp.billingAndPaymentSettings.turnOffInvoicingMessagePartOne')}
        </p>
        <br />
        <p>
          {t('abp.billingAndPaymentSettings.turnOffInvoicingMessagePartTwo')}
        </p>
      </>
    ),
    confirmText: t(
      'abp.billingAndPaymentSettings.turnOffInvoicingConfirmButton'
    ),
    confirmButtonStyle: {
      backgroundColor: '#C7200A',
      borderColor: '#C7200A',
      color: 'white',
    },
  };
}

import React from 'react';

import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { Modal } from 'components/lds';
import { IconDatabase, IconClipboard } from 'components/misc/icons';
import { H5, Text } from 'components/typography';

import styles from './NewDataLibraryModal.scss';

const dataTypes = [
  {
    title: 'Database',
    description:
      'A collection of static data that can power and manage your content.',
    icon: <IconDatabase />,
    route: `${routes.channelAdminLibrary}/data/database-new`,
  },
  {
    title: 'Input fields (legacy)',
    description:
      'Dynamic data that enables users to add responses and collect interactions.',
    icon: <IconClipboard />,
    route: routes.channelAdminMetatagNew,
  },
] as const;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  channel: { slug: string };
};

/**
 * Modal to select which type of data library to create
 *
 * @TODO: update routes
 */
export default function DataLibraryModalNew({
  isOpen,
  onClose,
  channel,
}: Props) {
  const history = useHistory();

  function handleRouteChange(route: string) {
    const newRoute = route.replace(':id', channel.slug);

    return () => {
      history.push(newRoute);
      onClose();
    };
  }

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="What do you want to create?"
      size="small"
      className={styles.modalBackground}
    >
      <div className={styles.container}>
        {dataTypes.map(dataType => (
          <div
            key={dataType.route}
            className={styles.dataType}
            role="button"
            onClick={handleRouteChange(dataType.route)}
            tabIndex={0}
          >
            <div className={styles.icon}>{dataType.icon}</div>
            <div className={styles.dataTypeText}>
              <H5 mb={2} className={styles.header}>
                {dataType.title}
              </H5>
              <Text className={styles.description}>{dataType.description}</Text>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}

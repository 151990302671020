import React from 'react';

import { useHistory } from 'react-router-dom';

import getDisplayName from 'lane-shared/helpers/getDisplayName';

import LibraryComponent from 'components/lane/MetatagLibrary';
import AdminPage from 'components/layout/AdminPage';

export default function MetatagLibrary({ channel }: any) {
  const history = useHistory();

  return (
    <AdminPage>
      <LibraryComponent
        channel={channel}
        onMetatagSelected={metatag =>
          history.push(`${history.location.pathname}/${metatag._id}/edit`)
        }
        library={{
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"Channel"' is not assignable to type 'Librar... Remove this comment to see the full error message
          type: 'Channel',
          _id: channel?._id,
          name: getDisplayName(channel),
        }}
      />
    </AdminPage>
  );
}

import { useMemo } from 'react';
import { Item } from 'components/form/Dropdown/Dropdown';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import {
    GetTenantSuitesQuery,
  } from 'graphql-query-contracts';

interface Suite {
  id: string;
  name: string;
  floors: { id: string; name: string }[];
}

const processSuiteList = (suitesData: GetTenantSuitesQuery | undefined) => {
 const suites = (suitesData?.tenant?.suites || []).filter((suite): suite is Suite => suite !== null);

  const floorNamesSet = new Set<string>();
  const suiteNames: string[] = [];
  const suitesAsLabelValue: Item<string>[] = [];
  const floorsAsLabelValueMap = new Map<string, Item<string>>();
  const selectedSuitesIds :string[] = [];
  const floorIdsSet = new Set<string>();


  suites.forEach((suite) => {
    suiteNames.push(suite.name);
    selectedSuitesIds.push(convertToUUID(suite?.id));
    suitesAsLabelValue.push({ label: suite.name, value: suite.id });
    suite.floors.forEach((floor) => {
      floorNamesSet.add(floor.name);
      floorIdsSet.add(floor.id);
      floorsAsLabelValueMap.set(floor.id, { label: floor.name, value: floor.id });
    });
  });

  const floorNames = Array.from(floorNamesSet).join(', ') || '-';
  const selectedFloorIds = Array.from(floorIdsSet);
  const floorsAsLabelValue = Array.from(floorsAsLabelValueMap.values());

  return {
    floorNames,
    suiteNames: suiteNames.join(', ') || '-',
    suitesAsLabelValue,
    floorsAsLabelValue,
    selectedFloorIds,
    selectedSuitesIds,
  };
};

export const useProcessSuiteList = (suites: GetTenantSuitesQuery | undefined) => {
  return useMemo(() => processSuiteList(suites), [suites]);
};

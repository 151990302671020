import React from 'react';

import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import {
  SORT_ASC,
  SORT_CREATED,
  SORT_END,
  SORT_START,
  SORT_STATUS,
  SORT_UPDATED,
} from 'lane-shared/helpers/interactions/types';

import { Flex } from 'components/layout';

import IconButton from '../general/IconButton';
import UserContentInteractionCard from '../lane/UserContentInteractionCard';

import styles from './UCIList.scss';

const headers = [
  { label: 'Created', sort: SORT_CREATED },
  { label: 'Updated', sort: SORT_UPDATED },
  { label: 'Status', sort: SORT_STATUS },
  { label: 'Start', sort: SORT_START },
  { label: 'End', sort: SORT_END },
];

export function UCIList({
  className,
  style,
  interactions,
  selectedSort,
  selectedOrder,
  onSortClicked,
  additionalHeaders,
}: any) {
  const [isAdminView, channelSlug] = useIsAdminView();

  function getInteractionLink(interaction: any) {
    if (isAdminView) {
      // if we are on a channel admin page, use that slug and direct
      // to the team member page, that will have more info about this user.
      return (
        routes.channelAdminInteraction
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          .replace(':id', channelSlug)
          .replace(':interactionId', interaction._id)
      );
    }

    // other wise send them to the public user page.
    return routes.interaction.replace(':id', interaction._id);
  }

  const allHeaders = [...headers, ...(additionalHeaders || [])];

  return (
    <div className={cx(styles.UCIList, className)} style={style}>
      <table className={styles.interactions}>
        <thead>
          <tr>
            {allHeaders.map(header => (
              <th key={header.label}>
                <Flex align="center">
                  <span>{header.label}</span>
                  {header.sort && (
                    <span
                      onClick={() => onSortClicked(header.sort)}
                      role="button"
                      tabIndex={0}
                    >
                      <IconButton
                        inverted
                        icon={
                          selectedOrder === SORT_ASC
                            ? 'sort-amount-down-alt'
                            : 'sort-amount-up'
                        }
                        className={cx(styles.sortButton, {
                          [styles.selected]: selectedSort === header.sort,
                        })}
                        selected={selectedSort === header.sort}
                      />
                    </span>
                  )}
                </Flex>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <ul className={styles.interactions}>
        {interactions.map((interaction: any) => (
          <Link to={getInteractionLink(interaction)} key={interaction._id}>
            <UserContentInteractionCard
              className={styles.interactionCard}
              interaction={interaction}
              forAdmin={isAdminView}
            />
          </Link>
        ))}
      </ul>
    </div>
  );
}

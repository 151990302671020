import React, { useContext } from 'react';

import routes from 'lane-shared/config/routes';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import byOrder from 'lane-shared/helpers/sort/byOrder';

import { Sidebar, SidebarItem } from 'components/navigation';

export default function PortalSidebar() {
  const { pages } = useContext(ChannelsContext);
  const { isLoggedIn } = useContext(UserDataContext);

  return (
    <Sidebar>
      {pages.sort(byOrder).map((page: any) => (
        <SidebarItem
          key={page._id}
          isCategory={false}
          route={routes.customTab
            .replace(':channelSlug', page.channel.slug)
            .replace(':tabSlug', page.slug)}
          icon={page.icon}
          iconSet={page.iconSet}
          iconWeight={page.iconWeight}
          headerMessage={page.label}
          isSidebarCollapsed
          isMemberView
        >
          {page.label}
        </SidebarItem>
      ))}
      {isLoggedIn && (
        <SidebarItem
          isCategory={false}
          route={routes.me}
          icon="user"
          mobileOnly
          isSidebarCollapsed
        >
          Profile
        </SidebarItem>
      )}
    </Sidebar>
  );
}

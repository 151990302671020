import gql from 'graphql-tag';

import { ApolloError, useQuery } from '@apollo/client';

import { getClient } from '../apollo';
import { ExternalUrlOpeningModeEnum } from '../types/ExternalUrl';
import { ContentTypeEnum } from '../types/content/ContentTypeEnum';
import { IntegrationProviderEnum } from '../types/integrations/IntegrationEnums';

export type ContentForAdminCardType = {
  _id: string;
  _created: Date;
  _updated: Date;
  name: string;
  description: string;
  subtitle?: string;
  slug?: string;
  type: ContentTypeEnum;
  tags?: string[];
  color: string;
  isInteractive: boolean;
  backgroundImage: string;
  icon: string;
  iconSet: string;
  iconWeight?: string;
  backgroundColor: string;
  liveDate?: Date;
  startDate?: Date;
  endDate?: Date;
  version: number;
  renderer: number;
  externalUrl?: {
    _id: string;
    url: string;
    openingMode: ExternalUrlOpeningModeEnum;
  };
  card: {
    _id: string;
  };
  integration?: {
    _id: string;
    settings: any;
    integration: {
      _id: string;
      name: IntegrationProviderEnum;
      settings: any;
    };
  };
};

const getContentForAdminCardQuery = gql`
  fragment ContentForAdminCardFragment on DraftContent {
    _id
    _created
    _updated
    name
    description
    subtitle
    slug
    type
    tags
    color
    isInteractive
    backgroundImage
    icon
    iconSet
    iconWeight
    backgroundColor
    liveDate
    startDate
    endDate
    interactionEndDate
    version
    renderer
    channel {
      _id
      slug
    }

    card {
      _id
    }

    integration {
      _id
      settings
      integration {
        _id
        name
        settings
      }
    }
  }

  query getContentForAdminCardQuery($contentId: UUID!) {
    draftContent(_id: $contentId) {
      ...ContentForAdminCardFragment
    }
  }
`;

export default function useContentForAdminCardQuery({
  contentId,
}: {
  contentId: string | null | undefined;
}): {
  content: ContentForAdminCardType | null;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(getContentForAdminCardQuery, {
    skip: !contentId,
    variables: {
      contentId,
    },
    client: getClient(),
  });

  const content = data?.draftContent;

  return { content, loading, error };
}

import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useChannelWeatherQuery } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import Loading from '../../../general/Loading';
import styleMap from '../styleMap';

import styles from './WeatherBlock.scss';

type BlockProps = WebBlockProps & {
  channel: ChannelType;
  color: string;
};

export default function WeatherBlock({
  className,
  style,
  channel,
  color,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const props = useEditModeProps(otherProps);
  const { temperature, icon } = useChannelWeatherQuery({
    channelId: channel?._id,
  });

  return (
    <div
      className={cx(styles.WeatherBlock, className)}
      style={{ ...styleMap(false, style), color }}
      {...props}
    >
      {!channel && (
        <>
          <p className={styles.instructions}>
            {t(
              'web.admin.channel.content.layout.editor.components.weatherBlock.locationText'
            )}
          </p>
          <Loading />
        </>
      )}
      {temperature && (
        <>
          <Icon name={icon} />
          <p className={styles.temperature}>{temperature}</p>
        </>
      )}
    </div>
  );
}

import React, { useState } from 'react';

import gql from 'graphql-tag';

import { getAdminClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { pause } from 'lane-shared/helpers';
import { ChannelContractType } from 'lane-shared/types/ChannelContract';

import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import AdminPage from 'components/layout/AdminPage';

import ChannelContractEdit from './ChannelContractEdit';

const createContractMutation = gql`
  mutation createContract($channelContract: ChannelContractInput!) {
    createChannelContract(channelContract: $channelContract) {
      _id
    }
  }
`;

export default function NewChannelContract({ history }: any) {
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ lineItems: never[]; }' is not ... Remove this comment to see the full error message
  const [contract, setContract] = useState<ChannelContractType>({
    lineItems: [],
  });

  function updateChannelContract(props: Partial<ChannelContractType>) {
    setContract(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  async function createContract() {
    if (validation) {
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await pause();

      const { data } = await getAdminClient().mutate({
        mutation: createContractMutation,
        variables: { channelContract: contract },
      });

      history.push(
        routes.portalManagementContractEdit.replace(
          ':contractId',
          data.createChannelContract._id
        )
      );
    } catch (err) {
      setError(err);
      window.Alert.alert({
        title: `Error creating contract.`,
        message: `This contract was not created, please see the error below and try again.`,
        error: err,
      });
    }

    setLoading(false);
  }

  return (
    <AdminPage>
      <ControlMenu>
        <h1>Create a new Contract</h1>
        <Button variant="contained" onClick={createContract} disabled={loading}>
          Create
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      <ChannelContractEdit
        contract={contract}
        onChannelContractUpdated={updateChannelContract}
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ValidationError | null' is not a... Remove this comment to see the full error message
        onValidation={validation => setValidation(validation)}
      />
    </AdminPage>
  );
}

import React, { useState, useRef, useMemo } from 'react';

import cx from 'classnames';
import { Layer, Source } from 'react-mapbox-gl';
import { useDebouncedCallback } from 'use-debounce';

import { LaneType } from 'common-types';
import { getClient } from 'lane-shared/apollo';
import { appUrl } from 'lane-shared/config';
import updateDraftContentMetatagsMutation from 'lane-shared/graphql/content/updateDraftContentMetatags';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import { FloorMapsSettingsType } from 'lane-shared/helpers/integrations/FloorMaps/definition';
import { createFeatureCollection } from 'lane-shared/helpers/integrations/FloorMaps/featuresHelpers';
import { FloorMapType } from 'lane-shared/helpers/integrations/FloorMaps/types/FloorMap';
import { useSectionContentForAdmin } from 'lane-shared/hooks';
import type { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';
import { ContentType } from 'lane-shared/types/content/Content';

import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ModalBackground from 'components/general/ModalBackground';
import ResizableWindow from 'components/general/ResizableWindow';
import MapboxMap from 'components/mapBox/MapboxMap';
import MapboxPoint from 'components/mapBox/MapboxPoint';
import getMapCenterCoordinates from 'components/mapBox/getMapCenterCoordinates';
import { H4 } from 'components/typography';

import ContentListItem from './ContentListItem';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './FloorMapDeskPlannerTool.scss';

const animationOptions = {
  animate: false,
};

const mapZoom: [number] = [18];
const containerStyle = {
  display: 'flex',
  minWidth: 200,
  minHeight: 200,
  height: '100%',
  flex: 1,
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channelIntegration: { _id: string; settings: FloorMapsSettingsType };
  floor: FloorMapType;
  onClose: () => void;
  onUpdateFloor: (update: Partial<FloorMapType>) => void;
};

const paintOptions = {
  'raster-opacity': 0.9,
};

const POINT_SIZE = 20;

function useSectionContentForAdminGetter(
  map: Map<LaneType.UUID, any>,
  sectionId: LaneType.UUID
) {
  const result = useSectionContentForAdmin({
    sectionId,
    searchOptions: {
      areFiltersApplied: false,
      search: '',
      filters: [],
      sorts: [],
      metatagFilters: [],
      channelId: null,
    },
    fetchPolicy: 'network-only',
  });

  if (result.sectionContent) {
    result.sectionContent.forEach(({ content }: any) => {
      map.set(content._id, content);
    });
  }

  return result;
}

export default function FloorMapDeskPlannerTool({
  className,
  style,
  channelIntegration,
  floor,
  onClose,
}: Props) {
  const { channel } = useChannelAdminContext();
  const metatagId = convertTo62(channelIntegration.settings?.metatag?._id);

  const [selectedContent, setSelectedContent] = useState<any | null>(null);
  const [, setLoading] = useState(false);
  const updateRef = useRef<{
    content: ContentType | null;
    coordinates: GeoCoordinateType[] | null;
  }>({
    content: null,
    coordinates: null,
  });
  const [refetchTimestamp, setRefetchTimestamp] = useState(Date.now());

  const mapRef = useRef(null);

  const tileSource = useMemo(
    () => ({
      type: 'raster',
      tiles: [
        `${appUrl}/api/v5/floormaps/${channelIntegration._id}/${floor._id}/{z}/{x}/{y}?disableCache=true`,
      ],
      tileSize: 256,
    }),
    [channelIntegration._id, floor._id]
  );

  const sectionsData = useMemo(() => new Map<string, any>(), [floor]);
  const sections = floor.sectionIds.map(sectionId =>
    // FIXME: we should not be calling hooks in loops, this needs to be changed to either:
    // - dynamically generate a query for each array element OR
    // - calling a version of the query that accepts an array of IDs as input
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSectionContentForAdminGetter(sectionsData, sectionId)
  );
  const sectionContent = Array.from(sectionsData.values());

  async function refetch() {
    await Promise.all(sections.map(item => item.refetch()));
    setRefetchTimestamp(Date.now());
  }

  async function removeContentFromMap(contentId: any) {
    const content = sectionContent.find(content => content._id === contentId);

    if (!content) {
      return;
    }

    try {
      await window.Alert.confirm({
        title: `Remove from map?`,
        message: `Remove ${content.name} from the map?`,
        confirmText: 'Yes, Remove',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    const contentMetatag = content?.contentMetatags?.find(
      (contentMetatag: any) => contentMetatag.metatag._id === metatagId
    );

    if (!contentMetatag) {
      return;
    }

    try {
      setLoading(true);
      await getClient().mutate({
        mutation: updateDraftContentMetatagsMutation,
        variables: {
          draft: {
            _id: content._id,
            contentMetatags: [
              {
                _pull: true,
                _id: contentMetatag._id,
              },
            ],
          },
        },
      });
      window.Toast.show('Removed.');
      await refetch();
    } catch (err) {
      await window.Alert.alert({
        title: `Removing failed`,
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  async function addContentToMap(contentId: any) {
    const content = sectionContent.find(content => content._id === contentId);

    if (!content) {
      return;
    }

    // use the center of the map as the default position
    const centerCoordinates = getMapCenterCoordinates(mapRef.current, 200, 200);

    try {
      setLoading(true);
      await getClient().mutate({
        mutation: updateDraftContentMetatagsMutation,
        variables: {
          draft: {
            _id: content._id,
            contentMetatags: [
              {
                metatag: {
                  _id: channelIntegration.settings.metatag._id,
                },
                value: centerCoordinates[0],
              },
            ],
          },
        },
      });

      window.Toast.show('Added to map.');
      await refetch();
    } catch (err) {
      await window.Alert.alert({
        title: `Wasn't able to add ${content.name}`,
        error: err,
      });
    } finally {
      setLoading(true);
    }
  }

  function mapReady(map: any) {
    // store the map control for later
    mapRef.current = map;
  }

  function doUpdate() {
    onClose();
  }

  async function updateMetatagWithCoordinates() {
    const { content, coordinates } = updateRef.current;

    const contentMetatag = content?.contentMetatags?.find(
      contentMetatag => contentMetatag.metatag._id === metatagId
    );

    if (!content || !contentMetatag) {
      return;
    }

    try {
      setLoading(true);
      await getClient().mutate({
        mutation: updateDraftContentMetatagsMutation,
        variables: {
          draft: {
            _id: content._id,
            contentMetatags: [
              {
                _id: contentMetatag._id,
                value: coordinates,
              },
            ],
          },
        },
      });
    } catch (err) {
      await window.Alert.alert({
        title: `Update failed`,
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  const debouncedUpdateMetatagWithCoordinates = useDebouncedCallback(
    updateMetatagWithCoordinates,
    1000
  ).callback;

  function updateCoordinates(pointId: any, coordinates: any) {
    updateRef.current.content = sectionContent.find(
      content => content._id === pointId
    );
    updateRef.current.coordinates = coordinates;

    debouncedUpdateMetatagWithCoordinates();
  }

  const placedContent = useMemo(
    () =>
      sectionContent.filter(content =>
        content?.contentMetatags?.some(
          (contentMetatag: any) => contentMetatag.metatag._id === metatagId
        )
      ),
    [sectionContent, refetchTimestamp]
  );

  const unplacedContent = useMemo(
    () =>
      sectionContent.filter(
        content =>
          !content?.contentMetatags?.some(
            (contentMetatag: any) => contentMetatag.metatag._id === metatagId
          )
      ),
    [sectionContent, refetchTimestamp]
  );

  const unselectedFeatures = useMemo(
    () =>
      createFeatureCollection(
        sectionContent.filter(content => content._id !== selectedContent),
        metatagId
      ),
    [sectionContent, selectedContent, metatagId, refetchTimestamp]
  );
  const selectedFeatures = useMemo(
    () =>
      createFeatureCollection(
        sectionContent.filter(content => content._id === selectedContent),
        metatagId
      ),
    [sectionContent, selectedContent, metatagId, refetchTimestamp]
  );

  return (
    <ModalBackground onClose={onClose} isOpen className={styles.background}>
      <ResizableWindow
        name="floorMapDeskPlannerTool"
        className={styles.window}
        onClose={onClose}
        defaultPosition={ResizableWindow.fullScreen()}
        showHeader
      >
        <div
          className={cx(styles.FloorMapDeskPlannerTool, className)}
          style={style}
        >
          <div className={styles.planner}>
            <div className={styles.content}>
              <H4 mt={0} mb={2}>
                Placed
              </H4>

              <ul className={styles.contentList}>
                {placedContent.map(content => (
                  <ContentListItem
                    className={styles.contentListItem}
                    content={content}
                    key={content._id}
                    isSelected={content._id === selectedContent}
                    onClick={() => setSelectedContent(content._id)}
                    onAddContent={content => addContentToMap(content._id)}
                    onRemoveContent={content =>
                      removeContentFromMap(content._id)
                    }
                    metatagId={metatagId}
                  />
                ))}
              </ul>

              <H4 mt={4} mb={2}>
                Not placed
              </H4>
              <ul className={styles.contentList}>
                {unplacedContent.map(content => (
                  <ContentListItem
                    className={styles.contentListItem}
                    content={content}
                    key={content._id}
                    isSelected={content._id === selectedContent}
                    onClick={() => setSelectedContent(content._id)}
                    onAddContent={content => addContentToMap(content._id)}
                    onRemoveContent={content =>
                      removeContentFromMap(content._id)
                    }
                    metatagId={metatagId}
                  />
                ))}
              </ul>
            </div>
            <div className={styles.map}>
              <MapboxMap
                animationOptions={animationOptions}
                center={channel?.address?.geo}
                zoom={mapZoom}
                containerStyle={containerStyle}
                onStyleLoad={mapReady}
              >
                <Source id="laneMaps" tileJsonSource={tileSource} />
                <Layer type="raster" sourceId="laneMaps" paint={paintOptions} />

                <MapboxPoint
                  id="unselected-points"
                  radius={POINT_SIZE / 2}
                  draggable
                  onFocus={setSelectedContent}
                  source={unselectedFeatures}
                  onCoordinatesUpdated={updateCoordinates}
                />

                <MapboxPoint
                  id="selected-points"
                  radius={POINT_SIZE / 2}
                  draggable
                  onFocus={setSelectedContent}
                  source={selectedFeatures}
                  onCoordinatesUpdated={updateCoordinates}
                  isSelected
                />
              </MapboxMap>
            </div>
          </div>
          <ControlMenu className={styles.menu}>
            <hr />
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>

            {/* @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"inh... Remove this comment to see the full error message */}
            <Button color="secondary" variant="contained" onClick={doUpdate}>
              Done
            </Button>
          </ControlMenu>
        </div>
      </ResizableWindow>
    </ModalBackground>
  );
}

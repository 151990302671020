import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TRANSLATION_KEYS = {
  name: 'web.admin.channel.waitlistManagement.table.name',
  primaryEmail: 'web.admin.channel.waitlistManagement.table.email',
  company: 'web.admin.channel.waitlistManagement.table.company',
  dateJoined: 'web.admin.channel.waitlistManagement.table.dateJoined',
  timeJoined: 'web.admin.channel.waitlistManagement.table.timeJoined',
};

export enum ColumnKey {
  name = 'name',
  primaryEmail = 'primaryEmail',
  company = 'company',
  dateJoined = 'dateJoined',
  timeJoined = 'timeJoined',
}

function getTimeZoneAbbreviation(channelTimeZone: string) {
  const today = new Date();
  const isoTodayTimestamp = today.toISOString();
  const dateTime = DateTime.fromISO(isoTodayTimestamp, {
    zone: channelTimeZone,
  });

  const timeZoneAbbreviation = dateTime.toFormat('ZZZZ');

  return timeZoneAbbreviation;
}

export function useColumnsHeaders(channelTimezone: string) {
  const { t } = useTranslation();

  const columns = [
    {
      header: t(TRANSLATION_KEYS.dateJoined),
      key: ColumnKey.dateJoined,
      renderCell: (dateJoined: string) => <div>{dateJoined}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.timeJoined, {
        timeZone: getTimeZoneAbbreviation(channelTimezone),
      }),
      key: ColumnKey.timeJoined,
      disableSorting: true,
      renderCell: (timeJoined: string) => <div>{timeJoined}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.name),
      key: ColumnKey.name,
      renderCell: (name: string) => <div>{name}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.primaryEmail),
      key: ColumnKey.primaryEmail,
      renderCell: (primaryEmail: string) => <div>{primaryEmail}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.company),
      key: ColumnKey.company,
      renderCell: (companyDisplayName: string) => (
        <div>{companyDisplayName}</div>
      ),
    },
  ];

  return { columns };
}

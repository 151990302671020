import styles from './fileImportRecord.scss';
import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Label } from 'components';
import { Flex } from 'components/layout';
import { H5 } from 'components/typography';
import { getImporterEntityByType } from 'lane-shared/domains/importer/jobDefinition';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const FileImportRecord = ({ job }: { job: any }) => {
  const { t } = useTranslation();
  const entityDefination = getImporterEntityByType(
    job?.metadata?.integrationMetadata?.entityType
  );
  const totalSuccessRecords =
    (job?.totalCreatedRecords || 0) + (job?.totalUpdatedRecords || 0);
  return (
    <Flex className={styles.adminPage} gap={1} direction="column">
      <Flex direction="column" gap={2}>
        <H5>{t(entityDefination?.friendlyName)}</H5>
      </Flex>
      {totalSuccessRecords >= 0 && (
        <Flex direction="row" className={styles.attachmentName} align="center">
          <Icon
            className={cx(styles.icon, styles.successColor)}
            name="check-circle"
            set="FontAwesome"
            testId="successIcon"
          />
          <Label className={styles.successColor} mt={0}>
            {t('web.admin.importer.workorders.detail.item.success', {
              itemCount: totalSuccessRecords,
            })}
          </Label>
        </Flex>
      )}
      {job?.totalFailedRecords > 0 && (
        <Flex direction="row" className={styles.attachmentName} align="center">
          <Icon
            className={cx(styles.icon, styles.errorColor)}
            name="exclamation-circle"
            set="FontAwesome"
            testId="errorIcon"
          />
          <Label className={styles.errorColor} mt={0}>
            {t('web.admin.importer.workorders.detail.item.failed', {
              itemCount: job?.totalFailedRecords,
            })}
          </Label>
        </Flex>
      )}
    </Flex>
  );
};

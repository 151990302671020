import gql from 'graphql-tag';

import { ProfileFragment } from '../fragments';

export default gql`
  ${ProfileFragment}

  query getUsersByWorkOrderTeams(
    $groupRoleIds: [UUID]!
    $search: UserGroupRoleSearch
  ) {
    workOrderUsersByGroupRoles(groupRoleIds: $groupRoleIds, search: $search) {
      items {
        _id
        user {
          _id
          _created
          _updated
          status
          lastSeen
          name
          profile {
            ...ProfileFragment
          }
        }
      }
    }
  }
`;

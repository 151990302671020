import i18next from 'i18next';

import {
  DataSourceEnum,
  RiseMigrationFeaturesType,
  SourceLocationAction,
  SourceLocations,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import { DEFAULT_WHITELABEL_URL, SETUP_STEPS } from './constants';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { Whitelabel } from 'graphql-query-contracts';

export const checkIfSourceLocationsAreValid = (
  sourceLocations: SourceLocations
): boolean => {
  if (!sourceLocations || !sourceLocations.length) return false;

  for (const sourceLocation of sourceLocations) {
    if (sourceLocation.value === '' || !sourceLocation.value) return false;
  }
  return true;
};

/**
 * Obfuscates the file path to only show the file name
 * @param {string|undefined} url
 * @returns {string|undefined}
 */
export const showOnlyFileName = (
  url: string | undefined
): string | undefined => {
  return url && new URL(url).pathname.split('/').pop();
};

export function getSetupSteps(dataSource?: DataSourceEnum) {
  const riseSetupSteps = [...SETUP_STEPS];
  riseSetupSteps[1] = i18next.t(
    'web.admin.channel.autoSetupWizard.selectFeatures'
  );
  return dataSource === DataSourceEnum.RISE ? riseSetupSteps : SETUP_STEPS;
}

export function formatValidationErrorMessage(message: string) {
  const regex = /Validation Errors: \\"(.*)\\""/g;
  const validationMatch = regex.exec(message);
  return validationMatch?.[1] ?? message;
}

export function getRiseFeaturesAreValid(features: RiseMigrationFeaturesType) {
  const featureKeys = Object.keys(features);

  const selectableFeatures = featureKeys.filter(
    key => !['addRandomSuffixToSlug', 'migrateCatUsers'].includes(key)
  );

  const noFeaturesSelected = selectableFeatures.every(
    key => !features[key as keyof RiseMigrationFeaturesType]
  );
  if (noFeaturesSelected && features.migrateCatUsers.length <= 0) {
    return false;
  }

  return true;
}

export function dataSourceLocationsReducer(
  sourceLocations: SourceLocations,
  action: SourceLocationAction
) {
  switch (action.type) {
    case 'ADD_SOURCE_LOCATION':
      return [...sourceLocations, { index: sourceLocations.length, value: '' }];

    case 'DELETE_SOURCE_LOCATION':
      return sourceLocations.filter(
        sourceLocation => sourceLocation.index !== action.index
      );

    case 'UPDATE_SOURCE_LOCATION':
      return sourceLocations.map(sourceLocation => {
        if (sourceLocation.index === action.sourceLocation.index)
          return action.sourceLocation;
        return sourceLocation;
      });

    case 'CLEAR_SOURCE_LOCATIONS':
      return [];

    default:
      return sourceLocations;
  }
}

export function getRedirectLinks(
  channel: UseChannelForAdminQueryResponse
): string[] {
  // should be only one result since we are searching by _id
  const whitelabels = channel.channel.whitelabels;

  if (whitelabels === null) {
    return [DEFAULT_WHITELABEL_URL];
  }
  return whitelabels.map(
    ({ whitelabel }: { whitelabel: Whitelabel }) => whitelabel.url
  );
}

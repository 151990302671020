import React, { ReactElement } from 'react';

import { Controller, Control } from 'react-hook-form';

import { CheckBox } from '../CheckBox';

type Props = {
  className?: string;
  text: string | ReactElement;
  control: Control<any>;
  name: string;
  isRequired?: boolean;
  labelStyles?: React.CSSProperties;
};

export function HookFormCheckBox({
  control,
  name,
  text,
  isRequired = false,
  ...checkBoxProps
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value } }) => (
        <CheckBox
          onChange={(isChecked: boolean) => {
            onChange(!isChecked);
          }}
          value={value}
          selected={value}
          text={text}
          {...checkBoxProps}
        />
      )}
    />
  );
}

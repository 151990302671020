import React, { useEffect, useState } from 'react';

import { Flex, Loading } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useLazyQuery, useQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { EntityTypeEnum } from 'lane-shared/types/attachment';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import ErrorMessage from 'lane-web/src/components/general/ErrorMessage';
import { BreadCrumbs } from 'lane-web/src/components/lds';

import { Equipment, type CreatePmScheduleDto } from 'graphql-query-contracts';

import {
  getPMScheduleByIdQuery,
  getAllEquipmentQuery,
  updatePMScheduleMutation,
} from 'graphql-queries';

import { SelectFileReturnType } from '../../components/PMAttachments';
import { ScheduleForm } from '../../components/ScheduleForm';
import { ScheduleEditor } from '../../components/ScheduleEditor';
import { useAttachmentByEntityData } from 'hooks/useAttachmentByEntityData';
import { useAttachmentDelete } from 'hooks/useAttachmentDelete';
import { useAttachmentUpload } from 'hooks/useAttachmentUpload';

import styles from './index.scss';
import { AmazonS3Buckets } from 'lane-shared/types/media';

export function ScheduleEditPage({ channel }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { scheduleId } = useParams<{ scheduleId: string }>();
  const [equipments, setEquipments] = useState<Equipment[]>([]);
  const newScheduleCreateForm = useFlag(
    FeatureFlag.InteractiveStepsCreate,
    false
  );

  const preventiveMaintenancePath = routes.channelAdminWorkOrdersPM.replace(
    ':id',
    channel?.slug
  );

  const scheduleDetailPath = routes.channelAdminWorkOrdersPMScheduleDetails
    .replace(':id', channel?.slug)
    .replace(':scheduleId', scheduleId);

  const { data: scheduleResponse } = useQuery(getPMScheduleByIdQuery, {
    variables: { scheduleId },
    fetchPolicy: 'network-only',
  });

  const schedule = scheduleResponse?.pmSchedule;
  const onCancel = async () => {
    try {
      await window.Alert.confirm({
        title: t(
          'web.admin.workOrder.preventiveMaintenance.schedule.alert.cancelEditing.title',
          { scheduleTitle: schedule?.title }
        ),
        message: t(
          'web.admin.workOrder.preventiveMaintenance.schedule.alert.cancelEditing.message'
        ),
      });
    } catch (error) {
      // user doesn't want to cancel editing schedule
      return;
    }
    history.push(scheduleDetailPath);
  };

  const [fetchEquipment, { data, loading }] = useLazyQuery(
    getAllEquipmentQuery,
    {
      onCompleted: () => {
        if (data?.getAllEquipment) {
          setEquipments(data.getAllEquipment);
        }
      },
    }
  );

  useEffect(() => {
    if (channel?._id) {
      fetchEquipment({
        variables: {
          channelId: channel._id,
        },
      });
    }
  }, [channel?._id, fetchEquipment]);

  const updateSchedule = async (data: CreatePmScheduleDto) => {
    const {
      extRefId: _extRefId,
      ...rest
    } = data;

    const dataToSave = {
      ...rest,
      scheduleId,
      stepTemplate: rest.stepTemplate?.map(step => ({
        id: step?.id,
        name: step?.name,
        type: step?.type,
        meterReadingId: step?.meterReadingId,
        options: {
          subtype: step?.options?.subtype,
          description: step?.options?.description,
          choices: step?.options?.choices,
          unit: step?.options?.unit,
        },
      })),
    };

    try {
      await getClient().mutate({
        mutation: updatePMScheduleMutation,
        variables: {
          updatePMSchedule: dataToSave,
        },
      });
      history.push(scheduleDetailPath);
      window.Toast.show(
        t(
          'web.admin.workOrder.preventiveMaintenance.schedule.message.saveChangesSuccessful'
        )
      );
    } catch (err: any) {
      history.push(scheduleDetailPath);
      window.Toast.show(
        <ErrorMessage
          error={t(
            'web.admin.workOrder.preventiveMaintenance.schedule.message.saveChangesFailed'
          )}
        />
      );
    }
  };

  const { fetchAttachments, attachments } = useAttachmentByEntityData({
    entityId: safeConvertToUUID(scheduleId),
    editMode: false,
  });

  const { filesSelectedHandler } = useAttachmentUpload({
    onAttachmentCreated: fetchAttachments,
    entity: {
      type: EntityTypeEnum.PMSchedule,
      id: safeConvertToUUID(scheduleId),
    },
    s3Bucket: AmazonS3Buckets.Activate,
  });

  const addAttachmentFiles = async (files: File[]) => {
    await filesSelectedHandler(files);
  };

  const { deleteAttachmentHandler } = useAttachmentDelete({
    fetchAttachments,
  });

  const breadcrumbLinks = [
    {
      label: `${t('web.admin.workOrder.preventiveMaintenance.title')}`,
      url: preventiveMaintenancePath,
    },
    {
      label: schedule?.title ?? '',
      url: scheduleDetailPath,
    },
    {
      label: `${t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.edit'
      )}`,
    },
  ];

  if (loading) return <Loading fullscreen />;

  return schedule && newScheduleCreateForm ? (
    <ScheduleEditor
      title={t(
        'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.edit'
      )}
      channel={channel}
      scheduleData={{
        ...schedule,
        untilDate: schedule.untilDate
          ? new Date(schedule.untilDate)
          : undefined,
        nextDueDate: schedule.nextDueDate
          ? new Date(schedule.nextDueDate)
          : undefined,
      }}
      breadcrumbLinks={breadcrumbLinks}
      channelEquipments={equipments}
      attachmentFiles={attachments}
      onAddAttachments={addAttachmentFiles as SelectFileReturnType}
      onRemoveAttachment={index => {
        deleteAttachmentHandler(attachments[Number(index)]!.id);
      }}
      onSaveSchedule={updateSchedule}
      onCancel={onCancel}
    />
  ) : (
    <Flex direction="column" width="full" className={styles.Container}>
      <BreadCrumbs links={breadcrumbLinks} />
      {schedule && (
        <div className={styles.Form}>
          <ScheduleForm
            title={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.Form.title.edit'
            )}
            channel={channel}
            scheduleData={{
              ...schedule,
              untilDate: schedule.untilDate
                ? new Date(schedule.untilDate)
                : undefined,
              nextDueDate: schedule.nextDueDate
                ? new Date(schedule.nextDueDate)
                : undefined,
            }}
            channelEquipments={equipments}
            attachmentFiles={attachments}
            onAddAttachments={addAttachmentFiles as SelectFileReturnType}
            onRemoveAttachment={index => {
              deleteAttachmentHandler(attachments[Number(index)]!.id);
            }}
            onSaveSchedule={updateSchedule}
            onCancel={onCancel}
            saveButtonText={t(
              'web.admin.workOrder.preventiveMaintenance.schedule.Form.save'
            )}
          />
        </div>
      )}
    </Flex>
  );
}

import { getClient } from 'lane-shared/apollo';
import {
  CreateChargeRequest,
  ExternalEntityType,
  ExternalActorType,
  ProductItemForCharge,
  AddProductToChargeDetails,
} from 'lane-shared/domains/billingPayments/types';
import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { ChargeDetails } from '../CreateChargeForm';
import { createChargeMutationQuery } from './chargesInvoiceQueries';

export interface CreateNewChargeDetails extends ChargeDetails {
  channelId: string;
  chargeCreatorUserId: string;
}

export async function createChargeMutation(
  chargeDetails: CreateNewChargeDetails,
  products: AddProductToChargeDetails[] = []
) {
  const {
    invoicee: { _id, type: invoiceeType },
    chargeName,
    chargeDescription,
    currency,
    channelId,
    chargeCreatorUserId,
    invoiceType,
    invoiceDueDate,
    amount,
  } = chargeDetails;

  const productItems = convertProductsToQueryFormat(products, channelId);

  await getClient().mutate<any, { createChargeRequest: CreateChargeRequest }>({
    mutation: createChargeMutationQuery,
    variables: {
      createChargeRequest: {
        name: chargeName,
        description: chargeDescription,
        amount,
        currency,
        externalPayerId: convertToUUID(_id),
        externalPayerType: invoiceeType,
        groupId: convertToUUID(channelId),
        groupType: GroupType.GroupTypeActivateChannel,
        externalEntityId: '',
        externalEntityType: ExternalEntityType.EXTERNAL_ENTITY_TYPE_UNKNOWN,
        externalActorId: convertToUUID(chargeCreatorUserId),
        externalActorType: ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
        metadata: '',
        invoiceType,
        invoiceDueDate:
          invoiceDueDate && new Date(invoiceDueDate.toUTCString()),
        items: productItems,
      },
    },
  });
}

export function convertProductsToQueryFormat(
  products: AddProductToChargeDetails[],
  channelId: string
): ProductItemForCharge[] {
  return products.map(product => {
    const productId = product.productId ? convertToUUID(product.productId) : '';
    const productGroupId = product.productGroupId
      ? convertToUUID(product.productGroupId)
      : convertToUUID(channelId);
    return {
      product: {
        id: productId,
        name: product.name,
        description: product.description,
        amount: Number(product.rate),
        groupId: productGroupId,
        groupType: GroupType.GroupTypeActivateChannel,
        markup: {
          value: Number(product.markup.value),
          markupAmountType: product.markup.type,
        },
        tax: {
          value: Number(product.tax.value),
          taxAmountType: product.tax.type,
        },
        originalPrice: product.originalPrice
          ? {
              amount: Number(product.originalPrice.rate),
              markup: {
                value: Number(product.originalPrice.markup.value),
                markupAmountType: product.originalPrice.markup.type,
              },
              tax: {
                value: Number(product.originalPrice.tax.value),
                taxAmountType: product.originalPrice.tax.type,
              },
            }
          : undefined,
      },
      quantity: Number(product.quantity),
      productCategoryId: product.productCategoryId,
      chargeCodeId: product.chargeCodeId,
    };
  });
}

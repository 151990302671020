import React, { useState } from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Table, PageSizeType } from 'design-system-web';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { useGetProductsServicesTableColumns } from '../helpers/useGetProductsServicesTableColumns';
import { useProductsListQuery } from '../helpers/useProductsListQuery';
import { Product } from 'lane-shared/domains/billingPayments/types';
import { ProductType } from 'graphql-query-contracts';
import { deleteProductMutation } from 'lane-shared/domains/billingPayments/queries/deleteProductMutation';

type Props = {
  canManage?: boolean;
};

export function ProductsServicesTable({ canManage }: Props) {
  const isEditProductEnabled = useFlag(FeatureFlag.EditProducts, false);
  const { channel } = useChannelAdminContext();
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const { data: queryResponse, refetch } = useProductsListQuery({
    page,
    pageSize,
    productTypes: [
      ProductType.ProductTypeServiceLabour,
      ProductType.ProductTypeServiceMaterials,
    ],
  });

  const onDelete = async (product: Product) => {
    try {
      await window.Alert.confirm({
        title: t('abp.productsServices.deleteProduct.title', {
          product: product.name,
        }),
        message: t('abp.productsServices.deleteProduct.description'),
        confirmText: t('abp.productsServices.deleteProduct.action.delete'),
        cancelText: t('abp.productsServices.deleteProduct.action.cancel'),
        confirmButtonStyle: {
          backgroundColor: '#C7200A',
          borderColor: '#C7200A',
          color: 'white',
        },
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      if (channel?._id) {
        await deleteProductMutation(product.id, channel._id);

        window.Toast.show(
          t('abp.productsServices.deleteProduct.delete.success')
        );

        refetch();
      }
    } catch (err) {
      window.Toast.show(t('abp.productsServices.deleteProduct.delete.error'));
    }
  };

  const productsServicesTableColumns = useGetProductsServicesTableColumns(
    channel?._id,
    onDelete,
    isEditProductEnabled && canManage
  );

  // TODO: correctly use generated type of Product
  const tableData = ((queryResponse?.listProducts.products ||
    []) as unknown) as Product[];

  return (
    <Table
      columns={productsServicesTableColumns}
      data={tableData}
      disableSorting
      isLoading={false}
      pagination="server"
      totalRows={queryResponse?.listProducts.pageInfo?.total}
      pageSize={pageSize}
      page={page}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
    />
  );
}

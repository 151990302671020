import { SupportedLocaleEnum } from 'localization';
import {
  ProductItem,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from '../../productCatalog/helpers';
import { PriceComponents } from '../../productCatalog/types';

export function getDraftTotalAmounts(
  listOfProducts: ProductItem[],
  currency: SupportedPaymentCurrency,
  locale: SupportedLocaleEnum
) {
  let subTotal = 0;
  let tax = 0;
  let total = 0;

  listOfProducts.forEach(productItem => {
    const priceComponents: PriceComponents = {
      amount: productItem.product.amount,
      markup: productItem.product.markup,
      tax: productItem.product.tax,
      netPrice: productItem.product.netPrice,
    };
    const { listPrice, grossTax, netPrice } = getPricing({
      currency,
      locale,
      priceComponents,
    });

    const quantity = Number(productItem.quantity);
    subTotal += listPrice * quantity;
    tax += grossTax * quantity;
    total += netPrice * quantity;
  });

  return { subTotal, tax, total };
}

import { ApolloError, NetworkStatus } from '@apollo/client';

import useLocation from 'lane-shared/hooks/location/useLocation';

import { CIRCUMFERENCE_OF_EARTH } from '../../helpers/constants';
import useChannelsSearchQuery from '../../hooks/useChannelsSearchQuery';
import { ChannelType, ChannelTypeEnum } from '../../types/ChannelType';
import { StringSearchTypeEnum } from '../../types/graphql/search';

export type BuildingSearchResult = {
  buildings: ChannelType[];
  loading: boolean;
  called: boolean;
  error: ApolloError | null | undefined;
  networkStatus: NetworkStatus;
  fetchMore: () => void;
  refetch: () => void;
};

type Props = {
  search: any;
  skip?: boolean;
};

export default function useBuildingsSearch({
  skip,
  search,
}: Props): BuildingSearchResult {
  const { location } = useLocation();

  const variables: any = {
    pagination: { start: 0, perPage: 25 },
    search: {
      // this will get overridden if there is location available
      sortBy: { key: 'name', dir: 'asc' },
      // search for property channels
      type: { any: [ChannelTypeEnum.Property] },
      // that are not sub channels
      isSub: false,
      // the search key on ChannelSearch is a combined key on address.street1
      // and channel.name
      search: { type: 'like', value: search },
      // only get channels that have parents.
      parent: { name: { type: StringSearchTypeEnum.NotEqual, value: null } },
      // only get buildings that have some address set.
      address: {
        street1: { type: StringSearchTypeEnum.NotEqual, value: null },
      },
    },
  };

  // If we have valid location data, add search variable
  if (location.latitude !== 0 && location.longitude !== 0) {
    variables.search.address = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
        distance: CIRCUMFERENCE_OF_EARTH,
      },
    };
  }

  // useChannelsSearchQuery will check to see if variables changed before
  // doing a re-render, so we don't need to ref or set variables in state
  const {
    loading,
    error,
    called,
    channels,
    fetchMore,
    refetch,
    networkStatus,
  } = useChannelsSearchQuery({
    variables,
    skip,
  });

  return {
    buildings: channels,
    loading,
    error,
    called,
    fetchMore,
    refetch,
    networkStatus,
  };
}

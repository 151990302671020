import React, { useRef } from 'react';

import IntegrationEdit from 'components/lane/IntegrationEdit';

export default function IntegrationNew(props: any) {
  const newIntegration = useRef({
    name: '',
    category: '',
    type: '',
    platforms: [],
    workflows: {},
    properties: {},
    settings: {},
  }).current;

  return <IntegrationEdit integration={newIntegration} {...props} />;
}

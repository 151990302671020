import React, { useContext, useRef, useLayoutEffect, useEffect } from 'react';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mapb... Remove this comment to see the full error message
import { Control } from 'mapbox-gl';
import { MapContext } from 'react-mapbox-gl';

import { polygon } from '@turf/helpers';

import { colors } from 'lane-shared/config';
import { imageUrl } from 'lane-shared/helpers/formatters';
import mapToGeoJSONPolygon from 'lane-shared/helpers/mapBox/mapToGeoJSONPolygon';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

import getMapCenterCoordinates from 'components/mapBox/getMapCenterCoordinates';

import { TxCenter } from 'helpers/MapboxRotate';

import styles from './MapboxRotatableRectangle.scss';

type OwnProps = {
  name?: string;
  opacity?: number;
  fillColor?: string;
  isSelected?: boolean;
  drawControl: Control;
  rectangleId: string;
  imageOverlay?: string;
  coordinates?: GeoCoordinateType[];
  newCoordindates?: GeoCoordinateType[];
  onCoordinatesUpdated: (coordinates: GeoCoordinateType[]) => void;
  onFocus: (rectangleId: string) => void;
};

type Props = OwnProps;

export default function MapboxRotatableRectangle({
  name,
  opacity = 0.6,
  fillColor,
  drawControl,
  isSelected,
  rectangleId,
  coordinates,
  imageOverlay,
  onCoordinatesUpdated = () => null,
  onFocus = () => null,
}: Props) {
  const layerId = `${rectangleId}-layer`;
  const sourceId = `${rectangleId}-source`;
  const fillLayerId = `${rectangleId}-fill-layer`;
  const lineLayerId = `${rectangleId}-line-layer`;
  const textLayerId = `${rectangleId}-text-layer`;

  const map = useContext(MapContext);
  const statusRef = useRef<{
    drawControl: Control;
    canvas: HTMLCanvasElement | null;
    image: HTMLImageElement | null;
    overlayAdded: boolean;
    poly: any;
    isSelected: boolean;
  }>({
    drawControl: null,
    canvas: null,
    image: null,
    overlayAdded: false,
    poly: null,
    isSelected: false,
  });

  function findFeature(features: any) {
    return features.find(
      (feature: any) =>
        feature.geometry.type === 'Polygon' &&
        feature.properties.id === rectangleId
    );
  }

  function txEdit(focus: boolean = false) {
    statusRef.current.isSelected = true;

    if (statusRef.current.drawControl) {
      statusRef.current.drawControl.changeMode('tx_poly', {
        featureId: rectangleId,
        canTrash: false,
        canScale: true,
        canRotate: true, // only rotation enabled
        singleRotationPoint: true,
        rotationPointRadius: 1.2, // extend rotation point outside polygon
        rotatePivot: TxCenter.Center, // rotate around center
        scaleCenter: TxCenter.Opposite, // scale around opposite vertex
        canSelectFeatures: true,
      });
    }

    if (focus && onFocus) {
      onFocus(rectangleId);
    }
  }

  useEffect(() => {
    if (coordinates) {
      const source = map.getSource(sourceId);
      // Update the coordinates property
      if (source && source.setCoordinates) {
        source.setCoordinates(coordinates?.slice(0, 4));
      }
    }
  }, [map, coordinates, sourceId]);

  function createOverlay() {
    if (statusRef.current.overlayAdded) {
      // already done
      return;
    }

    if (!(map && drawControl)) {
      // not ready
      return;
    }

    if (imageOverlay && !statusRef.current.image) {
      // there is an image, and it hasn't loaded yet.
      return;
    }

    statusRef.current.overlayAdded = true;

    // the polygon and the bounding box that we are adding to the mapbox map
    let cPoly: GeoCoordinateType[];
    let cBox: GeoCoordinateType[];

    if (imageOverlay) {
      // if there is an image overlay, we will need its canvas and
      // also its width and height

      const imageWidth = statusRef.current.image!.naturalWidth;
      const imageHeight = statusRef.current.image!.naturalHeight;

      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (!(coordinates?.length > 0)) {
        cPoly = getMapCenterCoordinates(map, imageWidth, imageHeight);
        cBox = cPoly.slice(0, 4);
      } else {
        // otherwise use the coordinates we already have.
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'GeoCoordinateType[] | undefined'... Remove this comment to see the full error message
        cPoly = mapToGeoJSONPolygon(coordinates);
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        cBox = coordinates.slice(0, 4);
      }

      if (!map.getLayer(sourceId)) {
        // add the image as a source
        map.addLayer({
          id: sourceId,
          type: 'raster',
          source: {
            type: 'image',
            url: imageUrl(imageOverlay, { notFromCache: true }),
            coordinates: cBox,
          },
          layout: {
            visibility: 'visible',
          },
        });
      }

      if (!map.getLayer(layerId)) {
        // then add a layer to the mapbox using the source image for the layer
        map.addLayer({
          id: layerId,
          type: 'raster',
          source: sourceId,
          paint: {
            'raster-opacity': opacity,
            'raster-fade-duration': 0,
          },
        });
      }
    } else {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (coordinates?.length > 0) {
        // no image, and we have coordinates already.
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'GeoCoordinateType[] | undefined'... Remove this comment to see the full error message
        cPoly = mapToGeoJSONPolygon(coordinates);
      } else {
        // otherwise, just add a box somewhere in the middle and give it a
        // default size of x pixels
        cPoly = getMapCenterCoordinates(map, 200, 200);
      }

      if (!map.getSource(sourceId)) {
        // add the polygon as a source
        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [cPoly],
            },
          },
        });
      }

      if (!map.getLayer(fillLayerId)) {
        // then add a layer to the mapbox using the source geo for the layer
        map.addLayer({
          id: fillLayerId,
          source: sourceId,
          type: 'fill',
          paint: {
            'fill-color': fillColor,
            'fill-opacity': 0.5,
          },
        });
      }

      if (!map.getLayer(lineLayerId)) {
        // then add a layer to the mapbox using the source geo for the layer
        map.addLayer({
          id: lineLayerId,
          source: sourceId,
          type: 'line',
          paint: {
            'line-color': fillColor,
            'line-width': 3,
          },
        });
      }
    }

    const poly = polygon([cPoly]);
    poly.id = rectangleId;

    // if an image is being used, set the source of the polygon to the image
    // layer
    poly.properties!.overlaySourceId = sourceId;
    poly.properties!.type = 'overlay';

    if (name && !map.getLayer(textLayerId)) {
      map.addLayer({
        id: textLayerId,
        source: sourceId,
        type: 'symbol',
        layout: {
          'text-field': name,
        },
      });
    }

    // add the polygon to the draw control
    statusRef.current.drawControl.add(poly);

    // keep track of the object.
    statusRef.current.poly = poly;

    // focus it on the mapbox
    txEdit();
  }

  function handleData(e: any) {
    if (
      statusRef.current.isSelected &&
      e.sourceId?.startsWith('mapbox-gl-draw-')
    ) {
      if (
        e.type === 'data' &&
        e.source.data &&
        e.sourceDataType === undefined &&
        e.isSourceLoaded
      ) {
        const geojson = e.source.data;

        const feature = geojson?.features?.find(
          (feature: any) =>
            feature.properties?.user_overlaySourceId === sourceId
        );

        if (!feature) {
          return;
        }

        const updatedCoordinates = feature.geometry.coordinates[0].slice(0, 4);

        if (map) {
          const source = map.getSource(sourceId);

          if (source) {
            if (imageOverlay) {
              source.setCoordinates(updatedCoordinates);
            } else {
              source.setData({
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [mapToGeoJSONPolygon(updatedCoordinates)],
                },
              });
            }
          }
        }

        // only update if something has changed.
        if (
          updatedCoordinates.some(
            // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'lat' implicitly has an 'any' type... Remove this comment to see the full error message
            ([lat, lng], i: any) =>
              coordinates?.[i]?.[0] !== lat && coordinates?.[i]?.[1] !== lng
          )
        ) {
          onCoordinatesUpdated(updatedCoordinates);
        }
      }
    }
  }

  function handleClick(e: any) {
    const feature = findFeature(map.queryRenderedFeatures(e.point));

    if (feature) {
      txEdit(true);
    }
  }

  function handleSelectionChange(e: any) {
    const feature = e.features?.find(
      (feature: any) => feature.id === rectangleId
    );

    if (feature) {
      txEdit(true);
    } else {
      statusRef.current.isSelected = false;
    }
  }

  useLayoutEffect(() => {
    // in case none of the other triggers caused this function to be called
    // call it anyways after 1s
    const timeout = setTimeout(createOverlay, 1000);

    map.on('data', handleData);
    map.on('click', handleClick);
    map.on('touchstart', handleClick);
    map.on('draw.selectionchange', handleSelectionChange);

    return () => {
      clearTimeout(timeout);

      if (!map) {
        return;
      }

      map.off('data', handleData);
      map.off('click', handleClick);
      map.off('touchstart', handleClick);
      map.off('draw.selectionchange', handleSelectionChange);

      try {
        if (map?.getLayer(fillLayerId)) {
          map.removeLayer(fillLayerId);
        }

        if (map?.getLayer(lineLayerId)) {
          map.removeLayer(lineLayerId);
        }

        if (map?.getLayer(textLayerId)) {
          map.removeLayer(textLayerId);
        }

        // remove the source last since the layers depend on it
        if (map?.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      } catch (err) {
        // map may have unloaded.
      }
    };
  }, []);

  useLayoutEffect(() => {
    // make sure the overlay is created.
    if (drawControl) {
      statusRef.current.drawControl = drawControl;
    }

    createOverlay();
  }, [drawControl, imageOverlay]);

  useLayoutEffect(() => {
    if (!map || !statusRef.current.overlayAdded) {
      return;
    }

    if (!statusRef.current.isSelected) {
      txEdit();
    }

    const color = isSelected ? colors.interactiveBlue : fillColor;

    try {
      map.setPaintProperty(fillLayerId, 'fill-color', color);
      map.setPaintProperty(lineLayerId, 'line-color', color);
    } catch (err) {
      // ok to ignore
    }
  }, [isSelected]);

  useLayoutEffect(() => {
    if (!coordinates) {
      return;
    }

    const source = map.getSource(sourceId);

    if (!source) {
      return;
    }

    if (!imageOverlay) {
      // add the polygon as a source
      source.setData({
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'GeoCoordinateType[]' is not assi... Remove this comment to see the full error message
          coordinates: [mapToGeoJSONPolygon(coordinates)],
        },
      });
    } else {
      source.setCoordinates(coordinates);
    }
  }, [coordinates]);

  return (
    <img
        className={styles.floorPlanImage}
        src={imageUrl(imageOverlay, { notFromCache: true })}
        alt="hidden"
        crossOrigin="anonymous"
        onLoad={({ target }) => {
          // @ts-expect-error ts-migrate(2740) FIXME: Type 'EventTarget' is missing the following proper... Remove this comment to see the full error message
          statusRef.current.image = target;
          createOverlay();
        }}
    />
  );
}

import React from 'react';

import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { H4, S } from 'components/typography';

import styles from './LoginMagicLinkRequest.scss';

const TRANSLATION_KEYS = {
  title: 'web.login.magicLink.title',
  description: 'web.login.magicLink.description',
  requestButton: 'web.login.magicLink.requestButton',
  redirectButton: 'web.login.magicLink.redirectButton',
};

type Props = {
  onClick: () => void;
  isLoading: boolean;
};

function LoginMagicLinkRequest({ onClick, isLoading }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.titleContainer}>
        <H4 mb={4}>{t(TRANSLATION_KEYS.title)}</H4>
      </div>
      <S>{t(TRANSLATION_KEYS.description)}</S>
      <div className={styles.buttonContainer}>
        <Button
          color="inherit"
          type="button"
          size="large"
          variant="contained"
          onClick={onClick}
          loading={isLoading}
        >
          {t(TRANSLATION_KEYS.requestButton)}
        </Button>
        <br />
        <Link
          className={styles.buttonRedirect}
          to={routes.manualLogin}
          data-test="LogInUsingPassword"
        >
          {t(TRANSLATION_KEYS.redirectButton)}
        </Link>
      </div>
    </>
  );
}

export default LoginMagicLinkRequest;

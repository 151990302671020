import React, { useState, useContext } from 'react';

import { Icon, Button } from 'design-system-web';
import { Flex } from 'components';
import { logout, history } from 'helpers';
import { graphql } from 'lane-shared';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { getClient } from 'lane-shared/apollo';
import { routes, config as sharedConfig } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { pause } from 'lane-shared/helpers';
import { getPrimaryEmail } from 'lane-shared/helpers/user';
import { useAuthClearSession } from 'components/onboarding/Onboarding/useAuthClearSession';

import { Alert, Modal } from 'components/lds';
import Box from 'components/lds/Box';

import Typography from '../../../../components/lds/Typography';

import styles from './AccountButtons.scss';

/**
 * Box that contains buttons related to user account
 */
export default function AuthButton() {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(
    false
  );
  const { isAuthenticated } = useAuth0();
  const authClearSession = useAuthClearSession();

  const { redirectionServiceURL } = sharedConfig.auth0;

  if (!redirectionServiceURL) {
    throw new Error('config.auth0.redirectServiceURL is required');
  }

  async function onResetPassword() {
    const email = getPrimaryEmail(user?.logins);

    setLoading(true);

    try {
      await pause(500);
      await getClient().mutate({
        mutation: graphql.user.forgotPassword,
        variables: {
          email,
        },
      });
      window.Toast.show(t('Check your email!'));
    } catch (ex) {
      // errors don't matter here.
    }

    setLoading(false);
  }

  async function onRequestAccountDeletion() {
    setLoading(true);

    await getClient().mutate({
      mutation: graphql.user.requestAccountDeletion,
      variables: {
        userId: user!._id,
      },
      refetchQueries: ['initializeUser'],
    });

    window.Toast.show(
      t(
        'Your account deletion request has been received and will be deleted within 30 days.'
      ),
      10000
    );

    setLoading(false);
    setIsAccountDeletionModalOpen(false);
  }

  async function onLogout() {
    if (isAuthenticated) {
      await authClearSession();
    } else {
      await logout();
      history.push(routes.login);
    }
  }

  return (
    <Box>
      <Button
        className={styles.button}
        loading={loading}
        onClick={onResetPassword}
        testId="resetPasswordButton"
      >
        {t('Reset password')}
      </Button>
      <Button
        testId="logoutButton"
        className={styles.button}
        onClick={onLogout}
      >
        {t('Logout')}
      </Button>
      <Flex justify="flex-end" className={styles.deleteAccountLinkContainer}>
        {user?.requestAccountDeletionByDate ? (
          <Alert type="warning">
            {t('Account deletion has been requested.')}
          </Alert>
        ) : (
          <Link
            className={styles.deleteAccountLink}
            to={{}}
            onClick={() => setIsAccountDeletionModalOpen(true)}
          >
            {t('Delete Account')}
          </Link>
        )}
      </Flex>
      <Modal
        isOpen={isAccountDeletionModalOpen}
        onClose={() => setIsAccountDeletionModalOpen(false)}
        size="small"
      >
        <Flex justify="flex-start" direction="column">
          <Flex>
            <Icon
              set="FontAwesome"
              name="user-slash"
              type="far"
              className={styles.deleteAccountIcon}
            />
          </Flex>
          <Flex className={styles.deleteAccountTextHeader}>
            <h2>{t('Delete Account')}</h2>
          </Flex>
          <Flex className={styles.deleteAccountTextBody}>
            <Typography type="s" mode="primary">
              {t(
                'Deleting your account is permanent and you will lose access to all content and features available on the app. All of your personal information will also be deleted. To confirm account deletion, click on the button below.'
              )}
            </Typography>
          </Flex>
          <Button
            disabled={loading}
            loading={loading}
            onClick={() => onRequestAccountDeletion()}
            testId="modalButtonCancel"
          >
            {t('Delete')}
          </Button>
        </Flex>
      </Modal>
    </Box>
  );
}

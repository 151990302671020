import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tenant } from 'lane-shared/types/Tenant';
import history from 'helpers/history';
import { routes } from 'lane-shared/config';
import { Link } from 'react-router-dom';
import { Table, Thumbnail, H4 } from 'design-system-web';
import styles from './styles.scss';

type Props = {
  tenants: Tenant[];
  propertyChannelSlug: string;
  removeTenant: (id: string, name: string) => void;
};

type Row = {
  id: string;
  name: string;
  type: string;
};

export const TenantsList = ({
  tenants,
  propertyChannelSlug,
  removeTenant,
}: Props) => {
  const { t } = useTranslation();

  const data: Row[] =
    tenants &&
    tenants.map((tenant: { id: string; name: string; type: string }) => {
      return {
        id: tenant.id,
        name: tenant.name,
        type: tenant.type,
      };
    });

  const columns = [
    {
      key: 'thumbnail',
      type: 'text',
      disableSorting: true,
      renderCell: (_: string, row: Row) => <Thumbnail name={row.name} />,
    },
    {
      key: 'name',
      header: t('web.pages.portal.admin.units.details.tenants.table.name'),
      type: 'text',
      renderCell: (name: string, row: Row) => {
        const url = routes.channelAdminRelationshipsTenantView
          .replace(':tenantId', row.id)
          .replace(':id', propertyChannelSlug);
        return (
          <Link to={url} style={{ textDecoration: 'underline' }}>
            {name}
          </Link>
        );
      },
    },
    {
      key: 'type',
      header: t('web.pages.portal.admin.units.details.tenants.table.type'),
      type: 'text',
      renderCell: (type: string) => <span>{type || '-'}</span>,
    },
  ];

  const rowActions = [
    {
      label: t(
        'web.pages.portal.admin.units.details.tenants.table.actions.viewDetails'
      ),
      onClick: (tenant: Row) => {
        const url = routes.channelAdminRelationshipsTenantView
          .replace(':tenantId', tenant.id)
          .replace(':id', propertyChannelSlug);
        history.push(url);
      },
    },
    {
      label: t(
        'web.pages.portal.admin.units.details.tenants.table.actions.removeTenant'
      ),
      onClick: (tenant: Row) => removeTenant(tenant.id, tenant.name),
    },
  ];

  return (
    <div className={styles.tenants}>
      <div>
        <H4>{t('web.pages.portal.admin.units.details.tenants.header')}</H4>
      </div>
      <div>
        <Table<Row>
          columns={columns}
          data={data}
          pagination="client"
          rowActions={rowActions}
          emptyMessage={t(
            'web.pages.portal.admin.units.details.tenants.table.emptyMessage'
          )}
        />
      </div>
    </div>
  );
};

import React, { useState } from 'react';

import { Button, ControlMenu, IconButton, ErrorMessage } from 'components';
import { useChannelAdminContext, useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { updateChannel } from 'lane-shared/graphql/mutation';
import { defaultChannelPages } from 'lane-shared/helpers/constants';
import { useChannelPagesForAdmin } from 'lane-shared/hooks';

import { AdminPage } from 'components/layout';

import TabGrid from './TabGrid';
import TabList from './TabList';

import styles from './TabCenter.scss';

const { profileTab } = defaultChannelPages;

const views = ['list', 'th-large'] as const;

export default function TabCenter() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [query, , makeUrl] = useQueryString({ view: views[0] });
  const { channelPages, refetch } = useChannelPagesForAdmin({
    channelId: channel?._id,
  });

  async function onChannelPagesUpdated(channelPages: any) {
    setLoading(true);

    const updatedPages = channelPages.filter(
      ({ _id }: any) => _id !== profileTab._id
    );

    try {
      await getClient().mutate({
        mutation: updateChannel,
        variables: {
          channel: {
            _id: channel?._id,
            pages: updatedPages.map(({ _id, _order }: any) => ({
              _id,
              _order,
            })),
          },
        },
      });

      refetch();
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  return (
    <AdminPage className={styles.TabCenter}>
      <ControlMenu>
        <h1>{channel?.name}</h1>
        <hr />
        <span className={styles.tabNumber}>
          {t`web.admin.channel.tabManagement.tabCenter.numberOfTabs`}:{' '}
          {channelPages.length}/5
        </span>
        <Link to={makeUrl({ view: 'list' })}>
          <IconButton icon="list" selected={query.view === 'list'} inverted />
        </Link>
        <Link to={makeUrl({ view: 'grid' })}>
          <IconButton
            icon="th-large"
            selected={query.view === 'th-large'}
            inverted
          />
        </Link>
        <Link
          /* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */
          to={routes.channelAdminTabsNew.replace(':id', channel?.slug)}
          style={channelPages.length > 4 ? { pointerEvents: 'none' } : {}}
        >
          <Button
            testId="newTab"
            variant="contained"
            disabled={channelPages.length > 4}
          >
            {t`web.admin.channel.tabManagement.tabCenter.newTab`}
          </Button>
        </Link>
      </ControlMenu>
      <ErrorMessage error={error} />
      {query.view === 'list' && (
        <TabList
          loading={loading}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ loading: boolean; channel: ChannelType | n... Remove this comment to see the full error message
          channel={channel}
          channelPages={channelPages}
          onChannelPagesUpdated={onChannelPagesUpdated}
        />
      )}
      {query.view === 'grid' && (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: ChannelType | null; channelPages:... Remove this comment to see the full error message
        <TabGrid channel={channel} channelPages={channelPages} />
      )}
    </AdminPage>
  );
}

import React from 'react';

import cx from 'classnames';

// @ts-expect-error ts-migrate(6137) FIXME: Cannot import type declaration files. Consider imp... Remove this comment to see the full error message
import { paymentMethods } from '@types/stripe';

import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { LONG_DATE } from 'lane-shared/helpers/constants/dates';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';
import { PaymentFeatureInteractionData } from 'lane-shared/types/features/PaymentFeatureInteractionData';

import CreditCard from 'components/payments/CreditCard';

import InfoRow from './InfoRow';
import useUserLocale from 'hooks/useUserLocale';

import styles from './PaymentFeatureStripeData.scss';

type Props = {
  className?: string;
  style: React.CSSProperties;
  payment: PaymentFeatureInteractionData | null | undefined;
};

export default function PaymentFeatureStripeData({
  className,
  style,
  payment,
}: Props) {
  const stripeData = payment?.stripeData;
  const locale = useUserLocale();

  if (!payment || !stripeData || !payment.quote) {
    return null;
  }

  const currencyFormat = currencyFormatter({
    locale,
    currency: payment.quote.currency,
  });

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'IPaymentMethodDetails | null' is not assigna... Remove this comment to see the full error message
  const paymentMethodDetails: paymentMethods.ICardPaymentMethod | null =
    stripeData.charge?.payment_method_details;

  return (
    <div
      className={cx(styles.PaymentFeatureStripeData, className)}
      style={style}
    >
      {stripeData.refund && (
        <div className={styles.refund}>
          <h1>
            Refunded on{' '}
            {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message */}
            {dateFormatter(stripeData.refund.created * 1000, LONG_DATE)}
          </h1>

          <InfoRow label="Refunded Amount" isId>
            {currencyFormat(stripeData.refund.amount / CURRENCY_CONVERSION)}
          </InfoRow>

          <a
            className={styles.receipt}
            href={stripeData.charge?.receipt_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Refund Receipt
          </a>
        </div>
      )}

      <InfoRow label="Transaction ID" isId>
        {stripeData.chargeId}
      </InfoRow>

      {paymentMethodDetails?.card && (
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'children' is missing in type '{ brand: "... Remove this comment to see the full error message
        <CreditCard
          brand={stripeData.cardBrand}
          last4={paymentMethodDetails.card.last4}
          expMonth={paymentMethodDetails.card.exp_month}
          expYear={paymentMethodDetails.card.exp_year}
        />
      )}
    </div>
  );
}

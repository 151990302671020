import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import useMagicLinkSignIn from '../hooks/useMagicLinkSignIn';
import LoginMagicLinkRequest from './LoginMagicLinkRequest';
import LoginMagicLinkSent from './LoginMagicLinkSent';

import styles from './index.scss';

type Props = {
  userEmail: string;
};

const TRANSLATION_KEYS = {
  errorMessage: 'web.login.magicLink.error',
};

function LoginMagicLink({ userEmail }: Props) {
  const [isSent, setIsSent] = useState(false);

  const { t } = useTranslation();
  const { sendMagicLink, isLoading } = useMagicLinkSignIn({
    onSuccess: () => {
      setIsSent(true);
    },
    onError: () => {
      window.Toast.show(t(TRANSLATION_KEYS.errorMessage));
    },
  });

  async function handleClick() {
    await sendMagicLink(userEmail);
  }

  async function handleResend() {
    await sendMagicLink(userEmail);
  }

  return (
    <div className={styles.container}>
      {isSent ? (
        <LoginMagicLinkSent
          userEmail={userEmail}
          onResend={handleResend}
          isLoading={isLoading}
        />
      ) : (
        <LoginMagicLinkRequest onClick={handleClick} isLoading={isLoading} />
      )}
    </div>
  );
}

export default LoginMagicLink;

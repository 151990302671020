import { useMemo } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';

export type MetatagUsageType = {
  _id: LaneType.UUID;
  type: 'DraftContent' | 'Section';
};

export type MetatagUsageReturn = {
  Section: MetatagUsageType[];
  DraftContent: MetatagUsageType[];
};

const metatagUsageQuery = gql`
  query metatagUsage($id: UUID!) {
    metatagUsage(id: $id) {
      _id
      type
    }
  }
`;

export default function useMetatagUsage({
  metatagId,
}: {
  metatagId: LaneType.UUID;
}) {
  const { data, loading, error } = useQuery(metatagUsageQuery, {
    skip: !metatagId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: metatagId,
    },
  });

  const metatagUsage: MetatagUsageReturn = useMemo(() => {
    const usage = data?.metatagUsage || [];

    return {
      Section: usage.filter((u: any) => u.type === 'Section'),
      DraftContent: usage.filter(
        (u: any) => u.type === 'Content' || u.type === 'DraftContent'
      ),
    };
  }, [data?.metatagUsage]);

  return {
    metatagUsage,
    loading,
    error,
  };
}

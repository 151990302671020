import React from 'react';

import cx from 'classnames';

import ValidationMessage from 'components/general/ValidationMessage';

import styleMap from '../../styleMap';

import styles from './TextInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function TextInputBlock({
  text,
  maxLength = 2048,
  placeholder = 'Enter text…',
  disabled = false,
  onInput = () => {},
  isValid = true,
  validationMessage = 'Required.',
  className,
  style,
}: any) {
  return (
    <>
      <input
        type="text"
        value={text || ''}
        className={cx(styles.TextInputBlock, className)}
        style={styleMap(false, style)}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => onInput(e.target.value)}
      />
      {!isValid && <ValidationMessage errors={[validationMessage]} />}
    </>
  );
}

export default TextInputBlock;

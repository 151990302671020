import React from 'react';

import ControlMenu from 'components/general/ControlMenu';

import stripeConnectButtonImage from 'static/img/stripe-connect-button.png';

import styles from './StripeConnectSetup.scss';

export default function StripeContinueOnboarding({ merchantAccount }: any) {
  return (
    <div className={styles.StripeConnectSetup}>
      <div className={styles.message}>
        <h3>Continue your on-boarding with Stripe Connect</h3>
        <p>
          Your setup is almost complete! Use the link below to finish connecting
          a Stripe account.
        </p>
        <ControlMenu>
          <a href={merchantAccount.onboardingLink.url} target="_self">
            <img
              className={styles.stripeButton}
              src={stripeConnectButtonImage}
              alt="Connect your Lane account to Stripe"
            />
          </a>
        </ControlMenu>
      </div>
    </div>
  );
}

import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import {
  Loading,
  Pagination,
  ControlMenu,
  Input,
  IconButton,
  Button,
} from 'components';
import gql from 'graphql-tag';
import { makeFileDownload } from 'helpers';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Query } from '@apollo/client/react/components';
import { Checkbox } from '@material-ui/core';

import { getAdminClient } from 'lane-shared/apollo';
import { resetUserPasswordsAdmin } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { simpleDate, fromNow } from 'lane-shared/helpers/formatters';
import { PasswordResetType } from 'lane-shared/types/PasswordResetType';
import { UserType } from 'lane-shared/types/User';

import { AdminPage, Flex } from 'components/layout';

import styles from './styles.scss';
import { useLegacyPagination } from './hooks/useLegacyPagination';

const queryUsers = gql`
  query queryUsers($search: AdminUserSearch!, $pagination: PaginationInput!) {
    users(search: $search, pagination: $pagination) {
      pageInfo {
        start
        perPage
        total
      }
      items {
        _id
        _created
        _updated
        status
        name
        lastSeen
        isSuperUser
        logins {
          _id
          key
          type
          isPrimary
        }
        profile {
          _id
          name
        }
      }
    }
  }
`;
const sorts = ['_created', '_updated', 'name', 'lastSeen', 'isSuperUser'];

const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_NAME,
  SORT_LAST_SEEN,
  SORT_IS_SUPER_USER,
] = sorts;

const sortOrders = ['asc', 'desc'];
const [SORT_ASC, SORT_DESC] = sortOrders;

const headers = [
  { label: '' },
  { label: 'Created', sort: SORT_CREATED },
  { label: 'Last Update', sort: SORT_UPDATED },
  { label: 'Name', sort: SORT_NAME },
  { label: 'Primary Email' },
  { label: 'Status' },
  { label: 'Last Seen', sort: SORT_LAST_SEEN },
  { label: 'Super User', sort: SORT_IS_SUPER_USER },
];

type UserRowType = {
  user: UserType;
  checked: boolean;
  handleUserChecked: (checked: boolean, user: UserType) => void;
  className?: any;
};

function downloadCSV(rows: PasswordResetType[]) {
  // Headers
  let csvContent = 'name,email,url\n';

  // Data
  csvContent += rows
    .map(row => `"${row.name}",${row.email},${row.resetUrl}`)
    .join('\n');

  makeFileDownload({
    name: 'password-reset.csv',
    contents: csvContent,
    type: 'text/csv',
  });
}

function UserRow({ user, checked, handleUserChecked, className }: UserRowType) {
  const primaryLogin = user.logins?.find(
    login => login.type === 'Email' && login.isPrimary
  );
  const primaryLoginEmail = (primaryLogin && primaryLogin.key) || '';

  return (
    <tr key={user._id} className={className}>
      <td>
        <Checkbox
          checked={checked}
          onChange={e => handleUserChecked(e.target.checked, user)}
          color="primary"
        />
      </td>
      <td>{simpleDate(user._created)}</td>
      <td>{fromNow(user._updated)}</td>
      <td>
        <Link to={`users/${user._id}/edit`}>{user.profile.name}</Link>
      </td>
      <td>{primaryLoginEmail}</td>
      <td>{user.status}</td>
      <td>{fromNow(user.lastSeen)}</td>
      <td>{user.isSuperUser && 'Yes'}</td>
    </tr>
  );
}

function UserManagement() {
  const { t } = useTranslation();
  const {
    page,
    perPage,
    sort,
    sortOrder: order,
    updatePageNumber,
    updateSortAndOrder,
  } = useLegacyPagination({ page: '0', perPage: '25' });

  const [search, setSearch] = useState('');
  const [resetPasswordsLoading, setResetPaswordsLoading] = useState(false);
  const [resetUsers, setResetUsers] = useState<UserType[]>([]);
  const [debouncedSearch] = useDebounce(search, 500);

  function handleUserChecked(checked: any, user: any) {
    if (!checked) {
      setResetUsers(
        resetUsers.filter(resetUsers => resetUsers._id !== user._id)
      );
      return;
    }

    setResetUsers([...resetUsers, user]);
  }

  async function resetUserPasswords() {
    try {
      await window.Alert.confirm({
        title: t('Reset Passwords'),
        message: t(
          'Are you sure you want to reset the passwords for the selected users?'
        ),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    setResetPaswordsLoading(true);
    await pause(300);

    try {
      const { data } = await getAdminClient().mutate({
        mutation: resetUserPasswordsAdmin,
        variables: { ids: resetUsers.map(user => user._id) },
      });

      window.Alert.alert({
        title: 'Reset Codes',
        children: (
          <div style={{ paddingTop: '2em' }}>
            <Button
              startIcon={<Icon name="download" />}
              onClick={() => downloadCSV(data.resetUserPasswordsAdmin)}
            >
              {t('Download CSV')}
            </Button>
          </div>
        ),
      });
    } catch (err) {
      window.Alert.alert({
        title: 'Failed',
        error: err,
      });
    }

    setResetPaswordsLoading(false);
  }

  const selectedSort = sort || SORT_NAME;
  const selectedOrder = order || SORT_ASC;
  const reverseOrder = selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC;

  const variables = {
    search: {
      sortBy: {
        key: selectedSort,
        dir: selectedOrder,
      },
    },
    pagination: {
      start: page * perPage,
      perPage,
    },
  };

  if (debouncedSearch && debouncedSearch.length > 3) {
    (variables.search as any).search = {
      type: 'like',
      value: debouncedSearch,
    };
  }

  return (
    <AdminPage className={styles.UserManagement}>
      <ControlMenu>
        <Input
          style={{ flex: 1 }}
          onChange={search => setSearch(search)}
          value={search}
          placeholder="Search…"
          icon="search"
          showClear
          testId="SearchTextBox"
        />
      </ControlMenu>

      <Query query={queryUsers} variables={variables} client={getAdminClient()}>
        {({ data, loading }: { data: any; loading: boolean | undefined }) => {
          if (!data || !data.users) {
            return <Loading />;
          }

          const users: UserType[] = data.users.items || [];
          const pageInfo = data.users.pageInfo || {};

          return (
            <>
              <Pagination
                loading={loading}
                total={pageInfo.total}
                page={page}
                onPage={updatePageNumber}
                perPage={perPage}
                testId="PaginatedPages"
              />
              <div className={styles.resetPasswordButtonContainer}>
                <Button
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"inher... Remove this comment to see the full error message
                  color="primary"
                  variant="contained"
                  loading={resetPasswordsLoading}
                  onClick={resetUserPasswords}
                  disabled={resetUsers.length === 0 || loading}
                >
                  {t('Reset User Passwords')}
                </Button>
              </div>
              <table data-test="reset-user-table">
                <thead>
                  <tr>
                    {headers.map(header => (
                      <th key={header.label}>
                        <Flex align="center">
                          <span>{header.label}</span>
                          {header.sort && (
                            <IconButton
                              onClick={() =>
                                updateSortAndOrder(header.sort, reverseOrder)
                              }
                              inverted
                              icon={
                                selectedOrder === SORT_ASC
                                  ? 'sort-amount-down-alt'
                                  : 'sort-amount-up'
                              }
                              className={cx(styles.sortButton, {
                                [styles.selected]: selectedSort === header.sort,
                              })}
                              selected={selectedSort === header.sort}
                            />
                          )}
                        </Flex>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {resetUsers.map(user => {
                    return (
                      <UserRow
                        key={user._id}
                        user={user}
                        checked={resetUsers.some(
                          resetUser => resetUser._id === user._id
                        )}
                        handleUserChecked={handleUserChecked}
                        className={styles.checkedUserRow}
                      />
                    );
                  })}
                  {users.map(user => {
                    return (
                      <UserRow
                        key={user._id}
                        user={user}
                        checked={resetUsers.some(
                          resetUser => resetUser._id === user._id
                        )}
                        handleUserChecked={handleUserChecked}
                      />
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        }}
      </Query>
    </AdminPage>
  );
}

export default UserManagement;

import { useContext } from 'react';

import { AnalyticsContext } from 'lane-shared/contexts';
import { useFlag } from 'lane-shared/hooks';
import { FeatureNameEnum } from 'lane-shared/types/features/FeatureNameEnum';

const FEATURE_FLAG = 'consumer-experience.time-slots.mixpanel';

export function useReservableAnalytics() {
  const analytics = useContext(AnalyticsContext);
  const canUseMixpanel = useFlag(FEATURE_FLAG, false);

  function trackReservablePublished(event: string, content: any) {
    if (!canUseMixpanel) {
      return;
    }

    const reservables = content?.features?.filter(
      (feature: any) => feature.type === FeatureNameEnum.Reservable
    );

    if (reservables) {
      reservables.forEach((reservable: any) => {
        analytics.track(event, {
          id: content._id,
          channelId: content.channel?._id,
          channelName: content.channel?.name,
          unitType: reservable.feature?.unitType,
          uiType: reservable.feature?.uiType,
          useCustomTimeSlots: reservable.feature?.useCustomTimeSlots,
          isWaitlistEnabled: reservable.feature?.isWaitlistEnabled,
        });
      });
    }
  }

  return {
    trackReservablePublished,
  };
}

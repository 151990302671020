import { ChannelType } from './ChannelType';
import { DocumentType } from './DocumentType';
import { IntegrationProviderEnum } from './integrations/IntegrationEnums';

export enum ContractUnitEnum {
  SquareFoot = 'SF',
  User = 'User',
  FlatFee = 'Flat Fee',
  SiteLicence = 'Site Licence',
}

export enum ChannelContractNameEnum {
  Platform = 'Platform',
  Whitelabel = 'Whitelabel',
  Perks = 'Perks',
  Other = 'Other',
}

export type ChannelContractLineItemType = DocumentType & {
  name: ChannelContractNameEnum | IntegrationProviderEnum;
  channelContract: DocumentType;
  unitType: ContractUnitEnum;
  units: number;
  currency: string;
  pricePerUnit: number;
  subscriptionStartDate: Date | null;
  estimatedSubscriptionStartDate: Date | null;
  termLengthMonths: number;
  settings: any;
};

export type ChannelContractType = DocumentType & {
  channel: ChannelType;
  contactUser: DocumentType | null;
  salesUser: DocumentType | null;
  managerUser: DocumentType | null;
  supportUser: DocumentType | null;
  lastContact: Date;
  lineItems: ChannelContractLineItemType[];
  settings: any;
};

import React, { useContext, useMemo, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { ChannelsContext, UserDataContext } from 'lane-shared/contexts';
import { getQueryString } from 'lane-shared/helpers';
import { useContentQuery } from 'lane-shared/hooks';
import useChannelPages from 'lane-shared/hooks/useChannelPages';

import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import ContentPage from 'components/lane/ContentPage';
import Box from 'components/layout/Box';

import styles from './TabRenderer.scss';

type Props = {
  tabSlug: string;
  channelSlug: string;
};

export default function TabRenderer({ tabSlug, channelSlug }: Props) {
  // or it maybe in our current pages.
  const { pages } = useContext(ChannelsContext);
  const { user } = useContext(UserDataContext);
  const history = useHistory();

  const { pages: channelPages, error, accessRestricted } = useChannelPages({
    channelSlug,
  });

  const page = useMemo(() => {
    let page = pages.find(
      (page: any) => page.slug === tabSlug && page.channel.slug === channelSlug
    );

    if (!page) {
      page = channelPages.find((page: any) => page.slug === tabSlug);
    }

    return page;
  }, [tabSlug, channelSlug, channelPages]);

  // load the required content for this guy.
  const { content: tabContent, error: contentError } = useContentQuery({
    contentId: page?.content?._id,
    forPublic: !user,
    ignoreCache: true,
  });

  useEffect(() => {
    if (accessRestricted) {
      history.push(
        routes.restricted +
          getQueryString({
            channelSlug,
            type: 'channel',
            tabSlug,
          })
      );
    }
  }, [accessRestricted]);

  if (error || contentError) {
    return (
      <Box p={7}>
        <ErrorMessage error={error || contentError} />
      </Box>
    );
  }

  if (!page || !tabContent) {
    return <Loading />;
  }

  return (
    <div className={styles.TabRenderer}>
      <ContentPage
        className={styles.TabRenderer}
        content={tabContent}
        onInteractionCreated={() => {}}
      />
    </div>
  );
}

import gql from 'graphql-tag';

import {
  FullWebhookFragment,
  PublicUserFragment,
} from 'lane-shared/graphql/fragments';

export default gql`
  ${PublicUserFragment}
  ${FullWebhookFragment}

  mutation updateWebhookMutation($webhook: WebhookInput!) {
    updateWebhook(webhook: $webhook) {
      ...FullWebhookFragment
    }
  }
`;

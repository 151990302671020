import React, { useState } from 'react';

import { ErrorMessage, TextArea, Input } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { AdminPage, PageHeader } from 'lane-web/src/components/layout';

import { createChargeCodeMutation } from '../helpers/createChargeCodeMutation';

import styles from '../styles.scss';
import { Button } from 'design-system-web';

export interface ChargeCodeDetails {
  chargeCode: string;
  glCode: string;
  description: string;
}

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
  className?: string;
};

export function CreateChargeCodeForm({ breadcrumbs }: Props) {
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const { t } = useTranslation();
  const channel_slug = channel?.slug || '';
  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    channel_slug
  );

  const [error, setError] = useState<Error | undefined>(undefined);
  const [
    newChargeCodeDetails,
    setNewChargeCodeDetails,
  ] = useState<ChargeCodeDetails>({
    chargeCode: '',
    glCode: '',
    description: '',
  });

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setNewChargeCodeDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const isSaveButtonDisabled = (): boolean => {
    const { chargeCode, glCode } = newChargeCodeDetails;

    return !(chargeCode && glCode);
  };

  const handleSaveChargeCode = async () => {
    try {
      await createChargeCodeMutation(
        {
          ...newChargeCodeDetails,
        },
        channel?._id || ''
      );
      history.goBack();
      window.Toast.show(
        t('abp.productsServices.createChargeCode.createSuccessful')
      );
    } catch (err) {
      console.error(err);
      setError(err as Error);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const defaultBreadcrumbs = [
    {
      label: t('adp.productsServices.breadcrumb'),
      url: productServicesLink,
    },
    { label: t('adp.productsServices.createChargeCode.breadcrumb') },
  ];

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.productsServices.createChargeCode.heading')}
        headerLevel="h3"
        breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
        actionButtons={[
          {
            label: t('abp.productsServices.createChargeCode.cancel'),
            type: 'secondary',
            onClick: handleCancel,
          },
          {
            label: t('abp.productsServices.createChargeCode.save'),
            type: 'primary',
            onClick: handleSaveChargeCode,
            disabled: isSaveButtonDisabled(),
          },
        ]}
      />
      <div className={styles.createChargeCodeForm}>
        <Input
          type="text"
          label={t('abp.productsServices.createChargeCode.chargeCode')}
          fixedLabel
          onChange={(value: string) => {
            handleFieldUpdate(value, 'chargeCode');
          }}
          fieldName="charge code"
          className={styles.fieldset}
          value={newChargeCodeDetails.chargeCode}
          isRequired
          testId="chargeCode"
        />
        <Input
          type="text"
          label={t('abp.productsServices.createChargeCode.glCode')}
          fixedLabel
          onChange={(value: string) => {
            handleFieldUpdate(value, 'glCode');
          }}
          fieldName="glcode"
          className={styles.fieldset}
          value={newChargeCodeDetails.glCode}
          isRequired
          testId="glcode"
        />
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.createChargeCode.description')}
          </label>
          <TextArea
            onChange={(value: string) => {
              handleFieldUpdate(value, 'description');
            }}
            value={newChargeCodeDetails.description}
            maxLength={200}
            showLengthIndicator
            minRows={4}
          />
        </fieldset>
      </div>
      <div className={styles.formButtons}>
        <Button
          className={styles.button}
          variant="primary"
          disabled={isSaveButtonDisabled()}
          onClick={handleSaveChargeCode}
          size="large"
        >
          {t('abp.productsServices.createChargeCode.save')}
        </Button>
        <Button
          className={styles.button}
          variant="secondary"
          onClick={handleCancel}
          size="large"
        >
          {t('abp.productsServices.createChargeCode.cancel')}
        </Button>
      </div>
      {error && <ErrorMessage error={error} />}
    </AdminPage>
  );
}

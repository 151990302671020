import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { usePaymentV2FeatureInvoice } from 'lane-shared/domains/billingPayments/hooks';
import { Invoice } from 'lane-shared/domains/billingPayments/types';

import { Loading } from 'lane-web/src/components/general';
import useUserLocale from 'lane-web/src/hooks/useUserLocale';

import { OrderSummary } from 'components/features/Payment/PaymentSummaryV2/OrderSummary';
import { PaymentMethod } from 'graphql-resolver-contracts';

import styles from './styles.scss';

export function InvoiceOrderSummary() {
  const history = useHistory();
  const { t } = useTranslation();
  const locale = useUserLocale();
  const location = useLocation<{ invoice: Invoice }>();
  const invoice = location.state?.invoice;
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [paymentFinished, setPaymentFinished] = useState(false);

  const { orderSummaryData, initiatePayment } = usePaymentV2FeatureInvoice({
    invoice,
    locale,
  });

  function showProcessingPaymentDialog() {
    window.Alert.show({
      title: t('abp.payment.orderSummary.processingPayment'),
      message: t('abp.payment.orderSummary.pleaseDontCloseThisWindow'),
      type: 'loading',
      children: (
        <Loading
          style={{
            marginTop: '0.5em',
            marginBottom: '0.5em',
            fontSize: '5em',
            alignItems: 'center',
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (paymentFinished) {
      history.push(routes.meAccountInvoices);
      window.Toast.show(t('abp.payment.orderSummary.purchaseCompleted'));
    }
  }, [paymentFinished]);

  const onSubmit = useCallback(
    async (
      accountsUserId: string | null,
      selectedPaymentMethod: string | undefined
    ) => {
      if (!invoice?.id) {
        return;
      }
      setLoading(true);
      showProcessingPaymentDialog();

      try {
        await initiatePayment(
          accountsUserId,
          selectedPaymentMethod,
          PaymentMethod.PaymentMethodCreditCard
        );
        setLoading(false);
        setPaymentFinished(true);
        window.Alert.hide();
      } catch (err: any) {
        window.Alert.hide();
        console.error(`initiatePayment error: ${err}`);
        setError(new Error(err.message));
      } finally {
        setLoading(false);
      }
    },
    [invoice?.id, initiatePayment]
  );

  return (
    <div className={styles.invoiceOrderSummary}>
      <OrderSummary
        orderSummaryData={orderSummaryData}
        backgroundImage=""
        loading={loading}
        onSubmit={onSubmit}
        error={error}
      />
    </div>
  );
}

import React, { useState, useContext, useEffect } from 'react';

import { ErrorMessage, ControlMenu, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext } from 'lane-shared/contexts';
import { createChannel } from 'lane-shared/graphql/mutation';
import { pause, getDisplayName } from 'lane-shared/helpers';
import { constructChannel } from 'lane-shared/helpers/channel';
import {
  validateChannelBase,
  validateChannelInfo,
} from 'lane-shared/validation/channel';

import ChannelInfoEdit from 'components/lane/ChannelSettingsEdit/ChannelInfoEdit';

import styles from './styles.scss';

function NewSubChannel({ parent }: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(UserDataContext);
  const [, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [channel, setChannel] = useState(() =>
    constructChannel({ userId: user?._id, parent, isSub: true })
  );

  useEffect(() => {
    if (parent) {
      setChannel(constructChannel({ userId: user?._id, parent, isSub: true }));
    }
  }, [parent]);

  async function validate() {
    try {
      await validateChannelBase.validate(channel, { abortEarly: false });
      await validateChannelInfo.validate(channel, { abortEarly: false });
      setValidation(null);
      return true;
    } catch (err) {
      setValidation(err);
      return false;
    }
  }

  function onChannelUpdated(props: any) {
    setChannel({
      ...channel,
      ...props,
    });

    if (validation) {
      validate();
    }
  }

  async function onCreateChannel() {
    if (!(await validate())) {
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await pause();

      // remove some _ids since this is a create.
      // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
      delete channel._id;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      delete channel.address._id;
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      delete channel.profile._id;
      // cleaning out channel parent data
      delete channel.parent?.name;

      await getClient().mutate({
        mutation: createChannel,
        variables: { channel },
      });

      history.push(`/l/channel/${channel.slug}/admin`);
    } catch (err) {
      setError(err);
      window.Alert.alert({
        title: t('web.admin.channel.subChannel.new.error.title'),
        message: t('web.admin.channel.subChannel.new.error.message'),
        error: err,
      });
    }

    setLoading(false);
  }

  return (
    <div className={styles.NewSubChannel}>
      <ControlMenu>
        <h1>
          {t('web.admin.channel.subChannel.new.title', {
            parentChannel: getDisplayName(parent),
          })}
        </h1>
        <Button
          onClick={onCreateChannel}
          variant="contained"
          testId="createButton"
          disabled={!parent}
        >
          {t('Create')}
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      <section>
        <ChannelInfoEdit
          validation={validation}
          channel={channel}
          onChannelUpdated={onChannelUpdated}
          heading={t('web.admin.channel.subChannel.new.heading', {
            channel: getDisplayName(channel),
          })}
          shouldShowStats
          channelForDataIdentifiers={channel}
          forCreate
        />
      </section>
    </div>
  );
}

export default NewSubChannel;

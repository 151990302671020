import React, { useMemo, useContext } from 'react';

import {
  ContentRendererContext,
  RendererContext,
  AppContext,
} from 'lane-shared/contexts';
import mergeThemes from 'lane-shared/helpers/content/mergeThemes';
import getTextColor from 'lane-shared/helpers/getTextColor';
import useContentRendererBlockContext from 'lane-shared/hooks/contentRenderer/useContentRendererBlockContext';
import { ThemeType } from 'lane-shared/types/Theme';

import BlockRenderer from './BlockRenderer';

type Props = {
  style?: React.CSSProperties;
  theme: ThemeType;
};

// these are needed by the renderer. usually these would be actual values,
// but this is showing an example render.
const editMode = true;
const loading = false;
const disabled = false;
const interaction = {};
const dataValidation = null;
const submitAttempted = false;
const onInteractionUpdated = () => null;
const onSubmit = () => null;
const onLink = () => null;
const onClick = () => null;

export default function ThemePreview({ theme }: Props) {
  const { blocks } = useContext(RendererContext);
  const { whitelabel } = useContext(AppContext);
  const { blockContext, updateBlockContext } = useContentRendererBlockContext();

  const displayTheme = mergeThemes([whitelabel.profile.theme, theme]);

  const providerValue = useMemo(
    () => ({
      content: example,
      theme: {
        ...displayTheme,
        palette: {
          ...displayTheme.palette,
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          text: getTextColor(displayTheme.palette?.background),
        },
      },
      loading,
      disabled,
      interaction,
      dataValidation,
      submitAttempted,
      onInteractionUpdated,
      onSubmit,
      onLink,
      onClick,
      editMode,
      blockContext,
      updateBlockContext,
    }),
    [example, theme]
  );

  return (
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ content: { name: string; description: stri... Remove this comment to see the full error message
    <ContentRendererContext.Provider value={providerValue}>
      <BlockRenderer
        top={example.block}
        isTop
        example={example}
        blocks={blocks}
      />
    </ContentRendererContext.Provider>
  );
}

const example = {
  name: 'Example Content',
  description: 'Example Content',
  tags: [],
  category: 'None',
  color: 'rgba(255,255,255,1)',
  backgroundColor: 'rgba(102,102,102,1)',
  resetPeriod: 'Never',
  block: {
    _id: 'acc50af9-daf8-475e-97fe-6566592c7448',
    name: 'New Static',
    blockId: '2wECcIaKSKjzQNKikhIeO7',
    properties: {
      children: [
        {
          _id: '3c38b462-7752-47b3-bd91-6968c3de970c',
          blockId: '3IHP56bNukaLFqlDqyFkFI',
          properties: {
            size: 'Medium',
            title: 'Example',
            images: ['5J9opPhOzJdH1tQUsjB21F'],
            subtitle:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rhoncus ex eu aliquam laoreet.',
            textColor: '#FFFFFF',
            useGradient: true,
          },
          blockVersion: 0,
        },
        {
          _id: '84c85cf4-d612-494e-83fa-ea80375a2316',
          blockId: '3MySOQd6QCKAv9HiktQe1w',
          properties: {
            text:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut rhoncus ex eu aliquam laoreet. In hac habitasse platea dictumst. Curabitur quam quam, gravida id lectus quis, iaculis tincidunt tellus. Maecenas ultrices congue turpis. ',
            header: 'Example paragraph with header.',
            textAlign: 'left',
          },
          blockVersion: 0,
        },
        {
          _id: 'c1696c95-12b2-4959-b1e5-99bce7173d0f',
          blockId: '5OwQXXF24XxWE3JpJ7avbl',
          properties: {
            icon: {
              set: 'Feather',
              name: 'mail',
              size: 14,
            },
            text: 'Example icon',
          },
          blockVersion: 0,
        },
        {
          _id: 'ab7912e3-4dff-4e23-8d02-3e17d553c10d',
          blockId: '5OwQXXF24XxWE3JpJ7avbl',
          properties: {
            icon: {
              set: 'Feather',
              name: 'cloud-drizzle',
              size: 14,
            },
            text: 'Another example icon',
          },
          blockVersion: 0,
        },
        {
          _id: '3d53f487-4597-4ca0-b28d-ee7ea80c0f30',
          lock: ['All'],
          name: 'Required Interactive Block',
          style: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '2em',
            paddingLeft: '2em',
            paddingRight: '2em',
            flexDirection: 'row',
            paddingBottom: '2em',
          },
          children: [
            {
              _id: 'ceb820e4-c6bb-412a-878f-90df4b0f52a9',
              style: { flex: 1 },
              primitive: 'ButtonBlock',
              subscribe: { submit: { _bind: true, publish: 'submit' } },
              properties: { text: 'Submit', isSubmit: true },
            },
          ],
          primitive: 'ViewBlock',
          publishes: { submit: { type: 'Event' } },
        },
      ],
      fullWidth: false,
      resizeMode: 'Contain',
    },
    blockVersion: 0,
  },
  renderer: 5,
  placements: [],
  data: {
    field_0: {
      _id: '7175d109-636d-4f9e-8475-84816a75c375',
      name: 'field_0',
      type: 'String',
      width: 150,
      placeholder: 'Enter some text here.',
      friendlyName: 'Text input example',
    },
    field_1: {
      _id: 'b5e4e739-8ba6-4c39-9844-0e144ede453a',
      name: 'field_1',
      type: 'Option',
      width: 150,
      isArray: false,
      validators: [
        {
          name: 'In',
          value: [
            {
              _id: 'f4819c30-284f-49aa-b131-29a4b5013f74',
              name: 'Option 1',
              value: 'Option 1',
              _order: 0,
            },
            {
              _id: '24261c2b-bfaf-405d-9ddb-8adf4fb77bda',
              name: 'Option 2',
              value: 'Option 2',
              _order: 1,
            },
            {
              _id: 'b9791fad-44dc-4932-87f8-7c201036cfce',
              name: 'Option 3',
              value: 'Option 3',
              _order: 2,
            },
          ],
        },
      ],
      placeholder: '',
      friendlyName: 'Radio Button Example',
      packagedType: 'Radios',
    },
  },
  properties: {},
  state: {},
  actions: [],
  notifications: [],
  features: [],
  version: 0,
};

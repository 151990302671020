import React from 'react';

import cx from 'classnames';

import DatePickerButton from '../../../../form/DatePickers/DatePickerButton';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './DateInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function DateInputBlock({
  value = null,
  style = {},
  onInput = () => {},
  disabled = false,
  isValid = true,
  placeholder = 'Select',
  validationMessage = 'Required.',
  className,
}: any) {
  return (
    <div
      className={cx(className, styles.DateInputBlock)}
      style={styleMap(false, style)}
    >
      <DatePickerButton
        disabled={disabled}
        // @ts-ignore ts(2322)
        placeholder={placeholder}
        onSubmit={onInput}
        includeTime={false}
        forRange={false}
        value={value}
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
      <div className={styles.wrapper} />
    </div>
  );
}

export default DateInputBlock;

import React from 'react';

import { Icon } from 'design-system-web';

import IconBaseType from 'lane-shared/properties/baseTypes/Icon';
import { IconType } from 'lane-shared/types/baseTypes/IconType';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';

type BlockProps = WebBlockProps & {
  icon: IconType;
};

export default function IconBlock({
  style,
  className,
  icon,
  theme,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const secondary = theme.palette!.secondary;

  if (!icon) {
    return (
      <div className={className} style={styleMap(false, style)} {...props} />
    );
  }

  return (
    <Icon
      className={className}
      style={{
        ...styleMap(false, style),
        fontSize: `${(icon.size || IconBaseType.default.size) / 10}em`,
        color: icon.color || secondary,
      }}
      name={icon.name}
      set={icon.set}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      type={icon.iconWeight}
      {...props}
    />
  );
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { longDate } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { ContentType } from 'lane-shared/types/content/Content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import Label from 'components/general/Label';
import ContentTargetingPlacements from 'components/lane/ContentTargetingPlacements';
import { M } from 'components/typography';

import styles from './ContentTargetingDetails.scss';

type Props = {
  channel: ChannelType;
  content: ContentType;
  timeZone: string;
};

export default function ContentTargetingDetails({
  channel,
  content,
  timeZone,
}: Props) {
  const { t } = useTranslation();

  if (!content) {
    return null;
  }

  const hasDates = [
    ContentTypeEnum.Content as string,
    ContentTypeEnum.Notice as string,
    ContentTypeEnum.Perk as string,
    ContentTypeEnum.Promotion as string,
  ].includes(content.type);

  return (
    <div className={styles.ContentTargetingDetails}>
      {hasDates && (
        <section className={styles.options}>
          <Label
              TooltipComponent={t(
                'The timezone to use for the following dates'
              )}
          >
              {t('Time Zone')}
            </Label>
            <M variant="secondary">{timeZone}</M>

            <Label
              TooltipComponent={t(
                'This is when the content will be published and unpublished in the platform'
              )}
            >
              {t('Publishes and unpublishes at')}
            </Label>
            <M variant="secondary">{longDate(content.liveDate!, timeZone)}</M>
            {content.unpublishDate ? (
              <M variant="secondary">
                {longDate(content.unpublishDate!, timeZone)}
              </M>
            ) : (
              <M variant="secondary">{longDate(content.endDate!, timeZone)}</M>
            )}

            {content.startDate && content.endDate && (
              <>
                <Label
                  TooltipComponent={t(
                    'The start and end date of the content event'
                  )}
                >
                  {t('Starts and ends at')}
                </Label>
                <M variant="secondary">
                  {longDate(content.startDate!, timeZone)}
                </M>
                <M variant="secondary">
                  {longDate(content.endDate!, timeZone)}
                </M>
              </>
            )}

            {content.interactionEndDate && (
              <>
                <Label
                  TooltipComponent={t(
                    'The date and time up until the content may be interacted with'
                  )}
                >
                  {t('Interaction end date')}
                </Label>
                <M variant="secondary">
                  {longDate(content.interactionEndDate!, timeZone)}
                </M>
              </>
            )}
        </section>
      )}
      <ContentTargetingPlacements channel={channel} content={content} />
    </div>
  );
}

/* eslint-disable react/forbid-component-props */
import React from 'react';

import { Flex } from 'components';

import {
  AttachmentData,
  PMAttachments,
  SelectFileReturnType,
} from '../PMAttachments';

import styles from './ScheduleAttachments.scss';

interface ScheduleAttachmentsProps {
  attachmentFiles: AttachmentData[];
  onAddAttachments: SelectFileReturnType;
  onRemoveAttachment: (index: string) => void;
}

export const ScheduleAttachments = ({
  attachmentFiles,
  onAddAttachments,
  onRemoveAttachment,
}: ScheduleAttachmentsProps) => {
  return (
    <Flex direction="column" className={styles.attachmentSection}>
      <PMAttachments
        attachmentFiles={attachmentFiles}
        onAddAttachments={onAddAttachments}
        onRemoveAttachment={onRemoveAttachment}
      />
    </Flex>
  );
};

import gql from 'graphql-tag';

export default gql`
  mutation updateMetatag($metatag: MetatagInput!) {
    updateMetatag(metatag: $metatag) {
      _id
      name
    }
  }
`;

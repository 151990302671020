import React, { useState, useEffect, useContext } from 'react';

import { Flex, Line, Button, Loading, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useMutation } from '@apollo/client';

import { convertToUUID } from 'lane-shared/helpers/convertId';

import {
  updateEquipmentSettingsOnChannel,
  getEquipmentSettingsOnChannel,
} from 'graphql-queries';
import { compact } from 'lodash';

import { BreadCrumbs } from 'components/lds';
import { H3, H4, M } from 'components/typography';

import ChannelAdminContext from 'pages/portal/admin/channel/ChannelAdminContext';

import SimpleOptions from '../../serviceRequest/components/builder/ServiceRequestDataBuilder/components/inputs/SimpleOptions';
import { OptionType } from '../../serviceRequest/components/builder/ServiceRequestDataBuilder/types';

import styles from './EquipmentSettings.scss';

export function EquipmentSettings() {
  const { channel } = useContext(ChannelAdminContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [pristine, setPristine] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const { t } = useTranslation();
  const [
    getEquipmentSettings,
    { loading: equipmentSettingsLoading },
  ] = useLazyQuery(getEquipmentSettingsOnChannel, {
    onError: () => {
      // equipment settings do not exist for channel
      setLoading(false);
    },
    onCompleted: ({ equipmentSettingsOnChannel }) => {
      if (equipmentSettingsOnChannel) {
        setCategories(compact(equipmentSettingsOnChannel.categories) || []);
        setLocations(compact(equipmentSettingsOnChannel.locations) || []);
      }
      setLoading(false);
    },
  });

  const [updateEquipmentSettings] = useMutation(
    updateEquipmentSettingsOnChannel
  );

  useEffect(() => {
    if (channel?._id) {
      getEquipmentSettings({
        variables: { channelId: convertToUUID(channel?._id) },
      });
    }
  }, [channel]);

  function arrayToOptions(arr: string[]): OptionType[] {
    return arr.map((str: string) => ({
      value: str,
      name: str,
    }));
  }

  function optionsToArray(arr: OptionType[]): string[] {
    if (pristine) setPristine(false);
    return arr.map((opt: OptionType) => opt.name);
  }

  function onCategoriesUpdated(arr: OptionType[]): void {
    setCategories(optionsToArray(arr));
  }

  function onLocationsUpdated(arr: OptionType[]): void {
    setLocations(optionsToArray(arr));
  }

  async function onSave() {
    try {
      setLoading(true);
      setError(null);
      await updateEquipmentSettings({
        variables: {
          settings: {
            channelId: convertToUUID(channel?._id),
            categories,
            locations,
          },
        },
      });
      window.Toast.show(t`web.admin.channel.equipment.settings.success`);
    } catch (ex: any) {
      setError(ex);
    }
    setLoading(false);
  }

  const isNotReady = Boolean(
    [...categories, ...locations].find(str => str === '')
  );

  if (equipmentSettingsLoading || loading) {
    return (
      <Flex direction="column" className={styles.container}>
        <Loading />
      </Flex>
    );
  }

  return (
    <Flex direction="column" className={styles.container}>
      <BreadCrumbs
        links={[
          {
            label: t`web.admin.equipment`,
            url: `/l/channel/:id/admin/work-orders/equipment`.replace(
              ':id',
              channel?.slug || ''
            ),
          },
          { label: t`web.admin.equipment.settings` },
        ]}
      />
      <div data-test="titleContainer" className={styles.titleContainer}>
        <ErrorMessage error={error} />
        <H3>{t`web.admin.channel.equipment.settings.title`}</H3>
        <M>{t`web.admin.channel.equipment.settings.subtitle`}</M>
        <Line className={styles.divider} />
      </div>
      <div className={styles.optionSection}>
        <H4>{t`web.admin.channel.equipment.settings.categories`}</H4>
        <SimpleOptions
          disableDragging
          options={arrayToOptions(categories)}
          setOptions={onCategoriesUpdated}
          addOptionTitle={t`web.admin.channel.equipment.settings.categories.add`}
        />
      </div>
      <div className={styles.optionSection}>
        <H4>{t`web.admin.channel.equipment.settings.locations`}</H4>
        <SimpleOptions
          disableDragging
          options={arrayToOptions(locations)}
          setOptions={onLocationsUpdated}
          addOptionTitle={t`web.admin.channel.equipment.settings.locations.add`}
        />
      </div>
      <Button
        variant="activate-contained"
        className={styles.button}
        testId="equipmentSettingsSaveButton"
        onClick={onSave}
        loading={loading}
        disabled={pristine || isNotReady}
      >
        {t`web.admin.channel.equipment.settings.save`}
      </Button>
    </Flex>
  );
}

import React, { useContext, useReducer, useState } from 'react';

import { AdminPage, ErrorMessage, Stepper } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { getClient } from 'lane-shared/apollo';
import { AnalyticsContext, UserDataContext } from 'lane-shared/contexts';
import {
  HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED,
  HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY,
  HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED,
} from 'lane-shared/helpers/constants/analytics';
import { PERMISSION_PROPERTY_AUTO_SETUP } from 'lane-shared/helpers/constants/permissions';

import {
  createAutoSetupConfiguration,
  autoSetupCesMutation,
} from 'graphql-queries';

import { AllowedFeatures as RiseMigrationAllowedFeatures } from 'graphql-query-contracts/src/__generated__/graphql';

import { Alert } from 'components/lds';

import { Confirm } from 'pages/portal/admin/channel/children/auto-setup-wizard/Confirm';
import { INITIAL_RISE_MIGRATION_FEATURES } from 'pages/portal/admin/channel/children/auto-setup-wizard/constants';
import {
  checkIfSourceLocationsAreValid,
  dataSourceLocationsReducer,
  formatValidationErrorMessage,
  getRedirectLinks,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/helpers';
import {
  AutoSetupConfig,
  DataSourceEnum,
  RiseMigrationFeaturesType,
  SourceLocation,
  SourceLocations,
  Template,
} from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { WizardNavigationButtons } from './WizardNavigationButtons';
import { WizardStepOne } from './WizardStepOne';
import { WizardStepTwo } from './WizardStepTwo';
import { useAutoSetupNavigation } from './hooks/useAutoSetupNavigation';
import { useTemplateIdValidation } from './hooks/useTemplateIdValidation';
import { useTrackSourceLocationSelected } from './hooks/useTrackSourceLocationSelected';
import { useTrackSourceSelected } from './hooks/useTrackSourceSelected';
import { useTrackTemplateSelected } from './hooks/useTrackTemplateSelected';
import styles from './styles.scss';

export function AutoSetupWizard(channel: UseChannelForAdminQueryResponse) {
  const { t } = useTranslation();
  const { hasAnyPermission } = useContext(UserDataContext);
  const analytics = useContext(AnalyticsContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [config, setConfig] = useState<AutoSetupConfig>({
    dataSource: null,
    templateType: null,
    templateId: null,
  });

  const [sourceLocations, dispatch] = useReducer(
    dataSourceLocationsReducer,
    [] as SourceLocations
  );

  const [features, setFeatures] = useState<RiseMigrationFeaturesType>(
    INITIAL_RISE_MIGRATION_FEATURES
  );
  const [
    allowedFeatures,
    setAllowedFeatures,
  ] = useState<RiseMigrationAllowedFeatures>();

  const redirectLinks = getRedirectLinks(channel);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { goToPropertiesPage, goToRecentJobsPage } = useAutoSetupNavigation();
  const [error, setError] = useState<string>('');

  const [cesOrganizationId, setCesOrganizationId] = useState<string>('');
  const [vcmBuildingIds, setVcmBuildingIds] = useState<string>('');

  const { id: channelSlug } = useParams<{ id: string }>();
  const channelSlugForNavigation = channel?.channel?.slug ?? channelSlug;

  const steps = useTrackSourceSelected({
    config,
    channel,
  });
  const isFirstStep = currentStep === 0;
  const isSecondStep = currentStep === 1;
  const isLastStep = currentStep === steps.length - 1;

  useTrackSourceLocationSelected({
    isSecondStep,
    sourceLocations,
    channel,
  });
  useTrackTemplateSelected({ config, channel });
  const templateValidation = useTemplateIdValidation({ config, setError });

  if (!channel?.channel?._id) {
    goToPropertiesPage(channelSlugForNavigation);
    return null;
  }

  const { channel: autoSetupChannel } = channel;
  const {
    _id: parentChannelId,
    name: parentChannelName,
    slug: parentChannelSlug,
  } = autoSetupChannel;

  if (!hasAnyPermission([PERMISSION_PROPERTY_AUTO_SETUP], parentChannelId)) {
    return (
      <ErrorMessage error={t`You are not authorized to perform this action.`} />
    );
  }

  const { dataSource, templateId, templateType } = config;
  const isRiseMigration = dataSource === DataSourceEnum.RISE;

  function getAutoSetupVariables(isValidationRun: boolean) {
    return {
      autoSetupData: {
        source: dataSource,
        sourceLocations: sourceLocations.map(each => each.value),
        parentChannelName,
        parentChannelId,
        parentChannelSlug,
        ...(templateType === Template.EXISTING && {
          templateChannelId: templateId,
        }),
        ...(templateType === Template.BLANK && {
          blankBuilding: true,
        }),
        features,
        isDryRun: isValidationRun,
        ...(isValidationRun ? {} : { redirectUrl }),
      },
    };
  }

  async function validateInputs() {
    try {
      const { data } = await getClient().mutate({
        mutation: createAutoSetupConfiguration,
        variables: {
          ...getAutoSetupVariables(true),
        },
      });
      if (
        isRiseMigration &&
        data?.createAutoSetupConfiguration?.allowedFeatures
      ) {
        setAllowedFeatures(data.createAutoSetupConfiguration.allowedFeatures);
      }
    } catch (error: any) {
      const validationMessage = formatValidationErrorMessage(error);
      setError(`There was an input validation error: ${validationMessage}`);
      return false;
    }
    return true;
  }

  function handleAddDataSourceLocation() {
    dispatch({
      type: 'ADD_SOURCE_LOCATION',
    });
  }

  function handleDeleteDataSourceLocation(index: number) {
    dispatch({
      type: 'DELETE_SOURCE_LOCATION',
      index,
    });
  }

  function handleUpdateDataSourceLocation(sourceLocation: SourceLocation) {
    dispatch({
      type: 'UPDATE_SOURCE_LOCATION',
      sourceLocation,
    });
  }

  const buttonNext = async (): Promise<void> => {
    let areInputsValid = true;

    // Want to skip dryRun for SFTP (scheduled jobs)
    const shouldValidateWithDryRun =
      (isFirstStep && config.dataSource === DataSourceEnum.RISE) ||
      (isSecondStep && config.dataSource !== DataSourceEnum.sFTP);

    if (
      dataSource &&
      checkIfSourceLocationsAreValid(sourceLocations) &&
      shouldValidateWithDryRun
    ) {
      areInputsValid = await validateInputs();
    }
    if (areInputsValid) {
      setError('');
      if (!isLastStep) setCurrentStep(cv => cv + 1);
    }
  };

  const onSubmit = async (): Promise<void> => {
    setError('');
    setLoading(true);
    analytics.track(HOST_BUILDING_AUTO_SETUP_WIZARD_CONFIRMED, {
      channelName: autoSetupChannel.name,
    });

    try {
      const { data } = await getClient().mutate({
        mutation: createAutoSetupConfiguration,
        variables: {
          ...getAutoSetupVariables(false),
        },
      });
      window.Toast.show(t`web.admin.channel.autoSetupWizard.successMessage`);
      analytics.track(HOST_BUILDING_AUTO_SETUP_WIZARD_SAVED_SUCCESSFULLY, {
        channelName: autoSetupChannel.name,
      });

      if (isRiseMigration) {
        goToRecentJobsPage(data.createAutoSetupConfiguration.id);
      } else {
        goToPropertiesPage(channelSlugForNavigation);
      }
    } catch (error: any) {
      // TODO: add specific error messages based on server responses
      setError(
        `There was an error saving your configuration: ${error.message}`
      );
      analytics.track(HOST_BUILDING_AUTO_SETUP_WIZARD_SAVE_FAILED, {
        channelName: autoSetupChannel.name,
      });
    } finally {
      setLoading(false);
    }
  };

  const submitCesAutoSetup = async (): Promise<void> => {
    setError('');
    setLoading(true);

    const longParentChannelId = convertToUUID(parentChannelId);
    const cloningChannelTemplateId = config.templateId
      ? convertToUUID(config.templateId)
      : '';

    const cesAutoSetupParams = {
      parentChannelId: longParentChannelId,
      cesAccountId: cesOrganizationId,
      activateChannelTemplateId: cloningChannelTemplateId,
      vcmBuildingIds: vcmBuildingIds.split(','),
    };

    try {
      const gqlClient = await getClient();

      const { data } = await gqlClient.mutate({
        mutation: autoSetupCesMutation,
        variables: {
          request: cesAutoSetupParams,
        },
      });

      // eslint-disable-next-line no-console
      console.log('ces auto setup response', JSON.stringify(data));

      goToPropertiesPage(channelSlugForNavigation);
      window.Toast.show(t`web.admin.channel.autoSetupWizard.successMessage`);
    } catch (error: any) {
      setError(`There was an error on ces auto setup: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminPage className={styles.Wizard}>
      <Stepper
        steps={steps}
        active={currentStep}
        onClick={() => {}}
        className={styles.Stepper}
      />

      <WizardNavigationButtons
        isFirstStep={isFirstStep}
        isSecondStep={isSecondStep}
        isLastStep={isLastStep}
        sourceLocations={sourceLocations}
        config={config}
        error={error}
        templateValidation={templateValidation}
        loading={loading}
        features={features}
        channelSlugForNavigation={channelSlugForNavigation}
        buttonNext={buttonNext}
        setCurrentStep={setCurrentStep}
        onSubmit={onSubmit}
        submitCesAutoSetup={submitCesAutoSetup}
      />

      {error && (
        <Alert type="error" fullWidth>
          {error}
        </Alert>
      )}
      {isFirstStep && (
        <WizardStepOne
          onChangeDataSource={(dataSource: DataSourceEnum) => {
            setError('');
            setConfig(oldConfig => ({
              ...oldConfig,
              dataSource,
              sourceLocations: [],
              templateType: null,
              templateId: null,
            }));
            dispatch({ type: 'CLEAR_SOURCE_LOCATIONS' });
          }}
          onChangeDataSourceLocation={handleUpdateDataSourceLocation}
          onDeleteSourceLocation={handleDeleteDataSourceLocation}
          onAddDataSourceLocation={handleAddDataSourceLocation}
          currentValue={{ dataSource, sourceLocations }}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {isSecondStep && (
        <WizardStepTwo
          selectedDataSource={config.dataSource || DataSourceEnum.CSV}
          parentChannelId={parentChannelId}
          riseMigrationOptions={{
            features,
            setFeatures,
            allowedFeatures,
            redirectUrl,
            setRedirectUrl,
            redirectLinks,
          }}
          selectChannelTemplateIdOptions={{
            config,
            setConfig,
            templateValidation,
          }}
          cesOptions={{
            cesOrganizationId,
            setCesOrganizationId,
            vcmBuildingIds,
            setVcmBuildingIds,
          }}
        />
      )}
      {isLastStep && (
        <Confirm
          currentValues={{ ...config, sourceLocations }}
          riseMigrationFeatures={features}
          cesOptions={{
            cesOrganizationId,
            vcmBuildingIds,
          }}
        />
      )}
    </AdminPage>
  );
}

import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation, isApolloError } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { unitErrorCodeTypes } from 'activate-errors';
import {
  deleteUnit,
  DeleteUnitMutationResponse,
} from 'lane-shared/graphql/units';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';
import { getSharedTranslationKeys } from '../utils';

export function useDeleteUnit() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const history = useHistory();
  const { deleteModal } = getSharedTranslationKeys(channel?.experienceType);

  const [deleteUnitMutation] = useMutation<DeleteUnitMutationResponse>(
    deleteUnit
  );

  const handleDelete = async (id: string, unitName: string) => {
    try {
      await window.Alert.confirm({
        title: t(deleteModal.title),
        message: (
          <Trans i18nKey={deleteModal.message} values={{ unit: unitName }} />
        ),
        confirmText: t('web.pages.portal.admin.units.list.delete.confirmText'),
        cancelText: t('web.pages.portal.admin.units.list.delete.cancelText'),
      });

      await deleteUnitMutation({ variables: { id } });

      window.Toast.show(t(deleteModal.successToast));

      history.push(
        routes.channelAdminUnitsListView.replace(':id', channel?.slug || '')
      );
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          unitErrorCodeTypes,
          t('shared.units.errors.generic')
        );

        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return {
    handleDelete,
  } as const;
}

import { useEffect, useRef } from 'react';

import { getTextColor } from 'lane-shared/helpers';
import { ThemeColorPaletteType } from 'lane-shared/types/Theme';

import fallbackBackgroundImage from 'static/img/onboarding-background.jpg';
import { FontType } from 'lane-shared/config/fonts';

// NOTE: If this is to be extended, add react-helmet & update
// https://github.com/nfl/react-helmet
export default function useTheme(
  palette: ThemeColorPaletteType,
  { bodyFont, headerFont }: { bodyFont: FontType; headerFont: FontType }
) {
  const styleRef = useRef<HTMLStyleElement>();

  useEffect(() => {
    if (!palette.primary) {
      return;
    }

    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = `
      :root {
        --theme-font-body:${bodyFont.fontFamily};
        --theme-font-header:${headerFont.fontFamily};
        --theme-primary: ${palette.primary};
        --theme-primary-button-color: ${getTextColor(palette.primary)};
        --theme-secondary: ${palette.secondary};
        --theme-secondary-button-color: ${getTextColor(palette.secondary)};
        --theme-accent-button-color: ${palette.accentColor};
        --theme-background-image: url(${
          palette.backgroundImage || fallbackBackgroundImage
        });
      }
    `;
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(styleElement);

    styleRef.current = styleElement;

    return () => {
      styleElement.remove();
      styleRef.current = undefined;
    };
  }, [palette]);

  return styleRef;
}

import createBlockRenderer from '../../renderers/v5/createBlockRenderer';
import { BlockType } from '../../types/blocks/BlockInterface';
import { convertToUUID } from '../convertId';

export default function registerBlockRenderer(blockDefinition: BlockType) {
  const Block = createBlockRenderer(blockDefinition);

  (Block as any).def = blockDefinition;
  (Block as any).blockName = blockDefinition.name;
  (Block as any).blockDescription = blockDefinition.description;
  (Block as any)._id = convertToUUID(blockDefinition._id);
  (Block as any).version = blockDefinition.version;
  (Block as any).renderer = blockDefinition.renderer;
  (Block as any).tags = blockDefinition.tags;
  (Block as any).availableForContentTypes =
    blockDefinition.availableForContentTypes;
  (Block as any).dependencies = blockDefinition.dependencies;

  return Block;
}

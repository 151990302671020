import { ChannelType } from 'lane-shared/types/ChannelType';
import React from 'react';
import routes from 'lane-shared/config/routes';
import { useTranslation } from 'react-i18next';
import { CreateCategoryForm } from 'pages/portal/admin/channel/products-services/categories/CreateCategoryForm';
import { ProductsServicesTabs } from 'pages/portal/admin/channel/products-services';

type Props = {
  channel?: ChannelType;
};

export function ServiceRequestBillingCategoryForm({ channel }: Props) {
  const { t } = useTranslation();

  if (!channel) return null;

  const breadcrumbs = [
    {
      label: t('web.admin.serviceRequest.title'),
      url: routes.channelAdminWorkOrdersServiceRequests.replace(
        ':id',
        channel.slug
      ),
    },
    {
      label: t('web.admin.serviceRequest.settings'),
      url: routes.channelAdminWorkOrdersServiceRequestsSettings.replace(
        ':id',
        channel.slug
      ),
    },
    {
      label: t('web.admin.serviceRequest.settings.billing.title'),
      url: routes.channelAdminWorkOrdersServiceRequestsBillingSettings.replace(
        ':id',
        channel.slug
      ),
    },
    {
      label: t('web.admin.serviceRequest.settings.categories.title'),
      url: routes.channelAdminWorkOrdersServiceRequestsBillingSettings
        .replace(':id', channel.slug)
        .concat(`?tab=${ProductsServicesTabs.Categories}`),
    },
    {
      label: t('web.admin.serviceRequest.settings.createCategories.title'),
    },
  ];

  return <CreateCategoryForm breadcrumbs={breadcrumbs} />;
}

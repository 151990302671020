import React from 'react';

import { MediaLibrary } from 'components';

import getDisplayName from 'lane-shared/helpers/getDisplayName';
import { MediaType } from 'lane-shared/hooks/useMediaLibrary';

import styles from './styles.scss';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';

export default function MyMedia({ user, history }: any) {
  return (
    <div className={styles.MyMedia}>
      <MediaLibrary
        className={styles.mediaLibrary}
        storageKey={user._id}
        onMediaDoubleClicked={(media: MediaType, libraryItemId: string) =>
          history.push(
            `${history.location.pathname}/${media._id}/edit?libraryItemId=${libraryItemId}`
          )
        }
        library={{
          type: LibraryTypeEnum.User,
          _id: user._id,
          name: getDisplayName(user),
        }}
      />
    </div>
  );
}

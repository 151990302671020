import React from 'react';

import { useTranslation } from 'react-i18next';

import { ShopifyBurstPageTitle } from 'lane-shared/types/integrations/ShopifyBurst';

import BurstCustomPageRenderer from 'components/integrations/ShopifyBurst/pages/BurstCustomPageRenderer';

import { BurstCreatePageProps } from '../../components/BurstCreateFlow';

export default function BurstDiyContent({
  wizardState,
  content,
  onDataUpdated,
  goToNextStep,
  goToPrevStep,
  ...rest
}: BurstCreatePageProps) {
  const { t } = useTranslation();
  return (
    <BurstCustomPageRenderer
      interactionKey="diyQuestions"
      title={t(ShopifyBurstPageTitle.BurstDIYContent)}
      wizardState={wizardState}
      content={content}
      goToPrevStep={goToPrevStep}
      goToNextStep={goToNextStep}
      onDataUpdated={onDataUpdated}
      contentKey="diyQuestionsContent"
      {...rest}
    />
  );
}

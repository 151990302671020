import React from 'react';

import cx from 'classnames';

import QuantityInput from 'components/form/QuantityInput';

import styleMap from '../../styleMap';

import styles from './QuantityInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function QuantityInputBlock({
  value,
  max,
  min,
  disabled = false,
  onInput = () => {},
  style,
  className,
}: any) {
  if (min) {
    min = parseInt(min, 10);
  }

  if (max) {
    max = parseInt(max, 10);
  }

  return (
    <div
      className={cx(styles.QuantityInputBlock, className)}
      style={styleMap(false, style)}
    >
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'iconType' is missing in type '{ min: any... Remove this comment to see the full error message */}
      <QuantityInput
        min={min}
        max={max}
        quantity={value || min || 0}
        onChange={onInput}
        disabled={disabled}
      />
    </div>
  );
}

export default QuantityInputBlock;

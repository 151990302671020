import { WizardStepComponent } from 'lane-shared/hooks/useWizard';
import { BurstDraftInteractionData } from 'lane-shared/types/integrations/ShopifyBurst';

import BurstAcknowledgement from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstAcknowledgement';
import BurstDateAndTime from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstDateAndTime';
import BurstDefaultView from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstDefaultView';
import BurstDiyContent from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstDiyContent';
import BurstGuests from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstGuests';
import BurstName from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstName';
import BurstQuestionnaire from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstQuestionnaire';
import BurstReviewAndSubmit from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstReviewAndSubmit';
import BurstSpecialRequest from 'components/integrations/ShopifyBurst/pages/burstWizardPages/BurstSpecialRequest';

import BurstContentPage from './pages/burstWizardPages/BurstContentPage';
import BurstL9 from './pages/burstWizardPages/BurstL9';

export const shopifyBurstPages = [
  BurstDefaultView,
  BurstContentPage,
  BurstAcknowledgement,
  BurstName,
  BurstQuestionnaire,
  BurstGuests,
  BurstSpecialRequest,
  BurstL9,
  BurstReviewAndSubmit,
];

export const shopifyDiyBurstPages = [
  BurstDefaultView,
  BurstAcknowledgement,
  BurstName,
  BurstQuestionnaire,
  BurstDateAndTime,
  BurstDiyContent,
  BurstGuests,
  BurstSpecialRequest,
  BurstL9,
  BurstReviewAndSubmit,
];

export const shopifyBurstPagesByName: {
  [id: string]: WizardStepComponent<BurstDraftInteractionData>;
} = {
  defaultView: BurstDefaultView,
  content: BurstContentPage,
  acknowledgement: BurstAcknowledgement,
  name: BurstName,
  questionnaire: BurstQuestionnaire,
  date: BurstDateAndTime,
  l9: BurstL9,
  guests: BurstGuests,
  request: BurstSpecialRequest,
  review: BurstReviewAndSubmit,
  diy: BurstDiyContent,
};

export default shopifyBurstPages;

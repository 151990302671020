import React, { useMemo, useEffect } from 'react';

import { Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { spacing, colors } from 'lane-shared/config';
import {
  createReservableInteraction,
  cancelReservableInteraction,
} from 'lane-shared/graphql/integrations/ShopifyBurst/reservableInteractions';
import { useSortFn } from 'lane-shared/hooks';
import useSectionContentQuery from 'lane-shared/hooks/integrations/shopify/useSectionContentQuery';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { WizardStepProps } from 'lane-shared/hooks/useWizard';
import { SortByEnum, SortDirectionEnum } from 'lane-shared/types/blocks/Sort';
import { PresentContentFilterTimeEnum } from 'lane-shared/types/filters/PresentContentFilterTimeEnum';
import { PresetContentFilter } from 'lane-shared/types/filters/PresetContentFilter';
import {
  BurstDraftInteractionData,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import SelectableCardWrapper from 'components/cards/SelectableCardWrapper';
import StandardContentCard from 'components/cards/StandardContentCard';
import { shopifyBurstPagesByName } from 'components/integrations/ShopifyBurst/shopifyBurstCreatePages';
import { Flex } from 'components/layout';
import StandardContentCardSkeleton from 'components/loading/StandardContentCardSkeleton';
import { H5, M, L } from 'components/typography';

import BurstButtonGroup from '../../components/BurstButtonGroup';
import BurstCreateHeader from '../../components/BurstCreateHeader';
import Container from '../../components/Container';
import RoomBookingModal from '../../components/RoomBookingModal';
import { useModalContext } from 'contexts/ModalContext';

import styles from './BurstRoomBook.scss';

// TODO: Mobile and Web share a lot of common implementation. Extract it to the dedicated hook.

export default function BurstRoomBook({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  goToStepByName,
  onDataUpdated,
}: WizardStepProps<BurstDraftInteractionData>) {
  const { t } = useTranslation();
  const { data } = wizardState;
  const channelId = wizardState.data.port?._id;
  const { burstIntegrationSettings } = useShopifyBurstContext();
  const { showModal, closeModal } = useModalContext();

  const searchOptions = {
    channelId,
    areFiltersApplied: true,
    filters: [
      {
        type: PresetContentFilter.FeatureReservableAvailableDays,
        filter: {
          ...data.dateRange,
          enabled: PresentContentFilterTimeEnum.Enabled,
        },
      },
    ],
  };

  const {
    sectionContent,
    refetch,
    loading: sectionContentLoading,
  } = useSectionContentQuery({
    sectionId: burstIntegrationSettings?.roomBookingSection?._id,
    fetchPolicy: 'no-cache',
    requireChannelId: true,
    searchOptions,
  });

  useEffect(() => {
    if (
      burstIntegrationSettings?.roomBookingSection?._id &&
      channelId &&
      !sectionContentLoading
    ) {
      refetch({
        id: burstIntegrationSettings?.roomBookingSection?._id,
        searchOptions,
      }).catch(e => console.error(e));
    }
  }, [data?.primaryRoomBooking?._id]);

  const { sortFn } = useSortFn({
    sortBy: SortByEnum.EndDate,
    sortDirection: SortDirectionEnum.Asc,
  });

  const sortedSectionContent = useMemo(
    () =>
      sectionContent
        .map(({ content }) => content) // unnest the content to sort properly
        .sort(sortFn),
    [sectionContent]
  );

  async function handleRoomSelect(content: any) {
    try {
      if (content?._id !== (data.primaryRoomBooking?.contentData as any)?._id) {
        if (data.primaryRoomBooking) {
          await cancelReservableInteraction(data.primaryRoomBooking);
        }
        const interaction = await createReservableInteraction(content!, {
          startDate: data?.dateRange?.startDate!,
          endDate: data?.dateRange?.endDate!,
        });
        onDataUpdated({
          primaryRoomBooking: interaction,
        });
      } else {
        await cancelReservableInteraction(wizardState.data.primaryRoomBooking!);
        onDataUpdated({
          primaryRoomBooking: undefined,
        });
      }
    } catch (err) {
      window.Toast.show(err.message.split(':')[1]);
    }
  }

  function handleRoomOpenPreview(contentId: string) {
    showModal(
      <RoomBookingModal
        onClose={closeModal}
        contentId={contentId}
        selectedRoom={data.primaryRoomBooking?.contentData}
        handleRoomSelect={handleRoomSelect}
      />
    );
  }

  return (
    <div>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={content}
        title={t(ShopifyBurstPageTitle.BurstRoomBook)}
      />
      <Container style={{ maxWidth: '60em' }}>
        <Flex direction="column" mb={2}>
          <H5 mb={2}>Which team room best suits your burst?</H5>
          <M mb={2} variant="secondary">
            Please choose a room that best fits the number of attendees without
            going over the max capacity.
          </M>
        </Flex>
        <Flex className={styles.roomBookings} gap={4}>
          {sectionContentLoading && <StandardContentCardSkeleton count={3} />}
          {!sectionContentLoading && data.primaryRoomBooking && (
            <SelectableCardWrapper
              className={styles.marginRight}
              key={(data.primaryRoomBooking as any).contentId}
              onClick={() =>
                handleRoomOpenPreview(
                  (data.primaryRoomBooking?.contentData as any)?._id
                )
              }
              isSelected
            >
              <StandardContentCard
                content={data.primaryRoomBooking.contentData as any}
              />
            </SelectableCardWrapper>
          )}
          {sortedSectionContent.map(content => (
            <SelectableCardWrapper
              className={styles.marginRight}
              key={content?._id}
              onClick={() => handleRoomOpenPreview(content._id)}
              isSelected={false}
            >
              <StandardContentCard content={content} />
            </SelectableCardWrapper>
          ))}
          {!data.primaryRoomBooking && sortedSectionContent.length === 0 && (
            <Flex
              direction="column"
              justify="center"
              align="center"
              className={styles.noRoomBookings}
            >
              <Icon
                name="calendar-exclamation"
                set="FontAwesome"
                type="far"
                className={styles.emptyStateIcon}
                style={{
                  marginBottom: spacing[4],
                  color: colors.charcoal[6],
                }}
              />
              <L variant="secondary" className={styles.emptyStateText}>
                There are no available rooms on your selected port and dates.
              </L>
            </Flex>
          )}
        </Flex>
        <BurstButtonGroup
          buttons={
            data.primaryRoomBooking || sortedSectionContent.length > 0
              ? [
                  { title: 'Prev', onClick: goToPrevStep },
                  { title: 'Next', onClick: goToNextStep },
                ]
              : [
                  {
                    title: 'Update port & dates',
                    onClick: () => goToStepByName(shopifyBurstPagesByName.port),
                  },
                ]
          }
          disabled={sortedSectionContent.length > 0 && !data.primaryRoomBooking}
        />
      </Container>
    </div>
  );
}

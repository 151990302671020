import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUserData } from 'lane-shared/hooks';
import { dates } from 'lane-shared/helpers/constants';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { cancelRecurringChargeMutation } from './cancelRecurringChargeMutation';
import { canAdminCancelMembership } from './canAdminCancelMembership';
import {
  useMutation,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';

import { routes } from 'lane-shared/config';
import {
  RecurringCharge,
  ExternalActorType,
} from 'lane-shared/domains/billingPayments/types';

export interface RecurringChargeActions {
  label: string;
  onSelect: Function;
  hidden: Function;
}

export function useGetActionsForRecurringCharges(
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
): RecurringChargeActions[] {
  const { t } = useTranslation();
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';
  const [cancelRecurringCharge] = useMutation(cancelRecurringChargeMutation);
  const { user } = useUserData();
  const isViewRoute = useRouteMatch(routes.channelAdminSubscriptionDetails);

  async function onCancel(actions: boolean, recurringCharge: RecurringCharge) {
    try {
      await window.Alert.confirm({
        title: t('abp.subscription.cancelSubscription.title', {
          chargeName: recurringCharge.charge!.name,
          customerName: recurringCharge.charge!.externalPayerId,
        }),
        message: t('abp.subscription.cancelSubscription.message', {
          nextOccurrence: dateFormatter(
            recurringCharge.recurrence!.nextOccurrence,
            dates.SIMPLE_DATE
          ),
        }),
        confirmText: t('abp.actions.cancelSubscription'),
        cancelText: t('abp.subscription.cancelSubscription.goBack'),
      });

      // User confirmed
      await cancelRecurringCharge({
        variables: {
          cancelRecurringChargeRequest: {
            recurrenceId: recurringCharge.recurrence!.recurrenceId,
            externalActorId: convertToUUID(user?._id) || '',
            externalActorType:
              ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
          },
        },
      });

      window.Toast.show(
        t(
          'abp.subscription.cancelSubscription.subscriptionCancelledSuccessfully'
        )
      );

      refetch();
    } catch (err) {
      console.error(`Action cancelled: ${err}`);
    } finally {
      window.Alert.hide();
    }
  }

  const viewAction = {
    label: t('abp.actions.viewSubscription'),
    onSelect: async (_: any, recurringCharge: RecurringCharge) => {
      const recurrenceId = recurringCharge?.recurrence?.recurrenceId || '';
      const detailsRoute = routes.channelAdminSubscriptionDetails
        .replace(':id', channel_slug)
        .replace(':membershipId', recurrenceId);
      history.push(detailsRoute, { recurringCharge });
    },
    hidden: (_recurringCharge: RecurringCharge) => {
      return isViewRoute;
    },
  };

  const cancelAction = {
    label: t('abp.actions.cancelSubscription'),
    onSelect: async (isActive: boolean, recurringCharge: RecurringCharge) => {
      await onCancel(isActive, recurringCharge);
    },
    hidden: (recurringCharge: RecurringCharge) => {
      return !canAdminCancelMembership(
        !!recurringCharge?.recurrence?.isActive,
        recurringCharge,
        user
      );
    },
  };

  return [viewAction, cancelAction];
}

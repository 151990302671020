import { useMemo } from 'react';

import { LocationValue } from '.';
import type { RegionValue } from './types';

export default function useLocationFilterOptionsBySearch(
  regions: RegionValue[],
  searchValue = ''
) {
  return useMemo(() => {
    const value = searchValue.toLowerCase().trim();
    return regions.reduce<(RegionValue | LocationValue)[]>((result, region) => {
      if (region.label.toLowerCase().includes(value)) {
        result.push(region);
      } else {
        const locations = region.locations?.map(location => ({
          ...location,
          hidden: !location.label.toLowerCase().includes(value),
        }));
        if (locations?.some(({ hidden }) => !hidden))
          result.push({ ...region, locations });
      }
      return result;
    }, []);
  }, [regions, searchValue]);
}

import { Column } from 'design-system-web';
import {
  Product,
  ProductTax,
  Receivable,
  TaxAmountType,
} from 'graphql-query-contracts';
import useUserLocale from 'hooks/useUserLocale';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  CurrencyFormatMap,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import {
  currencyFormatter,
  simpleCurrencyFormatter,
} from 'lane-shared/helpers/formatters';
import { useTranslation } from 'react-i18next';

export function useGetCreditBundleColumns(
  channelId: string
): Column<Product>[] {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { currency } = useBillingPaymentSettingsForChannel({ channelId });

  const renderDollarCell = currencyFormatter({
    currency: CurrencyFormatMap[currency],
  });
  return [
    {
      key: 'name',
      header: t('abp.creditBundle.tableColumn.name'),
    },
    {
      key: 'description',
      header: t('abp.creditBundle.tableColumn.description'),
    },
    {
      key: 'receivables',
      header: t('abp.creditBundle.tableColumn.creditsIssued'),
      renderCell: (cell: Receivable[]) => {
        return cell[0]?.creditBundle?.creditCount;
      },
    },
    {
      key: 'amount',
      header: t('abp.creditBundle.tableColumn.rate'),
      renderCell: (cell: number) => {
        return renderDollarCell(cell);
      },
    },
    {
      key: 'tax',
      header: t('abp.creditBundle.tableColumn.tax'),
      renderCell: (cell: ProductTax | undefined) => {
        if (!cell) {
          return '-';
        }
        if (cell.taxAmountType === TaxAmountType.TaxAmountTypePercentage) {
          return `${cell.value}%`;
        }
        return renderDollarCell(cell.value);
      },
    },
    {
      // unused key, need full row for this column field
      key: 'no-key',
      header: t('abp.creditBundle.tableColumn.total'),
      renderCell: (_: unknown, row: Product) => {
        const netPrice = getPricing({
          currency: currencyToPaymentCurrency(currency),
          locale,
          priceComponents: {
            amount: row.amount || 0,
            markup: row.markup ?? undefined,
            tax: row.tax ?? undefined,
          },
        }).netPrice;
        return simpleCurrencyFormatter(
          netPrice,
          currencyToPaymentCurrency(currency)
        );
      },
    },
  ];
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { WizardStepProps } from 'lane-shared/hooks/useWizard';
import {
  BurstDraftInteractionData,
  BurstWizardState,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import BurstCustomPageRenderer from 'components/integrations/ShopifyBurst/pages/BurstCustomPageRenderer';

export default function BurstAcknowledgement({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
  ...rest
}: WizardStepProps<BurstDraftInteractionData>) {
  const { t } = useTranslation();
  const { acknowledgementContent } = useShopifyBurstContext();

  function interactionToWizardStateAdapter(interaction: any) {
    return {
      // we expect the custom page for acknowledgements step to have termsAndConditionsRead field
      // with checkbox list type which is used as a termsAndConditionsRead of the burst
      termsAndConditionsRead: Boolean(
        interaction.data?.termsAndConditionsRead?.length
      ),
    };
  }

  function wizardStateToInteractionAdapter(
    updatedWizardState: BurstWizardState
  ) {
    const interaction = {
      data: {
        termsAndConditionsRead: [],
      },
    };
    if (updatedWizardState.data.termsAndConditionsRead) {
      // we expect the custom page for acknowledgements step to have termsAndConditionsRead field
      // with checkbox list type which is used as a termsAndConditionsRead of the burst
      const options = acknowledgementContent!.data?.termsAndConditionsRead?.validators?.find(
        v => v.name === 'In'
      ) as any;
      if (options?.value) {
        interaction.data.termsAndConditionsRead = options?.value;
      }
    }
    return interaction;
  }

  return (
    <BurstCustomPageRenderer
      title={t(ShopifyBurstPageTitle.Acknowledgement)}
      wizardState={wizardState}
      content={content}
      goToPrevStep={goToPrevStep}
      goToNextStep={goToNextStep}
      onDataUpdated={onDataUpdated}
      isNextButtonDisabled={!wizardState.data.termsAndConditionsRead}
      hasPreviousButton={false}
      interactionToWizardStateAdapter={interactionToWizardStateAdapter}
      wizardStateToInteractionAdapter={wizardStateToInteractionAdapter}
      contentKey="acknowledgementContent"
      {...rest}
    />
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTimeZoneAbbreviation } from '../../ReservableTableView/hooks/useColumnsHeaders';

const TRANSLATION_KEYS = {
  reservation: 'web.admin.channel.waitlistManagement.table.reservation',
  name: 'web.admin.channel.waitlistManagement.table.name',
  primaryEmail: 'web.admin.channel.waitlistManagement.table.email',
  company: 'web.admin.channel.waitlistManagement.table.company',
  reservationDate: 'web.admin.channel.waitlistManagement.table.reservationDate',
  reservationTime: 'web.admin.channel.waitlistManagement.table.reservationTime',
};

export enum ColumnKey {
  reservationDate = 'reservationDate',
  reservationTime = 'reservationTime',
  name = 'name',
  primaryEmail = 'primaryEmail',
  company = 'company',
}

export function useColumnsHeaders(channelTimezone: string) {
  const { t } = useTranslation();

  const columns = [
    {
      header: t(TRANSLATION_KEYS.reservationDate),
      key: ColumnKey.reservationDate,
      renderCell: (reservationDate: string) => <div>{reservationDate}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.reservationTime, {
        timeZone: getTimeZoneAbbreviation(channelTimezone),
      }),
      key: ColumnKey.reservationTime,
      disableSorting: true,
      renderCell: (reservationTime: string) => <div>{reservationTime}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.name),
      key: ColumnKey.name,
      renderCell: (name: string) => <div>{name}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.primaryEmail),
      key: ColumnKey.primaryEmail,
      renderCell: (primaryEmail: string) => <div>{primaryEmail}</div>,
    },
    {
      header: t(TRANSLATION_KEYS.company),
      key: ColumnKey.company,
      renderCell: (companyDisplayName: string) => (
        <div>{companyDisplayName}</div>
      ),
    },
  ];

  return { columns };
}

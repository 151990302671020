import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { getInteractionsOnContent } from 'lane-shared/graphql/query';
import {
  explodeFeatures,
  getResetPeriodFormattedDate,
} from 'lane-shared/helpers/features';
import { fromNow, simpleDate } from 'lane-shared/helpers/formatters';
import { TimeResetFrequencyEnum } from 'lane-shared/renderers/v5/features/Reset';
import { ContentType } from 'lane-shared/types/content/Content';
import { NumericSearchTypeEnum } from 'lane-shared/types/graphql/search';

import IconButton from 'components/general/IconButton';
import UserContentInteractionCard from 'components/lane/UserContentInteractionCard';
import DashboardWidget from 'components/layout/DashboardWidget';
import BuildingIllustration from 'components/svg/BuildingsIllustration';
import { H5 } from 'components/typography';

import ContentFeaturePaymentSummaryBadge from '../features/ContentFeaturePaymentSummaryBadge';
import { ContentFeatureQuantityBadge } from '../features/ContentFeatureQuantityBadge';
import WidgetContentWrapper from './WidgetContentWrapper';

import styles from './InteractiveContentDashboardWidget.scss';

const rows = 2;
const cols = 2;

export const views = ['list', 'th-large'] as const;
export const [VIEW_LIST, VIEW_GRID] = views;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: ContentType;
};

export default function InteractiveContentDashboardWidget({
  className,
  style,
  content,
}: Props) {
  const { t } = useTranslation();
  const [selectedView, setSelectedView] = useState(VIEW_GRID);
  const [, channelSlug] = useIsAdminView();
  const { quantityFeature, paymentFeature, resetFeature } = explodeFeatures(
    content?.features
  );

  const variables = {
    contentId: content?._id,
    search: {
      state: {
        isOpen: true,
        isActive: true,
      },
      sortBy: {
        key: '_updated',
        dir: 'desc',
      },
    },
    pagination: {
      start: 0,
      perPage: 10,
    },
  };

  if (resetFeature && resetFeature?.period !== TimeResetFrequencyEnum.Never) {
    (variables.search as any)._created = {
      type: NumericSearchTypeEnum.Between,
      value: content.lastReset,
      valueB: content.nextReset,
    };
  }

  const contentLink = routes.channelAdminContentInteractions
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    .replace(':id', channelSlug)
    .replace(':contentId', content._id);

  const [fetchInteractions, { data, loading, error, called }] = useLazyQuery(
    getInteractionsOnContent,
    {
      client: getClient(),
      variables,
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (content?._id && !called) {
      fetchInteractions();
    }
  }, [content?._id]);

  const interactionItems = data?.interactionsOnContent?.items || [];
  const isLoading = loading || !called;

  return (
    <DashboardWidget
      className={cx(styles.InteractiveContentDashboardWidget, className)}
      style={style}
      Header={<Link to={contentLink}>{content.name}</Link>}
      Menu={
        <menu>
          {resetFeature && (
            <h1 className={styles.menuHeader}>
              <span>
                {getResetPeriodFormattedDate({
                  period: resetFeature.period,
                  date: content.lastReset,
                })}
              </span>
              {t('to')}
              <span>
                {getResetPeriodFormattedDate({
                  period: resetFeature.period,
                  date: content.nextReset,
                })}
              </span>
            </h1>
          )}
          {paymentFeature && (
            <ContentFeaturePaymentSummaryBadge
              className={styles.badge}
              content={content}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              startDate={content.lastReset}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              endDate={content.nextReset}
            />
          )}
          {quantityFeature && (
            <ContentFeatureQuantityBadge
              className={styles.badge}
              content={content}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              startDate={content.lastReset}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
              endDate={content.nextReset}
            />
          )}

          {views.map(view => (
            <IconButton
              key={view}
              icon={view}
              selected={selectedView === view}
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"list" | "th-large"' is not assi... Remove this comment to see the full error message
              onClick={() => setSelectedView(view)}
              inverted
            />
          ))}
          <IconButton
            loading={loading}
            icon="sync"
            size="medium"
            selected={false}
            inverted
            // @ts-expect-error ts-migrate(2322) FIXME: Type '(options?: QueryLazyOptions<{ contentId: str... Remove this comment to see the full error message
            onClick={fetchInteractions}
          />
        </menu>
      }
      rows={rows}
      cols={cols}
    >
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: (false | Element)[]; loading: bo... Remove this comment to see the full error message */}
      <WidgetContentWrapper loading={isLoading} error={error}>
        {interactionItems.length === 0 && !isLoading && (
          <div className={styles.emptyState}>
            <BuildingIllustration />
            <H5 className={styles.emptyStateText}>{t('No results')}</H5>
          </div>
        )}
        {interactionItems.length !== 0 && (
          <>
            {/* @ts-expect-error ts-migrate(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message */}
            {selectedView === VIEW_LIST && (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>{t('Created')}</th>
                    <th>{t('Updated')}</th>
                    <th>{t('Person')}</th>
                    <th>...</th>
                  </tr>
                </thead>
                <tbody>
                  {interactionItems.map((interaction: any) => (
                    <tr key={interaction._id}>
                      <td>{simpleDate(interaction._created)}</td>
                      <td>{fromNow(interaction._updated)}</td>
                      <td>
                        <Link
                          to={routes.channelAdminTeamMember
                            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                            .replace(':id', channelSlug)
                            .replace(':userId', interaction.user._id)}
                        >
                          {interaction.user.profile.name}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={routes.channelAdminInteraction
                            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                            .replace(':id', channelSlug)
                            .replace(':interactionId', interaction._id)}
                        >
                          {t('View')}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {selectedView === VIEW_GRID && (
              <ul className={styles.interactions}>
                {interactionItems.map((interaction: any) => (
                  <Link
                    key={interaction._id}
                    to={routes.channelAdminInteraction
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      .replace(':id', channelSlug)
                      .replace(':interactionId', interaction._id)}
                  >
                    <UserContentInteractionCard
                      forAdmin
                      className={styles.interactionCard}
                      interaction={interaction}
                    />
                  </Link>
                ))}
              </ul>
            )}
          </>
        )}
      </WidgetContentWrapper>
    </DashboardWidget>
  );
}

import { FeatureFlag } from '../types/FeatureFlag';
import useFlag from './useFlag';

const FLAG = FeatureFlag.AuthenticationOIDC;

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-10-09T20:04:04+0530.
 */
export function useOIDCEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

import { ImporterEntityTypes } from '../../domains/importer/jobDefinition';

export const CSVImportProps = {
  [ImporterEntityTypes.Equipment]: [
    'equipment_id',
    'name',
    'created_by',
    'updated_by',
    'category',
    'status',
    'warranty_expiration_date',
    'install_date',
    // optional
    'make',
    'model',
    'serial',
    'notes',
    'location',
    'asset',
    'floor',
    'suite',
    'created_at',
    'updated_at',
  ],
  [ImporterEntityTypes.ServiceRequest]: [
    'service_request_id',
    'company',
    'description',
    'category',
    'issue',
    'location',
    'floor',
    'suite',
    'created_by',
    'updated_by',
    'status',
    // optional
    'assignee',
    'notes',
    'is_billable',
    'submit_on_behalf_of',
    'created_at',
    'updated_at',
  ],
  [ImporterEntityTypes.Schedule]: [
    'schedule_id',
    'title',
    'next_due_date',
    'until_date',
    'repeats',
    'days_ahead',
    'time_to_complete',
    'created_by',
    'updated_by',
    // optional
    'assignee',
    'notes',
    'steps',
    'meter_reading',
    'location',
    'created_at',
    'updated_at',
  ],
  [ImporterEntityTypes.Task]: [
    'task_id',
    'schedule_id',
    'repeats',
    'location',
    'status',
    'due_date',
    'estimate_time_to_complete',
    'created_by',
    'updated_by',
    // optional
    'notes',
    'hours',
    'steps',
    'meter_reading',
    'assignee',
    'schedule_notes',
    'created_at',
    'updated_at',
  ],
  [ImporterEntityTypes.EquipmentMapping]: [
    'source_entity_type',
    'source_entity_id',
    'equipment_id',
    // optional
    'mapping_id',
  ],
  [ImporterEntityTypes.AssigneeGroupMapping]: [
    'entity_type',
    'entity_id',
    'assignee_group_id',
  ],
};

export const FileImportStatus = {
  CREATED: 'Created',
  IN_PROGRESS: 'In progress',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const Providers = {
  IMPORTER: 'IMPORTER_1_0',
};

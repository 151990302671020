import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';

import cx from 'classnames';
import { useDatasetSchema, useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ValidationError } from 'yup';

import routes from 'lane-shared/config/routes';
import { RendererContext, UserDataContext } from 'lane-shared/contexts';
import { getQueryString } from 'lane-shared/helpers';
import {
  BUILDER_STEPS,
  CONTENT_TYPES,
} from 'lane-shared/helpers/constants/content';
import { EVENT_RESERVABLE_CONTENT_PUBLISHED } from 'lane-shared/helpers/constants/events';
import {
  STEP_EDITOR,
  STEP_INFO,
  STEP_PUBLISH,
  STEP_TARGETING,
} from 'lane-shared/helpers/constants/steps';
import { useCreateDraft, useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';
import { stepValidation } from 'lane-shared/validation';

import { AlertType } from 'components/lds';

import Button from '../../general/Button';
import ErrorMessage from '../../general/ErrorMessage';
import Loading from '../../general/Loading';
import Box from '../../layout/Box';
import Builder from '../../renderers/Builder';
import getDependentFeatureWarnings from '../../renderers/helpers/getDependentFeatureWarnings';
import { checkForPaymentFeatureErrors } from '../../renderers/helpers/checkForPaymentFeatureErrors';
import getMissingInputs from '../../renderers/helpers/getMissingInputs';
import getWorkflowWarnings from '../../renderers/helpers/getWorkflowWarnings';
import { H4, Text } from '../../typography';
import TemplateExportModal from '../TemplateExportModal';
import TemplateImportModal from '../TemplateImportModal';
import DraftContentHeader from './DraftContentHeader';
import DraftContentHistory from './DraftContentHistory';
import DraftContentPublish from './DraftContentPublish';
import DraftContentTargetingEdit from './DraftContentTargetingEdit';
import { DraftContentWarnings } from './DraftContentWarnings';
import DraftInfo from './DraftInfo';
import { useReservableAnalytics } from './hooks/useReservableAnalytics';
import useContentTargetingMissingModal from 'hooks/useContentTargetingMissingModal';

import styles from './DraftContent.scss';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';
import { useLazyQuery } from '@apollo/client';
import {
  getInteractionsOnContent,
  getAllSentNotificationsForContentCount,
} from 'lane-shared/graphql/query';
import { getClient } from 'lane-shared/apollo';

const menus = ['Features', 'Data', 'Workflows'];

type OwnProps = {
  channel?: any;
  library?: any;
  forCreate?: boolean;
  forEdit?: boolean;
  contentType: ContentTypeEnum;
  draft: any;
  liveContent: any;
  onUndo: () => void;
  onSave: () => void;
};

type Props = OwnProps;

const TRANSLATION_KEYS = {
  requiredOnStepError: 'web.admin.content.draftContent.errors.requiredOnStep',
};

export function DraftContent({
  channel,
  library,
  forCreate,
  forEdit,
  contentType = ContentTypeEnum.Content,
  draft,
  liveContent,
  onUndo,
  onSave,
}: Props) {
  const { user } = useContext(UserDataContext);
  const { constructBaseBlock, blocks } = useContext(RendererContext);
  const history = useHistory();
  const location = useLocation();
  const existingContent = (location as any).state?.content;
  const fromRef = useRef((location as any).state?.from);
  const { trackReservablePublished } = useReservableAnalytics();
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const { bootstrapStepper, draftContentTracker } = useDraftContentAnalytics({
    draftContent: draft,
  });
  const stepperTracker = bootstrapStepper(!!forCreate);

  const [fetchInteractions, { data: interactions }] = useLazyQuery(
    getInteractionsOnContent,
    {
      fetchPolicy: 'network-only',
      client: getClient(),
    }
  );

  const [fetchLiveNotifications, { data: allSentNofications }] = useLazyQuery(
    getAllSentNotificationsForContentCount,
    {
      fetchPolicy: 'network-only',
      client: getClient(),
    }
  );

  useEffect(() => {
    if (forEdit) {
      fetchInteractions({
        variables: {
          contentId: draft._id,
        },
      });
      fetchLiveNotifications({
        variables: {
          contentId: draft._id,
        },
      });
      draftContentTracker.Update.Resume();
    }
  }, [forEdit]);

  const menu = new URLSearchParams(location?.search).get('menu');
  const currentStep = new URLSearchParams(location?.search).get('step');
  const shouldCenterTab =
    menu && menus.includes(menu) && currentStep === STEP_EDITOR;

  const steps = useMemo(() => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return draft?.type ? BUILDER_STEPS[draft.type] : BUILDER_STEPS[contentType];
  }, [draft?.type, forCreate, contentType]);
  const [query, goToUrl] = useQueryString({ step: steps[0] });
  const { t } = useTranslation();
  const visitorManagementEnabled = useFlag(
    FeatureFlag.VisitorManagement,
    false
  );
  const [errorMessages, setErrorMessages] = useState<JSX.Element[]>([]);
  const [hasPublishBeenPressed, setHasPublishBeenPressed] = useState<boolean>(
    false
  );

  const {
    content,
    loading,
    error,
    validation,
    timeZone,
    hasChanged,
    validateStep,
    updateContent,
    updateTimeZone,
    addNotification,
    addMetatag,
    removeMetatag,
    addWorkflow,
    upgradeContent,
    migrateContent,
    createDraft,
    saveDraft,
    publishDraft,
    unpublishContent,
    deleteDraft,
  } = useCreateDraft({
    existingContent,
    forEdit,
    forCreate,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentTypeEnum' is not assignable to type '... Remove this comment to see the full error message
    contentType,
    draft,
    channel,
    stepValidation,
    step: query.step,
    baseBlock: constructBaseBlock(),
  });

  const { showContentTargetingMissingModal } = useContentTargetingMissingModal(
    styles
  );
  const { totalDatasetRecordsCount } = useDatasetSchema(
    content?.generator?.datasetSchema?._id
  );
  const isGenerator = Boolean(content?.generator);

  async function goQuery(props: any) {
    goToUrl(props);
  }

  useEffect(() => {
    const validateAllStepsAndCurrentStep = async () => {
      await validateAllSteps();
      await validateStep(currentStep);
    };
    if (hasPublishBeenPressed) {
      validateAllStepsAndCurrentStep();
    }
  }, [content, currentStep, hasPublishBeenPressed]);

  // set isEvent to true if startDate and endDate exist
  useEffect(() => {
    if (content) {
      if (!!content.startDate && !!content.endDate && !content.isEvent) {
        updateContent({ isEvent: true });
      }
    }
  }, [!!content?.startDate && !!content?.endDate]);

  async function onDeleteDraft() {
    try {
      if (isGenerator) {
        await window.Alert.confirm({
          title: t('web.admin.content.draftContent.alert.delete.title', {
            contentName: content.name,
          }),
          children: (
            <Box p={6}>
              {totalDatasetRecordsCount > 0 && (
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.delete.description.batch.promptChildren',
                    {
                      count: totalDatasetRecordsCount,
                      name: content.name,
                    }
                  )}
                </Text>
              )}
              <Text size="large" mb={4}>
                {t(
                  `web.admin.content.draftContent.alert.delete.description.batch.prompt`
                )}
              </Text>
            </Box>
          ),
        });
      } else {
        await window.Alert.confirm({
          title: t('web.admin.content.draftContent.alert.delete.title', {
            contentName: content.name,
          }),
          message: t(
            'web.admin.content.draftContent.alert.delete.description.single.prompt',
            { contentName: content.name }
          ),
        });
      }
    } catch (err) {
      // user cancelled.
      return;
    }

    if (await deleteDraft()) {
      history.push(`/l/channel/${channel.slug}/admin`);
    }
  }

  async function onUnpublishContent() {
    try {
      await window.Alert.confirm({
        title: t('web.admin.content.draftContent.alert.unpublish.title', {
          contentName: content?.name,
        }),
        message: t(
          'web.admin.content.draftContent.alert.unpublish.description.prompt',
          {
            contentName: content?.name,
          }
        ),
        children: liveContent.sectionContent?.length > 0 && (
          <div>
            <H4 mb={2}>
              {t(
                'web.admin.content.draftContent.alert.unpublish.description.sections.prompt'
              )}
            </H4>
            <ul>
              {liveContent.sectionContent.map((sectionContent: any) => (
                <li key={sectionContent._id}>{sectionContent.section.name}</li>
              ))}
            </ul>
          </div>
        ),
      });
    } catch (err) {
      // user cancelled
      return;
    }

    window.Alert.loading({
      title: t('web.admin.content.draftContent.alert.unpublish.loading'),
    });

    if (await unpublishContent()) {
      onUndo();
      draftContentTracker.Update.Unpublish(content);
      window.Toast.show(
        t('web.admin.content.draftContent.alert.unpublish.completed'),
        {
          contentName: content.name,
        }
      );
    }

    window.Alert.hide();
  }

  async function onPublish() {
    setHasPublishBeenPressed(true);

    const stepsWithErrors = await validateAllSteps();
    await getErrorMessages(stepsWithErrors);
    // Jump to the first tab with errors, unless the Publish step has errors
    if (stepsWithErrors.length) {
      const publishIndex = steps.indexOf(STEP_PUBLISH);
      const publishStepHasErrors = stepsWithErrors.includes(publishIndex);
      if (!publishStepHasErrors) {
        const firstErrorStep = stepsWithErrors[0] as number;
        goToUrl({ step: steps[firstErrorStep] });
      }
      return;
    }
    // if there is no targeting set for content (no placements), do not allow publishing:
    try {
      if (!content?.placements?.length && !content?.audiences?.length) {
        showContentTargetingMissingModal();
        return;
      }
      await window.Alert.confirm({
        title: t('web.admin.content.draftContent.alert.publish.title', {
          contentName: content?.name,
        }),
        message: t('web.admin.content.draftContent.alert.publish.prompt', {
          contentName: content?.name,
        }),
        confirmText: t('web.admin.content.draftContent.alert.publish.confirm'),
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    const missingInputs = content.isInteractive
      ? getMissingInputs(content, blocks)
      : [];

    const dependentFeatureWarnings = content.isInteractive
      ? getDependentFeatureWarnings(content.features, t)
      : [];

    const paymentFeatureError = checkForPaymentFeatureErrors(content.features);
    if (paymentFeatureError) {
      window.Alert.show({
        title: t('abp.publishContent.error.modal.title'),
        message: t(paymentFeatureError),
      });
      return;
    }

    const dependentWorkflowWarnings = getWorkflowWarnings(
      content.actions,
      content.startDate,
      content.endDate
    );

    const shouldDisplayWarnings =
      missingInputs.length ||
      dependentFeatureWarnings.length ||
      dependentWorkflowWarnings.length;

    if (shouldDisplayWarnings) {
      try {
        await window.Alert.confirm({
          title: t(
            'web.admin.content.draftContent.alert.publish.missing.title'
          ),
          message: t(
            'web.admin.content.draftContent.alert.publish.missing.prompt',
            { contentName: content?.name }
          ),
          children: (
            <ul className={styles.errors}>
              <ErrorMessage
                error={[
                  ...missingInputs.map((field: any) =>
                    t(
                      'web.admin.content.draftContent.alert.publish.missing.error',
                      {
                        fieldName: t(field.friendlyName) || field.name,
                      }
                    )
                  ),
                  ...dependentFeatureWarnings,
                  ...dependentWorkflowWarnings,
                ]}
                color={AlertType.warning}
              />
            </ul>
          ),
          confirmText: t(
            'web.admin.content.draftContent.alert.publish.missing.confirm'
          ),
        });
      } catch (err) {
        // user cancelled.
        return;
      }
    }

    window.Alert.loading({
      title: t(`web.admin.content.draftContent.alert.publish.loading`),
    });

    if (await publishDraft()) {
      draftContentTracker.Update.Publish(draft);
      trackReservablePublished(EVENT_RESERVABLE_CONTENT_PUBLISHED, draft);
      history.push(`/l/channel/${channel.slug}/admin/post/${content._id}`);
    }

    window.Alert.hide();
  }

  async function getErrorMessages(errorSteps: number[]) {
    const errorMessages: JSX.Element[] = [];
    if (errorSteps.length) {
      errorSteps.map(stepIndex => {
        const stepName = steps[stepIndex];
        errorMessages.push(
          <ErrorMessage
            key={stepIndex}
            error={t(TRANSLATION_KEYS.requiredOnStepError, { stepName })}
          />
        );
      });
      setErrorMessages(errorMessages);
    }
  }

  async function validateAllSteps() {
    const errorSteps = [];

    // for every step, validate
    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (!stepValidation[step]) {
        continue;
      }

      try {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        await stepValidation[step].validate(content, {
          abortEarly: false,
        });
      } catch (err) {
        errorSteps.push(i);
      }
    }

    setErrorSteps(errorSteps);
    return errorSteps;
  }
  const existingContentGeneratorId = content?.generator?._id;
  async function onSaveClicked() {
    setHasPublishBeenPressed(false);
    try {
      if (content?.generator) {
        try {
          await window.Alert.confirm({
            title: existingContentGeneratorId
              ? t('web.admin.content.draftContent.alert.save.existing')
              : t('web.admin.content.draftContent.alert.save.new'),
            children: existingContentGeneratorId ? (
              <Box p={6}>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.new.partOne'
                  )}
                  <b> {content?.name}</b>.
                </Text>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.new.partTwo'
                  )}
                  <b>
                    {' '}
                    {content?.generator?.datasetSchema?.name ||
                      content?.name}{' '}
                  </b>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.new.partThree',
                    { count: totalDatasetRecordsCount }
                  )}
                </Text>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.new.partFour'
                  )}
                </Text>
              </Box>
            ) : (
              <Box p={6}>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.existing.partOne'
                  )}
                  <b> {content?.name}</b>.
                </Text>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.existing.partTwo'
                  )}
                </Text>
                <Text size="large" mb={4}>
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.existing.partThree'
                  )}
                  <b>
                    {content?.generator?.datasetSchema?.name || content?.name}
                  </b>{' '}
                  {t(
                    'web.admin.content.draftContent.alert.save.prompt.existing.partFour'
                  )}
                </Text>
              </Box>
            ),
            confirmText: existingContentGeneratorId
              ? t('web.admin.content.draftContent.alert.save.confrim.existing')
              : t('web.admin.content.draftContent.alert.save.confirm.new'),
          });

          await saveDraft();
        } catch (err) {
          // user cancelled
        }
      }
      if (!content?.generator) {
        if (forCreate) {
          const saved = await createDraft();

          if (saved) {
            history.push(
              generatePath(routes.channelAdminDraft, {
                id: channel.slug,
                draftId: saved._id,
              }) + getQueryString(query),
              { from: fromRef.current }
            );
            return saved;
          }
        } else {
          return await saveDraft(onSave);
        }
      }
    } catch (err) {
      // error handled by hook
    }
  }

  async function onUndoClicked() {
    onUndo();
  }

  async function onCancel() {
    if (fromRef.current) {
      history.push(fromRef.current);
    } else {
      history.goBack();
    }
  }

  const [disableSaveAndNext, setDisableSaveAndNext] = useState<boolean>(false);
  async function processDataValidation(dataValidationErrors?: ValidationError) {
    if (dataValidationErrors) {
      setDisableSaveAndNext(true);
    } else {
      setDisableSaveAndNext(false);
    }
  }

  const interactionsCount =
    interactions?.interactionsOnContent?.items?.length ?? 0;
  const liveNotificationsCount =
    allSentNofications?.allSentNotificationsForContentCount ?? 0;

  if (!content && error) {
    return <ErrorMessage error={error} />;
  }

  if (!content) {
    return <Loading fullscreen />;
  }

  if (
    contentType === ContentTypeEnum.VisitorManagement &&
    (!visitorManagementEnabled ||
      !channel?.settings?.hasVisitorManagementEnabled)
  ) {
    const redirectTo = generatePath(routes.channel, { id: channel.slug });
    return <Redirect to={{ pathname: redirectTo }} />;
  }

  return (
    <div className={styles.DraftContent}>
      <DraftContentHeader
        loading={loading}
        forEdit={forEdit}
        forCreate={forCreate}
        hasChanged={hasChanged}
        onUndoClicked={forEdit ? onUndoClicked : undefined}
        onDeleteClicked={forEdit && !liveContent ? onDeleteDraft : undefined}
        onSaveClicked={onSaveClicked}
        onUnpublishClicked={liveContent ? onUnpublishContent : undefined}
        onPublishClicked={forEdit ? onPublish : undefined}
        onCancelClicked={onCancel}
        onGoStep={goQuery}
        steps={steps}
        errorSteps={errorSteps}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number | boolean | Date | (string |... Remove this comment to see the full error message
        currentStep={query.step}
        content={content}
        extendedOptions={
          <>
            <Button
              loading={loading}
              onClick={() => setIsImportOpen(true)}
              size="small"
            >
              {t('web.admin.content.draftContent.header.button.label.import')}
            </Button>
            <Button
              loading={loading}
              onClick={() => setIsExportOpen(true)}
              size="small"
            >
              {t('web.admin.content.draftContent.header.button.label.export')}
            </Button>
          </>
        }
        disableSaveAndNext={disableSaveAndNext}
        interactionsCount={interactionsCount}
        liveNotificationsCount={liveNotificationsCount}
      />

      <div style={{ marginLeft: '2em', marginTop: '2em' }}>
        <ErrorMessage error={error} />
        {errorMessages}
      </div>
      <DraftContentWarnings hasChanged={hasChanged} {...content} />
      <div
        className={cx(styles.contents, {
          [styles.centeredContent]: shouldCenterTab,
        })}
      >
        {query.step === STEP_INFO &&
          [
            CONTENT_TYPES.PROMOTION,
            CONTENT_TYPES.CONTENT,
            CONTENT_TYPES.PERK,
            CONTENT_TYPES.STATIC,
            CONTENT_TYPES.WORK_ORDER,
            CONTENT_TYPES.VISITOR_MANAGEMENT,
          ].includes(content.type) && (
            <DraftInfo
              validation={validation}
              channel={channel}
              library={library}
              content={content}
              timeZone={timeZone}
              onContentUpdated={updateContent}
              onMetatagAdded={addMetatag}
              onMetatagRemoved={removeMetatag}
              onTimeZoneUpdated={updateTimeZone}
              stepperTracker={stepperTracker}
            />
          )}

        {query.step === STEP_EDITOR && (
          <Builder
            timeZone={timeZone}
            user={user}
            channel={channel}
            content={content}
            onContentUpdated={updateContent}
            onContentUpgrade={upgradeContent}
            onContentMigrate={migrateContent}
            onAddWorkflow={addWorkflow}
            onDataValidation={processDataValidation}
            stepperTracker={stepperTracker}
            onSaveContentClicked={onSaveClicked}
          />
        )}
        {query.step === STEP_TARGETING &&
          [
            CONTENT_TYPES.STATIC,
            CONTENT_TYPES.CONTENT,
            CONTENT_TYPES.NOTICE,
            CONTENT_TYPES.SCHEDULED_NOTICE,
            CONTENT_TYPES.SCHEDULED_CONTENT,
            CONTENT_TYPES.PROMOTION,
            CONTENT_TYPES.PERK,
            CONTENT_TYPES.WORK_ORDER,
            CONTENT_TYPES.VISITOR_MANAGEMENT,
          ].includes(content.type) && (
            <DraftContentTargetingEdit
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
              user={user}
              hasChanged={hasChanged}
              content={content}
              liveContent={liveContent}
              channel={channel}
              onContentUpdated={updateContent}
              stepperTracker={stepperTracker}
            />
          )}

        {query.step === STEP_PUBLISH && (
          <DraftContentPublish
            timeZone={timeZone}
            updateTimezone={updateTimeZone}
            validation={validation}
            content={content}
            liveContent={liveContent}
            forCreate={forCreate}
            onContentUpdated={updateContent}
            onAddNotification={addNotification}
            channel={channel}
            stepperTracker={stepperTracker}
          />
        )}

        {forEdit && (
          <DraftContentHistory
            content={content}
            onContentUpdated={updateContent}
          />
        )}
      </div>
      <TemplateExportModal
        isOpen={isExportOpen}
        content={isExportOpen && content}
        onClose={() => setIsExportOpen(false)}
        forEdit={forEdit}
      />
      <TemplateImportModal
        isOpen={isImportOpen}
        channel={channel}
        content={content}
        onClose={() => setIsImportOpen(false)}
        onContentUpdated={content => {
          const newDraft = updateContent(content);
          if (forEdit) {
            draftContentTracker.Update.Import(newDraft);
          } else {
            draftContentTracker.Create.Import(newDraft);
          }
        }}
      />
    </div>
  );
}

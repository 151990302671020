import i18next from 'i18next';

import {
  DataSourceEnum,
  DataSourceType,
  RiseMigrationFeaturesListType,
  RiseMigrationFeaturesType,
} from './types';

export const DATA_SOURCES: DataSourceType[] = [
  {
    value: DataSourceEnum.sFTP,
    text: 'web.admin.channel.autoSetupWizard.sftp',
  },
  {
    value: DataSourceEnum.CSV,
    text: 'web.admin.channel.autoSetupWizard.csv',
  },
  {
    value: DataSourceEnum.RISE,
    text: 'web.admin.channel.autoSetupWizard.riseMigration',
  },
  {
    value: DataSourceEnum.CES,
    text: 'web.admin.channel.autoSetupWizard.ces',
  },
];

export const SETUP_STEPS: string[] = [
  i18next.t('web.admin.channel.autoSetupWizard.selectASource'),
  i18next.t('web.admin.channel.autoSetupWizard.selectATemplate'),
  i18next.t('web.admin.channel.autoSetupWizard.review'),
];

export const INITIAL_RISE_MIGRATION_FEATURES: RiseMigrationFeaturesType = {
  addRandomSuffixToSlug: false,
  migrateNonReservableAmenities: false,
  migrateAmenities: false,
  migrateDiscoveryLinks: false,
  migrateCommunityNews: false,
  migrateEvents: false,
  migrateForms: false,
  migrateTenants: false,
  migrateUsers: false,
  migrateCatUsers: [],
};

export const RISE_MIGRATION_FEATURES = ({
  addRandomSuffixEnabled = false,
  migrateTenantsEnabled = false,
}: {
  addRandomSuffixEnabled?: boolean;
  migrateTenantsEnabled?: boolean;
}): RiseMigrationFeaturesListType[] => {
  return [
    ...(addRandomSuffixEnabled
      ? [
          {
            key: 'addRandomSuffixToSlug',
            name: i18next.t(
              'web.admin.channel.autoSetupWizard.features.addRandomSuffixToSlug'
            ),
          },
        ]
      : []),
    {
      key: 'migrateNonReservableAmenities',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateNonReservableAmenities'
      ),
    },
    {
      key: 'migrateAmenities',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateAmenities'
      ),
    },
    {
      key: 'migrateDiscoveryLinks',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateDiscoveryLinks'
      ),
    },
    {
      key: 'migrateCommunityNews',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateCommunityNews'
      ),
    },
    {
      key: 'migrateEvents',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateEvents'
      ),
    },
    {
      key: 'migrateForms',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateForms'
      ),
    },
    ...(migrateTenantsEnabled
      ? [
          {
            key: 'migrateTenants',
            name: i18next.t(
              'web.admin.channel.autoSetupWizard.features.migrateTenants'
            ),
          },
        ]
      : []),
    {
      key: 'migrateUsers',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateUsers'
      ),
      description: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateUsersDescription'
      ),
    },
    {
      key: 'migrateCatUsers',
      name: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateCatUsers'
      ),
      description: i18next.t(
        'web.admin.channel.autoSetupWizard.features.migrateUsersDescription'
      ),
    },
  ];
};

export const DEFAULT_WHITELABEL_URL = 'https://activate.vts.com';

import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';
import i18n from 'localization';

const TOASTER_SECONDS = 5000;
export const buildCSVRows = (rows: any[], columns: any[]) => {
  const csvRows = [];

  const header = columns?.map(({ header }: { header: string }) => header);
  csvRows.push(header);

  rows.forEach(row => {
    const values = columns?.map(({ key, renderCell, renderForCSV }) => {
      if (renderForCSV) {
        return renderForCSV(row[key], row);
      }
      if (renderCell) {
        return renderCell(row[key], row);
      }
      return row[key];
    });
    csvRows.push(values);
  });
  return csvRows;
};

export const exportCSV = (rows: any[], columns: any[], filename: string) => {
  try {
    const csvRows = buildCSVRows(rows, columns);
    const csv = Papa.unparse(csvRows);

    makeFileDownload({
      name: `${filename}`,
      contents: csv,
      type: 'application/csv',
    });
    return csv;
  } catch (error) {
    window.Toast.show(i18n.t('web.admin.workOrder.table.export.error'), TOASTER_SECONDS);
    return '';
  }
};

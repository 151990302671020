import React, { useContext, useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { GroupType } from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { Table, PageSizeType } from 'design-system-web';

import ChannelAdminContext from '../ChannelAdminContext';
import { listRecurringChargesForChannelsQuery } from './helpers/chargesInvoiceQueries';
import { useGetRecurringChargesTableColumns } from './helpers/useGetRecurringChargesTableColumns';

import styles from './styles.scss';

export function RecurringChargesTable() {
  const { channel } = useContext(ChannelAdminContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [loadRecurringChargesList, loadRecurringChargesMetadata] = useLazyQuery(
    listRecurringChargesForChannelsQuery
  );
  const recurringChargesTableColumns = useGetRecurringChargesTableColumns(
    loadRecurringChargesMetadata.refetch!
  );

  useEffect(() => {
    if (channel) {
      loadRecurringChargesList({
        variables: {
          listRecurringChargesForChannelsRequest: {
            recurringChargesFilters: {
              groupIds: [convertToUUID(channel._id)],
              groupType: GroupType.GroupTypeActivateChannel,
            },
            pagination: {
              start: page * pageSize,
              perPage: pageSize,
            },
          },
        },
      });
    }
  }, [channel, loadRecurringChargesList, page, pageSize]);

  return (
    <div className={styles.recurringChargesTable}>
      <Table
        columns={recurringChargesTableColumns}
        data={
          loadRecurringChargesMetadata.data
            ? loadRecurringChargesMetadata.data.accounts
                .listRecurringChargesForChannels.recurringCharges
            : []
        }
        disableSorting
        isLoading={loadRecurringChargesMetadata.loading}
        pagination="server"
        totalRows={
          loadRecurringChargesMetadata.data
            ? loadRecurringChargesMetadata.data.accounts
                .listRecurringChargesForChannels.pageInfo.total
            : 0
        }
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
}

import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';

import Button from 'components/general/Button';
import Image from 'components/general/Image';
import { H4, M } from 'components/typography';

import restrictedImage from 'static/img/restricted-image.svg';

import styles from './styles.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  onNext: () => void;
  onNextLoggedIn: () => void;
};

export default function RestrictedNotice({
  className,
  onNext,
  onNextLoggedIn,
}: Props) {
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();
  const noticeInfo = user
    ? {
        title: 'This is private content',
        subTitle:
          'This account does not have permissions to view this private content.',
        buttonText: 'Back to home',
        buttonVariant: 'outlined',
        onNext: onNextLoggedIn,
      }
    : {
        title: 'You need access',
        subTitle:
          'Log in to an account with permissions to view this private content.',
        buttonText: 'Log in',
        buttonVariant: 'contained',
        onNext,
      };

  return (
    <div className={cx(styles.restrictedNotice, className)}>
      <Image
        className={styles.restrictedImage}
        src={restrictedImage}
        alt="restricted-image"
      />
      <H4 bold mb={4} className={styles.text}>
        {t(noticeInfo.title)}
      </H4>
      <M mb={4} className={styles.text}>
        {t(noticeInfo.subTitle)}
      </M>
      <Button
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'ButtonVar... Remove this comment to see the full error message
        variant={noticeInfo.buttonVariant}
        onClick={noticeInfo.onNext}
        fullWidth
        className={styles.actionButton}
      >
        {t(noticeInfo.buttonText)}
      </Button>
    </div>
  );
}

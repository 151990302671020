import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Papa from 'papaparse';

import { Button, TextArea } from 'design-system-web';

import { Mapping } from '../types';
import styles from '../styles.scss';

export const CustomImporterMapping = ({ setCustomMapping }: any) => {
  const { t } = useTranslation();
  const [customInput, setCustomInput] = useState(
    'Destination,Source,Transforms,Default Value'
  );

  const createMapping = (results: Papa.ParseResult<unknown>) => {
    const customMapping: Mapping = {};

    results.data.forEach((row: any) => {
      customMapping[row.Destination] = {
        keys: row.Source ? row.Source.split(',') : [],
        transforms: row.Transforms ? row.Transforms.split(',') : [],
        default: row['Default Value'],
      };
    });

    setCustomMapping(customMapping);
  };

  const handleClick = () => {
    Papa.parse(customInput, {
      header: true,
      complete: results => {
        createMapping(results);
      },
    });
  };

  return (
    <>
      <TextArea
        label={t('web.admin.sidebar.dataImport.converter.import.custom')}
        value={customInput}
        className={styles.inputField}
        onChange={val => setCustomInput(val)}
        showLengthIndicator
        minRows={10}
        showClear
      />
      <Button variant="tertiary" onClick={handleClick}>
        {t('web.admin.sidebar.dataImport.converter.createCustom')}
      </Button>
    </>
  );
};

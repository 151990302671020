// to standardize the accuracy of location across ios and android
export enum LocationAccuracyEnum {
  Fine = 'Fine',
  Coarse = 'Coarse',
}

// to standardize the availability of location access across ios and android
export enum LocationAvailabilityEnum {
  Foreground = 'Foreground',
  Background = 'Background',
}

export type LocationType = {
  latitude: number;
  longitude: number;
  distance?: number;
  accuracy: LocationAccuracyEnum | null;
  availability: LocationAvailabilityEnum | null;
  noLocation: boolean;
  error:
    | Error
    | {
        // GeolocationError from @react-native-community/geolocation
        code: number;
        message: string;
        PERMISSION_DENIED: number;
        POSITION_UNAVAILABLE: number;
        TIMEOUT: number;
      }
    | null;
};

export interface LocationHelperInterface {
  location: {
    latitude: number;
    longitude: number;
  };

  requestPermission: () => Promise<boolean>;
  isLocationGranted: (
    availability?: LocationAvailabilityEnum
  ) => Promise<boolean>;
  isWatching: () => boolean;
  stopWatching: () => void;
  startWatching: ({
    showRequestModal,
    availability,
    disableLocationPrecision,
    ignoreProximity,
  }: {
    showRequestModal?: boolean;
    ignoreNag?: boolean;
    availability: LocationAvailabilityEnum;
    disableLocationPrecision?: boolean;
    ignoreProximity?: boolean;
  }) => Promise<void>;
  getDistance: ({
    longitude,
    latitude,
  }: {
    longitude: number;
    latitude: number;
  }) => string;
}

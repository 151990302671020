import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { usePublicUserQuery } from 'lane-shared/hooks';
import { DocumentType } from 'lane-shared/types/DocumentType';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import UserStackListView from '../../../lane/UserStackListView';
import styleMap from '../styleMap';

import styles from './UserBlock.scss';

type BlockProps = WebBlockProps & {
  user: DocumentType;
};

export default function UserBlock({
  style,
  className,
  user,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { user: userData } = usePublicUserQuery({
    userId: user?._id,
  });

  return (
    <div
      className={cx(styles.UserBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <Link to={routes.user.replace(':id', user?._id)}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'PublicUserType | undefined' is not assignabl... Remove this comment to see the full error message */}
        <UserStackListView className={styles.User} user={userData} />
      </Link>
      <div className={styles.wrapper} />
    </div>
  );
}

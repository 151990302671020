import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  StripeProvider,
  Elements,
  CardElement,
  injectStripe, // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
} from 'react-stripe-elements';

import Button from '../general/Button';

import styles from './EmptyCreditCard.scss';

type EmptyCreditCardProps = {
  stripePublicKey: string;
  onAddCard: (token: { id: string }) => void;
  onEdit: () => void;
  onDone: () => void;
  loading: boolean;
  isEditing: boolean;
  className?: string;
  style?: React.CSSProperties;
};

export default function EmptyCreditCard({
  stripePublicKey,
  onAddCard,
  onEdit,
  onDone,
  loading,
  isEditing = false,
  className,
  style,
}: EmptyCreditCardProps) {
  const { t } = useTranslation();

  function editCreditCardForm() {
    onEdit();
  }

  async function submit(stripe: any) {
    try {
      const response = await stripe.createToken();

      if (response.token) {
        await onAddCard(response.token);
        onDone();
      }

      if (response.error) {
        window.Alert.alert({
          title: response.error.message,
        });
      }
    } catch (err) {
      window.Alert.alert({
        title: t('An error occurred!'),
        error: err,
      });
    }
  }

  function cancel() {
    onDone();
  }

  const ButtonRow = injectStripe(({ stripe }: any) => (
    <div className={styles.buttonRow}>
      <Button loading={loading} onClick={cancel}>
        {t('Cancel')}
      </Button>
      <Button
        loading={loading}
        onClick={() => submit(stripe)}
        variant="contained"
      >
        {t('Add Card')}
      </Button>
    </div>
  ));

  return (
    <div
      className={cx(styles.EmptyCreditCard, className)}
      style={style}
      data-private
    >
      {isEditing ? (
        <div className={styles.emptyCreditCard}>
          <StripeProvider apiKey={stripePublicKey}>
            <Elements>
              <div className={styles.cardForm}>
                <div className={styles.cardNumbersInput}>
                  <CardElement style={{ base: { fontSize: '18px' } }} />
                </div>
                <ButtonRow />
              </div>
            </Elements>
          </StripeProvider>
        </div>
      ) : (
        <Button
          className={styles.addButton}
          variant="outlined"
          size="medium"
          color="tertiary"
          startIcon={<Icon name="plus" />}
          onClick={editCreditCardForm}
          testId="addNewCard"
        >
          {t('Add a new Card')}
        </Button>
      )}
    </div>
  );
}

import gql from 'graphql-tag';

export const createAuth0MembershipInvitation = gql`
  mutation CreateAuth0MembershipInvitation($email: String!) {
    createAuth0MembershipInvitation(email: $email) {
      id
      ticketId
      organizationId
    }
  }
`;

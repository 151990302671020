import { useEffect } from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import history from '../helpers/history';

type Params = {
  whitelabel: WhiteLabelType;
};

/**
 * Sets up a listener for updating the document title.
 */
export default function useSetDocumentTitle({ whitelabel }: Params) {
  function setTitle(location: any, whitelabel: WhiteLabelType) {
    const state = location.state || {};
    const displayName = getDisplayName(whitelabel);

    document.title = state.title
      ? `${state.title} - ${displayName}`
      : `${displayName}`;
  }

  useEffect(() => {
    let unlisten: any;

    if (whitelabel) {
      setTitle(history.location, whitelabel);
      unlisten = history.listen(location => setTitle(location, whitelabel));
    }

    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, [whitelabel]);
}

import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentRendererContext } from 'lane-shared/contexts';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { EssensysProductInteractionType } from 'lane-shared/types/features/EssensysProductFeatureProperties';

import BlockPreview from 'components/builder/BlockPreview';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import { M } from 'components/typography';

import useEditModeProps from '../useEditModeProps';
import PointOfSale from './PointOfSale';

type BlockProps = WebBlockProps & {
  printingCredits: boolean;
  credits: boolean;
  autoHide: boolean;
};

export default function OperateProductBlock({
  className,
  style,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const editModeProps = useEditModeProps(otherProps);
  const { content } = useContext(ContentRendererContext);
  const { essensysProductFeature, paymentFeature } = explodeFeatures(
    content?.features
  );

  if (editModeProps['data-is-edit-mode']) {
    if (!essensysProductFeature) {
      return (
        <BlockPreview editModeProps={editModeProps}>
          <M>
            {t(
              'web.admin.channel.content.layout.editor.components.paymentFeatureBlock.preview'
            )}
          </M>
        </BlockPreview>
      );
    }

    return (
      <BlockPreview editModeProps={editModeProps}>
        {essensysProductFeature?.productInteractionType ===
        EssensysProductInteractionType.PointOfSale ? (
          <PointOfSale
            style={style}
            className={className}
            essensysProductFeature={essensysProductFeature}
            paymentFeature={paymentFeature}
            {...otherProps}
          />
        ) : null}
      </BlockPreview>
    );
  }

  if (!essensysProductFeature) {
    return null;
  }

  if (
    essensysProductFeature?.productInteractionType ===
    EssensysProductInteractionType.PointOfSale
  ) {
    return (
      <PointOfSale
        style={style}
        className={className}
        essensysProductFeature={essensysProductFeature}
        paymentFeature={paymentFeature}
        {...otherProps}
      />
    );
  }

  return null;
}

import React, { memo } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useTrackChannelView } from 'lane-shared/hooks/analytics';
import { DocumentType } from 'lane-shared/types/DocumentType';

import Thumbnail from '../general/Thumbnail';

import styles from './DirectoryListView.scss';

type OwnProps = {
  channel?: DocumentType;
  className?: string;
  onClick?: (...args: any[]) => any;
  style?: {};
};

type Props = OwnProps;

function DirectoryListView({ 
  className = '', 
  channel = {} as DocumentType, 
  onClick = () => {}, 
  style = {}
}: Props) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  useTrackChannelView(channel);

  return (
    <div
      className={cx(styles.DirectoryListView, className)}
      style={style}
      role="button"
      tabIndex={0}
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <Thumbnail
        className={styles.imageWrapper}
        src={
          imageUrl((channel as any).profile.logo) ||
          imageUrl((channel as any).profile.image)
        }
        name={(channel as any).name}
      />
      <div className={styles.textWrapper}>
        <p>{getDisplayName(channel)}</p>
      </div>
    </div>
  );
}

export default memo(DirectoryListView);

import React, { useState } from 'react';

import gql from 'graphql-tag';

import { getClient } from 'lane-shared/apollo';
import { ChannelMerchantFragment } from 'lane-shared/graphql/fragments';
import { pause } from 'lane-shared/helpers';

import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import Panel from 'components/layout/Panel';
import { H4, M } from 'components/typography';

import stripeConnectButtonImage from 'static/img/stripe-connect-button.png';

import styles from './StripeConnectSetup.scss';

const createMerchantAccountMutation = gql`
  ${ChannelMerchantFragment}

  mutation createMerchantAccountM($merchantAccount: ChannelMerchantInput!) {
    addChannelMerchantAccount(merchantAccount: $merchantAccount) {
      ...ChannelMerchantFragment
    }
  }
`;

export default function StripeConnectSetup({ channel }: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function createMerchantAccount() {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await pause(1000);

      const { data } = await getClient().mutate({
        mutation: createMerchantAccountMutation,
        variables: {
          merchantAccount: {
            channel: {
              _id: channel._id,
            },
            enabled: true,
          },
        },
      });

      window.location = data.addChannelMerchantAccount.onboardingLink.url;
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.StripeConnectSetup} data-is-loading={loading}>
      <ErrorMessage error={error} />
      <Panel className={styles.message}>
        <H4 mb={2}>
          Have payouts directly deposited into your business bank account
          through Stripe Connect
        </H4>
        <M mb={4} variant="secondary">
          Lane uses Stripe to get you paid quickly and keep your personal and
          payment information secure. Thousands of companies around the world
          trust Stripe to process payments for their users. Set up a Stripe
          account to get paid with Lane.
        </M>
        <ControlMenu>
          <Loading className={styles.loading} />
          <img
            className={styles.stripeButton}
            src={stripeConnectButtonImage}
            alt="Connect your Lane account to Stripe"
            role="button"
            tabIndex={0}
            onClick={createMerchantAccount}
          />
        </ControlMenu>
      </Panel>
    </div>
  );
}

import React, { useState, useEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';

import { generateExampleData } from 'lane-shared/helpers';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { H4 } from 'components/typography';

import PropertyValue from './PropertyValue';

import styles from './ExampleDataTable.scss';

const NUBBIN_ROW_WIDTH = 60;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  cacheKey: string;
  definition: PropertyType[];
  onAddField: (property: PropertyType) => void;
  onFieldResize: (property: PropertyType) => void;
  numberOfRows?: number;
};

export default function ExampleDataTable({
  className,
  style,
  cacheKey,
  definition,
  onAddField = () => null,
  onFieldResize = () => null,
  numberOfRows = 5,
}: Props) {
  const [resizing, setResizing] = useState<{
    start: number;
    last: number;
    field: PropertyType;
  } | null>(null);
  const exampleData = generateExampleData({
    key: cacheKey,
    rows: numberOfRows,
    definition,
  });

  useEffect(() => {
    window.addEventListener('mousemove', onColResizing);
    window.addEventListener('mouseleave', onColResizeEnd);
    window.addEventListener('mouseup', onColResizeEnd);

    return () => {
      window.removeEventListener('mousemove', onColResizing);
      window.removeEventListener('mouseleave', onColResizeEnd);
      window.removeEventListener('mouseup', onColResizeEnd);
    };
  }, [definition, resizing]);

  function onStartColResize({ e, field }: any) {
    setResizing({
      start: e.nativeEvent.clientX,
      last: e.nativeEvent.clientX,
      field,
    });

    e.stopPropagation();
  }

  function onColResizing(e: any) {
    if (!resizing) {
      return;
    }

    const field = definition.find(f => resizing.field.name === f.name);

    if (!field) {
      return;
    }

    field.width = Math.min(
      Math.max(
        (field.width || NUBBIN_ROW_WIDTH) + e.clientX - resizing.last,
        NUBBIN_ROW_WIDTH
      ),
      window.innerWidth
    );

    setResizing({
      ...resizing,
      last: e.clientX,
    });

    onFieldResize(field);
  }

  function onColResizeEnd() {
    if (!resizing) {
      return;
    }

    setTimeout(() => setResizing(null), 50);
  }

  if (!definition || !exampleData) {
    return null;
  }

  return (
    <div className={cx(styles.ExampleDataTable, className)} style={style}>
      <H4 mb={2}>Example Data</H4>
      <div className={styles.table} data-is-resizing={!!resizing}>
        <div className={cx(styles.row, styles.header)}>
          {definition.map(field => (
            <label
              key={field.name}
              style={{ width: field.width || NUBBIN_ROW_WIDTH }}
              data-is-editable={field.editable}
            >
              {field.friendlyName || field.name}
              <div
                className={styles.expand}
                role="presentation"
                onMouseDown={e => onStartColResize({ e, field })}
              />
            </label>
          ))}
          {onAddField && (
            <label
              onClick={e => onAddField(e)}
              style={{ width: NUBBIN_ROW_WIDTH }}
            >
              <Icon name="plus" className={styles.addIcon} />
            </label>
          )}
        </div>

        {exampleData.map((row: any, i: any) => (
          <div
            key={row._id}
            data-is-even={i % 2 === 0}
            className={cx(styles.row, styles.body)}
          >
            {definition.map(field => (
              <label
                key={field.name}
                style={{ width: field.width || NUBBIN_ROW_WIDTH }}
              >
                {/* @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
                <PropertyValue field={field} value={row[field.name]} />
              </label>
            ))}
            <label aria-label="Spacer" style={{ width: NUBBIN_ROW_WIDTH }} />
          </div>
        ))}
      </div>
    </div>
  );
}

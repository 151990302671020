import React from 'react';

import { Icon } from 'design-system-web';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { useUserCompanyInfo } from 'lane-shared/domains/billingPayments/helpers/getInvoiceDetails';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { useBillingAndPaymentEuComplianceEnabled } from 'lane-shared/domains/billingPayments/hooks/useBillingAndPaymentEuComplianceEnabled';
import {
  CurrencyFormatMap,
  currencyFromJSON,
  Invoice,
  ExternalPayerType,
} from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import {
  ICON_SET_FONTAWESOME,
  FONT_AWESOME_SOLID,
} from 'lane-shared/helpers/constants/icons';
import {
  dateFormatter,
  currencyFormatter,
} from 'lane-shared/helpers/formatters';

import { Button } from 'lane-web/src/components/general';

import { H3, H4, H5, M } from 'components/typography';

import styles from './styles.scss';

const InfoSection = ({ title, value }: { title: string; value: string }) => {
  return (
    <span className={styles.group}>
      <H5 className={styles.title}>{title}</H5>
      <M>{value}</M>
    </span>
  );
};

export function Info({
  invoice,
  isPaid,
}: {
  invoice: Invoice;
  isPaid: boolean;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const isEuComplianceEnabled = useBillingAndPaymentEuComplianceEnabled();

  const [isAdminView] = useIsAdminView();

  const { taxDetails } = useBillingPaymentSettingsForChannel({
    channelId: invoice.groupId,
  });

  const groupInfo = useUserCompanyInfo(invoice.groupId, true);
  const companyPayer =
    invoice.externalPayerType ===
    ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY;
  const payerInfo = useUserCompanyInfo(invoice.externalPayerId, companyPayer);

  const currencyCode = CurrencyFormatMap[currencyFromJSON(invoice.currency)];
  const formatCurrency = currencyFormatter({
    currency: currencyCode,
    currencyDisplay: 'narrowSymbol',
  });
  const businessNumberDetails = `${taxDetails?.businessTaxName}: ${taxDetails?.businessTaxNumber}`;
  const taxNumberDetails = `${taxDetails?.taxDisplayName}: ${taxDetails?.taxNumber}`;

  return (
    <div className={styles.invoiceDetailInfoSection}>
      <H4>{t('abp.invoice.details.invoiceDetails')}</H4>
      <div className={styles.invoiceDetailsSection}>
        <div>
          <InfoSection
            title={t('abp.invoice.details.id')}
            value={invoice.displayId}
          />
          <InfoSection
            title={t('abp.invoice.details.dateIssued')}
            value={
              invoice.invoiceDate
                ? dateFormatter(invoice.invoiceDate, dates.LONG_MONTH_DATE_YEAR)
                : '-'
            }
          />
          <span className={styles.group}>
            <H5 className={styles.title}>
              {t('abp.invoice.details.billFrom')}
            </H5>
            <M>{groupInfo.name}</M>
            <div className={styles.address}>
              <M>
                {[
                  groupInfo.address.city,
                  groupInfo.address.state,
                  groupInfo.address.country,
                ].join(', ')}
              </M>
              <M>{groupInfo.address.code}</M>
              {taxDetails?.businessTaxName && taxDetails?.businessTaxNumber && (
                <M>{businessNumberDetails}</M>
              )}
              {isEuComplianceEnabled && taxDetails?.taxDisplayName && taxDetails?.taxNumber && (
                <M>{taxNumberDetails}</M>
              )}
            </div>
          </span>
        </div>
        <div>
          <InfoSection
            title={t('abp.invoice.details.amountDue')}
            value={`${currencyCode} ${formatCurrency(invoice.amount)}`}
          />
          <InfoSection
            title={t('abp.invoice.details.dueDate')}
            value={
              invoice.dueDate
                ? dateFormatter(invoice.dueDate, dates.LONG_MONTH_DATE_YEAR)
                : '-'
            }
          />
          <span className={styles.group}>
            <H5 className={styles.title}>{t('abp.invoice.details.billTo')}</H5>
            <M>{payerInfo.name}</M>
            {companyPayer ? (
              <div className={styles.address}>
                <M>
                  {[
                    payerInfo.address.city,
                    payerInfo.address.state,
                    payerInfo.address.country,
                  ].join(', ')}
                </M>
                <M>{payerInfo.address.code}</M>
              </div>
            ) : (
              <M>{payerInfo.email}</M>
            )}
          </span>
        </div>
        <div className={styles.paymentSection}>
          {isPaid ? (
            <>
              <Icon
                name="check-circle"
                className={styles.paidIcon}
                set={ICON_SET_FONTAWESOME}
                type={FONT_AWESOME_SOLID}
                size="large"
              />
              <M className={styles.status}>
                {t('abp.invoiceList.status.paid')}
              </M>
            </>
          ) : (
            <M>{t('abp.invoice.details.amountDue')}</M>
          )}
          <span className={styles.currency}>
            <H3>{`${currencyCode} ${formatCurrency(invoice.amount)}`}</H3>
          </span>
          {!isPaid && !isAdminView && (
            <Button
              className={styles.button}
              variant="contained"
              onClick={() => {
                history.push(routes.meInvoicePayment, { invoice });
              }}
            >
              {t('abp.invoice.details.payInvoice')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { Checkbox } from 'design-system-web';

import { ChannelManagementModal } from './ChannelManagementModal';

export function TurnToLiveOrDraftModal({
  selectedChannel,
  isOpen,
  setIsOpen,
  onConfirmClick,
  isUsersToActive,
  setIsUsersToActive,
}: {
  selectedChannel: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmClick: () => Promise<void>;
  isUsersToActive: boolean;
  setIsUsersToActive: Dispatch<SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  // Setting to opposite of current value
  const isSettingToLive = !selectedChannel?.channel.isLive;

  const translationKey = isSettingToLive ? 'goLive' : 'turnToDraft';

  const body = isSettingToLive ? (
    <Checkbox
      selected={isUsersToActive}
      value={isUsersToActive}
      onChange={() => setIsUsersToActive(prev => !prev)}
      text={t(
        'web.admin.portalManagement.channelManagement.goLive.modal.usersToActive'
      )}
    />
  ) : (
    <></>
  );

  return (
    <ChannelManagementModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t(
        `web.admin.portalManagement.channelManagement.${translationKey}.modal.title`
      )}
      description={t(
        `web.admin.portalManagement.channelManagement.${translationKey}.modal.description`,
        { channelName: selectedChannel?.channel?.name }
      )}
      body={body}
      onClick={onConfirmClick}
    />
  );
}

import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';

import { getClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { emitter } from 'lane-shared/helpers';
import { EVENT_AUTH_TOKEN } from 'lane-shared/helpers/emitter';

function getClaimInviteRoute(inviteId: string) {
  return routes.invite.replace(/:inviteId/, inviteId);
}

export async function signInOAuth(
  idToken: string,
  provider: string,
  inviteId?: string | null
) {
  const { iss, sub } = jwtDecode<{ iss: string; sub: string }>(idToken);

  const response = await getClient().mutate<{
    signIn: { token: string; jti: string };
  }>({
    mutation: gql`
      mutation SignIn(
        $key: ID!
        $password: String!
        $provider: UserLoginProvider!
      ) {
        signIn(
          loginKey: $key
          password: $password
          loginType: OAuth
          loginProvider: $provider
        ) {
          token
          jti
        }
      }
    `,
    variables: {
      key: `${iss}:${sub}`,
      password: idToken,
      provider,
    },
  });

  if (!response.data) {
    throw new Error('Could not sign in.');
  }

  const { jti, token } = response.data.signIn;
  const route = !inviteId ? routes.home : getClaimInviteRoute(inviteId);

  emitter.emit(EVENT_AUTH_TOKEN, {
    authToken: { jti, token },
    route,
  });
}

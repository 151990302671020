import React from 'react';

import {
  AddProductToChargeDetails,
  ExternalPayerType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { Modal } from 'components/lds';
import { AddProductForm } from './AddProductForm';

export function AddProductFormModal({
  onAddProduct,
  currency,
  isOpen,
  onClose,
  payerId,
  payerType,
  testId,
}: {
  onAddProduct: (product: AddProductToChargeDetails) => void;
  currency: SupportedPaymentCurrency;
  isOpen: boolean;
  onClose: Function;
  payerId: string;
  payerType: ExternalPayerType;
  testId?: string;
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      testId={testId}
      closeButtonSize="large"
      disableContentPadding
    >
      <AddProductForm
        addProduct={onAddProduct}
        closeModal={onClose}
        currency={currency}
        payerId={payerId}
        payerType={payerType}
      />
    </Modal>
  );
}

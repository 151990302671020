import { useEffect, useState } from 'react';
import {
  HIDCredTemplateStrategy,
  getCredentialTemplateStrategy,
} from '../credentialTemplate';
import { ChannelIntegrationEditorProps } from '../../ChannelIntegrationEditorProps';

export function useHIDCredTemplateStrategy(
  idpSettings: ChannelIntegrationEditorProps['channelIntegration']['settings']
) {
  const isAppleWalletEnabled =
    idpSettings &&
    'isAppleWalletEnabled' in idpSettings &&
    idpSettings.isAppleWalletEnabled;
  const [
    currentStrategy,
    setCurrentStrategy,
  ] = useState<HIDCredTemplateStrategy>(() => {
    return getCredentialTemplateStrategy({
      hid22: true,
      hid30: isAppleWalletEnabled,
    });
  });

  useEffect(() => {
    const strategy = getCredentialTemplateStrategy({
      hid22: true,
      hid30: isAppleWalletEnabled,
    });

    setCurrentStrategy(strategy);
  }, [isAppleWalletEnabled]);

  return currentStrategy;
}

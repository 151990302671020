import React, { useContext, useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Validator } from 'lane-shared/types/Validator';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';
import { PropertyType } from 'lane-shared/types/properties/Property';

import { Button, Dropdown, Input } from 'lane-web/src/components';
import { H5, S } from 'lane-web/src/components/typography';
import { ServiceRequestBuilderContext } from 'lane-web/src/domains/workOrder/serviceRequest/components/builder/contexts/ServiceRequestBuilderContext';

import {
  DROPDOWN_VALIDATOR,
  ENABLED_INPUT_PACKAGED_TYPES,
  ENABLED_INPUT_TYPES,
} from '../../constants';
import { OptionType, YupError, YupInnerError } from '../../types';
import {
  customDataInputValidation,
  option as optionValidator,
} from '../../validation';
import SimpleOptions from './SimpleOptions';

import styles from './CustomInput.scss';

type Props = {
  input: PropertyType;
  onChange: (input: PropertyType) => void;
  onSave?: (input: PropertyType) => void;
  onDelete: (input: PropertyType) => void;
  disableSave?: boolean;
};

export default function CustomInput({
  input,
  onChange,
  onSave,
  onDelete,
  disableSave,
}: Props) {
  const { t } = useTranslation();
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [inputErrors, setInputErrors] = useState<YupError | null>(null);
  const [optionErrors, setOptionsError] = useState(null);
  const [noOptionError, setNoOptionError] = useState<string | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const optionsValidator = input.validators?.find(
    validator => validator.name === DROPDOWN_VALIDATOR.name
  );
  const { setIsServiceRequestDataReady } = useContext(
    ServiceRequestBuilderContext
  );

  const [options, setOptions] = useState<OptionType[]>(
    optionsValidator ? optionsValidator.value : []
  );

  const updateOptions = (options: OptionType[]) => {
    setOptions(options);
    const dropdownValidator: Validator = {
      ...DROPDOWN_VALIDATOR,
      value: [...options],
    };
    onChange({ ...input, validators: [dropdownValidator] });
  };

  useEffect(() => {
    setIsServiceRequestDataReady(!isSaveDisabled);
  }, [isSaveDisabled]);

  useEffect(() => {
    setIsSaveDisabled(Boolean(inputErrors || optionErrors || noOptionError));
  }, [inputErrors, optionErrors, noOptionError]);

  useEffect(() => {
    validateInputs();
  }, [input.name, input.friendlyName]);

  useEffect(() => {
    if (input.packagedType === PackagedTypeEnum.Dropdown) {
      validateOptions();
    }
  }, [options, input.packagedType]);

  const handleDelete = () => {
    setIsSaveDisabled(false);
    onDelete(input);
  };

  async function validateInputs() {
    try {
      await customDataInputValidation.validate(input, { abortEarly: false });
      setInputErrors(null);
    } catch (ex: any) {
      setInputErrors(ex);
    }
  }

  async function validateOptions() {
    const errors = [];
    if (options?.length === 0) {
      setNoOptionError(t`web.admin.serviceRequest.option.validation.text`);
      return;
    }
    setNoOptionError(null);

    for (const option of options) {
      try {
        await optionValidator.validate(option, {
          abortEarly: false,
        });
      } catch (ex: any) {
        errors.push({ optionId: option._id, errors: ex.errors });
      }
    }

    if (errors.length === 0) {
      setOptionsError(null);
      return;
    }

    // @ts-ignore
    setOptionsError(errors);
  }

  function onInputTypeChange(packagedType: PackagedTypeEnum) {
    if (packagedType === PackagedTypeEnum.Checkboxes) {
      onChange({ ...input, packagedType, type: ENABLED_INPUT_TYPES.Boolean });
    } else if (packagedType === PackagedTypeEnum.Dropdown) {
      onChange({ ...input, packagedType, type: ENABLED_INPUT_TYPES.Option });
    } else {
      onChange({ ...input, packagedType, type: ENABLED_INPUT_TYPES.String });
    }
  }

  function onInputSave() {
    if (!onSave) return;
    if (input.packagedType === PackagedTypeEnum.Dropdown) {
      const inputValidators = input.validators || [];
      const dropdownValidator: Validator = {
        ...DROPDOWN_VALIDATOR,
        value: [...options],
      };
      onSave({ ...input, validators: [...inputValidators, dropdownValidator] });
    } else {
      onSave(input);
    }
  }

  const friendlyNameError: YupInnerError | undefined = inputErrors?.inner?.find(
    (error: YupInnerError) => error.path === 'friendlyName'
  );

  const nameError: YupInnerError | undefined = inputErrors?.inner?.find(
    (error: YupInnerError) => error.path === 'name'
  );

  return (
    <div className={styles.customInputContainer}>
      <div className={styles.headerContainer}>
        <p className={styles.customInputName}>{input.friendlyName}</p>
        <p
          onClick={() => setIsCollapsed(!isCollapsed)}
          className={styles.collapseButton}
          data-test="collapse-button"
        >
          {isCollapsed ? t`web.expand` : t`web.collapse`}
        </p>
      </div>

      {!isCollapsed && (
        <div data-test="custom-input-form">
          <div className={styles.row}>
            <Input
              fieldName="name"
              error={nameError?.errors}
              placeholder={t`web.admin.serviceRequest.reporting.name.text`}
              label={t`web.admin.serviceRequest.reporting.text`}
              maxLength={25}
              showLengthIndicator
              testId={`name-input-${input._id}`}
              onChange={value => onChange({ ...input, name: value })}
              value={input.name}
            />
            <Dropdown
              className={styles.dropdown}
              onValueChange={onInputTypeChange}
              items={ENABLED_INPUT_PACKAGED_TYPES}
              initialValue={input.packagedType}
              isSearchable={false}
              value={input.packagedType}
              testId="input-type-dropdown"
            />
          </div>
          <div className={styles.row}>
            <Input
              fieldName="friendlyName"
              error={friendlyNameError?.errors}
              placeholder={t`web.admin.serviceRequest.prompt.text`}
              label={t`web.admin.serviceRequest.submission.form.text`}
              onChange={value => onChange({ ...input, friendlyName: value })}
              value={input.friendlyName}
            />
          </div>
          {input.packagedType === PackagedTypeEnum.Dropdown && (
            <div className={styles.row}>
              <div className={styles.column}>
                <H5>{t`web.admin.serviceRequest.options`}</H5>
                <SimpleOptions
                  options={options}
                  setOptions={options => updateOptions([...options])}
                  errors={optionErrors}
                />
                {noOptionError && (
                  <S className={styles.error}>{noOptionError}</S>
                )}
              </div>
            </div>
          )}

          <div className={cx(styles.row, styles.buttonRow)}>
            {/* TODO: add required check box back once data validation has been added to the submission form */}
            {/* {isNullable && (
          <>
            <Label>{t(FRIENDLY_PROPERTY_TEXTS.label.requiredValidator)}</Label>
            <Toggle
              value={requiredValidator?.value === true}
              onChange={toggleRequiredValidator}
              testId="validator-required-toggle"
            />
          </>
        )} */}

            {!disableSave && (
              <Button
                disabled={isSaveDisabled}
                onClick={onInputSave}
                variant="contained"
                testId="save-custom-question"
              >
                {t`web.admin.serviceRequest.save`}
              </Button>
            )}
          </div>
          <div className={styles.deleteContainer}>
            <Button
              startIcon={<Icon name="trash" set="Feather" />}
              variant="text-icon"
              onClick={handleDelete}
              className={styles.delete}
              testId="delete-custom-question"
            >
              {t`web.admin.serviceRequest.delete`}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';

import { ProfileQRCard, ControlMenu } from 'components';
import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import { ProfileFragment } from 'lane-shared/graphql/fragments';
import { fromNow, longDateTimeZone } from 'lane-shared/helpers/formatters';
import { UserLoginTypeEnum } from 'lane-shared/types/UserLogin';

import TabStrip from 'components/general/TabStrip';
import { H5 } from 'components/typography';

import { DisableUserButton } from './actionButtons/DisableUser';
import { MergeUserButton } from './actionButtons/MergeUser';
import { ResetPasswordButton } from './actionButtons/ResetPassword';
import { SuperUserButton } from './actionButtons/SuperUserButton';
import PortalManagementUserDeviceTokens from './tabs/PortalManagementUserDeviceTokens';
import PortalManagementUserEventSubscriptions from './tabs/PortalManagementUserEventSubscriptions';
import PortalManagementUserLogins from './tabs/PortalManagementUserLogins';
import PortalManagementUserMemberships from './tabs/PortalManagementUserMemberships';
import PortalManagementUserSessions from './tabs/PortalManagementUserSessions';
import { PortalManagementUserEditType } from './types';
import useQueryString from 'hooks/useQueryString';

import styles from './styles.scss';

type ManageUserProps = {
  match: {
    params: {
      userId: string;
    };
  };
};

const userQuery = gql`
  ${ProfileFragment}

  query getUser($id: UUID!) {
    userAdmin(_id: $id) {
      _id
      _created
      _updated
      status
      name
      settings
      isSuperUser
      lastSeen
      lastLogin
      lastGeo
      lastTimeZone
      locale
      primaryLocation {
        _id
      }
      profile {
        ...ProfileFragment
      }
      logins {
        _id
        status
        isPrimary
        key
        type
      }
    }
  }
`;

// Default View
const ProfileTab = {
  label: 'Profile',
  value: 'profile',
  Component: ({
    user,
  }: {
    user: PortalManagementUserEditType;
  }): JSX.Element => (
    <div className={styles.user}>
      {/* @ts-expect-error ts-migrate(2740) FIXME: Type 'PortalManagementUserEditType' is missing the... Remove this comment to see the full error message */}
      <ProfileQRCard user={user} />
      <div>
        <H5>Created</H5>
        {longDateTimeZone(user?._created, user?.lastTimeZone)}
        <H5 mt={2}>Updated</H5>
        {longDateTimeZone(user?._updated, user?.lastTimeZone)}
        <H5 mt={2}>Status</H5>
        {user?.status}
        <H5 mt={2}>Super User</H5>
        {user?.isSuperUser ? 'Yes' : 'No'}
        <H5 mt={2}>Last Seen</H5>
        {fromNow(user?.lastSeen)}
        <H5 mt={2}>Last Login</H5>
        {fromNow(user?.lastLogin)}
        <H5 mt={2}>Last Time Zone</H5>
        {user?.lastTimeZone}
        <H5 mt={2}>Locale</H5>
        {user?.locale}
      </div>
    </div>
  ),
};

const TabsMap = Object.freeze({
  profile: ProfileTab,
  memberships: {
    label: 'Memberships',
    value: 'memberships',
    Component: PortalManagementUserMemberships,
  },
  logins: {
    label: 'Logins',
    value: 'logins',
    Component: PortalManagementUserLogins,
  },
  sessions: {
    label: 'Sessions',
    value: 'sessions',
    Component: PortalManagementUserSessions,
  },
  'event-subscriptions': {
    label: 'Event Subscriptions',
    value: 'event-subscriptions',
    Component: PortalManagementUserEventSubscriptions,
  },
  'device-tokens': {
    label: 'Device Tokens',
    value: 'device-tokens',
    Component: PortalManagementUserDeviceTokens,
  },
});

const getTabComponent = (selectedTab: keyof typeof TabsMap) =>
  TabsMap[selectedTab].Component ?? TabsMap.profile.Component;

const userIsLoaded = (
  queryLoading: boolean,
  user: PortalManagementUserEditType | undefined
): user is PortalManagementUserEditType => !queryLoading && Boolean(user);

export default function ManageUser({ match }: ManageUserProps) {
  const [query, goToUrl] = useQueryString<{
    selectedTab: keyof typeof TabsMap;
  }>({
    selectedTab: 'profile',
  });

  const { data, loading: queryLoading } = useQuery<{
    userAdmin: PortalManagementUserEditType;
  }>(userQuery, {
    client: getAdminClient(),
    fetchPolicy: 'network-only',
    skip: !match.params?.userId,
    variables: {
      id: match.params?.userId,
    },
  });

  const { userAdmin: user } = data ?? {};
  if (!userIsLoaded(queryLoading, user)) return null;

  const hasEmailLogin = !!user?.logins?.find(
    login => login.type === UserLoginTypeEnum.Email
  );

  const CurrentTab = getTabComponent(query.selectedTab);

  return (
    <div className={styles.ManageUser}>
      <ControlMenu>
        <hr />
        <DisableUserButton user={user} parentLoading={queryLoading} />
        <SuperUserButton user={user} parentLoading={queryLoading} />
        <MergeUserButton user={user} />
        {hasEmailLogin && <ResetPasswordButton user={user} />}
      </ControlMenu>

      <TabStrip
        className={styles.tabStrip}
        tabs={Object.values(TabsMap)}
        selected={{ value: query.selectedTab }}
        onSelectTab={tab => goToUrl({ selectedTab: tab.value })}
      />

      <CurrentTab user={user} />
    </div>
  );
}

import React from 'react';

import styles from './CreditListView.scss';

export default function CreditListView({ title = '', text = '' }) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <h3>{title}</h3>
        <span className={styles.credit}>{text}</span>
      </div>
    </div>
  );
}

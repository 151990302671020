import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Loading, Input } from 'components';
import { useTranslation } from 'react-i18next';

import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { ChannelListItem } from '../../../../components/ChannelListItem';

import styles from './index.scss';

type OnboardingBuildingInputProps = {
  data: {
    building: ChannelType | null;
    buildingSearch: string;
    buildings: ChannelType[];
    whiteLabelName: string;
  };
  config: {
    isLoading: boolean;
  };
  handlers: {
    onBuildingSelectionChange: (building: ChannelType | null) => void;
    onBuildingSearchTextChange: (buildingSearch: string) => void;
  };
};

export function OnboardingBuildingInput({
  data: { building, buildingSearch, buildings },
  config: { isLoading },
  handlers: { onBuildingSelectionChange, onBuildingSearchTextChange },
}: OnboardingBuildingInputProps) {
  const { t } = useTranslation();
  const {
    translationKeys: { locationPlaceholder },
  } = useContinueAsGuest();

  return (
    <div>
      {building ? (
        <div className={styles.selectedChannelContainer}>
          <ChannelListItem
            className={cx([styles.channelResult, styles.selected])}
            showName={false}
            channel={building}
            onClick={() => {
              onBuildingSelectionChange(null);
            }}
          />
          <Icon
            name="edit"
            className={styles.checkIcon}
            set={ICON_SET_FONTAWESOME}
            type={FONT_AWESOME_REGULAR}
          />
        </div>
      ) : (
        <div>
          <Input
            onChange={onBuildingSearchTextChange}
            value={buildingSearch}
            placeholder={t(locationPlaceholder)}
            icon="search"
            testId="buildingLocation"
          />
          <br />
          <div className={styles.results}>
            {buildings.map((channel, index) => (
              <ChannelListItem
                key={channel._id || index}
                showName
                channel={channel}
                onClick={onBuildingSelectionChange}
                className={styles.channelResult}
              />
            ))}
            {isLoading && <Loading className={styles.loading} />}
          </div>
        </div>
      )}
    </div>
  );
}

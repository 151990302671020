import computeRectangle from 'lane-shared/helpers/mapBox/computeRectangle';
import { GeoCoordinateType } from 'lane-shared/types/baseTypes/GeoTypes';

export default function getMapCenterCoordinates(
  map: unknown,
  width: number,
  height: number
): GeoCoordinateType[] {
  // get the canvas of the mapbox
  const mapCanvas = (map as any).getCanvas();

  // Get the size of the canvas in CSS pixels.
  const rect = mapCanvas.getBoundingClientRect();
  const w = rect.width;
  const h = rect.height;

  // fit the desired width and height into the view port
  while (width >= 0.8 * w || height >= 0.8 * h) {
    width = Math.round(0.8 * width);
    height = Math.round(0.8 * height);
  }

  return computeRectangle(map, [w / 2, h / 2], [width, height]);
}

import React from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { ContentType } from 'lane-shared/types/content/Content';

import IconButton from 'components/general/IconButton';
import Label from 'components/general/Label';

import styles from './ContentListItem.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  content: ContentType;
  isSelected?: boolean;
  metatagId?: string;
  onClick: (content: ContentType) => void;
  onAddContent: (content: ContentType) => void;
  onRemoveContent: (content: ContentType) => void;
};

export default function ContentListItem({
  className,
  style,
  content,
  isSelected,
  metatagId,
  onClick,
  onRemoveContent,
  onAddContent,
}: Props) {
  return (
    <div
      className={cx(styles.ContentListItem, className)}
      style={style}
      key={content._id}
      data-selected={isSelected}
      role="button"
      tabIndex={0}
      onClick={() => onClick(content)}
      onKeyPress={e => e.key === Key.Enter && onClick(content)}
    >
      <Label mt={0}>{content.name}</Label>
      {content?.contentMetatags?.some(
        contentMetatag => contentMetatag.metatag._id === metatagId
      ) ? (
        <IconButton
          inverted
          icon="times"
          onClick={() => onRemoveContent(content)}
        />
      ) : (
        <IconButton
          inverted
          icon="plus"
          onClick={() => onAddContent(content)}
        />
      )}
    </div>
  );
}

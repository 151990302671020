import { InlineDropdownOption } from './InlineDropdownOption';

export interface CustomQuestions {
  label: string;
  type: CustomQuestionsType;
  options?: InlineDropdownOption[];
}

export enum CustomQuestionsType {
  None = 'None',
  Checkboxes = 'Checkboxes',
  Dropdown = 'Dropdown',
}

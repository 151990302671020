import { HIDCredTemplateStrategy } from '../../AccessManagementEditor/credentialTemplate';
import { HIDTemplatesMap } from '../types';

export function getTemplateLabel(
  hidTemplatesMap: HIDTemplatesMap,
  id: string,
  strategy: HIDCredTemplateStrategy
) {
  let label = '';

  for (const [key, value] of Object.entries(hidTemplatesMap)) {
    if (value[strategy] === id) {
      label = key;
      break;
    }
  }

  return label;
}

import { QuantityWaitlistByContent } from 'graphql-query-contracts';
import { Sort } from 'design-system-web';
import { SortDirectionEnum } from 'lane-shared/types/graphql/search';
import {
  formatISODateToMonthDayYear,
  formatISODateToHourMinutes,
} from './formatters';

export type RowData = {
  id: string;
  company: string;
  name: string;
  primaryEmail: string;
  dateJoined: string;
  timeJoined: string;
};

const DEFAULT_SORT = 'startDate';

export function waitlistItemToRowDataMapper(
  channelTimeZone: string,
  waitlistItems: QuantityWaitlistByContent[]
): RowData[] {
  const mappedRows = waitlistItems.map(waitlist => ({
    id: waitlist?.id,
    dateJoined: formatISODateToMonthDayYear(
      waitlist.createdAt,
      channelTimeZone
    ),
    timeJoined: formatISODateToHourMinutes(waitlist.createdAt, channelTimeZone),
    name: waitlist?.user?.name || '',
    primaryEmail: waitlist?.user?.email || '',
    company: waitlist?.companyName || '',
  }));

  return mappedRows || [];
}

export function getSort(sort: Sort) {
  const sortMapping: { [key: string]: string } = {
    company: 'Company',
    name: 'User_Name',
    primaryEmail: 'User_Email',
    dateJoined: 'startDate',
  };

  return {
    key: sort && sort.id ? sortMapping[sort.id] : DEFAULT_SORT,
    dir: sort && sort.direction ? sort.direction : SortDirectionEnum.Desc,
  };
}

import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useEssensysAccount } from 'lane-shared/hooks';

import BlockPreview from 'components/builder/BlockPreview';
import { Loading } from 'components/general';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';
import { H4, M } from 'components/typography';

import ErrorMessage from '../../../general/ErrorMessage';
import {
  CreditListView,
  PrintingCreditListView,
} from '../../../integrations/Essensys/components';
import styleMap from '../styleMap';

import styles from './EssensysCreditsBlock.scss';

type BlockProps = WebBlockProps & {
  printingCredits: boolean;
  credits: boolean;
  autoHide: boolean;
  profile?: boolean;
};

export default function EssensysCreditsBlock({
  className,
  style,
  printingCredits,
  credits,
  autoHide,
  profile = false,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const {
    creditsRemaining,
    printingPin,
    formatted,
    error,
    canViewCreditBlock,
    loading,
  } = useEssensysAccount({
    pollInterval: 10000,
    isEditMode: props['data-is-edit-mode'],
  });
  const { t } = useTranslation();
  const remainingText = t(
    'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.bookingCredits.remaining'
  );
  const overText = t(
    'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.bookingCredits.over'
  );

  if (props['data-is-edit-mode']) {
    return (
      <BlockPreview editModeProps={props}>
        <M className={styles.previewPadding}>
          {t(
            'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.noPreview'
          )}
        </M>
      </BlockPreview>
    );
  }

  if (!props['data-is-edit-mode'] && error && autoHide) {
    return null;
  }

  if (!canViewCreditBlock) {
    if (!profile) {
      return null;
    }

    if (loading) {
      return <Loading />;
    }

    return (
      <div
        className={cx(className, styles.EssensysCreditsBlock)}
        style={styleMap(false, style)}
        {...props}
      >
        <div className={cx(styles.Empty)}>
          <H4 v2 mb={3}>
            {t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.missingOut'
            )}
          </H4>
          <M>
            {t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.noCredits'
            )}
          </M>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(className, styles.EssensysCreditsBlock)}
      style={styleMap(false, style)}
      {...props}
    >
      <ErrorMessage error={error} />
      {printingCredits && (
        <div className={styles.ListViewContainer}>
          <h3 className={styles.printingPin}>
            {t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.printingHeader',
              { printingPin }
            )}
          </h3>
        </div>
      )}
      {credits && (
        <div className={styles.ListViewContainer}>
          <CreditListView
            title={t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.bookingCredits.title'
            )}
            text={t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.bookingCredits.count',
              {
                creditsUsed: formatted.creditsUsed,
                totalCredits: formatted.totalCredits,
                creditsRemaining,
                creditStatus: creditsRemaining > 0 ? remainingText : overText,
              }
            )}
          />
        </div>
      )}
      {printingCredits && (
        <div className={styles.ListViewContainer}>
          <PrintingCreditListView
            title={t(
              'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.printingCredits.balance'
            )}
            credits={[
              {
                name: t(
                  'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.printingCredits.blackAndWhite'
                ),
                amount: formatted.printingBlackAndWhiteCredit,
              },
              {
                name: t(
                  'web.admin.channel.content.layout.editor.components.essensysCreditsBlock.printingCredits.color'
                ),
                amount: formatted.printingColorCredit,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

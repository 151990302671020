import React, { useEffect, useState } from 'react';
import { Loading, ErrorMessage } from 'components';
import { useTranslation } from 'react-i18next';
import { getClient } from 'lane-shared/apollo';
import { getTag } from 'lane-shared/graphql/content';
import { useParams, useHistory } from 'react-router-dom';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { BreadCrumbs } from 'components/lds';
import { routes } from 'lane-shared/config';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { TagForm } from './TagForm';
import type { Tag } from './types';
import { LocalizationColumnType } from 'lane-shared/types/LocalizationColumnType';

import { updateTag } from 'lane-shared/graphql/mutation';

import styles from './Tags.scss';

export const TagEdit = () => {
  const { t } = useTranslation();

  const { channel } = useChannelAdminContext();

  const history = useHistory();
  const [error, setError] = useState('');
  const { tagId } = useParams<{ tagId: string }>();
  const [tag, setTag] = useState<Tag | undefined>();

  useEffect(() => {
    fetchTagDetails();
  }, [tagId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTagDetails = async () => {
    const variables = {
      id: convertToUUID(tagId),
    };

    const { data } = await getClient().query({
      fetchPolicy: 'network-only',
      query: getTag,
      variables,
    });

    if (data?.tag) {
      setTag(data.tag);
    }
  };

  const updateTagMutation = async ({
    name_l10n,
  }: {
    name_l10n?: LocalizationColumnType;
  }) => {
    try {
      await getClient().mutate({
        mutation: updateTag,
        variables: {
          tag: {
            id: convertToUUID(tagId),
            name_l10n,
            channelId: convertToUUID(channel?._id),
          },
        },
      });

      window.Toast.show(
        t('web.pages.portal.admin.library.tags.new.updateSuccess')
      );

      history.push(
        `${routes.channelAdminLibraryTagShow
          .replace(':id', channel?.slug!)
          .replace(':tagId', tagId)}`
      );
    } catch (e) {
      setError(e);
    }
  };

  const breadCrumbs = [
    {
      label: t('Tags'),
      url: `${routes.channelAdminLibraryTags.replace(':id', channel?.slug!)}`,
    },
    {
      label: tag?.name || '',
      url: `${routes.channelAdminLibraryTagShow
        .replace(':id', channel?.slug!)
        .replace(':tagId', tagId)}`,
    },
    {
      label: t('web.pages.portal.admin.audiences.update.breadCrumbCurrent'),
    },
  ];

  if (!channel || !tagId || !tag) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {error && <ErrorMessage error={error} />}
      <div className={styles.topContainer}>
        <BreadCrumbs links={breadCrumbs} />
      </div>
      <TagForm channel={channel} tag={tag} saveAction={updateTagMutation} />
    </div>
  );
};

import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { routes } from 'lane-shared/config';

import { AdminPage, PageHeader } from 'lane-web/src/components/layout';
import { ButtonType } from 'lane-web/src/components/layout/PageHeader';

import { RecurringChargesTable } from '../charges-invoices/RecurringChargesTable';

export function Memberships() {
  const { t } = useTranslation();
  const buttonType = 'primary' as ButtonType;
  const history = useHistory();

  const { channel } = useChannelAdminContext();

  const handleCreateMembership = () => {
    const channel_slug = channel?.slug || '';
    history.push(
      routes.channelAdminSubscriptionsCreate.replace(':id', channel_slug)
    );
  };

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.charges.subscriptions')}
        headerLevel="h3"
        actionButtons={[
          {
            label: t('abp.charges.createSubscription'),
            type: buttonType,
            onClick: handleCreateMembership,
          },
        ]}
      />
      <RecurringChargesTable />
    </AdminPage>
  );
}

import useFlag from '../useFlag';

const FLAG = 'flex.authentication.auth0_login_alert_box';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-04-30T22:28:50+0530.
 */
export function useHideAuth0LoginAlertBox() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import getMerchantAccountQuery from 'lane-shared/graphql/query/getMerchantAccountQuery';

import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import AdminPage from 'components/layout/AdminPage';
import StripeConnectSetup from 'components/stripe/StripeConnectSetup';
import StripeContinueOnboarding from 'components/stripe/StripeContinueOnboarding';

import useChannelAdminContext from 'hooks/useChannelAdminContext';

import styles from './MerchantAccounts.scss';

export default function MerchantAccounts() {
  const { channel } = useChannelAdminContext();
  const { data, loading, called, error, refetch } = useQuery(
    getMerchantAccountQuery,
    {
      fetchPolicy: 'no-cache',
      skip: !channel?._id,
      variables: {
        id: channel?._id,
      },
    }
  );
  let blockingError = false;
  // if error, check for error and env
  // handle non-prod accessing live-mode stripe account
  if (
    error?.message &&
    error.message !==
      'You tried to create a test mode account link for an account that was created in live mode.'
  ) {
    blockingError = true;
  }

  const merchantAccount = data?.channel?.merchantAccount;
  const detailsSubmitted =
    data?.channel?.merchantAccount?.stripeAccount?.details_submitted;
  useEffect(() => {
    if (merchantAccount?.onboardingLink?.expires_at) {
      // if the stripe link expires, get a new one.
      const linkExpired =
        new Date(merchantAccount?.onboardingLink?.expires_at * 1000) <
        new Date();

      if (linkExpired) {
        refetch({
          variables: {
            id: channel?._id,
          },
        });
      }
    }
  }, [merchantAccount?.onboardingLink?.expires_at]);

  const notSetup = channel && called && !blockingError && !merchantAccount;
  const notDoneOnboarding =
    channel && called && !blockingError && merchantAccount && !detailsSubmitted;
  const isReady = channel && called && !blockingError && detailsSubmitted;

  return (
    <AdminPage className={styles.MerchantAccounts}>
      <ErrorMessage error={error} />
      {loading && <Loading className={styles.loading} />}
      {notSetup && <StripeConnectSetup channel={channel} />}
      {notDoneOnboarding && (
        <StripeContinueOnboarding merchantAccount={merchantAccount} />
      )}
      {isReady && (
        <div className={styles.dashboard}>
          <h1>Your account has been linked with Stripe</h1>
          <p>
            You can login to Stripe with your Stripe login details to manage
            payments, issue refunds, etc.
          </p>
          <a
            href="https://dashboard.stripe.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Login to Stripe.
          </a>
        </div>
      )}
    </AdminPage>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ChargesList.scss';
import {
  Charge,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { Flex, ToggleView } from 'components';
import { SupportedLocaleEnum } from 'localization';
import { ChargeListItem } from './ChargeListItem/ChargeListItem';

export function ChargesList({
  charges,
  currency,
  locale,
  voidCharge,
}: {
  charges: Charge[];
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  voidCharge: (chargeId: string) => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <ToggleView
      title={`${t`abp.charges.chargeManager.list.title`} (${charges.length})`}
      className={styles.Title}
      defaultExpanded
    >
      <Flex direction="column" gap={5} width="full">
        {charges.map((charge, index) => (
          <ChargeListItem
            charge={charge}
            currency={currency}
            locale={locale}
            voidCharge={() => voidCharge(charge.id)}
            key={index}
          />
        ))}
      </Flex>
    </ToggleView>
  );
}

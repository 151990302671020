import React from 'react';

import {
  ProductItem,
  ProductType,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { styles } from './ChargeListItemPDFExport.styles';

import { View, Text, Svg, Line } from '@react-pdf/renderer';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { SupportedLocaleEnum } from 'localization';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';
import { PriceExceptionPDFExport } from '../PriceExceptionPDFExport/PriceExceptionPDFExport';

type Props = {
  item: ProductItem;
  labels: { [key: string]: string };
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
};
export function ChargeListItemPDFExport({
  item,
  labels,
  currency,
  locale,
}: Props) {
  const twoDecimalsWithCurrencySymbol = currencyFormatter({
    currency,
    minimumFractionDigits: 2,
  });

  const { product, quantity } = item;

  const productTypeLabel = () => {
    switch (product.productCategory.productType) {
      case ProductType.PRODUCT_TYPE_SERVICE_LABOUR:
        return labels.serviceCategory;
      case ProductType.PRODUCT_TYPE_SERVICE_MATERIALS:
        return labels.productCategory;
      default:
        return '-';
    }
  };

  const priceComponents = productToPriceComponents(product);
  const { listPrice } = getPricing({
    currency,
    locale,
    priceComponents,
  });

  return (
    <>
      <View style={styles.ChargeRow}>
        <View style={[styles.ChargeCell, styles.ChargeItemDetails]}>
          <Text style={styles.ChargeItemHeading}>{productTypeLabel()}</Text>
          <Text style={[styles.ChargeCellItem, styles.ProductCategory]}>
            {product.productCategory.name}
          </Text>
          <Text style={styles.ChargeCellItem}>{product.name}</Text>
        </View>
        <View style={[styles.ChargeCell, styles.ChargeItemQuantity]}>
          <Text style={styles.ChargeItemHeading}>{labels.quantity}</Text>
          <Text style={styles.ChargeCellItem}>{quantity}</Text>
        </View>
        <View style={[styles.ChargeCell, styles.ChargeCellAlignRight]}>
          <Text style={styles.ChargeItemHeading}>{labels.rate}</Text>
          <Text style={styles.ChargeCellItem}>
            {twoDecimalsWithCurrencySymbol(listPrice)}
          </Text>
        </View>
        <View style={[styles.ChargeCell, styles.ChargeCellAlignRight]}>
          <Text style={styles.ChargeItemHeading}>{labels.total}</Text>
          <Text style={styles.ChargeCellItem}>
            {twoDecimalsWithCurrencySymbol(listPrice * quantity)}
          </Text>
        </View>
      </View>
      {item.product.originalPrice && (
        <PriceExceptionPDFExport
          item={item}
          labels={labels}
          currency={currency}
          locale={locale}
        />
      )}
      <Svg height="1" width="504" style={styles.Line}>
        <Line x1="1100" y1="0" x2="0" y2="0" strokeWidth={1} stroke="#ECEDEF" />
      </Svg>
    </>
  );
}

import React from 'react';

import { Input, Radio } from 'components';
import { useTranslation } from 'react-i18next';

import { H3, P } from 'components/typography';

import { Template } from 'pages/portal/admin/channel/children/auto-setup-wizard/types';

import styles from './styles.scss';

export function SelectChannelTemplateId({
  onChange,
  currentValue,
  validationError,
  templateType,
  onTemplateTypeChange,
}: {
  onChange: (value: string) => void;
  currentValue: string | null;
  validationError: string | null;
  templateType: Template | null;
  onTemplateTypeChange: (template: Template) => void;
}) {
  const { t } = useTranslation();

  return (
    <section className={styles.Section} data-test="wizard-template-id-page">
      <H3>{t`web.admin.channel.autoSetupWizard.selectATemplate`}</H3>
      <P mb={2}>
        {t`web.admin.channel.autoSetupWizard.selectATemplateDescription`}
      </P>
      <Radio
        value={Template.EXISTING}
        text="web.admin.channel.autoSetupWizard.fromExisting"
        selected={
          templateType === Template.EXISTING ? Template.EXISTING : undefined
        }
        onChange={() => onTemplateTypeChange(Template.EXISTING)}
        style={{ marginTop: '1.5rem' }}
      />
      {templateType === Template.EXISTING && (
        <Input
          value={currentValue === Template.BLANK ? null : currentValue}
          onChange={v => onChange(v)}
          style={{ width: '30%', marginLeft: '2rem' }}
          placeholder={t`web.admin.channel.autoSetupWizard.channelId`}
          error={validationError ? [validationError] : null}
        />
      )}
      <Radio
        value={Template.BLANK}
        text="web.admin.channel.autoSetupWizard.createBlank"
        selected={templateType === Template.BLANK ? Template.BLANK : undefined}
        onChange={() => {
          onTemplateTypeChange(Template.BLANK);
        }}
        style={{ marginTop: '1.5rem' }}
      />
    </section>
  );
}

import routes from 'lane-shared/config/routes';

export const essensysAdminRoutes = [
  {
    name: 'Invoices',
    route: routes.meEssensysInvoices,
    icon: 'dollar-sign',
  },
];

export const essensysRoutes = [
  {
    name: 'Credits & Info',
    route: routes.meEssensysCredits,
    icon: 'info',
  },
];

export const privacyAndSubscriptionRoutes: any = [
  {
    name: 'Privacy & terms and conditions',
    route: routes.privacyTermsAndCondition,
    icon: 'align-justify',
  },
];

export const walletV2 = [
  {
    name: 'abp.wallet.walletV2',
    route: routes.meWalletV2,
  },
];

export const wallet = [
  {
    name: 'Wallet',
    route: routes.meWallet,
  },
];

export const accountInvoices = [
  {
    name: 'abp.routes.accountInvoices',
    route: routes.meAccountInvoices,
  },
];

export const recurringCharges = [
  {
    name: 'abp.routes.subscriptions',
    route: routes.meRecurringCharges,
  },
];

export const credits = [
  {
    name: 'abp.routes.credits',
    route: routes.meCredits,
  },
];

export const billingAndPaymentsRoutes = [
  {
    name: 'abp.routes.billingsAndPayments',
    route: routes.meWallet,
    icon: 'usd-circle',
  },
];

export default [
  {
    name: 'Profile',
    route: routes.me,
    icon: 'user',
  },
  {
    name: 'Media',
    route: routes.meMedia,
    icon: 'image',
  },
  {
    name: 'Templates',
    route: routes.meTemplates,
    icon: 'copy',
  },
  {
    name: 'Building & Companies',
    route: routes.meSubscriptionAdd,
    icon: 'home',
  },
  {
    name: 'Subscriptions',
    route: routes.meSubscriptions,
    icon: 'map-marked-alt',
  },

  {
    name: 'abp.routes.billingsAndPayments',
    route: routes.meWallet,
    icon: 'usd-circle',
  },
  {
    name: 'Recent Activity',
    route: routes.meRecentActivity,
    icon: 'align-justify',
  },
] as const;

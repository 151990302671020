import React from 'react';

import { useTranslation } from 'react-i18next';
import { useChannelTheme } from 'lane-shared/hooks';

import {
  getLibraryOptions,
  getValidationMessages,
  castForUpdate,
} from 'lane-shared/helpers';
import { H4, S, M, Text, H5 } from 'components/typography';
import { Flex } from 'components/layout';
import IconPicker from 'components/form/IconPicker';
import Loading from 'components/general/Loading';
import CirclePin from 'components/lane/CirclePin';
import MediaPickerButton from 'components/lane/MediaPickerButton';
import SectionListView from 'components/lane/SectionListView';
import SquarePin from 'components/lane/SquarePin';
import ThemePaletteColorSelectorButton from 'components/lane/ThemePaletteColorSelectorButton';
import { useUpdateSection } from 'lane-shared/hooks/useUpdateSection';
import { CustomizationPanel } from '../CustomizationPanel';
import { isEqual } from 'lodash';
import { ThemeColorPaletteType } from 'lane-shared/types/Theme';
import { IconSet } from 'lane-shared/helpers/constants/icons';

import type { SectionType } from 'lane-shared/types/sections/SectionType';
import type { ChannelType } from 'lane-shared/types/ChannelType';

import styles from './styles.scss';

type AppearanceProps = {
  section: SectionType;
  channel: ChannelType;
};

export function Appearance({ channel, section }: AppearanceProps) {
  const { t } = useTranslation();
  const theme = useChannelTheme(channel);

  const {
    onSectionUpdated,
    section: updatedSection,
    updating,
    validation,
    saveSection,
  } = useUpdateSection(castForUpdate(section));

  function backgroundColorChanged(backgroundColor: string | null) {
    if (backgroundColor) {
      onSectionUpdated({
        backgroundColor,
      });
    }
  }

  function colorChanged(color: string | null) {
    if (color) {
      onSectionUpdated({
        color,
      });
    }
  }

  if (!section || updating) {
    return <Loading />;
  }

  return (
    <Flex gap={5}>
      <div className={styles.pinPreview}>
        <H4>{t('web.admin.section.appearance.sectionPins')}</H4>
        <M className={styles.description} mt={1}>
          {t('web.admin.section.appearance.sectionPinsDescription')}
        </M>
        <div className={styles.appearancePreviewContainer}>
          <Flex justify="space-between" align="flex-start">
            <Flex direction="column" align="center" gap={5}>
              <H5>{t('web.admin.section.appearance.sectionPins.standard')}</H5>
              <SectionListView section={updatedSection} />
            </Flex>
            <Flex direction="column" align="center" gap={5}>
              <H5>{t('web.admin.section.appearance.sectionPins.square')}</H5>
              <SquarePin content={updatedSection} />
            </Flex>
            <Flex direction="column" align="center" gap={5}>
              <H5>{t('web.admin.section.appearance.sectionPins.circle')}</H5>
              <CirclePin content={updatedSection} />
            </Flex>
          </Flex>
        </div>
      </div>

      <CustomizationPanel
        disabled={isEqual(updatedSection, section)}
        buttonAction={saveSection}
      >
        <Flex justify="space-between">
          <div>
            <Text mb={2}>{t('web.admin.section.backgroundColor')}</Text>
            <ThemePaletteColorSelectorButton
              value={updatedSection.backgroundColor}
              palette={theme?.palette!}
              onColorSelected={backgroundColorChanged}
            />

            <Text mt={4} mb={2}>
              {t('web.admin.section.iconColor')}
            </Text>
            <ThemePaletteColorSelectorButton
              value={updatedSection.color}
              palette={theme?.palette!}
              onColorSelected={colorChanged}
            />

            <S mt={4} mb={2}>
              {t('web.admin.section.icon')}
            </S>
            <IconPicker
              palette={theme!.palette! as ThemeColorPaletteType}
              icon={updatedSection.icon}
              iconSet={updatedSection.iconSet}
              iconWeight={updatedSection.iconWeight}
              onChange={({
                icon,
                iconSet,
                iconWeight,
              }: {
                icon?: string;
                iconSet?: string;
                iconWeight?: string;
              }) =>
                onSectionUpdated({
                  icon: icon!,
                  iconSet: iconSet as IconSet,
                  iconWeight,
                })
              }
            />

            <S mt={4} mb={2}>
              {t('web.admin.section.logo')}
            </S>
            <MediaPickerButton
              showDownload={false}
              libraries={getLibraryOptions({ channel })}
              errors={getValidationMessages(validation, 'logo')}
              media={{ _id: section?.logo! }}
              onMediaSelected={media =>
                onSectionUpdated({
                  logo: (media && media?._id) || undefined,
                })
              }
              storageKey={channel?._id}
            />

            <S mt={4} mb={2}>
              {t('web.admin.section.background')}
            </S>
            <MediaPickerButton
              showDownload={false}
              libraries={getLibraryOptions({ channel })}
              errors={getValidationMessages(validation, 'backgroundImage')}
              media={{ _id: section?.backgroundImage! }}
              onMediaSelected={media =>
                onSectionUpdated({
                  backgroundImage: media?._id || undefined,
                })
              }
              storageKey={channel?._id}
            />
          </div>
        </Flex>
      </CustomizationPanel>
    </Flex>
  );
}

import { LaneType } from 'common-types';
import { AnalyticsInterface } from '../../../types/Analytics';
import { EVENT_UNPIN_SECTION_CONTENT } from '../../constants/events';

type EmitUnpinContentType = {
  userId: LaneType.UUID;
  channelId: LaneType.UUID;
  channelName?: string;
  contentId: LaneType.UUID;
  contentName?: string;
  sectionId: LaneType.UUID;
  sectionName?: string;
  analytics: Pick<AnalyticsInterface, 'track'>;
};

export function emitUnpinContent({
  userId,
  channelId,
  channelName,
  contentId,
  contentName,
  sectionId,
  sectionName,
  analytics,
}: EmitUnpinContentType) {
  analytics.track(EVENT_UNPIN_SECTION_CONTENT, {
    userId,
    channelId,
    channelName,
    contentId,
    contentName,
    sectionId,
    sectionName,
  });
}

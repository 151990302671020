import * as yup from 'yup';

import validateValidator from 'lane-shared/validation/validateValidator';

export const serviceCategoryStringValidation = yup
  .string()
  .required('A service category option name is required');

export const issueTypeStringValidation = yup
  .string()
  .required('An issue type option name is required');

export const categoryIssueValidation = yup.object().shape({
  serviceCategory: yup
    .string()
    .trim()
    .required('A service category name is required'),
  issueType: yup.string().trim().required('An issue type name is required'),
});

export const customDataInputValidation = yup.object().shape({
  friendlyName: yup.string().trim().required('A new display name is required'),
  name: yup.string().trim().required('A new data name is required'),
});

export const input = yup.object().shape({
  _id: yup.string().trim().required(),
  friendlyName: yup.string().trim().required(),
  validators: yup.array().of(validateValidator).nullable(),
});

export const option = yup.object().shape({
  name: yup.string().trim().required('Option name is required'),
  value: yup.string().trim(),
});

export const optionValidation = yup.array(option);

export const inputValidation = yup.array(input);

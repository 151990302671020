import React from 'react';

import VisitForm from 'components/integrations/BuildingEnginesPrismVisitors/components/VisitForm';

export default function VisitEdit({
  params,
  content,
}: {
  params: { id: string; visitId: string; visitorId: string };
  content: any;
}) {
  return <VisitForm interaction={params} content={content} />;
}

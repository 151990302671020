import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import {
  useInteractionsOnContentQuery,
  useFlag,
  useMultiLanguage,
} from 'lane-shared/hooks';
import { ThemeType } from 'lane-shared/types/Theme';
import { SortPropertyType } from 'lane-shared/types/blocks/Sort';
import { ContentType } from 'lane-shared/types/content/Content';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import EmptyState from '../../../general/EmptyState';
import Loading from '../../../general/Loading';
import UserContentInteractionCard from '../../../lane/UserContentInteractionCard';
import styleMap from '../styleMap';
import ListHeader from './components/ListHeader';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { cloneDeep } from 'lodash';

import styles from './InteractionsOnContentList.scss';

type BlockProps = WebBlockProps & {
  content: ContentType;
  flexDirection: 'row' | 'column';
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  perPage: number;
  theme: ThemeType;
  interactionState: 'Open' | 'Closed' | 'All';
  sort: SortPropertyType;
};

export default function InteractionsOnContentList({
  style,
  className,
  content,
  flexDirection,
  header,
  autoHide,
  showViewAll,
  perPage,
  interactionState,
  sort,
  theme,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const { translate } = useMultiLanguage();

  const {
    items: initialInteractions,
    loading,
    pageInfo,
    isHidden,
  } = useInteractionsOnContentQuery({
    contentId: content?._id,
    pollInterval: POLL_INTERVAL,
    interactionState,
    perPage,
    sort,
  });

  const isMLSLanguagePreviewSelectorEnabled = useFlag(
    FeatureFlag.MultiLanguageSupportLanguagePreviewSelector,
    false
  );

  const props = useEditModeProps(otherProps);

  if (!props['data-is-edit-mode'] && autoHide && isHidden) {
    return null;
  }

  let items = initialInteractions;

  if (isMLSLanguagePreviewSelectorEnabled && props['data-preview-language']) {
    items = translate({
      model: cloneDeep(items),
      columns: [
        'contentData.name',
        'contentData.description',
        'contentData.subtitle',
      ],
      previewLanguage: props['data-preview-language'],
    });
  }

  return (
    <div
      className={cx(className, styles.InteractionsOnContentList)}
      style={styleMap(false, style)}
      {...props}
    >
      <ListHeader
        theme={theme}
        header={header}
        totalItems={pageInfo?.total}
        currentItems={items?.length}
        link={routes.contentInteractions.replace(':id', content?._id)}
        viewAll={showViewAll}
      />
      <div className={styles[flexDirection]}>
        {/* @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message */}
        {loading && !items.length > 0 && <Loading />}
        {items?.map((interaction: any) => (
          <Link
            className={styles.channelLink}
            to={routes.interaction.replace(':id', interaction._id)}
            key={interaction._id}
          >
            <UserContentInteractionCard
              interaction={interaction}
              className={styles.interactionCard}
            />
          </Link>
        ))}
        {!(items?.length > 0) && (
          <EmptyState showIcon={false}>
            {t(
              'web.admin.channel.content.layout.editor.components.interactionsList.noResults'
            )}
          </EmptyState>
        )}
      </div>
      <div className={styles.wrapper} />
    </div>
  );
}

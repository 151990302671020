import React from 'react';

import { Button } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMutation, isApolloError, ApolloQueryResult } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { floorErrorCodeTypes } from 'activate-errors';
import { deleteFloor } from 'lane-shared/graphql/floors';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

import { H4, M } from 'lane-web/src/components/typography';

import { Modal } from 'components/lds';

import styles from './DeleteFloorModal.scss';

export function DeleteFloorModal({
  id,
  floorName,
  deleteFloorModalOpen,
  setDeleteFloorModalOpen,
  refetch,
}: {
  id: string;
  floorName: string;
  refetch?: (
    variables?:
      | Partial<{
          propertyId: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  deleteFloorModalOpen: boolean;
  setDeleteFloorModalOpen: (isModalOpen: boolean) => void;
}) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const history = useHistory();

  const [deleteFloorMutation] = useMutation(deleteFloor);

  const onDeleteFloor = async () => {
    try {
      await deleteFloorMutation({ variables: { id } });

      window.Toast.show(t('web.pages.portal.admin.floors.delete.successToast'));
      setDeleteFloorModalOpen(false);

      if (refetch) {
        await refetch();
      } else {
        history.push(
          routes.channelAdminFloorsListView.replace(':id', channel?.slug || '')
        );
      }
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          floorErrorCodeTypes,
          t('shared.floors.errors.generic')
        );

        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }
    }
  };

  return (
    <Modal
      isOpen={deleteFloorModalOpen}
      onClose={() => setDeleteFloorModalOpen(false)}
      className={styles.modalContainer}
      disableContentPadding
      includeHeader={false}
    >
      <div className={styles.modalContent}>
        <H4 className={styles.header} mb={2}>
          {t('web.pages.portal.admin.floors.delete.title')}
        </H4>
        <M variant="secondary" className={styles.textColor}>
          <Trans
            i18nKey="web.pages.portal.admin.floors.delete.text"
            values={{ floor: floorName }}
          />
        </M>
        <M mb={5} variant="secondary" className={styles.textColor}>
          {t('web.pages.portal.admin.floors.delete.warning')}
        </M>
      </div>
      <div className={styles.modalFooter}>
        <Button
          onClick={() => setDeleteFloorModalOpen(false)}
          testId="deleteFloorCancel"
          fullWidth
        >
          {t('web.pages.portal.admin.floors.delete.cancelText')}
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={onDeleteFloor}
          testId="deleteFloorConfirmation"
        >
          {t('web.pages.portal.admin.floors.delete.confirmText')}
        </Button>
      </div>
    </Modal>
  );
}

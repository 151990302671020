import { useContext } from 'react';

import { OnboardingContext, OnboardingContextType } from '.';

export const useOnboardingContext = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);

  if (!context) {
    throw new Error(
      'onboarding context must be used within OnboardingContextProvider scope'
    );
  }

  return context;
};

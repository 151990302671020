import React from 'react';

import Button from '../../../../general/Button';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
function GeoLocationInputBlock() {
  return <Button>Geolocationblock</Button>;
}

export default GeoLocationInputBlock;

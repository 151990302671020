import gql from 'graphql-tag';

export default gql`
  query getDatasetSchemasOnChannel(
    $channelId: UUID!
    $search: DatasetSchemaSearch
    $pagination: PaginationInput
  ) {
    datasetSchemas(
      channelId: $channelId
      search: $search
      pagination: $pagination
    ) {
      pageInfo {
        start
        total
        perPage
      }
      items {
        datasetSchema {
          _id
          _updated
          name
          description
        }
      }
    }
  }
`;

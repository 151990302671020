import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { ChipSelect, ChipStyle } from 'lane-web/src/components/ads';

import { ServiceRequestStatusEnum } from 'lane-web/src/domains/workOrder/serviceRequest/components/types/serviceRequestStatus';

import { Size } from 'components/ads/chipSelect/ChipSelect';
import Label from 'components/general/Label';

import styles from './StatusDropdown.scss';
import { updateServiceRequestStatusByUCIIdMutation } from 'graphql-queries';
import { getServiceRequestStatusLabel } from 'lane-shared/domains/workOrder/helpers';

const chipListColor: { [key: string]: ChipStyle } = {
  [ServiceRequestStatusEnum.Created]: ChipStyle.Green,
  [ServiceRequestStatusEnum.Accepted]: ChipStyle.Pink,
  [ServiceRequestStatusEnum.InProgress]: ChipStyle.Blue,
  [ServiceRequestStatusEnum.OnHold]: ChipStyle.Yellow,
  [ServiceRequestStatusEnum.Closed]: ChipStyle.Grey,
  [ServiceRequestStatusEnum.Declined]: ChipStyle.Grey,
  [ServiceRequestStatusEnum.Cancelled]: ChipStyle.Grey,
  [ServiceRequestStatusEnum.Complete]: ChipStyle.Grey,
};

type Props = {
  serviceRequestData: any;
  isServiceRequestList?: boolean;
  refetch?: () => void;
  pillSize?: Size;
  disableEdit?: boolean;
};

const getChipType = (status: string) => chipListColor[status] || ChipStyle.Red;

const ServiceRequestStatusDropdown = ({
  serviceRequestData,
  isServiceRequestList = false,
  refetch,
  pillSize,
  disableEdit = false,
}: Props) => {
  const { t } = useTranslation();

  const [SRStatus, setSRStatus] = useState({
    label: getServiceRequestStatusLabel(serviceRequestData?.status),
    value: serviceRequestData?.status,
    type: getChipType(serviceRequestData?.status),
  });
  const [statusOptions, setStatusOptions] = useState([SRStatus]);

  const nextStatuses = serviceRequestData?.nextStatuses;

  useEffect(() => {
    const availableStatuses =
      nextStatuses.map((singleStatus: string) => ({
        label: getServiceRequestStatusLabel(singleStatus),
        value: singleStatus,
        type: getChipType(singleStatus),
      })) || [];
    availableStatuses.push(SRStatus);
    setStatusOptions(availableStatuses);
  }, [SRStatus, nextStatuses]);

  useEffect(() => {
    if (serviceRequestData?.status) {
      setSRStatus({
        label: getServiceRequestStatusLabel(serviceRequestData?.status),
        value: serviceRequestData?.status,
        type: getChipType(serviceRequestData?.status),
      });
    }
  }, [serviceRequestData?.status]);

  const updateWorkOrderUCIStatus = async (value: string) => {
    if (value === ServiceRequestStatusEnum.Cancelled) {
      try {
        await window.Alert.confirm({
          title: t('web.admin.serviceRequest.cancel.modal.header'),
          message: t('web.admin.serviceRequest.cancel.modal.action.message'),
          cancelText: t(
            'web.admin.serviceRequest.cancel.modal.backbutton.text'
          ),
          confirmText: t('web.admin.serviceRequest.cancel.modal.button.text'),
        });
      } catch (err) {
        // user "cancelled" their cancellatiion request
        return;
      }
    }
    try {
      await getClient().mutate({
        mutation: updateServiceRequestStatusByUCIIdMutation,
        variables: {
          uciId: convertToUUID(serviceRequestData?.extRefId),
          status: value,
          skip: !serviceRequestData?.extRefId,
        },
      });
      setSRStatus({
        label: getServiceRequestStatusLabel(value),
        value,
        type: getChipType(serviceRequestData?.status),
      });
      if (refetch) {
        await refetch();
      }
      await window.Toast.show(
        t('web.admin.serviceRequest.status.update.success')
      );
    } catch (err) {
      await window.Alert.alert({
        title: t('web.admin.serviceRequest.status.update.failure'),
        error: err,
      });
    }
  };

  return (
    <div className={styles.chipSelectContainer}>
      {!isServiceRequestList && (
        <Label mb={1} mt={0}>
          {t('web.admin.serviceRequest.status')}
        </Label>
      )}
      <ChipSelect
        testId={`statusSelect-${serviceRequestData?.id}`}
        anchorClassName={styles.anchor}
        value={SRStatus.value}
        list={statusOptions}
        size={pillSize}
        onChange={updateWorkOrderUCIStatus}
        isDisabled={disableEdit}
      />
    </div>
  );
};

export default ServiceRequestStatusDropdown;

import React from 'react';

import { Document, Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';

import { styles } from './EquipmentPDFStyle';
import { MeterReadingExport } from './components/MeterReadingExport';
import { Equipment } from 'graphql-query-contracts';

type EquipmentPDFProps = {
  equipmentData?: Equipment | null;
  userData: any;
  textData: any;
  isPropertyChannel?: boolean;
  t: (val: string, opts?: any) => string;
};

const EquipmentPDF = ({
  equipmentData,
  userData,
  textData,
  isPropertyChannel = true,
  t,
}: EquipmentPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>{equipmentData?.name}</Text>
        <Text style={styles.subheader}>
          {textData.updatedAt} {simpleDate(equipmentData?.updatedAt)}{' '}
          {textData.by} {userData?.user?.name}
        </Text>
        <Svg height="8" width="550" style={styles.line}>
          <Line
            x1="1000"
            y1="0"
            x2="0"
            y2="0"
            strokeWidth={1}
            stroke="rgb(128,128,128)"
          />
        </Svg>

        <Text style={styles.header2}>{textData.details}</Text>

        <View style={styles.twoFieldRow}>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.status}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.status || ' '}
            </Text>
          </View>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.category}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.category || ' '}
            </Text>
          </View>
        </View>

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>{textData.location}</Text>
          <Text style={styles.subHeadingValues}>
            {equipmentData?.location || ' '}
          </Text>
        </View>

        {isPropertyChannel && (
          <View style={styles.twoFieldRow}>
            <View style={styles.twoFieldContainer}>
              <Text style={styles.subheadings}>{textData.floor}</Text>
              <Text style={styles.subHeadingValues}>
                {equipmentData?.floor || ' '}
              </Text>
            </View>
            <View style={styles.twoFieldContainer}>
              <Text style={styles.subheadings}>{textData.suite}</Text>
              <Text style={styles.subHeadingValues}>
                {equipmentData?.suite || ' '}
              </Text>
            </View>
          </View>
        )}

        <View style={styles.twoFieldRow}>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.make}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.make || ' '}
            </Text>
          </View>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.model}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.model || ' '}
            </Text>
          </View>
        </View>

        <View style={styles.twoFieldRow}>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.serial}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.serial || ' '}
            </Text>
          </View>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.asset}</Text>
            <Text style={styles.subHeadingValues}>
              {equipmentData?.asset || ' '}
            </Text>
          </View>
        </View>

        <View style={styles.twoFieldRow}>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>
              {textData?.warrantyExpirationDate}
            </Text>
            <Text style={styles.subHeadingValues}>
              {simpleDate(equipmentData?.warrantyExpirationDate) || ' '}
            </Text>
          </View>
          <View style={styles.twoFieldContainer}>
            <Text style={styles.subheadings}>{textData.installDate}</Text>
            <Text style={styles.subHeadingValues}>
              {simpleDate(equipmentData?.installDate) || ' '}
            </Text>
          </View>
        </View>

        <View style={styles.fieldContainer}>
          <Text style={styles.subheadings}>{textData.notes}</Text>
          <Text style={styles.subHeadingValues}>
            {equipmentData?.notes || ' '}
          </Text>
        </View>

        <Svg height="8" width="550" style={styles.line}>
          <Line
            x1="1000"
            y1="0"
            x2="0"
            y2="0"
            strokeWidth={1}
            stroke="rgb(128,128,128)"
          />
        </Svg>

        {equipmentData?.meterReadingSettings &&
          equipmentData?.meterReadingSettings?.length > 0 && (
            <Text style={styles.header2}>{textData.meterReadingName}</Text>
          )}

        {equipmentData?.meterReadingSettings &&
          equipmentData?.meterReadingSettings?.length > 0 && (
            <View style={styles.MeterReadingWrapper}>
              {equipmentData.meterReadingSettings.map((item, subindex) => (
                <MeterReadingExport
                  key={subindex}
                  meterReadingSettingData={item}
                  textData={textData}
                  t={t}
                />
              ))}
            </View>
          )}

        <Text
          style={styles.pageNumber}
          render={({ pageNumber }) => `Pg ${pageNumber}`}
          fixed
        />
      </Page>
    </Document>
  );
};
export default EquipmentPDF;

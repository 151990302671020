import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useContainerLayoutListener } from '../../../hooks';
import styles from './index.scss';

const MOBILE_WIDTH_PX = 450;
export function DateRangeInputSkeletonLoader() {
  const { containerRef, isBelowThreshold } = useContainerLayoutListener(
    MOBILE_WIDTH_PX
  );

  return (
    <div
      ref={containerRef}
      className={isBelowThreshold ? styles.mobileLayout : styles.container}
      data-test="date-range-skeleton-loader"
    >
      <Skeleton className={styles.date} style={{ height: 48 }} />
      <Skeleton className={styles.startTime} style={{ height: 48 }} />
      <Skeleton className={styles.endTime} style={{ height: 48 }} />
    </div>
  );
}

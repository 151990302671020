import { ContentType } from 'lane-shared/types/content/Content';

export const REGION_KEY = 'region';
export const REGION_NAME = 'Region';

export default function extractRegionAttribute(
  content: ContentType | undefined
) {
  return content?.contentMetatags?.find(
    ({ metatag }) =>
      metatag.properties.key === REGION_KEY && metatag.name === REGION_NAME
  );
}

import {
  RegionValue,
  LocationValue,
} from 'lane-shared/hooks/integrations/shopify/locationFilter';

export default function isRegionLocationFilter(
  filter: LocationValue | RegionValue
): filter is RegionValue {
  return (filter as RegionValue).locations !== undefined;
}

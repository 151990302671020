import { createContext } from 'react';

type ServiceRequestBuilderContextType = {
  isServiceRequestDataReady: boolean;
  setIsServiceRequestDataReady: (value: boolean) => void;
};

export const ServiceRequestBuilderContext = createContext<ServiceRequestBuilderContextType>(
  {
    isServiceRequestDataReady: true,
    setIsServiceRequestDataReady: () => {},
  }
);

import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { ChannelsContext } from 'lane-shared/contexts';
import { pause } from 'lane-shared/helpers';
import { useChannelProfileQuery } from 'lane-shared/hooks';
import { ChannelType } from 'lane-shared/types/ChannelType';

import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import { history } from '../../../../helpers';
import styleMap from '../styleMap';

import styles from './ChannelBlock.scss';

type BlockProps = WebBlockProps & {
  channel: ChannelType;
};

export default function ChannelBlock({
  style,
  className,
  channel,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const props = useEditModeProps(otherProps);
  const [switching, setSwitching] = useState(false);
  const { switchChannel } = useContext(ChannelsContext);

  const { channel: channelData } = useChannelProfileQuery({
    channelId: channel?._id,
    channelSlug: channel?.slug,
  });

  async function onChannelChange(channel: ChannelType) {
    if (switching) {
      return;
    }

    setSwitching(true);

    window.Alert.loading({
      title: t(
        'web.admin.channel.content.layout.editor.components.channelBlock.switching'
      ),
    });

    try {
      await switchChannel(channel._id);
    } catch (error) {
      window.Alert.alert({
        title: t(
          'web.admin.channel.content.layout.editor.components.channelBlock.alert'
        ),
        error,
      });
      setSwitching(false);
      return;
    }

    history.push(routes.home);

    await pause();
    window.Alert.hide();
    setSwitching(false);
  }

  return (
    <div className={cx(styles.ChannelBlock, className)}>
      <button
        onClick={() => onChannelChange(channel)}
        className={cx(styles.ChannelBlock, className)}
        style={styleMap(false, style)}
        {...props}
      >
        <ChannelCircleListView channel={channelData} />
      </button>
    </div>
  );
}

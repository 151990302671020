import gql from 'graphql-tag';

import { getClient } from '../index';

type Feedback = {
  comment: String;
  data?: Record<string, any>;
};

export default async function submitFeedbackMutation(feedback: Feedback) {
  return getClient().mutate({
    variables: { feedback },
    mutation: gql`
      mutation SubmitFeedbackForm($feedback: FeedbackFormInput!) {
        submitFeedbackForm(feedback: $feedback) {
          _id
        }
      }
    `,
  });
}

import React from 'react';

import { history } from 'helpers';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';

import ChannelIntegrationEditorAutoRenderer from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorAutoRenderer';
import { ChannelIntegrationEditorProps } from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';

import styles from './HID.scss';

export default function HID({
  forCreate,
  ...props
}: ChannelIntegrationEditorProps) {
  const { channelIntegration, channel } = props;
  const { t } = useTranslation();

  const supportPageId = channelIntegration?.settings?.supportPage?._id;

  async function navigateToPage() {
    try {
      await window.Alert.confirm({
        title: t`web.admin.channel.integrations.hid.leave-alert.title`,
        message: t`web.admin.channel.integrations.hid.leave-alert.description`,
        confirmText: t`web.admin.channel.integrations.hid.leave-alert.confirm`,
      });
    } catch (err) {
      // user cancelled.
      return;
    }

    history.push(
      routes.channelAdminContent
        .replace(':id', channel.slug)
        .replace(':contentId', supportPageId)
    );
  }

  if (forCreate) {
    return (
      <div>
        <p>{t`web.admin.channel.integrations.hid.create`}</p>
      </div>
    );
  }

  return (
    <div className={styles.HID}>
      <ChannelIntegrationEditorAutoRenderer {...props} />
      <span role="navigation" onClick={navigateToPage}>
        {t`web.admin.channel.integrations.hid.leave`}
      </span>
    </div>
  );
}

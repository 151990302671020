import React, { useEffect, useMemo, useState } from 'react';

import registerBlockRenderer from 'lane-shared/helpers/blocks/registerBlockRenderer';
import { LinkType } from 'lane-shared/types/baseTypes/LinkType';

import RendererContext from '../contexts/RendererContext';
import createBlockInstance from '../renderers/v5/createBlockInstance';
import getBlockKey from '../renderers/v5/getBlockKey';
import { PlatformEnum } from '../types/PlatformEnum';
import {
  BlockRenderInterface,
  BlockType,
} from '../types/blocks/BlockInterface';

type Props = {
  linkHandler: ({ value }: { value: LinkType }) => Promise<void>;
  primitives: any;
  platform: PlatformEnum;
  children: React.ReactNode;
  blockDefinitions: BlockType[];
};

export default function RendererProvider({
  linkHandler,
  primitives,
  platform,
  children,
  blockDefinitions,
}: Props) {
  const [blocks, setBlocks] = useState<{ [key: string]: BlockRenderInterface }>(
    {}
  );
  const blockArray = useMemo(() => Object.values(blocks), [blocks]);
  const availableBlocks = useMemo<BlockRenderInterface[]>(
    () => blockArray.filter(block => !block?.def?.tags?.includes('Legacy')),
    [blockArray]
  );

  const tags = useMemo(() => {
    const customSortedOrder = [
      'Text',
      'Hero',
      'Other',
      'Media',
      'Separator',
      'Container',
      'Content',
      'Team',
      'Channel',
    ];
    const customSort = (a: string, b: string) => {
      return customSortedOrder.indexOf(a) - customSortedOrder.indexOf(b);
    };
    return availableBlocks
      .reduce((arr: string[], block) => {
        if (!block.tags) {
          return arr;
        }

        block.tags.forEach(tag => !arr.includes(tag) && arr.push(tag));

        return arr;
      }, [])
      .filter(tag => tag !== 'v5')
      .sort(customSort);
  }, [availableBlocks]);

  function registerBlocks(blockDefinitions: BlockType[]) {
    const newBlocks: { [key: string]: BlockRenderInterface } = {};

    blockDefinitions.forEach(
      blockDefinition =>
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'FunctionComponent<{}>' is not assignable to ... Remove this comment to see the full error message
        (newBlocks[getBlockKey(blockDefinition)] = registerBlockRenderer(
          blockDefinition
        ))
    );

    setBlocks(prevState => ({
      ...prevState,
      ...newBlocks,
    }));
  }

  function registerBlock(blockDefinition: BlockType): BlockRenderInterface {
    const key = getBlockKey(blockDefinition);
    const Block = registerBlockRenderer(blockDefinition);

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(prevState: { [key: string]: Blo... Remove this comment to see the full error message
    setBlocks(prevState => ({
      ...prevState,
      [key]: Block,
    }));

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'FunctionComponent<{}>' is not assignable to ... Remove this comment to see the full error message
    return Block;
  }

  function findBlock(name: string) {
    return blockArray.find(block => block.blockName === name);
  }

  function constructBaseBlock() {
    // todo make this search by something more intelligent than name?
    const block = availableBlocks.find(block =>
      block.blockName.toLowerCase().includes('column container')
    );

    return createBlockInstance(block);
  }

  useEffect(() => {
    registerBlocks(blockDefinitions);
  }, [JSON.stringify(blockDefinitions)]);

  return (
    <RendererContext.Provider
      value={{
        primitives,
        blocks,
        blockArray,
        availableBlocks,
        tags,
        linkHandler,
        platform,
        registerBlock,
        registerBlocks,
        constructBaseBlock,
        findBlock,
      }}
    >
      {children}
    </RendererContext.Provider>
  );
}

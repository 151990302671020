import React from 'react';
import styles from './Comments.scss';

type Props = {
  serviceRequestId: string;
  testId?: string;
};

export function CommentsTab({ serviceRequestId }: Props) {
  return <div className={styles.commentsTab}>{serviceRequestId}</div>;
}

import { ApolloError, useLazyQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import existingUserLogins from 'lane-shared/graphql/user/existingUserLogins';
import {
  UserLoginProviderEnum,
  UserLoginTypeEnum,
} from 'lane-shared/types/UserLogin';

export type UserLoginMethod = {
  _id: LaneType.UUID;
  type: UserLoginTypeEnum;
  provider: UserLoginProviderEnum | null;
};

type QueryData = {
  loginEmailExists: boolean;
  oAuthLogins: UserLoginMethod[];
};

type QueryVariables = {
  email: string;
};

type Props = {
  onError: (err: ApolloError) => void;
  onSuccess: (_: {
    isLoginEmailExist: boolean;
    oAuthLoginMethods: UserLoginMethod[];
  }) => void;
};

export function useExistingOAuthUserLogins({ onError, onSuccess }: Props) {
  const [fetchQuery, { loading }] = useLazyQuery<QueryData, QueryVariables>(
    existingUserLogins,
    {
      onError,
      fetchPolicy: 'cache-and-network',
      onCompleted({ loginEmailExists, oAuthLogins }) {
        onSuccess({
          isLoginEmailExist: loginEmailExists,
          oAuthLoginMethods: oAuthLogins,
        });
      },
    }
  );

  function handleUserExists(email: string) {
    fetchQuery({ variables: { email } });
  }

  return {
    fetchUserLoginMethods: handleUserExists,
    isLoading: loading,
  };
}

import React, { useMemo } from 'react';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

type Props = WebBlockProps & { style?: { gradient: string } };

export default function ViewBlock({
  style,
  className,
  children,
  theme,
  isTop,
  showBackground,
  ...otherProps
}: Props) {
  const props = useEditModeProps(otherProps);

  const viewStyle = useMemo(() => {
    const ret: React.CSSProperties = {
      ...style,
    };

    ret.color = theme.palette!.text;
    ret.backgroundColor = showBackground
      ? theme.palette!.background
      : 'transparent';

    // special style
    if (style?.gradient) {
      ret.backgroundImage = style.backgroundImage
        ? `${style.gradient}, ${style.backgroundImage}`
        : style.gradient;
      ret.backgroundColor = 'transparent';
    }

    return ret;
  }, [style, isTop, theme]);

  return (
    <div className={className} style={viewStyle} {...props}>
      {children}
    </div>
  );
}

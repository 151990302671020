import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useChannelAdminContext } from 'hooks';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { defaultChannelPages } from 'lane-shared/helpers/constants';

import styles from './TabGrid.scss';

const { profileTab } = defaultChannelPages;

type Props = {
  channelPages: any;
};

/**
 *  Display the tabs in a grid view.
 */
function TabGrid({ channelPages }: Props) {
  const { channel } = useChannelAdminContext();

  return (
    <div className={styles.container}>
      <ul className={styles.tabContainer}>
        {channelPages?.map((channelPage: any) => (
          <li
            key={channelPage._id}
            className={cx({
              [styles.tabItemDisabled]: channelPage._id === profileTab._id,
            })}
          >
            <Link
              to={routes.channelAdminTabsEdit
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                .replace(':id', channel?.slug)
                .replace(':tabId', channelPage._id)}
              className={styles.tabItem}
            >
              <Icon name={channelPage.icon} className={styles.tabIcon} />
              <span>{channelPage.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TabGrid;

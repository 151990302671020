import React, { useState, useMemo, ReactNode } from 'react';

import { UserBillingAndPaymentContext } from './userBillingAndPaymentContext';

type Props = {
  children: ReactNode;
};

export const UserBillingAndPaymentContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [
    isInvoiceAutopayEnabled,
    setIsInvoiceAutopayEnabled,
  ] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      isInvoiceAutopayEnabled,
      updateInvoiceAutopayEnabled: setIsInvoiceAutopayEnabled,
    }),
    [isInvoiceAutopayEnabled]
  );

  return (
    <UserBillingAndPaymentContext.Provider value={contextValue}>
      {children}
    </UserBillingAndPaymentContext.Provider>
  );
};

import React from 'react';

import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import { useUserDirectoryQuery } from 'lane-shared/hooks';

import Input from 'components/form/Input';
import ControlMenu from 'components/general/ControlMenu';
import Loading from 'components/general/Loading';
import UserListView from 'components/lane/UserListView';

import styles from './styles.scss';

const perPage = 25;

export default function ChannelMembers({ channel }: any) {
  const { items, search, setSearch } = useUserDirectoryQuery({
    channelId: channel?._id,
    perPage,
  });

  if (!channel) {
    return <Loading />;
  }

  function renderItem(item: any) {
    return <UserListView user={item} className={styles.user} />;
  }

  return (
    <div className={styles.ChannelMembers}>
      <ControlMenu>
        <h1>Members</h1>
        <Input
          value={search}
          icon="search"
          className={styles.directoryInput}
          onChange={val => setSearch(val)}
          showClear
        />
      </ControlMenu>
      <ul>
        {items.map(user => (
          <Link
            to={routes.user.replace(':id', (user as any)._id)}
            key={(user as any)._id}
          >
            {renderItem(user)}
          </Link>
        ))}
      </ul>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';

import { TextArea, Input, RadioGroup, Radio, Checkbox } from 'components';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  Currency,
  RecurrenceInterval,
  ExternalPayerType,
  currencyToPaymentCurrency,
  AddProductToChargeDetails,
  InvoiceeInfo,
  TaxPolicy,
} from 'lane-shared/domains/billingPayments/types';

import { AdminPage, PageHeader } from 'lane-web/src/components/layout';

import { AddProductToCharge } from '../charges-invoices/add-product-to-charge';
import { AddItemsToCharge } from '../charges-invoices/add-items-to-charge';
import { createRecurringChargeMutation } from '../charges-invoices/helpers/createRecurringChargeMutation';
import {
  validateRecurrenceEndDate,
  getMinimumEndDate,
} from '../charges-invoices/helpers/validateRecurrenceEndDate';

import styles from './styles.scss';
import { Button, DatePickerButton, M, L } from 'design-system-web';
import { InvoiceeSearchBar } from '../charges-invoices/InvoiceeSearchBar';
import { InvoiceType } from 'graphql-query-contracts';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { debounce } from 'lodash';

const FUNCTION_THROTTLE = 1000;

interface MembershipDetails {
  name: string;
  description: string;
  amount: string;
  currency: Currency;
  frequency: RecurrenceInterval;
  startDate: Date;
  endDateOption: string;
  endDate: Date | undefined;
  paymentDue: string;
  paymentDueDays: number;
  totalCounts: number;
  invoicee: InvoiceeInfo;
  anyoneCanCancel: boolean;
  invoiceType: InvoiceType;
}

const FREQUENCY_OPTIONS = [
  {
    label: 'abp.subscription.weekly',
    id: RecurrenceInterval.RECURRENCE_INTERVAL_WEEKLY,
  },
  {
    label: 'abp.subscription.monthly',
    id: RecurrenceInterval.RECURRENCE_INTERVAL_MONTHLY,
  },
  {
    label: 'abp.subscription.yearly',
    id: RecurrenceInterval.RECURRENCE_INTERVAL_YEARLY,
  },
];

export function CreateMembershipForm() {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const { user } = useContext(UserDataContext);
  const { id }: { id: string } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const channelId = channel?._id;

  const isCreditBundlesInChargesEnabled = useFlag(
    FeatureFlag.CreditBundlesInCharges,
    false
  );

  const {
    currency,
    invoiceDueInDays,
    isInvoicingDisabled,
    taxDetails,
  } = useBillingPaymentSettingsForChannel({
    channelId,
  });
  const [listOfProducts, setListOfProducts] = useState<
    AddProductToChargeDetails[]
  >([]);

  const [
    newMembershipDetails,
    setNewMembershipDetails,
  ] = useState<MembershipDetails>({
    name: '',
    description: '',
    amount: '',
    currency: currency || Currency.CURRENCY_USD,
    frequency: RecurrenceInterval.RECURRENCE_INTERVAL_MONTHLY,
    startDate: new Date(),
    paymentDue: 'days',
    paymentDueDays: invoiceDueInDays || 30,
    endDateOption: 'never',
    endDate: undefined,
    totalCounts: 0,
    invoicee: {
      name: '',
      _id: '',
      type: ExternalPayerType.EXTERNAL_PAYER_TYPE_UNKNOWN,
    },
    anyoneCanCancel: false,
    invoiceType: InvoiceType.InvoiceTypeOneoff,
  });

  useEffect(() => {
    setNewMembershipDetails(prev => ({
      ...prev,
      currency,
      paymentDueDays: invoiceDueInDays || 30,
    }));
  }, [currency, invoiceDueInDays]);

  const isFormDisabled =
    isLoading ||
    newMembershipDetails.name === '' ||
    newMembershipDetails.invoicee.name === '' ||
    listOfProducts.length < 1 ||
    !validateRecurrenceEndDate(newMembershipDetails);

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setNewMembershipDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const handleUpdateInvoicee = (invoicee: InvoiceeInfo) => {
    setNewMembershipDetails(prev => ({
      ...prev,
      invoicee,
    }));

    // Reset line items on changing invoicee
    setListOfProducts([]);
  };

  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;

  const saveAndCancelLink = routes.channelAdminSubscriptions.replace(':id', id);

  const breadcrumbs = [
    {
      label: t('abp.charges.breadcrumbs.subscriptions'),
      url: saveAndCancelLink,
    },
    { label: t('abp.charges.breadcrumbs.createSubscription') },
  ];

  const handleSaveChargeFunction = async () => {
    try {
      const totalAmount = listOfProducts.reduce((acc, product) => {
        return acc + Number(product.total);
      }, 0);
      await createRecurringChargeMutation(
        {
          ...newMembershipDetails,
          channelId: channel?._id || '',
          chargeCreatorUserId: user?._id || '',
          amount: totalAmount.toString(),
          invoiceType: isInvoicingDisabled
            ? InvoiceType.InvoiceTypeNoInvoice
            : newMembershipDetails.invoiceType,
        },
        listOfProducts
      );
      history.push(saveAndCancelLink, {
        tab: 'recurringCharges',
      });
      window.Toast.show(t('abp.subscription.createChargeSuccessfull'));
    } catch (err) {
      console.error(`BPError - createRecurringChargeForm error: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCharge = debounce(() => {
    setIsLoading(true);
    handleSaveChargeFunction();
  }, FUNCTION_THROTTLE);

  const handleCancel = () => {
    history.push(saveAndCancelLink, {
      tab: 'recurringCharges',
    });
  };

  if (!channel) return null;

  return (
    <AdminPage>
      <PageHeader
        externalPadding={[0, 0]}
        header={t('abp.charges.createSubscription')}
        headerLevel="h3"
        breadcrumbs={breadcrumbs}
        actionButtons={[
          {
            label: t('abp.productsServices.createCategory.cancel'),
            type: 'secondary',
            onClick: handleCancel,
            testId: 'CancelFormButton',
          },
          {
            label: t('abp.productsServices.createCategory.save'),
            type: 'primary',
            disabled: isFormDisabled,
            onClick: handleSaveCharge,
            testId: 'SaveMembershipButton',
          },
        ]}
      />
      <div className={styles.createMembershipBody}>
        <div className={styles.sectionContainer}>
          <L mb={5}>{t('abp.subscriptions.form.customerSection.title')}</L>
          <div className={styles.formInputFields}>
            <InvoiceeSearchBar
              handleUpdateInvoicee={handleUpdateInvoicee}
              label={t(
                'abp.subscriptions.form.customerSection.customerNameLabel'
              )}
              placeholder=""
            />
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <L mb={5}>
            {t('abp.subscriptions.form.subscriptionDetailsSection.title')}
          </L>
          <div className={styles.formInputFields}>
            <Input
              type="text"
              label={t(
                'abp.subscriptions.form.subscriptionDetailsSection.subscriptionNameLabel'
              )}
              fixedLabel
              onChange={(value: string) => {
                handleFieldUpdate(value, 'name');
              }}
              fieldName="name"
              className={styles.fieldset}
              value={newMembershipDetails.name}
              isRequired
              testId="MembershipNameInput"
            />
            <span className={styles.descriptionField}>
              <label className={styles.InputLabel}>
                {t(
                  'abp.subscriptions.form.subscriptionDetailsSection.subscriptionDescriptionLabel'
                )}
              </label>
              <TextArea
                onChange={(value: string) => {
                  handleFieldUpdate(value, 'description');
                }}
                value={newMembershipDetails.description}
                maxLength={200}
                showLengthIndicator
                minRows={4}
                testId="MembershipDescriptionInput"
              />
            </span>
          </div>
          <L className={styles.recurrenceRule}>
            {t('abp.subscription.recurrenceRule')}
          </L>
          <label className={styles.InputLabel}>
            {' '}
            {t('abp.subscription.frequency')}
          </label>
          <span className={styles.frequencyRadioGroup}>
            <RadioGroup
              key="type"
              name="frequency"
              selected={newMembershipDetails.frequency}
              className={styles.radio}
              schema={{
                id: 'id',
                text: 'label',
              }}
              items={FREQUENCY_OPTIONS}
              onChange={(value: string) => {
                handleFieldUpdate(value, 'frequency');
              }}
              showBorder
            />
          </span>
          <DatePickerButton
            onChange={(value: Date) => {
              handleFieldUpdate(value, 'startDate');
            }}
            dateInputLabel={t('abp.subscription.startDate')}
            minDate={new Date()}
            buttonClassName={styles.datePickerButton}
            fixedLabel
            value={newMembershipDetails.startDate}
            testId="StartDateInput"
          />
          <label className={styles.Label}> {t('abp.subscription.ends')}</label>
          <span className={styles.radioGroup}>
            <Radio
              key="endDate-never"
              testId="RadioButtonNever"
              selected={newMembershipDetails.endDateOption}
              onChange={(value: string) => {
                handleFieldUpdate(value, 'endDateOption');
                handleFieldUpdate(undefined, 'endDate');
                handleFieldUpdate(0, 'totalCounts');
              }}
              name="endDate"
              value="never"
              text={t('abp.subscription.endDate.never')}
              doTranslate={false}
            />
            <span className={styles.endDateOption}>
              <Radio
                key="endDate-on"
                testId="RadioButtonEndDateOn"
                selected={newMembershipDetails.endDateOption}
                onChange={(value: string) => {
                  handleFieldUpdate(value, 'endDateOption');
                  handleFieldUpdate(0, 'totalCounts');
                }}
                name="endDate"
                value="on"
                text={t('abp.subscription.endDate.on')}
                doTranslate={false}
              />
              <DatePickerButton
                testId="EndDatePicker"
                onChange={(value: Date) => {
                  handleFieldUpdate(value, 'endDate');
                  handleFieldUpdate(0, 'totalCounts');
                }}
                className={styles.endDateOptionDatePicker}
                buttonClassName={styles.endDateOptionDatePickerButton}
                disabled={newMembershipDetails.endDateOption !== 'on'}
                minDate={getMinimumEndDate(newMembershipDetails)}
                hideLabel
                fixedLabel
              />
            </span>
            <span className={styles.endDateOption}>
              <Radio
                key="endDate-after"
                testId="RadioButtonEndDateAfter"
                selected={newMembershipDetails.endDateOption}
                onChange={(value: string) => {
                  handleFieldUpdate(value, 'endDateOption');
                }}
                name="endDate"
                value="after"
                text={t('abp.subscription.endDate.after')}
                doTranslate={false}
              />
              <Input
                type="number"
                testId="occurrences"
                onChange={(value: string) => {
                  handleFieldUpdate(parseInt(value, 10), 'totalCounts');
                  handleFieldUpdate(null, 'endDate');
                }}
                fieldName="totalCounts"
                className={styles.endDateOptionInput}
                disabled={newMembershipDetails.endDateOption !== 'after'}
                value={newMembershipDetails.totalCounts}
                min={1}
              />
              <M>{t('abp.subscription.endDate.occurences')}</M>
            </span>
          </span>
          <span className={styles.radioGroup}>
            <label className={styles.Label}>
              {t('abp.subscription.paymentDue')}
            </label>
            <span className={styles.endDateOption}>
              <Radio
                key="paymentDue-days"
                selected={newMembershipDetails.paymentDue}
                onChange={(value: string) => {
                  handleFieldUpdate(value, 'paymentDue');
                }}
                name="paymentDue"
                value="days"
                doTranslate={false}
              />
              <Input
                type="number"
                onChange={(value: string) => {
                  handleFieldUpdate(parseInt(value, 10), 'paymentDueDays');
                }}
                disabled={newMembershipDetails.paymentDue !== 'days'}
                fieldName="paymentDueDays"
                className={styles.paymentDaysInput}
                value={newMembershipDetails.paymentDueDays}
                min={1}
              />
              <M>{t('abp.subscription.paymentDue.days')}</M>
            </span>
            <Radio
              key="paymentDue-immediately"
              selected={newMembershipDetails.paymentDue}
              onChange={(value: string) => {
                handleFieldUpdate(value, 'paymentDue');
                handleFieldUpdate(0, 'paymentDueDays');
              }}
              name="paymentDue"
              value="immediately"
              text={t('abp.subscription.paymentDue.immediately')}
              doTranslate={false}
            />
            <Checkbox
              className={styles.selfCancelCheckbox}
              value={newMembershipDetails.anyoneCanCancel}
              selected={newMembershipDetails.anyoneCanCancel}
              name="anyoneCanCancel"
              onChange={(value, name) => {
                handleFieldUpdate(!value, name!);
              }}
              text={t(
                'abp.subscriptions.form.subscriptionDetailsSection.selfCancelSubscription'
              )}
              testId="AnyoneCanCancelMembershipCheckbox"
            />
          </span>
        </div>

        {isCreditBundlesInChargesEnabled ? (
          <AddItemsToCharge
            listOfProducts={listOfProducts}
            setListOfProducts={setListOfProducts}
            currency={currencyToPaymentCurrency(currency)}
            inclusivePricing={inclusivePricing}
            payer={{
              id: newMembershipDetails.invoicee._id,
              type: newMembershipDetails.invoicee.type,
            }}
          />
        ) : (
          <div className={styles.sectionContainer}>
            <AddProductToCharge
              listOfProducts={listOfProducts}
              setListOfProducts={setListOfProducts}
              currency={currencyToPaymentCurrency(currency)}
              className={styles.addProductsToMembership}
              showTotals={false}
              payer={{
                id: newMembershipDetails.invoicee._id,
                type: newMembershipDetails.invoicee.type,
              }}
            />
          </div>
        )}

        <div className={styles.formButtons}>
          <Button
            className={styles.button}
            disabled={isFormDisabled}
            onClick={handleSaveCharge}
            size="large"
            testId="SaveMembershipButton"
          >
            {t('abp.productsServices.createCategory.save')}
          </Button>
          <Button
            className={styles.button}
            variant="secondary"
            onClick={handleCancel}
            size="large"
            testId="CancelFormButton"
          >
            {t('abp.productsServices.createCategory.cancel')}
          </Button>
        </div>
      </div>
    </AdminPage>
  );
}

import React from 'react';

import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import Loading from '../../../../components/general/Loading';
import Invoice from '../../../../components/integrations/Essensys/Invoice';

function ContentInvoice({ content, location }: any) {
  if (!content) {
    return <Loading />;
  }

  const integrationProvider = content?.integration?.integration?.name;
  const locationArray = location.pathname.split('/');
  const invoiceId = locationArray[locationArray.length - 1];

  switch (integrationProvider) {
    case IntegrationProviderEnum.Essensys:
      return <Invoice invoiceId={invoiceId} />;
    default:
      return null;
  }
}

export default ContentInvoice;

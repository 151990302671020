import { useEffect } from 'react';

import scrollTo from '../helpers/scrollTo';

const SCROLL_TO_TOP_WAIT = 50;

export default function useScrollToTop(
  watch: any[] = [],
  wait: number = SCROLL_TO_TOP_WAIT
) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      scrollTo(window.document.scrollingElement, { top: 0, behavior: 'auto' });
    }, wait);

    return () => {
      clearTimeout(timeout);
    };
  }, watch);
}

import React, { useEffect, useState } from 'react';

import { SPECIAL_GROUP_ROLES } from 'lane-shared/helpers/constants/channel';
import {
  mapDisabledPermissions,
} from 'lane-shared/helpers/getOrganizedPermissions';
import {
  useChannelServiceRequestFeaturesContext,
} from 'lane-shared/hooks';
import { useFilteredPermissions } from '../hooks/useFilteredPermissions';
import ErrorMessage from 'components/general/ErrorMessage';
import Loading from 'components/general/Loading';
import ValidationMessage from 'components/general/ValidationMessage';
import {
  ConfigureMaxUsers,
  HeaderCreateTeam,
  ListOfPermissions,
  TogglePublicTeam,
} from 'components/lane/Team/';
import AdminPage from 'components/layout/AdminPage';

import styles from './styles.scss';

function TeamCreate({ channel }: { channel?: any }) {
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();
  const [groupRole, setGroupRole] = useState<any>({
    channel: {
      _id: channel?._id,
    },
    isPublic: false,
    name: '',
    permissions: [],
    requirements: [],
    maxUsers: 0,
  });

  const filteredPermissions = useFilteredPermissions();

  if (!serviceRequestFeatures.createServiceRequest) {
    mapDisabledPermissions(filteredPermissions, 'work orders');
  }

  // dirty hack, initially, channel param is undefined and then re-render happens when it is loaded.
  useEffect(() => {
    setGroupRole({
      channel: {
        _id: channel?._id,
      },
      isPublic: false,
      name: '',
      permissions: [],
      requirements: [],
      maxUsers: 0,
    });
  }, [channel?._id]);

  const [validation, setValidation] = useState(null);
  const [error, setError] = useState<Error | null>(null);

  function updateGroupRole(props: any) {
    setGroupRole({
      ...groupRole,
      ...props,
    });
  }

  if (!groupRole) {
    return <Loading />;
  }

  const isAdminOrWorplaceMember = [
    SPECIAL_GROUP_ROLES.WORKPLACE_MEMBER,
    SPECIAL_GROUP_ROLES.ADMIN,
  ].includes(groupRole.name);

  const errors =
    (validation as any)?.inner?.map((err: any) => err.message) || [];

  return (
    <AdminPage className={styles.TeamCreate}>
      <HeaderCreateTeam
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
        channelSlug={channel?.slug}
        setValidation={setValidation}
        setError={setError}
        isAdminOrWorplaceMember={isAdminOrWorplaceMember}
      />

      <ErrorMessage error={error} />
      <ValidationMessage errors={errors} />

      <TogglePublicTeam
        isPublic={groupRole.isPublic}
        updateGroupRole={updateGroupRole}
      />

      <ConfigureMaxUsers
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
        validation={validation}
      />
      <ListOfPermissions
        permissions={filteredPermissions}
        groupRole={groupRole}
        updateGroupRole={updateGroupRole}
        isAdminOrWorplaceMember={isAdminOrWorplaceMember}
      />
    </AdminPage>
  );
}

export default TeamCreate;

import React from 'react';
import { Modal } from 'components/lds';
import styles from './PopupModal.scss';
import { M, Button } from 'design-system-web';

type Props = {
  isModalOpen: boolean;
  modalTitle: string;
  setModalClose: (value: boolean) => void;
  t: (value: string) => string;
  children: React.ReactNode;
  loading: boolean;
  onConfirm: () => void;
};

export const PopupModal = ({
  isModalOpen,
  setModalClose,
  modalTitle,
  t,
  children,
  loading,
  onConfirm,
}: Props) => {
  return (
    <Modal
      testId="Modal-UnsubscribeFromAllCannels"
      className={styles.PopupModal}
      isOpen={isModalOpen}
      onClose={() => setModalClose(true)}
      isCloseButtonHidden
      includeHeader={false}
    >
      <M className={styles.title}>{modalTitle}</M>

      <div className={styles.content}>{children}</div>

      <M className={styles.confirmMessage}>
        {t(
          'web.pages.portal.settings.userChannelsettingsV2.popupModal.confirmMessage'
        )}
      </M>

      <div className={styles.buttons}>
        <Button
          variant="secondary"
          size="large"
          className={styles.confirmButton}
          onClick={onConfirm}
          testId="Button-Unsubscribe"
        >
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.popupModal.confirmBtn'
          )}
        </Button>
        <Button
          variant="secondary"
          size="large"
          loading={loading}
          className={styles.cancelButton}
          onClick={() => setModalClose(false)}
        >
          {t(
            'web.pages.portal.settings.userChannelsettingsV2.popupModal.cancelBtn'
          )}
        </Button>
      </div>
    </Modal>
  );
};

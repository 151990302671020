import React from 'react';

import { Link } from 'react-router-dom';

import { LaneType } from 'common-types';
import { routes } from 'lane-shared/config';
import useContentForAdminCardQuery from 'lane-shared/hooks/useContentForAdminCardQuery';

import Loading from '../general/Loading';
import ContentCard from './ContentCard';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  contentId: LaneType.UUID;
};

export default function AdminContentCardLoader({
  className,
  style,
  contentId,
}: Props) {
  const { content, loading } = useContentForAdminCardQuery({ contentId });

  if (!content && loading) {
    return <Loading className={className} style={style} />;
  }

  return (
    <Link
      to={routes.channelAdminContent
        .replace(':id', (content as any)?.channel?.slug)
        .replace(':contentId', contentId)}
    >
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ContentForAdminCardType | null' is not assig... Remove this comment to see the full error message */}
      <ContentCard content={content} className={className} style={style} />
    </Link>
  );
}

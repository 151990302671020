import React from 'react';

import { useTranslation } from 'react-i18next';

import { colors } from 'lane-shared/config/colors';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useInvites } from 'lane-shared/hooks';

import { ErrorMessage } from 'components/general';
import { Button } from 'design-system-web';
import { Modal } from 'components/lds';
import { H4, M } from 'components/typography';

import channelImageDefault from 'static/img/channel-image-default.svg';

import styles from './InviteModal.scss';

/**
 *
 * @deprecated Do not add new features to this component as it is being replaced by InviteModal
 * https://viewthespace.atlassian.net/browse/TM-14310
 */
export default function LegacyInviteModal() {
  const { t } = useTranslation();
  const {
    inviteId,
    company,
    building,
    updateSignUp,
    clearInvite,
  } = useSignUpContext();
  const { claiming, claimError, claimInvite } = useInvites({ inviteId });
  const channel = building || company;

  async function acceptInvite() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    const data = await claimInvite(inviteId, channel._id);

    if (data) {
      updateSignUp({ inviteId: null });
    }
  }

  const JoinButton = (
    <Button
      className={styles.joinButton}
      variant="primary"
      type="button"
      loading={claiming}
      onClick={acceptInvite}
    >
      {t('Join community')}
    </Button>
  );

  return (
    <Modal
      className={styles.inviteModal}
      onClose={clearInvite}
      isOpen={Boolean(inviteId)}
      actions={JoinButton}
    >
      <div className={styles.inviteInfo}>
        <div
          className={styles.channelImage}
          style={{
            backgroundImage: `url(${imageUrl(
              channel?.profile?.image || channelImageDefault
            )})`,
            backgroundSize: channel?.profile?.image ? 'cover' : '45em',
          }}
        >
          <div
            className={styles.channelIcon}
            style={{
              backgroundImage: `url(${imageUrl(channel?.profile?.logo)})`,
              backgroundColor: channel?.profile?.logo
                ? colors.white
                : colors.laneGreen,
            }}
          />
        </div>
        <div className={styles.channelInfo}>
          <H4 v2>{channel?.name}</H4>
          <M className={styles.channelAddress}>{channel?.address?.street1}</M>
          <ErrorMessage error={claimError} />
        </div>
      </div>
    </Modal>
  );
}

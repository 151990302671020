import React, { useContext } from 'react';

import { Switch, useHistory, Route, match, Redirect } from 'react-router-dom';
import urlJoin from 'url-join';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import {
  PERMISSION_ADMIN,
  PERMISSION_MEDIA_CREATE,
  PERMISSION_MEDIA_UPDATE,
  PERMISSION_DOCUMENTS_CREATE,
  PERMISSION_DOCUMENTS_UPDATE,
} from 'lane-shared/helpers/constants/permissions';
import useFlag from 'lane-shared/hooks/useFlag';
import { useTranslation } from 'react-i18next';
import TabStrip, { TabItem } from 'components/general/TabStrip';
import AdminPage from 'components/layout/AdminPage';

import DataLibraryIndex from './data/DataLibraryIndex';
import { DocumentLibraryIndex } from './documents/DocumentLibraryIndex';
import MediaLibraryIndex from './media/MediaLibraryIndex';
import TemplateLibraryIndex from './templates/TemplateLibraryIndex';
import WebhookLibraryIndex from './webhooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { Tags } from './tags';
import MetatagLibrary from '../meta-tag';
import { H3 } from 'components/typography';

import styles from './styles.scss';

const legacyTabs: { [key: string]: TabItem } = {
  data: {
    value: 'data',
    label: 'Data',
  },
  media: {
    value: 'media',
    label: 'Media',
  },
  documents: {
    value: 'documents',
    label: 'Documents',
  },
  templates: {
    value: 'templates',
    label: 'Templates',
  },
  webhooks: {
    value: 'webhooks',
    label: 'Webhooks',
  },
} as const;

const tabs: { [key: string]: TabItem } = {
  media: {
    value: 'media',
    label: 'Media',
  },
  documents: {
    value: 'documents',
    label: 'Documents',
  },
  templates: {
    value: 'templates',
    label: 'Templates',
  },
  tags: {
    value: 'tags',
    label: 'Tags',
  },
  filters: {
    value: 'filters',
    label: 'Filters',
  },
  data: {
    value: 'data',
    label: 'Data',
  },
  webhooks: {
    value: 'webhooks',
    label: 'Webhooks',
  },
} as const;

type Props = {
  match: match<{ libraryType: string }>;
  channel: any;
};

export default function Library({ match, channel }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { hasAnyPermission } = useContext(UserDataContext);
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const tabOptions = areContentTagsEnabled
    ? Object.values(tabs)
    : Object.values(legacyTabs);

  const enableBulkEditCreate = useFlag('enable-bulk-edit-create', true);
  const baseUrl = routes.channelAdminLibrary.replace(':id', channel?.slug);
  function handleTabChange(tab: TabItem) {
    history.push(urlJoin(baseUrl, tab.value));
  }

  const availableTabs = tabOptions.filter(tab => {
    if (tab.value === tabs.media!.value) {
      return hasAnyPermission(
        [PERMISSION_ADMIN, PERMISSION_MEDIA_UPDATE, PERMISSION_MEDIA_CREATE],
        channel?._id
      );
    }

    if (tab.value === tabs.data!.value) {
      return enableBulkEditCreate;
    }

    if (tab.value === tabs.documents!.value) {
      return hasAnyPermission(
        [
          PERMISSION_ADMIN,
          PERMISSION_DOCUMENTS_UPDATE,
          PERMISSION_DOCUMENTS_CREATE,
        ],
        channel?._id
      );
    }

    return true;
  });

  let selectedTab = { value: match.params.libraryType || tabs.media.value };

  if (!availableTabs.find(tab => tab.value === selectedTab.value)) {
    selectedTab = availableTabs[0];
  }

  return (
    <AdminPage>
      <H3>{t('web.admin.library.title')}</H3>
      <Route path={baseUrl}>
        <div className={styles.tabs}>
          <TabStrip
            tabs={availableTabs}
            selected={selectedTab}
            onSelectTab={handleTabChange}
            className={styles.tabStrip}
            fullWidth
          />
        </div>
      </Route>
      <Switch>
        <Route
          path={routes.channelAdminLibraryData}
          component={DataLibraryIndex}
        />
        <Route
          path={routes.channelAdminLibraryTemplates}
          component={TemplateLibraryIndex}
        />
        {areContentTagsEnabled && (
          <Route path={routes.channelAdminLibraryTags} component={Tags} />
        )}
        {areContentTagsEnabled && (
          <Route
            path={routes.channelAdminLibraryFilters}
            render={() => <MetatagLibrary channel={channel} />}
          />
        )}
        <Route
          path={routes.channelAdminLibraryMedia}
          render={() => <MediaLibraryIndex channel={channel} />}
        />
        <Route
          path={routes.channelAdminLibraryWebhooks}
          component={WebhookLibraryIndex}
        />
        <Route
          path={routes.channelAdminLibraryDocuments}
          render={() => <DocumentLibraryIndex channel={channel} />}
        />
        <Redirect to={`library/${availableTabs[0].value}`} />
      </Switch>
    </AdminPage>
  );
}

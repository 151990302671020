import React, { useEffect, useState } from 'react';

import { Icon } from 'design-system-web';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

import TextArea from 'components/form/TextArea';
import { H4 } from 'components/typography';

import styles from './AdminNotes.scss';

const TRANSLATION_KEYS = {
  adminNotes: 'shared.common.adminNotes.title',
  edit: 'shared.common.adminNotes.edit',
  save: 'shared.common.adminNotes.save',
  cancel: 'shared.common.adminNotes.cancel',
};

type props = {
  note?: string;
  onSubmit: (note: string) => void;
  onChange?: (note: string) => void;
  onCancel?: () => void;
};

export function AdminNotes({ note = '', onSubmit, onCancel, onChange }: props) {
  const { t } = useTranslation();

  const [showTextArea, setShowTextArea] = useState(false);
  const [noteText, setNoteText] = useState(note);

  useEffect(() => {
    setNoteText(note);
  }, [note]);

  const handleSaveClick = () => {
    onSubmit(noteText);
    setShowTextArea(false);
  };

  const handleOnChange = (newNote: string) => {
    setNoteText(newNote);
    if (onChange) {
      onChange(newNote);
    }
  };

  const handleCancelClick = () => {
    setNoteText(note);
    setShowTextArea(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className={styles.adminNotesContainer}>
      <div className={styles.adminNotesTitleContainer}>
        <H4>{t(TRANSLATION_KEYS.adminNotes)}</H4>
        {!showTextArea && (
          <Button
            startIcon={<Icon name="pencil" />}
            className={styles.adminNotesEditButton}
            onClick={() => setShowTextArea(true)}
          >
            {t(TRANSLATION_KEYS.edit)}
          </Button>
        )}
      </div>

      {!showTextArea && note && note.length > 0 && (
        <p className={styles.adminNotesText}>{noteText}</p>
      )}

      {showTextArea && (
        <div>
          <TextArea
            showLengthIndicator
            value={noteText}
            onChange={handleOnChange}
            maxLength={200}
            showClear={false}
            className={styles.adminNotesTextArea}
            data-test="admin-notes-textarea"
          />
          <div className={styles.adminNotesButtonContainer}>
            <Button
              className={styles.adminNotesSaveButton}
              onClick={handleSaveClick}
              variant="contained"
              disabled={noteText === note}
            >
              {t(TRANSLATION_KEYS.save)}
            </Button>
            <Button
              className={styles.adminNotesCancelButton}
              onClick={handleCancelClick}
            >
              {t(TRANSLATION_KEYS.cancel)}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

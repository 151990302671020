import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { Invoice } from 'lane-shared/domains/billingPayments/types';
import { convertToUUID } from 'lane-shared/helpers/convertId';

import { PageHeader } from 'lane-web/src/components/layout';
import { ButtonType } from 'lane-web/src/components/layout/PageHeader';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks/useBillingPaymentSettingsForChannel';
import { isInvoicePayable } from 'lane-shared/helpers/features/isInvoicePayable';

import { ChipStyle } from 'components/ads';

import {
  InvoiceActions,
  useGetActionsForInvoices,
} from '../helpers/useGetActionsForInvoices';

export function InvoiceDetailsPageHeader({
  channelId,
  invoice,
  statusMap,
}: {
  channelId: string;
  invoice: Invoice;
  statusMap: string[];
}) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';
  const buttonType = 'secondary' as ButtonType;

  const displayId = invoice.displayId;
  const invoiceListLink = routes.channelAdminInvoices.replace(
    ':id',
    channel_slug
  );
  const canMarkAsPaid = invoice ? isInvoicePayable(invoice) : false;
  const invoiceActions = useGetActionsForInvoices() as InvoiceActions[];

  const paidButton = invoiceActions.map(
    ({ label: invoiceActionLabel, onSelect: onInvoiceActionSelected }) => {
      const triggerInvoiceAction = () => onInvoiceActionSelected(null, invoice);
      return {
        label: invoiceActionLabel,
        type: buttonType,
        onClick: triggerInvoiceAction,
      };
    }
  );

  const { invoiceDisplayName } = useBillingPaymentSettingsForChannel({
    channelId: convertToUUID(channelId),
  });

  const headerLabel = `${invoiceDisplayName} ${displayId}`;

  return (
    <PageHeader
      header={headerLabel}
      headerLevel="h3"
      status={statusMap[0]}
      statusType={statusMap[1] as ChipStyle}
      actionButtons={canMarkAsPaid ? paidButton : []}
      externalPadding={[0, 0]}
      breadcrumbs={[
        {
          label: t('abp.invoice.details.breadcrumbs.invoiceList'),
          url: invoiceListLink,
        },
        { label: displayId },
      ]}
    />
  );
}

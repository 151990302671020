import React, { useContext, useEffect, useState } from 'react';

import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';
import { routes } from 'lane-shared/config';

import { getSectionsByChannel } from 'lane-shared/graphql/query';
import { simpleDate } from 'lane-shared/helpers/formatters';
import SectionType from 'lane-shared/types/sections/SectionType';
import { Table } from 'design-system-web';
import { H3, S } from 'components/typography';
import { Flex } from 'components';

import ChannelAdminContext from '../ChannelAdminContext';

import styles from './styles.scss';

function ChannelSections() {
  const { t } = useTranslation();
  const { channel } = useContext(ChannelAdminContext);
  const [query, goToUrl] = useQueryString({
    page: 0,
    search: '',
    pageSize: 10,
    sortBy: '_created',
    sortyDirection: 'desc',
  });
  const history = useHistory();

  const [search, setSearch] = useState(query.search as string | undefined);

  const page = parseInt(query.page as string, 10) || 0;
  const pageSize = parseInt(query.pageSize as string, 10) || 10;

  const getQueryVariables = () => ({
    id: channel?._id!,
    sectionNameIncludes: search,
    pagination: {
      start: page * pageSize,
      perPage: pageSize,
    },
    order: query.sortDirection ? query.sortDirection : 'asc',
    orderBy: query.sortBy ? query.sortBy : 'name',
  });

  const [fetchSectionByChannel, { data }] = useLazyQuery(getSectionsByChannel, {
    fetchPolicy: 'cache-and-network',
    variables: getQueryVariables(),
  });

  const formatColumnData = (dataArr: String[]) => {
    const dataString = dataArr.join(', ');
    return dataString.length > 29
      ? `${dataString.slice(0, 26)}...`
      : dataString;
  };

  const doFetch = useDebouncedCallback(() => {
    fetchSectionByChannel({
      variables: getQueryVariables(),
    });
  }, 500).callback;

  useEffect(() => {
    goToUrl({ search });
  }, [search]);

  useEffect(() => {
    doFetch();
  }, [query, channel]);

  const tableColumns = [
    {
      header: t('web.admin.content.sections.table.sectionName'),
      key: 'name',
      renderCell: (name: string, row: SectionType) => (
        <Link to={`sections/${row._id}/view`} className={styles.link}>
          {name}
        </Link>
      ),
    },
    {
      header: t('web.admin.content.sections.table.sectionType'),
      key: 'type',
      renderCell: (type: string) => type,
    },
    {
      header: t('web.admin.content.sections.table.sectionCategories'),
      key: 'query.contentCategories',
      disableSorting: true,
      renderCell: (categories: string[]) =>
        categories ? formatColumnData(categories) : '-',
    },
    {
      header: t('web.admin.content.sections.table.contentTypes'),
      key: 'query.contentTypes',
      disableSorting: true,
      renderCell: (types: string[]) => (types ? formatColumnData(types) : '-'),
    },
    {
      header: t('web.admin.content.sections.table.createdOn'),
      key: '_created',
      renderCell: (created: Date) => simpleDate(created),
    },
    {
      header: t('web.admin.content.sections.table.updated'),
      key: '_updated',
      renderCell: (updated: Date) => simpleDate(updated),
    },
  ];

  const rowActions = [
    {
      label: t('web.admin.content.sections.table.edit'),
      onClick: ({ _id }: SectionType) =>
        history.push(
          routes.channelAdminSectionsEdit
            .replace(':id', channel?._id!)
            .replace(':sectionId', _id)
        ),
    },
  ];

  const tableData = !data?.channelSections?.sections
    ? []
    : data.channelSections.sections.map((section: SectionType) => section);

  const newSectionRoute = routes.channelAdminSectionsNew.replace(
    ':id',
    channel?._id!
  );

  return (
    <div className={styles.ChannelSections}>
      <Flex justify="space-between">
        <Flex direction="column">
          <H3>{t(`web.admin.content.sections.breadcrumbs.index`)}</H3>
          <div className={styles.description}>
            <S>{t('web.admin.content.sections.index.description')}</S>
          </div>
        </Flex>
      </Flex>
      <Table
        hasKeywordFilter
        onKeywordFilterChange={search => setSearch(search)}
        columns={tableColumns}
        data={tableData}
        totalRows={data?.channelSections?.total}
        rowActions={rowActions}
        primaryActionLabel={t('web.admin.content.sections.addSection')}
        onPrimaryAction={() => history.push(newSectionRoute)}
        pagination="server"
        queryStringsEnabled
      />
    </div>
  );
}

export default ChannelSections;

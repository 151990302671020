import { createMuiTheme } from '@material-ui/core/styles';

import { colors } from 'lane-shared/config';

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.darkGreen,
    },
    secondary: {
      main: colors.errorOLD,
    },
    success: {
      main: colors.accent,
    },
  },
  typography: {
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

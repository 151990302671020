import React, { useState, useEffect } from 'react';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ProductCategory } from 'graphql-query-contracts';
import { Item } from 'lane-web/src/components/form/Dropdown/Dropdown';
import { useProductListQuery } from 'lane-shared/domains/billingPayments/hooks';
import { M, P } from 'components/typography';

import { ErrorMessage, MultiselectField, Checkbox } from 'components';
import { Button, Loading } from 'lane-web/src/components/general';
import {
  ProductType,
  ExceptionDetails,
  ExternalPayerType,
  MarkupAmountType,
  TaxAmountType,
  GetProductExceptionResponse,
  convertToGqlProductType,
} from 'lane-shared/domains/billingPayments/types';
import { Icon, Input } from 'design-system-web';
import { MarkupAndTaxOptions } from 'lane-web/src/pages/portal/admin/channel/products-services/products/MarkupAndTaxOptions';
import { useLazyQuery } from '@apollo/client';
import { routes } from 'lane-shared/config';
import { AdminPage, PageHeader } from 'lane-web/src/components/layout';

import styles from './styles.scss';
import { updateProductExceptionMutation } from 'lane-shared/domains/billingPayments/queries/updateProductExceptionMutation';
import { deleteProductExceptionMutation } from 'lane-shared/domains/billingPayments/queries';
import { getProductExceptionQuery } from 'graphql-queries';
import { useUserCompanyInfo } from 'lane-shared/domains/billingPayments/helpers/getInvoiceDetails';
import { ProductCategoryDropdown } from '../../../../../../domains/billingAndPayments/components/ProductCategoryDropdown';

type Props = {
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
};

export function EditExceptionForm({ breadcrumbs }: Props) {
  const history = useHistory();
  const { exceptionId }: { exceptionId: string } = useParams();
  const { channel } = useChannelAdminContext();
  const { t } = useTranslation();
  const channel_slug = channel?.slug || '';
  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    channel_slug
  );

  const defaultBreadcrumbs = [
    {
      label: t('abp.productsServices.exceptions.breadcrumb'),
      url: `${productServicesLink}?tab=exceptions`,
    },
    { label: t('abp.productsServices.editException.breadcrumb') },
  ];
  const [loadException, { data, loading }] = useLazyQuery<{
    getProductException: GetProductExceptionResponse;
  }>(getProductExceptionQuery, {
    fetchPolicy: 'network-only',
  });

  const [error, setError] = useState<Error | undefined>(undefined);
  const [
    updateExceptionDetails,
    setUpdateExceptionDetails,
  ] = useState<ExceptionDetails>({
    productCategoryId: '',
    productType: ProductType.PRODUCT_TYPE_SERVICE_MATERIALS,
    productIds: [],
    applyToAllProducts: false,
  });

  useEffect(() => {
    loadException({
      variables: {
        getProductExceptionRequest: {
          id: exceptionId,
        },
      },
    });
  }, [exceptionId, loadException]);

  useEffect(() => {
    if (data) {
      const exception = data.getProductException;
      const exceptionDetails = {
        invoicee: {
          _id: exception.payer.id,
          type: exception.payer.type,
          name: '',
        },
        productType: exception.productCategory!.productType,
        productCategoryId: exception.productCategory!.productCategoryId,
        rate: exception.amount,
        productIds: [],
        applyToAllProducts: false,
      } as ExceptionDetails;

      if (
        exception.markup &&
        exception.markup.markupAmountType !==
          MarkupAmountType.MarkupAmountTypeUnknown &&
        exception.markup.value >= 0
      ) {
        exceptionDetails.markup = {
          value: String(exception.markup.value),
          type: exception.markup.markupAmountType,
        };
      }
      if (
        exception.tax &&
        exception.tax.taxAmountType !== TaxAmountType.TaxAmountTypeUnknown &&
        exception.tax.value >= 0
      ) {
        exceptionDetails.tax = {
          value: String(exception.tax.value),
          type: exception.tax.taxAmountType,
        };
      }
      if (exception.products && exception.products.length > 0) {
        const productIds = exception.products.map(product => ({
          label: product.name,
          value: product.id,
        }));
        exceptionDetails.productIds = productIds;
      } else {
        exceptionDetails.applyToAllProducts = true;
      }
      setUpdateExceptionDetails(exceptionDetails);
    }
  }, [data]);

  const isCompany =
    updateExceptionDetails.invoicee?.type ===
    ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY;
  const invoiceeInfo = useUserCompanyInfo(
    updateExceptionDetails.invoicee?._id ?? '',
    isCompany
  );
  const isSaveButtonDisabled = (): boolean => {
    const {
      productCategoryId,
      productIds,
      applyToAllProducts,
      rate,
      tax,
      markup,
    } = updateExceptionDetails;
    const noProductsSelected = !applyToAllProducts && productIds.length === 0;
    const noModifierSelected = !rate && !tax && !markup;

    return !productCategoryId || noProductsSelected || noModifierSelected;
  };

  const handleSave = async () => {
    try {
      await updateProductExceptionMutation(
        exceptionId,
        updateExceptionDetails,
        channel?._id || ''
      );
      history.goBack();
      window.Toast.show(t('abp.productsServices.editException.updatedSuccess'));
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    const description = (
      <div className={styles.alertModalDescription}>
        <P className={styles.paragraph}>
          {t(
            'abp.productServices.priceException.deleteException.descriptionOne'
          )}
        </P>
        <P>
          {t(
            'abp.productServices.priceException.deleteException.descriptionTwo',
            {
              type: t(
                `abp.productsServices.productType.plural.${updateExceptionDetails.productType}`
              ),
            }
          )}
        </P>
      </div>
    );

    try {
      await window.Alert.confirm({
        title: t('abp.productServices.priceException.deleteException.title', {
          customer: invoiceeInfo.name,
        }),
        message: description,
        confirmText: t('abp.productsServices.priceException.action.delete'),
        cancelText: t('abp.productsServices.priceException.action.cancel'),
        confirmButtonStyle: {
          backgroundColor: '#C7200A',
          borderColor: '#C7200A',
          color: 'white',
        },
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      if (channel?._id) {
        await deleteProductExceptionMutation(exceptionId, channel._id);

        window.Toast.show(
          t('abp.productsServices.priceException.delete.success')
        );

        history.goBack();
      }
    } catch (err) {
      window.Toast.show(t('abp.productsServices.priceException.delete.error'));
    }
  };

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setUpdateExceptionDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const handleCategoryUpdate = (category: ProductCategory) => {
    setUpdateExceptionDetails({
      ...updateExceptionDetails,
      productCategoryId: category.productCategoryId,
      productIds: [],
    });
  };

  const productsList = useProductListQuery({
    page: 0,
    pageSize: 100,
    channel,
    productCategoryId: updateExceptionDetails.productCategoryId,
  });

  function getProductItems(): Item<string>[] | undefined {
    const listOfProducts = productsList.data?.listProducts.products;

    if (listOfProducts) {
      const dropdownProductItems = listOfProducts.map(product => {
        return {
          label: product.name,
          value: product.id,
        };
      });
      return dropdownProductItems;
    }
    return undefined;
  }

  const onProductSelection = (items: any[]) => {
    handleFieldUpdate(items, 'productIds');
  };

  const productItems = getProductItems();

  if (loading) {
    return <Loading fullscreen />;
  }

  return (
    <AdminPage>
      <PageHeader
        header={t('abp.productsServices.editException.heading', {
          customerName: invoiceeInfo.name,
        })}
        headerLevel="h3"
        breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
        customActions={
          <Button
            onClick={handleDelete}
            startIcon={<Icon name="trash-alt" />}
            testId="deleteExceptionBtn"
          >
            {t('abp.actions.deleteException')}
          </Button>
        }
      />
      <div className={styles.editExceptionForm}>
        <div className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.editException.customer')}
          </label>
          <Input
            readOnly
            onChange={() => {}}
            showClear={false}
            value={invoiceeInfo.name}
            testId="invoiceeNameReadonly"
          />
        </div>
        <M className={styles.sectionLabel}>
          {t('abp.productsServices.editException.applyExceptionTo')}
        </M>
        <fieldset className={styles.fieldset}>
          <label className={styles.label}>
            {t('abp.productsServices.editException.type')}
          </label>
          <M>
            {t(
              `abp.productsServices.productType.${updateExceptionDetails.productType}`
            )}
          </M>
        </fieldset>

        <fieldset className={styles.dropdown}>
          <ProductCategoryDropdown
            label={t(
              'abp.productsServices.editException.category.categoryLabel'
            )}
            fixedLabel
            isRequired
            isFullWidth
            value={updateExceptionDetails.productCategoryId}
            placeholder={t(
              'abp.productsServices.editException.category.placeholder'
            )}
            productType={convertToGqlProductType(
              updateExceptionDetails.productType
            )}
            handleCategorySelection={handleCategoryUpdate}
          />
        </fieldset>

        <Checkbox
          className={styles.checkbox}
          testId="applyToAllProductsOptionCheckbox"
          value={updateExceptionDetails.applyToAllProducts}
          text={t(`abp.productsServices.editException.applyToAllProducts`)}
          name="applyToAllProducts"
          selected={updateExceptionDetails.applyToAllProducts}
          onChange={() =>
            handleFieldUpdate(
              !updateExceptionDetails.applyToAllProducts,
              'applyToAllProducts'
            )
          }
        />

        {!updateExceptionDetails.applyToAllProducts && (
          <fieldset className={styles.dropdown}>
            <MultiselectField
              label={t(
                `abp.productsServices.editException.addProductService.${updateExceptionDetails.productType}`
              )}
              fixedLabel
              isFullWidth
              testId="productDropdown"
              items={productItems}
              onChange={onProductSelection}
              value={updateExceptionDetails.productIds}
              disabled={!updateExceptionDetails.productCategoryId}
              placeholder={t(
                'abp.charges.addProduct.modal.productInputPlaceholder'
              )}
              doTranslation={false}
            />
          </fieldset>
        )}

        <M className={styles.sectionLabel}>
          {t('abp.productsServices.editException.override')}
        </M>

        <MarkupAndTaxOptions
          productDetails={updateExceptionDetails}
          updateDetails={handleFieldUpdate}
          showRate
        />
      </div>
      <div className={styles.formButtons}>
        <Button
          className={styles.button}
          variant="contained"
          disabled={isSaveButtonDisabled()}
          onClick={handleSave}
          testId="saveBtn"
        >
          {t('abp.productsServices.createCategory.save')}
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleCancel}
          testId="cancelBtn"
        >
          {t('abp.productsServices.editException.cancel')}
        </Button>
      </div>
      {error && <ErrorMessage error={error} />}
    </AdminPage>
  );
}

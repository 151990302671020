import { makeFileDownload } from 'helpers';
import Papa from 'papaparse';

export const buildCSVRows = (rows: any[], columns: any[]) => {
  const csvRows = [];

  const header = columns?.map(({ header }: { header: string }) => header);
  csvRows.push(header);

  rows.forEach(row => {
    const values = columns?.map(({ key, renderCell, renderForCSV }) => {
      if (renderForCSV) {
        return renderForCSV(row[key], row);
      }
      if (renderCell) {
        return renderCell(row[key], row);
      }
      return row[key];
    });
    csvRows.push(values);
  });
  return csvRows;
};

export const exportCSV = (
  rows: any[],
  columns: any[],
  filename: string,
  tableKey?: string
) => {
  const reportColumns = getTableVisibleColumns(columns, tableKey);

  const csvRows = buildCSVRows(rows, reportColumns);
  const csv = Papa.unparse(csvRows);

  makeFileDownload({
    name: `${filename}`,
    contents: csv,
    type: 'application/csv',
  });

  return csv;
};

export const getTableVisibleColumns = (columns: any[], tableKey?: string) => {
  let storedValue;
  if (tableKey) {
    storedValue = localStorage.getItem(`${tableKey}.columnVisibility`);
  }

  let reportColumns = [];
  if (storedValue) {
    // coloumn.id =>visible true/false
    const columnVisibility = JSON.parse(storedValue);
    columns.map(column => {
      if (columnVisibility[column.key]) {
        reportColumns.push(column);
      }
    });
  } else {
    reportColumns = columns;
  }

  return reportColumns;
};

import React from 'react';

import cx from 'classnames';

import { PaymentFeatureInteractionData } from 'lane-shared/types/features/PaymentFeatureInteractionData';

import InfoRow from './InfoRow';

import styles from './PaymentFeatureStripeData.scss';

type Props = {
  className?: string;
  style: React.CSSProperties;
  payment: PaymentFeatureInteractionData | null | undefined;
};
export default function PaymentFeaturePlacePayData({
  className,
  style,
  payment,
}: Props) {
  const placePayData = payment?.placePayData;

  if (!placePayData) {
    return null;
  }

  return (
    <div
      className={cx(styles.PaymentFeatureStripeData, className)}
      style={style}
    >
      <InfoRow label="Transaction ID" isId>
        {placePayData.transaction_id}
      </InfoRow>
      <InfoRow label="Type">{placePayData.type}</InfoRow>
    </div>
  );
}

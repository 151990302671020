import React from 'react';

import ShopifyBurstContextProvider from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import RequestForm from 'components/integrations/AngusServiceRequests/RequestForm';
import BuildingEnginesClassicRequestForm from 'components/integrations/BuildingEnginesClassic/RequestForm';
import VisitForm from 'components/integrations/BuildingEnginesPrismVisitors/components/VisitForm';
import MeetingForm from 'components/integrations/ProxyClick/components/MeetingForm';
import BurstCreateFlow from 'components/integrations/ShopifyBurst/components/BurstCreateFlow';

export default function ContentNewInteraction({ content }: any) {
  const integrationProvider = content?.integration?.integration?.name;

  switch (integrationProvider) {
    case IntegrationProviderEnum.BuildingEnginesPrismVisitors:
      return <VisitForm content={content} />;
    case IntegrationProviderEnum.ProxyClick:
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ content: any; }' is not assignable to type... Remove this comment to see the full error message
      return <MeetingForm content={content} />;
    case IntegrationProviderEnum.AngusServiceRequests:
      return <RequestForm content={content} />;
    case IntegrationProviderEnum.ShopifyBurst:
      return (
        <ShopifyBurstContextProvider contentId={content?._id}>
          <BurstCreateFlow style={{ width: '100%' }} />
        </ShopifyBurstContextProvider>
      );
    case IntegrationProviderEnum.BuildingEnginesClassic:
      return <BuildingEnginesClassicRequestForm content={content} />;
    default:
      // todo: non integrations don't use this? maybe redirect them somewhere.
      return <div />;
  }
}

import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DataLibrary from './DataLibrary';
import DatasetSchema from './DatasetSchema';
import NewDatasetSchema from './NewDatasetSchema';

export default function DataLibraryIndex() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <DataLibrary />
      </Route>
      <Route exact path={`${path}/database-new`}>
        <NewDatasetSchema />
      </Route>
      <Route path={`${path}/:datasetSchemaId`}>
        <DatasetSchema />
      </Route>
    </Switch>
  );
}

import React from 'react';

import cx from 'classnames';

import { StatusChip } from './StatusChip';

import styles from './UciCard.scss';

export interface UciCardProps {
  title?: string;
  subTitle?: string;
  description?: string;
  date?: string;
  status?: string;
  id?: string;
  onSelect?: (id: string) => void;
  isActive?: boolean;
  className?: string;
  index: number;
}

export const UciCard: React.FC<UciCardProps> = ({
  title,
  subTitle,
  date,
  status,
  description,
  id,
  isActive,
  onSelect,
  className,
  index,
}) => {
  const onClick = () => {
    if (!onSelect || !id) {
      return;
    }
    onSelect(id);
  };
  return (
    <div
      className={cx(styles.workOrderCard, isActive && styles.active, className)}
      id={id}
      onClick={onClick}
      role="button"
      tabIndex={index}
    >
      <span className={cx(styles.tertiaryText)}>{date}</span>
      <StatusChip status={status} className={styles.statusChip} />

      <p className={cx(styles.primaryText, styles.heading)}>{title}</p>
      <p className={cx(styles.secondaryText)}>{subTitle}</p>
      <p
        className={cx(
          styles.secondaryText,
          styles.description,
          styles.twoLineClamp
        )}
      >
        {description}
      </p>
    </div>
  );
};

import Input from 'components/form/Input';
import type { ChannelIntegrationEditorProps } from 'components/integrations/ChannelIntegrationEditor/ChannelIntegrationEditorProps';
import React from 'react';
import styles from './ConfigurableInputField.scss';
import { Tooltip } from 'design-system-web/components/Tooltip/Tooltip';

type PropertyProps = {
  propertyKey: string;
  settings: ChannelIntegrationEditorProps['channelIntegration']['settings'];
  disabled: boolean;
  label: string;
};

export const ConfigurableInputField = ({
  propertyKey,
  disabled = false,
  settings,
  label,
}: PropertyProps) => {
  return (
    <div className={styles.configurableInput}>
      <div className={styles.inputLabel}>{label}</div>
      <Tooltip
        TooltipComponent="Used as reference for Event Stream Owner GUID"
        placement="top"
      >
        <Input
          disabled={disabled}
          value={settings?.[propertyKey]}
          onChange={() => null}
          showClear={false}
          testId="buildingGuildInput"
        />
      </Tooltip>
    </div>
  );
};

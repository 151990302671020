import React, { useContext } from 'react';
import { PropertyType } from 'lane-shared/types/properties/Property';
import Input from 'components/form/Input';
import styles from './CredentialRange.scss';
import inputSeparator from 'static/img/input-separator.svg';
import infoCircle from 'static/img/info-circle.svg';
import { Tooltip } from 'components';
import { ValidationErrorContext } from 'lane-shared/contexts';
import { useTranslation } from 'react-i18next';

type PropertyProps = {
  property: PropertyType;
  propertyKey: string;
  value: any;
  onChange: (key: string, value: any) => void;
  keys?: string[];
  error: any;
};

export const CredentialRange = ({
  property,
  propertyKey,
  value,
  onChange,
  error,
}: PropertyProps) => {
  const { t } = useTranslation();

  const errors = useContext(ValidationErrorContext);
  const credentialError = errors?.inner?.find(error =>
    error?.path?.includes(propertyKey)
  );

  const handleChange = (key: string, currentValue: any) => {
    const isPositiveNumber = /^(?!0)\d*(\.\d+)?$/.test(currentValue);

    if (isPositiveNumber || currentValue === '') {
      const max =
        key === 'max'
          ? currentValue.toString()
          : value?.[propertyKey]?.max?.toString();
      const min =
        key === 'min'
          ? currentValue.toString()
          : value?.[propertyKey]?.min?.toString();

      const rangeValue = {
        max,
        min,
      };

      onChange(propertyKey, rangeValue);
    }
  };

  return (
    <div className={styles.credentialRange}>
      <div className={styles.dFlex}>
        <label className={styles.parentLabel}>{property?.friendlyName}</label>
        <Tooltip
          TooltipComponent="This is the range of numbers issued as access credentials."
          zIndex={1000}
        >
          <img src={infoCircle as string} alt="info" className={styles.ml2} />
        </Tooltip>
      </div>
      <div className={styles.range}>
        <Input
          showClear={false}
          maxExpand={false}
          fieldName={property.friendlyName}
          inputWrapperClassName={styles.inputCustomWidth}
          label="Min"
          value={value?.[propertyKey]?.min}
          testId={property.friendlyName}
          error={error?.errors}
          placeholder="Min"
          onChange={value => handleChange('min', value)}
        />
        <img
          src={inputSeparator as string}
          alt="input-separator"
          className={styles.inputSeparator}
        />
        <Input
          showClear={false}
          maxExpand={false}
          fieldName={property.friendlyName}
          inputWrapperClassName={styles.inputCustomWidth}
          label="Max"
          value={value?.[propertyKey]?.max}
          testId={property.friendlyName}
          error={error?.errors}
          placeholder="Max"
          onChange={value => handleChange('max', value)}
        />
      </div>
      {credentialError?.message && (
        <p className={styles.error} data-test="errorMessage">
          {t(credentialError.message)}
        </p>
      )}
    </div>
  );
};

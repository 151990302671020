import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ValidationError } from 'yup';

import { routes } from 'lane-shared/config';
import { validateLoginLegacy } from 'lane-shared/validation';

import Input from 'components/form/Input';
import Button from 'components/general/Button';
import { H4 } from 'components/typography';

import styles from './LoginForm.scss';

const MESSAGES = {
  title: 'Welcome back!',
  emailLabel: 'Email',
  passwordLabel: 'Password',
  forgotPassword: 'Forgot password?',
  button: 'Log in',
  incorrectPasswordError: 'Incorrect password',
};

type Props = {
  userEmail: string;
  isLoading: boolean;
  error: {
    isIncorrectPassword: boolean;
  };
  onSubmit: (email: string, password: string) => void;
};

export default function LoginForm({
  userEmail,
  isLoading,
  error: { isIncorrectPassword },
  onSubmit,
}: Props) {
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState<null | string>(null);
  const [incorrectPasswordError, setIncorrectPasswordError] = useState<
    null | string
  >(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isIncorrectPassword) {
      setIncorrectPasswordError(t(MESSAGES.incorrectPasswordError));
    }
  }, [isIncorrectPassword, t]);

  function handleChange(value: string) {
    setPassword(value);
  }

  function validateLoginInputs() {
    if (!password) {
      return;
    }

    try {
      validateLoginLegacy.validateSync({ email: userEmail, password });
    } catch (err) {
      if (err instanceof ValidationError) {
        setValidationError(err.message);
      }
    }
  }

  function getPasswordError(
    validationError: null | string,
    incorrectPasswordError: null | string
  ) {
    if (validationError) {
      return [validationError];
    }

    if (incorrectPasswordError) {
      return [incorrectPasswordError];
    }

    return null;
  }

  return (
    <div>
      <H4 className={styles.title} mb={4}>
        {t(MESSAGES.title)}
      </H4>
      <div className={styles.formContainer}>
        <Input
          onChange={() => {}}
          value={userEmail}
          showClear={false}
          label={t(MESSAGES.emailLabel)}
          fieldName="email"
          readOnly
          icon="lock"
          iconRight
          style={{ color: 'black' }}
        />
        <Input
          className={styles.input}
          onBlur={() => validateLoginInputs()}
          onChange={handleChange}
          onFocus={() => {
            setValidationError(null);
            setIncorrectPasswordError(null);
          }}
          type="password"
          value={password}
          showClear={false}
          label={t(MESSAGES.passwordLabel)}
          fieldName="password"
          error={getPasswordError(validationError, incorrectPasswordError)}
        />
        <div className={styles.forgotPasswordContainer}>
          <Link
            className={styles.forgotPasswordLink}
            to={routes.forgotPassword}
          >
            {t(MESSAGES.forgotPassword)}
          </Link>
        </div>
        <Button
          color="inherit"
          disabled={!password || !!validationError || !!incorrectPasswordError}
          loading={isLoading}
          onClick={() => onSubmit(userEmail, password)}
          size="large"
          type="button"
          variant="contained"
          testId="signinButton"
        >
          {MESSAGES.button}
        </Button>
      </div>
    </div>
  );
}

import React from 'react';

import qs from 'querystring';
import { useLocation } from 'react-router-dom';

import { routes, config } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { emitter } from 'lane-shared/helpers';
import { useMagicLinksFeatureEnabled } from 'lane-shared/hooks';

import Login from 'components/authV2/Login';

import buildingPurple from 'static/img/building-purple.svg';

import WelcomeTemplate from './WelcomeTemplate';

import styles from './LoginPageLegacy.scss';

const SLIDE = {
  title: 'Tap into your workplace',
  subTitle:
    'Access your building, book rooms, discover amenities, and join our community.',
  image: buildingPurple,
};

type Props = {
  isManualLogin?: boolean;
  history: any;
} & any;

/**
 * @deprecated replaced by LoginPage
 */
export default function LoginPageLegacy({ history, isManualLogin }: Props) {
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectUrl = queryParams.get(config.redirectUrlQueryParameterName);
  const { shouldSkipCompanySelection } = useSignUpContext();
  const magicLinksFeatureFlag = useMagicLinksFeatureEnabled();

  async function onLogin(signInData: any) {
    if (redirectUrl) {
      queryParams.delete(config.redirectUrlQueryParameterName);
      window.location.replace(`${redirectUrl}?${queryParams.toString()}`);

      return;
    }

    // what route are we sending the user to.
    let route: string = routes.home;

    if (!signInData) {
      route = shouldSkipCompanySelection
        ? routes.signUpBuilding
        : routes.signUpCompany;
    } else {
      emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
        authToken: signInData,
      });
    }

    // check for an invite code
    const query = qs.parse(history?.location.search.substr(1));

    if (query.invite) {
      // grab invite code from the url and send them back to accept
      route = `${routes.invite.split(':')[0]}${query.invite}`;
    } else if (history.location?.state?.from && signInData) {
      // they were redirected from a page to login, send them back
      route = history.location?.state.from.pathname;
    }

    history.push(route);
  }

  function navigateToMagicLink() {
    history.push(routes.magicLinkRequest);
  }

  return (
    <WelcomeTemplate slide={SLIDE} portalSize="small">
      <div className={styles.loginPage}>
        <Login
          onLogin={onLogin}
          isManualLogin={magicLinksFeatureFlag ? Boolean(isManualLogin) : true}
          navigateToMagicLink={navigateToMagicLink}
        />
      </div>
    </WelcomeTemplate>
  );
}

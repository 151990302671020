import React from 'react';

import {
  Charge,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import { styles } from './ChargesListPDFExport.styles';

import { Page, View, Text, Svg, Line } from '@react-pdf/renderer';
import { SupportedLocaleEnum } from 'localization';
import { ChargeListItemPDFExport } from './ChargeListItemPDFExport/ChargeListItemPDFExport';
import { ChargeSummaryPDFExport } from './ChargeSummaryPDFExport/ChargeSummaryPDFExport';
import { getChargeStatus } from 'lane-shared/helpers/features';

type Props = {
  charges: Charge[];
  draftCharge?: Charge;
  labels: { [key: string]: string };
  billingStatusLabels: Record<string, string>;
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
};
export function ChargesListPDFExport({
  charges,
  draftCharge,
  labels,
  billingStatusLabels,
  currency,
  locale,
}: Props) {
  return (
    <>
      {draftCharge && (
        <Page size="A4" style={styles.page}>
          <Text style={styles.header}>{labels.draftTitle}</Text>
          <View style={styles.ChargeDetails}>
            <View style={styles.ChargeDetailsItem}>
              <Text style={styles.ChargeDetailsKey}>
                {labels.chargeStatus}:
              </Text>
              <Text style={styles.ChargeDetailsValue}>
                {labels.unsubmitted}
              </Text>
            </View>
            <Svg height="1" width="504" style={styles.line}>
              <Line
                x1="1100"
                y1="0"
                x2="0"
                y2="0"
                strokeWidth={1}
                stroke="#ECEDEF"
              />
            </Svg>
            {draftCharge.items.map((item, index) => (
              <ChargeListItemPDFExport
                key={index}
                item={item}
                labels={labels}
                currency={currency}
                locale={locale}
              />
            ))}
            <ChargeSummaryPDFExport
              charge={draftCharge}
              labels={labels}
              currency={currency}
              locale={locale}
            />
          </View>
          <Text
            style={styles.PageNumber}
            render={({ pageNumber }) => `${labels.pageNumber} ${pageNumber}`}
            fixed
          />
        </Page>
      )}
      {charges.map((charge, index) => (
        <Page size="A4" style={styles.page} key={index}>
          <Text style={styles.header}>{labels.chargeTitle}</Text>
          <View style={styles.ChargeDetails}>
            <View style={styles.ChargeDetailsItem}>
              <Text style={styles.ChargeDetailsKey}>{labels.chargeName}:</Text>
              <Text style={styles.ChargeDetailsValue}>{charge.name}</Text>
            </View>
            <View style={styles.ChargeDetailsItem}>
              <Text style={styles.ChargeDetailsKey}>
                {labels.chargeStatus}:
              </Text>
              <Text style={styles.ChargeDetailsValue}>
                {billingStatusLabels[getChargeStatus(charge.status, charge)[0]]}
              </Text>
            </View>
            <Svg height="1" width="504" style={styles.line}>
              <Line
                x1="1100"
                y1="0"
                x2="0"
                y2="0"
                strokeWidth={1}
                stroke="#ECEDEF"
              />
            </Svg>
            {charge.items.map((item, index) => (
              <ChargeListItemPDFExport
                key={index}
                item={item}
                labels={labels}
                currency={currency}
                locale={locale}
              />
            ))}
            <ChargeSummaryPDFExport
              charge={charge}
              labels={labels}
              currency={currency}
              locale={locale}
            />
          </View>
          <Text
            style={styles.PageNumber}
            render={({ pageNumber }) => `${labels.pageNumber} ${pageNumber}`}
            fixed
          />
        </Page>
      ))}
    </>
  );
}

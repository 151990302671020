import React from 'react';

import { useTranslation } from 'react-i18next';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { PublicUserType } from 'lane-shared/hooks/useMyPublicUsersQuery';
import { WizardStepProps } from 'lane-shared/hooks/useWizard';
import {
  BurstDraftInteractionData,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import { Button } from 'components/general';
import CircleListView from 'components/lane/CircleListView';
import { Flex } from 'components/layout';
import { H5, M, XS } from 'components/typography';

import AddShopifolkModal from '../../components/AddShopifolkModal';
import BurstButtonGroup from '../../components/BurstButtonGroup';
import BurstCreateHeader from '../../components/BurstCreateHeader';
import Container from '../../components/Container';
import { useModalContext } from 'contexts/ModalContext';

import styles from './BurstGuests.scss';

export default function BurstL9({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
}: WizardStepProps<BurstDraftInteractionData>) {
  const { data } = wizardState;
  const { t } = useTranslation();
  const { showModal, closeModal } = useModalContext();

  const users = data.l9 ? [data.l9] : [];

  function updatel9(users: PublicUserType[]) {
    onDataUpdated({
      l9: users[0],
    });

    closeModal();
  }

  function removeL9() {
    onDataUpdated({
      l9: undefined,
    });
  }

  function openShopifolkModal() {
    showModal(
      <AddShopifolkModal
        modalTitle={t(ShopifyBurstPageTitle.SelectM6Approver)}
        actionButtonTitle={t(ShopifyBurstPageTitle.ActionM6Approver)}
        onClose={closeModal}
        updateGuestList={updatel9}
        attendees={users}
        maxCapacity={1}
        forM6
      />
    );
  }

  return (
    <>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={content}
        title={t('Select your M6+ approver')}
      />
      <Container style={{ maxWidth: '50em', paddingBottom: '0' }}>
        <M mb={2}>
          {t(
            'Your M6+ Approver is the individual approving your burst purpose and budget.'
          )}
        </M>
        <M variant="secondary" mb={5}>
          {t(
            'Pro-tip: please ensure your Approver and Lead are aware of your burst prior to submission. If your team has any team-specific guidance on bursting, we recommend reviewing those Vault pages.'
          )}
        </M>
        <Flex justify="center" mb={5}>
          <Button onClick={openShopifolkModal}>
            {t('Add your M6+ approver')}
          </Button>
        </Flex>
        <Flex
          direction="column"
          align="center"
          m={[5, 0]}
          gap={4}
          className={styles.guestList}
        >
          {data.l9 && (
            <CircleListView
              key={data.l9._id}
              image={imageUrl(data.l9.profile.image)}
              logo={imageUrl(data.l9.profile.logo)}
              name={getDisplayName(data.l9)}
              className={styles.guest}
              onClick={() => removeL9()}
              isCancellable
            >
              <H5 bold>{getDisplayName(data.l9)}</H5>
              <XS variant="secondary">{data.l9.profile.email}</XS>
            </CircleListView>
          )}
        </Flex>

        <BurstButtonGroup
          buttons={[
            { title: 'Prev', onClick: goToPrevStep },
            { title: 'Next', onClick: goToNextStep },
          ]}
          disabled={users.length === 0}
        />
      </Container>
    </>
  );
}

import React from 'react';

import cx from 'classnames';
import { getUserLocale } from 'helpers';
import { useTranslation } from 'react-i18next';

import usePointOfSaleLineItem from 'lane-shared/hooks/integrations/essensys/usePointOfSaleLineItem';
import { EssensysProductFeatureProperties } from 'lane-shared/types/features/EssensysProductFeatureProperties';
import { PaymentFeatureProperties } from 'lane-shared/types/features/PaymentFeatureProperties';

import { Loading } from 'components/general';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import styleMap from 'components/renderers/v5/styleMap';

import styles from './PointOfSale.scss';

type BlockProps = WebBlockProps & {
  printingCredits: boolean;
  credits: boolean;
  autoHide: boolean;
  essensysProductFeature: EssensysProductFeatureProperties;
  paymentFeature?: PaymentFeatureProperties;
};

export default function PointOfSale({
  className,
  style,
  essensysProductFeature,
  paymentFeature,
}: BlockProps) {
  const { t } = useTranslation();
  const userLocale = getUserLocale();
  const { loading, lineItem } = usePointOfSaleLineItem({
    userLocale,
    essensysProductFeature,
    paymentFeature,
  });

  if (!lineItem) {
    return null;
  }
  return (
    <div
      className={cx(styles.pointOfSale, className)}
      style={styleMap(false, style)}
    >
      <h3 className={cx(styles.title)}>
        {t(
          'web.admin.channel.content.layout.editor.components.pointOfSale.header'
        )}
      </h3>
      {loading ? <Loading /> : null}
      <div className={cx(styles.row)}>
        <div>{t(lineItem.name)}</div>
        <div className={cx(styles.price)}>{lineItem.price}</div>
      </div>
    </div>
  );
}

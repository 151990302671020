import { ApolloError } from '@apollo/client';

import { AddressType } from 'lane-shared/types/AddressType';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { LaneType } from 'common-types';
import { updateUserStatusesForChannel } from 'lane-shared/graphql/mutation';
import { getClient } from 'lane-shared/apollo';

export const formattedAddress = (address: AddressType | undefined) =>
  [
    address?.street1,
    address?.city,
    address?.state,
    address?.code,
    address?.country,
  ]
    .filter(e => !!e)
    .join(', ');

export const getChannelInfoById = (
  channels: ChannelType[],
  channelId: string | undefined
) => channels.find(({ _id }: ChannelType) => channelId === _id);

export function doesChannelRowHaveParent(rowInfo: any): boolean {
  return rowInfo.parentChannel?.parentChannelName !== undefined;
}

export type ChannelManagementErrorType = ApolloError | string | null;

export async function updateUserStatuses(channelId: LaneType.UUID) {
  const client = getClient();
  return await client.mutate({
    mutation: updateUserStatusesForChannel,
    variables: {
      channelId,
    },
  });
}

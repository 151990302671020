import { WHITELABEL_THEZOAPP } from './index';
import { fonts } from '../fonts';

const whitelabelFonts = {
  [WHITELABEL_THEZOAPP]: {
    headerFont: fonts.questrial,
    bodyFont: fonts.questrial,
  },
  default: {
    headerFont: fonts.inter,
    bodyFont: fonts.inter,
  },
} as const;

export const getWhitelabelFonts = (instance?: string) => {
  if (instance && instance in whitelabelFonts) {
    return whitelabelFonts[instance as keyof typeof whitelabelFonts];
  }

  return whitelabelFonts.default;
};

import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidationError } from 'yup';

import { UserDataContext } from 'lane-shared/contexts';
import { constructAddress, getValidationMessages } from 'lane-shared/helpers';
import longAddress from 'lane-shared/helpers/formatters/longAddress';
import Types from 'lane-shared/properties/Types';
import { AddressType } from 'lane-shared/types/AddressType';
import { validateCreateAddress } from 'lane-shared/validation';

import { Input } from 'design-system-web';
import IconButton from 'components/general/IconButton';
import PlacesAutocompleteV2, {
  PlacesAutocompleteHandle,
} from 'components/general/PlacesAutocompleteInline';

import styles from './AddressEditInline.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  address: AddressType;
  onAddressUpdated: (address: AddressType) => void;
  isNewAddress?: boolean;
  isRequired?: boolean;
  error?: string;
};

const example = Types.getBaseTypes().Address!.example;

export default function AddressEditInline({
  className,
  style,
  address,
  onAddressUpdated,
  isNewAddress = false,
  isRequired,
  error,
}: Props) {
  const { t } = useTranslation();
  const { user } = useContext(UserDataContext);
  const [internalAddress, setInternalAddress] = useState<AddressType | null>(
    null
  );
  const [validation, setValidation] = useState<ValidationError | null>(null);
  const [internalHasGeoLocation, setInternalHasGeoLocation] = useState<
    boolean | null
  >(null);
  const [isOpen, setIsOpen] = useState<boolean>(isNewAddress);
  const [isExistingAddressValid, setIsExistingAddressValid] = useState<boolean>(
    false
  );

  const placesRef = useRef<PlacesAutocompleteHandle>();

  // only set error message if the address fields are hidden which is controlled with geo location data.
  const errorMessage = useMemo(
    () => (!internalHasGeoLocation && error ? error : undefined),
    [internalHasGeoLocation, error]
  );

  function checkForGeoLocation(address: AddressType | null): boolean {
    const hasGeo = Boolean(address?.geo?.[0]);

    setInternalHasGeoLocation(hasGeo);
    return hasGeo;
  }

  useEffect(() => {
    async function init() {
      if (isNewAddress) return;

      // perform validation to determine how to render if editing a prefilled address
      const isValidAddress = await validate(address);
      setIsExistingAddressValid(isValidAddress);
    }

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function validate(address: AddressType): Promise<boolean> {
    checkForGeoLocation(address);

    try {
      setValidation(null);
      await validateCreateAddress.validate(address, { abortEarly: false });
      return true;
    } catch (err) {
      setValidation(err);
      return false;
    }
  }

  function updateInternalAddress(props: Partial<AddressType>) {
    const newAddress = {
      ...internalAddress!,
      ...props,
    };

    setInternalAddress(newAddress);

    validate(newAddress);

    onAddressUpdated(newAddress);
  }

  function openEdit() {
    if (!isNewAddress) {
      setInternalAddress(address);
      setIsOpen(true);
    }
  }

  if (!address) {
    setInternalAddress(constructAddress({ userId: user?._id }));
  }

  useEffect(() => {
    setInternalAddress(address);
    setInternalHasGeoLocation(checkForGeoLocation(address));
  }, [address]);

  const showLongAddress = !isNewAddress && !isOpen && isExistingAddressValid;

  return (
    <div
      className={cx(styles.AddressEditInline, className)}
      data-editing={!isNewAddress && isExistingAddressValid}
      data-open={isOpen}
      style={style}
    >
      {showLongAddress ? (
        <div className={styles.info} data-test="addressDisplay">
          <p>{longAddress(address)}</p>
          <IconButton
            className={styles.editButton}
            icon="edit"
            testId="addressEditButton"
            onClick={openEdit}
          />
        </div>
      ) : (
        <div className={styles.form}>
          <div className={styles.search}>
            <PlacesAutocompleteV2
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'MutableRefObject<PlacesAutocompleteHandle | ... Remove this comment to see the full error message
              ref={placesRef}
              className={cx(styles.places, styles.input)}
              placeholder={t('Search address')}
              value=""
              onChange={place =>
                updateInternalAddress({
                  ...place,
                  geo: [
                    (place.geo as any).longitude,
                    (place.geo as any).latitude,
                  ],
                })
              }
              testId="addressSearch"
              isRequired={isRequired}
              error={errorMessage}
            />
          </div>
          <div
            className={styles.fields}
            data-has-address={internalHasGeoLocation}
            data-test="addressFields"
          >
            <div className={styles.row}>
              <Input
                label={t('Address Line 1')}
                testId="addressValueStreet1"
                className={styles.input}
                placeholder={example.street1}
                error={getValidationMessages(validation, `street1`)}
                value={internalAddress ? internalAddress?.street1 : ''}
                onChange={street1 => updateInternalAddress({ street1 })}
                showClear={false}
              />
            </div>

            <div className={styles.row}>
              <Input
                label={t('Address Line 2')}
                testId="addressValueStreet2"
                className={styles.input}
                placeholder={example.street2}
                error={getValidationMessages(validation, `street2`)}
                value={internalAddress ? internalAddress?.street2 : ''}
                onChange={street2 => updateInternalAddress({ street2 })}
                showClear={false}
              />
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <Input
                  label={t('City/Municipality')}
                  testId="addressValueCity"
                  className={styles.input}
                  placeholder={example.city}
                  error={getValidationMessages(validation, `city`)}
                  value={internalAddress ? internalAddress?.city : ''}
                  onChange={city => updateInternalAddress({ city })}
                  showClear={false}
                />
              </div>

              <div className={styles.col}>
                <Input
                  label={t('State/Province')}
                  testId="addressValueState"
                  className={styles.input}
                  placeholder={example.state}
                  error={getValidationMessages(validation, `state`)}
                  value={internalAddress ? internalAddress?.state : ''}
                  onChange={state => updateInternalAddress({ state })}
                  showClear={false}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.col}>
                <Input
                  label={t('Country')}
                  testId="addressValueCountry"
                  className={styles.input}
                  placeholder={example.country}
                  error={getValidationMessages(validation, `country`)}
                  value={internalAddress ? internalAddress?.country : ''}
                  onChange={country => updateInternalAddress({ country })}
                  showClear={false}
                />
              </div>

              <div className={styles.col}>
                <Input
                  label={t('Postal/Zip Code')}
                  testId="addressValueCode"
                  className={styles.input}
                  error={getValidationMessages(validation, `code`)}
                  placeholder={example.code}
                  value={internalAddress ? internalAddress?.code : ''}
                  onChange={code => updateInternalAddress({ code })}
                  showClear={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';

import cx from 'classnames';

import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './NumberInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export function NumberInputBlock({
  max,
  min,
  placeholder = 'Enter Number…',
  disabled = false,
  onInput = () => {},
  isValid = true,
  validationMessage = 'Required.',
  style,
  className,
}: any) {
  return (
    <>
      <input
        type="number"
        className={cx(styles.NumberInputBlock, className)}
        style={styleMap(false, style)}
        placeholder={placeholder}
        disabled={disabled}
        onChange={e => onInput(e.target.value)}
        max={max}
        min={min}
      />
      {!isValid && <ValidationMessage errors={[validationMessage]} />}
    </>
  );
}

import React from 'react';

import { Page, View, Text, Svg, Line } from '@react-pdf/renderer';

import { simpleDate } from 'lane-shared/helpers/formatters';

import { styles } from './ServiceRequestDetailsList.styles';

export const ServiceRequestDetailsList = ({
  serviceRequestData,
  status,
  labels,
  isPropertyChannel,
}: any) => {
  return (
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>{labels.title}</Text>
      <Text style={{ margin: 8, fontSize: 13 }}>
        {labels.id} {serviceRequestData.userFriendlyID}
      </Text>
      <Text style={{ margin: 8 }}>
        <Text style={[styles.subheadings, { color: 'rgb(128,128,128)' }]}>
          {labels.createdOn}:
        </Text>
        <Text style={styles.subHeadingValues}>
          {simpleDate(serviceRequestData.createdAt)}
        </Text>
      </Text>
      <Svg height="20" width="530" style={styles.line}>
        <Line
          x1="1100"
          y1="0"
          x2="0"
          y2="0"
          strokeWidth={1}
          stroke="rgb(128,128,128)"
        />
      </Svg>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.status}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 55,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>{status}</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.assignedTo}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 25,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.assignee?.name || ''}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.billable}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 50,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.isBillable
              ? labels.isBillable
              : labels.isNotBillable}
          </Text>
        </View>
      </View>
      <Svg height="20" width="530" style={styles.line}>
        <Line
          x1="1100"
          y1="0"
          x2="0"
          y2="0"
          strokeWidth={1}
          stroke="rgb(128,128,128)"
        />
      </Svg>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.submittedBy}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 21,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData?.createdBy?.name}
          </Text>
        </View>
      </View>
      {isPropertyChannel && (
        <View style={{ flexDirection: 'row' }}>
          <View>
            <Text style={styles.subheadings}>{labels.company}:</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              paddingLeft: 53,
              paddingTop: 11,
            }}
          >
            <Text style={styles.subHeadingValues}>
              {serviceRequestData.company?.name}
            </Text>
          </View>
        </View>
      )}
      {isPropertyChannel && (
        <>
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text style={styles.subheadings}>{labels.floor}:</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: 62,
                paddingTop: 11,
              }}
            >
              <Text style={styles.subHeadingValues}>
                {serviceRequestData.floor}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View>
              <Text style={styles.subheadings}>{labels.suite}:</Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: 62,
                paddingTop: 11,
              }}
            >
              <Text style={styles.subHeadingValues}>
                {serviceRequestData.suite}
              </Text>
            </View>
          </View>
        </>
      )}
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.phone}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 55,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.phone}
          </Text>
        </View>
      </View>
      <Svg height="20" width="530" style={styles.line}>
        <Line
          x1="1100"
          y1="0"
          x2="0"
          y2="0"
          strokeWidth={1}
          stroke="rgb(128,128,128)"
        />
      </Svg>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.issue}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 80,
            paddingLeft: 60,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.issue}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.category}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 80,
            paddingLeft: 40,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.category}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.location}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 80,
            paddingLeft: 45,
            paddingTop: 11,
          }}
        >
          <Text style={styles.subHeadingValues}>
            {serviceRequestData.location}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.description}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 100,
            paddingLeft: 30,
            paddingTop: 11,
          }}
        >
          <Text
            style={[
              styles.serviceRequestDescriptionText,
              styles.subHeadingValues,
              { color: 'rgb(128,128,128)' },
            ]}
          >
            {serviceRequestData.description}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <View>
          <Text style={styles.subheadings}>{labels.notes}:</Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingRight: 80,
            paddingLeft: 60,
            paddingTop: 11,
          }}
        >
          <Text
            style={[
              styles.serviceRequestDescriptionText,
              styles.subHeadingValues,
              { color: 'rgb(128,128,128)' },
            ]}
          >
            {serviceRequestData.notes}
          </Text>
        </View>
      </View>
      <Svg height="20" width="530" style={styles.line}>
        <Line
          x1="1100"
          y1="0"
          x2="0"
          y2="0"
          strokeWidth={1}
          stroke="rgb(128,128,128)"
        />
      </Svg>
      {serviceRequestData?.customData?.length &&
        serviceRequestData?.customData?.map((customData: any) => (
          <>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <Text style={styles.serviceRequestCustomData}>
                  {customData.Question}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <View>
                <Text style={styles.serviceRequestCustomDataValue}>
                  {customData.Answer}
                </Text>
              </View>
            </View>
          </>
        ))}

      <Text
        style={styles.pageNumber}
        render={({ pageNumber }) => `${labels.pageNumber} ${pageNumber}`}
        fixed
      />
    </Page>
  );
};

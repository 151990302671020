import React from 'react';

import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';

import { PageHeader } from 'lane-web/src/components/layout';

export function CreateProductFormHeader({
  id,
  breadcrumbs,
  ...props
}: {
  id: string;
  [x: string]: any;
  breadcrumbs?: {
    label: string;
    url?: string;
  }[];
}) {
  const { t } = useTranslation();
  const productServicesLink = routes.channelAdminProductsServices.replace(
    ':id',
    id
  );

  const defaultBreadcrumbs = [
    {
      label: t('adp.productsServices.breadcrumb'),
      url: productServicesLink,
    },
    { label: t('abp.productsServices.createProduct.breadcrumb') },
  ];

  return (
    <PageHeader
      externalPadding={[0, 0]}
      header={t('abp.productsServices.createProduct.heading')}
      headerLevel="h3"
      breadcrumbs={breadcrumbs ?? defaultBreadcrumbs}
      {...props}
    />
  );
}

import gql from 'graphql-tag';

import { ProfileFragment, PublicUserFragment } from '../fragments';

export default gql`
  ${PublicUserFragment}
  ${ProfileFragment}

  query interactionHistory($id: UUID!, $pagination: PaginationInput) {
    interactionHistory(_id: $id, pagination: $pagination) {
      items {
        _id
        _created
        _updated
        _createdBy {
          ...PublicUserFragment
        }
        _updatedBy {
          ...PublicUserFragment
        }
        user {
          _id
          name
          profile {
            ...ProfileFragment
          }
        }
        channel {
          _id
        }
        content {
          _id
        }
        startDate
        endDate
        geo
        data
        state
        features
        actions
        contentData
        version
        status
        adminNote
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`;

import useFlag from './useFlag';

const FLAG = 'flex.fetchUserBeforeOnboard';

/**
 * Generated with generate-feature-flag-custom-hook.sh script
 * on 2024-05-27T12:33:14-0400.
 */
export function useFetchUserBeforeOnboardEnabled() {
  const isEnabled = useFlag<boolean>(FLAG, false);

  return Boolean(isEnabled);
}

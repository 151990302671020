import { GraphQLErrorOptions } from 'graphql';

import { ChannelFiltersError, UnverifiedEmailError } from 'activate-errors';

import { isThisError } from 'lane-shared/helpers';

const isCodedError = (err: any, errorType: any) => {
  const isApolloCodedError = err.graphQLErrors?.some(
    (graphQLError: GraphQLErrorOptions) =>
      isThisError(graphQLError?.extensions?.exception, errorType)
  );

  return Boolean(isApolloCodedError);
};

export const isChannelFiltersError = (err: any) =>
  isCodedError(err, ChannelFiltersError);

export const isUnverifiedEmailError = (err: any) =>
  isCodedError(err, UnverifiedEmailError);

import React, { useState, useEffect, useContext } from 'react';

import { Input, Button, ErrorMessage, Checkbox, ControlMenu } from 'components';
import { useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { UnverifiedEmailError } from 'activate-errors';
import {
  getValidationMessages,
  emitter,
  pause,
  isThisError,
} from 'lane-shared/helpers';
import { validateLoginLegacy } from 'lane-shared/validation';

import { M, H3 } from 'components/typography';

import { history } from '../../../helpers';

import styles from './Signup.scss';

type Props = {
  onNext: () => void;
};

export default function SignupConfirm({ onNext }: Props) {
  const { whitelabel } = useContext(AppContext);
  const {
    submitting,
    submitError,
    email,
    password,
    name,
    building,
    company,
    oAuth,
    updateSignUp,
    doSignUp,
  } = useSignUpContext();

  const [validation, setValidation] = useState(null);
  const [termsAndConditionsRead, setTermsAndConditionsRead] = useState(false);
  const [privacyPolicyRead, setPrivacyPolicyRead] = useState(false);
  const { t } = useTranslation();

  async function createAccount() {
    try {
      const { token, jti, warnings } = await doSignUp();

      emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
        authToken: { jti, token },
        warnings,
      });

      onNext();
    } catch (err) {
      if (isThisError(err, UnverifiedEmailError)) {
        await pause();
        history.push(routes.signUpValidateEmail);
      }
    }
  }

  async function validate() {
    setValidation(null);

    try {
      // After updating the state, see if its valid.
      await validateLoginLegacy.validate({ email, password });
    } catch (validation) {
      setValidation(validation);
    }
  }

  useEffect(() => {
    if (!oAuth) {
      validate();
    }
  }, [name, email, password]);

  return (
    <>
      <H3>{t('Please ensure all your information is correct')}</H3>
      <div>
        {!oAuth && (
          <Input
            className={styles.input}
            key="name"
            onChange={name =>
              updateSignUp({
                name,
              })
            }
            value={name}
            placeholder={t('Enter your name')}
            icon="user"
            error={getValidationMessages(validation, 'name')}
          />
        )}
        {building && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'onChange' is missing in type '{ classNam... Remove this comment to see the full error message
          <Input
            className={styles.input}
            key="building"
            value={building.name}
            icon="map-pin"
            error={getValidationMessages(validation, 'building')}
          />
        )}

        {company && (
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ className: any; key: string; icon: string;... Remove this comment to see the full error message
          <Input
            className={styles.input}
            key="company"
            icon="briefcase"
            error={getValidationMessages(validation, 'company')}
          />
        )}
        {!oAuth && (
          <Input
            className={styles.input}
            key="email"
            onChange={email =>
              updateSignUp({
                email,
              })
            }
            value={email}
            placeholder={t('Enter your email')}
            icon="mail"
            error={getValidationMessages(validation, 'email')}
          />
        )}
        {!oAuth && (
          <Input
            className={styles.input}
            key="password"
            onChange={password =>
              updateSignUp({
                password,
              })
            }
            value={password}
            type="password"
            placeholder={t('Choose a password')}
            icon="lock"
            error={getValidationMessages(validation, 'password')}
          />
        )}

        <Checkbox
          selected={termsAndConditionsRead}
          onChange={() => setTermsAndConditionsRead(!termsAndConditionsRead)}
          name="terms-and-conditions-read"
          text={
            <M variant="secondary">
              {t('I have read and accept the')}{' '}
              <a
                href={whitelabel.termsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Terms and Conditions')}
              </a>
            </M>
          }
          className={styles.checkbox}
          value={termsAndConditionsRead}
        />

        <Checkbox
          selected={privacyPolicyRead}
          onChange={() => setPrivacyPolicyRead(!privacyPolicyRead)}
          name="privacy-policy-read"
          text={
            <span>
              {t('I have read and accept the')}{' '}
              <a
                href={whitelabel.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Privacy Policy')}
              </a>
            </span>
          }
          className={styles.checkbox}
          value={privacyPolicyRead}
        />

        <ErrorMessage error={submitError} className={styles.errorMessage} />

        <ControlMenu className={styles.menu}>
          <Button
            variant="contained"
            disabled={
              !!validation || !termsAndConditionsRead || !privacyPolicyRead
            }
            loading={submitting}
            onClick={createAccount}
          >
            {t('Create my account')}
          </Button>
        </ControlMenu>
      </div>
    </>
  );
}

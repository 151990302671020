import React from 'react';

import { Modal } from 'components/lds';

import styles from './RequirementsBlock.scss';

type RequirementModalProps = {
  children: React.ReactNode;
  visible: boolean;
  onClose: (...args: any) => void;
  className?: string;
};

export default function RequirementModal({
  children,
  visible,
  onClose,
}: RequirementModalProps) {
  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      style={{ minHeight: 640, minWidth: 448 }}
      disableContentPadding
      closeButtonSize="large"
    >
      <div className={styles.requirementModal}>
        <div className={styles.closeButton} />
        {children}
      </div>
    </Modal>
  );
}

import React from 'react';

import { useInteractiveContentsQuery } from 'lane-shared/hooks';

import InteractiveContentDashboardWidget from './InteractiveContentDashboardWidget';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channelId?: string;
};

export default function ChannelInteractiveContents({
  className,
  style,
  channelId,
}: Props) {
  const { contents, statics } = useInteractiveContentsQuery(channelId);

  return (
    <>
      {contents.map((content: any) => (
        <InteractiveContentDashboardWidget
          className={className}
          style={style}
          key={content._id}
          content={content}
        />
      ))}
      {statics.map((content: any) => (
        <InteractiveContentDashboardWidget
          className={className}
          style={style}
          key={content._id}
          content={content}
        />
      ))}
    </>
  );
}

import styles from '../addItemsToChargeStyles.scss';
import { Button } from 'design-system-web';
import { AddProductToChargeDetails } from 'lane-shared/domains/billingPayments/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNumberTwoDecimalPlaces } from '../../../products-services/helpers';

export function AddCreditBundleButtonGroup({
  productDetails,
  addProduct,
  onCancel,
}: {
  productDetails: AddProductToChargeDetails;
  addProduct: Function;
  onCancel: Function;
}) {
  const { t } = useTranslation();
  const isSaveButtonDisabled = () => {
    const {
      productCategoryId,
      productId,
      rate,
      quantity,
      tax,
    } = productDetails;

    const rateIsInvalid = !isNumberTwoDecimalPlaces(parseFloat(rate));
    const taxIsInvalid =
      tax?.value && !isNumberTwoDecimalPlaces(parseFloat(tax.value));

    // Check if rate and taxValue are numbers with two or fewer decimal places
    const invalidDecimalPlaces = rateIsInvalid || taxIsInvalid;

    // Check if any of the required fields are missing
    const missingFields =
      !productCategoryId || !productId || !rate || !quantity || !tax?.value;

    // Return true if any of the conditions are met
    return invalidDecimalPlaces || missingFields;
  };

  return (
    <div className={styles.buttonGroup}>
      <Button
        size="large"
        variant="primary"
        onClick={() => addProduct()}
        testId="saveProductButton"
        disabled={isSaveButtonDisabled()}
      >
        {t('Save')}
      </Button>
      <Button
        size="large"
        variant="secondary"
        onClick={() => onCancel()}
        testId="cancelAddProductButton"
      >
        {t('abp.cancel')}
      </Button>
    </div>
  );
}

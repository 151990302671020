import React from 'react';

import { useTranslation } from 'react-i18next';

import { WorkOrderModuleSettings } from 'lane-shared/domains/workOrder';
import { useChannelServiceRequestFeaturesContext } from 'lane-shared/hooks';

import SimpleOptions from './components/inputs/SimpleOptions';
import { arrayToOptions, optionsToArray } from './serviceCategory';

import styles from './locationBuilder.scss';

type Props = {
  settings?: WorkOrderModuleSettings;
  onSettingsUpdated: (locations: string[]) => void;
};
export const LocationBuilder = ({ settings, onSettingsUpdated }: Props) => {
  const { t } = useTranslation();
  const { serviceRequestFeatures } = useChannelServiceRequestFeaturesContext();

  return (
    <div className={styles.LocationBuilder}>
      <p className={styles.description}>
        {t`web.admin.serviceRequest.builder.location.description`}
      </p>
      <SimpleOptions
        disableDragging
        options={arrayToOptions(settings?.locations)}
        setOptions={locationOptions => {
          const locations = optionsToArray(locationOptions);
          onSettingsUpdated(locations);
        }}
        addOptionTitle={t`web.admin.channel.serviceRequest.builder.locations.add`}
        hideAddOption={!serviceRequestFeatures?.createServiceRequest}
        allowEdit={serviceRequestFeatures?.updateServiceRequest}
      />
    </div>
  );
};

import { useState, useEffect } from 'react';

import { v4 as uuid } from 'uuid';

export default function useManagePaymentAccount({
  paymentAccount,
  onPaymentAccountDelete,
  onPaymentAccountRuleAdd,
  onPaymentAccountRuleDelete,
}: any) {
  const [editedPaymentAccount, setEditedPaymentAccount] = useState(null);
  const [newRule, setNewRule] = useState(null);
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (paymentAccount?.rules) {
      setRules(paymentAccount.rules);
    }
  }, [paymentAccount?.rules]);

  function updatePaymentAccount(update: any) {
    setEditedPaymentAccount({
      ...(editedPaymentAccount || paymentAccount),
      ...update,
    });
  }

  function updateNewRule(update: any) {
    setNewRule({
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...newRule,
      ...update,
    });
  }

  async function onRuleDelete(rule: any) {
    try {
      await window.Alert.confirm({
        title: `Remove rule?`,
        message: `Are you sure you want to remove this rule?`,
      });
    } catch (err) {
      return;
    }

    await onPaymentAccountRuleDelete(rule);
  }

  async function onRuleAdd(rule: any) {
    try {
      await onPaymentAccountRuleAdd(rule);
      setNewRule(null);
    } catch (err) {
      // error handled elsewhere
    }
  }

  async function constructNewRule() {
    setNewRule({
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ _id: string; allContent: boole... Remove this comment to see the full error message
      _id: uuid(),
      allContent: true,
      contentCategories: [],
      noSinglePurchaseLimit: true,
      singlePurchaseLimit: 0,
      noPurchaseLimit: true,
      purchaseLimit: 0,
      purchaseLimitTimeUnit: 'Month',
      anyTimes: true,
      startTime: null,
      endTime: null,
      allRoles: true,
      groupRole: null,
    });
  }

  async function deleteAccount() {
    try {
      await window.Alert.confirm({
        title: `Delete payment account?`,
        message: 'Are you sure you want to delete this payment account?',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    await onPaymentAccountDelete(paymentAccount);
  }

  return {
    rules,
    setRules,
    editedPaymentAccount,
    setEditedPaymentAccount,
    updatePaymentAccount,
    newRule,
    setNewRule,
    updateNewRule,
    onRuleAdd,
    onRuleDelete,
    constructNewRule,
    deleteAccount,
  };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from 'design-system-web';
import styles from './StepExecutionForm.scss';
import { type StepExecution } from 'graphql-query-contracts';

export const PreviousMeterReading = ({
  step,
  previousValues,
}: {
  step: StepExecution;
  previousValues: any;
}) => {
  const { t } = useTranslation();

  const getPreviousReadingValue = (meterReadingId: string | undefined | null) => {
    if (!meterReadingId) return;
    const previousValue = previousValues.find(
      (value: any) => value.meterReadingId === meterReadingId
    );
    return previousValue?.value;
  };

  const getPreviousReadingDate = (meterReadingId: string | undefined | null) => {
    if (!meterReadingId) return;
    const previousValue = previousValues.find(
      (value: any) => value.meterReadingId === meterReadingId
    );
    return previousValue?.createdAt;
  };

  const previousReadingValue = step.meterReadingId
    ? getPreviousReadingValue(step.meterReadingId)
    : null;
  const previousReadingDate = step.meterReadingId
    ? getPreviousReadingDate(step.meterReadingId)
    : null;
  const hasPreviousReadingValue =
    previousReadingValue !== undefined && previousReadingValue !== null;

  return (
    <div>
      {hasPreviousReadingValue ? (
        <Flex direction="column" className={styles.previousValue}>
          <span>{t('web.admin.workOrder.steps.previousMeterReading')}</span>
          <span>
            {t('web.admin.workOrder.steps.meterReadingText', {
              value: previousReadingValue,
              unit: step.options?.unit,
              date: previousReadingDate
                ? new Date(previousReadingDate).toDateString()
                : '',
            })}
          </span>
        </Flex>
      ) : null}
    </div>
  );
};

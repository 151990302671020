import React, { useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import AppContext from 'lane-shared/contexts/AppContext';
import mergeThemes from 'lane-shared/helpers/content/mergeThemes';
import { ThemeType } from 'lane-shared/types/Theme';

import ThemePaletteColorDetails from 'components/lane/ThemePaletteColorDetails';
import { paletteColors } from 'components/lane/ThemePaletteEdit';
import Panel from 'components/layout/Panel';
import { H4 } from 'components/typography';

import styles from './ThemeDetails.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  theme: ThemeType;
};

export default function ThemeDetails({ className, style, theme }: Props) {
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);

  const displayTheme = mergeThemes([whitelabel.profile.theme, theme]);

  return (
    <Panel className={cx(styles.ThemeDetails, className)} style={style}>
      <H4 mb={2}>
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.color.palette'
        )}
      </H4>
      <div className={styles.colorPalette}>
        {paletteColors.map(p => (
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ className: any; key: string; color: any; }... Remove this comment to see the full error message
          <ThemePaletteColorDetails
            className={styles.swatch}
            key={p.type}
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            color={displayTheme.palette![p.type]}
          />
        ))}
      </div>

      <H4 mb={2}>
        {t(
          'web.admin.channel.settings.profile.themesTab.theme.palette.custom.palette'
        )}
      </H4>
      <div className={styles.customColorPalette}>
        {displayTheme.palette!.custom?.map(customColor => (
          // @ts-expect-error ts-migrate(2739) FIXME: Type '{ className: any; key: string; color: string... Remove this comment to see the full error message
          <ThemePaletteColorDetails
            className={styles.swatch}
            key={customColor._id}
            color={customColor.color}
          />
        ))}
      </div>
    </Panel>
  );
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/general';
import { Flex } from 'components/layout';

type ButtonInfo = {
  title: string;
  onClick: () => void;
};

type Props = {
  disabled?: boolean;
  loading?: boolean;
  buttons:
    | [leftButton: ButtonInfo, rightButton: ButtonInfo]
    | [button: ButtonInfo];
};

export default function BurstButtonGroup({
  buttons,
  disabled,
  loading,
}: Props) {
  const { t } = useTranslation();
  return (
    <Flex justify="center" gap={4} p={[7, 4]}>
      {buttons.map((button, index) => {
        // Check if this is the left button
        const isSecondaryButton = buttons.length === 2 && index === 0;
        return (
          <Button
            disabled={!isSecondaryButton && disabled}
            loading={loading}
            key={button.title}
            variant={isSecondaryButton ? 'outlined' : 'contained'}
            style={{
              minWidth: '10.25em',
            }}
            onClick={button.onClick}
          >
            {t(button.title)}
          </Button>
        );
      })}
    </Flex>
  );
}

import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { ChannelManagementModal } from './ChannelManagementModal';

type ArchiveModalProps = {
  selectedChannelName: string | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  archiveChannel: () => Promise<void>;
};
export default function ArchiveModal({
  selectedChannelName,
  isOpen,
  setIsOpen,
  archiveChannel,
}: ArchiveModalProps) {
  const { t } = useTranslation();
  return (
    <ChannelManagementModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t(
        'web.admin.portalManagement.channelManagement.archiveChannel.modal.title',
        { channelName: selectedChannelName }
      )}
      description={t(
        'web.admin.portalManagement.channelManagement.archiveChannel.modal.description',
        { channelName: selectedChannelName }
      )}
      onClick={archiveChannel}
    />
  );
}

import React, { useState } from 'react';

import { DateRange } from 'react-day-picker';

import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';

import DateRangeCalendar from './components/DateRangeCalendar';
import 'react-day-picker/dist/style.css';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
  timeZone?: string;
  // function to be called when element is clicked, returns selected day
  onChange: (date?: DateRangeType) => void;
  // submit callback when user click on 'set date'
  onSubmit?: ((date?: DateRangeType) => void) | null;
  onClear?: (() => void) | null;
  // callback when user changes months
  onFocusChange?: (date: Date) => void;
  // JS date object for max date
  maxDate?: Date;
  // JS date object for min date
  minDate?: Date;
  // the min range available to be selected, in days
  rangeMin?: number;
  // the max range available to be selected, in days
  rangeMax?: number;
  // unavailable ranges
  unavailableDateRanges?: DateRangeType[];
  weekdayOnly?: boolean;
  // the number of months to be displayed at one time
  numberOfMonths?: number;
  // a label for the submit button
  submitLabel?: string;
};

export default function DateRangePicker({
  className,
  style,
  loading,
  disabled,
  startDate,
  endDate,
  timeZone,
  onChange,
  onSubmit,
  onClear,
  onFocusChange,
  maxDate,
  minDate,
  rangeMin,
  rangeMax,
  unavailableDateRanges,
  weekdayOnly,
  submitLabel,
}: Props) {
  const value = { startDate, endDate } as DateRangeType;
  const [selected, setSelected] = useState<DateRangeType | undefined>(value);

  if ((selected?.startDate || selected?.endDate) && !onSubmit) {
    onChange(selected);
  }

  function handleSubmit() {
    if (onSubmit) {
      onSubmit(selected);
      onChange(selected);
    }
  }

  function handleSelect(range: DateRange | undefined) {
    setSelected({ startDate: range?.from, endDate: range?.to });
  }

  return (
    <DateRangeCalendar
      className={className}
      style={style}
      loading={loading}
      disabled={disabled}
      selected={
        { from: selected?.startDate, to: selected?.endDate } as DateRange
      }
      onChange={handleSelect}
      onSubmit={onSubmit && handleSubmit}
      onFocusChange={onFocusChange}
      onClear={onClear}
      minDate={minDate}
      maxDate={maxDate}
      rangeMin={rangeMin}
      rangeMax={rangeMax}
      unavailableDateRanges={unavailableDateRanges}
      weekdayOnly={weekdayOnly}
      submitLabel={submitLabel}
      testId="dateRangePicker"
      timeZone={timeZone}
    />
  );
}

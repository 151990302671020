import React from 'react';

import qs from 'qs';
import { useLocation } from 'react-router-dom';

import config, { routes } from 'lane-shared/config';
import { useSignUpContext } from 'lane-shared/contexts/SignUpContext';
import { emitter } from 'lane-shared/helpers';
import type { UserLoginMethod } from 'lane-shared/hooks/auth/useExistingOAuthUserLogins';
import useOAuthConfigForUserLogin from 'lane-shared/hooks/auth/useOAuthConfigForUserLogin';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import LoginWithSSOForm from './LoginWithSSOForm';
import { AuthToken } from 'src/typings';

import styles from './index.scss';

export function onLogin(
  signInData: any,
  queryParams: URLSearchParams,
  location: any,
  redirectToHomeAfterLogin: (route: string) => void,
  shouldSkipCompanySelection: boolean
) {
  const redirectUrl = queryParams.get(config.redirectUrlQueryParameterName);

  if (redirectUrl) {
    queryParams.delete(config.redirectUrlQueryParameterName);
    window.location.replace(`${redirectUrl}?${queryParams.toString()}`);
    return;
  }

  // what route are we sending the user to.
  let route: string = routes.home;
  if (!signInData) {
    route = shouldSkipCompanySelection
      ? routes.signUpBuilding
      : routes.signUpCompany;
  } else {
    emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
      authToken: signInData,
    });
  }

  // check for an invite code
  const query = qs.parse(location.search.substr(1));

  if (query.invite) {
    // grab invite code from the url and send them back to accept
    route = `${routes.invite.split(':')[0]}${query.invite}`;
  } else if (location?.state?.from && signInData) {
    // they were redirected from a page to login, send them back
    route = location?.state?.from.pathname;
  }
  redirectToHomeAfterLogin(route);
}

export default function LoginWithSSO({
  userEmail,
  onError,
  onManualLogin,
  redirectToHomeAfterLogin,
  whitelabel,
}: {
  userEmail: string;
  onError: (err: Error | null) => void;
  onManualLogin: () => void;
  redirectToHomeAfterLogin: (route: string) => void;
  whitelabel: WhiteLabelType;
}) {
  const { search, state } = useLocation<{
    email: string;
    oAuthUserLoginMethods: UserLoginMethod[];
  }>();
  const queryParams = new URLSearchParams(search);
  const { shouldSkipCompanySelection } = useSignUpContext();
  const location = useLocation();
  const oAuthConfigForUser = useOAuthConfigForUserLogin({
    whitelabel,
    oAuthUserLoginMethods: state?.oAuthUserLoginMethods,
  });

  return (
    <div className={styles.container}>
      <LoginWithSSOForm
        oAuthConfig={oAuthConfigForUser}
        userEmail={userEmail}
        onError={onError}
        onSuccess={(signInData: AuthToken) =>
          onLogin(
            signInData,
            queryParams,
            location,
            redirectToHomeAfterLogin,
            shouldSkipCompanySelection
          )
        }
        onManualLogin={onManualLogin}
        whitelabel={whitelabel}
      />
    </div>
  );
}

import React from 'react';

import { ChannelContentList } from 'components';

import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

export default function ChannelContent({ channel, ...otherProps }: any) {
  return (
    <ChannelContentList
      channelId={channel?._id}
      contentType={ContentTypeEnum.Content}
      {...otherProps}
    />
  );
}

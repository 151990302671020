import React, { useContext, useMemo } from 'react';

import { UserDataContext } from 'lane-shared/contexts';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { explodeFeatures } from 'lane-shared/helpers/features';
import { useReservableTimePickerEnabled } from 'lane-shared/hooks/useReservableTimePickerEnabled';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  ReservableUiTypesEnum,
  ReservableUnitTypesEnum,
} from 'lane-shared/types/features/ReservableFeatureProperties';

import LegacyReservableInput from 'components/features/LegacyReservableInput';

import { ReservableDateRange } from './Reservable/ReservableDateRange';

type OwnReservableFeatureProps = {
  className?: string;
  style?: React.CSSProperties;
  value: DateRangeType;
  existingValue?: DateRangeType;
  content: any;
  disabled?: boolean;
  forAdmin?: boolean;
  onInput: (dateRange: DateRangeType) => void;
};

export type ReservableFeatureProps = OwnReservableFeatureProps;

export default function ReservableInput({
  content,
  onInput,
  ...rest
}: ReservableFeatureProps) {
  const { user } = useContext(UserDataContext);
  const isTimePickerEnabled = useReservableTimePickerEnabled();
  const { reservableFeature, timeAvailabilityFeature } = explodeFeatures(
    content?.features
  );

  const isTimePicker =
    isTimePickerEnabled &&
    reservableFeature?.uiType === ReservableUiTypesEnum.TimePicker &&
    reservableFeature?.unitType === ReservableUnitTypesEnum.Minutes;

  const timeZone = useMemo(
    () =>
      getTimeZoneByGeoLocation({
        latitude: content?.geo?.[1],
        longitude: content?.geo?.[0],
      }),
    [content._id]
  );

  if (isTimePicker) {
    return (
      <ReservableDateRange
        contentId={content._id}
        intervalMinutes={reservableFeature!.units}
        maxDuration={
          reservableFeature!.mainRule.maxSlots * reservableFeature!.units
        }
        onInteractionChange={onInput}
        timeZone={timeZone}
        reservableFeature={reservableFeature!}
        timeAvailabilityFeature={timeAvailabilityFeature}
        bufferTimeConfig={reservableFeature!.bufferTime}
        userGroupRoleIds={
          user?.roles?.map(({ groupRole }) => groupRole._id) || []
        }
      />
    );
  }

  return (
    <LegacyReservableInput content={content} onInput={onInput} {...rest} />
  );
}

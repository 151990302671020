import React from 'react';

import cx from 'classnames';

import { youtubeEmbed } from 'lane-shared/helpers/formatters';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';

import styles from './YoutubeBlock.scss';

type BlockProps = WebBlockProps & {
  videoid: string;
};

const videoStyle = {
  width: '100%',
  height: '100%',
  border: 'none',
};

export default function YoutubeBlock({
  videoid,
  style,
  className,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  return (
    <div
      className={cx(styles.YoutubeBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <iframe
        title="youtube"
        src={youtubeEmbed({ videoid })}
        style={videoStyle}
      />
      <div className={styles.wrapper} />
    </div>
  );
}

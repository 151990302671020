import React from 'react';

import { routes } from 'lane-shared/config';
import { SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE } from 'lane-shared/helpers/integrations/Essensys/getCachedEssensysReservableValue';
import { useFlag } from 'lane-shared/hooks';
import { ContentQueryInteraction } from 'lane-shared/hooks/useContentQuery';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import { NavLinks } from 'components/general';
import PastMeetings from 'components/integrations/ProxyClick/components/PastMeetings';
import { NewUserContentInteractionReceipt } from 'components/lane/NewUserContentInteractionReceipt';
import UserContentInteractionReceipt from 'components/lane/UserContentInteractionReceipt';

import useScrollToTop from 'hooks/useScrollToTop';

import styles from './styles.scss';

type Props = {
  content: any;
  interactions: ContentQueryInteraction[];
};

export default function ContentInteractions({ content, interactions }: Props) {
  sessionStorage.removeItem(SESSIONSTORAGE_ESSENSYS_RESERVABLE_VALUE);
  const contentRoutes = [
    {
      name: content.name,
      route: routes.content.replace(':id', content._id),
    },
    {
      name: 'History',
      route: routes.contentInteractions.replace(':id', content._id),
    },
  ];

  useScrollToTop([content?._id]);

  const integrationProvider = content?.integration?.integration?.name;
  const showNewUserContentInteractionReceipt = useFlag(
    FeatureFlag.NewUserContentInteractionReceipt,
    false
  );

  switch (integrationProvider) {
    case IntegrationProviderEnum.ProxyClick:
      return <PastMeetings interactions={interactions} />;
    default:
      return (
        <div className={styles.ContentInteractionsPage}>
          <NavLinks routes={contentRoutes} />
          {showNewUserContentInteractionReceipt ? (
            <div className={styles.newInteractions}>
              {interactions.map(interaction => (
                <NewUserContentInteractionReceipt
                  key={interaction?._id}
                  // FIXME: Fix the types here
                  // @ts-ignore
                  interaction={interaction}
                />
              ))}
            </div>
          ) : (
            <div className={styles.interactions}>
              {interactions.map(interaction => (
                <div
                  key={interaction._id}
                  className={styles.interactionContainer}
                >
                  <UserContentInteractionReceipt
                    className={styles.interaction}
                    // @ts-expect-error ts-migrate(2741) FIXME: Property '_createdBy' is missing in type 'ContentQ... Remove this comment to see the full error message
                    interaction={interaction}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      );
  }
}

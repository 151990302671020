import React from 'react';

import ForgotPasswordV2 from 'components/authV2/ForgotPassword';

import WelcomeTemplate from 'pages/user/WelcomeTemplate';

import styles from './ForgotPasswordPage.scss';

export default function ForgotPasswordPage() {
  return (
    <WelcomeTemplate>
      <div className={styles.forgotPasswordPage}>
        <ForgotPasswordV2 />
      </div>
    </WelcomeTemplate>
  );
}

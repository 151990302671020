import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import VTSActivateLogo from 'static/img/vts-activate-logo-indigo.svg';
import VTSxLane from 'static/img/vts-x-lane.png';

import styles from './RebrandRedirectPage.scss';

export default function RebrandRedirectPage() {
  const [timeLeft, setTimeLeft] = useState<number>(5);
  const { t } = useTranslation();

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  if (timeLeft <= 0) {
    goToActivateUrl(true);
  }

  return (
    <div className={styles.redirectPage}>
      <div className={styles.vtsxLane}>
        <img src={VTSxLane} alt="VTS x Lane" />
        <div className={styles.acquired}>
          <div>{t('VTS has acquired Lane')}</div>
          <div>{t('to become VTS Activate.')}</div>
        </div>
      </div>
      <div className={styles.redirect}>
        <img src={VTSActivateLogo} alt="VTS Activate" />
        <div className={styles.newTenant}>
          <div>{t('You are being redirected to our')}</div>
          <div>{t('new Tenant Experience platform.')}</div>
        </div>
        <div className={styles.timeLeft}>...{timeLeft}</div>
        <div className={styles.thirty}>
          <div>{t("If you haven't been automatically redirected in")}</div>
          <div>{t('30 seconds, please click below.')}</div>
        </div>
        <button
          className={styles.button}
          onClick={() => goToActivateUrl(false)}
        >
          {t('Go to activate.vts.com')}
        </button>
      </div>
    </div>
  );
}

export function goToActivateUrl(shouldRetainPath?: boolean) {
  const url = `https://activate.vts.com${
    shouldRetainPath ? window.location.pathname : ''
  }`;
  window.open(url, '_self');
}

import { HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED } from 'lane-shared/helpers/constants/analytics';
import { getSetupSteps } from '../helpers';
import { AutoSetupConfig } from '../types';
import { useContext, useEffect, useState } from 'react';
import { AnalyticsContext } from 'lane-shared/contexts';
import { SETUP_STEPS } from '../constants';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

export function useTrackSourceSelected({
  config,
  channel,
}: {
  config: AutoSetupConfig;
  channel: UseChannelForAdminQueryResponse;
}) {
  const analytics = useContext(AnalyticsContext);
  const [steps, setSteps] = useState<string[]>(SETUP_STEPS);

  useEffect(() => {
    if (config.dataSource && channel?.channel) {
      analytics.track(HOST_BUILDING_AUTO_SETUP_WIZARD_SOURCE_SELECTED, {
        channelName: channel.channel.name,
        value: config.dataSource,
      });
      setSteps(getSetupSteps(config.dataSource));
    }
  }, [config.dataSource, analytics, channel]);

  return steps;
}

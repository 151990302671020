import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ThemeType } from 'lane-shared/types/Theme';

import styles from './ListHeader.scss';
import { ButtonLink } from 'components/general/ButtonLink';

const numberFormat = new Intl.NumberFormat().format;

type Props = {
  className?: string;
  style?: any;
  header: string;
  viewAll?: boolean;
  totalItems: number;
  currentItems: number;
  link?: string;
  theme: ThemeType;
  contentType?: string;
};

export default function ListHeader({
  className,
  style,
  header,
  currentItems = 0,
  totalItems = 0,
  viewAll = false,
  link,
  contentType,
}: Props) {
  const { t } = useTranslation();

  return (
    <h3 className={cx(styles.ListHeader, className)} style={style}>
      <span>{Boolean(header) && header}</span>
      {viewAll && (
        <ButtonLink
          to={{ pathname: link, state: { contentType } }}
          size="medium"
        >
          {t(
            'web.admin.channel.content.layout.editor.components.listHeader.viewAll'
          )}{' '}
          {currentItems < totalItems && (
            <span>({numberFormat(totalItems)})</span>
          )}
        </ButtonLink>
      )}
    </h3>
  );
}

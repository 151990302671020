import { gql } from '@apollo/client';

import { Invoice, PageInfo } from 'lane-shared/domains/billingPayments/types';

export interface ListInvoicesCompanyQueryResult {
  accounts: {
    listInvoicesByCompanies: {
      invoices: Invoice[];
      pageInfo: PageInfo;
    };
  };
}

export const listInvoicesByCompaniesQuery = gql`
  query listInvoicesByCompanies(
    $listInvoicesByPayersRequest: ListInvoicesByPayersRequest!
  ) {
    accounts {
      listInvoicesByCompanies(
        listInvoicesByPayersRequest: $listInvoicesByPayersRequest
      ) {
        invoices {
          id
          displayId
          groupId
          groupName
          amount
          status
          externalPayerId
          externalPayerName
          currency
          invoiceDate
          dueDate
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

export interface ListInvoicesUserQueryResult {
  accounts: {
    listInvoicesByUsers: {
      invoices: Invoice[];
      pageInfo: PageInfo;
    };
  };
}

export const listInvoicesByUsersQuery = gql`
  query listInvoicesByUsers(
    $listInvoicesByPayersRequest: ListInvoicesByPayersRequest!
  ) {
    accounts {
      listInvoicesByUsers(
        listInvoicesByPayersRequest: $listInvoicesByPayersRequest
      ) {
        invoices {
          id
          displayId
          groupId
          groupName
          amount
          status
          externalPayerId
          externalPayerName
          currency
          invoiceDate
          dueDate
        }
        pageInfo {
          perPage
          start
          total
        }
      }
    }
  }
`;

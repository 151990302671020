import { ReservableWaitlistByContent } from 'graphql-query-contracts';
import { Sort } from 'design-system-web';
import { SortDirectionEnum } from 'lane-shared/types/graphql/search';
import { formatISODateToMonthDayYear, formatTime } from './formatters';

export type RowData = {
  id: string;
  company: string;
  name: string;
  primaryEmail: string;
  reservationDate: string;
  reservationTime: string;
};

const DEFAULT_SORT = 'startDate';

export function waitlistItemToRowDataMapper(
  channelTimeZone: string,
  waitlistItems: ReservableWaitlistByContent[]
): RowData[] {
  const mappedRows = waitlistItems.map(waitlist => ({
    id: waitlist?.id,
    reservationDate: formatISODateToMonthDayYear(
      waitlist.startDate,
      channelTimeZone
    ),
    reservationTime: formatTime(
      waitlist.startDate,
      waitlist.endDate,
      channelTimeZone
    ),
    name: waitlist?.user?.name || '',
    primaryEmail: waitlist?.user?.email || '',
    company:
      waitlist?.companies?.map(company => company?.name).join(', ') || '',
  }));

  return mappedRows || [];
}

export function getSort(sort: Sort) {
  const sortMapping: { [key: string]: string } = {
    company: 'Company',
    name: 'User_Name',
    primaryEmail: 'User_Email',
    reservationDate: 'startDate',
  };

  return {
    key: sort && sort.id ? sortMapping[sort.id] : DEFAULT_SORT,
    dir: sort && sort.direction ? sort.direction : SortDirectionEnum.Asc,
  };
}

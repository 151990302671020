import React from 'react';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

type BlockProps = WebBlockProps & {
  text: string;
  isRequired?: boolean;
};

export default function TextBlock({
  style,
  className,
  text,
  isRequired,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  return (
    <p
      className={className}
      style={{
        whiteSpace: 'pre-line',
        margin: 0,
        padding: 0,
        ...style,
        fontFamily: 'Inter',
      }}
      {...props}
    >
      {(text as any)?.en || text}
      {/* We use text block as a label for input fields */}
      {isRequired && (
        <span className="text-text-critical text-md align-top ml-0.25">*</span>
      )}
    </p>
  );
}

import React, { useState, useMemo, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import interactionHistoryQuery from 'lane-shared/graphql/interactions/interactionHistory';
import { adminInteraction } from 'lane-shared/graphql/query';
import { castGraphQLObject } from 'lane-shared/helpers';
import toSchema from 'lane-shared/helpers/toSchema';
import { useFlag, useMultiLanguage } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import ModalBackground from 'components/general/ModalBackground';
import Pagination from 'components/general/Pagination';
import TabStrip from 'components/general/TabStrip';
import UCIEdit from 'components/interactions/UCIEdit';
import { NewUserContentInteractionReceipt } from 'components/lane/NewUserContentInteractionReceipt';
import UserContentInteractionReceipt from 'components/lane/UserContentInteractionReceipt';
import AdminPage from 'components/layout/AdminPage';
import { BreadCrumbs } from 'components/lds';
import { H3, H4, M } from 'components/typography';

import ChannelAdminContext from '../ChannelAdminContext';
import { AttachmentProvider } from 'contexts/AttachmentContext';

import styles from './ChannelAdminInteraction.scss';

const tabs = ['Edit', 'Receipt'];
const [TAB_EDIT, TAB_RECEIPT] = tabs;

export default function ChannelAdminInteraction() {
  const { interactionId } = useParams<{ interactionId: string }>();
  const [selectedTab, setSelectedTab] = useState(TAB_EDIT);
  const { channel } = useContext(ChannelAdminContext);
  const [isOpen, setIsOpen] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);
  const { t } = useTranslation();
  const { translate } = useMultiLanguage();

  const showNewUserContentInteractionReceipt = useFlag(
    FeatureFlag.NewUserContentInteractionReceipt,
    false
  );

  function openHistory() {
    setHistoryIndex(0);
    setIsOpen(true);
  }

  function closeHistory() {
    setIsOpen(false);
    setHistoryIndex(0);
  }

  const interactionResult = useQuery(adminInteraction, {
    variables: {
      id: interactionId,
    },
    skip: !interactionId,
    fetchPolicy: 'network-only',
  });

  const interactionHistoryResult = useQuery(interactionHistoryQuery, {
    variables: {
      id: interactionId,
      skip: !interactionId || !isOpen,
      pagination: {
        start: 0,
        perPage: 25,
      },
    },
  });

  const interaction = useMemo(() => {
    if (interactionResult?.data?.interaction) {
      return castGraphQLObject(
        translate({ model: interactionResult?.data.interaction })
      );
    }

    return null;
  }, [interactionResult?.data?.interaction]);

  const interactionHistory = useMemo(
    () =>
      translate({
        model: interactionHistoryResult.data?.interactionHistory?.items || [],
      }),

    [interactionHistoryResult.data?.interactionHistory?.items]
  );

  const contentType =
    (interaction?.contentData?.type as ContentTypeEnum) ||
    ContentTypeEnum.Static;

  return (
    <>
      <AdminPage>
        <div className={styles.ChannelAdminInteraction}>
          <BreadCrumbs
            links={[
              {
                label: t(breadCrumbsLabelForContentTypeMap[contentType]),
                url: breadCrumbsUrlForContentTypeMap[contentType](
                  channel?.slug || ''
                ),
              },
              { label: interaction?.contentData.name },
            ]}
          />
          <H3 bold mt={4}>
            {interaction?.contentData.name}
          </H3>
          <TabStrip
            tabs={tabs.map(toSchema)}
            selected={toSchema(selectedTab)}
            onSelectTab={tab => setSelectedTab(tab.value)}
          />

          {selectedTab === TAB_EDIT && (
            <AttachmentProvider>
              <UCIEdit
                mode="edit"
                interaction={interaction}
                onViewHistory={openHistory}
              />
            </AttachmentProvider>
          )}

          {selectedTab === TAB_RECEIPT && (
            <ReceiptComponent
              displayNewReceipt={showNewUserContentInteractionReceipt}
              interaction={interaction}
            />
          )}
        </div>
      </AdminPage>
      <ModalBackground
        onClose={closeHistory}
        isOpen={isOpen}
        className={styles.background}
        hasOpaqueBackground
      >
        <div className={styles.window}>
          {interactionHistory.length > 0 ? (
            <div className={styles.uciWrapper}>
              {interactionHistory[historyIndex] && (
                <UCIEdit
                  className={styles.uciEdit}
                  interaction={interactionHistory[historyIndex]}
                  mode="history"
                />
              )}
              <Pagination
                className={styles.pagination}
                pagesToShow={10}
                perPage={1}
                page={historyIndex}
                onPage={page => setHistoryIndex(page)}
                total={interactionHistory.length}
              />
            </div>
          ) : (
            <>
              <H4 mb={2}>No history available.</H4>
              <M>There is no history available for this interaction.</M>
            </>
          )}
        </div>
      </ModalBackground>
    </>
  );
}

function ReceiptComponent({
  displayNewReceipt,
  interaction,
}: {
  displayNewReceipt?: boolean;
  interaction: any;
}) {
  return displayNewReceipt ? (
    <div className={styles.newReceiptWrapper}>
      <div>
        <NewUserContentInteractionReceipt
          className={styles.receipt}
          interaction={interaction}
        />
      </div>
    </div>
  ) : (
    <UserContentInteractionReceipt
      className={styles.receipt}
      interaction={interaction}
    />
  );
}

type BreadCrumbUrlFunctionMap<
  Key extends keyof typeof ContentTypeEnum,
  Value extends (channelSlug: string) => string
> = {
  [K in Key]: Value;
};

function defaultBreadCrumbsUrl(channelSlug: string) {
  return routes.channelAdminPageCenter.replace(':id', channelSlug);
}

function visitorManagementBreadCrumbsUrl(channelSlug: string) {
  return routes.visitorManagementRegistrationForms.replace(':id', channelSlug);
}

const breadCrumbsUrlForContentTypeMap: Readonly<
  BreadCrumbUrlFunctionMap<ContentTypeEnum, (channelSlug: string) => string>
> = Object.freeze({
  [ContentTypeEnum.Static]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Content]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Notice]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.ScheduledContent]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.ScheduledNotice]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Promotion]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.Perk]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.WorkOrder]: defaultBreadCrumbsUrl,
  [ContentTypeEnum.VisitorManagement]: visitorManagementBreadCrumbsUrl,
});

type BreadCrumbLabelMap<
  Key extends keyof typeof ContentTypeEnum,
  Value extends string
> = {
  [K in Key]: Value;
};

const breadCrumbsLabelForContentTypeMap: Readonly<
  BreadCrumbLabelMap<ContentTypeEnum, string>
> = Object.freeze({
  [ContentTypeEnum.Static]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.Content]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.Notice]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.ScheduledContent]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.ScheduledNotice]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.Promotion]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.Perk]: 'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.WorkOrder]:
    'web.admin.content.viewer.bread.crumbs.label.static',
  [ContentTypeEnum.VisitorManagement]:
    'web.admin.content.viewer.bread.crumbs.label.visitor.management',
});

import gql from 'graphql-tag';

export default gql`
  query lookerEmbedURL(
    $selectedReport: LookerReportType!
    $channelId: UUID!
    $channelName: String!
    $contentName: String
    $timezone: String!
  ) {
    me {
      lookerEmbedURL(
        selectedReport: $selectedReport
        channelId: $channelId
        channelName: $channelName
        contentName: $contentName
        timezone: $timezone
      )
    }
  }
`;

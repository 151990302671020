import React, { useState } from 'react';
import useLaunchDarklyWebClient from 'hooks/useLaunchDarklyWebClient';
import { config } from 'lane-shared/config';
import LaunchDarklyContext from 'lane-shared/contexts/LaunchDarklyContext';
import { useLaunchDarklyContext } from 'lane-shared/hooks';
import { useWhitelabelByIndexHTML } from 'lane-shared/hooks/useWhitelabelByIndexHTML';

export function LaunchDarklyContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [ldReady, setLDReady] = useState(false);
  const whitelabelByIndexHTML = useWhitelabelByIndexHTML();

  const client = useLaunchDarklyWebClient({
    clientId: config.launchDarkly.clientId,
    whitelabelName: whitelabelByIndexHTML?.name,
    onReady: () => setLDReady(true),
  });
  const launchDarklyContext = useLaunchDarklyContext(client);

  return (
    <LaunchDarklyContext.Provider value={launchDarklyContext}>
      {ldReady ? children : null}
    </LaunchDarklyContext.Provider>
  );
}

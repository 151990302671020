import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
    createTenant,
  } from 'lane-shared/graphql/tenant';
  import {
    CreateTenantMutation
  } from 'graphql-query-contracts';
import { convertToUUID } from 'lane-shared/helpers/convertId';

export const useCreateTenant = (tenantId: string | undefined, propertyId: string | undefined) => {
  const [ createTenantMutation ] = useMutation<CreateTenantMutation>(createTenant);

  useEffect(() => {
    if (tenantId && propertyId) {
      createTenantMutation({
        variables: { 
          tenantId: convertToUUID(tenantId), 
          propertyId: convertToUUID(propertyId)
        },
      });
    }
  }, [tenantId, propertyId, createTenantMutation]);
};

import React from 'react';

import { PopupMenu, TextButton, Column } from 'design-system-web';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from 'lane-shared/config';
import useChannelAdminContext from 'hooks/useChannelAdminContext';

import {
  Invoice,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { dates } from 'lane-shared/helpers/constants';
import { getInvoiceStatus } from 'lane-shared/helpers/features/getInvoiceStatus';
import { isInvoicePayable } from 'lane-shared/helpers/features/isInvoicePayable';
import {
  simpleCurrencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';

import { Chip, ChipStyle } from 'components/ads';

import {
  InvoiceActions,
  useGetActionsForInvoices,
} from './useGetActionsForInvoices';

export function useGetInvoiceTableColumns(): Column<Invoice>[] {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';

  const invoiceActions = useGetActionsForInvoices();
  const renderIdCell = (_: any, invoice: Invoice) => {
    const invoiceDisplayRoute = routes.channelAdminInvoiceView
      .replace(':id', channel_slug)
      .replace(':invoiceDisplayId', invoice.displayId);
    return <Link to={invoiceDisplayRoute}>{invoice.displayId}</Link>;
  };

  const invoiceColumns: Column<Invoice>[] = [
    {
      key: 'status',
      header: t('abp.invoiceList.status'),
      renderCell: renderStatusCell,
      renderForCSV: (cell, row) => {
        return t(renderStatusCellForCSV(cell, row));
      },
    },
    {
      key: 'id',
      header: t('abp.invoiceList.invoiceId'),
      renderCell: renderIdCell,
      renderForCSV: (_, row) => row.displayId,
    },
    { key: 'externalPayerId', header: t('abp.invoiceList.customer') },
    {
      key: 'amount',
      header: t('abp.invoiceList.amount'),
      renderCell: renderAmountCell,
      renderForCSV: renderAmountCellForCSV,
    },
    {
      key: 'invoiceDate',
      header: t('abp.invoiceList.issueDate'),
      renderCell: renderDateCell,
      renderForCSV: renderDateCellForCSV,
    },
    {
      key: 'dueDate',
      header: t('abp.invoiceList.dueDate'),
      renderCell: renderDateCell,
      renderForCSV: renderDateCellForCSV,
    },
  ];

  if (invoiceActions.length) {
    invoiceColumns.push({
      key: 'actions',
      header: t('abp.actions'),
      renderCell: (cell: number, row: Invoice) =>
        renderActionsCell(cell, row, invoiceActions, t),
    });
  }

  return invoiceColumns;
}

function renderStatusCell(cell: string, invoiceDetails: Invoice) {
  const metadata = invoiceDetails.metadata || {};
  const statusMap = getInvoiceStatus(
    cell,
    invoiceDetails,
    metadata.autopay || false
  );
  const chipColor = statusMap[1] as ChipStyle;
  return <Chip value={statusMap[0]} type={chipColor} size="xs" />;
}

function renderStatusCellForCSV(cell: string, invoiceDetails: Invoice): string {
  const statusMap = getInvoiceStatus(cell, invoiceDetails);
  return statusMap[0];
}

function renderAmountCell(cell: number, invoiceDetails: Invoice) {
  return (
    <span>
      {simpleCurrencyFormatter(
        cell,
        currencyToPaymentCurrency(invoiceDetails.currency)
      )}
    </span>
  );
}

function renderAmountCellForCSV(cell: number, invoiceDetails: Invoice) {
  return simpleCurrencyFormatter(
    cell,
    currencyToPaymentCurrency(invoiceDetails.currency)
  );
}

function renderDateCell(cell: string) {
  return <span>{dateFormatter(cell, dates.SIMPLE_DATE)}</span>;
}

function renderDateCellForCSV(cell: string) {
  return dateFormatter(cell, dates.SIMPLE_DATE);
}

function renderActionsCell(
  cell: number,
  row: Invoice,
  invoiceActions: InvoiceActions[],
  t: (key: string) => string
) {
  return (
    <PopupMenu
      trigger={
        <TextButton iconName="angle-down">{t('abp.actions')}</TextButton>
      }
      items={invoiceActions.map(
        ({ label: invoiceActionLabel, onSelect: onInvoiceActionSelected }) => {
          const triggerInvoiceAction = () => onInvoiceActionSelected(cell, row);
          return {
            label: invoiceActionLabel,
            onSelect: triggerInvoiceAction,
            isDisabled: !!(
              invoiceActionLabel === t('abp.actions.markAsPaid') &&
              !isInvoicePayable(row)
            ),
          };
        }
      )}
    />
  );
}

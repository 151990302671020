import React, { useState, useContext } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';
import { UserDataContext, ChannelsContext } from 'lane-shared/contexts';
import { unsubscribeFromChannel, updateUser } from 'lane-shared/graphql/user';
import { getDisplayName, pause, emitter } from 'lane-shared/helpers';
import { longDate } from 'lane-shared/helpers/formatters';
import { useUpdatedData } from 'lane-shared/hooks';

import Checkbox from '../form/Checkbox';
import Input from '../form/Input';
import TextArea from '../form/TextArea';
import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import Label from '../general/Label';

import styles from './UserGroupRoleEdit.scss';

export default function UserGroupRoleEdit({
  className,
  style,
  role,
  channel,
}: any) {
  const { t } = useTranslation();
  const { user, refetch } = useContext(UserDataContext);
  const { primaryChannel } = useContext(ChannelsContext);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatedRole, setUpdatedRole, hasChanges, getPatch] = useUpdatedData(
    role
  );
  const displayName = getDisplayName(channel);

  function toggleEditing() {
    if (isEditing) {
      setIsEditing(false);
    } else {
      setUpdatedRole(role, true);
      setIsEditing(true);
    }
  }

  async function saveRole() {
    setLoading(true);
    setError(null);

    try {
      await getClient().mutate({
        mutation: updateUser,
        variables: {
          user: {
            _id: user?._id,
            roles: [
              {
                _id: role._id,
                ...getPatch(),
              },
            ],
          },
        },
      });

      await refetch();
      setIsEditing(false);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }

  async function leaveRole() {
    try {
      await window.Alert.confirm({
        title: t('Leaving {{ displayName }}', { displayName }),
        message: t('Are you sure you would like to leave {{ displayName }}?', {
          displayName,
        }),
      });
    } catch (err) {
      return;
    }

    setLoading(true);

    await pause();
    await getClient().mutate({
      refetchQueries: ['GetEventSubscriptionSettings'],
      mutation: unsubscribeFromChannel,
      variables: { userGroupId: role._id },
    });

    await refetch();

    // user left the role they are looking at right now.
    if (role.groupRole?.channel?._id === primaryChannel?._id) {
      emitter.emit((emitter as any).EVENT_SUBSCRIPTIONS_CHANGED);
    }

    setLoading(false);
  }

  return (
    <li className={cx(styles.UserGroupRoleEdit, className)} style={style}>
      <div className={styles.role}>
        <h1>{role.groupRole.name}</h1>
        <ErrorMessage error={error} />
        {!isEditing && (
          <>
            {role.name && <h2>{role.name}</h2>}
            {role.description && <h2>{role.description}</h2>}
            {role.isPublic && <p>{t('Public membership')}</p>}
            <ControlMenu>
              <hr />
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"inh... Remove this comment to see the full error message */}
              <Button color="secondary" onClick={leaveRole} loading={loading}>
                {t('Leave')}
              </Button>
              <Button onClick={toggleEditing}>Edit</Button>
            </ControlMenu>
          </>
        )}
        {isEditing && (
          <>
            <Label
              h2
              TooltipComponent={t(
                'Enter a public facing title for your membership here.'
              )}
            >
              {t('Title')}
            </Label>
            <Input
              value={(updatedRole as any).name}
              onChange={name => setUpdatedRole({ name })}
              placeholder={t('i.e. Head Retail Manager')}
            />
            <Label
              h2
              TooltipComponent={t(
                'Enter more information about your membership here.'
              )}
            >
              {t('Description')}
            </Label>
            <TextArea
              className={styles.textArea}
              value={(updatedRole as any).description}
              onChange={description => setUpdatedRole({ description })}
              placeholder={`${t(
                'i.e. I am the Head Retail Manager at'
              )} ${displayName}`}
            />

            {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ className: ... Remove this comment to see the full error message */}
            <Checkbox
              className={styles.checkbox}
              selected={(updatedRole as any).isPublic}
              onChange={() =>
                setUpdatedRole({ isPublic: !(updatedRole as any).isPublic })
              }
              text={t('Public membership')}
            />

            <ControlMenu>
              <hr />
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '"secondary"' is not assignable to type '"inh... Remove this comment to see the full error message */}
              <Button color="secondary" onClick={leaveRole} loading={loading}>
                {t('Leave')}
              </Button>

              <Button onClick={toggleEditing} loading={loading}>
                {t('Cancel')}
              </Button>

              <Button
                color={'primary' as any}
                loading={loading}
                disabled={!hasChanges}
                variant="contained"
                onClick={saveRole}
              >
                {t('Save Changes')}
              </Button>
            </ControlMenu>
          </>
        )}
      </div>
      <p className={styles.joined}>
        {t('You joined on')} <strong>{longDate(role._created)}</strong>
      </p>
    </li>
  );
}

import React from 'react';

import { Icon } from 'design-system-web';
import { getUserLocale } from 'helpers';
import { useTranslation } from 'react-i18next';

import { RecurringCharge } from 'graphql-query-contracts';
import { ApolloQueryResult, OperationVariables } from '@apollo/client';

import {
  Currency,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { SIMPLE_DATE } from 'lane-shared/helpers/constants/dates';
import {
  currencyFormatter,
  dateFormatter,
} from 'lane-shared/helpers/formatters';

import { H5, M, Text } from 'components/typography';

import styles from './styles.scss';
import { CancelButton } from './CancelButton';

export function ChargeCard({
  recurringCharge,
  refetch,
}: {
  recurringCharge: Partial<RecurringCharge>;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
}) {
  const { t } = useTranslation();
  const recurrence = recurringCharge.recurrence!;
  const chargeTemplate = recurringCharge.charge!;
  const currencyFormat = currencyFormatter({
    currency: currencyToPaymentCurrency(Currency[chargeTemplate.currency]),
    locale: getUserLocale(),
  });

  const isActive = recurrence.isActive;
  const description = chargeTemplate.description;
  const amount = currencyFormat(chargeTemplate.amount);
  const timeFrame = recurrence.recurrenceInterval;
  const nilDate = new Date(0);
  const lastOccurrenceDate = recurrence.lastOccurrence
    ? new Date(recurrence.lastOccurrence)
    : nilDate;
  const nextOccurrenceDate = recurrence.nextOccurrence
    ? new Date(recurrence.nextOccurrence)
    : nilDate;

  return (
    <div className={styles.recurringChargeCard}>
      <span className={styles.heading}>
        <Icon
          name="RefreshCw"
          set="Feather"
          style={{ fontSize: 20, marginBottom: 10, marginRight: 16 }}
        />
        <H5>{chargeTemplate.name}</H5>
      </span>
      <span className={styles.testing}>
        <span className={styles.amountHeading}>
          <span className={styles.displayAmount}>
            <H5>{amount}</H5>
            <H5 className={styles.timeFrame}>
              {t(`abp.subscription.timeFrame.${timeFrame}`)}
            </H5>
          </span>
          <M>{description}</M>
        </span>
        <CancelButton recurringCharge={recurringCharge} refetch={refetch} />
      </span>
      <span className={styles.info}>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.from')}
          </Text>
          {chargeTemplate.groupId}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.to')}
          </Text>
          {chargeTemplate.externalPayerId}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.starts')}
          </Text>
          {dateFormatter(recurrence.startDate, SIMPLE_DATE)}
        </span>
        <span className={styles.infoGroup}>
          <Text className={styles.infoGroupHeading}>
            {t('abp.subscription.card.expires')}
          </Text>
          {nilDate >= lastOccurrenceDate
            ? t('abp.subscription.never')
            : dateFormatter(lastOccurrenceDate, SIMPLE_DATE)}
        </span>
        {isActive ? (
          <span className={styles.infoGroup}>
            <Text className={styles.infoGroupHeading}>
              {t('abp.subscription.card.next')}
            </Text>
            {dateFormatter(nextOccurrenceDate, SIMPLE_DATE)}
          </span>
        ) : (
          <span className={styles.infoGroup} />
        )}
      </span>
    </div>
  );
}

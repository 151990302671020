import React, { Suspense, useEffect, useMemo } from 'react';

import {
  AlertModal,
  Toast,
  Favicon,
  GoogleRecaptchaProvider,
  ErrorBoundary,
  Loading,
} from 'components';
import { I18nextProvider } from 'react-i18next';
import { Router } from 'react-router-dom';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as TerraThemeProvider } from '@viewthespace/components';
import { datadogRum } from '@datadog/browser-rum';

import LocationProvider from 'lane-shared/components/LocationProvider';
import RendererProvider from 'lane-shared/components/RendererProvider';
import { WHITELABEL_LANE } from 'lane-shared/config/whitelabels';
import {
  AppContext,
  UserDataContext,
  ChannelsContext,
  ThemeContext,
} from 'lane-shared/contexts';
import SignUpContextProvider from 'lane-shared/contexts/SignUpContext/SignUpContextProvider';
import {
  useAppData,
  useUserData,
  useUserChannelsData,
  useChannelFromSlug,
} from 'lane-shared/hooks';
import useInitialTheme from 'lane-shared/hooks/useInitialTheme';
import i18n from 'localization';
import { PlatformEnum } from 'lane-shared/types/PlatformEnum';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';
import { getHexColor } from 'lane-shared/helpers';
import { useFonts } from './hooks/useFonts';

import BlockingLoad from 'components/general/BlockingLoad';

import MuiTheme from 'static/materialUITheme';

import * as primitives from './components/renderers/v5/primitives';
import locationHelper from './helpers/Location';
import history from './helpers/history';
import linkHandler from './helpers/linkHandler';
import './helpers/setAppHeight';
import { useAnonymousChannelsData } from './hooks';
import useTheme from './hooks/useTheme';

import { getWhitelabelFonts } from 'lane-shared/config/whitelabels/whitelabelFonts';

export default function Providers({ children, withRouter = true }: any) {
  const urlSearchParams = new URLSearchParams(document.location.search);
  const jti = urlSearchParams.get('tokenid') ?? undefined;
  const jwt = urlSearchParams.get('token') ?? undefined;

  const {
    user,
    loading,
    error,
    route,
    hasAttemptedLogin,
    isLoggingIn,
    isLoggedIn,
    isInitialized,
    refetch,
    hasAnyPermission,
    sessionId,
  } = useUserData({ jti, jwt });

  const getHost = (url: string) => {
    // https://*.sub.domain.name.com to match sub.domain.name.com
    const pattern = /^(https?:\/\/)?(\*\.)?(?<host>[^/:]+)(:\d+)?(\/.*)?$/;

    return pattern.exec(url)?.groups?.host?.toLowerCase();
  };

  const address = window.location.href;
  const addressHost = getHost(address);

  function getChannelSlug() {
    const pathNameSplit = window.location.pathname.split('/');
    return pathNameSplit[4] === 'admin' ? pathNameSplit[3] : null;
  }
  const channelSlug = getChannelSlug();
  const { channel: adminFocusChannel } = useChannelFromSlug({
    channelSlug: channelSlug ?? null,
  });

  const {
    whitelabel,
    whitelabels,
    hasWhiteLabelLoaded,
    cards,
    blocks,
    isTransitioning,
    transition,
    clearTransition,
    isBlockingLoad,
    setIsBlockingLoad,
  } = useAppData({
    defaultInstance: WHITELABEL_LANE,
    findWhiteLabel: (whitelabel: WhiteLabelType) => {
      const isHostSame = (wlHost: string) =>
        addressHost && getHost(wlHost)?.toLowerCase() === addressHost;

      return whitelabel.hosts.some(isHostSame);
    },
  });

  const channelsData = useUserChannelsData({
    user,
    channelId: adminFocusChannel?._id,
  });
  const anonChannelsData = useAnonymousChannelsData({
    user,
    isInitialized,
    isLoggingIn,
  });

  const theme = useInitialTheme({
    whitelabel,
    channelId: channelsData?.primaryId,
  });

  useEffect(() => {
    if (
      !isLoggedIn &&
      whitelabel?.locale &&
      i18n.language !== whitelabel.locale
    ) {
      i18n.changeLanguage(whitelabel.locale);
    }
  }, [whitelabel?.locale, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && user?.profile) {
      datadogRum.setGlobalContext({
        user: {
          _id: user.profile?._id || '',
          name: user.profile?.name || '',
          email: user.profile?.email || '',
        },
      });
    }
  }, [user, isLoggedIn]);

  const { bodyFont, headerFont } = getWhitelabelFonts(whitelabel?.instance);

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ logo: string | undefined; name... Remove this comment to see the full error message
  useTheme(theme, { bodyFont, headerFont });

  useFonts({ bodyFont, headerFont });

  // Theme colors seemed to be saved in a variety of formats, this ensures we pass hex values to Terra's theme provider
  const { primaryColor, secondaryColor } = useMemo(() => {
    return {
      primaryColor: theme.primary ? getHexColor(theme.primary) : undefined,
      secondaryColor: theme.secondary
        ? getHexColor(theme.secondary)
        : undefined,
    };
  }, [theme.primary, theme.secondary]);

  // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  function setAlertRef(ref: AlertModal) {
    window.Alert = ref;
  }

  // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  function setToastRef(ref: Toast) {
    window.Toast = ref;
  }

  return (
    <Suspense fallback={<Loading />}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message */}
      <ErrorBoundary user={user} sessionId={sessionId}>
        <I18nextProvider i18n={i18n}>
          <RendererProvider
            platform={PlatformEnum.Web}
            primitives={primitives}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '({ value, switchChannel, }: { value: LinkTyp... Remove this comment to see the full error message
            linkHandler={linkHandler}
            blockDefinitions={blocks}
          >
            <LocationProvider locationHelper={locationHelper}>
              <AppContext.Provider
                value={{
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<WhiteLabelType>' is not assignable t... Remove this comment to see the full error message
                  whitelabel,
                  whitelabels,
                  cards,
                  blocks,
                  transition,
                  hasWhiteLabelLoaded,
                  isTransitioning,
                  isBlockingLoad,
                  setIsBlockingLoad,
                  clearTransition,
                }}
              >
                <UserDataContext.Provider
                  value={{
                    user,
                    error,
                    route,
                    hasAttemptedLogin,
                    isLoggingIn,
                    loading,
                    isLoggedIn,
                    isInitialized,
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '(variables?: Partial<OperationVariables> | u... Remove this comment to see the full error message
                    refetch,
                    hasAnyPermission,
                  }}
                >
                  <GoogleRecaptchaProvider>
                    <MuiThemeProvider theme={MuiTheme}>
                      {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ logo: string | undefined; name: string; in... Remove this comment to see the full error message */}
                      <ThemeContext.Provider value={theme}>
                        <TerraThemeProvider
                          primaryColor={primaryColor}
                          secondaryColor={secondaryColor}
                          interactivePaletteId="secondary"
                          setCSSVariables
                        >
                          <ChannelsContext.Provider
                            // @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelsContextType | { readonly channels: r... Remove this comment to see the full error message
                            value={user ? channelsData : anonChannelsData}
                          >
                            <SignUpContextProvider>
                              <Favicon />
                              <BlockingLoad />
                              {withRouter ? (
                                <Router history={history}>{children}</Router>
                              ) : (
                                <>{children}</>
                              )}
                            </SignUpContextProvider>
                          </ChannelsContext.Provider>
                        </TerraThemeProvider>
                      </ThemeContext.Provider>
                    </MuiThemeProvider>
                    <AlertModal ref={setAlertRef} />
                    <Toast ref={setToastRef} />
                  </GoogleRecaptchaProvider>
                </UserDataContext.Provider>
              </AppContext.Provider>
            </LocationProvider>
          </RendererProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

import { errorCodes } from 'activate-errors';
import { getDisplayName, getMessagesFromError } from 'lane-shared/helpers';
import i18n from 'localization';
import { ChannelType } from 'lane-shared/types/ChannelType';

export function getLocalizedJoinChannelError(
  error: unknown,
  channel: Pick<ChannelType, 'name' | 'profile'> | null | undefined
) {
  const messages = getMessagesFromError(error);
  const channelName = getDisplayName(channel);

  if (
    messages.some(message =>
      message.includes(errorCodes.unverifiedEmailError.code)
    )
  ) {
    return i18n.t('Please verify your email to join {{channelName}}', {
      channelName,
    });
  }

  return messages;
}

import React from 'react';

import { TemplateLibrary, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import getDisplayName from 'lane-shared/helpers/getDisplayName';

import styles from './styles.scss';
import { LibraryTypeEnum } from 'lane-shared/types/libraries';

export default function MyTemplates({ user, history }: any) {
  const { t } = useTranslation();

  return (
    <div className={styles.MyTemplates}>
      <TemplateLibrary
        className={styles.templateLibrary}
        onTemplateSelected={(template: any) =>
          history.push(`${history.location.pathname}/${template._id}/edit`)
        }
        library={{
          type: LibraryTypeEnum.User,
          _id: user._id,
          name: getDisplayName(user),
        }}
        additionalMenu={
          <Link to={routes.meTemplateNew}>
            <Button>{t('Add Template')}</Button>
          </Link>
        }
        TemplateWrapper={({ template, children }: any) => (
          <Link
            className={styles.templateWrapper}
            to={`/l/me/templates/${template._id}/edit`}
          >
            {children}
          </Link>
        )}
      />
    </div>
  );
}

import React, { useState } from 'react';

import Papa from 'papaparse';
import { generatePath } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';

import { PropertiesDataTable } from 'components/builder';
import { Input, TextArea, FileInput } from 'components/form';
import { Button, ErrorMessage } from 'components/general';
import { Grid, Col, BreadCrumbs } from 'components/lds';
import { H4, Text } from 'components/typography';

import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { constructDatasetSchemaPropertiesAsLongText } from 'hooks/useDatasetSchema';

import styles from './NewDatasetSchema.scss';

type Field = {
  _id: string;
  name: string;
  type: string;
};

type Row = {
  _id: string;
  [key: string]: string;
};

const NUMBER_OF_EXAMPLE_ROWS = [1, 2, 4, 5, 6, 7];

const exampleFields: Field[] = NUMBER_OF_EXAMPLE_ROWS.map(id => ({
  _id: String(id),
  name: `Ref #${id}`,
  type: 'String',
}));

const exampleRows: Row[] = NUMBER_OF_EXAMPLE_ROWS.map(id => ({
  _id: String(id),
  [`Ref #${id}`]: '',
}));

const createDatasetSchemaMutation = gql`
  mutation CreateDatasetSchema($libraryItem: LibraryItemInput!) {
    createDatasetSchema(libraryItem: $libraryItem) {
      _id
      name
    }
  }
`;

export default function NewDatabase() {
  const { channel } = useChannelAdminContext();
  const [databaseName, setDatabaseName] = useState('');
  const [databaseDescription, setDatabaseDescription] = useState('');
  const [fields, setFields] = useState<Field[]>(exampleFields);
  const [rows, setRows] = useState<Row[]>(exampleRows);
  const [errorMessage, setErrorMessage] = useState('');
  const [createDatasetSchema] = useMutation(createDatasetSchemaMutation);
  const history = useHistory();
  const { id: channelIdOrSlug } = useParams<{ id: string }>();

  async function handleCreateDatabase() {
    try {
      const wasUpdated = JSON.stringify(rows) !== JSON.stringify(exampleRows);

      const properties = wasUpdated
        ? constructDatasetSchemaPropertiesAsLongText(
            fields.map(field => field.name)
          )
        : {};

      const datasetRecords = wasUpdated
        ? rows.map(record => {
            // remove _id since it will be changed
            const { _id, ...rest } = record;
            return { value: rest };
          })
        : [];

      const { data } = await createDatasetSchema({
        variables: {
          libraryItem: {
            channel: { _id: channel?._id },
            datasetSchema: {
              name: databaseName,
              description: databaseDescription,
              properties,
              datasetRecords,
            },
          },
        },
      });
      setErrorMessage(data.errors?.toString());
      const newDatasetSchemaId = data.createDatasetSchema._id;
      const libraryRoute = generatePath(routes.channelAdminDatasetLibrary, {
        id: channelIdOrSlug,
        datasetSchemaId: newDatasetSchemaId,
      });
      history.push(libraryRoute);
    } catch (err) {
      setErrorMessage(err.toString());
    }
  }

  async function onCsvUpload(csv: FileReturnType, fileName: string) {
    try {
      await window.Alert.confirm({
        title: 'Import data from CSV',
        message: `You are importing this csv file into this database: ${fileName} `,
        confirmText: 'Import',
      });
    } catch (err) {
      return;
    }

    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const parsed = Papa.parse(csv, {
      header: true,
      skipEmptyLines: 'greedy',
    });

    // TODO: validate parsed data
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
    const fields = (parsed as any).meta.fields.map((field, index) => ({
      _id: index,
      name: field,
      type: 'String',
    }));

    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    const rows = (parsed as any).data.map((item, index) => ({
      _id: index,
      ...item,
    }));

    setFields(fields);
    setRows(rows);
  }

  return (
    <Grid className={styles.container}>
      <Col columns={[1, -1]}>
        <BreadCrumbs
          links={[
            {
              label: 'Data Library',
              url: generatePath(routes.channelAdminLibrary, {
                id: channelIdOrSlug,
              }),
            },
            { label: 'New Database' },
          ]}
        />
        <Button
          variant="contained"
          className={styles.createButton}
          onClick={handleCreateDatabase}
        >
          Create
        </Button>
        <div className={styles.headerContainer}>
          <H4 v2 mb={4}>
            New Data
          </H4>
          <Text>Give this database a name, and add data into the table.</Text>
        </div>
        <ErrorMessage error={errorMessage} />
      </Col>
      <Col columns={[1, 9]} className={styles.formContainer}>
        <div className={styles.inputs}>
          <Input
            label="Database name"
            className={styles.databaseNameInput}
            value={databaseName}
            onChange={setDatabaseName}
          />
          <TextArea
            label="Database description (optional)"
            className={styles.databaseDescription}
            maxLength={400}
            showLengthIndicator
            value={databaseDescription}
            onChange={setDatabaseDescription}
          />
        </div>
      </Col>
      <Col className={styles.seperator} columns={[9, 9]} />
      <Col columns={[10, -1]} className={styles.importButtons}>
        <FileInput
          accept=".csv"
          type={FileReturnTypeEnum.Text}
          onFileSelected={onCsvUpload}
        >
          <Button className={styles.importButton}>Import from CSV</Button>
        </FileInput>
      </Col>
      <Col columns={[1, -1]} className={styles.tableContainer}>
        {fields.length > 0 && (
          <PropertiesDataTable fields={fields} items={rows} />
        )}
      </Col>
    </Grid>
  );
}

import { useEffect, useMemo, useState } from 'react';

import { getClient } from 'lane-shared/apollo';
import isRegionLocationFilter from 'lane-shared/helpers/integrations/ShopifyBurst/isRegionLocationFilter';
import searchSectionContentQuery from 'lane-shared/hooks/useSectionQuery/searchSectionContentQuery';
import { ContentType } from 'lane-shared/types/content/Content';
import { PresentContentFilterTimeEnum } from 'lane-shared/types/filters/PresentContentFilterTimeEnum';
import { PresetContentFilter } from 'lane-shared/types/filters/PresetContentFilter';

import { MetatagFilter } from '../../../../types/filters/MetatagFilter';
import type { LocationValue, RegionValue } from './types';

type SectionContent = {
  _id: string;
  content: ContentType;
};

type Props = {
  search: string;
  channelId?: string;
  shopifyParentChannelId?: string;
  roomBookingSectionId?: string;
  dates: {
    startDate: string | undefined;
    endDate: string | undefined;
    minDate: Date;
  };
  locationFilters: (LocationValue | RegionValue)[];
  regionMetatag?: {
    _id: string;
    metatagId: string;
    metatagType: string;
    valueType: string;
  };
};

export default function useBatchRoomBookingSection({
  search,
  channelId,
  roomBookingSectionId,
  dates,
  locationFilters,
  shopifyParentChannelId,
  regionMetatag,
}: Props) {
  const [error, setError] = useState<any>();
  const [isBatchLoading, setBatchLoading] = useState(false);

  const [sectionContent, setSectionContent] = useState<SectionContent[]>([]);

  const initialSearch = useMemo(() => {
    return {
      search,
      areFiltersApplied: true,
      channelId: shopifyParentChannelId,

      filters: [
        {
          type: PresetContentFilter.FeatureReservableAvailableDays,
          filter: {
            startDate: dates.startDate,
            endDate: dates.endDate,
            enabled: dates.endDate
              ? PresentContentFilterTimeEnum.Enabled
              : PresentContentFilterTimeEnum.AnyTime,
            useContentTimeZone: true,
          },
        },
      ],
      metatagFilters: [] as MetatagFilter[],
    };
  }, [dates, shopifyParentChannelId, search]);

  useEffect(() => {
    async function fetchSectionContent(variables: {
      id?: string;
      searchOptions: {
        search: string;
        areFiltersApplied: boolean;
        channelId?: string;
        filters: {
          type: PresetContentFilter;
          filter: {
            startDate: string | undefined;
            endDate: string | undefined;
            enabled: PresentContentFilterTimeEnum;
          };
        }[];
        metatagFilters: MetatagFilter[];
      };
    }) {
      return await getClient().query({
        query: searchSectionContentQuery,
        fetchPolicy: 'network-only',
        variables,
      });
    }
    function extractIds(filters: (LocationValue | RegionValue)[]) {
      const regionIds = filters
        .filter(isRegionLocationFilter)
        .map(({ _id }) => _id);
      const locationIds = filters
        .filter(
          filter =>
            !isRegionLocationFilter(filter) &&
            !regionIds.includes(filter?.region)
        )
        .map(({ _id }) => _id);

      return {
        locationIds,
        regionIds,
      };
    }
    function fetchLocation(id: string) {
      return fetchSectionContent({
        id: roomBookingSectionId!,
        searchOptions: {
          ...initialSearch,
          channelId: id,
        },
      });
    }
    function fetchRegion(id: string) {
      return fetchSectionContent({
        id: roomBookingSectionId,
        searchOptions: {
          ...initialSearch,
          metatagFilters: [{ ...regionMetatag!, value: { _id: id } }],
        },
      });
    }
    async function fetchSections() {
      try {
        setError(undefined);
        setBatchLoading(true);
        if (!locationFilters.length) {
          const result = await fetchSectionContent({
            id: roomBookingSectionId,
            searchOptions: {
              ...initialSearch,
              channelId: channelId || shopifyParentChannelId,
            },
          });
          if (result?.data?.section?.sectionContent) {
            setSectionContent(result?.data?.section?.sectionContent);
          }
          return;
        }
        const { regionIds, locationIds } = extractIds(locationFilters);

        const fetchedSections = await Promise.all([
          ...locationIds.map(fetchLocation),
          ...regionIds.map(fetchRegion),
        ]);

        const sectionContent: SectionContent[] = [];
        fetchedSections.forEach(({ data }) => {
          if (data?.section?.sectionContent) {
            sectionContent.push(...data?.section?.sectionContent);
          }
        });
        setSectionContent(sectionContent);
      } catch (err) {
        setError(err);
      } finally {
        setBatchLoading(false);
      }
    }
    // If region metatag is present this means that you cat proceed further
    if (regionMetatag?._id) {
      fetchSections();
    }
  }, [
    dates,
    locationFilters,
    roomBookingSectionId,
    search,
    channelId,
    shopifyParentChannelId,
    initialSearch,
    regionMetatag,
  ]);

  return {
    sectionContent,
    loading: isBatchLoading,
    error,
  };
}

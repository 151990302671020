import React from 'react';

import cx from 'classnames';

import RichTextHtmlRenderer from 'lane-shared/components/RichTextRenderer';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';

import styles from './RichTextBlock.scss';

type BlockProps = WebBlockProps & { text: any };

export default function RichTextBlock({
  style,
  className,
  text,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);

  return (
    <div
      className={cx(styles.RichTextBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <RichTextHtmlRenderer value={text} />
    </div>
  );
}

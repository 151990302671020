import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import routes from 'lane-shared/config/routes';
import {
  CHANNEL_DIRECTORY_TYPE,
  CHANNEL_DIRECTORY_LIST_VIEW,
} from 'lane-shared/helpers/constants/channel';

import {
  useChannelSelector,
  useChannelsByRelationshipQuery,
} from 'lane-shared/hooks';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { FlexDirectionEnum } from 'lane-shared/types/blocks/Flex';

import Input from 'components/form/Input';
import ChannelSelectorDropdownLegacy from 'components/lane/ChannelSelectorDropdownLegacy';
import DirectoryListView from 'components/lane/DirectoryListView';
import DirectoryThumbnail from 'components/lane/DirectoryThumbnail';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styleMap from '../styleMap';
import ListHeader from './components/ListHeader';

import styles from './DirectoryBlock.scss';

const RetailTypes = [
  ChannelTypeEnum.Retail,
  ChannelTypeEnum.Restaurant,
  ChannelTypeEnum.Service,
  ChannelTypeEnum.Entertainment,
];

const OfficeTypes = [ChannelTypeEnum.Company, ChannelTypeEnum.Professional];

type BlockProps = WebBlockProps & {
  directory: string;
  filter: string;
  perPage: number;
  filterByChannel: boolean;
  showSearch: boolean;
  flexDirection: 'row' | 'column';
  listView: CHANNEL_DIRECTORY_LIST_VIEW;
  header: string;
  autoHide: boolean;
  showViewAll: boolean;
  channel: ChannelType;
};

export default function DirectoryBlock({
  directory,
  filter,
  perPage,
  filterByChannel,
  showSearch,
  flexDirection,
  listView,
  header,
  autoHide,
  showViewAll,
  channel,
  theme,
  style,
  className,
  ...otherProps
}: BlockProps) {
  const defaultTypes: ChannelTypeEnum[] =
    directory === CHANNEL_DIRECTORY_TYPE.Retail ? RetailTypes : OfficeTypes;

  const [searchInput, setSearchInput] = useState('');
  const [searchTypes, setSearchTypes] = useState<ChannelTypeEnum[]>(
    defaultTypes
  );
  const props = useEditModeProps(otherProps);

  const channelId = channel?._id;

  const {
    channels,
    called: channelsCalled,
    loading: channelsLoading,
    selectedChannelId,
    setSelectedChannelId,
  } = useChannelSelector({ channelId, skip: !filterByChannel });

  const { items, loading, isHidden, pageInfo } = useChannelsByRelationshipQuery(
    {
      skip: filterByChannel && (!channelsCalled || channelsLoading),
      perPage,
      autoHide,
      channel: {
        sortBy: { key: 'name', dir: 'asc' },
        // if nothing selected, pass in the default types
        type: { any: searchTypes.length === 0 ? defaultTypes : searchTypes },
        name: {
          type: 'like',
          value: searchInput,
        },
      },
      relatedTo: { _id: selectedChannelId || channelId },
    }
  );

  const { t } = useTranslation();
  const [showTags, setShowTags] = useState(false);

  function toggleType(type: any) {
    setSearchTypes(
      searchTypes.includes(type)
        ? searchTypes.filter(t => t !== type)
        : [...searchTypes, type]
    );
  }

  function renderItem(item: any) {
    switch (listView) {
      case CHANNEL_DIRECTORY_LIST_VIEW.Thumbnail:
        return (
          <DirectoryThumbnail
            channel={item}
            className={styles.directoryThumbView}
          />
        );
      case CHANNEL_DIRECTORY_LIST_VIEW.List:
      default:
        return (
          <DirectoryListView
            channel={item}
            className={styles.directoryListView}
          />
        );
    }
  }

  // don't auto-hide is search is present.
  const hasSearch = filter || showSearch || filterByChannel;

  function renderSearch() {
    return (
      <Input
        className={styles.directoryInput}
        value={searchInput}
        icon="search"
        onChange={search => setSearchInput(search)}
        showClear
      />
    );
  }

  function renderChannelSelector() {
    return (
      <ChannelSelectorDropdownLegacy
        channelId={selectedChannelId || channelId}
        channels={channels}
        onChannelSelected={channel => setSelectedChannelId(channel._id)}
        showAllLocations
        onAllLocationsSelected={() => setSelectedChannelId(channelId)}
      />
    );
  }

  if (!hasSearch && !props['data-is-edit-mode'] && autoHide && isHidden) {
    return null;
  }

  return (
    <div
      className={cx(className, styles.DirectoryBlock)}
      style={styleMap(false, style)}
      {...props}
    >
      {header && (
        <ListHeader
          theme={theme}
          header={header}
          totalItems={pageInfo?.total}
          currentItems={items.length}
          link={(directory === CHANNEL_DIRECTORY_TYPE.Retail
            ? routes.channelRetail
            : routes.channelOffice
          ).replace(':id', channelId)}
          viewAll={showViewAll}
        />
      )}

      {filterByChannel && showSearch && (
        <div className={styles.inputWrapper}>{renderChannelSelector()}</div>
      )}

      {!filter && showSearch && (
        <div className={styles.inputWrapper}>{renderSearch()}</div>
      )}

      {filter && (
        <>
          <div className={styles.inputWrapper}>
            {!showSearch && filterByChannel && renderChannelSelector()}

            {showSearch && renderSearch()}
            <button
              className={styles.iconButton}
              data-is-selected={showTags}
              onClick={() => setShowTags(!showTags)}
            >
              <Icon name="bars" className={styles.icon} />
            </button>
          </div>
          {showTags && (
            <div className={styles.inputWrapper}>
              {defaultTypes.map(type => (
                <button
                  key={type}
                  onClick={() => toggleType(type)}
                  className={styles.tag}
                  data-is-selected={searchTypes.includes(type)}
                >
                  {t(type)}
                </button>
              ))}
            </div>
          )}
        </>
      )}
      <div className={styles.fullWidth}>
        <div
          className={cx(
            flexDirection === FlexDirectionEnum.Horizontal
              ? styles.row
              : styles.column,
            styles.channelDirectory
          )}
        >
          {items.map(relationship => (
            <Link
              className={styles.channelLink}
              to={`/l/channel/${relationship.channel.slug}`}
              key={relationship.channel._id}
            >
              {renderItem(relationship.channel)}
            </Link>
          ))}
          {!loading && items?.length === 0 && (
            <div className={styles.noResults}>
              {t(
                'web.admin.channel.content.layout.editor.components.directoryBlock.noResults'
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.wrapper} />
    </div>
  );
}

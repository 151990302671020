import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './EventDatePickerDropdown.scss';
import { Button } from 'design-system-web/components/Button/Button';
import { Icon } from 'design-system-web/components/Icon';
import { Flex, Popup } from 'design-system-web';
import { PresetContentFilterEventDate } from 'lane-shared/types/filters/PresetContentFilterEventDate';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import DateRangePicker from '../../domains/visitorManagement/components/DatePickers/DateRangePicker';
import { formatDateRange } from 'lane-shared/domains/visitorManagement/helpers/formatDateRange';

type Props = {
  wrapperClassName?: string;
  eventDateSelector: PresetContentFilterEventDate;
  onEventDateSelected: (dateRange?: DateRangeType) => void;
};

export const EventDatePickerDropdown = ({
  wrapperClassName,
  eventDateSelector,
  onEventDateSelected,
}: Props) => {
  const { t } = useTranslation();

  return (
    <span className={cx(styles.wrapper, wrapperClassName)}>
      <Popup
        placement="bottom"
        trigger={({ isOpen, ...referenceProps }) => (
          <div
            data-open={isOpen}
            role="button"
            tabIndex={0}
            {...referenceProps}
          >
            <Button
              endIcon={<Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />}
              startIcon={<Icon name="Calendar" />}
              variant="secondary"
              size="medium"
            >
              {eventDateSelector.startDate && eventDateSelector.endDate ? (
                <>
                  {formatDateRange(
                    eventDateSelector.startDate,
                    eventDateSelector.endDate
                  )}
                </>
              ) : (
                t('web.components.eventDatePickerDropdown.displayText')
              )}
            </Button>
          </div>
        )}
        listNavigation
        zIndex={5}
      >
        {({ close }) => (
          <Flex>
            <DateRangePicker
              startDate={eventDateSelector.startDate || undefined}
              endDate={eventDateSelector.endDate || undefined}
              className={styles.dateRangePicker}
              onChange={onEventDateSelected}
              onSubmit={(dateRange?: DateRangeType) => {
                onEventDateSelected(dateRange);
                close();
              }}
              onClear={() => {
                onEventDateSelected(undefined);
                close();
              }}
              minDate={new Date()}
            />
          </Flex>
        )}
      </Popup>
    </span>
  );
};

import { StyleSheet } from '@react-pdf/renderer';

import { spacing, colors } from 'lane-shared/config';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    maxWidth: '100vw',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: spacing[2],
  },
  line: {
    marginTop: 20,
  },
  scheduleHeader: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 12,
    fontWeight: 'ultrabold',
    margin: 8,
    marginBottom: 4,
  },
  subHeader: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 'bold',
    color: colors.gray,
    marginRight: 8,
    marginLeft: 8,
    marginTop: 4,
    marginBottom: 0,
  },
  sectionHeader: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 11,
    fontWeight: 'ultrabold',
    margin: 8,
  },
  dualView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },
  infoText: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontSize: 10,
    fontWeight: 'bold',
    marginRight: 8,
    marginLeft: 8,
    marginTop: 2,
    marginBottom: 2,
  },
  equipmentSection: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    padding: 8,
    margin: 8,
    width: '80%',
  },
  stepSection: {
    flexDirection: 'row',
    width: '80%',
  },
  stepIndex: {
    marginRight: 8,
    width: '10%',
  },
  footer: {
    position: 'absolute',
    fontSize: 10,
    fontStyle: 'italic',
    bottom: 5,
    left: 0,
    right: 25,
    textAlign: 'right',
    color: 'grey',
  },
});

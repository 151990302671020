import React from 'react';

import { useTranslation } from 'react-i18next';

import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import {
  BurstWizardState,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import BurstCustomPageRenderer from 'components/integrations/ShopifyBurst/pages/BurstCustomPageRenderer';
import UserSelectorButton from 'components/lane/UserSelectorButton';
import { Box } from 'components/layout';
import { H5 } from 'components/typography';

import { BurstCreatePageProps } from '../../components/BurstCreateFlow';

import styles from './BurstName.scss';

export default function BurstName({
  wizardState,
  content,
  onDataUpdated,
  goToNextStep,
  goToPrevStep,
  ...rest
}: BurstCreatePageProps) {
  const { t } = useTranslation();
  const {
    setInteractionUser,
    newBurstInteraction,
    hasSubmitOnBehalfOfPermission,
  } = useShopifyBurstContext();

  function interactionToWizardStateAdapter(interaction: any) {
    return {
      // we expect the custom page for burst name step to have burstName field
      // with text type which is used as a name of the burst
      name: interaction.data?.burstName,
    };
  }

  function wizardStateToInteractionAdapter(
    updatedWizardState: BurstWizardState
  ) {
    return {
      data: {
        // we expect the custom page for burst name step to have burstName field
        // with text type which is used as a name of the burst
        burstName: updatedWizardState.data.name,
      },
    };
  }

  function updateUser(
    user: {
      _id: string;
      name: string;
      profile: { _id: string; name: string };
    } | null
  ) {
    setInteractionUser(user);
  }

  return (
    <BurstCustomPageRenderer
      title={t(ShopifyBurstPageTitle.BurstName)}
      wizardState={wizardState}
      content={content}
      goToPrevStep={goToPrevStep}
      goToNextStep={goToNextStep}
      onDataUpdated={onDataUpdated}
      interactionToWizardStateAdapter={interactionToWizardStateAdapter}
      wizardStateToInteractionAdapter={wizardStateToInteractionAdapter}
      contentKey="burstNameContent"
      headerComponent={
        hasSubmitOnBehalfOfPermission ? (
          <Box className={styles.fullWidth}>
            <Box className={styles.submitOnBehalfOf} mb={6}>
              <H5 mb={2}>{t('Create burst on someones behalf')}</H5>
              <UserSelectorButton
                className={[styles.submitOnBehalfOfButton, styles.input]}
                channelId={content?.channel?._id}
                userId={(newBurstInteraction as any)?.user?._id}
                onUserSelected={updateUser}
                includeWorkplaceMember
              />
            </Box>
          </Box>
        ) : null
      }
      {...rest}
    />
  );
}

import { useEffect, useCallback } from 'react';

import { useCreateInteraction } from 'lane-shared/hooks';
import {
  BurstWizardState,
  ShopifyBurstCustomPageInteractionKeys,
  ShopifyBurstInteractionType,
} from 'lane-shared/types/integrations/ShopifyBurst';

import StrictUnion from '../types/StrictUnion';
import { ContentType } from '../types/content/Content';

type BurstPageProps = {
  content?: ContentType;
  wizardState: BurstWizardState;
  onDataUpdated: (data: Partial<ShopifyBurstInteractionType>) => void;
  goToNextStep: () => void;
} & StrictUnion<
  | {
      interactionKey: ShopifyBurstCustomPageInteractionKeys;
    }
  | {
      wizardStateToInteractionAdapter: (wizardState: BurstWizardState) => any;
      interactionToWizardStateAdapter: (
        interaction: any
      ) => Partial<ShopifyBurstInteractionType>;
    }
>;

const SHOULD_THROW = false;

export default function useBurstCustomPage({
  content,
  wizardState,
  onDataUpdated,
  goToNextStep,
  // without this object spread union types will not work properly
  ...rest
}: BurstPageProps) {
  const {
    interaction,
    submitting,
    validation,
    submitAttempted,
    updateInteraction,
    validate,
    setSubmitAttempted,
    validateAdditional,
    preventSubmission,
  } = useCreateInteraction({ content });

  const interactionUpdatedHandler = useCallback(
    (update: any) => updateInteraction(update),
    [updateInteraction]
  );

  useEffect(() => {
    if (rest.interactionKey) {
      if (wizardState.data[rest.interactionKey]) {
        updateInteraction(wizardState.data[rest.interactionKey]);
      }
    } else {
      updateInteraction(rest.wizardStateToInteractionAdapter(wizardState));
    }
    // here we base the hook on contentId because useCreateInteraction resets interaction data
    // based on contentId and since some old bursts may not have contentId inside of their schema
    // we may have a gap before the first renderer and the time when content is downloaded which
    // leads to interaction data being reset after the content is downloaded and this contentId
    // dependency forces the interaction data to contain actual data, not the reset one
  }, [content?._id]);

  useEffect(() => {
    if (rest.interactionKey) {
      onDataUpdated({
        [rest.interactionKey]: interaction,
      });
    } else {
      onDataUpdated(rest.interactionToWizardStateAdapter(interaction));
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [interaction]);

  const onNextStep = useCallback(async () => {
    setSubmitAttempted(true);

    if (!(await validate(SHOULD_THROW))) {
      return;
    }

    if (rest.interactionKey) {
      onDataUpdated({
        [rest.interactionKey]: interaction,
      });
    } else {
      onDataUpdated(rest.interactionToWizardStateAdapter(interaction));
    }

    goToNextStep();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [onDataUpdated, goToNextStep, interaction, validate]);

  return {
    onNextStep,
    interactionUpdatedHandler,
    validateAdditional,
    interaction,
    validation,
    submitAttempted,
    submitting,
    preventSubmission,
  };
}

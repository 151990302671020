import React, { useState } from 'react';

import { Radio } from 'components';
import { useTranslation } from 'react-i18next';

import { M, DatePickerButton } from 'design-system-web';

import styles from './styles.scss';
import { InvoiceType } from 'graphql-query-contracts';

const CHARGE_TYPE_FIELD = 'invoiceType';
const INVOICE_DUE_DATE_FIELD = 'invoiceDueDate';

export function ChargeIssueOptions({
  updateChargeDetails,
}: {
  updateChargeDetails: (value: InvoiceType | Date, fieldname: string) => void;
}) {
  const { t } = useTranslation();
  const [selectedInvoiceOption, setSelectedInvoiceOption] = useState(
    InvoiceType.InvoiceTypeMonthly
  );
  const [selectedInvoiceDueDate, setSelectedInvoiceDueDate] = useState<
    Date | undefined
  >(undefined);

  const handleOptionChange = (value: string) => {
    updateChargeDetails(value as InvoiceType, CHARGE_TYPE_FIELD);
    setSelectedInvoiceOption(value as InvoiceType);
  };

  const handleDueDateChange = (newDate: Date) => {
    updateChargeDetails(newDate, INVOICE_DUE_DATE_FIELD);
    setSelectedInvoiceDueDate(newDate);
  };

  const isOneOffOptionSelected =
    selectedInvoiceOption === InvoiceType.InvoiceTypeOneoff;

  const minInvoiceDueDateIsToday = new Date();

  return (
    <div className={styles.chargeRadioButtons}>
      <M className={styles.invoiceOptionsHeading}>
        {t('abp.charges.invoiceOptions')}
      </M>
      <Radio
        text="abp.charges.chargeAddToMonthlyInvoice"
        name={CHARGE_TYPE_FIELD}
        value={InvoiceType.InvoiceTypeMonthly}
        selected={selectedInvoiceOption}
        onChange={handleOptionChange}
        testId="AddToMonthlyInvoiceRadio"
      />
      <Radio
        className={
          !isOneOffOptionSelected && styles.chargeInvoiceDueDateFieldNotVisible
        }
        text="abp.charges.chargeCreateNewInvoice"
        name={CHARGE_TYPE_FIELD}
        value={InvoiceType.InvoiceTypeOneoff}
        selected={selectedInvoiceOption}
        onChange={handleOptionChange}
        testId="OneOffInvoiceRadio"
      />
      {isOneOffOptionSelected && (
        <DatePickerButton
          className={styles.chargeInvoiceDueDateField}
          dateInputLabel="abp.charges.chargeInvoiceDueDateLabel"
          value={selectedInvoiceDueDate}
          onChange={handleDueDateChange}
          hideOnSelect
          minDate={minInvoiceDueDateIsToday}
        />
      )}
    </div>
  );
}

import React from 'react';

import { useChannelAdminContext } from 'hooks';
import { Link, useLocation } from 'react-router-dom';

import { getDisplayName } from 'lane-shared/helpers';

import Loading from 'components/general/Loading';
import WebhookLibrary from 'components/webhooks/WebhookLibrary';

import styles from './ChannelWebhookLibrary.scss';

export default function ChannelWebhookLibrary() {
  const location = useLocation();
  const { channel } = useChannelAdminContext();

  if (!channel) {
    return <Loading />;
  }

  return (
    <div className={styles.Templates}>
      <WebhookLibrary
        library={{
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"Channel"' is not assignable to type 'Librar... Remove this comment to see the full error message
          type: 'Channel',
          _id: channel?._id,
          name: getDisplayName(channel),
        }}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'webhook' does not exist on type '{ child... Remove this comment to see the full error message
        ItemWrapper={({ webhook, children }) => (
          <Link
            to={{
              pathname: `webhooks/${webhook._id}/edit`,
              state: { from: location.pathname },
            }}
          >
            {children}
          </Link>
        )}
      />
    </div>
  );
}

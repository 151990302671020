import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { BRAND_LOGO } from 'lane-shared/helpers/constants/cards';
import { capitalize } from 'lane-shared/helpers/formatters';

import styles from './CreditCard.scss';

type CreditCardProps = {
  className?: string;
  style?: React.CSSProperties;
  last4: string;
  name?: string;
  brand: 'American Express' | 'Amex' | 'Visa' | 'MasterCard';
  expMonth: string | number;
  expYear: string | number;
  children: React.ReactNode;
};

export default function CreditCard({
  className,
  style,
  last4,
  name,
  brand,
  expMonth,
  expYear,
  children,
}: CreditCardProps) {
  const { t } = useTranslation();
  // BRAND_LOGO keys are capitalized but stripe paymentMethod brand keys are lowerCase
  const formattedBrand = capitalize(brand) as
    | 'American Express'
    | 'Amex'
    | 'Visa'
    | 'MasterCard';

  return (
    <div className={cx(styles.CreditCard, className)} style={style}>
      <div className={styles.brandInfo}>
        {BRAND_LOGO[formattedBrand] && (
          <img
            alt={formattedBrand}
            src={BRAND_LOGO[formattedBrand]}
            className={styles.logo}
          />
        )}
        {!BRAND_LOGO[formattedBrand] && (
          <p className={styles.cardText}>{formattedBrand}</p>
        )}
      </div>
      <div className={styles.cardInfo}>
        <p className={styles.cardText}>
          {`${t('abp.payment.ending')} ${last4}`}
        </p>
        {expMonth && (
          <p className={styles.expireText}>
            {`${t('abp.payment.exp')} ${expMonth}/${expYear}`}
          </p>
        )}
        {name && <p className={styles.name}>{name}</p>}
      </div>
      {children}
    </div>
  );
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { WizardStepProps } from 'lane-shared/hooks/useWizard';
import {
  BurstDraftInteractionData,
  ShopifyBurstPageTitle,
} from 'lane-shared/types/integrations/ShopifyBurst';

import BurstCustomPageRenderer from 'components/integrations/ShopifyBurst/pages/BurstCustomPageRenderer';

export default function BurstQuestionnaire({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
  ...rest
}: WizardStepProps<BurstDraftInteractionData>) {
  const { t } = useTranslation();
  // we convert v2 custom page values into v1 format fields for backward compatibility
  // with mobile app versions that only work with v1 format values
  function handleDataUpdated(data: any) {
    const questionnaireData = data.questionnaireInteraction.data;
    onDataUpdated({
      ...data,
      questionnaire: {
        agenda: questionnaireData.agenda,
        groupName: questionnaireData.groupName?.name,
        justification: questionnaireData.justification,
        purpose: questionnaireData.purpose,
        teamName: questionnaireData.teamName,
        teamRegion: questionnaireData.teamRegion?.name,
      },
    });
  }

  return (
    <BurstCustomPageRenderer
      interactionKey="questionnaireInteraction"
      title={t(ShopifyBurstPageTitle.BurstQuestionnaire)}
      wizardState={wizardState}
      content={content}
      goToPrevStep={goToPrevStep}
      goToNextStep={goToNextStep}
      onDataUpdated={handleDataUpdated}
      contentKey="questionnaireContent"
      {...rest}
    />
  );
}

import { z } from 'zod';

const maxDaysAhead = 366;
const maxTimeToComplete = 48;

export const scheduleSchema = z
  .object({
    title: z.string().nonempty('Required'),
    nextDueDate: z.date(),
    endDateType: z.string(),
    untilDate: z.date().optional(),
    repeats: z.string().optional(),
    daysAhead: z
      .number()
      .nonnegative()
      .max(maxDaysAhead, 'Schedule task should be created within 365 days'),
    weekday: z.number().nonnegative().optional(),
    weekNo: z.number().nonnegative().optional(),
    monthNo: z.number().nonnegative().optional(),
    interval: z.number().nonnegative().optional(),
    assignee: z.string().optional(),
    assigneeGroups: z.string().array().optional(),
    equipmentIds: z.string().array().optional(),
    notes: z.string().optional(),
    timeToComplete: z
      .number()
      .nonnegative()
      .max(maxTimeToComplete, 'Schedule should be completed within 48 hours'),
    completeWithin: z.number().nonnegative().optional(),
    steps: z.string().optional(),
    meterReading: z.string().optional(),
    extRefId: z.string(),
    location: z.string().optional(),
  })
  .refine(
    data =>
      !(data.equipmentIds && data.equipmentIds.length > 0 && data.location),
    {
      path: ['location', 'equipmentIds'],
      message: 'Value allowed for one of Location or Equipments but not both',
    }
  )
  .refine(data => !(data.endDateType === 'specific' && !data.untilDate), {
    message: 'Until date is required if end date is to be specified',
  })
  .transform(({ endDateType: _, ...rest }) => ({
    ...rest,
  }));

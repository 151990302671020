import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'b23e5c1f-32f1-4fd4-9a46-98786e229151',
  clientToken: 'puba4d1516b5143dab521b9a6b91912f7b9',
  site: 'datadoghq.com',
  service: 'lane-web-monitoring',
  env: `lane-web-${process.env.APP_ENV || 'local'}`,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});
if (
  typeof performance !== 'undefined' &&
  typeof performance.mark === 'function' &&
  typeof performance.measure === 'function'
)
  performance.mark('app-start');
datadogRum.startSessionReplayRecording();
datadogRum.addAction('app_start', { timestamp: Date.now() });

import React, { useState, useContext } from 'react';

import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { AppContext } from 'lane-shared/contexts';
import { StudioGroupRoleNameEnum } from 'lane-shared/types/Essensys';
import { validateResetPassword } from 'lane-shared/validation';

import { Checkbox } from 'components/form';
import { Button } from 'components/general';

import styles from './CompleteSignupForm.scss';

type Props = {
  email: string;
  name: string;
  clientTypeName: string;
  loading: boolean;
  // @ts-expect-error ts-migrate(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onSubmit: (...any) => any;
};

export default function CompleteSignupForm({
  email,
  name,
  clientTypeName,
  loading,
  onSubmit,
}: Props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [termsAndConditionsRead, setTermsAndConditionsRead] = useState(false);
  const [privacyPolicyRead, setPrivacyPolicyRead] = useState(false);
  const [validation, setValidation] = useState(null);

  const { whitelabel } = useContext(AppContext);

  const { t } = useTranslation();

  async function validate() {
    setValidation(null);

    if (!password || !confirmPassword) {
      return;
    }

    try {
      // After updating the state, see if its valid.
      await validateResetPassword.validate({
        password1: password,
        password2: confirmPassword,
      });
    } catch (validation) {
      setValidation(validation);
    }
  }

  // Don't show checkbox for these client types
  const showIsPublicCheckbox =
    clientTypeName !== StudioGroupRoleNameEnum.External &&
    clientTypeName !== StudioGroupRoleNameEnum.Tenant;

  return (
    <form
      onSubmit={e => {
        onSubmit(password, isPublic);
        e.preventDefault();
      }}
      className={styles.form}
    >
      <div className={styles.welcome}>
        <h2>{t('Welcome, {{- name}}!', { name })}</h2>
        <span className={styles.subtext}>
          {t('Fill out the fields below to set up your account')}
        </span>
      </div>
      <div className={styles.inputRow} style={{ marginBottom: '3em' }}>
        <Input
          className={styles.textInput}
          onChange={() => {}}
          fieldName="name"
          value={name || ''}
          label={t('Name')}
          disabled
        />
        <Input
          className={styles.textInput}
          onChange={() => {}}
          fieldName="email"
          value={email}
          label={t('Email')}
          disabled
        />
      </div>
      <span className={styles.subtext} style={{ marginBottom: '1.5em' }}>
        {t('Fill out the fields below to set your password')}
      </span>
      <div className={styles.inputRow} style={{ marginBottom: '3em' }}>
        <Input
          className={styles.textInput}
          onChange={p => setPassword(p)}
          onBlur={validate}
          onFocus={() => setValidation(null)}
          type="password"
          fieldName="password"
          placeholder={t('Password')}
          value={password}
          error={
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            validation && validation.path === 'password1'
              ? // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                validation.errors
              : null
          }
        />
        <Input
          className={styles.textInput}
          onChange={p => setConfirmPassword(p)}
          onBlur={validate}
          onFocus={() => setValidation(null)}
          type="password"
          fieldName="confirmPassword"
          placeholder={t('Confirm Password')}
          value={confirmPassword}
          error={
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            validation && validation.path === 'password2'
              ? // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                validation.errors
              : null
          }
        />
      </div>
      <div style={{ marginBottom: '4em' }}>
        {showIsPublicCheckbox && (
          // @ts-expect-error ts-migrate(2741) FIXME: Property 'value' is missing in type '{ name: strin... Remove this comment to see the full error message
          <Checkbox
            name="isPublic"
            text={t('Would you like to make your profile public?')}
            selected={isPublic}
            className={styles.checkbox}
            onChange={() => setIsPublic(prevIsPublic => !prevIsPublic)}
          />
        )}
        <Checkbox
          selected={termsAndConditionsRead}
          onChange={() => setTermsAndConditionsRead(!termsAndConditionsRead)}
          text={
            <span>
              {t('I have read and accept the')}{' '}
              <a
                href={whitelabel.termsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Terms and Conditions')}
              </a>
            </span>
          }
          className={styles.checkbox}
          value={termsAndConditionsRead}
        />

        <Checkbox
          selected={privacyPolicyRead}
          onChange={() => setPrivacyPolicyRead(!privacyPolicyRead)}
          text={
            <span>
              {t('I have read and accept the')}{' '}
              <a
                href={whitelabel.privacyPolicy}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Privacy Policy')}
              </a>
            </span>
          }
          className={styles.checkbox}
          value={privacyPolicyRead}
        />
      </div>
      <div className={styles.submitWrapper}>
        <Button
          className={styles.submit}
          variant="contained"
          type="submit"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '"primary"' is not assignable to type '"inher... Remove this comment to see the full error message
          color="primary"
          loading={loading}
          disabled={
            Boolean(validation) ||
            password.length === 0 ||
            confirmPassword.length === 0 ||
            !termsAndConditionsRead ||
            !privacyPolicyRead
          }
        >
          {t('Submit')}
        </Button>
      </div>
    </form>
  );
}

import React from 'react';

import { Thumbnail } from 'components';
import { Link } from 'react-router-dom';

import {
  ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK,
  ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK,
} from 'lane-shared/helpers/constants/analytics';
import { imageUrl } from 'lane-shared/helpers/formatters';

import styles from './styles.scss';

export const PER_SIZE = 25;
export const INITIAL_SEARCH_PARAMS = {
  page: 0,
  pageSize: PER_SIZE,
  total: 0,
  keyword: '',
};

export const LOGO = {
  key: 'logo',
  labelKey: 'web.admin.portalManagement.channelManagement.column.logo',
};

export const CHANNEL_NAME = {
  key: 'channelName',
  labelKey: 'web.admin.portalManagement.channelManagement.column.channelName',
};

export const PARENT_CHANNEL = {
  key: 'parentChannel',
  labelKey: 'web.admin.portalManagement.channelManagement.column.parentChannel',
};

export const CHANNEL_TYPE = {
  key: 'channelType',
  labelKey: 'web.admin.portalManagement.channelManagement.column.channelType',
};

export const LOCATION = {
  key: 'location',
  labelKey: 'web.admin.portalManagement.channelManagement.column.location',
};

export const OPTION_ITEMS = [
  {
    order: 1,
    label: LOGO.labelKey,
    value: LOGO.key,
    header: LOGO.labelKey,
    key: LOGO.key,
    isDefaultNonEditableColumn: false,
    isVisibleByDefault: true,
    disableSorting: true,
    renderCell: ({ image, logo, name }: any) =>
      logo && <Thumbnail src={imageUrl(logo || image)} name={name} />,
  },
  {
    order: 2,
    label: CHANNEL_NAME.labelKey,
    value: CHANNEL_NAME.key,
    header: CHANNEL_NAME.labelKey,
    key: CHANNEL_NAME.key,
    isDefaultNonEditableColumn: true,
    isVisibleByDefault: true,
    disableSorting: true,
    renderCell: ({
      channelName,
      channelSlug,
      captureAnalytics,
      channelId,
    }: any) => (
      <Link
        to={`/l/channel/${channelSlug}/admin`}
        onClick={() =>
          captureAnalytics(ANALYTIC_CHANNEL_MANAGEMENT_CHANNEL_LINK_CLICK, {
            clickedChannelName: channelName,
            clickedChannelId: channelId,
          })
        }
      >
        {channelName}
      </Link>
    ),
  },
  {
    order: 3,
    label: CHANNEL_TYPE.labelKey,
    value: CHANNEL_TYPE.key,
    header: CHANNEL_TYPE.labelKey,
    key: CHANNEL_TYPE.key,
    isDefaultNonEditableColumn: false,
    isVisibleByDefault: true,
    disableSorting: true,
  },
  {
    order: 4,
    label: PARENT_CHANNEL.labelKey,
    value: PARENT_CHANNEL.key,
    header: PARENT_CHANNEL.labelKey,
    key: PARENT_CHANNEL.key,
    isDefaultNonEditableColumn: false,
    isVisibleByDefault: true,
    disableSorting: true,
    renderCell: ({
      parentChannelName,
      parentChannelSlug,
      captureAnalytics,
      channelId,
    }: any) => (
      <Link
        to={`/l/channel/${parentChannelSlug}/admin`}
        onClick={() =>
          captureAnalytics(
            ANALYTIC_CHANNEL_MANAGEMENT_PARENT_CHANNEL_LINK_CLICK,
            {
              clickedChannelName: parentChannelName,
              clickedChannelId: channelId,
            }
          )
        }
      >
        {parentChannelName}
      </Link>
    ),
  },
  {
    order: 5,
    label: LOCATION.labelKey,
    value: LOCATION.key,
    header: LOCATION.labelKey,
    key: LOCATION.key,
    isDefaultNonEditableColumn: false,
    isVisibleByDefault: true,
    disableSorting: true,
    renderCell: (address: string) => (
      <div className={styles.addressContainer}>{address}</div>
    ),
  },
];

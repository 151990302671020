import React from 'react';

import { Flex, Line } from 'components';
import { useTranslation } from 'react-i18next';

import { H4 } from 'components/typography';
import {
  ProductItem,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';

import styles from './DraftItemsTable.scss';
import { ItemRow } from '../ItemRow/ItemRow';
import { SupportedLocaleEnum } from 'localization';

export function DraftItemsTable({
  items,
  currency,
  locale,
  addProductButton,
  updateDraftProductItem,
  deleteDraftProductItem,
}: {
  items: ProductItem[];
  currency: SupportedPaymentCurrency;
  locale: SupportedLocaleEnum;
  updateDraftProductItem: (index: number, updatedProduct: ProductItem) => void;
  deleteDraftProductItem: (index: number) => void;
  addProductButton?: React.ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <Flex className={styles.DraftItemsTable} direction="column" gap={5}>
      <H4>{t('abp.productsServices.table.title')}</H4>
      {items.length === 0 ? (
        <ItemRow currency={currency} locale={locale} isDraft />
      ) : (
        items.map((item, index) => (
          <ItemRow
            key={index}
            item={item}
            currency={currency}
            locale={locale}
            update={(updatedProduct: ProductItem) =>
              updateDraftProductItem(index, updatedProduct)
            }
            remove={() => deleteDraftProductItem(index)}
            isDraft
          />
        ))
      )}
      <Line className={styles.Divider} />
      {addProductButton}
    </Flex>
  );
}

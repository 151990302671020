import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';

import { getClient } from 'lane-shared/apollo';
import { getDraftContentHistory } from 'lane-shared/graphql/query';
import castGraphQLObject from 'lane-shared/helpers/castGraphQLObject';
import { fromNow } from 'lane-shared/helpers/formatters';

import Dropdown from '../../form/Dropdown';
import Button from '../../general/Button';

import styles from './DraftContentHistory.scss';

function DraftContentHistory({ onContentUpdated, content }: any) {
  const [selectedHistory, setSelectedHistory] = useState<unknown>(null);
  const [fetchHistory, { data, loading }] = useLazyQuery(
    getDraftContentHistory,
    {
      client: getClient(),
      fetchPolicy: 'network-only',
      variables: { id: content._id },
    }
  );

  function onRestore(history: any) {
    // we don't want all the data from history, just select fields

    const {
      name,
      name_l10n,
      description,
      description_l10n,
      tags,
      logo,
      color,
      backgroundImage,
      icon,
      iconSet,
      iconWeight,
      backgroundColor,
      liveDate,
      startDate,
      endDate,
      unpublishDate,
      renderer,
      block,
      properties,
      data,
      state,
      features,
      actions,
      notifications,
      placements,
      audiences,
      isEvent,
    } = castGraphQLObject(history);

    onContentUpdated({
      name,
      name_l10n,
      description,
      description_l10n,
      tags,
      logo,
      color,
      backgroundImage,
      icon,
      iconSet,
      iconWeight,
      backgroundColor,
      liveDate,
      startDate,
      endDate,
      unpublishDate,
      renderer,
      block,
      properties,
      data,
      state,
      features,
      actions,
      notifications,
      placements,
      audiences,
      isEvent,
    });
  }

  useEffect(() => {
    if (content?._id && !loading) {
      fetchHistory({
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; }' is not assignable ... Remove this comment to see the full error message
        id: content._id,
      });
    }
  }, [content?._id]);

  const items = data?.draftContentHistory?.items || [];

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.DraftContentHistory}>
      <Dropdown
        className={styles.dropDown}
        placeholder="Select version…"
        value={
          selectedHistory
            ? (selectedHistory as any)._id + (selectedHistory as any)._updated
            : null
        }
        items={(items as any[]).map((draftHistory, i) => ({
          label: `v${items.length - i} - ${fromNow(draftHistory._updated)}`,
          value: draftHistory._id + draftHistory._updated,
        }))}
        onValueChange={value =>
          setSelectedHistory(
            items.find((item: any) => item._id + item._updated === value)
          )
        }
      />

      <Button
        disabled={!selectedHistory}
        onClick={() => onRestore(selectedHistory)}
      >
        Restore
      </Button>
    </div>
  );
}

export default DraftContentHistory;

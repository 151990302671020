import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  useBillingPaymentSettingsForChannel,
  useListChargesQuery,
} from 'lane-shared/domains/billingPayments/hooks';
import {
  ExternalEntityType,
  currencyToPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { Flex, Loading } from 'components';
import { ChannelType } from 'lane-shared/types/ChannelType';
import useUserLocale from 'hooks/useUserLocale';

import { ChargeSummary } from '../ChargeManager/ChargeSummary/ChargeSummary';
import { ChargeItemsTable } from '../ChargeManager/ChargeItemsTable/ChargeItemsTable';
import { H4 } from 'design-system-web';
import styles from './index.scss';

type Props = {
  channel: ChannelType;
  externalEntityType: ExternalEntityType;
  externalEntityId?: string;
};
export function MemberChargeList({
  channel,
  externalEntityType = ExternalEntityType.EXTERNAL_ENTITY_TYPE_ACTIVATE_INVOICE,
  externalEntityId,
}: Props) {
  const { t } = useTranslation();
  const locale = useUserLocale();
  const { currency } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });

  const { charges, loading } = useListChargesQuery({
    channelId: channel._id,
    externalEntityType,
    externalEntityId,
  });

  if (loading) {
    return <Loading />;
  }

  if (!charges || charges.length === 0) return null;

  const paymentCurrency = currencyToPaymentCurrency(currency);

  return (
    <Flex gap={5} direction="column">
      <H4
        className={styles.Title}
      >{t`abp.charges.chargeManager.list.details.title`}</H4>
      <Flex direction="column" gap={5} width="full">
        {charges.map((charge, index) => (
          <Flex
            className={styles.Charge}
            gap={5}
            key={index}
            direction="column"
          >
            <ChargeItemsTable
              charge={charge}
              locale={locale}
              currency={paymentCurrency}
            />
            <ChargeSummary
              items={charge.items}
              locale={locale}
              currency={paymentCurrency}
              fullWidth
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

import React from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useMutation, isApolloError } from '@apollo/client';
import { unitErrorCodeTypes } from 'activate-errors';
import {
  removeTenant,
  RemoveTenantMutationResponse,
} from 'lane-shared/graphql/units';
import { getErrorCodes, getErrorMessagesFromCodes } from 'lane-shared/helpers';

const TITLE_TEXT_MAX_LENGTH = 20;

export function useRemoveTenant() {
  const { t } = useTranslation();

  const [removeTenantMutation] = useMutation<RemoveTenantMutationResponse>(
    removeTenant
  );

  const handleRemove = async ({
    id,
    unitId,
    name,
    unitName,
  }: {
    id: string;
    unitId: string;
    name: string;
    unitName: string;
  }): Promise<boolean> => {
    const truncatedTenantName =
      name.length > TITLE_TEXT_MAX_LENGTH
        ? `${name.substring(0, TITLE_TEXT_MAX_LENGTH)}...`
        : name;
    try {
      await window.Alert.confirm({
        title: t('web.pages.portal.admin.units.details.tenants.remove.title', {
          tenant: truncatedTenantName,
        }),
        message: (
          <Trans
            i18nKey="web.pages.portal.admin.units.details.tenants.remove.message"
            values={{ tenant: name, suite: unitName }}
          />
        ),
        confirmText: t(
          'web.pages.portal.admin.units.details.tenants.remove.confirmText'
        ),
        cancelText: t(
          'web.pages.portal.admin.units.details.tenants.remove.cancelText'
        ),
      });

      await removeTenantMutation({
        variables: {
          tenantId: id,
          unitId,
        },
      });

      window.Toast.show(
        t('web.pages.portal.admin.units.details.tenants.remove.successToast')
      );

      return true;
    } catch (err) {
      if (isApolloError(err)) {
        const codes = getErrorCodes(err);
        const errorMessage = getErrorMessagesFromCodes(
          codes,
          unitErrorCodeTypes,
          t('shared.units.errors.generic')
        );

        window.Toast.show(errorMessage);
      } else {
        console.error(`Action cancelled: ${err}`);
      }

      return false;
    }
  };

  return {
    handleRemove,
  };
}

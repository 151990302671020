import { ChannelExperienceTypeEnum } from 'lane-shared/types/ChannelType';

type SharedTranslationKeys = {
  listPage: {
    columns: {
      space: string;
    };
  };
  createPage: {
    form: {
      space: {
        label: string;
        placeholder: string;
        notFound: string;
      };
    };
  };
  detailsPage: {
    space: {
      label: string;
    };
    deleteTooltip: string;
  };
};

/**
 * Returns an object that includes the translation keys common to both Units and Suites.
 * This allows for the dynamic rendering of text tailored to either units or suites, based on the type of channel experience.
 * @param experienceType
 * @returns SharedTranslationKeys
 */
export const getSharedTranslationKeys = (
  experienceType: ChannelExperienceTypeEnum | null | undefined
) => {
  const mfExperienceKeys: SharedTranslationKeys = {
    listPage: {
      columns: {
        space: 'web.pages.portal.admin.floors.list.columns.units',
      },
    },
    createPage: {
      form: {
        space: {
          label: 'web.admin.channel.floors.create.form.units.label',
          placeholder: 'web.admin.channel.floors.create.form.units.placeholder',
          notFound: 'web.admin.channel.floors.create.form.units.noUnitsFound',
        },
      },
    },
    detailsPage: {
      space: {
        label: 'web.pages.portal.admin.floors.details.units',
      },
      deleteTooltip:
        'web.pages.portal.admin.floors.details.delete.tooltipMessage',
    },
  };

  const officeExperienceKeys: SharedTranslationKeys = {
    listPage: {
      columns: {
        space: 'web.pages.portal.admin.floors.list.columns.suites',
      },
    },
    createPage: {
      form: {
        space: {
          label: 'web.admin.channel.floors.create.form.suites.label',
          placeholder:
            'web.admin.channel.floors.create.form.suites.placeholder',
          notFound: 'web.admin.channel.floors.create.form.suites.noSuitesFound',
        },
      },
    },
    detailsPage: {
      space: {
        label: 'web.pages.portal.admin.floors.details.suites',
      },
      deleteTooltip:
        'web.pages.portal.admin.floors.details.delete.tooltipMessage.suites',
    },
  };

  return experienceType === ChannelExperienceTypeEnum.office
    ? officeExperienceKeys
    : mfExperienceKeys;
};

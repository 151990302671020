import React from 'react';

import { imageUrl } from 'lane-shared/helpers/formatters';
import addressFormatter from 'lane-shared/helpers/formatters/addressFormatter';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import { ChannelType } from 'lane-shared/types/ChannelType';

import CircleListView from 'components/lane/CircleListView';
import IconListView from 'components/lane/IconListView';
import { M } from 'components/typography';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  showName: boolean;
  channel: ChannelType;
  onClick: (channel: ChannelType) => void;
  showCompanyIcon?: boolean;
  companyIconStyle?: React.CSSProperties;
  companyIconClassName?: string;
};

const addressLine1Props = ['street1', 'street2', 'street3'];
const addressLine2Props = ['city', 'state', 'code'];

export function ChannelListItem({
  className,
  style,
  showName,
  channel,
  onClick = () => {},
  showCompanyIcon = false,
  companyIconStyle,
  companyIconClassName,
}: Props) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
  const address1 = addressFormatter(channel.address, addressLine1Props);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
  const address2 = addressFormatter(channel.address, addressLine2Props);

  const channelName = getDisplayProfileName(channel);
  const hasUniqueName = showName && channelName !== channel.address?.street1;

  return showCompanyIcon ? (
    <CircleListView
      image={imageUrl(channel?.profile?.image)}
      logo={imageUrl(channel?.profile?.logo)}
      className={companyIconClassName}
      style={companyIconStyle}
      onClick={() => onClick(channel)}
    >
      <M>
        {hasUniqueName && (
          <>
            {channelName}
            <br />
          </>
        )}
        {address1}
      </M>
    </CircleListView>
  ) : (
    <IconListView
      style={style}
      className={className}
      iconName={channel.inviteOnly ? 'lock' : 'map-marker-alt'}
      onClick={() => onClick(channel)}
    >
      <M>
        {hasUniqueName && (
          <>
            {channelName}
            <br />
          </>
        )}
        {address1}
        <br />
        {address2}
      </M>
    </IconListView>
  );
}

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { PublicUserFragment } from '../../../graphql/fragments';
import { QRCodeCheckinFeatureProperties } from '../../../types/features/QRCodeCheckinFeatureProperties';
import { StringSearchTypeEnum } from '../../../types/graphql/search';
import useQRCodeCheckIn, { QRCodeCheckInMode } from './useQRCodeCheckIn';

const checkInInteractionsQuery = gql`
  ${PublicUserFragment}

  query checkInInteractionsQuery(
    $id: UUID!
    $search: UserContentInteractionSearch
    $pagination: PaginationInput
  ) {
    me {
      content(_id: $id) {
        _id
        isInteractive

        userInteractions(search: $search, pagination: $pagination) {
          pageInfo {
            total
            start
            perPage
          }

          items {
            _id
            _created
            _updated
            _updatedBy {
              ...PublicUserFragment
            }
            user {
              _id
              name
            }
            startDate
            endDate
            geo
            data
            state
            features
            actions
            contentData
            version
            status
          }
        }
      }
    }
  }
`;

type Props = {
  contentId: LaneType.UUID | null | undefined;
  qrCodeCheckinFeature: QRCodeCheckinFeatureProperties | null | undefined;
  mode: QRCodeCheckInMode;
};

/**
 * Use this hook when coming in from the context of the Content, i.e. you
 * have a Content Id but you don't have an interactionId yet.
 *
 * This would happen if the user scans a QR code for the Content itself,
 * not for a specific interaction.
 */
export default function useContentFeatureQRCodeCheckIn({
  contentId,
  qrCodeCheckinFeature,
  mode,
}: Props) {
  const checkInRule = qrCodeCheckinFeature?.checkIn;
  const checkOutRule = qrCodeCheckinFeature?.checkOut;

  const fromStatus =
    mode === 'check-in' ? checkInRule?.fromStatus : checkOutRule?.fromStatus;

  const toStatus =
    mode === 'check-in' ? checkInRule?.toStatus : checkOutRule?.toStatus;

  const { data, loading, error } = useQuery(checkInInteractionsQuery, {
    fetchPolicy: 'cache-and-network',
    skip: Boolean(!contentId || !checkInRule?.fromStatus),
    variables: {
      id: contentId,
      search: {
        status: {
          type: StringSearchTypeEnum.Equal,
          value: fromStatus,
        },
        sortBy: {
          key: '_updated',
          dir: 'desc',
        },
      },
      pagination: {
        start: 0,
        perPage: 25,
      },
    },
  });

  const interactions = data?.me?.content?.userInteractions?.items || [];

  const interaction = interactions?.[0];

  const {
    error: interactionError,
    loading: interactionLoading,
    canCheckInOrOut,
    submitCheckIn,
    submitCheckOut,
  } = useQRCodeCheckIn({ qrCodeCheckinFeature, interaction, mode });

  return {
    error: interactionError || error,
    loading: interactionLoading || loading,
    checkInRule,
    checkOutRule,
    fromStatus,
    toStatus,
    interaction,
    canCheckInOrOut,
    submitCheckIn,
    submitCheckOut,
  };
}

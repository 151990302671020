export enum meterReadingNotificationTrigger {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  OUTSIDE_RANGE_OF = 'OUTSIDE_RANGE_OF',
}

export const notificationTriggerOptions = [
  {
    value: meterReadingNotificationTrigger.GREATER_THAN,
    label:
      'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.greaterThan',
  },
  {
    value: meterReadingNotificationTrigger.LESS_THAN,
    label:
      'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.lessThan',
  },
  {
    value: meterReadingNotificationTrigger.OUTSIDE_RANGE_OF,
    label:
      'web.admin.serviceRequest.equipment.MeterReading.notificationTrigger.outsideRange',
  },
];

export function getNotificationTriggerLabel(
  trigger: string | undefined | null
) {
  if (!trigger) {
    return '';
  }
  return (
    notificationTriggerOptions.find(option => option.value === trigger)
      ?.label || ''
  );
}

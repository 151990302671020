import React from 'react';

import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useMyRecentActivityQuery } from 'lane-shared/hooks';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { Loading } from 'components/general';
import { UserContentInteractionCard } from 'components/lane';

import styles from './RecentActivity.scss';

export default function RecentActivity() {
  const { items, loading, getNextPage, pageInfo } = useMyRecentActivityQuery();
  const debouncedGetNextPage = useDebouncedCallback(getNextPage, 500).callback;
  const hasNextPage = pageInfo.total > items.length;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: debouncedGetNextPage,
    rootMargin: '0px 0px 100px 0px',
  });

  const redirectionPath = (interaction: any) => {
    return interaction.contentData.type === ContentTypeEnum.WorkOrder
      ? `/l/post/${interaction.contentData._id}?uciId=${interaction._id}`
      : `/l/interaction/${interaction._id}`;
  };

  return (
    <div className={styles.RecentActivity}>
      {items.map(interaction => (
        <Link
          to={redirectionPath(interaction)}
          key={interaction._id}
          className={styles.Item}
        >
          <UserContentInteractionCard
            className={styles.interactionCard}
            interaction={interaction}
          />
        </Link>
      ))}

      {loading && <Loading />}
      <div ref={sentryRef} />
    </div>
  );
}

import React, { useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { routes } from 'lane-shared/config';
import { AppContext } from 'lane-shared/contexts';
import { emitter } from 'lane-shared/helpers';

import { H3, Text } from 'components/typography';

import AppleStoreImage from 'static/img/apple-store.png';
import GooglePlayImage from 'static/img/google-play.png';

import styles from './CompleteSignupSuccess.scss';

export default function CompleteSignupSuccess({
  authToken,
}: {
  authToken: string | null;
}) {
  const { t } = useTranslation();
  const { whitelabel } = useContext(AppContext);

  // Use the auth token passed in to log the user in
  function routeToHome() {
    emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
      route: routes.home,
      authToken,
    });
  }

  return (
    <>
      <div style={{ marginBottom: '4em' }}>
        <H3>{t('You’re all set up!')}</H3>
        <Text size="large">
          {t(
            'To continue, download the {{- appName}} mobile app and sign in with the email and password you created',
            { appName: whitelabel.name }
          )}
        </Text>
      </div>
      <div className={styles.downloadButtons}>
        <a
          href={whitelabel.mobileAndroid}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={GooglePlayImage}
            alt={t('Download the app on the Play Store')}
            className={styles.downloadButton}
          />
        </a>
        <a
          href={whitelabel.mobileIos}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={AppleStoreImage}
            alt={t('Download the app on the Apple Store')}
            className={styles.downloadButton}
          />
        </a>
      </div>
      <div className={styles.continueLink}>
        <Text>
          <Trans t={t}>
            or continue to the{' '}
            <a role="button" tabIndex={0} onClick={routeToHome}>
              web app
            </a>
          </Trans>
        </Text>
      </div>
    </>
  );
}

import React, { useContext, useMemo, useState } from 'react';

import {
  ErrorMessage,
  Sidebar,
  SidebarItem,
  SidebarItemCreateNew,
  DraftNotice,
  MediaEdit,
} from 'components';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import config from 'lane-shared/config';
import { ENVIRONMENTS } from 'activate-constants';
import {
  UserDataContext,
  ChannelServiceRequestFeaturesContext,
} from 'lane-shared/contexts';
import {
  PERMISSION_ANALYTICS_ADVANCED,
  PERMISSION_CONTENT_CREATE,
  PERMISSION_CONTENT_NOTICE_CREATE,
  PERMISSION_CONTENT_PAGE_CREATE,
  PERMISSION_CONTENT_PERK_CREATE,
  PERMISSION_CONTENT_POST_CREATE,
  PERMISSION_CONTENT_PROMOTION_CREATE,
  PERMISSION_PORTAL_ACCESS,
  PERMISSION_ADMIN,
} from 'lane-shared/helpers/constants/permissions';
import {
  useFlag,
  useChannelServiceRequestFeaturesQuery,
} from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import {
  ContentTypeEnum,
  ContentModalTypeEnum,
} from 'lane-shared/types/content/ContentTypeEnum';

import { ServiceRequestBuilder } from 'lane-web/src/domains/workOrder/serviceRequest/components/builder';
import {
  useChannelForAdminQuery,
  useChannelSidebarLinks,
} from 'lane-web/src/hooks';

import { DraftContent } from 'components/lane/DraftContent';
import DraftContentCreationModal from 'components/lane/DraftContent/DraftContentCreationModal';
import Pendo from 'components/misc/Pendo';
import SiteHeader from 'components/misc/SiteHeader';

import { AutoSetupWizard } from 'pages/portal/admin/channel/children/auto-setup-wizard';

import TemplateEdit from '../../../../components/lane/TemplateEdit';
import ProtectedRoute from '../../../../components/navigation/ProtectedRoute';
import {
  TemplatePickerModalContext,
  useTemplatePickerModal,
} from '../../../../contexts/TemplatePickerModalContext';
import { AccessControlRoutes } from '../../../../domains/accessControl';
import { HardwareManagementRoutes } from '../../../../domains/hardwareManagement';
import { ReservableManagement } from '../../../../domains/reservableManagement';
import { UserManagementPagesRoutes } from '../../../../domains/userManagement';
import { VisitorManagementRoutes } from '../../../../domains/visitorManagement';
import { EquipmentSettings } from '../../../../domains/workOrder/equipment/settings/EquipmentSettings';
import { PreventiveMaintenanceRoutes } from '../../../../domains/workOrder/preventiveMaintenance';
import ChannelAdminContext from './ChannelAdminContext';
import { Audiences } from './audiences';
import { AudienceForm } from './audiences/AudienceForm';
import { ShowAudience } from './audiences/ShowAudience';
import AdminViewGenerator from './batch-content/AdminViewGenerator';
import { Charges } from './charges-invoices';
import { CreateChargePage } from './charges-invoices/CreateChargePage';
import { InvoiceDetails } from './charges-invoices/invoice-details';
import { Memberships } from './memberships';
import { CreateMembershipForm } from './memberships/CreateMembershipForm';
import { MembershipDetails } from './memberships/MembershipDetails';
import Children from './children';
import NewChild from './children/new';
import ContentCentre from './content-center';
import Dashboard from './dashboard';
import {
  ImportConverter,
  ImportJobList,
  Importer,
  ImporterDetail,
} from './data-import';
import DraftEdit from './draft/DraftEdit';
import { FloorsList, CreateFloor, FloorDetails } from './floors';
import ChannelInsightsIndex from './insights';
import ChannelInsightsReport from './insights/report';
import Integrations from './integrations';
import IntegrationEdit from './integrations/edit';
import IntegrationNew from './integrations/new';
import ChannelAdminInteraction from './interaction/ChannelAdminInteraction';
import Library from './library';
import Media from './media';
import ChannelAdminMember from './member/ChannelAdminMember';
import MerchantAccounts from './merchant-accounts/MerchantAccounts';
import MetatagLibrary from './meta-tag';
import MetatagEdit from './meta-tag/edit';
import MetatagCreate from './meta-tag/new';
import PaymentAccounts from './payment-accounts';
import { ChannelPaymentAccountTransactions } from './payment-accounts/transactions';
import { PaymentSettings } from './payment-settings';
import AdminViewContent from './post/AdminViewContent';
import { ProductsServices } from './products-services';
import { CreateCategoryForm } from './products-services/categories/CreateCategoryForm';
import { CreateExceptionForm } from './products-services/exceptions/CreateExceptionForm';
import { CreateProductForm } from './products-services/products/CreateProductForm';
import { EditProductForm } from './products-services/products/EditProductForm';
import { CreateChargeCodeForm } from './products-services/chargeCodes/CreateChargeCodeForm';
import { CreditBundles } from './credit-bundles';
import Profile from './profile';
import Relationships from './relationships';
import NewChannelAndRelationship from './relationships/new';
import { TenantDetails } from './relationships/details';

import Sections from './sections';
import SectionEdit from './sections/edit';
import SectionNew from './sections/new';
import { SectionView } from './sections/view/index';
import SectionViewLegacy from './sections/viewLegacy';
import { ServiceRequests } from './service-requests';
import ServiceRequestCreate from './service-requests/create';
import { ServiceRequestDetails } from './service-requests/details';
import ServiceRequestEdit from './service-requests/details/forms/ServiceRequestEditForm';
import SubChannels from './sub-channels';
import NewSubChannel from './sub-channels/new';
import TabCenter from './tab-management';
import Team from './team';
import TeamView from './team/view';
import TeamCreate from './teamCreate';
import TeamEdit from './teamEdit';
import ChannelTemplateLibrary from './templates/ChannelTemplateLibrary';
import { TransactionsReports } from './transactions-reports';
import { TagShow } from './library/tags/TagShow';
import { TagNew } from './library/tags/TagNew';
import { TagEdit } from './library/tags/TagEdit';
import { UnitsList, CreateUnit, UnitDetails } from './units';
import EquipmentDetails from './work-orders/equipments/details/index';
import EquipmentCreate from './work-orders/equipments/form/EquipmentCreateForm';
import EquipmentEdit from './work-orders/equipments/form/EquipmentEditForm';
import EquipmentList from './work-orders/equipments/list/EquipmentList';
import { ServiceRequestBillingSettings } from './work-orders/billing/settings/ServiceRequestBillingSettings';
import { MessagingAndMarketplace } from './messagingAndMarketplace';

import styles from './styles.scss';
import {
  ServiceRequestBillingCategoryForm,
  ServiceRequestBillingProductForm,
  ServiceRequestBillingChargeCodeForm,
} from './work-orders/billing/settings/forms';
import { TeamBulkInvite } from './teamBulkInvite';
import { UserBulkAdd } from './teamsBulkAdd';
import { useDraftContentAnalytics } from 'lane-shared/hooks/analytics';
import { CreateCreditBundleForm } from './credit-bundles/CreateCreditBundleForm';
import { ChannelType } from 'lane-shared/types/ChannelType';
import { Invoices } from './charges-invoices/Invoices';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { EditExceptionForm } from './products-services/exceptions/EditExceptionForm';
import { DraftView } from './draft/DraftView';

export function ChannelAdmin() {
  const match = useRouteMatch();

  const {
    channel,
    timeZone,
    specialPermissions,
    loading,
    error,
    called,
    refetchChannel,
  } = useChannelForAdminQuery();

  const { user, hasAnyPermission } = useContext(UserDataContext);
  const { t } = useTranslation();
  const { isInvoicingDisabled } = useBillingPaymentSettingsForChannel({
    channelId: channel?._id,
  });
  const {
    setTemplateType,
    setModalType,
    ...templatePickerModalState
  } = useTemplatePickerModal();
  const newSectionUIIsEnabled = useFlag(FeatureFlag.SectionUI, false);
  const { draftContentTracker } = useDraftContentAnalytics();

  const hasChannelAdminPermission = hasAnyPermission(
    [PERMISSION_PORTAL_ACCESS],
    channel?._id
  );

  const [serviceRequestFeatures, setServiceRequestFeatures] = useState({
    createServiceRequest: false,
    updateServiceRequest: false,
  });

  const refetchChannelFeatures = useChannelServiceRequestFeaturesQuery(
    channel,
    setServiceRequestFeatures
  );

  const sidebarLinks = useChannelSidebarLinks({
    user,
    channel,
    specialPermissions,
    url: match.url,
    channelModules: channel?.channelModules,
    serviceRequestFeatures,
    isInvoicingDisabled,
  });

  const isIntuitiveUserManagementEnabled = useFlag(
    FeatureFlag.IntuitiveUserManagement,
    false
  );
  const tagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const unitsFloorsEnabled = useFlag(FeatureFlag.UnitsFloors, false);
  const chargesInvoiceFlagEnabled = useFlag(FeatureFlag.ChargesInvoice, false);
  const creditsFlagEnabled = useFlag(FeatureFlag.Credits, false);
  const exceptionsFlagEnabled = useFlag(FeatureFlag.Exceptions, false);
  const editProductsFlagEnabled = useFlag(FeatureFlag.EditProducts, false);
  const messagingAndMarketplaceFlag = useFlag(
    FeatureFlag.MessagingAndMarketplace,
    false
  );
  const tenantManagementFlag = useFlag(FeatureFlag.TenantManagement, false);

  const isMessagingAndMarketplaceFlagEnabled =
    channel?.settings.hasMessagingAndMarketplaceEnabled &&
    messagingAndMarketplaceFlag;

  const channelFeaturesValue = useMemo(
    () => ({
      serviceRequestFeatures,
      setServiceRequestFeatures,
      refetchChannelFeatures,
    }),
    [serviceRequestFeatures, setServiceRequestFeatures, refetchChannelFeatures]
  );

  return (
    <ChannelServiceRequestFeaturesContext.Provider value={channelFeaturesValue}>
      <ChannelAdminContext.Provider
        value={{
          // @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message
          channel,
          timeZone,
          specialPermissions,
          loading,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'ApolloError | undefined' is not assignable t... Remove this comment to see the full error message
          error,
          called,
          refetchChannel,
        }}
      >
        <SiteHeader />

        <TemplatePickerModalContext.Provider
          value={{
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Dispatch<SetStateAction<ContentTypeEnum | nu... Remove this comment to see the full error message
            setTemplateType,
            setModalType,
            ...templatePickerModalState,
          }}
        >
          <div className={styles.ChannelAdmin}>
            <Sidebar type="admin">
              <SidebarItemCreateNew
                channel={channel}
                onClick={() => {
                  setModalType(ContentModalTypeEnum.Content);
                  setTemplateType(null);
                  draftContentTracker.Create.SideBarCreate();
                }}
              />
              {sidebarLinks.map(sidebarLink => (
                <SidebarItem
                  key={sidebarLink.name}
                  iconSet={(sidebarLink as any).iconSet}
                  iconWeight={(sidebarLink as any).iconType}
                  icon={sidebarLink.icon}
                  links={(sidebarLink as any).links}
                  route={(sidebarLink as any).route}
                  isCategory={sidebarLink.isCategory}
                  type="admin"
                >
                  {sidebarLink.name}
                </SidebarItem>
              ))}
            </Sidebar>

            <ErrorMessage error={error} />

            {user && (user.isSuperUser || hasChannelAdminPermission) ? (
              <>
                {![ENVIRONMENTS.TEST].includes(config.env) && <Pendo />}
                <Switch>
                  <Route exact path={`${match.path}/dashboard`}>
                    <Dashboard />
                  </Route>

                  <Route exact path={`${match.path}/content-center`}>
                    <ContentCentre
                      key="content-center"
                      availableTypes={[
                        ContentTypeEnum.Notice,
                        ContentTypeEnum.Content,
                      ]}
                    />
                  </Route>

                  <Route exact path={`${match.path}/page-center`}>
                    <ContentCentre
                      key="page-center"
                      availableTypes={[ContentTypeEnum.Static]}
                    />
                  </Route>

                  <Route exact path={`${match.path}/perk-center`}>
                    <ContentCentre
                      key="perk-center"
                      availableTypes={[ContentTypeEnum.Perk]}
                    />
                  </Route>

                  <Route exact path={`${match.path}/promotion-center`}>
                    <ContentCentre
                      key="promotion-center"
                      availableTypes={[ContentTypeEnum.Promotion]}
                    />
                  </Route>

                  <Route path={`${match.path}/tab-center`}>
                    <TabCenter />
                  </Route>

                  <Route exact path={`${match.path}/sections`}>
                    <Sections />
                  </Route>

                  <Route exact path={`${match.path}/sections/new`}>
                    <SectionNew />
                  </Route>

                  <Route exact path={`${match.path}/sections/:sectionId/edit`}>
                    <SectionEdit />
                  </Route>

                  <Route exact path={`${match.path}/sections/:sectionId/view`}>
                    {newSectionUIIsEnabled ? (
                      <SectionView />
                    ) : (
                      <SectionViewLegacy />
                    )}
                  </Route>

                  <ProtectedRoute
                    exact
                    path={`${match.path}/insights`}
                    permissions={[PERMISSION_ANALYTICS_ADVANCED]}
                  >
                    <ChannelInsightsIndex />
                  </ProtectedRoute>

                  <ProtectedRoute
                    exact
                    path={`${match.path}/insights/:selectedReport/`}
                    permissions={[PERMISSION_ANALYTICS_ADVANCED]}
                  >
                    <ChannelInsightsReport />
                  </ProtectedRoute>

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/units`}>
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                      <UnitsList channel={channel} />
                    </Route>
                  )}

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/floors`}>
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                      <FloorsList channel={channel} />
                    </Route>
                  )}

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/floors/new`}>
                      <CreateFloor channel={channel} />
                    </Route>
                  )}

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/floors/:floorId`}>
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                      <FloorDetails channel={channel} />
                    </Route>
                  )}

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/units/new`}>
                      <CreateUnit channel={channel} />
                    </Route>
                  )}

                  {unitsFloorsEnabled && channel && (
                    <Route exact path={`${match.path}/units/:unitId`}>
                      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                      <UnitDetails channel={channel} />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/audiences`}>
                    <Audiences />
                  </Route>

                  <Route exact path={`${match.path}/audiences/new`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <AudienceForm channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/audiences/:audienceId`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <ShowAudience channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/audiences/:audienceId/edit`}
                  >
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <AudienceForm channel={channel} forUpdate />
                  </Route>

                  <Route exact path={`${match.path}/templates`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <ChannelTemplateLibrary channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/templates/:templateId/edit`}
                  >
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <TemplateEdit channel={channel} forEdit />
                  </Route>

                  <Route exact path={`${match.path}/media`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <Media channel={channel} />
                  </Route>

                  {tagsEnabled && (
                    <Route exact path={`${match.path}/library/tags/new`}>
                      <TagNew />
                    </Route>
                  )}

                  {tagsEnabled && (
                    <Route exact path={`${match.path}/library/tags/:tagId`}>
                      <TagShow />
                    </Route>
                  )}

                  {tagsEnabled && (
                    <Route
                      exact
                      path={`${match.path}/library/tags/:tagId/edit`}
                    >
                      <TagEdit />
                    </Route>
                  )}

                  {!tagsEnabled && (
                    <Route exact path={`${match.path}/meta-tag`}>
                      <MetatagLibrary channel={channel} />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/meta-tag/new`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message */}
                    <MetatagCreate channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/meta-tag/:metatagId/edit`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message */}
                    <MetatagEdit channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/library/filters/new`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message */}
                    <MetatagCreate channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/library/filters/:metatagId/edit`}
                  >
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message */}
                    <MetatagEdit channel={channel} />
                  </Route>

                  <Route
                    path={[
                      `${match.path}/library/:libraryType`,
                      `${match.path}/library`,
                    ]}
                    render={({ match }) => (
                      <Library match={match as any} channel={channel} />
                    )}
                  />

                  <Route
                    exact
                    path={`${match.path}/media/:mediaId/edit`}
                    render={({ match, history }) => (
                      <MediaEdit match={match} history={history} />
                    )}
                  />

                  <Route exact path={`${match.path}/relationships`}>
                    <Relationships />
                  </Route>

                  <Route exact path={`${match.path}/relationships/new`}>
                    <NewChannelAndRelationship />
                  </Route>

                  {tenantManagementFlag && (
                    <Route
                      exact
                      path={`${match.path}/relationships/tenant/:tenantId`}
                    >
                      <TenantDetails />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/children`}>
                    <Children channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/children/new`}>
                    {/* @ts-expect-error ts-migrate(2322) FIXME: Type '(Pick<ChannelType, "type" | "name" | "tags" ... Remove this comment to see the full error message */}
                    <NewChild parent={channel} />
                  </Route>

                  {channel && (
                    <Route
                      exact
                      path={`${match.path}/children/auto-setup-wizard`}
                    >
                      <AutoSetupWizard channel={channel} />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/sub-channels`}>
                    <SubChannels channel={channel} user={user} />
                  </Route>

                  <Route exact path={`${match.path}/sub-channels/new`}>
                    <NewSubChannel parent={channel} />
                  </Route>

                  {!isIntuitiveUserManagementEnabled && (
                    <Route exact path={`${match.path}/teams`}>
                      <Team channel={channel} />
                    </Route>
                  )}
                  {!isIntuitiveUserManagementEnabled && (
                    <Route exact path={`${match.path}/teams/:groupRoleId/view`}>
                      <TeamView channel={channel} />
                    </Route>
                  )}
                  {!isIntuitiveUserManagementEnabled && (
                    <Route exact path={`${match.path}/teams/:groupRoleId/edit`}>
                      <TeamEdit channel={channel} />
                    </Route>
                  )}
                  {!isIntuitiveUserManagementEnabled && (
                    <Route exact path={`${match.path}/teams/new`}>
                      <TeamCreate channel={channel} />
                    </Route>
                  )}
                  {!isIntuitiveUserManagementEnabled && (
                    <Route
                      exact
                      path={`${match.path}/teams/:groupRoleId/bulkinvite`}
                    >
                      <TeamBulkInvite channel={channel} />
                    </Route>
                  )}

                  {channel && (
                    <Route exact path={`${match.path}/teams/bulkadd`}>
                      <UserBulkAdd channel={channel} />
                    </Route>
                  )}
                  {channel && (
                    <Route path={`${match.path}/user-management`}>
                      <UserManagementPagesRoutes
                        urlPath={match.path}
                        channel={channel}
                        user={user}
                        specialPermissions={specialPermissions}
                      />
                    </Route>
                  )}

                  {channel && (
                    <Route exact path={`${match.path}/member/:userId`}>
                      <ChannelAdminMember channel={channel} />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/merchant-accounts`}>
                    <MerchantAccounts />
                  </Route>

                  <Route exact path={`${match.path}/payment-accounts`}>
                    <PaymentAccounts channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/transactions-reports`}>
                    <TransactionsReports />
                  </Route>

                  <Route exact path={`${match.path}/subscriptions`}>
                    <Memberships />
                  </Route>

                  <Route exact path={`${match.path}/subscriptions/create`}>
                    <CreateMembershipForm />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/subscriptions/:recurringChargeId`}
                  >
                    <MembershipDetails />
                  </Route>

                  {chargesInvoiceFlagEnabled && (
                    <Route exact path={`${match.path}/charges`}>
                      <Charges />
                    </Route>
                  )}

                  {chargesInvoiceFlagEnabled && (
                    <Route exact path={`${match.path}/charges/invoices`}>
                      <Charges />
                    </Route>
                  )}

                  {chargesInvoiceFlagEnabled && !isInvoicingDisabled && (
                    <Route exact path={`${match.path}/invoices`}>
                      <Invoices />
                    </Route>
                  )}

                  {chargesInvoiceFlagEnabled && (
                    <Route
                      exact
                      path={`${match.path}/invoice/:invoiceDisplayId`}
                    >
                      <InvoiceDetails />
                    </Route>
                  )}

                  {chargesInvoiceFlagEnabled && (
                    <Route exact path={`${match.path}/charges/create-charge`}>
                      <CreateChargePage />
                    </Route>
                  )}

                  <Route exact path={`${match.path}/payment-settings`}>
                    <PaymentSettings channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/products-services`}>
                    <ProductsServices />
                  </Route>

                  {creditsFlagEnabled && (
                    <Route exact path={`${match.path}/credit-bundles`}>
                      <CreditBundles
                        channel={(channel as unknown) as ChannelType}
                      />
                    </Route>
                  )}
                  {creditsFlagEnabled && (
                    <Route
                      exact
                      path={`${match.path}/credit-bundles/create-credit-bundle`}
                    >
                      <CreateCreditBundleForm channel={channel} />
                    </Route>
                  )}

                  <Route
                    exact
                    path={`${match.path}/products-services/create-category`}
                  >
                    <CreateCategoryForm />
                  </Route>

                  {exceptionsFlagEnabled && (
                    <Route
                      exact
                      path={`${match.path}/products-services/create-exception`}
                    >
                      <CreateExceptionForm />
                    </Route>
                  )}

                  {exceptionsFlagEnabled && editProductsFlagEnabled && (
                    <Route
                      exact
                      path={`${match.path}/products-services/edit-exception/:exceptionId`}
                    >
                      <EditExceptionForm />
                    </Route>
                  )}

                  <Route
                    exact
                    path={`${match.path}/products-services/create-charge-code`}
                  >
                    <CreateChargeCodeForm />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/products-services/create-product`}
                  >
                    <CreateProductForm />
                  </Route>

                  {editProductsFlagEnabled && (
                    <Route
                      exact
                      path={`${match.path}/products-services/edit-product/:productId`}
                    >
                      <EditProductForm />
                    </Route>
                  )}

                  <Route
                    exact
                    path={`${match.path}/payment-accounts/:paymentAccountId/transactions`}
                    render={({ match }) => (
                      <ChannelPaymentAccountTransactions
                        paymentAccountId={match.params.paymentAccountId}
                        channel={channel}
                      />
                    )}
                  />

                  <ProtectedRoute
                    path={`${match.path}/create-promotion`}
                    permissions={[
                      PERMISSION_CONTENT_CREATE,
                      PERMISSION_CONTENT_PROMOTION_CREATE,
                    ]}
                  >
                    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <DraftContent
                      channel={channel}
                      contentType={ContentTypeEnum.Promotion}
                      liveContent={null}
                      forCreate
                    />
                  </ProtectedRoute>

                  <ProtectedRoute
                    path={`${match.path}/create-post`}
                    permissions={[
                      PERMISSION_CONTENT_CREATE,
                      PERMISSION_CONTENT_POST_CREATE,
                    ]}
                  >
                    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <DraftContent
                      channel={channel}
                      contentType={ContentTypeEnum.Content}
                      liveContent={null}
                      forCreate
                    />
                  </ProtectedRoute>

                  <ProtectedRoute
                    path={`${match.path}/create-perk`}
                    permissions={[
                      PERMISSION_CONTENT_CREATE,
                      PERMISSION_CONTENT_PERK_CREATE,
                    ]}
                  >
                    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <DraftContent
                      channel={channel}
                      contentType={ContentTypeEnum.Perk}
                      liveContent={null}
                      forCreate
                    />
                  </ProtectedRoute>

                  <ProtectedRoute
                    path={`${match.path}/create-page`}
                    permissions={[
                      PERMISSION_CONTENT_CREATE,
                      PERMISSION_CONTENT_PAGE_CREATE,
                    ]}
                  >
                    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <DraftContent
                      channel={channel}
                      contentType={ContentTypeEnum.Static}
                      liveContent={null}
                      forCreate
                    />
                  </ProtectedRoute>

                  <ProtectedRoute
                    path={`${match.path}/create-notice`}
                    permissions={[
                      PERMISSION_CONTENT_CREATE,
                      PERMISSION_CONTENT_NOTICE_CREATE,
                    ]}
                  >
                    <DraftNotice channel={channel} forCreate />
                  </ProtectedRoute>

                  <ProtectedRoute
                    path={`${match.path}/create-visitor-form`}
                    permissions={[PERMISSION_ADMIN]}
                  >
                    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ channel: (Pick<ChannelType, "type" | "name... Remove this comment to see the full error message */}
                    <DraftContent
                      channel={channel}
                      contentType={ContentTypeEnum.VisitorManagement}
                      liveContent={null}
                      forCreate
                    />
                  </ProtectedRoute>

                  <Route path={`${match.path}/draft/:draftId`}>
                    <DraftEdit channel={channel} />
                  </Route>

                  <Route path={`${match.path}/post/:contentId`}>
                    <AdminViewContent channel={channel} />
                  </Route>

                  <Route path={`${match.path}/draftpost/:draftId`}>
                    <DraftView channel={channel} />
                  </Route>

                  <Route path={`${match.path}/generator/:contentId`}>
                    <AdminViewGenerator />
                  </Route>

                  <Route path={`${match.path}/interaction/:interactionId`}>
                    <ChannelAdminInteraction />
                  </Route>

                  <Route exact path={`${match.path}/integrations`}>
                    <Integrations />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/integrations/:jobId`}
                    render={() => <IntegrationNew />}
                  />

                  <Route
                    exact
                    path={`${match.path}/integrations/:integrationId/edit`}
                    render={({ match }) => (
                      <IntegrationEdit
                        channelIntegrationId={match?.params?.integrationId}
                      />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path={`${match.path}/profile`}
                    permissions={[PERMISSION_PORTAL_ACCESS]}
                  >
                    <Profile />
                  </ProtectedRoute>

                  {isMessagingAndMarketplaceFlagEnabled && (
                    <ProtectedRoute
                      exact
                      path={`${match.path}/messaging-and-marketplace`}
                      permissions={[PERMISSION_PORTAL_ACCESS]}
                    >
                      <MessagingAndMarketplace />
                    </ProtectedRoute>
                  )}

                  <Route exact path={`${match.path}/data-import`}>
                    <ImportJobList channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/data-import/converter`}>
                    <ImportConverter channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/data-import/new`}>
                    <Importer channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/data-import/:jobId`}>
                    <ImporterDetail channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/service-requests`}
                  >
                    <ServiceRequests channel={channel} user={user} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/service-requests/settings/billing/create-product`}
                  >
                    {/* @ts-ignore */}
                    <ServiceRequestBillingProductForm channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/service-requests/settings/billing/create-charge-code`}
                  >
                    {/* @ts-ignore */}
                    <ServiceRequestBillingChargeCodeForm channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/service-requests/settings/billing/create-category`}
                  >
                    {/* @ts-ignore */}
                    <ServiceRequestBillingCategoryForm channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/service-requests/settings/billing`}
                  >
                    {/* @ts-ignore */}
                    <ServiceRequestBillingSettings channel={channel} />
                  </Route>

                  <Route
                    path={`${match.path}/work-orders/service-requests/settings`}
                  >
                    <ServiceRequestBuilder
                      /* @ts-ignore */
                      channel={channel}
                      user={user}
                      refetchChannel={refetchChannel}
                    />
                  </Route>

                  <Route
                    path={`${match.path}/work-orders/service-requests/new`}
                  >
                    <ServiceRequestCreate channel={channel} />
                  </Route>

                  <Route exact path={`${match.path}/work-orders/equipment`}>
                    <EquipmentList channel={channel} user={user} />
                  </Route>

                  <Route exact path={`${match.path}/work-orders/equipment/new`}>
                    <EquipmentCreate channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/equipment/:equipmentId/edit`}
                  >
                    <EquipmentEdit channel={channel} />
                  </Route>
                  <Route
                    path={`${match.path}/work-orders/service-requests/:serviceRequestId/view`}
                  >
                    <ServiceRequestDetails channel={channel} user={user} />
                  </Route>
                  <Route
                    path={`${match.path}/work-orders/service-requests/:serviceRequestId/edit`}
                  >
                    <ServiceRequestEdit channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/equipment/settings`}
                    render={() => <EquipmentSettings />}
                  />

                  <Route exact path={`${match.path}/work-orders/equipment/new`}>
                    <EquipmentCreate channel={channel} />
                  </Route>

                  <Route
                    exact
                    path={`${match.path}/work-orders/equipment/edit`}
                  >
                    <EquipmentEdit channel={channel} />
                  </Route>

                  <Route
                    path={`${match.path}/work-orders/equipment/:equipmentId`}
                  >
                    <EquipmentDetails channel={channel} user={user} />
                  </Route>

                  <Route path={`${match.path}/hardware-management`}>
                    <HardwareManagementRoutes
                      urlPath={match.path}
                      channel={channel}
                      user={user}
                    />
                  </Route>

                  <Route path={`${match.path}/access-control`}>
                    <AccessControlRoutes
                      urlPath={match.path}
                      channel={channel}
                      user={user}
                    />
                  </Route>

                  <Route path={`${match.path}/visitor-management`}>
                    <VisitorManagementRoutes
                      urlPath={match.path}
                      channel={channel}
                      user={user}
                    />
                  </Route>

                  <Route
                    path={`${match.path}/work-orders/preventive-maintenance`}
                  >
                    <PreventiveMaintenanceRoutes
                      urlPath={match.path}
                      channel={channel}
                      user={user}
                    />
                  </Route>

                  <Route path={`${match.path}/reservable-management/:view`}>
                    <ReservableManagement channel={channel} />
                  </Route>

                  <Route exact path={match.path}>
                    <Dashboard />
                  </Route>
                </Switch>
              </>
            ) : (
              <div className={styles.loginWrapper}>
                <div className={styles.login}>
                  <h1>{t`You need to login to access this.`}</h1>
                </div>
              </div>
            )}
          </div>
          <DraftContentCreationModal />
        </TemplatePickerModalContext.Provider>
      </ChannelAdminContext.Provider>
    </ChannelServiceRequestFeaturesContext.Provider>
  );
}

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getClient } from '../apollo';

const getSectionForCardQuery = gql`
  query getSectionForCardQuery($id: UUID!) {
    section(_id: $id, searchOptions: $searchOptions) {
      _id
      _id
      name
      description
      type
      object
      query
      renderType
      tags
      backgroundImage
      logo
      icon
      iconSet
      iconWeight
      color
      filters
      sorts
      backgroundColor
      channel {
        _id
        slug
      }
    }
  }
`;

type Props = {
  sectionId: string | null | undefined;
};

export default function useSectionForCardQuery({ sectionId }: Props) {
  const { data, loading, error } = useQuery(getSectionForCardQuery, {
    skip: !sectionId,
    client: getClient(),
    fetchPolicy: 'no-cache',
  });

  const section = data?.section;

  return {
    section,
    loading,
    error,
  };
}

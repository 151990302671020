import React from 'react';

import { useTranslation } from 'react-i18next';

import { BEVisitor } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types';
import { VisitorProps } from 'lane-shared/helpers/integrations/BuildingEnginesPrismVisitors/types/VisitTypes';

import PhoneNumberInput from '../../../form/PhoneNumberInput';
import ValidatedInput from '../../../form/ValidatedInput';
import Button from '../../../general/Button';

import styles from './VisitorForm.scss';

export default function VisitorForm({
  visitor,
  index,
  showRemove,
  update,
  removeVisitor,
  hasAttemptedSubmit,
  isEditableVisitor,
}: VisitorProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.visitorBox}>
      <div className={styles.visitorLabel}>
        <h3>
          {index === 0 ? (
            <>{t('Visitor')}</>
          ) : (
            <>
              {t('Visitor')} - {index + 1}
            </>
          )}
        </h3>
        {showRemove && (
          <Button
            onClick={() => {
              removeVisitor(visitor);
            }}
            variant="text"
            size="small"
          >
            {t('Remove')}
          </Button>
        )}
      </div>

      <ValidatedInput
        onChange={first_name => update({ ...visitor, first_name })}
        placeholder={t('First Name')}
        value={visitor.first_name}
        validation={BEVisitor.shape.first_name}
        isPristine={!hasAttemptedSubmit}
        disabled={!isEditableVisitor}
        showClear={!isEditableVisitor}
      />
      <ValidatedInput
        onChange={last_name => update({ ...visitor, last_name })}
        placeholder={t('Last Name')}
        value={visitor.last_name}
        validation={BEVisitor.shape.last_name}
        isPristine={!hasAttemptedSubmit}
        disabled={!isEditableVisitor}
        showClear={!isEditableVisitor}
      />
      <ValidatedInput
        onChange={email => update({ ...visitor, email })}
        placeholder={t('Email')}
        value={visitor.email}
        isPristine={!hasAttemptedSubmit}
        disabled={!isEditableVisitor}
        showClear={!isEditableVisitor}
      />
      <PhoneNumberInput
        label="Phone Number"
        value={visitor.phone!}
        onChange={phone => update({ ...visitor, phone })}
        placeholder={t('Phone Number')}
        disabled={!isEditableVisitor}
      />
    </div>
  );
}

import React from 'react';

import cx from 'classnames';
import { Dropdown } from 'components';
import { useTranslation } from 'react-i18next';

import {
  TaxAmountType,
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
  Product,
} from 'lane-shared/domains/billingPayments/types';

import { useCreditBundlesListQuery } from 'lane-web/src/pages/portal/admin/channel/products-services/helpers/index';

import { Item } from 'components/form/Dropdown/Dropdown';

import styles from '../addItemsToChargeStyles.scss';
import useUserLocale from 'hooks/useUserLocale';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { productToPriceComponents } from 'lane-shared/domains/billingPayments/helpers';

export function AddCreditBundleSelection({
  productDetails,
  handleProductDetailsUpdate,
  currency,
  inclusivePricing = false,
}: {
  productDetails: AddProductToChargeDetails;
  handleProductDetailsUpdate: Function;
  currency: SupportedPaymentCurrency;
  inclusivePricing?: boolean;
}) {
  const { t } = useTranslation();
  const locale = useUserLocale();

  const creditBundlesList = useCreditBundlesListQuery({
    page: 0,
    pageSize: 100,
  });

  function getCreditBundles(): Item<string>[] | undefined {
    const listOfCreditBundles = creditBundlesList.data?.listProducts.products;

    if (listOfCreditBundles) {
      const dropdownProductItems = listOfCreditBundles.map(creditBundle => {
        return {
          label: creditBundle.name,
          value: creditBundle.id,
        };
      });
      return dropdownProductItems;
    }
    return undefined;
  }

  const onCreditBundleSelection = (item: Item<string>) => {
    const selectedCreditBundle = creditBundlesList.data?.listProducts.products.find(
      creditBundle => creditBundle.id === item.value
    );

    if (selectedCreditBundle) {
      // TODO: TM-18527 - Update to use the generated types correctly
      const priceComponents = productToPriceComponents(
        (selectedCreditBundle as unknown) as Product
      );
      const { netPrice, rate } = getPricing({
        currency,
        locale,
        priceComponents,
        inclusivePricing,
      });

      handleProductDetailsUpdate({
        ...productDetails,
        productId: selectedCreditBundle.id,
        productCategoryId:
          selectedCreditBundle.productCategory?.productCategoryId,
        productCategoryType: selectedCreditBundle.productCategory?.productType,
        productCategoryName: selectedCreditBundle.productCategory?.name,
        productCategoryDescription:
          selectedCreditBundle.productCategory?.description,
        productGroupId: selectedCreditBundle.groupId,
        name: selectedCreditBundle.name,
        description: selectedCreditBundle.description,
        rate: rate.toString(),
        tax: {
          type:
            selectedCreditBundle.tax?.taxAmountType ||
            TaxAmountType.TaxAmountTypePercentage,
          value: selectedCreditBundle?.tax?.value.toString() || '',
        },
        netPrice: netPrice.toString(),
        receivables: selectedCreditBundle.receivables,
      });
    }
  };

  return (
    <Dropdown
      testId="creditBundleDropdown"
      doTranslation={false}
      className={cx(styles.categoryAndProductDropdown)}
      value={productDetails.productId}
      placeholder={t(
        'abp.charges.addCreditBundle.modal.creditBundleInputPlaceholder'
      )}
      items={getCreditBundles()}
      onChange={onCreditBundleSelection}
      label={t('abp.charges.addCreditBundle.modal.creditBundleInputLabel')}
      isRequired
      fixedLabel
    />
  );
}

import React from 'react';

import { Controller, Control } from 'react-hook-form';
import { MultiselectField } from 'lane-web/src/components';

type Item<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  control: Control<any>;
  errorMessage?: string[] | null;
  name: string;
  items: Item<T>[];
  isFullWidth?: boolean;
  placeholder?: string;
  label?: string;
  fixedLabel?: boolean;
  doTranslation?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  onValueChange?: any;
  isRequired?: boolean;
  dataCy?:string;
};

export function HookFormMultiSelectDropdown<T>({
  control,
  name,
  items,
  placeholder,
  isFullWidth,
  label,
  fixedLabel,
  ariaLabel,
  doTranslation,
  disabled,
  onValueChange,
  isRequired,
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <MultiselectField
          label={label}
          isSearchable
					placeholder={placeholder}
					value={value}
					onChange={e => {
						onChange(e);
						if (onValueChange) {
							onValueChange(e);
						}
					}}
					items={items}
					isFullWidth={isFullWidth}
					ariaLabel={ariaLabel}
					fixedLabel={fixedLabel}
					doTranslation={doTranslation}
					disabled={disabled}
					isRequired={isRequired}
        />
      )}
    />
  );
}
export default HookFormMultiSelectDropdown;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, PageSizeType, Sort, Text } from 'design-system-web';
import { useColumnsHeaders } from './hooks/useColumnsHeaders';
import { useReservableWaitlistActions } from 'lane-shared/hooks/contentReservable';

import { RowData, getSort, waitlistItemToRowDataMapper } from './helpers';
import { useGetReservableWaitlist } from './hooks/useGetReservableWaitlist';
import { DateTime } from 'luxon';

const TRANSLATION_KEYS = {
  emptyMessage: 'web.admin.channel.waitlistManagement.table.emptyMessage',
  serverError: 'web.admin.channel.waitlistManagement.table.serverError',
  leaveWaitlistSuccess:
    'web.admin.channel.waitlistManagement.table.remove.success',
  remove: 'web.admin.channel.waitlistManagement.table.remove',
};

type Props = {
  channelId: string;
  contentId: string;
  timeZone: string;
};

const DEFAULT_PAGE_SIZE = 25;

export function WaitlistTableView({ channelId, contentId, timeZone }: Props) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<PageSizeType>(DEFAULT_PAGE_SIZE);
  const [sort, setSort] = useState<Sort>({});
  const [error, setError] = useState<string | null>(null);

  const { leaveWaitlist } = useReservableWaitlistActions();

  const { t } = useTranslation();

  const handleRemove = async (id: string) => {
    try {
      await leaveWaitlist(id);
      await getReservableWaitlists({
        contentId,
        channelId,
        search: {
          sortBy: getSort(sort),
          afterEndDate: DateTime.now().setZone(timeZone).toISO(),
        },
        page,
        pageSize,
      });

      window.Toast.show(t(TRANSLATION_KEYS.leaveWaitlistSuccess));
    } catch (ex) {
      window.Toast.show(t(TRANSLATION_KEYS.serverError));
    }
  };

  const { columns } = useColumnsHeaders(timeZone);

  const {
    getReservableWaitlists,
    isLoading,
    waitlists,
  } = useGetReservableWaitlist();

  useEffect(() => {
    const fetchReservableWaitlists = async () => {
      try {
        await getReservableWaitlists({
          contentId,
          channelId,
          search: {
            sortBy: getSort(sort),
            afterEndDate: DateTime.now().setZone(timeZone).toISO(),
          },
          page,
          pageSize,
        });
        setError(null);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchReservableWaitlists();
  }, [contentId, channelId, page, pageSize, sort]);

  const totalRows = waitlists?.pageInfo?.total || 0;
  const waitlistItems = waitlists?.items || [];
  const rowActions = [
    {
      label: t(TRANSLATION_KEYS.remove),
      onClick: (rowinfo: any) => {
        handleRemove(rowinfo.id);
      },
    },
  ];

  if (error) {
    window.Toast.show(<Text>{t(TRANSLATION_KEYS.serverError)}</Text>);
  }

  return (
    <Table<RowData>
      isLoading={isLoading}
      columns={columns}
      data={waitlistItemToRowDataMapper(timeZone, waitlistItems)}
      pagination="server"
      totalRows={totalRows}
      pageSize={pageSize}
      page={page}
      rowActions={rowActions}
      onPageChange={setPage}
      onSortChange={setSort}
      onPageSizeChange={setPageSize}
      emptyMessage={t(TRANSLATION_KEYS.emptyMessage)}
      tableKey="waitlistManagement.waitlistTableView"
    />
  );
}

import React from 'react';

import { Toggle } from 'components';
import { useTranslation } from 'react-i18next';

import CardContainer from 'components/cards/CardContainer';
import { H5, M, S } from 'components/typography';

import styles from './styles.scss';

interface RefundProps {
  failureRefund: boolean;
  cancellationRefund: boolean;
  updateFailureRefund: (value: boolean) => void;
  updateCancellationRefund: (value: boolean) => void;
}

export function Refund({
  failureRefund,
  cancellationRefund,
  updateFailureRefund,
  updateCancellationRefund,
}: RefundProps) {
  const { t } = useTranslation();

  return (
    <CardContainer className={styles.PaymentSettings}>
      <div className={styles.SectionHeader}>
        <H5>{t('abp.billingAndPaymentSettings.refund.header')}</H5>
      </div>
      <div className={styles.RefundToggleContainer}>
        <Toggle
          value={failureRefund}
          onChange={value => updateFailureRefund(value)}
          testId="refundSystemErrorsToggle"
        />
        <div className={styles.RefundToggleDescription}>
          <M mt={2} className={styles.FieldLabel}>
            <label>
              {t('abp.billingAndPaymentSettings.refund.systemError')}
            </label>
          </M>
          <M mt={2} className={styles.FieldLabel}>
            <S>{t('abp.billingAndPaymentSettings.refund.systemErrorLabel')}</S>
          </M>
        </div>
      </div>
      <div className={styles.RefundToggleContainer}>
        <Toggle
          value={cancellationRefund}
          onChange={value => updateCancellationRefund(value)}
          testId="refundCancellationToggle"
        />
        <div className={styles.RefundToggleDescription}>
          <M mt={2} className={styles.FieldLabel}>
            <label>
              {t('abp.billingAndPaymentSettings.refund.cancellations')}
            </label>
          </M>
          <M mt={2} className={styles.FieldLabel}>
            <S>
              {t('abp.billingAndPaymentSettings.refund.cancellationsLabel')}
            </S>
          </M>
        </div>
      </div>
    </CardContainer>
  );
}

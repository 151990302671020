import React from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { DocumentType } from 'lane-shared/types/DocumentType';

import CircleListView from 'components/lane/CircleListView';

type Props = {
  user: DocumentType;
  description?: string;
  onClick?: (user: DocumentType) => void;
  className?: string;
  style?: React.CSSProperties;
};

export default function UserListView({
  className,
  user,
  description,
  onClick = () => {},
  style,
}: Props) {
  const name = getDisplayName(user);

  return (
    <CircleListView
      onClick={() => onClick(user)}
      image={imageUrl((user as any)?.profile?.image)}
      logo={imageUrl((user as any)?.profile?.logo)}
      name={name}
      className={className}
      style={style}
    >
      <h2>{name}</h2>
      {(user as any)?.profile?.email && <h4>{(user as any).profile.email}</h4>}
      {description && <p>{description}</p>}
    </CircleListView>
  );
}

import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  AddProductToChargeDetails,
  SupportedPaymentCurrency,
} from 'lane-shared/domains/billingPayments/types';
import { getPricing } from 'lane-shared/domains/productCatalog/helpers';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { SupportedLocaleEnum } from 'localization';

import useUserLocale from 'hooks/useUserLocale';

import styles from './addProductToChargeStyles.scss';
import { priceInputToComponents } from 'lane-shared/domains/billingPayments/helpers';

export function AddedProductsTotal({
  listOfProducts,
  currency,
}: {
  listOfProducts: AddProductToChargeDetails[];
  currency: SupportedPaymentCurrency;
}) {
  const { t } = useTranslation();
  const [summary, setSummary] = useState({
    subTotal: '',
    tax: '',
    total: '',
  });

  const twoDecimalsWithCurrency = currencyFormatter({
    currency,
    minimumFractionDigits: 2,
  });

  const locale = useUserLocale();

  useEffect(() => {
    const { subTotal, tax, total } = getAmounts(
      listOfProducts,
      currency,
      locale
    );

    setSummary({
      subTotal: twoDecimalsWithCurrency(subTotal),
      tax: twoDecimalsWithCurrency(tax),
      total: twoDecimalsWithCurrency(total),
    });
  }, [listOfProducts]);

  return (
    <div className={styles.addedProductsSummary}>
      <div className={styles.summaryRow}>
        <div className={styles.subTotalLabel}>
          {t('abp.charges.addProduct.summary.subtotal')}
        </div>
        <div
          className={styles.subTotalValue}
          id="create-charge-summary-subtotal"
        >
          {summary.subTotal}
        </div>
      </div>
      <div className={styles.summaryRow}>
        <div className={styles.taxLabel}>
          {t('abp.charges.addProduct.summary.tax')}
        </div>
        <div className={styles.taxValue} id="create-charge-summary-tax">
          {summary.tax}
        </div>
      </div>
      <div className={cx(styles.summaryRow, styles.total)}>
        <div className={styles.totalRowLabel}>
          {t('abp.charges.addProduct.summary.total')}
        </div>
        <div className={styles.totalRowValue} id="create-charge-summary-total">
          {summary.total}
        </div>
      </div>
    </div>
  );
}

export function getAmounts(
  listOfProducts: AddProductToChargeDetails[],
  currency: SupportedPaymentCurrency,
  locale: SupportedLocaleEnum
) {
  let subTotal = 0;
  let tax = 0;
  let total = 0;

  listOfProducts.forEach(product => {
    const priceComponents = priceInputToComponents(product);
    const { listPrice, grossTax, netPrice } = getPricing({
      currency,
      locale,
      priceComponents,
    });

    const quantity = Number(product.quantity);
    subTotal += listPrice * quantity;
    tax += grossTax * quantity;
    total += netPrice * quantity;
  });

  return { subTotal, tax, total };
}

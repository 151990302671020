import React from 'react';

import { useTranslation } from 'react-i18next';

import { EditModeProps } from 'components/renderers/v5/primitives/useEditModeProps';
import { H4 } from 'components/typography';

import styles from './BlockPreview.scss';

type BlockPreviewProps = {
  editModeProps: EditModeProps;
  children?: React.ReactNode;
};

export default function BlockPreview({
  editModeProps: { 'data-block-name': dataBlockName },
  children,
}: BlockPreviewProps) {
  const { t } = useTranslation();

  if (children) {
    return (
      <div className={styles.previewAvailable}>
        <H4 className={styles.previewCaption}>{t('Block preview')}</H4>
        {children}
      </div>
    );
  }

  const label = dataBlockName
    ? '"{{dataBlockName}}" cannot be previewed'
    : 'This block is unavailable for preview.';

  return (
    <H4 className={styles.previewNotAvailable} v2>
      {t(label, { dataBlockName })}
    </H4>
  );
}

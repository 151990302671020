import React from 'react';

import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import useSectionForCardQuery from 'lane-shared/hooks/useSectionForCardQuery';

import Loading from '../general/Loading';
import SectionListView from './SectionListView';

export default function SectionCardLoader({
  className,
  style,
  sectionId,
}: any) {
  const { section, loading } = useSectionForCardQuery({ sectionId });

  if (loading) {
    return <Loading className={className} style={style} />;
  }

  if (!section) {
    return null;
  }

  return (
    <Link
      to={routes.channelAdminSectionsEdit
        .replace(':id', section?.channel?.slug)
        .replace(':sectionId', sectionId)}
    >
      <SectionListView section={section} className={className} style={style} />
    </Link>
  );
}

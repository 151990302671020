import { unitErrorCodeTypes } from 'activate-errors';

import { Item } from 'components/form/Dropdown/Dropdown';

export type UnitInputFields = {
  name: string;
  description: string;
  floors: Item<string>[];
};

export type UnitInputFieldKey = keyof UnitInputFields;

type ServerErrorCodeToFieldMappingType = {
  [key: string]: UnitInputFieldKey;
};

export const ServerErrorCodeToFieldMapping: ServerErrorCodeToFieldMappingType = {
  [unitErrorCodeTypes.unitNameAlreadyExistsError.code]: 'name',
  [unitErrorCodeTypes.unitNameMinimumCharacterError.code]: 'name',
};

import React, { useContext } from 'react';

import cx from 'classnames';

import { ChannelsContext } from 'lane-shared/contexts';
import { getDisplayName } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';

import Image from 'components/general/Image';
import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styles from './ChannelSwitcherBlock.scss';

type BlockProps = WebBlockProps & {
  color: string;
};

// This doesn't do much since it is just a representation for the mobile version,
// which will actually be able to switch channels
export default function ChannelSwitcherBlock({
  style,
  className,
  color,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { primaryChannel } = useContext(ChannelsContext);

  const image = imageUrl(primaryChannel?.profile?.image);
  const logo = imageUrl(primaryChannel?.profile?.logo);
  const name = getDisplayName(primaryChannel);

  return (
    <div
      className={cx(styles.ChannelSwitcherBlock, className)}
      style={style}
      {...props}
    >
      <Image src={logo || image} alt={name} className={styles.logo} />
      <p className={styles.text} style={{ color }}>
        {name}
      </p>
    </div>
  );
}

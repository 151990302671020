import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { useContentForAdmin } from 'lane-shared/hooks';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { Loading, ContentPage } from 'lane-web/src/components';
import { BreadCrumbs } from 'lane-web/src/components/lds';
import { H4 } from 'lane-web/src/components/typography';

import styles from './styles.scss';
import { getServiceRequestContentId } from 'graphql-queries';

function ServiceRequestCreate({ channel }: any) {
  const { t } = useTranslation();

  const [contentId, setContentId] = useState<string>();

  useQuery(getServiceRequestContentId, {
    onCompleted: data => {
      setContentId(data?.serviceRequestContentId);
    },
    variables: {
      channelId: channel?._id,
      search: {
        type: {
          any: ContentTypeEnum.WorkOrder,
        },
      },
    },
  });

  const { content: adminContent } = useContentForAdmin({
    contentId: contentId!,
  });

  if (!adminContent) return <Loading fullscreen />;

  return (
    <div className={styles.WorkOrderCreatePage}>
      <BreadCrumbs
        links={[
          {
            label: t('web.admin.serviceRequest.title'),
            url: routes.channelAdminWorkOrdersServiceRequests.replace(
              ':id',
              channel?.slug
            ),
          },
          {
            label: t('web.admin.serviceRequest.create'),
          },
        ]}
      />
      <H4 className={styles.title}>{t('web.admin.serviceRequest.title')}</H4>
      <section>
        <ContentPage content={adminContent} forAdmin />
      </section>
    </div>
  );
}

export default ServiceRequestCreate;

import { useContext, useEffect } from 'react';
import { AutoSetupConfig } from 'pages/portal/admin/channel/children/auto-setup-wizard/types';
import { AnalyticsContext } from 'lane-shared/contexts';
import { HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED } from 'lane-shared/helpers/constants/analytics';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';

export function useTrackTemplateSelected({
  config,
  channel,
}: {
  config: AutoSetupConfig;
  channel: UseChannelForAdminQueryResponse;
}): void {
  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    if (config.templateId && channel?.channel) {
      analytics.track(HOST_BUILDING_AUTO_SETUP_WIZARD_TEMPLATE_SELECTED, {
        channelName: channel.channel.name,
        value: config.templateId,
      });
    }
  }, [config.templateId, analytics, channel]);
}

import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import {
  PROPERTY_TYPES,
  TENANT_TYPES,
} from 'lane-shared/helpers/constants/channel';
import {
  ChannelType,
  ChannelTypeEnum,
  DeprecatedChannelTypeEnum,
} from 'lane-shared/types/ChannelType';

import { ChannelSearchHierarchiesEnum } from 'lane-web/src/components/lane/ChannelSearch';
import ChannelSelector, {
  ChannelSelectorModesEnum,
} from 'lane-web/src/components/lane/ChannelSelector';

import { S } from 'components/typography';

import { ChannelManagementModal } from './ChannelManagementModal';

export function ChangeParentModal({
  selectedChannel,
  isOpen,
  setIsOpen,
  selectedParentChannel,
  setSelectedParentChannel,
  onParentSelected,
}: {
  selectedChannel: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedParentChannel: ChannelType | null;
  setSelectedParentChannel: Dispatch<SetStateAction<ChannelType | null>>;
  onParentSelected: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const isPropertyChannel =
    selectedChannel?.channel?.type === ChannelTypeEnum.Property;

  const types: ChannelTypeEnum[] = isPropertyChannel
    ? PROPERTY_TYPES
    : TENANT_TYPES.concat(
        Object.keys(DeprecatedChannelTypeEnum) as ChannelTypeEnum[]
      );

  const body = (
    <>
      <ChannelSelector
        // @ts-ignore
        onChannelSelected={(channel: ChannelType) =>
          setSelectedParentChannel(channel)
        }
        hierarchies={[ChannelSearchHierarchiesEnum.Parent]}
        types={types}
        modes={[ChannelSelectorModesEnum.Search]}
        disableTypeSelect
        disableStorage
        shouldHighlightOnClick
      />
      <S style={{ padding: '1em' }}>
        {t(
          'web.admin.portalManagement.channelManagement.changeParent.modal.joinRules',
          { channelName: selectedChannel?.channel?.name }
        )}
      </S>
    </>
  );

  return (
    <ChannelManagementModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => setSelectedParentChannel(null)}
      title={t(
        'web.admin.portalManagement.channelManagement.changeParent.modal.title'
      )}
      description={t(
        'web.admin.portalManagement.channelManagement.changeParent.modal.description',
        { channelName: selectedChannel?.channel?.name }
      )}
      body={body}
      onClick={onParentSelected}
      size="large"
      disableConfirm={!selectedParentChannel}
    />
  );
}

import React from 'react';

import { Loading, ErrorPage } from 'components';
import { match, Route, Switch, useHistory } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import routes from 'lane-shared/config/routes';
import { NotFoundError } from 'activate-errors';
import { getChannel } from 'lane-shared/graphql/query';
import isProbablyUUID from 'lane-shared/helpers/isProbablyUUID';
import { useTrackChannelDetails } from 'lane-shared/hooks/analytics';
import { UserType } from 'lane-shared/types/User';

import OfficeOnChannel from 'components/lane/OfficeOnChannel';
import RetailOnChannel from 'components/lane/RetailOnChannel';

import RestrictedNotice from '../post/restricted/RestrictedNotice';
import ChannelContent from './content';
import ChannelMembers from './members';
import Profile from './profile';
import useAuthModal from 'hooks/useAuthModal';

import styles from './styles.scss';

interface Props {
  match: match<{ id: string }>;
  user: UserType | null;
}

export default function ChannelMain({ match, user }: Props) {
  let slug;
  let id;

  if (isProbablyUUID(match.params.id)) {
    ({ id } = match.params);
  } else {
    slug = match.params.id;
  }

  const { data, error, loading } = useQuery(getChannel, {
    skip: !(id || slug),
    variables: { id, slug },
    fetchPolicy: 'cache-and-network',
  });

  const { channel } = data ?? {};

  useTrackChannelDetails(channel);

  const history = useHistory();
  const { showAuthModal } = useAuthModal({});

  if (loading) {
    return (
      <div className={styles.Channel}>
        <Loading />
      </div>
    );
  }

  if (error || !channel) {
    // @ts-ignore
    const { status } = error?.graphQLErrors[0]?.extensions?.exception ?? {};
    // If we get a 404 error retrieving the channel and do not have a user,
    // there is a good chance it is private and we need to log in
    if (status === 404 && !user)
      return (
        <div id="restricted" className={styles.restrictedChannel}>
          <RestrictedNotice
            onNextLoggedIn={() => history.push(routes.home)}
            onNext={showAuthModal}
          />
        </div>
      );

    // Else, show the error page - this is a fallback
    // for proper 404s and other uncontrolled errors
    return (
      <ErrorPage
        error={error ?? new NotFoundError('Channel is not available')}
      />
    );
  }

  return (
    <div className={styles.Channel}>
      <Switch>
        <Route
          exact
          path={routes.channelMembers}
          render={() => <ChannelMembers channel={channel} />}
        />
        <Route
          exact
          path={routes.channelRetail}
          render={() => <RetailOnChannel channel={channel} />}
        />
        <Route
          exact
          path={routes.channelOffice}
          render={() => <OfficeOnChannel channel={channel} />}
        />
        <Route
          exact
          path={routes.channelProfile}
          render={() => <Profile channel={channel} />}
        />

        <Route
          path={routes.channelContent}
          render={props => <ChannelContent channel={channel} {...props} />}
        />

        <Route
          path={routes.channel}
          render={() => <Profile channel={channel} />}
        />
      </Switch>
    </div>
  );
}

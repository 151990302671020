import React from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import { PublicUserFragment } from 'lane-shared/graphql/fragments';

import IntegrationEdit from 'components/lane/IntegrationEdit';

const getIntegration = gql`
  ${PublicUserFragment}

  query getIntegration($id: UUID!) {
    integration(_id: $id) {
      _id
      _created
      _updated
      _createdBy {
        ...PublicUserFragment
      }
      _updatedBy {
        ...PublicUserFragment
      }
      name
      category
      type
      settings
      properties
      platforms
      workflows
    }
  }
`;

export default function PortalManagementIntegrationEdit({ match }: any) {
  const { data, loading, error } = useQuery(getIntegration, {
    client: getAdminClient(),
    variables: {
      id: match?.params?.integrationId,
    },
    skip: !match?.params?.integrationId,
  });

  return (
    <IntegrationEdit
      forEdit
      dataError={error}
      dataLoading={loading}
      integration={data?.integration}
    />
  );
}

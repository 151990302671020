import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import cx from 'classnames';
import { getUserLocale } from 'helpers';

import useFormattedNumberInput from 'lane-shared/hooks/useFormattedNumberInput';
import { SupportedLocaleEnum } from 'localization';

import styles from './FormattedNumberInput.scss';

export type CurrencyInputProps = {
  value: number;
  className?: string;
  style?: any;
  placeholder?: string;
  min: number;
  max: number;
  locale: SupportedLocaleEnum;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
  onChange: (value: string) => void;
  onValueChange: (value: number) => void;
  onBlur: (e: any) => void;
  onFocus: (e: any) => void;
};

function FormattedNumberInput(
  {
    className,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'testId' does not exist on type 'Currency... Remove this comment to see the full error message
    testId,
    style,
    value,
    placeholder,
    min = -Number.MAX_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    locale = getUserLocale(),
    onChange = () => null,
    onValueChange = () => null,
    onFocus = () => null,
    onBlur = () => null,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
  }: CurrencyInputProps,
  ref: any
) {
  const inputRef = useRef(null);
  const { maskedValue, inputOnChange, inputOnBlur } = useFormattedNumberInput({
    min,
    max,
    locale,
    value,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    minimumSignificantDigits,
    maximumSignificantDigits,
    onChange,
    onValueChange,
  });

  function handleOnBlur(e: any) {
    inputOnBlur();
    if (onBlur) {
      onBlur(e);
    }
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.focus();
    },
    blur: () => {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.blur();
    },
  }));

  return (
    <input
      className={cx(styles.FormattedNumberInput, className)}
      data-is-negative={value < 0}
      style={style}
      ref={inputRef}
      value={maskedValue}
      placeholder={placeholder}
      onChange={e => inputOnChange(e.target.value)}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      type="text"
      data-test={testId}
    />
  );
}

const FormattedNumberInputForwarded = forwardRef(FormattedNumberInput);

export default FormattedNumberInputForwarded;

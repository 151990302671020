import React from 'react';

import { useParams } from 'react-router-dom';

import { META_TAG_TYPE_SIMPLE } from 'lane-shared/helpers/constants/metatags';
import { ChannelType } from 'lane-shared/types/ChannelType';

import useMetatagEdit from '../useMetatagEdit';
import MetatagEditComplex from './MetatagEditComplex';
import MetatagEditSimple from './MetatagEditSimple';

export default function MetatagEdit({ channel }: { channel: ChannelType }) {
  const { metatagId } = useParams<{ metatagId?: string }>();
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const metatagEdit = useMetatagEdit({ metatagId, channel });

  if (!metatagEdit.metatag) {
    return null;
  }

  if (metatagEdit.metatag.type === META_TAG_TYPE_SIMPLE) {
    return <MetatagEditSimple channel={channel} {...metatagEdit} />;
  }

  return <MetatagEditComplex channel={channel} {...metatagEdit} />;
}

import React from 'react';
import { Icon } from 'design-system-web';
import cx from 'classnames';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './StatusChip.scss';
import { getServiceRequestStatusLabel } from 'lane-shared/domains/workOrder/helpers';

export const StatusChip: React.FC<{ status?: string; className?: string }> = ({
  status,
  className,
}) => {
  let statusClassName = '';
  switch (status?.toLocaleLowerCase()) {
    case 'created':
      statusClassName = 'created';
      break;
    case 'accepted':
      statusClassName = 'accepted';
      break;
    case 'in progress':
      statusClassName = 'in-progress';
      break;
    case 'on hold':
      statusClassName = 'on-hold';
      break;
    case 'declined':
    case 'complete':
    case 'closed':
    case 'cancelled':
    case 'withdrawn':
      statusClassName = 'disabled';
      break;
    case 'active':
      statusClassName = 'active';
      break;
    case 'inactive':
      statusClassName = 'inactive';
      break;
    default:
      statusClassName = 'disabled';
      break;
  }

  const translatedStatus = getServiceRequestStatusLabel(status);

  return status ? (
    <div className={cx(styles.statusChip, className, styles[statusClassName])}>
      <Icon
        name="circle"
        className={styles.icon}
        set={ICON_SET_FONTAWESOME}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        type=""
      />
      <i className="fa-solid fa-circle" />
      {translatedStatus}
    </div>
  ) : (
    <></>
  );
};

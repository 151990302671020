import React, { useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getAdminClient } from 'lane-shared/apollo';
import { fromNow, longDateTimeZone } from 'lane-shared/helpers/formatters';
import { ChannelType } from 'lane-shared/properties/baseTypes/Channel';
import { EventNameType } from 'lane-shared/types/Analytics';
import { NotificationDeliveryTypeEnum } from 'lane-shared/types/NotificationDeliveryTypeEnum';

import IconButton from 'components/general/IconButton';
import { H3 } from 'components/typography';

import { PortalManagementUserEditType } from '../types';

import styles from './PortalManagementUserEventSubscriptions.scss';

const updateUserMutation = gql`
  mutation updateUserForEventSubscriptions($update: UserInput!) {
    updateUser(user: $update) {
      _id
    }
  }
`;

const userQuery = gql`
  query getUserEventSubscriptions($id: UUID!) {
    userAdmin(_id: $id) {
      _id
      eventSubscriptions {
        _id
        _created
        _updated
        event
        groupRoleId
        channelId
        contentId
        interactionId
        userId
        delivery
      }
    }
  }
`;

export default function PortalManagementUserEventSubscriptions({
  user,
}: {
  user: PortalManagementUserEditType;
}) {
  const [loading, setLoading] = useState(false);

  const { data } = useQuery<{
    userAdmin: {
      _id: LaneType.UUID;
      eventSubscriptions: {
        _id: LaneType.UUID;
        _created: LaneType.DateTime;
        _updated: LaneType.DateTime;
        event: EventNameType;
        channel: ChannelType;
        groupRoleId: LaneType.UUID;
        channelId: LaneType.UUID;
        contentId: LaneType.UUID;
        interactionId: LaneType.UUID;
        userId: LaneType.UUID;
        delivery: NotificationDeliveryTypeEnum[];
      }[];
    };
  }>(userQuery, {
    client: getAdminClient(),
    fetchPolicy: 'network-only',
    skip: !user._id,
    variables: {
      id: user._id,
    },
  });

  const eventSubscriptions = data?.userAdmin?.eventSubscriptions || [];

  async function deleteEventSubscription(_id: LaneType.UUID) {
    try {
      await window.Alert.confirm({
        title: 'Delete User Event Subscription.',
        message:
          'Are you sure you want to delete this user event subscription? This cannot be undone.',
        confirmText: 'Yes, delete',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      setLoading(true);

      await getAdminClient().mutate({
        mutation: updateUserMutation,
        refetchQueries: ['getUserEventSubscriptions'],
        variables: {
          update: {
            _id: user._id,
            eventSubscriptions: [
              {
                _pull: true,
                _id,
              },
            ],
          },
        },
      });
    } catch (err) {
      window.Alert.alert({
        title: 'Failed',
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.PortalManagementUserEventSubscriptions}>
      <H3>User Event Subscriptions</H3>
      <table className={styles.eventSubscriptions}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Channel ID</th>
            <th>Delivery</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eventSubscriptions.map(eventSubscription => (
            <tr key={eventSubscription._id}>
              <td>{eventSubscription._id}</td>
              <td>{longDateTimeZone(eventSubscription._created)}</td>
              <td>{fromNow(eventSubscription._updated)}</td>
              <td>{eventSubscription.channelId}</td>
              <td>
                {eventSubscription.delivery
                  ? eventSubscription.delivery.join(', ')
                  : ''}
              </td>
              <td>
                <IconButton
                  loading={loading}
                  icon="trash"
                  onClick={() => deleteEventSubscription(eventSubscription._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { useTeamQuery } from 'lane-shared/hooks';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import UserStackListView from '../../../lane/UserStackListView';
import styleMap from '../styleMap';

import styles from './GroupRoleBlock.scss';

type BlockProps = WebBlockProps & {
  groupRole: DocumentType;
};

export default function GroupRoleBlock({
  style,
  className,
  groupRole,
  ...otherProps
}: BlockProps) {
  const props = useEditModeProps(otherProps);
  const { team } = useTeamQuery({ groupRoleId: (groupRole as any)?._id });

  return (
    <div
      className={cx(styles.GroupRoleBlock, className)}
      style={styleMap(false, style)}
      {...props}
    >
      <h1 className={styles.header}>{team?.name}</h1>
      <ul className={styles.users}>
        {team?.users?.map((user: any) => (
          <Link to={`/l/user/${user._id}`} key={user._id}>
            <UserStackListView className={styles.User} user={user} />
          </Link>
        ))}
      </ul>
      <div className={styles.wrapper} />
    </div>
  );
}

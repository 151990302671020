import { Button } from 'design-system-web';
import { Modal } from 'components/lds';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateChargeCodeFormModal.scss';
import { Input, TextArea } from 'components/form';
import { ErrorMessage } from 'components';
import { useChannelAdminContext } from 'hooks';
import { ChargeCodeDetails } from '../../chargeCodes/CreateChargeCodeForm';
import { createChargeCodeMutation } from '../../helpers/createChargeCodeMutation';

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  onSave: () => void;
  testId?: string;
};

/**
 * Modal variant of @function CreateChargeCodeForm
 */
export function CreateChargeCodeFormModal({
  modalVisible,
  onClose,
  onSave,
  testId,
}: Props) {
  const { t } = useTranslation();
  const { channel } = useChannelAdminContext();
  const [error, setError] = useState<Error | undefined>(undefined);

  const [
    newChargeCodeDetails,
    setNewChargeCodeDetails,
  ] = useState<ChargeCodeDetails>({
    chargeCode: '',
    glCode: '',
    description: '',
  });

  const handleFieldUpdate = (value: any, fieldname: string) => {
    setNewChargeCodeDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const isSaveButtonDisabled = (): boolean => {
    const { chargeCode, glCode } = newChargeCodeDetails;

    return !(chargeCode && glCode);
  };

  const handleSaveChargeCode = async () => {
    try {
      await createChargeCodeMutation(
        {
          ...newChargeCodeDetails,
        },
        channel?._id || ''
      );
      onSave();
      onClose();
      window.Toast.show(
        t('abp.productsServices.createChargeCode.createSuccessful')
      );
    } catch (err) {
      console.error(err);
      setError(err as Error);
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      onClose={onClose}
      testId={testId}
      closeButtonSize="large"
      size="large"
      disableContentPadding
      title={t('abp.productsServices.createChargeCode.heading')}
      className={styles.createChargeCodeFormModal}
    >
      <div className={styles.form}>
        <div className={styles.formInputs}>
          <Input
            type="text"
            label={t('abp.productsServices.createChargeCode.chargeCode')}
            fixedLabel
            onChange={(value: string) => {
              handleFieldUpdate(value, 'chargeCode');
            }}
            fieldName="charge code"
            className={styles.fieldset}
            value={newChargeCodeDetails.chargeCode}
            isRequired
            testId="chargeCode"
          />
          <Input
            type="text"
            label={t('abp.productsServices.createChargeCode.glCode')}
            fixedLabel
            onChange={(value: string) => {
              handleFieldUpdate(value, 'glCode');
            }}
            fieldName="glcode"
            className={styles.fieldset}
            value={newChargeCodeDetails.glCode}
            isRequired
            testId="glcode"
          />
          <fieldset className={styles.fieldset}>
            <label className={styles.label}>
              {t('abp.productsServices.createChargeCode.description')}
            </label>
            <TextArea
              onChange={(value: string) => {
                handleFieldUpdate(value, 'description');
              }}
              value={newChargeCodeDetails.description}
              maxLength={200}
              showLengthIndicator
              minRows={4}
            />
          </fieldset>
        </div>
        <div className={styles.formButtons}>
          <Button
            className={styles.button}
            variant="primary"
            disabled={isSaveButtonDisabled()}
            onClick={handleSaveChargeCode}
            size="large"
          >
            {t('abp.productsServices.createChargeCode.save')}
          </Button>
          <Button
            className={styles.button}
            variant="secondary"
            onClick={onClose}
            size="large"
          >
            {t('abp.productsServices.createChargeCode.cancel')}
          </Button>
        </div>
        {error && <ErrorMessage error={error} className={styles.error} />}
      </div>
    </Modal>
  );
}

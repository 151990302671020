export const views = ['list', 'th-large'];
export type VIEW_LIST_TYPE = 'list';
export type VIEW_GRID_TYPE = 'th-large';
export type VIEW_TYPE = VIEW_LIST_TYPE | VIEW_GRID_TYPE;

export const [VIEW_LIST, VIEW_GRID] = views;
export const sorts = [
  '_created',
  '_updated',
  'name',
  'tags',
  'description',
  'interactive',
];

export const [
  SORT_CREATED,
  SORT_UPDATED,
  SORT_NAME,
  ,
  SORT_DESCRIPTION,
  SORT_INTERACTIVE,
] = sorts;

export const headers = [
  { label: 'Created', sort: SORT_CREATED },
  { label: 'Updated', sort: SORT_UPDATED },
  { label: 'Name', sort: SORT_NAME },
  { label: 'Description', sort: SORT_DESCRIPTION },
  { label: 'Interactive', sort: SORT_INTERACTIVE },
  { label: 'Tags' },
  { label: 'Warnings' },
];

export const COMPARABLE_SECTION_PROPERTIES = [
  'backgroundColor',
  'backgroundImage',
  'color',
  'filters',
  'icon',
  'iconSet',
  'iconWeight',
  'logo',
  'query',
  'sectionMetatags',
  'sorts',
  'groupBy',
];

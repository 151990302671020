import React, { useRef, useState } from 'react';

import { Icon } from 'design-system-web';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useEssensysPaymentSettings } from 'lane-shared/hooks';

import Input from 'components/form/Input';
import TextArea from 'components/form/TextArea';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import Label from 'components/general/Label';
import PaymentAccountRuleEdit from 'components/payments/PaymentAccountRuleEdit';
import PlacePayPaymentModal from 'components/payments/PlacePayPaymentModal';

import useManagePaymentAccount from './useManagePaymentAccount';

import styles from './PlacePayPaymentAccount.scss';

export default function PlacePayPaymentAccount({
  loading,
  channel,
  paymentAccount,
  currency,
  onPaymentAccountSave,
  onPaymentAccountRuleAdd,
  onPaymentAccountRuleSave,
  onPaymentAccountRuleDelete,
  onPaymentAccountDelete,
}: any) {
  const placePayRef = useRef({
    reject: () => null,
    resolve: () => null,
  });
  const [isPlacePayOpen, setIsPlacePayOpen] = useState(false);
  const { essensysPaymentSettings } = useEssensysPaymentSettings();
  const {
    rules,
    setRules,
    editedPaymentAccount,
    setEditedPaymentAccount,
    updatePaymentAccount,
    newRule,
    setNewRule,
    updateNewRule,
    deleteAccount,
    onRuleAdd,
    onRuleDelete,
    constructNewRule,
  } = useManagePaymentAccount({
    paymentAccount,
    onPaymentAccountRuleAdd,
    onPaymentAccountRuleDelete,
    onPaymentAccountDelete,
  });

  const _paymentAccount = editedPaymentAccount || paymentAccount;

  return (
    <section className={styles.PlacePayPaymentAccount}>
      <ControlMenu>
        <h1>Payment with PlacePay</h1>
        <hr />
        <Link
          className={styles.link}
          to={routes.channelAdminPaymentAccountTransactions
            .replace(':id', channel.slug)
            .replace(':paymentAccountId', paymentAccount?._id)}
        >
          View transactions
        </Link>
      </ControlMenu>

      <section className={styles.name}>
        <fieldset>
          <Input
            label="Display Name"
            value={_paymentAccount?.name}
            placeholder="Company Procurement Cards…"
            maxLength={40}
            onChange={name => updatePaymentAccount({ name })}
          />
        </fieldset>

        <fieldset>
          <label>Description</label>
          <TextArea
            className={styles.textArea}
            placeholder="Use this account to purchase items for the business."
            value={_paymentAccount?.description}
            onChange={description => updatePaymentAccount({ description })}
          />
        </fieldset>
      </section>

      <PlacePayPaymentModal
        isOpen={isPlacePayOpen}
        paymentAccountId={paymentAccount?._id}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        payeeId={essensysPaymentSettings?.orgpayeeid}
        mode="autopay"
        onClose={() => {
          setIsPlacePayOpen(false);
          placePayRef.current.reject();
        }}
        onPaymentSuccess={data => {
          setIsPlacePayOpen(false);
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.resolve(data);
        }}
        onPaymentFailed={err => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          placePayRef.current.reject(err);
          setIsPlacePayOpen(false);
        }}
      />

      {paymentAccount?._id && (
        <>
          <Label h2>
            <span style={{ lineHeight: '1em' }}>Payment Rules</span>
            <Icon
              name="plus-circle"
              set={ICON_SET_FONTAWESOME}
              className={styles.addIcon}
              disabled={!!newRule}
              onClick={() => constructNewRule()}
            />
          </Label>

          <ul className={styles.rules}>
            {rules?.map((rule, i) => (
              <li key={(rule as any)._id}>
                <PaymentAccountRuleEdit
                  loading={loading}
                  className={styles.paymentAccountRule}
                  currency={currency}
                  rule={rule}
                  channel={channel}
                  onRuleUpdated={(update: any) => {
                    // @ts-expect-error
                    rules[i] = {
                      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                      ...rules[i],
                      ...update,
                      __updated: true,
                    };
                    setRules([...rules]);
                  }}
                  onRuleSave={onPaymentAccountRuleSave}
                  onCancel={() => setNewRule(null)}
                />
                <Icon
                  name="times-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={styles.removeIcon}
                  onClick={() => onRuleDelete(rule)}
                />
              </li>
            ))}
            {newRule && (
              <li>
                <PaymentAccountRuleEdit
                  className={styles.paymentAccountRule}
                  currency={currency}
                  rule={newRule}
                  channel={channel}
                  onRuleUpdated={updateNewRule}
                  onRuleAdd={onRuleAdd}
                  onCancel={() => setNewRule(null)}
                  forCreate
                />
              </li>
            )}
          </ul>
        </>
      )}
      <ControlMenu>
        <hr />
        {paymentAccount?._id && (
          <>
            <Button
              color={'secondary' as any}
              variant="contained"
              loading={loading}
              onClick={deleteAccount}
            >
              Delete
            </Button>

            <Button
              disabled={!editedPaymentAccount}
              loading={loading}
              onClick={() => setEditedPaymentAccount(null)}
            >
              Undo
            </Button>
            <Button
              disabled={!editedPaymentAccount}
              loading={loading}
              onClick={() => onPaymentAccountSave(editedPaymentAccount)}
              variant="contained"
            >
              Save
            </Button>
            <Button variant="contained" onClick={() => setIsPlacePayOpen(true)}>
              Setup Accounts
            </Button>
          </>
        )}
      </ControlMenu>
    </section>
  );
}

import React, { useState } from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import { LaneType } from 'common-types';
import { getAdminClient } from 'lane-shared/apollo';
import { fromNow, longDateTimeZone } from 'lane-shared/helpers/formatters';

import IconButton from 'components/general/IconButton';
import { H3 } from 'components/typography';

import { PortalManagementUserEditType } from '../types';

import styles from './PortalManagementUserSessions.scss';

const updateUserMutation = gql`
  mutation updateUserForUserSessions($update: UserInput!) {
    updateUser(user: $update) {
      _id
    }
  }
`;

const userQuery = gql`
  query getUserSessions($id: UUID!) {
    userAdmin(_id: $id) {
      _id
      sessions {
        _id
        _created
        _updated
        expires
        ip
        device
        platform
        version
        hostname
        geo
      }
    }
  }
`;

export default function PortalManagementUserSessions({
  user,
}: {
  user: PortalManagementUserEditType;
}) {
  const [loading, setLoading] = useState(false);

  const { data } = useQuery<{
    userAdmin: {
      _id: LaneType.UUID;
      sessions: {
        _id: LaneType.UUID;
        _created: LaneType.DateTime;
        _updated: LaneType.DateTime;
        expires: LaneType.DateTime;
        ip: string;
        device: string;
        platform: string;
        version: string;
        hostname: string;
        geo: LaneType.GeoCoordinate;
      }[];
    };
  }>(userQuery, {
    client: getAdminClient(),
    fetchPolicy: 'network-only',
    skip: !user._id,
    variables: {
      id: user._id,
    },
  });

  const sessions = data?.userAdmin?.sessions || [];

  async function deleteUserLogin(_id: LaneType.UUID) {
    try {
      await window.Alert.confirm({
        title: 'Delete User Session.',
        message:
          'Are you sure you want to delete this user session? This cannot be undone.',
        confirmText: 'Yes, delete',
      });
    } catch (err) {
      // user cancelled
      return;
    }

    try {
      setLoading(true);

      await getAdminClient().mutate({
        mutation: updateUserMutation,
        refetchQueries: ['getUserSessions'],
        variables: {
          update: {
            _id: user._id,
            sessions: [
              {
                _pull: true,
                _id,
              },
            ],
          },
        },
      });
    } catch (err) {
      window.Alert.alert({
        title: 'Failed',
        error: err,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.PortalManagementUserSessions}>
      <H3>User Sessions</H3>
      <table className={styles.sessions}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Expires</th>
            <th>IP Address</th>
            <th>Device</th>
            <th>Platform</th>
            <th>Version</th>
            <th>Hostname</th>
            <th>Geo</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map(session => (
            <tr key={session._id}>
              <td>{session._id}</td>
              <td>{longDateTimeZone(session._created)}</td>
              <td>{fromNow(session._updated)}</td>
              <td>{fromNow(session.expires)}</td>
              <td>{session.ip}</td>
              <td>{session.device}</td>
              <td>{session.platform}</td>
              <td>{session.version}</td>
              <td>{session.hostname}</td>
              <td>{session.geo ? (session.geo as any).join(', ') : ''}</td>
              <td>
                <IconButton
                  loading={loading}
                  icon="trash"
                  onClick={() => deleteUserLogin(session._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import AdminContentCardLoader from 'components/cards/AdminContentCardLoader';
import ErrorMessage from 'components/general/ErrorMessage';
import SectionCardLoader from 'components/lane/SectionCardLoader';
import Panel from 'components/layout/Panel';
import { H4, H5 } from 'components/typography';

import useMetatagUsage from './useMetatagUsage';

import styles from './MetatagUsage.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  metatagId: LaneType.UUID;
};

export default function MetatagUsage({ className, style, metatagId }: Props) {
  const { metatagUsage, loading, error } = useMetatagUsage({
    metatagId,
  });

  const { t } = useTranslation();

  return (
    <div className={cx(styles.MetatagUsage, className)} style={style}>
      <ErrorMessage error={error} />

      <H4 mb={2}>{t('web.admin.content.metatag.tabItem.usage.sections')}</H4>
      <Panel>
        {!loading && metatagUsage.Section.length === 0 && (
          <H5>{t('web.admin.content.metatag.tabItem.usage.noSections')}</H5>
        )}
        {metatagUsage.Section.map(metatagUsage => (
          <SectionCardLoader
            key={metatagUsage._id}
            sectionId={metatagUsage._id}
          />
        ))}
      </Panel>

      <H4 mt={6} mb={2}>
        {t('web.admin.content.metatag.tabItem.usage.content')}
      </H4>
      <Panel className={styles.panel}>
        {!loading && metatagUsage.DraftContent.length === 0 && (
          <H5>{t('web.admin.content.metatag.tabItem.usage.noContent')}</H5>
        )}
        {metatagUsage.DraftContent.map(metatagUsage => (
          <AdminContentCardLoader
            key={metatagUsage._id}
            contentId={metatagUsage._id}
          />
        ))}
      </Panel>
    </div>
  );
}

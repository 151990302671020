import { useEffect, useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {  isEqual } from 'lodash';

import { convertToUUID } from 'lane-shared/helpers/convertId';
import { getTenantSuites } from 'lane-shared/graphql/tenant';
import { GetTenantSuitesQuery} from 'graphql-query-contracts';

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

export const useFetchSuitesByTenant = (tenantId: string | undefined, propertyId: string | undefined) => {
  const [suitesData, setSuitesData] = useState<GetTenantSuitesQuery | undefined>(undefined);
  const [retryFetchSuitesFailed, setRetryFetchSuitesFailed] = useState(false);
  const [isLoadingSuites, setIsLoadingSuites] = useState(false);

  const [fetchSuitesByTenant]= useLazyQuery<GetTenantSuitesQuery>(getTenantSuites, {
    fetchPolicy: 'network-only',
  });

  const fetchSuites = useCallback(async (tenantId: string | undefined, propertyId: string | undefined) => {
    if(tenantId && propertyId){
      setRetryFetchSuitesFailed(false);
      const { data } = await fetchSuitesByTenant({
        variables: {
          tenantId: convertToUUID(tenantId),
          propertyId: convertToUUID(propertyId),
        },
      });
      setSuitesData(data);
    }
  }, [fetchSuitesByTenant]);

  const retryFetchSuites = useCallback(async (tenantId: string | undefined, propertyId: string | undefined, previousSuitesData: any, retryAttempt : number) => {
    setRetryFetchSuitesFailed(false);
    setIsLoadingSuites(true);
    if(tenantId && propertyId){
      const { data } = await fetchSuitesByTenant({
        variables: {
          tenantId: convertToUUID(tenantId),
          propertyId: convertToUUID(propertyId),
        },
      });

      if(retryAttempt > 0 && retryAttempt < MAX_RETRIES && !isEqual(data, previousSuitesData)){
        setSuitesData(data);
        setIsLoadingSuites(false);
      }else if (retryAttempt >= MAX_RETRIES){
        setRetryFetchSuitesFailed(true);
        setIsLoadingSuites(false);
      } else {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        await retryFetchSuites(tenantId, propertyId, previousSuitesData, retryAttempt+1);
      }
    }
  }, [fetchSuitesByTenant]);

  useEffect(() => {
    if (tenantId) {
      fetchSuites(tenantId, propertyId);
    }
  }, [tenantId, propertyId, fetchSuites]);

  return {
    suitesData,
    fetchSuites,
    retryFetchSuites,
    retryFetchSuitesFailed,
    isLoadingSuites,
  };
};

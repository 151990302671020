import React, { useState } from 'react';

import { getAdminClient } from 'lane-shared/apollo';
import { resetUserPasswordsAdmin } from 'lane-shared/graphql/mutation';
import { pause } from 'lane-shared/helpers';
import { PasswordResetType } from 'lane-shared/types/PasswordResetType';

import Button from 'components/general/Button';

import { PortalManagementUserEditType } from '../types';

type ResetPasswordButtonProps = {
  user: PortalManagementUserEditType;
};

export const ResetPasswordButton = ({
  user,
}: ResetPasswordButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  function onResetUserPassword(user: PortalManagementUserEditType) {
    setLoading(true);

    const resetPassword = async () => {
      await pause(1000);

      try {
        const { data } = await getAdminClient().mutate({
          mutation: resetUserPasswordsAdmin,
          variables: { ids: [user._id] },
        });

        if (
          !data.resetUserPasswordsAdmin ||
          data.resetUserPasswordsAdmin.length === 0
        ) {
          throw new Error(
            'Could not generate reset code for this user, please try again'
          );
        }

        const resetData: PasswordResetType = data.resetUserPasswordsAdmin[0];

        window.Alert.alert({
          title: 'Reset Code',
          message: resetData.resetUrl,
        });
      } catch (err) {
        window.Alert.alert({
          title: 'Failed',
          error: err,
        });
      }

      setLoading(false);
    };

    resetPassword();
  }

  return (
    <Button loading={loading} onClick={() => onResetUserPassword(user)}>
      Reset Password
    </Button>
  );
};

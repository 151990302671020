import React, { useState } from 'react';

import { Table, PageSizeType, Sort } from 'design-system-web';

import { useChargeCodeListQuery } from '../helpers/useChargeCodeListQuery';
import { useChargeCodesTableColumns } from '../helpers/useGetChargeCodesTableColumns';

export function ChargeCodesTable() {
  const [pageSize, setPageSize] = useState<PageSizeType>(25);
  const [page, setPage] = useState(0);
  const chargeCodesTableColumns = useChargeCodesTableColumns();

  const [sort, setSort] = useState<Sort>({
    id: 'charge_code',
    direction: 'asc',
  });

  const { data: chargeCodeListQueryResponse } = useChargeCodeListQuery(
    page,
    pageSize,
    sort,
    ''
  );

  return (
    <Table
      columns={chargeCodesTableColumns}
      data={
        chargeCodeListQueryResponse?.accounts?.listChargeCode?.chargeCodes || []
      }
      isLoading={false}
      totalRows={
        chargeCodeListQueryResponse?.accounts?.listChargeCode?.pageInfo?.total
      }
      pageSize={pageSize}
      pagination="server"
      page={page}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
      onSortChange={setSort}
    />
  );
}

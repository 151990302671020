import { gql } from '@apollo/client';

export const getProductQuery = gql`
  query GetProduct($getProductRequest: GetProductRequest!) {
    getProduct(getProductRequest: $getProductRequest) {
      product {
        id
        name
        description
        groupId
        groupType
        productCategory {
          productCategoryId
          name
          description
          productType
          groupId
          groupType
        }
        amount
        markup {
          value
          markupAmountType
        }
        tax {
          value
          taxAmountType
        }
        chargeCode {
          id
          chargeCode
          glCode
          description
          groupId
          groupType
        }
        originalPrice {
          amount
          markup {
            value
            markupAmountType
          }
          tax {
            value
            taxAmountType
          }
        }
        receivables {
          type
          creditBundle {
            id
            creditCount
            creditValue
            expiryType
            expiryValue
          }
        }
        creditAmount
        sellingPrice {
          netPrice
          listPrice
          grossMarkup
          grossTax
        }
      }
    }
  }
`;

import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import { Flex } from 'components';
import { useTranslation } from 'react-i18next';

import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  roomSizes,
  RoomSize as RoomSizeType,
} from 'lane-shared/helpers/integrations/ShopifyBurst/burstRoomSize';

import Radio from 'components/form/Radio';
import { Button } from 'components/general';
import Chip from 'components/general/Chip';
import DropdownContextMenu from 'components/general/DropdownContextMenu';
import { M, XS } from 'components/typography';

import styles from './RoomSize.scss';

interface Props {
  attendeesRange?: RoomSizeType;
  onChange: (value?: RoomSizeType) => void;
  className?: string;
}

export default function RoomSize({
  onChange,
  attendeesRange,
  className,
}: Props) {
  const [isOpen, setOpen] = useState(false);

  const { t } = useTranslation();

  function handleCancel() {
    onChange();
    setOpen(false);
  }

  function handleSave(data: RoomSizeType) {
    onChange(data);
    setOpen(false);
  }

  const labelForChip = attendeesRange
    ? t(`{{range}} people`, { range: attendeesRange.label })
    : t('# of Attendees');
  const selected = attendeesRange?.label;

  return (
    <DropdownContextMenu
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      title={
        <Chip
          onClick={() => setOpen(!isOpen)}
          onDelete={() => setOpen(!isOpen)}
          deleteIcon={<Icon name="angle-down" set={ICON_SET_FONTAWESOME} />}
          selected={Boolean(attendeesRange?.min)}
          label={labelForChip}
          selectedIcon={null}
          className={className}
        />
      }
    >
      <div className={styles.OptionsMenu}>
        <M className={styles.headerText}>{t('# of Attendees')}</M>
        <XS variant="secondary" className={styles.textAttendees}>
          {t('How many people will be attending this burst ?')}
        </XS>

        <div className={styles.itemList}>
          {roomSizes.map((data, key) => {
            if (!data.label) {
              return null;
            }

            return (
              <Radio
                key={key}
                selected={selected}
                value={data.label}
                name="room-size-range"
                onChange={() => handleSave(data)}
                text={data.label}
                className={styles.option}
              />
            );
          })}
        </div>
        <hr className={styles.line} />
        <Flex>
          <Button className={styles.clearButton} onClick={handleCancel}>
            {t('Clear')}
          </Button>
        </Flex>
      </div>
    </DropdownContextMenu>
  );
}

import { LaneType } from 'common-types';
import { ChannelCreationError, NotAuthorizedError } from 'activate-errors';
import { hasPermission } from 'lane-shared/helpers';
import {
  PERMISSION_ADMIN,
  PERMISSION_CHANNEL_CREATE,
} from 'lane-shared/helpers/constants/permissions';
import { UserGroupRoleType } from 'lane-shared/types/UserGroupRole';

export default function checkCreateChannelPermissions({
  channel,
  me,
  userGroupRoles,
}: {
  channel: {
    parent?: {
      _id: LaneType.UUID;
    };
    groupRoles?: any[];
  };
  me: {
    _id: LaneType.UUID;
    isSuperUser: boolean;
  };
  userGroupRoles: UserGroupRoleType[];
}) {
  // You can't update groupRoles from here. Create the channel first, then add some.
  if (channel.groupRoles) {
    throw new ChannelCreationError(
      'Cannot create a Channel and specify group roles at the same time.'
    );
  }

  if (!me.isSuperUser && !channel.parent && !(channel.parent as any)?._id) {
    // If a non-super user is trying to create a channel without a Parent, this
    // automatically fails.  They can't add new channels into the system (yet).
    // only Lane Super Users can do that for now.
    throw new NotAuthorizedError();
  }

  if (channel.parent) {
    // Is this user allowed to create this channel?
    // If this user has {admin, channel.create} access on the parent channel yes.
    // If they are a SuperUser yes.
    const isAllowed = hasPermission(
      userGroupRoles,
      [PERMISSION_ADMIN, PERMISSION_CHANNEL_CREATE],
      channel.parent._id
    );

    if (!(me.isSuperUser || isAllowed)) {
      throw new NotAuthorizedError();
    }

    // Make sure the user isn't trying to set additional data on the Parent channel.
    // upsert helper will do recursive updates if we pass in that data.
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '{ _id: st... Remove this comment to see the full error message
    channel.parent = channel.parent._id;
  }
}

import { v4 as uuid } from 'uuid';

import { Validator } from 'lane-shared/types/Validator';
import { PackagedTypeEnum } from 'lane-shared/types/properties/PackagedTypeEnum';

import { OptionType } from './types';

export const DEFAULT_OPTION: OptionType = {
  _id: '',
  name: '',
  value: '',
  _order: 0,
};

export const DEFAULT_OPTION_SET: OptionType[] = [];

for (let i = 0; i < 5; i++) {
  DEFAULT_OPTION_SET.push({
    ...DEFAULT_OPTION,
    _id: uuid(),
    _order: i,
  });
}

export const ENABLED_INPUT_PACKAGED_TYPES = [
  {
    label: 'Open Response',
    value: PackagedTypeEnum.None,
  },
  {
    label: PackagedTypeEnum.Dropdown,
    value: PackagedTypeEnum.Dropdown,
  },
  {
    label: 'Approval Checkbox',
    value: PackagedTypeEnum.Checkboxes,
  },
];

export const ENABLED_INPUT_TYPES = {
  Boolean: 'Boolean',
  String: 'String',
  Option: 'Option',
};

export const REQUIRED_VALIDATOR: Validator = {
  name: 'Required',
  value: true,
};

export const DROPDOWN_VALIDATOR: Validator = {
  name: 'In',
  value: [],
};

export const DEFAULT_INPUT = {
  _id: '',
  placeholder: '',
  type: 'String',
  width: 150,
  packagedType: PackagedTypeEnum.None,
  isEditing: true,
  isCustomData: true,
};

export const DEFAULT_ISSUE_INPUT = {
  name: '',
  packagedType: PackagedTypeEnum.Dropdown,
  _id: uuid(),
  placeholder: '',
  type: 'Option',
  isArray: false,
  validators: [
    {
      name: 'In',
      value: [],
    },
  ],
  friendlyName: 'Select issue',
};

export const DEFAULT_CATEGORY_ISSUE_PROPERTY_OPTION = {
  rules: [
    {
      disabled: false,
      targetPropertyRef: 'category',
      targetPropertyCondition: {
        name: 'In',
        value: [
          {
            _id: uuid(),
            name: '',
            value: '',
            _order: 0,
          },
        ],
      },
    },
  ],
  propertyRef: '',
};

export const CATEGORY_FIELD_NAME = 'category';

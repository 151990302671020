import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { routes } from 'lane-shared/config';
import { useChannelPagesForAdmin } from 'lane-shared/hooks';
import { BurstFlowType } from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { IWizardState } from 'lane-shared/hooks/useWizard';
import { ShopifyBurstInteractionType } from 'lane-shared/types/integrations/ShopifyBurst';

import { IconButton } from 'components/general';
import { Flex, Box } from 'components/layout';
import { H4, S } from 'components/typography';

import styles from './BurstCreateHeader.scss';

type WizardState = IWizardState<Partial<ShopifyBurstInteractionType>>;

type Props = {
  title: string;
  wizardState: WizardState;
  burstContent: any;
};

/**
 *
 */
export default function BurstCreateHeader({
  title,
  wizardState,
  burstContent,
}: Props) {
  const history = useHistory();
  const { t } = useTranslation();
  const { channelPages } = useChannelPagesForAdmin({
    channelId: burstContent?.channel?._id,
  });
  const { burstFlowType } = useShopifyBurstContext();

  const burstPage = channelPages.find(
    page => page?.content?._id === burstContent?._id
  );

  const progressLevel = useMemo(
    () =>
      ({
        '--progress-level': `${
          ((wizardState.currentStepIndex + 1) / wizardState.numSteps) * 100
        }%`,
      } as React.CSSProperties),
    [wizardState.currentStepIndex]
  );

  function returnToBurstPage() {
    history.replace(
      burstPage
        ? routes.customTab
            .replace(':channelSlug', 'bursting-app')
            .replace(':tabSlug', burstPage.slug)
        : routes.content.replace(':id', burstContent?._id)
    );
  }

  return (
    <Box p={5} className={styles.container} style={progressLevel}>
      {burstFlowType === BurstFlowType.UserEdit ? (
        <Flex justify="space-between">
          <IconButton
            icon="times"
            iconSet="FontAwesome"
            type="far"
            className={styles.button}
            onClick={returnToBurstPage}
          />
        </Flex>
      ) : null}
      <Flex align="center" direction="column" className={styles.headerText}>
        <S className={styles.burstName}>{wizardState.data.name}</S>
        <H4 bold>{t(title)}</H4>
      </Flex>
    </Box>
  );
}

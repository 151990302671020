import React from 'react';

import cx from 'classnames';

import RadioGroup from 'components/form/RadioGroup';
import ValidationMessage from 'components/general/ValidationMessage';

import styleMap from '../../styleMap';

import styles from './RadiosInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function RadiosInputBlock({
  keyPass = '',
  options = [],
  selected = null,
  onInput = (_option: any | undefined) => {},
  disabled = false,
  isValid = true,
  validationMessage = 'Required.',
  style = {},
  className,
}: any) {
  return (
    <div
      className={cx(styles.RadiosInputBlock, className)}
      style={styleMap(false, style)}
    >
      <RadioGroup
        name={keyPass}
        schema={{ id: 'name', text: 'value' }}
        disabled={disabled}
        selected={selected ? selected.value : null}
        items={options}
        onChange={(value: any) =>
          onInput(options.find((option: any) => option.value === value))
        }
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
    </div>
  );
}

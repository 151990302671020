import React from 'react';

import { ErrorMessage } from 'components/general';
import { H5 } from 'components/typography';

import styles from './shared.scss';

type Props = {
  error: any;
  children: React.ReactNode;
};

export default function WidgetContentWrapper({ error, children }: Props) {
  if (error) {
    return (
      <div className={styles.emptyStateContainer}>
        <ErrorMessage error={error} />
        <H5 className={styles.emptyStateText}>Data unavailable</H5>
      </div>
    );
  }

  return <>{children}</>;
}

import React, { useState, useContext, useMemo, useEffect } from 'react';

import { AdminPage } from 'components/layout';
import { H4 } from 'components/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.scss';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  Column,
  QueryString,
  getPageSizeFromQueryString,
  ModalBackground,
  NativeFilterTypes,
  FilterType,
} from 'design-system-web';
import {
  ControlMenu,
  FileInput,
  ResizableWindow,
  AddTenantRelationship,
} from 'components';
import { UserDataContext } from 'lane-shared/contexts';
import useChannelAdminContext from 'hooks/useChannelAdminContext';
import { toSchema } from 'lane-shared/helpers';
// import { PERMISSION_PROPERTY_CSV_UPLOAD } from 'lane-shared/helpers/constants/permissions';
import { useQuery } from '@apollo/client';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import { useQueryString, usePersistedParams } from 'lane-web/src/hooks';
import { shortAddress, imageUrl } from 'lane-shared/helpers/formatters';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';
import { getClient } from 'lane-shared/apollo';
import {
  createAutoSetupConfiguration,
  uploadCSVMutation,
} from 'graphql-queries';
import { TENANT_TYPES } from 'lane-shared/helpers/constants/channel';
import { Thumbnail } from 'lane-web/src/components/general';

type ModeType = 'relatedTo' | 'channel';
const PER_PAGE = 50;
const TABLE_STATE_STORAGE_VARIABLE =
  'relationships.tenant-management-table-state';

type ServiceRequestQueryString = {
  keyword: string;
  total: number;
  company: string;
  category: string;
  status: string;
  issue: string;
  location: string;
  assignee: string;
  created_at: string;
} & QueryString;

const DEFAULT_SEARCH_PARAMS = {
  // table params
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
  sortBy: 'name',
  sortDirection: 'asc',
};

const TRANSLATION_KEYS = {
  uploadSuccessToast:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadSuccessToast',
  uploadErrorTitle:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadError.title',
  uploadErrorMessage:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadError.message',
  createNewTenantButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.createNewTenantButton',
  csvImportButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.csvImportButton',
  uploadCSVButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.uploadCSVButton',
  addButton:
    'web.pages.portal.admin.channel.relationships.propertyChannelRelationships.addButton',
  emptyTableMessage:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.emptyTable.message',
  header:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.header',
  tenantName:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.tenantName',
  address:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.address',
  type:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.type',
  squareFootage:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.squareFootage',
  activeMembers:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.activeMembers',
  isSubtenant:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.isSubtenant',
  isSubtenantYes:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.isSubtenant.yes',
  isSubtenantNo:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.isSubtenant.no',
  sqrt:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.squareFootage.sqft',
  tenantErrorTitle:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.error.message',
  tenantErrorMessage:
    'web.pages.portal.admin.channel.relationships.newPropertyChannelRelationships.table.error.message',
};

export function NewPropertyChannelRelationships() {
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvOpen, setIsCsvOpen] = useState(false);
  const { channel } = useChannelAdminContext();

  const storedTableState = window.localStorage.getItem(
    TABLE_STATE_STORAGE_VARIABLE
  );
  const initialTableParams = storedTableState
    ? JSON.parse(storedTableState)
    : DEFAULT_SEARCH_PARAMS;

  const [
    searchParams,
    setSearchParams,
  ] = useQueryString<ServiceRequestQueryString>(DEFAULT_SEARCH_PARAMS);

  function getVariables(mode: ModeType) {
    const pagination = {
      start:
        ((searchParams?.page || 0) as number) *
        getPageSizeFromQueryString(searchParams?.pageSize),
      perPage: getPageSizeFromQueryString(searchParams?.pageSize),
    };
    const channelSearch = {
      ...(searchParams.sortBy
        ? {
            sortBy: {
              key: searchParams.sortBy,
              dir: searchParams.sortDirection,
            },
          }
        : {}),
      ...(searchParams.keyword
        ? {
            search: {
              type: 'like',
              value: searchParams.keyword,
            },
          }
        : {}),
      ...(searchParams.type
        ? {
            type: {
              any: searchParams.type.split(','),
            },
          }
        : {}),
    };
    const idSearch = { _id: channel?._id };

    const variables = {
      pagination,
      search: {
        relatedTo: mode === 'relatedTo' ? channelSearch : idSearch,
        channel: mode === 'relatedTo' ? idSearch : channelSearch,
      },
    };
    return variables;
  }

  usePersistedParams({
    searchParams,
    initialTableParams,
    tableStorageVariable: TABLE_STATE_STORAGE_VARIABLE,
  });

  const { data, loading, error } = useQuery(queryChannelsByRelationship, {
    variables: getVariables('channel'),
  });

  if (error) {
    window.Alert.show({
      title: t(TRANSLATION_KEYS.tenantErrorTitle),
      message: t(TRANSLATION_KEYS.tenantErrorMessage),
      error,
    });
  }

  const totalTenantRequests = data?.channelsByRelationship?.pageInfo?.total;
  const relationships = data?.channelsByRelationship?.items;

  useEffect(() => {
    if (totalTenantRequests) {
      setSearchParams({ total: totalTenantRequests });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.channelsByRelationship?.pageInfo]);

  const columns: Column<any>[] = [
    {
      key: 'name',
      header: (
        <span style={{ paddingLeft: '3rem' }}>
          {t(TRANSLATION_KEYS.tenantName)}
        </span>
      ),
      renderCell: (name: string, row: any) => (
        <div className={styles.logoWrapper}>
          <Thumbnail
            className={styles.logo}
            src={row.logo || row.image}
            name={name}
            outline={false}
          />
          <Link
            to={`/l/channel/${channel?.slug}/admin/relationships/tenant/${row?._id}`}
          >
            {name}
          </Link>
        </div>
      ),
      disableVisibilityToggle: true,
    },
    {
      key: 'type',
      header: t(TRANSLATION_KEYS.type),
      type: 'text',
    },
    {
      key: 'subscribers',
      disableSorting: true,
      header: t(TRANSLATION_KEYS.activeMembers),
      type: 'text',
    },
    {
      key: 'sf',
      disableSorting: true,
      header: t(TRANSLATION_KEYS.squareFootage),
      type: 'text',
    },
    {
      key: 'address',
      disableSorting: true,
      header: t(TRANSLATION_KEYS.address),
      type: 'text',
    },
    {
      key: 'isSub',
      disableSorting: true,
      header: t(TRANSLATION_KEYS.isSubtenant),
      type: 'text',
    },
  ];
  const tableRows = useMemo(() => {
    return parseTenantListRequests(relationships) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationships]);

  function parseTenantListRequests(serviceRequests: any[]) {
    return serviceRequests?.map((relationship: any) => {
      const row: { [key: string]: any } = {
        name: getDisplayProfileName(relationship.channel),
        type: relationship.channel?.type,
        subscribers: relationship.channel?.stats?.subscribers,
        sf: `${relationship.channel?.stats?.sf} ${t(TRANSLATION_KEYS.sqrt)}`,
        address: shortAddress(relationship.channel.address),
        isSub: relationship.channel?.isSub
          ? t(TRANSLATION_KEYS.isSubtenantYes)
          : t(TRANSLATION_KEYS.isSubtenantNo),
        slug: relationship.channel?.slug,
        _id: relationship.channel?._id,
        logo: imageUrl(relationship.channel?.profile?.logo),
        image: imageUrl(relationship.channel?.profile?.image),
      };
      return row;
    });
  }

  const renderTable = () => {
    const filters: FilterType[] = [
      {
        isPromoted: true,
        key: 'type',
        label: t(TRANSLATION_KEYS.type),
        type: NativeFilterTypes.Multiselect,
        options: Object.values(TENANT_TYPES).map(toSchema),
      },
    ];
    return (
      <Table
        columns={columns}
        data={tableRows}
        hasKeywordFilter
        showColumnVisibility
        totalRows={Number(searchParams.total)}
        filters={filters}
        tableKey="relationship.relationshipRequestsTable"
        emptyMessage={t(TRANSLATION_KEYS.emptyTableMessage)}
        queryStringsEnabled
        isLoading={loading}
        pagination="server"
      />
    );
  };

  const handleFileUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setIsLoading(true);

    try {
      const { data } = await getClient().mutate({
        mutation: uploadCSVMutation,
        variables: {
          uploadCSVData: {
            text: file,
            contentType: 'text/csv',
            fileName: name,
          },
        },
      });

      if (data.uploadCSV.fileUrl) {
        await getClient().mutate({
          mutation: createAutoSetupConfiguration,
          variables: {
            autoSetupData: {
              source: 'csv',
              sourceLocations: [data.uploadCSV.fileUrl],
              parentChannelName: channel && channel.name,
              parentChannelId: channel && channel._id,
              tenantsOnly: true,
            },
          },
        });

        window.Toast.show(t(TRANSLATION_KEYS.uploadSuccessToast));
        setIsCsvOpen(false);
      }
    } catch (e) {
      window.Alert.show({
        title: t(TRANSLATION_KEYS.uploadErrorTitle),
        message: t(TRANSLATION_KEYS.uploadErrorMessage),
        error: e,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AdminPage className={styles.ChannelRelationships}>
      <div className={styles.header}>
        <H4>{t(TRANSLATION_KEYS.header)}</H4>
        <ControlMenu>
          {user?.isSuperUser && (
            <Link to="relationships/new">
              <Button
                onClick={() => setIsOpen(true)}
                loading={isLoading}
                testId="createTenantButton"
                variant="primary"
              >
                {t(TRANSLATION_KEYS.createNewTenantButton)}
              </Button>
            </Link>
          )}
          {/* {hasAnyPermission([PERMISSION_PROPERTY_CSV_UPLOAD], channel?._id) && (
            <Button
              onClick={() => setIsCsvOpen(true)}
              loading={isLoading}
              variant="secondary"
            >
              {t(TRANSLATION_KEYS.csvImportButton)}
            </Button>
          )} */}
          {user?.isSuperUser && (
            <Button
              onClick={() => setIsOpen(true)}
              loading={isLoading}
              testId="addTenantButton"
              variant="secondary"
            >
              {t(TRANSLATION_KEYS.addButton)}
            </Button>
          )}
        </ControlMenu>
      </div>
      {renderTable()}

      <ModalBackground
        className={styles.background}
        isOpen={isCsvOpen}
        onClose={() => setIsCsvOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          defaultPosition={ResizableWindow.centerPosition()}
          name="csv-tenant-upload"
        >
          <FileInput
            accept="text/csv"
            type={FileReturnTypeEnum.Text}
            onFileSelected={handleFileUpload}
          >
            <Button
              style={{ marginLeft: '2rem', marginBottom: '1rem' }}
              loading={loading}
            >
              {t(TRANSLATION_KEYS.uploadCSVButton)}
            </Button>
          </FileInput>
        </ResizableWindow>
      </ModalBackground>
      <ModalBackground
        className={styles.background}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <ResizableWindow
          className={styles.window}
          defaultPosition={ResizableWindow.centerPosition()}
          name="add-new-teant"
        >
          <AddTenantRelationship
            setIsOpen={setIsOpen}
            setLoading={setIsLoading}
          />
        </ResizableWindow>
      </ModalBackground>
    </AdminPage>
  );
}

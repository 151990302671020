export function getErrorMessage(listData: any, msg: string) {
  if (Array.isArray(listData)) {
    for (const item of listData) {
      msg = getErrorMessage(item, msg);
    }
  } else if (typeof listData === 'object' && listData !== null) {
    for (const key in listData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      msg = getErrorMessage(listData[key], msg);
    }
  } else if (typeof listData === 'string') {
    msg = `${msg + listData}\n`;
  }
  return msg;
}

import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import currencyCodes from 'currency-codes';
import { ValidationError } from 'yup';

import { toSchema, getValidationMessages } from 'lane-shared/helpers';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import {
  ContractUnitEnum,
  ChannelContractNameEnum,
  ChannelContractLineItemType,
} from 'lane-shared/types/ChannelContract';
import { IntegrationProviderEnum } from 'lane-shared/types/integrations/IntegrationEnums';

import DatePickerButton from 'components/form/DatePickers/DatePickerButton';
import Dropdown from 'components/form/Dropdown';
import FormattedNumberInput from 'components/form/FormattedNumberInput';
import IconButton from 'components/general/IconButton';
import Label from 'components/general/Label';
import ValidationMessage from 'components/general/ValidationMessage';

import styles from './ChannelContractLineItemEdit.scss';

const lineItemOptions = [
  ...Object.values(ChannelContractNameEnum),
  ...Object.values(IntegrationProviderEnum),
].map(toSchema);

const currencyOptions = currencyCodes.codes().map(toSchema);

const unitTypeOptions = Object.values(ContractUnitEnum).map(toSchema);

type Props = {
  style?: React.CSSProperties;
  className?: string;
  lineItem: ChannelContractLineItemType;
  validation?: ValidationError;
  validationPath: string;
  onLineItemUpdated: (
    lineItem: ChannelContractLineItemType,
    update: Partial<ChannelContractLineItemType>
  ) => void;
  onLineItemDeleted: (lineItem: ChannelContractLineItemType) => void;
};

export default function ChannelContractLineItemEdit({
  style,
  className,
  lineItem,
  validation,
  validationPath,
  onLineItemUpdated,
  onLineItemDeleted,
}: Props) {
  return (
    <li
      className={cx(styles.ChannelContractLineItemEdit, className)}
      style={style}
    >
      <div className={styles.row}>
        <div className={styles.group}>
          <Label className={styles.label}>Item</Label>
          <Dropdown
            className={styles.dropdownItem}
            placeholder="Item"
            onValueChange={name => onLineItemUpdated(lineItem, { name })}
            items={lineItemOptions}
            value={lineItem.name}
          />
          <ValidationMessage
            errors={getValidationMessages(validation, `${validationPath}.name`)}
          />
        </div>

        <div className={styles.group}>
          <Label className={styles.label}>Units</Label>
          <FormattedNumberInput
            className={styles.input}
            placeholder="1,000,000"
            value={lineItem.units}
            min={0}
            max={Number.MAX_SAFE_INTEGER}
            maximumFractionDigits={0}
            onChange={units =>
              onLineItemUpdated(lineItem, {
                units: parseInt(units, 10) || 0,
              })
            }
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; placeholder: string; value... Remove this comment to see the full error message
            errors={getValidationMessages(
              validation,
              `${validationPath}.units`
            )}
          />
        </div>

        <div className={styles.group}>
          <Label className={styles.label}>Unit Type</Label>
          <Dropdown
            className={styles.unitType}
            placeholder="Unit Type"
            onValueChange={unitType =>
              onLineItemUpdated(lineItem, { unitType })
            }
            value={lineItem.unitType}
            items={unitTypeOptions}
          />
          <ValidationMessage
            errors={getValidationMessages(
              validation,
              `${validationPath}.unitType`
            )}
          />
        </div>

        <div className={styles.group}>
          <Label className={styles.label}>Price per Unit in Cents</Label>
          {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ value: number; placeholder: string; min: n... Remove this comment to see the full error message */}
          <FormattedNumberInput
            value={lineItem.pricePerUnit / CURRENCY_CONVERSION}
            placeholder="e.g. 350"
            min={0}
            max={Number.MAX_SAFE_INTEGER}
            minimumFractionDigits={0}
            maximumFractionDigits={2}
            onValueChange={pricePerUnit =>
              onLineItemUpdated(lineItem, {
                pricePerUnit: pricePerUnit * CURRENCY_CONVERSION,
              })
            }
          />
          <ValidationMessage
            errors={getValidationMessages(
              validation,
              `${validationPath}.pricePerUnit`
            )}
          />
        </div>

        <div className={styles.group}>
          <Label className={styles.label}>Currency</Label>
          <Dropdown
            className={styles.dropdown}
            placeholder="Currency"
            onValueChange={currency =>
              onLineItemUpdated(lineItem, { currency })
            }
            value={lineItem.currency}
            items={currencyOptions}
          />
          <ValidationMessage
            errors={getValidationMessages(
              validation,
              `${validationPath}.currency`
            )}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.group}>
          <Label className={styles.label}>
            Estimated Subscription Start Date
          </Label>
          <div className={styles.datePickerWrapper}>
            <DatePickerButton
              value={lineItem.estimatedSubscriptionStartDate}
              includeTime={false}
              onChange={(estimatedSubscriptionStartDate: any) =>
                onLineItemUpdated(lineItem, {
                  estimatedSubscriptionStartDate,
                })
              }
            />
            <IconButton
              inverted
              icon="times"
              onClick={() =>
                onLineItemUpdated(lineItem, {
                  estimatedSubscriptionStartDate: undefined,
                })
              }
            />
          </div>
          <ValidationMessage
            errors={getValidationMessages(
              validation,
              `${validationPath}.estimatedSubscriptionStartDate`
            )}
          />
        </div>

        <div className={styles.group}>
          <Label className={styles.label}>Subscription Start Date</Label>
          <div className={styles.datePickerWrapper}>
            <DatePickerButton
              value={lineItem.subscriptionStartDate}
              includeTime={false}
              onChange={(subscriptionStartDate: any) =>
                onLineItemUpdated(lineItem, { subscriptionStartDate })
              }
            />
            <IconButton
              inverted
              icon="times"
              onClick={() =>
                onLineItemUpdated(lineItem, {
                  subscriptionStartDate: undefined,
                })
              }
            />
          </div>
        </div>
        <ValidationMessage
          errors={getValidationMessages(
            validation,
            `${validationPath}.subscriptionStartDate`
          )}
        />
      </div>
      <Icon
        name="times-circle"
        set={ICON_SET_FONTAWESOME}
        className={styles.removeIcon}
        onClick={() => onLineItemDeleted(lineItem)}
      />
    </li>
  );
}

import React from 'react';

import { routes } from 'lane-shared/config';

import ResetPassword from 'components/authV2/ResetPassword';

import WelcomeTemplate from './WelcomeTemplate';

import styles from './ResetPasswordPage.scss';

export default function ResetPasswordPage({ history, match }: any) {
  function resetPasswordSuccess() {
    history.push(routes.login);
  }

  return (
    <WelcomeTemplate>
      <div className={styles.resetPasswordPage}>
        <ResetPassword
          guid={match.params.guid}
          verify={match.params.verify}
          onResetPassword={resetPasswordSuccess}
          onResetPasswordFailed={() => null}
        />
      </div>
    </WelcomeTemplate>
  );
}

import { Mapping } from './types';

export const ANGUS_EQUIPMENT_MAPPING: Mapping = {
  equipment_id: { keys: [], transforms: ['index'] },
  name: { keys: ['Description'] },
  created_by: { keys: [], default: '[INSERT CREATOR ID]' },
  updated_by: { keys: [], default: '[INSERT UPDATER ID]' },
  category: { keys: ['System'] },
  status: { keys: [], default: 'Active' },
  warranty_expiration_date: { keys: ['Warranty Expiry'], transforms: ['date'] },
  install_date: { keys: ['Date Installed'], transforms: ['date'] },
  make: { keys: ['Make'] },
  model: { keys: ['Model'] },
  serial: { keys: ['Serial Number'] },
  notes: { keys: ['Notes'] },
  location: { keys: [], default: '[INSERT LOCATION]' },
  asset: { keys: [], default: '[INSERT ASSET]' },
  floor: { keys: ['Floor'] },
  suite: { keys: ['Suite'] },
  created_at: { keys: [], default: '[INSERT CREATED AT]' },
  updated_at: { keys: [], default: '[INSERT UPDATED AT]' },
};

export const ANGUS_SERVICE_REQUESTS_IMPORT: Mapping = {
  service_request_id: { keys: [], transforms: ['index'] },
  company: { keys: [], default: '[INSERT COMPANY ID]' },
  description: { keys: ['Details'] },
  category: { keys: ['Trade'] },
  issue: { keys: ['Request Type'] },
  location: { keys: [], default: '[INSERT LOCATION]' },
  floor: { keys: [], default: '[INSERT FLOOR]' },
  suite: { keys: [], default: '[INSERT SUITE]' },
  created_by: { keys: [], default: '[INSERT CREATOR ID]' },
  assignee: { keys: [], default: '[INSERT ASSIGNEE ID]' },
  status: { keys: ['Status'] },
  submit_on_behalf_of: { keys: [], default: '[INSERT SUBMITTER ID]' },
  updated_by: { keys: [], default: '[INSERT UPDATER ID]' },
  notes: { keys: [] },
  created_at: { keys: [], default: '[INSERT CREATED AT]' },
  updated_at: { keys: [], default: '[INSERT UPDATED AT]' },
  is_billable: { keys: [], default: '[INSERT IS BILLABLE]' },
};

export const ANGUS_SCHEDULES_IMPORT: Mapping = {
  schedule_id: { keys: [], transforms: ['index'] },
  title: { keys: ['Task'] },
  next_due_date: { keys: [], default: '[INSERT NEXT DUE DATE]' },
  until_date: { keys: ['Start / End'], transforms: ['date_range_end'] },
  repeats: { keys: ['Rec. Period'] },
  days_ahead: { keys: [], default: '[INSERT DAYS AHEAD]' },
  assignee: { keys: [], default: '[INSERT ASSIGNEE ID]' },
  notes: { keys: ['Schedule Description'] },
  time_to_complete: {
    keys: ['Est. Time (h:mm)'],
    transforms: ['hours_to_number'],
  },
  steps: { keys: [], default: '[INSERT STEPS]' },
  meter_reading: { keys: [], default: '[INSERT METER READING]' },
  created_by: { keys: [], default: '[INSERT CREATOR ID]' },
  location: { keys: ['Floor / Suite'] },
  created_at: { keys: [], default: '[INSERT CREATED AT]' },
  updated_at: { keys: [], default: '[INSERT UPDATED AT]' },
  updated_by: { keys: [], default: '[INSERT UPDATER ID]' },
};

export const ANGUS_TASKS_IMPORT: Mapping = {
  task_id: { keys: [], transforms: ['index'] },
  due_date: { keys: [], default: '[INSERT DUE DATE]' },
  repeats: { keys: ['Rec. Period'] },
  estimate_time_to_complete: { keys: ['Est. Time (h:mm)'] },
  assignee: { keys: [], default: '[INSERT ASSIGNEE ID]' },
  notes: { keys: ['Detail'] },
  hours: { keys: ['Est. Time (h:mm)'] },
  steps: { keys: [], default: '[INSERT STEPS]' },
  created_by: { keys: [], default: '[INSERT CREATOR ID]' },
  created_at: { keys: [], default: '[INSERT CREATED AT]' },
  updated_at: { keys: [], default: '[INSERT UPDATED AT]' },
  updated_by: { keys: [], default: '[INSERT UPDATER ID]' },
  meter_reading: { keys: [], default: '[INSERT METER READING]' },
  location: { keys: ['Floor / Suite'] },
  status: { keys: [], default: '[INSERT STATUS]' },
  schedule_id: { keys: [], default: '[INSERT SCHEDULE ID]' },
  schedule_notes: { keys: ['Task Line Details'] },
};

export const IMPORT_TYPES: { [key: string]: Mapping } = {
  angus_equipment: ANGUS_EQUIPMENT_MAPPING,
  angus_service_requests: ANGUS_SERVICE_REQUESTS_IMPORT,
  angus_schedules: ANGUS_SCHEDULES_IMPORT,
  angus_tasks: ANGUS_TASKS_IMPORT,
  custom: {},
};

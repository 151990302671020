/* eslint-disable */
import React, { useState, useEffect } from 'react';

import gql from 'graphql-tag';

import { getAdminClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import FullChannelContractFragment from 'lane-shared/graphql/fragments/FullChannelContractFragment';
import ProfileFragment from 'lane-shared/graphql/fragments/ProfileFragment';
import PublicUserFragment from 'lane-shared/graphql/fragments/PublicUserFragment';
import { castGraphQLObject, pause } from 'lane-shared/helpers';
import { ChannelContractType } from 'lane-shared/types/ChannelContract';

import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import ErrorMessage from 'components/general/ErrorMessage';
import AdminPage from 'components/layout/AdminPage';

import ChannelContractDetails from '../new/ChannelContractDetails';
import ChannelContractEdit from '../new/ChannelContractEdit';

const contractQuery = gql`
  ${PublicUserFragment}
  ${ProfileFragment}
  ${FullChannelContractFragment}

  query contracQuery($id: UUID!) {
    channelContract(id: $id) {
      ...FullChannelContractFragment
    }
  }
`;

const updateContractMutation = gql`
  mutation updateContract($channelContract: ChannelContractInput!) {
    updateChannelContract(channelContract: $channelContract) {
      _id
    }
  }
`;

export default function EditChannelContract({ history, match }: any) {
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [contract, setContract] = useState<ChannelContractType>(null);

  async function fetchContract(id: any) {
    setLoading(true);

    try {
      const { data } = await getAdminClient().query({
        query: contractQuery,
        variables: {
          id,
        },
      });

      setContract(castGraphQLObject(data.channelContract));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  function updateChannelContract(props: Partial<ChannelContractType>) {
    setContract(prevState => ({
      ...prevState,
      ...props,
    }));
  }

  async function updateContract() {
    if (validation) {
      return;
    }

    try {
      setError(null);
      setLoading(true);
      await pause();

      await getAdminClient().mutate({
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(props: Partial<ChannelContractType>) => voi... Remove this comment to see the full error message
        mutation: updateChannelContract,
        variables: { channelContract: contract },
      });
    } catch (err) {
      setError(err);
      window.Alert.alert({
        title: `Error creating contract.`,
        message: `This contract was not created, please see the error below and try again.`,
        error: err,
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    if (match?.params?.contractId) {
      fetchContract(match.params.contractId);
    }
  }, [match?.params?.contractId]);

  return (
    <AdminPage>
      <ControlMenu>
        <hr />
        <Button
          variant="contained"
          onClick={() => setIsEditing(true)}
          disabled={loading}
        >
          Edit
        </Button>
      </ControlMenu>

      <ErrorMessage error={error} />

      {contract && isEditing && (
        <ChannelContractEdit
          contract={contract}
          onChannelContractUpdated={updateChannelContract}
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ValidationError | null' is not a... Remove this comment to see the full error message
          onValidation={validation => setValidation(validation)}
        />
      )}

      {contract && !isEditing && <ChannelContractDetails contract={contract} />}
    </AdminPage>
  );
}

import React from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import { useChannelProfileQuery } from 'lane-shared/hooks';

import { H4 } from 'components/typography';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  channelId: string | null | undefined;
};

export default function ChannelHeader({ style, className, channelId }: Props) {
  const { channel } = useChannelProfileQuery({ channelId });

  return (
    <H4 className={className} style={style}>
      {getDisplayName(channel)}
    </H4>
  );
}

import React, { useState } from 'react';

import { Table, PageSizeType } from 'design-system-web';

import { useCategoryListQuery } from '../helpers/useCategoryListQuery';
import { useGetCategoriesTableColumns } from '../helpers/useGetCategoriesTableColums';

export function CategoriesTable() {
  const [pageSize, setPageSize] = useState<PageSizeType>(50);
  const [page, setPage] = useState(0);
  const categoriesTableColumns = useGetCategoriesTableColumns();
  const { data: queryResponse } = useCategoryListQuery({ page, pageSize });

  return (
    <Table
      columns={categoriesTableColumns}
      data={queryResponse?.listProductCategories.productCategory || []}
      disableSorting
      isLoading={false}
      pagination="server"
      totalRows={queryResponse?.listProductCategories.pageInfo?.total}
      pageSize={pageSize}
      page={page}
      onPageChange={setPage}
      onPageSizeChange={setPageSize}
    />
  );
}

import React, { useState, useRef, useEffect } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { LaneType } from 'common-types';
import { cleanupDraftBurstReservations } from 'lane-shared/graphql/integrations/ShopifyBurst/burstDraft';
import { getTimeZoneByGeoLocation } from 'lane-shared/helpers';
import { BurstFlowType } from 'lane-shared/hooks/integrations/shopify/ShopifyBurstContextProvider';
import useShopifyBurstContext from 'lane-shared/hooks/integrations/shopify/useShopifyBurstContext';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import {
  ShopifyBurstPageTitle,
  SHOPIFY_BURST_MAX_RANGE_SIZE,
  SHOPIFY_BURST_MIN_RANGE_SIZE,
  SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
} from 'lane-shared/types/integrations/ShopifyBurst';

import DateRangePickerButton from 'components/form/DatePickers/DateRangePickerButton';
import { Flex } from 'components/layout';
import { H5, M } from 'components/typography';

import BurstButtonGroup from '../../components/BurstButtonGroup';
import { BurstCreatePageProps } from '../../components/BurstCreateFlow';
import BurstCreateHeader from '../../components/BurstCreateHeader';
import Container from '../../components/Container';

import styles from './BurstDateAndTime.scss';

/**
 * TODO: replace date range picker once it's completed https://linear.app/joinlane/issue/STAR-20/[design]-calendar-update-date-range
 */
export default function BurstDataAndTime({
  wizardState,
  content,
  goToPrevStep,
  goToNextStep,
  onDataUpdated,
}: BurstCreatePageProps) {
  const [isDateRangeFromDraft, setDateRangeFromDraft] = useState<boolean>(
    false
  );

  const prevSelectedDateRef = useRef<DateRangeType | undefined>();

  const { t } = useTranslation();

  const {
    unavailableDatesAsDateRanges,
    burstFlowType,
    maxDate,
  } = useShopifyBurstContext();

  const timeZone =
    wizardState.data.portTimeZone || getTimeZoneByGeoLocation({});

  const { data } = wizardState;

  const minDate = DateTime.local()
    .setZone(timeZone)
    .startOf('day')
    .plus({
      weeks: SHOPIFY_BURST_WEEKS_BEFORE_FIRST_AVAILABLE_DATE,
    })
    .toJSDate();

  const dateRange = data.dateRange;
  const isNextButtonDisabled = !dateRange?.startDate || !dateRange.endDate;

  async function onNextStep() {
    if (!dateRange) {
      return;
    }

    goToNextStep();
  }

  useEffect(() => {
    if (wizardState?.data?.dateRange) {
      setDateRangeFromDraft(true);
    }
  }, []);

  async function updateDateRange(dateRange: DateRangeType) {
    prevSelectedDateRef.current = data?.dateRange;

    const startDate = DateTime.fromJSDate(dateRange.startDate!)
      .setZone(timeZone)
      .toISO();
    const endDate = DateTime.fromJSDate(dateRange.endDate!)
      .setZone(timeZone)
      .toISO();

    onDataUpdated({
      dateRange: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date'.
        startDate,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date'.
        endDate,
      },
    });

    if (!isDateRangeFromDraft) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'Date'.
      await onDateSubmit({ startDate, endDate });
    }
  }

  async function onDateSubmit(dateRange: LaneType.DateRange) {
    await cleanupDraftBurstReservations(wizardState.data);
    onDataUpdated({
      dateRange: {
        startDate: dateRange?.startDate || data?.dateRange?.startDate!,
        endDate: dateRange?.endDate || data?.dateRange?.endDate!,
      },
      primaryRoomBooking: undefined,
      dailySchedule: undefined,
      activitiesBookings: undefined,
    });
  }

  return (
    <>
      <BurstCreateHeader
        wizardState={wizardState}
        burstContent={content}
        title={t(ShopifyBurstPageTitle.BurstDateAndTime)}
      />
      <Container>
        <Flex direction="column">
          <H5 mb={2}>{t`What is the date and duration of your burst?`}</H5>
          <M mb={6} variant="secondary">
            {t(
              'Note that bursts can only span between {{- min}}-{{- max}} days. Make sure to only include the days of the burst and not the travel days as those are not booked in the app.',
              {
                min: SHOPIFY_BURST_MIN_RANGE_SIZE,
                max: SHOPIFY_BURST_MAX_RANGE_SIZE,
              }
            )}
          </M>
          <DateRangePickerButton
            minRangeSize={SHOPIFY_BURST_MIN_RANGE_SIZE}
            maxRangeSize={SHOPIFY_BURST_MAX_RANGE_SIZE}
            minDate={minDate}
            maxDate={maxDate}
            startDate={data.dateRange?.startDate || minDate}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | undefined' is not assignable to type ... Remove this comment to see the full error message
            endDate={data.dateRange?.endDate}
            onChange={updateDateRange}
            className={styles.dateRangeButton}
            timeZone={timeZone}
            weekdayOnly
            unavailableDateRanges={unavailableDatesAsDateRanges}
            disabled={burstFlowType === BurstFlowType.AdminEdit}
          />
          <BurstButtonGroup
            disabled={isNextButtonDisabled}
            buttons={[
              { title: 'Prev', onClick: goToPrevStep },
              { title: 'Next', onClick: onNextStep },
            ]}
          />
        </Flex>
      </Container>
    </>
  );
}

import React from 'react';

import { Button, FileInput } from 'components';
import { useTranslation } from 'react-i18next';

import { getClient } from 'lane-shared/apollo';

import { uploadCSVMutation } from 'graphql-queries';

import { showOnlyFileName } from 'pages/portal/admin/channel/children/auto-setup-wizard/helpers';

import { WizardStepOneParams } from '../WizardStepOne';

import { Icon } from 'design-system-web';
import { FileReturnType, FileReturnTypeEnum } from 'helpers/fileReaderResolver';

import styles from '../styles.scss';

export function UploadCSV({
  currentValue,
  onChangeDataSourceLocation,
  onDeleteSourceLocation,
  onAddDataSourceLocation,
  loading,
  setLoading,
}: WizardStepOneParams) {
  const { t } = useTranslation();
  const { sourceLocations } = currentValue;

  const verifyCSVDataEntered = (): boolean => {
    return (
      sourceLocations &&
      sourceLocations.length === 1 &&
      sourceLocations[0]?.value !== ''
    );
  };

  const handleFileUpload = async (
    file: FileReturnType,
    name: string
  ): Promise<void> => {
    setLoading(true);
    if (!verifyCSVDataEntered()) onAddDataSourceLocation();

    try {
      const { data } = await getClient().mutate({
        mutation: uploadCSVMutation,
        variables: {
          uploadCSVData: {
            text: file,
            contentType: 'text/csv',
            fileName: name,
          },
        },
      });
      onChangeDataSourceLocation({ index: 0, value: data.uploadCSV.fileUrl });
    } catch (e) {
      window.Alert.show({
        title: t`web.admin.channel.autoSetupWizard.uploadFailed`,
        message: t`web.admin.channel.autoSetupWizard.uploadFailedMessage`,
        error: e,
      });

      onDeleteSourceLocation(0);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FileInput
      accept="text/csv"
      type={FileReturnTypeEnum.Text}
      onFileSelected={handleFileUpload}
    >
      <Button
        loading={loading}
        startIcon={verifyCSVDataEntered() && <Icon name="check-circle" />}
        className={styles.SourceButton}
      >
        {verifyCSVDataEntered()
          ? `${t`web.admin.channel.autoSetupWizard.uploaded`}: ${showOnlyFileName(
              sourceLocations[0]?.value
            )}`
          : t`web.admin.channel.autoSetupWizard.csv`}
      </Button>
    </FileInput>
  );
}

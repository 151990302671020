import { useMutation } from '@apollo/client';
import { GraphQLErrorOptions } from 'graphql';
import { useInviteContext } from 'lane-shared/contexts/InviteContext/useInviteContext';
import { AlreadyExistsError } from 'activate-errors';
import { joinChannel } from 'lane-shared/graphql/mutation';
import { updateUser } from 'lane-shared/graphql/user';
import { isThisError } from 'lane-shared/helpers';
import { OnboardingData } from './types';

export class UserNotDefined extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'UserNotDefined';
  }
}

export function useOnboardUser(
  userId?: string
): {
  onboardUser: (accountData: OnboardingData) => Promise<void>;
} {
  const [updateUserMutation] = useMutation(updateUser);
  const [joinChannelMutation] = useMutation(joinChannel);

  const { inviteId, clearInvite, claimInvite } = useInviteContext();

  const hasUserAlreadyJoinedTheChannel = (err: any) => {
    const isApolloCodedError = err.graphQLErrors?.some(
      (graphQLError: GraphQLErrorOptions) =>
        isThisError(graphQLError?.extensions?.exception, AlreadyExistsError)
    );

    return isApolloCodedError;
  };

  const updateUserAndJoinChannels = async ({
    fullName,
    building,
    company,
    marketingOptIn,
  }: OnboardingData) => {
    if (!userId) {
      throw new UserNotDefined();
    }

    await updateUserMutation({
      variables: {
        user: {
          _id: userId,
          profile: {
            name: fullName,
          },
          settings: {
            emailOptIn: marketingOptIn,
          },
        },
      },
    });

    const joinCompany = async () => {
      if (company) {
        await joinChannelMutation({
          variables: {
            channelId: company._id,
          },
        });
      }
    };

    const joinBuilding = async () => {
      if (inviteId) {
        await claimInvite(inviteId, building._id);
        clearInvite();
      } else {
        if (!building) {
          return;
        }

        await joinChannelMutation({
          variables: {
            channelId: building._id,
          },
        });
      }
    };

    try {
      await joinCompany();
    } catch (err) {
      if (!hasUserAlreadyJoinedTheChannel(err)) {
        throw err;
      }
    }

    try {
      await joinBuilding();
    } catch (err) {
      if (!hasUserAlreadyJoinedTheChannel(err)) {
        throw err;
      }
    }
  };

  return { onboardUser: updateUserAndJoinChannels };
}

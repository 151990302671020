import React from 'react';
import styles from '../addItemsToChargeStyles.scss';
import { IconButton } from 'components';
import { useTranslation } from 'react-i18next';

import {
  MarkupAmountType,
  SupportedPaymentCurrency,
  TaxAmountType,
  AddProductToChargeDetails,
  ProductType,
} from 'lane-shared/domains/billingPayments/types';
import { currencyFormatter } from 'lane-shared/helpers/formatters';

import { M, S, Column, Icon, Tooltip } from 'design-system-web';

export function useTableColumnsAddedProducts({
  currency,
  removeProduct,
  editProduct,
}: {
  currency: SupportedPaymentCurrency;
  removeProduct: Function;
  editProduct: Function;
}): Column<AddProductToChargeDetails>[] {
  const { t } = useTranslation();

  const renderFixedAmountCell = (cell: string) => {
    return currencyFormatter({
      currency,
    })(Number(cell));
  };

  const renderRateModifierCell = (cell: {
    value: string;
    type: MarkupAmountType | TaxAmountType;
  }) => {
    const isPercentageCell =
      cell.type === MarkupAmountType.MarkupAmountTypePercentage ||
      cell.type === TaxAmountType.TaxAmountTypePercentage;

    return isPercentageCell
      ? `${cell.value} %`
      : renderFixedAmountCell(cell.value);
  };

  const renderIconCell = (data: AddProductToChargeDetails) => {
    return (
      <>
        <IconButton
          icon="trash-alt"
          onClick={() => removeProduct(data)}
          testId="deleteIconButton"
        />
        <IconButton
          icon="pen"
          onClick={() => editProduct(data)}
          testId="editIconButton"
        />
      </>
    );
  };

  const renderTotalCell = (cell: string, row: AddProductToChargeDetails) => {
    return (
      <div
        className={styles.totalCell}
        data-test={`totalCell-${row.productId}`}
      >
        <M>{renderFixedAmountCell(cell)}</M>
        {row.originalPrice && (
          <Tooltip
            TooltipComponent={t('abp.charges.addProduct.modal.exceptionInfo')}
            zIndex={100}
            placement="right"
          >
            <Icon
              name="exclamation-circle"
              set="FontAwesome"
              type="far"
              size="medium"
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderNameCell = (cell: string, row: AddProductToChargeDetails) => {
    return (
      <div>
        <div>
          <S variant="secondary">
            {t(`abp.productsServices.productType.${row.productCategoryType}`)}
          </S>
          {row.productCategoryType !==
            ProductType.PRODUCT_TYPE_CREDIT_BUNDLE && (
            <>
              {' '}
              <S variant="secondary">{`>`}</S>{' '}
              <S variant="secondary">{row.productCategoryName}</S>
            </>
          )}
        </div>
        <M>{cell}</M>
      </div>
    );
  };

  return [
    {
      key: 'name',
      header: t('abp.charges.addProduct.table.headers.item'),
      renderCell: (cell: string, row: AddProductToChargeDetails) =>
        renderNameCell(cell, row),
    },
    {
      key: 'chargeCode',
      header: t('abp.charges.addProduct.table.headers.chargeCode'),
      renderCell: renderStringCell,
    },
    {
      key: 'rate',
      header: t('abp.charges.addProduct.table.headers.rate'),
      renderCell: renderFixedAmountCell,
    },
    {
      key: 'quantity',
      header: t('abp.charges.addProduct.table.headers.qty'),
      renderCell: renderStringCell,
    },
    {
      key: 'markup',
      header: t('abp.charges.addProduct.table.headers.markup'),
      renderCell: renderRateModifierCell,
    },
    {
      key: 'tax',
      header: t('abp.charges.addProduct.table.headers.tax'),
      renderCell: renderRateModifierCell,
    },
    {
      key: 'total',
      header: t('abp.charges.addProduct.table.headers.total'),
      renderCell: (cell: string, row: AddProductToChargeDetails) =>
        renderTotalCell(cell, row),
    },
    {
      key: 'actions',
      header: '',
      renderCell: (_: unknown, row: AddProductToChargeDetails) =>
        renderIconCell(row),
    },
  ];
}

function renderStringCell(cell: string) {
  return cell;
}

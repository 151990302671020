import { GraphQLErrorOptions } from 'graphql';

import {
  AlreadyExistsError,
  ChannelFiltersError,
  ChannelMaxUsersError,
  NotFoundError,
  UserGroupRoleMaxUsersError,
} from 'activate-errors';

import { isThisError } from 'lane-shared/helpers';

const isCodedError = (err: any, errorType: any) => {
  const isApolloCodedError = err.graphQLErrors?.some(
    (graphQLError: GraphQLErrorOptions) =>
      isThisError(graphQLError?.extensions?.exception, errorType)
  );

  return Boolean(isApolloCodedError);
};

export const isChannelMaxUsersError = (err: any) =>
  isCodedError(err, ChannelMaxUsersError);
export const isChannelFiltersError = (err: any) =>
  isCodedError(err, ChannelFiltersError);
export const isUserGroupRoleMaxUsersError = (err: any) =>
  isCodedError(err, UserGroupRoleMaxUsersError);
export const isNotFoundError = (err: any) => isCodedError(err, NotFoundError);
export const isAlreadyExistsError = (err: any) =>
  isCodedError(err, AlreadyExistsError);

// One of those errors can only happen if the user is already created
export const isUserCreatedError = (err: any) =>
  isChannelMaxUsersError(err) ||
  isChannelFiltersError(err) ||
  isUserGroupRoleMaxUsersError(err) ||
  isNotFoundError(err) ||
  isAlreadyExistsError(err);

import { useContext, useMemo } from 'react';

import { ContentRendererContext } from 'lane-shared/contexts';
import { editEvents as EDIT_EVENTS } from 'lane-shared/renderers/v5/editEvents';

export type EditModeProps = Partial<{
  'data-is-top': boolean;
  'data-block-id': string;
  'data-primitive-block-id': string;
  'data-block-name': string;
  'data-is-edit-mode': boolean;
  'data-preview-language': string | undefined;
}> & { [key: string]: any };

export function useEditModeProps(
  otherProps: Record<string, any>
): EditModeProps {
  const { editMode, previewLanguage } = useContext(ContentRendererContext);

  return useMemo(() => {
    const props: EditModeProps = {
      'data-is-top': otherProps.isTopLevel,
      'data-block-id': otherProps.isTopLevel ? otherProps.blockId : null,
      'data-primitive-block-id': otherProps.primitiveBlockId,
      'data-block-name': otherProps.blockName,
      'data-is-edit-mode': editMode,
      'data-preview-language': previewLanguage,
    };

    if (otherProps.onClick) {
      props.onClick = otherProps.onClick;
    }

    if (editMode) {
      Object.keys(EDIT_EVENTS).forEach(
        name => (props[name] = otherProps[name])
      );
    }

    return props;
  }, [editMode, otherProps]);
}

export default useEditModeProps;

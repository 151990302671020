import styles from '../addItemsToChargeStyles.scss';
import { Button } from 'design-system-web';
import {
  AddItemType,
  AddProductToChargeDetails,
} from 'lane-shared/domains/billingPayments/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isNumberTwoDecimalPlaces } from '../../../products-services/helpers';

export function AddProductButtonGroup({
  productDetails,
  formType,
  addProduct,
  onCancel,
}: {
  productDetails: AddProductToChargeDetails;
  formType: AddItemType | undefined;
  addProduct: Function;
  onCancel: Function;
}) {
  const { t } = useTranslation();
  const isSaveButtonDisabled = () => {
    const {
      productCategoryId,
      productId,
      name,
      rate,
      quantity,
      markup,
      tax,
    } = productDetails;

    const rateIsInvalid = !isNumberTwoDecimalPlaces(parseFloat(rate));
    const taxIsInvalid =
      tax?.value && !isNumberTwoDecimalPlaces(parseFloat(tax.value));
    const markupIsInvalid =
      markup?.value && !isNumberTwoDecimalPlaces(parseFloat(markup.value));

    // Check if rate, tax, or markup are not numbers with two or fewer decimal places
    const decimalPlacesInvalid =
      rateIsInvalid || taxIsInvalid || markupIsInvalid;

    // Check if any of the required fields are missing
    const missingFields =
      !productCategoryId || !rate || !quantity || !markup?.value || !tax?.value;

    // Check if the form type is 'EXISTING' and productId is missing
    const missingProductId = formType === AddItemType.EXISTING && !productId;

    // Check if the form type is 'CUSTOM' and name is missing
    const missingName = formType === AddItemType.CUSTOM && !name;

    // Return true if any of the above conditions are met
    return (
      decimalPlacesInvalid || missingFields || missingProductId || missingName
    );
  };

  return (
    <div className={styles.buttonGroup}>
      <Button
        size="large"
        className={styles.button}
        variant="primary"
        onClick={() => addProduct()}
        testId="saveProductButton"
        disabled={isSaveButtonDisabled()}
      >
        {t('Save')}
      </Button>
      <Button
        size="large"
        className={styles.button}
        variant="secondary"
        onClick={() => onCancel()}
        testId="cancelAddProductButton"
      >
        {t('abp.cancel')}
      </Button>
    </div>
  );
}

import { useMemo } from 'react';

import extractRegionAttribute from 'lane-shared/helpers/integrations/ShopifyBurst/extractRegionAttribute';
import type { ContentType } from 'lane-shared/types/content/Content';
import type { ShopifyChannelType } from 'lane-shared/types/integrations/ShopifyBurst';

import type { RegionValue } from './types';

type SectionContent = {
  _id: string;
  content: ContentType;
};

export default function useBurstLocationData(
  sectionContent: SectionContent[],
  ports: ShopifyChannelType[]
): RegionValue[] {
  return useMemo(() => {
    const extractLocations = () => {
      return ports?.map(port => {
        const regionId = extractRegionAttribute(
          sectionContent?.find(
            ({ content }) => content.channel._id === port._id
          )?.content
        )?.value.Option._id;
        return {
          _id: port._id,
          label: port.profile.name,
          region: regionId,
        };
      });
    };

    const extractRegionsWithLocations = () => {
      const locations = extractLocations();
      const validators = extractRegionAttribute(sectionContent[0]?.content)
        ?.metatag?.properties.validators;
      if (Array.isArray(validators)) {
        const regionOptions = validators.find(
          validator => validator.name === 'In'
        )?.value;
        return regionOptions
          .map(({ _id, name }: { _id: string; name: string }) => ({
            _id,
            label: name,
            locations: locations.filter(location => location.region === _id),
          }))
          .filter((region: RegionValue) => !!region?.locations?.length);
      }
      return [];
    };
    if (sectionContent?.length) {
      return extractRegionsWithLocations();
    }
    return [];
  }, [sectionContent, ports]);
}

import { gql } from '@apollo/client';

export const cancelRecurringChargeMutation = gql`
  mutation cancelRecurringCharge(
    $cancelRecurringChargeRequest: CancelRecurringChargeRequest!
  ) {
    accounts {
      cancelRecurringCharge(
        cancelRecurringChargeRequest: $cancelRecurringChargeRequest
      ) {
        recurrenceId
      }
    }
  }
`;

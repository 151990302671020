import React, { useContext } from 'react';

import cx from 'classnames';

import { AppContext } from 'lane-shared/contexts';

import Loading from './Loading';
import ModalBackground from './ModalBackground';

import styles from './BlockingLoad.scss';

export default function BlockingLoad() {
  const appContext = useContext(AppContext);
  const { isBlockingLoad } = appContext;

  if (!isBlockingLoad) {
    return null;
  }

  return (
    <ModalBackground
      className={cx(styles.blockingLoad)}
      hasOpaqueBackground
      isOpen={isBlockingLoad}
      onClose={() => undefined}
    >
      <Loading className={styles.loading} />
    </ModalBackground>
  );
}

import React from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import WebhookCreate from 'components/webhooks/WebhookCreate';
import WebhookEdit from 'components/webhooks/WebhookEdit';

import ChannelWebhookLibrary from './ChannelWebhookLibrary';

export default function WebhookLibraryIndex() {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={path}>
        <ChannelWebhookLibrary />
      </Route>

      <Route exact path={`${path}/new`}>
        <WebhookCreate />
      </Route>

      <Route
        exact
        path={`${path}/:webhookId/edit`}
        render={({ match }) => (
          <WebhookEdit webhookId={match.params.webhookId} />
        )}
      />
    </>
  );
}

import { config as sharedConfig } from 'lane-shared/config';

export function useAuth0WebRedirectURL() {
  const { redirectionServiceURL } = sharedConfig.auth0;

  const redirectURLSearchParams = new URLSearchParams({
    destination_url: window.location.origin,
  });

  return `https://${redirectionServiceURL}?${redirectURLSearchParams}`;
}

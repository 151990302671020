// vh doesn't work properly in mobile devices (see  https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser)
// so we will set a CSS variable that we update as the device size changes.

// the app-height css variable is set in src/static/style/_base.scss

export {};

function appHeight(): void {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
}

window.addEventListener('resize', appHeight);
appHeight();

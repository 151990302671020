import React, { useRef, useLayoutEffect } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Input } from 'components';
import { useTranslation } from 'react-i18next';

import { CompanyLocationsSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyLocationsSearch';
import { CompanyParentSearchResult } from 'lane-shared/contexts/SignUpContext/useCompanyParentSearch';
import {
  FONT_AWESOME_REGULAR,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useContinueAsGuest } from 'lane-shared/hooks/useContinueAsGuest';
import { ChannelType } from 'lane-shared/types/ChannelType';

import { Loading } from 'components/general';
import { ChannelSearchCircleListView } from 'components/lane';
import { H4 } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

import SignUpChannelListItem from './SignUpChannelListItem';

import styles from './Signup.scss';

export type OnboardingCompanyInputProps = {
  data: {
    signupError: Error | null;
    company: ChannelType | null;
    building: ChannelType | null;
    parentCompanySearch: string;
    companyParentsResult: CompanyParentSearchResult;
    companySearch: string;
    parentCompany: ChannelType | null;
    companyLocationsResult: CompanyLocationsSearchResult;
  };
  config: {
    isLocked: boolean;
  };
  handlers: {
    resetSelection: () => void;
    onParentCompanySearchTextChange: (parentCompanySearch: string) => void;
    onParentCompanySelectionChange: (parentCompany: ChannelType) => void;
    onCompanySearchTextChange: (companySearch: string) => void;
    onCompanySelectionChange: (
      company: ChannelType | null,
      building: ChannelType | null
    ) => void;
  };
  ContinueButton: React.ReactNode;
};

export function OnboardingCompanyInput({
  data: {
    company,
    building,
    parentCompanySearch,
    companyParentsResult,
    companySearch,
    parentCompany,
    companyLocationsResult,
  },
  config: { isLocked },
  handlers: {
    resetSelection,
    onParentCompanySearchTextChange,
    onParentCompanySelectionChange,
    onCompanySearchTextChange,
    onCompanySelectionChange,
  },
  ContinueButton,
}: OnboardingCompanyInputProps) {
  const { t } = useTranslation();
  const {
    translationKeys: { locationPlaceholder },
  } = useContinueAsGuest();

  const parentCompanySearchRef = useRef(null);

  useLayoutEffect(() => {
    if (parentCompanySearchRef.current) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      parentCompanySearchRef.current.focus();
    }
  }, []);

  const companySearchHeader =
    companyLocationsResult.locations.length > 1
      ? 'We found multiple locations, which one is yours?'
      : 'We found this location';

  return (
    <div>
      {!parentCompany ? (
        <div>
          <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
            {t('onboarding.companyNameQuestion')}
          </H4>
          <Input
            ref={parentCompanySearchRef}
            onChange={onParentCompanySearchTextChange}
            value={parentCompanySearch}
            placeholder={t('Company name')}
            testId="companyName"
            icon="search"
            iconRight
          />
          <br />
          <div className={styles.results}>
            {companyParentsResult.companies.map((company: ChannelType) => (
              <ChannelSearchCircleListView
                className={styles.channelResult}
                key={company._id}
                channel={company}
                onClick={onParentCompanySelectionChange}
                hideDescription
              />
            ))}
            {companyParentsResult.loading && (
              <Loading className={styles.loading} />
            )}
          </div>
        </div>
      ) : (
        <div>
          <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
            {t('Your company')}
          </H4>
          <div className={styles.selectedChannelContainer}>
            <ChannelSearchCircleListView
              className={cx([styles.channelResult, styles.selected])}
              key={parentCompany._id}
              channel={parentCompany}
              onClick={() => {
                if (!isLocked) {
                  resetSelection();
                }
              }}
              hideDescription
            />
            <Icon
              name={isLocked ? 'lock' : 'edit'}
              className={styles.checkIcon}
              set={ICON_SET_FONTAWESOME}
              type={FONT_AWESOME_REGULAR}
            />
          </div>
          {!company ? (
            <>
              <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
                {t(companySearchHeader)}
              </H4>
              <Input
                className={styles.channelSearchInput}
                onChange={onCompanySearchTextChange}
                value={companySearch}
                placeholder={t(locationPlaceholder)}
                testId="buildingLocation"
                icon="search"
                showClear
              />
              <div className={styles.results}>
                {companyLocationsResult.locations.map(location => (
                  <SignUpChannelListItem
                    key={(location as any)._id}
                    channel={location.channel}
                    onClick={() =>
                      onCompanySelectionChange(
                        location.channel,
                        location.relatedTo
                      )
                    }
                    showName
                  />
                ))}
                {companyLocationsResult.loading && (
                  <Loading className={styles.loading} />
                )}
              </div>
            </>
          ) : (
            <>
              <H4 bold mb={4} className={welcomeFormStyles.formDescription}>
                {t(locationPlaceholder)}
              </H4>
              <div className={styles.selectedChannelContainer}>
                <SignUpChannelListItem
                  className={cx([styles.channelResult, styles.selected])}
                  channel={building as ChannelType}
                  showName
                  onClick={() => onCompanySelectionChange(null, null)}
                />
                <Icon
                  name="edit"
                  className={styles.checkIcon}
                  set={ICON_SET_FONTAWESOME}
                  type={FONT_AWESOME_REGULAR}
                />
              </div>
            </>
          )}
          {company && ContinueButton}
        </div>
      )}
    </div>
  );
}

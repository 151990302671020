import React, { useState, Dispatch, SetStateAction } from 'react';

import { PopupMenu } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ActiveChannelTypeEnum } from 'lane-shared/types/ChannelType';

import { Button } from 'components/general';

import { ChannelManagementErrorType } from '../utils';

import styles from './styles.scss';

type Props = {
  setIsChannelTypeChangeModalOpen: Dispatch<SetStateAction<boolean>>;
  isSelectionActive: boolean;
  setError: Dispatch<
    SetStateAction<ChannelManagementErrorType | ChannelManagementErrorType[]>
  >;
  setSelectedChannelType: Dispatch<
    SetStateAction<ActiveChannelTypeEnum | string>
  >;
  isOnlyChildChannelsSelected: boolean;
  updateChannelTypes: (x: string) => Promise<void>;
};

export function ChannelTypeChangeDropdown({
  setSelectedChannelType,
  setIsChannelTypeChangeModalOpen,
  isSelectionActive,
  isOnlyChildChannelsSelected,
  setError,
  updateChannelTypes,
}: Props) {
  const { t } = useTranslation();
  const [isEditChannelTypeOpen, setIsEditChannelTypeOpen] = useState<boolean>(
    false
  );

  const onEditChannelType = () => {
    setError(null);

    setIsEditChannelTypeOpen(!isEditChannelTypeOpen);
  };

  const onChannelTypeChange = async (channelType: string) => {
    setError(null);
    setSelectedChannelType(channelType);
    setIsEditChannelTypeOpen(!isEditChannelTypeOpen);
    if (isOnlyChildChannelsSelected) {
      await updateChannelTypes(channelType);
    } else {
      setIsChannelTypeChangeModalOpen(true);
    }
  };

  return (
    <div className={styles.AdditionalControl}>
      <PopupMenu
        trigger={
          <Button disabled={!isSelectionActive} onClick={onEditChannelType}>
            {t(
              'web.admin.portalManagement.channelManagement.customControls.editChannelType'
            )}
          </Button>
        }
        items={Object.keys(ActiveChannelTypeEnum).map(type => {
          return {
            label: type,
            onSelect: () => onChannelTypeChange(type),
          };
        })}
      />
    </div>
  );
}

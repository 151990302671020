import React, { useState } from 'react';

import { Input, Button, Loading } from 'components';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import { ChannelTypeEnum } from 'lane-shared/types/ChannelType';

import { ControlMenu } from 'components/general';
import { ChannelSearchCircleListView } from 'components/lane';

import ContactDetails from './ContactDetails';

import styles from './Signup.scss';

const types = [
  ChannelTypeEnum.Company,
  ChannelTypeEnum.Restaurant,
  ChannelTypeEnum.Service,
  ChannelTypeEnum.Retail,
  ChannelTypeEnum.Entertainment,
];

export default function SignupCompany({
  building,
  company,
  onChange,
  onNext,
  onSkip,
}: any) {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(queryChannelsByRelationship, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: { start: 0, perPage: 10 },
      search: {
        sortBy: { key: '_id', dir: 'desc' },
        relatedTo: { _id: building?._id },
        channel: {
          type: { any: types },
          isSub: false,
          name: { type: 'like', value: search },
        },
      },
    },
  });

  function onSearchChange(value: any) {
    setSearch(value);
    onChange({ companySearchValue: value });
  }

  const items = data?.channelsByRelationship?.items || [];

  return (
    <>
      <h1>{t('What company do you work for there?')}</h1>
      <div>
        <Input
          onChange={onSearchChange}
          value={search}
          placeholder={t('onboarding.searchYourCompanyPlaceholder')}
          icon="search"
        />
        <ul className={styles.results}>
          {items.map((item: any) => (
            <ChannelSearchCircleListView
              className={styles.channelResult}
              key={item._id}
              channel={item.channel}
              onClick={channel => {
                onChange({ company: channel, companySearchValue: null });
                onNext();
              }}
            />
          ))}
          {!loading && !error && items.length === 0 && <ContactDetails />}
          {loading && <Loading />}
        </ul>
        <ControlMenu className={styles.menu}>
          <Button onClick={() => onSkip()}>{t('Skip')}</Button>

          <Button onClick={onNext} disabled={!company} variant="contained">
            {t('Next')}
          </Button>
        </ControlMenu>
      </div>
    </>
  );
}

import React, { useContext } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { UserDataContext } from 'lane-shared/contexts';
import { arrayReorder, objectToArray } from 'lane-shared/helpers';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { TypeContextEnum } from 'lane-shared/types/properties/TypeContextEnum';

import {
  FieldButton,
  FieldEditWindow,
  ExampleDataTable,
} from 'components/builder';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import { H4 } from 'components/typography';

import styles from './MetatagEditComplexProperties.scss';

export default function MetatagEditComplexProperties({
  className,
  style,
  channel,
  metatag,
  fields,
  updating,
  addField,
  saveField,
  removeField,
  editingField,
  setEditingField,
  updateMetatag,
  updateSchemaChanged,
}: any) {
  const { user } = useContext(UserDataContext);
  const { t } = useTranslation();

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    arrayReorder(fields, result.source.index, result.destination.index);

    const properties = { ...metatag.properties };

    Object.entries(properties).forEach(([key, value]) => {
      properties[key] = fields.find(
        (field: any) => field._id === (value as any)._id
      );
    });

    updateSchemaChanged(true);

    updateMetatag({ properties });
  }

  return (
    <section
      className={cx(styles.MetatagEditComplexProperties, className)}
      style={style}
    >
      <ControlMenu>
        <H4>
          {t('web.admin.content.metatag.editComplexProperties.dataFields')}
        </H4>
        <hr />
        <Button loading={updating} onClick={addField}>
          {t('web.admin.content.metatag.editComplexProperties.addField')}
        </Button>
      </ControlMenu>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fields">
          {provided => (
            <ul className={styles.options} ref={provided.innerRef}>
              {fields.map((field: any, index: any) => (
                <Draggable
                  key={field._id}
                  draggableId={field._id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <li
                      key={field._id}
                      ref={provided.innerRef}
                      data-is-dragging={snapshot.isDragging}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Icon
                        name="bars"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.iconReorder}
                      />
                      <FieldButton
                        key={field._id}
                        className={styles.fieldButton}
                        onClick={() => setEditingField(field)}
                        onDelete={() => removeField(field)}
                        field={field}
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'onFieldResize' is missing in type '{ cla... Remove this comment to see the full error message */}
      <ExampleDataTable
        className={styles.exampleData}
        definition={fields}
        cacheKey={metatag._id}
        onAddField={addField}
      />

      {editingField && (
        <FieldEditWindow
          channel={channel}
          user={user}
          contexts={[TypeContextEnum.Data]}
          definition={objectToArray(metatag.properties)}
          field={editingField}
          forCreate={editingField.new}
          showSecurity={false}
          onCancel={() => setEditingField(null)}
          onSave={field => saveField(field)}
          onDelete={field => removeField(field)}
        />
      )}
    </section>
  );
}

import React, { useState } from 'react';

import { Button, Dropdown, Icon } from 'design-system-web';
import { Flex, Input, Label, TextArea } from 'lane-web/src/components';

import { useTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/client';
import { searchMeterReadingValue } from 'graphql-queries';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { simpleDate } from 'lane-shared/helpers/formatters';

import { SortDirection } from 'graphql-query-contracts';
import {
  getMeterReadingUnitDropDown,
  getMeterReadingUnitLabel,
} from 'lane-web/src/domains/workOrder/equipment/utils/meterReadingUnitOptions';
import styles from './MeterReadingInput.scss';

export interface MeterReadingInputProps {
  meterReadingValue: any;
  index: number;
  onDelete: (index: number) => void;
  onUpdate: (index: number, meterReadingValue: any) => void;
  meterReadingSettings: any[];
  addlineBreak: boolean;
  source_type: string;
  channel?: UseChannelForAdminQueryResponse['channel'];
  enableDelete?: boolean;
  validated?: boolean;
  editMode?: boolean;
}

function MeterReadingInput({
  meterReadingValue,
  index,
  onDelete,
  onUpdate,
  meterReadingSettings,
  addlineBreak = false,
  source_type,
  channel,
  enableDelete = false,
  validated = false,
  editMode = false,
}: MeterReadingInputProps) {
  const { t } = useTranslation();

  const [meterReadingSetting, setMeterReadingSetting] = useState<any>(
    editMode && meterReadingSettings.length === 1
      ? meterReadingSettings[0]
      : undefined
  );
  const [notes, setNotes] = useState<string>(
    editMode && meterReadingValue ? meterReadingValue.notes : ''
  );
  const [readingValue, setReadingValue] = useState<number | undefined>(
    meterReadingValue?.value !== null || meterReadingValue?.value !== undefined
      ? meterReadingValue?.value
      : undefined
  );
  const [previousValue, setPreviousValue] = useState<any>(undefined);
  const [valueDropDown, setValueDropDown] = useState<
    | {
        label: string;
        key: string;
        items: { label: string; value: string }[];
      }
    | undefined
  >(undefined);

  const [fetchMeterReadingValues, { data }] = useLazyQuery(
    searchMeterReadingValue,
    {
      onCompleted: () => {
        if (
          data?.searchMeterReadingValue?.meterReadingValues &&
          data?.searchMeterReadingValue?.meterReadingValues?.length > 0
        ) {
          setPreviousValue(
            data?.searchMeterReadingValue?.meterReadingValues[0]
          );
        }
      },
      fetchPolicy: 'network-only',
    }
  );

  const getPreviousValue = (id: string) => {
    if (id) {
      fetchMeterReadingValues({
        variables: {
          channelId: channel?._id,
          search: {
            sortBy: {
              key: 'created_at',
              dir: 'desc' as SortDirection,
            },
          },
          pagination: {
            start: 0,
            perPage: 1,
          },
          meterReadingId: convertToUUID(id),
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  return (
    <Flex gap={5} className={styles.equipmentFormBlock} direction="column">
      <Flex gap={3} className={styles.MeterReadingLabels}>
        <div className={styles.MeterReadingInput}>
          <Dropdown
            id="meter-reading-setting-select-dropdown"
            value={meterReadingSetting}
            label={t(
              'web.admin.serviceRequest.equipment.MeterReadingInput.selection'
            )}
            items={meterReadingSettings.map(setting => ({
              value: setting,
              label: setting.name
                ? setting.name
                : t(getMeterReadingUnitLabel(setting.unit)),
            }))}
            doTranslation={false}
            isRequired
            fixedLabel
            isFullWidth
            onChange={(value: any) => {
              getPreviousValue(value.value.id);
              const meterReadingSetting = meterReadingSettings.find(
                setting => setting.id === value.value.id
              );
              setMeterReadingSetting(meterReadingSetting);
              setValueDropDown(
                getMeterReadingUnitDropDown(meterReadingSetting.unit)
              );
              onUpdate(index, {
                ...meterReadingValue,
                meterReadingId: value.value.id,
                source_type,
              });
            }}
            invalid={validated ? meterReadingSetting === undefined : undefined}
            errors={
              validated &&
              (meterReadingSetting === null ||
                meterReadingSetting === undefined)
                ? [t('web.admin.serviceRequest.field.required')]
                : undefined
            }
          />
        </div>

        {meterReadingSetting !== undefined && valueDropDown !== undefined && (
          <div className={styles.MeterReadingInput}>
            <Dropdown
              testId="meter-reading-value-select-dropdown"
              value={
                readingValue !== undefined ? readingValue.toString() : undefined
              }
              label={t(
                'web.admin.serviceRequest.equipment.MeterReadingInput.value'
              )}
              items={valueDropDown?.items}
              isRequired
              fixedLabel
              isFullWidth
              onChange={(value: any) => {
                setReadingValue(Number(value.value));
                onUpdate(index, {
                  ...meterReadingValue,
                  value: Number(value.value),
                });
              }}
              invalid={validated ? readingValue === undefined : undefined}
              errors={
                validated &&
                (readingValue === null || readingValue === undefined)
                  ? [t('web.admin.serviceRequest.field.required')]
                  : undefined
              }
            />
          </div>
        )}

        {meterReadingSetting !== undefined && valueDropDown === undefined && (
          <Flex className={styles.MeterReadingInput} gap={5}>
            <Input
              fieldName="value"
              className={styles.MeterReadingInputField}
              testId="meter-reading-value-input"
              value={readingValue}
              label={t(
                'web.admin.serviceRequest.equipment.MeterReadingInput.value'
              )}
              isRequired
              fixedLabel
              onChange={(value: string) => {
                setReadingValue(value === '' ? undefined : Number(value));
                onUpdate(index, {
                  ...meterReadingValue,
                  value: value === '' ? undefined : Number(value),
                });
              }}
              type="number"
              error={
                validated && readingValue === undefined
                  ? [t('web.admin.serviceRequest.field.required')]
                  : undefined
              }
            />
            <Label mt={5} className={styles.MeterReadingInputUnit}>
              {meterReadingSetting.unit}
            </Label>
          </Flex>
        )}
      </Flex>
      <Flex direction="column">
        <Label
          mt={0}
          className={styles.InpuLabel}
          htmlFor="meter-reading-notes"
        >
          {t('web.admin.serviceRequest.equipment.MeterReadingInput.notes')}
        </Label>
        <TextArea
          value={notes}
          id="meter-reading-notes"
          onChange={(value: string) => {
            setNotes(value);
            onUpdate(index, {
              ...meterReadingValue,
              notes: value,
            });
          }}
          maxLength={4000}
          showLengthIndicator
          minRows={4}
        />
      </Flex>

      <Flex
        direction="row"
        className={
          meterReadingSetting !== undefined && previousValue !== undefined
            ? styles.rowContainerWithValues
            : styles.rowContainer
        }
        gap={12}
      >
        {meterReadingSetting !== undefined && previousValue !== undefined && (
          <Flex direction="row" gap={6} className={styles.previousValue}>
            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.InpuLabel}>
                  {t(
                    'web.admin.serviceRequest.equipment.MeterReadingInput.previousValue'
                  )}
                </Label>
              </Flex>
              <Flex gap={1}>
                <Label>{previousValue?.value}</Label>
                <Label>{meterReadingSetting?.unit}</Label>
              </Flex>
            </Flex>

            <Flex direction="column" className={styles.twoFieldContainer}>
              <Flex>
                <Label className={styles.InpuLabel}>
                  {t(
                    'web.admin.serviceRequest.equipment.MeterReadingInput.previousDate'
                  )}
                </Label>
              </Flex>
              <Flex>
                <Label>{simpleDate(previousValue?.createdAt)}</Label>
              </Flex>
            </Flex>
          </Flex>
        )}
        {enableDelete && (
          <Flex>
            <Button
              className={styles.removeButton}
              variant="text"
              startIcon={<Icon name="trash-alt" />}
              testId="remove-option"
              onClick={() => onDelete(index)}
            >
              {t('web.admin.serviceRequest.equipment.MeterReadingInput.remove')}
            </Button>
          </Flex>
        )}
      </Flex>

      {addlineBreak && <div className={styles.lineBreak} />}
    </Flex>
  );
}

export default MeterReadingInput;

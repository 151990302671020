import React from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { useChannelAdminContext } from 'hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { arrayReorder } from 'lane-shared/helpers';
import { defaultChannelPages } from 'lane-shared/helpers/constants';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';

import styles from './TabList.scss';

const { profileTab } = defaultChannelPages;

type Props = {
  channelPages: any[];
  onChannelPagesUpdated: Function;
  loading: boolean;
};

/**
 * Display the tabs in a table view to allow for a reordering
 * when a page is dragged and dropped.
 */
export default function TabList({
  loading,
  channelPages,
  onChannelPagesUpdated,
}: Props) {
  const { channel } = useChannelAdminContext();

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    onChannelPagesUpdated(
      arrayReorder(channelPages, result.source.index, result.destination.index)
    );
  }

  function onArrowClick(index: number, direction: 'up' | 'down') {
    return () =>
      onChannelPagesUpdated(
        arrayReorder(channelPages, index, index + (direction === 'up' ? -1 : 1))
      );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div className={styles.container}>
            <ul className={styles.tabList} ref={provided.innerRef}>
              {channelPages?.map((channelPage, index) => (
                <Draggable
                  key={channelPage._id}
                  draggableId={channelPage._id}
                  index={index}
                  isDragDisabled={loading || channelPage._id === profileTab._id}
                >
                  {(provided, snapshot) => (
                    <li
                      className={cx(styles.tabItem, {
                        [styles.tabItemIsDragged]: snapshot.isDragging,
                        [styles.tabItemDisabled]:
                          channelPage._id === profileTab._id,
                      })}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Icon
                        name="bars"
                        set={ICON_SET_FONTAWESOME}
                        className={styles.iconMenu}
                      />
                      <Link
                        to={routes.channelAdminTabsEdit
                          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                          .replace(':id', channel?.slug)
                          .replace(':tabId', channelPage._id)}
                      >
                        <span className={styles.tabIconContainer}>
                          <Icon
                            name={channelPage.icon}
                            set={channelPage.iconSet}
                            type={channelPage.iconWeight}
                            className={styles.tabIcon}
                          />
                        </span>
                        <span>{channelPage.name}</span>
                      </Link>
                      <div className={styles.iconContainer}>
                        <Link
                          to={routes.channelAdminTabsEdit
                            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                            .replace(':id', channel?.slug)
                            .replace(':tabId', channelPage._id)}
                        >
                          <Icon
                            name="edit"
                            disabled={channelPage._id === profileTab._id}
                          />
                        </Link>
                        <span className={styles.arrowContainer}>
                          <Icon
                            name="chevron-up"
                            disabled={loading || index === 0}
                            className={cx({
                              [styles.iconDisabled]: index === 0,
                            })}
                            onClick={onArrowClick(index, 'up')}
                          />
                          <Icon
                            name="chevron-down"
                            disabled={
                              loading || index === channelPages.length - 1
                            }
                            className={cx({
                              [styles.iconDisabled]:
                                index === channelPages.length - 1,
                            })}
                            onClick={onArrowClick(index, 'down')}
                          />
                        </span>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

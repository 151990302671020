/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from 'react';

import cx from 'classnames';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useLazyQuery } from '@apollo/client';

import { getAdminClient } from 'lane-shared/apollo';
import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import ChannelContractFragment from 'lane-shared/graphql/fragments/ChannelContractFragment';
import ProfileFragment from 'lane-shared/graphql/fragments/ProfileFragment';
import { pause } from 'lane-shared/helpers';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import parseDate from 'lane-shared/helpers/dates/parseDate';
import {
  simpleDate,
  simpleDateTime,
  fromNow,
  currencyFormatter,
} from 'lane-shared/helpers/formatters';
import {
  ContractUnitEnum,
  ChannelContractLineItemType,
  ChannelContractType,
} from 'lane-shared/types/ChannelContract';
import { NumericSearchTypeEnum } from 'lane-shared/types/graphql/search';

import DateRangePickerButton from 'components/form/DatePickers/DateRangePickerButton';
import Button from 'components/general/Button';
import ControlMenu from 'components/general/ControlMenu';
import IconButton from 'components/general/IconButton';
import Pagination from 'components/general/Pagination';
import { Flex } from 'components/layout';
import AdminPage from 'components/layout/AdminPage';

import useQueryString from 'hooks/useQueryString';
import useUserLocale from 'hooks/useUserLocale';

import styles from './styles.scss';

enum SortEnum {
  Asc = 'asc',
  Desc = 'desc',
}

enum ColumnSortEnum {
  Created = '_created',
  Updated = '_updated',
  ContactUser = 'contactUser',
  SubscriptionStartDate = 'subscriptionStartDate',
  EstimatedSubscriptionStartDate = 'estimatedSubscriptionStartDate',
}

const defaultHeaders = [
  { label: 'Created', sort: ColumnSortEnum.Created },
  { label: 'Updated', sort: ColumnSortEnum.Updated },
  { label: 'Contract Name' },
  { label: 'Channel' },
  { label: 'Name' },
  { label: 'Units' },
  { label: 'Price' },
  { label: 'Total' },
  { label: 'Est. Start', sort: ColumnSortEnum.EstimatedSubscriptionStartDate },
  { label: 'Start', sort: ColumnSortEnum.SubscriptionStartDate },
];

enum ViewEnum {
  List = 'list',
  Grid = 'grid',
}

const channelContractsQuery = gql`
  ${ChannelContractFragment}

  query channelContractsQuery(
    $search: ChannelContractSearch
    $pagination: PaginationInput
  ) {
    channelContracts(search: $search, pagination: $pagination) {
      items {
        ...ChannelContractFragment
      }
      pageInfo {
        start
        total
        perPage
      }
    }
  }
`;

const defaultStartDate = DateTime.local().set({
  year: 2014,
  month: 1,
  day: 1,
});

const defaultEndDate = DateTime.local().endOf('year').plus({ years: 1 });

export default function NewChannelContract({ history }: any) {
  const { user } = useContext(UserDataContext);
  const locale = useUserLocale();
  const [downloading, setDownloading] = useState(false);

  const [query, goToUrl, makeUrl] = useQueryString({
    page: 0,
    perPage: 100,
    startDate: defaultStartDate.toJSDate(),
    endDate: defaultEndDate.toJSDate(),
    selectedSort: ColumnSortEnum.SubscriptionStartDate,
    selectedOrder: SortEnum.Asc,
    view: ViewEnum.List,
  });

  const page = Number(query.page) || 0;
  const perPage = Number(query.perPage) || 100;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const startDate = parseDate(query.startDate);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const endDate = parseDate(query.endDate);
  const selectedSort =
    query.selectedSort || ColumnSortEnum.SubscriptionStartDate;
  const selectedOrder = query.selectedOrder || SortEnum.Asc;
  const view = query.view || ViewEnum.List;

  const numberFormatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format,
    [locale]
  );

  function handleSortClick(fieldToSort: any) {
    if (query.selectedSort === fieldToSort) {
      goToUrl({
        selectedOrder:
          query.selectedOrder === SortEnum.Asc ? SortEnum.Desc : SortEnum.Asc,
      });
    } else {
      goToUrl({ selectedSort: fieldToSort });
    }
  }

  const [fetchContracts, { data, loading, error, called }] = useLazyQuery(
    channelContractsQuery,
    {
      client: getAdminClient(),
      fetchPolicy: 'cache-and-network',
    }
  );

  function getVariables() {
    const variables = {
      pagination: {
        start: page * perPage,
        perPage,
      },
      search: {},
    };

    return variables;
  }

  const doFetch = useDebouncedCallback(() => {
    fetchContracts({
      variables: getVariables(),
    });
  }, 500).callback;

  function onRefresh() {
    doFetch();
  }

  useEffect(() => {
    doFetch();
  }, [
    page,
    perPage,
    query.selectedOrder,
    query.selectedSort,
    query.startDate,
    query.endDate,
    query.selectedState,
  ]);

  async function onDownload() {
    setDownloading(true);

    setDownloading(false);
  }

  const items = data?.channelContracts?.items || [];
  const pageInfo = data?.channelContracts?.pageInfo || { total: 0 };

  // explode out each line item.
  const lineItems = items.reduce((arr: any, contract: any) => {
    contract.lineItems.forEach(
      (lineItem: any) => (lineItem.channelContract = contract)
    );
    return [...arr, ...contract.lineItems];
  }, []);

  return (
    <AdminPage className={styles.ChannelContracts}>
      <ControlMenu>
        <h1>Contracts</h1>

        <Link to={routes.portalManagementContractNew}>
          <Button variant="contained" disabled={loading}>
            New Contract
          </Button>
        </Link>
      </ControlMenu>

      <ControlMenu>
        <hr />
        {Object.values(ViewEnum).map(view => (
          <Link key={view} to={makeUrl({ view })}>
            <IconButton icon={view} selected={query.view === view} inverted />
          </Link>
        ))}

        <DateRangePickerButton
          className={styles.datePickerButton}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'neve... Remove this comment to see the full error message
          startDate={startDate}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Date | null' is not assignable to type 'neve... Remove this comment to see the full error message
          endDate={endDate}
          minRangeSize={1}
          maxRangeSize={365}
          onChange={({ startDate, endDate }: any) =>
            goToUrl({ startDate, endDate })
          }
        />

        <IconButton
          icon="download"
          loading={loading || downloading}
          onClick={onDownload}
        />
        <IconButton
          icon="sync-alt"
          onClick={onRefresh}
          loading={loading || !called || downloading}
        />
      </ControlMenu>
      <Pagination
        perPage={perPage}
        page={page}
        onPage={page => goToUrl({ page })}
        total={pageInfo.total}
      />
      <div className={styles.contracts}>
        <table>
          <thead>
            <tr>
              {defaultHeaders.map(header => (
                <th key={header.label}>
                  <Flex align="center">
                    <span>{header.label}</span>
                    {header.sort && (
                      <span
                        onClick={() => handleSortClick(header.sort)}
                        role="button"
                        tabIndex={0}
                      >
                        <IconButton
                          inverted
                          icon={
                            selectedOrder === SortEnum.Asc
                              ? 'sort-amount-down-alt'
                              : 'sort-amount-up'
                          }
                          className={{
                            [styles.selected]: selectedSort === header.sort,
                          }}
                          selected={selectedSort === header.sort}
                        />
                      </span>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {lineItems.map((lineItem: any) => (
              <tr key={lineItem._id}>
                <td>{simpleDate(lineItem._created)}</td>
                <td>{fromNow(lineItem._updated)}</td>
                <td>
                  <Link
                    to={routes.portalManagementContractEdit.replace(
                      ':contractId',
                      lineItem.channelContract._id
                    )}
                  >
                    {lineItem.channelContract.name}
                  </Link>
                </td>
                <td>
                  <Link
                    to={routes.channelAdmin.replace(
                      ':id',
                      lineItem.channelContract.channel.slug
                    )}
                  >
                    {lineItem.channelContract.channel.name}
                  </Link>
                </td>
                <td>{lineItem.name}</td>
                <td style={{ fontFamily: 'monospace' }}>
                  {numberFormatter(lineItem.units)} {lineItem.unitType}
                </td>
                <td style={{ fontFamily: 'monospace' }}>
                  {currencyFormatter({
                    currency: lineItem.currency,
                    locale,
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })(lineItem.pricePerUnit / CURRENCY_CONVERSION ** 2)}{' '}
                  {lineItem.currency}
                </td>
                <td style={{ fontFamily: 'monospace' }}>
                  {currencyFormatter({
                    currency: lineItem.currency,
                    locale,
                  })(
                    (lineItem.pricePerUnit / CURRENCY_CONVERSION ** 2) *
                      lineItem.units
                  )}{' '}
                  {lineItem.currency}
                </td>
                <td>{simpleDate(lineItem.estimatedSubscriptionStartDate)}</td>
                <td>{simpleDate(lineItem.subscriptionStartDate)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AdminPage>
  );
}

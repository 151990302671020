import React from 'react';

import { Button } from 'design-system-web';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { HookFormInput } from 'components/reactHookForm';

import { MarketingEmailOptIn } from '../MarketingEmailOptIn';
import { TermsAndConditionsOptIn } from '../TermsAndConditionsOptIn';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  fullNameLabel: 'web.onboarding.userProfile.fullNameLabel',
  button: 'web.onboarding.userProfile.continueButton',
  requiredFieldError: 'web.onboarding.userProfile.requiredField.error',
  textLengthError: 'web.onboarding.userProfile.textLength.error',
};

type FormData = {
  fullName: string;
  marketingOptIn: boolean;
  termsAndConditionsOptIn: boolean;
};

export type ProfileData = Pick<FormData, 'fullName' | 'marketingOptIn'>;

type Props = {
  defaultUserName?: string;
  onSubmit: (data: ProfileData) => void;
  whitelabel: Pick<
    WhiteLabelType,
    | 'termsAndConditionsUrls'
    | 'privacyPolicyUrls'
    | 'complianceContact'
    | 'name'
  >;
};

export const ProfileForm = ({
  defaultUserName,
  onSubmit,
  whitelabel,
}: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      fullName: defaultUserName || '',
      marketingOptIn: false,
      termsAndConditionsOptIn: false,
    },
  });

  const fullNameErrorMessage = () => {
    if (errors.fullName?.type === 'required') {
      return [t(TRANSLATION_KEYS.requiredFieldError)];
    }

    if (errors.fullName?.type === 'minLength') {
      return [t(TRANSLATION_KEYS.textLengthError)];
    }

    return null;
  };

  const handleFormSubmission = (data: FormData) => {
    onSubmit({
      fullName: data.fullName,
      marketingOptIn: data.marketingOptIn,
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmission)}>
      {/* @ts-ignore */}
      <HookFormInput
        control={control}
        isRequired
        name="fullName"
        label={TRANSLATION_KEYS.fullNameLabel}
        minLength={2}
        errorMessage={fullNameErrorMessage()}
      />
      <div className={styles.termsAndConditions}>
        <TermsAndConditionsOptIn
          control={control}
          whitelabel={{
            name: whitelabel.name,
            termsAndConditionsUrls: whitelabel.termsAndConditionsUrls,
            privacyPolicyUrls: whitelabel.privacyPolicyUrls,
          }}
        />
      </div>
      <div className={styles.marketingEmail}>
        <MarketingEmailOptIn
          control={control}
          complianceContact={whitelabel?.complianceContact}
        />
      </div>
      <Button
        className={styles.button}
        fullWidth
        size="large"
        type="submit"
        disabled={!isValid}
      >
        {t(TRANSLATION_KEYS.button)}
      </Button>
    </form>
  );
};

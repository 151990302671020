import React, { memo } from 'react';

import cx from 'classnames';
import { Key } from 'ts-key-enum';

import { imageUrl } from 'lane-shared/helpers/formatters';
import { getDisplayProfileName } from 'lane-shared/helpers/getDisplayName';
import { useTrackChannelView } from 'lane-shared/hooks/analytics';
import { ChannelType } from 'lane-shared/types/ChannelType';

import Image from '../general/Image';

import styles from './DirectoryThumbnail.scss';

type Props = {
  className?: string;
  channel: ChannelType;
  onClick?: (e: any) => void;
  style?: React.CSSProperties;
};

function DirectoryThumbnail({ className, channel, onClick, style }: Props) {
  useTrackChannelView(channel);

  return (
    <div
      className={cx(styles.DirectoryThumbnail, className)}
      style={style}
      role="button"
      tabIndex={0}
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onKeyPress={e => e.key === Key.Enter && onClick(e)}
      onClick={onClick}
    >
      <Image
        className={styles.imageWrapper}
        src={
          imageUrl(channel.profile?.logo) || imageUrl(channel.profile?.image)
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: any; src: string; name: string ... Remove this comment to see the full error message
        name={channel.name}
      />
      <div className={styles.textWrapper}>
        <p>{getDisplayProfileName(channel)}</p>
      </div>
    </div>
  );
}

export default memo(DirectoryThumbnail);

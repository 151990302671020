import { emitter } from 'lane-shared/helpers';

import { SignInData } from '../../hooks/useSignIn';
import setSharedCookiesForWebView from 'helpers/setSharedCookiesForWebView';

async function waitUntilAuthTokenSet(data: SignInData) {
  return new Promise<void>(resolve => {
    emitter.emit((emitter as any).EVENT_AUTH_TOKEN, {
      authToken: data,
      resolve,
    });
  });
}

export async function emitAuthEventAndSetCookie(
  data: SignInData,
  setCookie: (name: 'jti' | 'token', value: any, options?: any) => void
) {
  await waitUntilAuthTokenSet(data);
  await setSharedCookiesForWebView(data.token, data.jti, setCookie);
}

import React from 'react';

import { useTranslation } from 'react-i18next';

import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import LoginSSOButtons from 'components/authV2/LoginSSOButtons';
import { S } from 'components/typography';

import welcomeFormStyles from 'pages/user/WelcomeFormStyles.scss';

export interface SignUpUserData {
  fullName: string;
  email: string;
  password: string;
  emailOptIn: boolean;
}

const SEPARATOR_TEXT = 'web.signup.separator';

type Props = {
  onSuccess: () => void;
  onError: (err: Error | null) => void;
  whitelabel: WhiteLabelType;
};

function SignUpWithSSO({ whitelabel, onSuccess, onError }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={welcomeFormStyles.topSeparator}>
        <S>{t(SEPARATOR_TEXT)}</S>
      </div>

      <LoginSSOButtons
        mode="SignUp"
        onSuccess={onSuccess}
        onError={onError}
        showSeparator={false}
        showTopSeparator
        oAuthConfig={whitelabel.oAuthConfig}
      />
    </>
  );
}

export default SignUpWithSSO;

import { useMemo } from 'react';

import type { RegionValue, LocationValue } from './types';

export default function useLocationFilterHandlers(
  regions: RegionValue[],
  selectedIds: string[],
  selectedLocations: (LocationValue | RegionValue)[],
  onChange: (value: (LocationValue | RegionValue)[]) => void
) {
  return useMemo(() => {
    function onLocationChange(location: LocationValue) {
      if (!selectedIds.includes(location._id)) {
        const locations: (LocationValue | RegionValue)[] = [location];

        const region = regions.find(region => region._id === location?.region);
        const isAllSelected = region?.locations?.every(({ _id }) =>
          [...selectedIds, location._id].includes(_id)
        );

        if (isAllSelected && region) {
          locations.push(region);
        }

        onChange([...selectedLocations, ...locations]);
      } else {
        onChange(
          selectedLocations.filter(
            selectedLocation =>
              selectedLocation?._id !== location._id &&
              selectedLocation?._id !== location?.region
          )
        );
      }
    }

    function onRegionChange(region: RegionValue) {
      if (!selectedIds.includes(region._id)) {
        const locationsToAdd = region.locations?.filter(
          location => !selectedIds.includes(location._id)
        );
        if (locationsToAdd) {
          onChange([...selectedLocations, ...locationsToAdd, region]);
        }
      } else {
        const regionLocationIds = region.locations?.map(
          location => location._id
        );

        onChange(
          selectedLocations.filter(
            location =>
              location?._id !== region._id &&
              !regionLocationIds?.includes(location?._id)
          )
        );
      }
    }
    return {
      onRegionChange,
      onLocationChange,
    };
  }, [regions, selectedIds, selectedLocations, onChange]);
}

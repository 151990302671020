import React from 'react';

import { ResizableWindow, ModalBackground, ContentSelector } from 'components';
import {
  CONTENT_LOCATION_PAGE_CENTER,
  CONTENT_SEARCH_TYPE_CHANNEL,
} from 'lane-shared/helpers/constants/content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import type { ChannelType } from 'lane-shared/types/ChannelType';
import type { DocumentType } from 'lane-shared/types/DocumentType';

import styles from './styles.scss';

export function AddPageModal({
  channel,
  isAddOpen,
  setIsAddOpen,
  onAddContent,
}: {
  channel: ChannelType;
  isAddOpen: boolean;
  setIsAddOpen: (value: boolean) => void;
  onAddContent: (content: DocumentType | null | undefined) => Promise<void>;
}) {
  return (
    <ModalBackground
      onClose={() => setIsAddOpen(false)}
      isOpen={isAddOpen}
      className={styles.background}
    >
      <ResizableWindow
        showHeader
        onClose={() => setIsAddOpen(false)}
        name="channelSectionView"
        className={styles.window}
        defaultPosition={ResizableWindow.fullScreen()}
      >
        <ContentSelector
          className={styles.search}
          channelId={channel?._id}
          onContentSelected={onAddContent}
          availableTypes={[ContentTypeEnum.Static]}
          contentSearchLocations={[CONTENT_LOCATION_PAGE_CENTER]}
          contentQueryType={CONTENT_SEARCH_TYPE_CHANNEL}
          includeGeneratedBatchContent
        />
      </ResizableWindow>
    </ModalBackground>
  );
}

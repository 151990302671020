import React, { useState, useContext, useEffect } from 'react';

import { Loading, WhitelabeledLogo, ErrorPage } from 'components';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';

import {
  WHITELABEL_LANE,
  WHITELABEL_STUDIO,
} from 'lane-shared/config/whitelabels';
import { ThemeContext } from 'lane-shared/contexts';
import { completeSignup } from 'lane-shared/graphql/user';
import { POLL_INTERVAL } from 'lane-shared/helpers/constants';
import { imageUrl } from 'lane-shared/helpers/formatters';
import { useCompleteSignupUserQuery } from 'lane-shared/hooks';
import { ThemeColorPaletteType } from 'lane-shared/types/Theme';

import StudioLogo from 'static/img/studio_ts_logo.png';

import CompleteSignupForm from './Signup/CompleteSignupForm';
import CompleteSignupSuccess from './Signup/CompleteSignupSuccess';

import styles from './CompleteSignup.scss';

export default function CompleteSignup({ match }: any) {
  const { guid, verify } = match.params;
  const { data, loading, error } = useCompleteSignupUserQuery({
    guid,
    verify,
    pollInterval: POLL_INTERVAL,
  });
  const [submitting, setSubmitting] = useState(false);
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{ primary: string; secondary: string; tertia... Remove this comment to see the full error message
  const theme: ThemeColorPaletteType = useContext(ThemeContext);
  const { t } = useTranslation();

  const [authToken, setAuthToken] = useState(null);
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [completeSignupMutation] = useMutation(completeSignup);

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  async function submitCompleteSignup(password: any, isPublic: any) {
    setSubmitting(true);

    try {
      const { data } = await completeSignupMutation({
        variables: {
          guid,
          verify,
          password,
          isPublic,
        },
      });

      setAuthToken(data.completeSignup);
      setIsSignupSuccessful(true);
    } catch (err) {
      Sentry.captureException(err);

      await window.Alert.alert({
        title: t('Your signup link has expired'),
        message: t('This signup link has expired.'),
      });
    }

    setSubmitting(false);
  }

  if (loading) {
    return (
      <div className={styles.completeSignup}>
        <section className={styles.panel}>
          <Loading />
        </section>
      </div>
    );
  }

  if (error) {
    return <ErrorPage error={error.graphQLErrors[0]} />;
  }

  let formLogo;
  switch ((theme as any).instance) {
    case WHITELABEL_LANE:
      formLogo = <WhitelabeledLogo className={styles.logo} />;
      break;
    case WHITELABEL_STUDIO:
      formLogo = <img src={StudioLogo} className={styles.logo} alt="" />;
      break;
    default:
      formLogo = (
        <img
          src={imageUrl((theme as any).logo)}
          className={styles.logo}
          alt=""
        />
      );
      break;
  }

  return (
    <div
      className={styles.completeSignup}
      data-is-studio={(theme as any).instance === WHITELABEL_STUDIO}
    >
      <section className={styles.panel}>
        {formLogo}
        {isSignupSuccessful ? (
          <CompleteSignupSuccess authToken={authToken} />
        ) : (
          <CompleteSignupForm
            email={data.email}
            name={data.name}
            loading={submitting || loading}
            clientTypeName={data.clientTypeName}
            onSubmit={submitCompleteSignup}
          />
        )}
      </section>
    </div>
  );
}

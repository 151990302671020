const PER_PAGE = 50;

export const DEFAULT_FILTER_PARAMS = {
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
};

export type ChargeQueryString = {
  page?: string;
  pageSize?: string;
  total?: string;
};

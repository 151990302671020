import React from 'react';

import { PageHeader } from 'components';
import { useQueryString } from 'hooks';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { POLL_INTERVAL } from 'lane-shared/helpers/constants';

import { Table, QueryString } from 'design-system-web';

import { getRecentJobsQuery } from 'graphql-queries';

import {
  useRecentJobsTableColumns,
  getSortedFilteredRecentJobsData,
} from './utils';

import styles from './styles.scss';

const NUM_RECENT_JOBS = 10;

export type JobResponse = {
  id: string;
  status: string;
  type: string;
  created: Date;
  updated: Date;
};

export function RecentJobs() {
  const { t } = useTranslation();
  const [queryStrings] = useQueryString<QueryString>();

  const { data, loading, refetch } = useQuery(getRecentJobsQuery, {
    variables: {
      recentJobsRequest: {
        count: NUM_RECENT_JOBS,
      },
    },
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
  });

  const columns = useRecentJobsTableColumns();

  const jobs: JobResponse[] = data?.getRecentJobs?.jobs ?? [];

  const {
    filteredJobs: rows,
    filtersFromJobs: filters,
  } = getSortedFilteredRecentJobsData({
    t,
    jobs,
    queryStrings,
  });

  return (
    <>
      <PageHeader
        header={t('web.admin.portalManagement.recentJobs')}
        actionButtons={[
          {
            type: 'primary',
            onClick: () =>
              refetch({ recentJobsRequest: { count: NUM_RECENT_JOBS } }),
            label: t('web.admin.portalManagement.recentJobs.refreshJobs'),
          },
        ]}
      />
      <div className={styles.TableContainer}>
        <Table
          isLoading={loading}
          columns={columns}
          filters={filters}
          hasKeywordFilter
          data={rows}
          showColumnVisibility
          pagination="server"
          queryStringsEnabled
        />
      </div>
    </>
  );
}

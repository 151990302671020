import { LDUserCustom } from '../hooks/useLaunchDarklyUser';

export enum LaunchDarklyClientEventEnum {
  change = 'change',
  ready = 'ready',
}

type LDFlagValue = any;

export type LDFlagSet = {
  [key: string]: LDFlagValue;
};

export type LaunchDarklyEvents =
  | `${LaunchDarklyClientEventEnum}`
  | LaunchDarklyClientEventEnum;

export type LaunchDarklyEventCallback<T extends LaunchDarklyEvents = any> = (
  payload: T extends 'ready' ? LDFlagSet | null : LDFlagSet
) => void;

type ILaunchDarklyClient = {
  on: <T extends LaunchDarklyEvents>(
    event: T,
    cb: LaunchDarklyEventCallback<T>
  ) => void;
  off: <T extends LaunchDarklyEvents>(
    event: T,
    cb: LaunchDarklyEventCallback<T>
  ) => void;
  setUser: (key: string, custom?: Partial<LDUserCustom>) => void;
  unsetUser: () => void;
  setAttribute: (field: Partial<LDUserCustom>) => void;
};

export default ILaunchDarklyClient;

import { routes } from 'lane-shared/config';
import React from 'react';
import { ContentCard, Flex } from 'components';
import cx from 'classnames';
import styles from './styles.scss';
import { ButtonLink } from 'components/general/ButtonLink';
import { Button, Icon } from 'design-system-web';
import { t } from 'i18next';
import {
  FONT_AWESOME_SOLID,
  ICON_SET_FONTAWESOME,
} from 'lane-shared/helpers/constants/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SectionContentType } from './types';
import { LocationType } from 'lane-shared/types/LocationType';

type Props = {
  channelSlug: string;
  sectionContent: SectionContentType;
  isSortedByDistance: boolean;
  location: LocationType;
  disabled: boolean;
  onUnpinContent: (value: any) => void;
  onPinContent: (value: any) => void;
  onViewButtonClick: (contentId: string) => void;
  filterStatus: React.ReactElement;
};

export const DynamicSectionContentCard = ({
  channelSlug,
  sectionContent,
  isSortedByDistance,
  location,
  disabled,
  onUnpinContent,
  onPinContent,
  filterStatus,
  onViewButtonClick,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id: sectionContent.content._id, disabled });

  const style = {
    transform: disabled ? 'none' : CSS.Transform.toString(transform),
    transition,
    cursor: disabled ? 'auto' : 'grab',
  };

  return (
    <li ref={setNodeRef} style={style}>
      <div className={styles.contentContainer}>
        <Flex direction="row" align="center">
          <div
            className={cx(
              styles.cardWrapper,
              disabled ? styles.noHoverShadow : ''
            )}
            data-test="sectionContentCard"
          >
            <div ref={setActivatorNodeRef} {...attributes} {...listeners}>
              <ContentCard
                className={cx(styles.content, styles.isPinningModeOn)}
                content={sectionContent.content}
                showDistance={isSortedByDistance}
                location={location}
              />
            </div>

            <Flex justify="space-between" className={styles.cardFooter}>
              <ButtonLink
                to={routes.channelAdminContent
                  .replace(':id', channelSlug)
                  .replace(':contentId', sectionContent.content._id)}
                variant="text"
                startIcon={<Icon name="file-alt" />}
                size="medium"
                onClick={() => onViewButtonClick(sectionContent.content._id)}
              >
                {t('View')}
              </ButtonLink>
              {sectionContent?.isPinned ? (
                <Button
                  variant="primary"
                  size="medium"
                  onClick={() => onUnpinContent(sectionContent.content._id)}
                  startIcon={
                    <Icon
                      name="thumbtack"
                      set={ICON_SET_FONTAWESOME}
                      type={FONT_AWESOME_SOLID}
                    />
                  }
                >
                  {t(
                    'web.admin.content.sections.view.availableTabs.preview.unpin'
                  )}
                </Button>
              ) : (
                <Button
                  variant="text"
                  size="medium"
                  onClick={() => onPinContent(sectionContent.content._id)}
                  startIcon={
                    <Icon
                      name="thumbtack"
                      set={ICON_SET_FONTAWESOME}
                      type={FONT_AWESOME_SOLID}
                    />
                  }
                >
                  {t('Pin')}
                </Button>
              )}
            </Flex>
          </div>
        </Flex>
        {filterStatus}
      </div>
    </li>
  );
};

import { getClient } from 'lane-shared/apollo';
import {
  ExternalPayerType,
  InvoiceeInfo,
} from 'lane-shared/domains/billingPayments/types';
import { queryChannelsByRelationship } from 'lane-shared/graphql/channel';
import {
  getChannelGroupRoles,
  queryChannelUsersByGroupRole,
} from 'lane-shared/graphql/query';
import { STANDARD_GROUP_ROLES_NAMES } from 'lane-shared/helpers/constants/channel';
import { ChannelType, ChannelTypeEnum } from 'lane-shared/types/ChannelType';
import { GroupRole } from 'lane-shared/types/GroupRole';

export interface ChannelMember {
  relatedChannels: [{ id: string; name: string }];
  user: {
    _id: string;
    name: string;
    status: string;
  };
}

export function searchWorkplaceMembersAndCompanies(channelId: string) {
  async function filterInvoicees(
    searchString: string
  ): Promise<InvoiceeInfo[]> {
    if (!channelId) {
      return [];
    }
    const workplaceMemberGroupRoleId = await getGroupRoleIdForMembers(
      channelId
    );

    if (!workplaceMemberGroupRoleId) {
      return [];
    }

    const channelsQueryPromise = createChannelsQueryPromise(
      channelId,
      searchString
    );

    const membersQueryPromise = createMembersQueryPromise(
      workplaceMemberGroupRoleId,
      searchString
    );

    const resolvedQueries = await Promise.all([
      channelsQueryPromise,
      membersQueryPromise,
    ]);

    const invoicees = reduceQueryResultsToInvoicees(resolvedQueries);

    return invoicees;
  }

  return filterInvoicees;
}

async function getGroupRoleIdForMembers(channelId: string) {
  const channelGroupRoles = await getClient().query({
    query: getChannelGroupRoles,
    variables: {
      id: channelId,
    },
  });

  const workplaceMemberGroupRoleId = getWorkplaceMemberGroupRoleId(
    channelGroupRoles.data?.channel?.groupRoles
  );

  return workplaceMemberGroupRoleId;
}

function getWorkplaceMemberGroupRoleId(
  groupRoles: GroupRole[]
): string | undefined {
  const workplaceMemberGroupRole = groupRoles.find(
    groupRole => groupRole.name === STANDARD_GROUP_ROLES_NAMES.WORKPLACE_MEMBER
  );

  return workplaceMemberGroupRole?._id;
}

function createChannelsQueryPromise(channelId: string, searchString: string) {
  const searchStringVariable = searchString
    ? { name: { type: 'like', value: searchString } }
    : undefined;

  return getClient().query({
    query: queryChannelsByRelationship,
    variables: {
      search: {
        relatedTo: { _id: channelId },
        channel: {
          sortBy: { key: 'name', dir: 'asc' },
          type: { any: [ChannelTypeEnum.Company] },
          ...searchStringVariable,
        },
      },
      pagination: {
        perPage: 100,
      },
    },
  });
}

function createMembersQueryPromise(
  workplaceMemberGroupRoleId: string,
  searchString: string
) {
  const searchByUserDetails = searchString
    ? {
        user: { search: { type: 'like', value: searchString } },
      }
    : undefined;
  return getClient().query({
    query: queryChannelUsersByGroupRole,
    variables: {
      groupRoleId: workplaceMemberGroupRoleId,
      search: {
        ...searchByUserDetails,
      },
      pagination: {
        perPage: 100,
      },
    },
  });
}

function reduceQueryResultsToInvoicees(resolvedQueries: any[]) {
  return resolvedQueries.reduce(
    (invoiceeArray: InvoiceeInfo[], queryResult) => {
      const { data } = queryResult;
      if (data) {
        const invoiceeChannels = mapChannelRelationshipsToInvoicees(
          data.channelsByRelationship?.items
        );
        const invoiceeMembers = mapMemberRelationshipsToInvoicees(
          data.channelUsersByGroupRole?.items
        );

        invoiceeArray = [
          ...invoiceeArray,
          ...invoiceeChannels,
          ...invoiceeMembers,
        ];
      }
      return invoiceeArray;
    },
    []
  );
}

function mapChannelRelationshipsToInvoicees(
  channelRelationships: { channel: ChannelType }[] | undefined
): InvoiceeInfo[] {
  if (!channelRelationships) {
    return [];
  }
  return channelRelationships.map(channelRelationship => {
    const {
      channel: { _id, name },
    } = channelRelationship;
    return {
      _id,
      name: name || '',
      type: ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_COMPANY,
    };
  });
}

function mapMemberRelationshipsToInvoicees(
  memberRelationships: ChannelMember[] | undefined
): InvoiceeInfo[] {
  if (!memberRelationships) {
    return [];
  }
  return memberRelationships.map(memberRelationship => {
    const {
      user: { _id, name },
    } = memberRelationship;
    return {
      _id,
      name,
      type: ExternalPayerType.EXTERNAL_PAYER_TYPE_ACTIVATE_USER,
    };
  });
}

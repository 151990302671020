import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { routes } from 'lane-shared/config';
import {
  Invoice,
  currencyFromJSON,
  CurrencyFormatMap,
  ExternalActorType,
} from 'lane-shared/domains/billingPayments/types';
import { bpErrorsCodes } from 'activate-errors';
import { useUserData } from 'lane-shared/hooks';
import { UserType } from 'lane-shared/types/User';

import { updateInvoiceToPaidMutation } from './chargesInvoiceQueries';

const LONG_TOAST_DURATION = 10000;

export interface InvoiceActions {
  label: string;
  onSelect: Function;
}

export function useGetActionsForInvoices(): InvoiceActions[] {
  const { t } = useTranslation();
  const { user } = useUserData();
  const [updateInvoiceToPaid, _] = useMutation(updateInvoiceToPaidMutation);
  const actions: InvoiceActions[] = [];
  const history = useHistory();
  const { channel } = useChannelAdminContext();
  const channel_slug = channel?.slug || '';
  const isInvoiceViewRoute = useRouteMatch(routes.channelAdminInvoiceView);

  const invoiceViewAction = (invoice: Invoice) => {
    history.push(
      routes.channelAdminInvoiceView
        .replace(':id', channel_slug)
        .replace(':invoiceDisplayId', invoice.displayId)
    );
  };

  if (!isInvoiceViewRoute) {
    actions.push({
      label: t('abp.invoice.details.viewInvoice'),
      onSelect: (_: any, invoice: Invoice) => invoiceViewAction(invoice),
    });
  }

  actions.push({
    label: t('abp.actions.markAsPaid'),
    onSelect: (_: any, invoice: Invoice) =>
      markAsPaidAction({
        invoice,
        user,
        updateInvoiceToPaid,
        t,
      }),
  });
  return actions;
}

interface MarkAsPaidAction {
  invoice: Invoice;
  user: UserType | null;
  updateInvoiceToPaid: Function;
  t: (key: string, opts?: any) => string;
}

async function markAsPaidAction(actionDetails: MarkAsPaidAction) {
  try {
    await executeMarkAsPaidAction(actionDetails);
  } catch (err: any) {
    await handleMarkAsPaidError(err, actionDetails.t);
  }
}

async function executeMarkAsPaidAction(actionDetails: MarkAsPaidAction) {
  const { invoice, t, updateInvoiceToPaid, user } = actionDetails;
  await window.Alert.confirm({
    title: t('abp.invoice.markAsPaidTitle'),
    message: t('abp.invoice.markAsPaidMessage', {
      currency: CurrencyFormatMap[currencyFromJSON(invoice.currency)],
      amount: invoice.amount,
      name: invoice.externalPayerId,
    }),
    confirmText: t('abp.actions.markAsPaid'),
  });

  // User confirmed
  await updateInvoiceToPaid({
    variables: {
      updateInvoiceToPaidRequest: {
        invoiceId: invoice.id,
        externalActorId: user?._id || '',
        externalActorType: ExternalActorType.EXTERNAL_ACTOR_TYPE_ACTIVATE_USER,
      },
    },
  });

  await window.Toast.show(
    t('abp.invoice.markAsPaidSuccessToast'),
    LONG_TOAST_DURATION
  );
}

async function handleMarkAsPaidError(err: any, t: (key: string) => string) {
  // user cancelled or mutation failed
  console.error(`Action cancelled: ${err}`);
  if (err.message) {
    if (
      err.message.toString().includes(bpErrorsCodes.invoiceAlreadyPaid.code)
    ) {
      await window.Toast.show(
        t(bpErrorsCodes.invoiceAlreadyPaid.message),
        LONG_TOAST_DURATION
      );
    } else if (
      err.message.toString().includes(bpErrorsCodes.invoiceNotIssued.code)
    ) {
      await window.Toast.show(
        t(bpErrorsCodes.invoiceNotIssued.message),
        LONG_TOAST_DURATION
      );
    } else {
      await window.Toast.show(err.message, LONG_TOAST_DURATION);
    }
  }
}

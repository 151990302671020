import React from 'react';

import cx from 'classnames';

import DateRangePickerButton from '../../../../form/DatePickers/DateRangePickerButton';
import ValidationMessage from '../../../../general/ValidationMessage';
import styleMap from '../../styleMap';

import styles from './DateRangeInputBlock.scss';

/**
 * @deprecated since 5.8.10, use the PropertyInputBlock instead.
 */
export default function DateRangeInputBlock({
  value = null,
  style = {},
  onInput = () => {},
  disabled = false,
  isValid = true,
  minRangeSize,
  maxRangeSize,
  placeholder = 'Select Date Range',
  validationMessage = 'Required.',
  className,
}: any) {
  return (
    <div
      className={cx(className, styles.DateRangeInputBlock)}
      style={styleMap(false, style)}
    >
      <DateRangePickerButton
        disabled={disabled}
        placeholder={placeholder}
        onChange={onInput}
        includeTime={false}
        minRangeSize={minRangeSize}
        maxRangeSize={maxRangeSize}
        startDate={value && value.startDate}
        endDate={value && value.endDate}
      />
      <ValidationMessage errors={!isValid ? [validationMessage] : null} />
      <div className={styles.wrapper} />
    </div>
  );
}

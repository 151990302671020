import { getChargeStatus } from 'lane-shared/helpers/features';
import {
  CHARGE_STATUS,
  Charge,
} from 'lane-shared/domains/billingPayments/types';

export function checkChargeVoidable(charge: Charge) {
  const chargeStatus = getChargeStatus(charge.status, charge);

  const voidableStates: string[] = [
    CHARGE_STATUS.CHARGE_PENDING,
    CHARGE_STATUS.CHARGE_UNPAID,
    CHARGE_STATUS.CHARGE_OVERDUE,
  ];
  const chargeVoidable = voidableStates.includes(chargeStatus[0]);

  return chargeVoidable;
}

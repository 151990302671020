import React, { useState } from 'react';

import { Icon } from 'design-system-web';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { ICON_SET_FONTAWESOME } from 'lane-shared/helpers/constants/icons';
import { useCreditCards, useStripeConfig } from 'lane-shared/hooks';

import Input from '../form/Input';
import TextArea from '../form/TextArea';
import Button from '../general/Button';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import Label from '../general/Label';
import CreditCard from './CreditCard';
import EmptyCreditCard from './EmptyCreditCard';
import PaymentAccountRuleEdit from './PaymentAccountRuleEdit';
import useManagePaymentAccount from './useManagePaymentAccount';

import styles from './StripePaymentAccount.scss';

function StripePaymentAccount({
  className,
  style,
  loading,
  channel,
  paymentAccount,
  currency,
  onPaymentAccountSave,
  onPaymentAccountRuleAdd,
  onPaymentAccountRuleSave,
  onPaymentAccountRuleDelete,
  onPaymentAccountDelete,
}: any) {
  const { publicKey } = useStripeConfig(paymentAccount?._id);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const {
    rules,
    setRules,
    editedPaymentAccount,
    setEditedPaymentAccount,
    updatePaymentAccount,
    newRule,
    setNewRule,
    updateNewRule,
    onRuleAdd,
    onRuleDelete,
    constructNewRule,
    deleteAccount,
  } = useManagePaymentAccount({
    paymentAccount,
    onPaymentAccountRuleAdd,
    onPaymentAccountRuleDelete,
    onPaymentAccountDelete,
  });

  const paymentAccountIds: string[] = [];
  if (paymentAccount?._id) {
    paymentAccountIds.push(paymentAccount._id);
  }

  const {
    loading: creditCardLoading,
    cards,
    error,
    removeCreditCard,
    addCreditCard,
  } = useCreditCards(paymentAccountIds);

  async function onDeleteCard(card: any) {
    try {
      await window.Alert.confirm({
        title: `Remove this card`,
        message: `Are you sure you want to remove this credit card from your payment methods.`,
      });
    } catch (err) {
      return;
    }

    await removeCreditCard(paymentAccount?._id, card.id);
  }

  const _paymentAccount = editedPaymentAccount || paymentAccount;

  return (
    <section
      className={cx(styles.StripePaymentAccount, className)}
      style={style}
    >
      <ControlMenu>
        <h1>Business Credit Cards</h1>
        <hr />
        <Link
          className={styles.link}
          to={routes.channelAdminPaymentAccountTransactions
            .replace(':id', channel.slug)
            .replace(':paymentAccountId', paymentAccount?._id)}
        >
          View transactions
        </Link>
      </ControlMenu>

      <section className={styles.name}>
        <fieldset>
          <Input
            label="Display Name"
            value={_paymentAccount?.name}
            placeholder="Company Procurement Cards…"
            maxLength={40}
            onChange={name => updatePaymentAccount({ name })}
          />
        </fieldset>

        <fieldset>
          <label>Description</label>
          <TextArea
            className={styles.textArea}
            placeholder="Use this account to purchase items for the business."
            value={_paymentAccount?.description}
            onChange={description => updatePaymentAccount({ description })}
          />
        </fieldset>
      </section>

      <ErrorMessage error={error} />

      <Label h2>
        <span>Available Credit Cards</span>
        <Icon
          className={styles.addIcon}
          name="plus-circle"
          set={ICON_SET_FONTAWESOME}
          onClick={() => setIsAddingCard(true)}
        />
      </Label>
      <ul className={styles.cards}>
        {cards?.map((card: any) => (
          <CreditCard
            last4={card.last4}
            brand={card.brand}
            expMonth={card.exp_month}
            expYear={card.exp_year}
            key={card.id}
          >
            <Icon
              name="times-circle"
              set={ICON_SET_FONTAWESOME}
              className={styles.removeIcon}
              onClick={() => onDeleteCard(card)}
            />
          </CreditCard>
        ))}
        {isAddingCard && (
          <EmptyCreditCard
            stripePublicKey={publicKey}
            loading={creditCardLoading}
            onAddCard={token => addCreditCard(paymentAccount?._id, token.id)}
            isEditing
            onEdit={() => null}
            onDone={() => setIsAddingCard(false)}
          />
        )}
      </ul>

      {paymentAccount?._id && (
        <>
          <Label h2>
            <span style={{ lineHeight: '1em' }}>Payment Rules</span>
            <Icon
              name="plus-circle"
              set={ICON_SET_FONTAWESOME}
              className={styles.addIcon}
              disabled={!!newRule}
              onClick={() => constructNewRule()}
            />
          </Label>

          <ul className={styles.rules}>
            {rules?.map((rule, i) => (
              <li key={(rule as any)._id}>
                <PaymentAccountRuleEdit
                  loading={loading}
                  className={styles.paymentAccountRule}
                  currency={currency}
                  rule={rule}
                  channel={channel}
                  onRuleUpdated={(update: any) => {
                    // @ts-expect-error
                    rules[i] = {
                      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                      ...rules[i],
                      ...update,
                      __updated: true,
                    };
                    setRules([...rules]);
                  }}
                  onRuleSave={onPaymentAccountRuleSave}
                  onCancel={() => setNewRule(null)}
                />
                <Icon
                  name="times-circle"
                  set={ICON_SET_FONTAWESOME}
                  className={styles.removeIcon}
                  onClick={() => onRuleDelete(rule)}
                />
              </li>
            ))}
            {newRule && (
              <li>
                <PaymentAccountRuleEdit
                  className={styles.paymentAccountRule}
                  currency={currency}
                  rule={newRule}
                  channel={channel}
                  onRuleUpdated={updateNewRule}
                  onRuleAdd={onRuleAdd}
                  onCancel={() => setNewRule(null)}
                  forCreate
                />
              </li>
            )}
          </ul>
        </>
      )}

      <ControlMenu>
        <hr />
        {paymentAccount?._id && (
          <>
            <Button
              color={'secondary' as any}
              variant="contained"
              loading={loading}
              onClick={deleteAccount}
            >
              Delete
            </Button>
            <Button
              disabled={!editedPaymentAccount}
              loading={loading}
              onClick={() => setEditedPaymentAccount(null)}
            >
              Undo
            </Button>
            <Button
              disabled={!editedPaymentAccount}
              loading={loading}
              onClick={() => onPaymentAccountSave(editedPaymentAccount)}
              variant="contained"
            >
              Save
            </Button>
          </>
        )}
      </ControlMenu>
    </section>
  );
}

export default StripePaymentAccount;

import localization from 'localization';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ServiceRequestStatusEnum } from '../constants';

const i18n = localization.cloneInstance();

/**
 * Gets the correct status label for both service requests
 * @param status
 * @returns
 */
export function getServiceRequestStatusLabel(status?: string) {
  if (!status) return '';
  if (
    !Object.values(ServiceRequestStatusEnum).includes(
      status as ServiceRequestStatusEnum
    )
  )
    return status;

  const statusKey = status.replace(/\s/g, '').toLowerCase();
  return i18n.t(`shared.workOrders.serviceRequests.status.${statusKey}`);
}

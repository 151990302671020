import React, { useState, useMemo, useEffect } from 'react';

import cx from 'classnames';

import { arrayClamp } from 'lane-shared/helpers';
import { imageUrl } from 'lane-shared/helpers/formatters';

import { WebBlockProps } from 'components/renderers/v5/WebBlockProps';
import useEditModeProps from 'components/renderers/v5/primitives/useEditModeProps';

import styles from './CarouselBlock.scss';

type BlockProps = WebBlockProps & {
  images: string[];
  sizing: 'contain' | 'cover';
  timing: number;
  useGradient: Boolean;
};

export default function CarouselBlock({
  style,
  className,
  images,
  sizing = 'contain',
  timing = 3000,
  useGradient,
  ...otherProps
}: BlockProps) {
  const [slide, setSlide] = useState(0);
  const _images = useMemo(() => images || [], [images]);
  const props = useEditModeProps(otherProps);

  useEffect(() => {
    const interval = setInterval(
      () => setSlide(slide => arrayClamp(slide + 1, images)),
      timing
    );

    return () => clearInterval(interval);
  }, [_images]);

  return (
    <div
      className={cx(styles.CarouselBlock, className)}
      style={style}
      {...props}
    >
      {_images.map((image, i) => (
        <div
          key={(image as any)?._id || image || i}
          className={styles.image}
          style={{
            backgroundImage: `url(${imageUrl(image)})`,
            backgroundSize: sizing,
          }}
          data-is-active={i === slide}
        />
      ))}

      {_images.length > 1 && (
        <menu>
          {_images.map((image, i) => (
            <button
              key={(image as any)?._id || image || i}
              data-is-active={i === slide}
              aria-label={`Slide image #${i + 1}`}
            />
          ))}
        </menu>
      )}
      {useGradient ? <div className={styles.gradient} /> : null}
    </div>
  );
}

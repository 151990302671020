import React from 'react';

import { useTranslation } from 'react-i18next';
import { currencyFormatter } from 'lane-shared/helpers/formatters';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import { PopupMenu, TextButton, Column } from 'design-system-web';
import {
  CurrencyFormatMap,
  MarkupAmountType,
  Markup,
  TaxAmountType,
  Tax,
} from 'lane-shared/domains/billingPayments/types';

import useUserLocale from 'hooks/useUserLocale';

import { Exception } from 'graphql-query-contracts/src/__generated__/graphql';

import { M } from 'components/typography';
import { useGetActionsForExceptions } from './useGetActionsForExceptions';

interface TableColumn {
  title: string;
  dataIndex: string;
  key: string;
}

export function renderFixedAmountCell(
  cell: number | null,
  currency: string
): string {
  if (cell !== null) {
    const currencyFormatMap: { [key: string]: string } = CurrencyFormatMap;
    return currencyFormatter({
      currency: currencyFormatMap[currency],
    })(cell);
  }
  return '';
}

export function useGetExceptionsTableColumns(
  channelId: string | undefined,
  onDelete: (exception: Exception) => void,
  canManage?: boolean
): TableColumn[] {
  const { t } = useTranslation();
  const { currency } = useBillingPaymentSettingsForChannel({ channelId });

  const locale = useUserLocale();

  const exceptionActions = useGetActionsForExceptions({ onDelete });

  const percentageFormat = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format;

  function renderMarkupCell(cell: Markup, currency: string): string {
    switch (cell.markupAmountType) {
      case MarkupAmountType.MarkupAmountTypePercentage:
        return `${percentageFormat(cell.value)}%`;
      case MarkupAmountType.MarkupAmountTypeFixed:
        return renderFixedAmountCell(cell.value, currency);
      default:
        return '';
    }
  }

  function renderTaxCell(cell: Tax, currency: string): string {
    switch (cell.taxAmountType) {
      case TaxAmountType.TaxAmountTypePercentage:
        return `${percentageFormat(cell.value)}%`;
      case TaxAmountType.TaxAmountTypeFixed:
        return renderFixedAmountCell(cell.value, currency);
      default:
        return '';
    }
  }
  const exceptionTableColumns: any = [
    {
      key: 'payer.id',
      header: t('abp.exceptionsList.customer'),
      renderCell: renderStringCell,
    },
    {
      key: 'productType',
      header: t('abp.exceptionsList.type'),
      renderCell: (_: undefined, row: Exception) => {
        const productType = row.productCategory?.productType;

        return productType
          ? t(`abp.productsServices.productType.${productType}`)
          : '';
      },
    },
    {
      key: 'productCategory.name',
      header: t('abp.exceptionsList.category'),
      renderCell: renderStringCell,
    },
    {
      key: 'productCount',
      header: t('abp.exceptionsList.itemsSelected'),
      renderCell: (_: undefined, row: Exception) =>
        row.productCount && row.productCount > 0
          ? t('abp.exceptionsList.items', { count: row.productCount })
          : t('abp.exceptionsList.allCategory'),
    },
    {
      key: 'amount',
      header: t('abp.exceptionsList.rate'),
      renderCell: (cell: number | null) =>
        renderFixedAmountCell(cell, currency),
    },
    {
      key: 'markup',
      header: t('abp.exceptionsList.markup'),
      renderCell: (cell: Markup) => renderMarkupCell(cell, currency),
    },
    {
      key: 'tax',
      header: t('abp.exceptionsList.tax'),
      renderCell: (cell: Tax) => renderTaxCell(cell, currency),
    },
  ];

  const actionColumn: Column<Exception> = {
    key: 'actions',
    header: t('abp.actions'),
    renderCell: (_cell: boolean, row: Exception) => {
      return (
        <PopupMenu
          trigger={
            <TextButton iconName="angle-down">{t('abp.actions')}</TextButton>
          }
          items={exceptionActions.map(({ label, onSelect }) => ({
            label,
            onSelect: () => {
              onSelect(row);
            },
            isDisabled: false,
          }))}
        />
      );
    },
  };

  if (canManage) exceptionTableColumns.push(actionColumn);

  return exceptionTableColumns;
}

function renderStringCell(cell: string) {
  return <M>{cell}</M>;
}

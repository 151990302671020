import React, { useState, useContext } from 'react';

import { ControlMenu, Button, ErrorMessage, Toggle } from 'components';
import { history } from 'helpers';
import { useIsAdminView } from 'hooks';
import { useTranslation } from 'react-i18next';
import useFlag from 'lane-shared/hooks/useFlag';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';

import { routes } from 'lane-shared/config';
import { UserDataContext } from 'lane-shared/contexts';
import { objectToArray } from 'lane-shared/helpers';
import { MetatagType } from 'lane-shared/helpers/constants/metatags';
import { DocumentType } from 'lane-shared/types/DocumentType';
import { TypeContextEnum } from 'lane-shared/types/properties/TypeContextEnum';
import { validateCreateMetatag } from 'lane-shared/validation';

import { FieldEditWindow } from 'components/builder';
import FriendlyPropertyCreate from 'components/builder/properties/creation/FriendlyPropertyCreate';
import { ValidatedInput, ValidatedTextArea } from 'components/form';
import { H3, H5 } from 'components/typography';

import MetatagEditComplexProperties from '../MetatagEditComplexProperties';
import useMetatagEdit from '../useMetatagEdit';

import styles from './styles.scss';

type Props = {
  channel: DocumentType;
};

export default function MetatagCreate({ channel }: Props) {
  const areContentTagsEnabled = useFlag(FeatureFlag.ContentTags, false);
  const { user } = useContext(UserDataContext);
  const [errors, setErrors] = useState(null);
  const [, channelSlug] = useIsAdminView();
  const { t } = useTranslation();
  const {
    metatag,
    fields,
    error,
    updating,
    validation,
    hasAttemptedSubmit,
    addField,
    saveField,
    removeField,
    editingField,
    setEditingField,
    updateName,
    updateMetatag,
    toggleType,
    createMetatag,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'DocumentType' is not assignable to type 'Cha... Remove this comment to see the full error message
  } = useMetatagEdit({ channel });

  async function onCreate() {
    try {
      const createdMetatag = await createMetatag();

      window.Toast.show(`${(metatag as any).name} created.`);

      const filtersEditRoute = areContentTagsEnabled
        ? routes.channelAdminMetatagEdit
        : routes.legacyChannelAdminMetatagEdit;

      history.push(
        filtersEditRoute
          .replace(':id', channelSlug!)
          .replace(':metatagId', createdMetatag._id)
      );
    } catch (err) {
      // error set by hook.
    }
  }

  function renderSimple() {
    return (
      <FriendlyPropertyCreate
        className={styles.simpleCreate}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'UserType | null' is not assignable to type '... Remove this comment to see the full error message
        user={user}
        channel={channel}
        contexts={[TypeContextEnum.Metatag]}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'PropertiesInterface<PropertyOptionType> | Pr... Remove this comment to see the full error message
        property={metatag.properties}
        onPropertyUpdated={properties =>
          updateMetatag({
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ _id?: string | undefined; _order?: number ... Remove this comment to see the full error message
            properties: {
              // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
              ...metatag.properties,
              ...properties,
            },
          })
        }
        showSecurity={false}
        onValidation={(errors: any) => setErrors(errors)}
      />
    );
  }

  return (
    <div className={styles.metatagCreate}>
      <ControlMenu>
        <H3>{t('web.admin.content.metatag.create.creatingNewData')}</H3>
        <hr />
        <Button
          loading={updating}
          disabled={Boolean(validation)}
          onClick={onCreate}
          variant="contained"
          testId="buttonCreate"
        >
          {t('web.admin.content.metatag.create.createButton')}
        </Button>
      </ControlMenu>
      <ErrorMessage error={validation} />
      <ErrorMessage error={error} />
      <ErrorMessage error={errors} />
      <section className={styles.setup}>
        <fieldset>
          <H5 mb={2}>{t('web.admin.content.metatag.tabItem.edit.name')}</H5>
          <ValidatedInput
            onChange={updateName}
            placeholder={t(
              'web.admin.content.metatag.tabItem.edit.namePlaceholder'
            )}
            value={(metatag as any).name}
            validation={validateCreateMetatag.fields?.name}
            isPristine={false}
            data-test="metatag-name"
          />
        </fieldset>

        <fieldset>
          <H5 mb={2}>
            {t('web.admin.content.metatag.tabItem.edit.description')}
          </H5>
          <ValidatedTextArea
            minRows={4}
            placeholder={t(
              'web.admin.content.metatag.tabItem.edit.descriptionPlaceholder'
            )}
            value={(metatag as any).description}
            isPristine={!hasAttemptedSubmit}
            validation={validateCreateMetatag.fields?.description}
            onChange={description => updateMetatag({ description } as any)}
            data-test="metatag-description"
          />
        </fieldset>
        <Toggle
          text={t('web.admin.content.metatag.tabItem.edit.showDescription')}
          onChange={showDescription =>
            updateMetatag({ showDescription } as any)
          }
          value={Boolean((metatag as any).showDescription)}
          testId="metatag-showDescription"
        />
        <Toggle
          text={t('web.admin.content.metatag.tabItem.edit.advanced')}
          onChange={toggleType}
          value={metatag?.type === MetatagType.Complex}
        />
      </section>

      {metatag?.type === MetatagType.Simple && renderSimple()}
      {metatag?.type === MetatagType.Complex && (
        <MetatagEditComplexProperties
          channel={channel}
          metatag={metatag}
          fields={fields}
          updating={updating}
          addField={addField}
          saveField={saveField}
          removeField={removeField}
          editingField={editingField}
          setEditingField={setEditingField}
          updateMetatag={updateMetatag}
          updateSchemaChanged={() => null}
        />
      )}

      {editingField && (
        <FieldEditWindow
          channel={channel}
          user={user}
          contexts={[TypeContextEnum.Data]}
          definition={objectToArray(metatag?.properties as any)}
          field={editingField}
          forCreate={(editingField as any).new}
          showSecurity={false}
          onCancel={() => setEditingField(null)}
          onSave={field => saveField(field)}
          onDelete={field => removeField(field)}
        />
      )}
    </div>
  );
}

import React, { useCallback, useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useQuery } from '@apollo/client';

import { routes } from 'lane-shared/config';
import { WorkOrderModuleSettings } from 'lane-shared/domains/workOrder';
import { channelModuleByChannelId } from 'lane-shared/graphql/modules';
import { safeConvertToUUID } from 'lane-shared/helpers';
import { MEDIUM_DATE_TIME } from 'lane-shared/helpers/constants/dates';
import { perPage } from 'lane-shared/helpers/constants/pagination';
import { convertTo62 } from 'lane-shared/helpers/convertId';
import { dateFormatter } from 'lane-shared/helpers/formatters';
import { ContentType } from 'lane-shared/types/content/Content';
import { ContentTypeEnum } from 'lane-shared/types/content/ContentTypeEnum';

import { AdminPage, Button, Input } from 'lane-web/src/components';
import { Subtitle } from 'lane-web/src/components/typography';
import useQueryString from 'lane-web/src/hooks/useQueryString';

import { searchServiceRequestsQuery } from 'graphql-queries';

import { MemberServiceRequestDetail } from './MemberServiceRequestDetail';
import { UciCard } from './UciCard';

import styles from './MemberServiceRequestList.scss';
import { ChannelType } from 'lane-shared/types/ChannelType';

export interface MemberServiceRequestListProps {
  content?: ContentType;
}

const DEBOUNCE_THROTTLE = 500;
let prevContent: string | undefined;
type serviceRequestListParams = {
  uciId: string;
};

export const MemberServiceRequestList: React.FC<MemberServiceRequestListProps> = ({
  content,
}) => {
  // we need to preserve the list of service requests between re-renders, as
  // this component will need to show the previously loaded results
  // but if the user switches channels, we need to clear out the service requests
  if (content && content._id !== prevContent) {
    prevContent = content._id;
  }
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_THROTTLE);
  const [page, setPage] = useState(0);
  const loader = useRef<HTMLDivElement>(null);
  const [
    searchParams,
    setSearchParams,
  ] = useQueryString<serviceRequestListParams>({
    uciId: '',
  });
  const [serviceRequests, setServiceRequests] = useState([]);
  let total = 0;
  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && serviceRequests.length < total) {
      setPage(page + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);
  const channelId = content?.channel?._id
    ? safeConvertToUUID(content.channel._id)
    : '';
  const { data } = useQuery(searchServiceRequestsQuery, {
    variables: {
      channelId,
      search: {
        ...(debouncedSearch
          ? {
              search: {
                type: 'like',
                value: search,
              },
            }
          : {}),
      },
      pagination: {
        start: page * perPage,
        perPage,
      },
    },
  });

  const { data: moduleResp } = useQuery(channelModuleByChannelId, {
    variables: {
      channelId,
      contentType: ContentTypeEnum.WorkOrder,
    },
  });
  const settings: WorkOrderModuleSettings =
    moduleResp?.channelModuleByChannelId?.settings;

  total = data?.searchServiceRequests?.pageInfo?.total || 0;
  useEffect(() => {
    setServiceRequests(data?.searchServiceRequests?.serviceRequests || []);
    // TM-16690: Temp fix for issue where service requests are undefined or malformed ids
    if (data?.searchServiceRequests?.serviceRequests?.[0]?.extRefId) {
      setSearchParams({
        uciId: convertTo62(
          data.searchServiceRequests.serviceRequests[0].extRefId
        ),
      });
    }
  }, [data]);

  if (!content) {
    return <></>;
  }

  return (
    <AdminPage className={styles.AdminViewContent}>
      <div className={styles.headingContainer}>
        <span className={styles.heading}>
          {t`web.admin.serviceRequest.title`}
        </span>
        <Link to={routes.contentRenderer.replace(':id', content._id)}>
          <Button variant="contained">{t`web.member.serviceRequest.new.request`}</Button>
        </Link>
      </div>

      <div className={styles.workOrdersWindowContainer}>
        <div className={styles.workOrdrListContainer}>
          <Input
            className={styles.input}
            icon="search"
            value={search}
            onChange={val => {
              setSearch(val);
              setPage(0);
            }}
            onClear={() => {
              setSearch('');
            }}
            placeholder={t`web.member.serviceRequest.search.request`}
          />
          {search && (
            <Subtitle>
              {t`web.member.serviceRequest.request.results.text`}{' '}
              <q>{search}</q>
            </Subtitle>
          )}
          {serviceRequests && serviceRequests.length > 0 ? (
            <>
              {serviceRequests.map((workOrder: any, index: number) => (
                <UciCard
                  id={workOrder.extRefId}
                  key={index}
                  description={workOrder.description}
                  date={dateFormatter(workOrder.createdAt, MEDIUM_DATE_TIME)}
                  subTitle={workOrder.issue}
                  title={workOrder.category}
                  className={styles.uciCard}
                  isActive={
                    workOrder.extRefId &&
                    searchParams.uciId === convertTo62(workOrder.extRefId)
                  }
                  onSelect={id => setSearchParams({ uciId: convertTo62(id) })}
                  status={workOrder.status}
                  index={index}
                />
              ))}
              <div ref={loader} />
            </>
          ) : (
            <div className={styles.noResultsCard}>
              {search ? (
                <Subtitle>
                  {t`web.member.serviceRequest.request.noResults.text`}{' '}
                  <q>{search}</q>
                </Subtitle>
              ) : (
                <Subtitle>
                  {t`web.member.serviceRequest.noOpen.request.text`} <br />
                  {t`web.member.serviceRequest.yay.text`}
                </Subtitle>
              )}
            </div>
          )}
        </div>
        <div className={styles.workOrderDetailContainer}>
          <MemberServiceRequestDetail
            interactionId={searchParams.uciId}
            settings={settings}
            channel={content.channel as ChannelType}
          />
        </div>
      </div>
    </AdminPage>
  );
};

import React from 'react';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { HookFormCheckBox } from 'components/reactHookForm';
import { S } from 'components/typography';

import styles from './index.scss';

const TRANSLATION_KEYS = {
  defaultLabel: 'shared.signup.form.checkboxLabel.default',
  complianceLabel: 'shared.signup.form.checkboxLabel.compliance',
  description: 'shared.signup.form.checkboxDescription',
};

type Props = {
  control: Control<any>;
  complianceContact?: WhiteLabelType['complianceContact'];
};

export function MarketingEmailOptIn({ control, complianceContact }: Props) {
  const { t } = useTranslation();
  const enableComplianceContact = useFlag(
    FeatureFlag.EnableComplianceContact,
    false
  );

  const complianceWithoutContact = t(TRANSLATION_KEYS.defaultLabel);
  const complianceWithContact = t(TRANSLATION_KEYS.complianceLabel, {
    complianceContact,
  });

  const checkBoxLabel =
    enableComplianceContact && complianceContact
      ? complianceWithContact
      : complianceWithoutContact;

  return (
    <>
      <HookFormCheckBox
        className={styles.checkBox}
        control={control}
        name="marketingOptIn"
        text={checkBoxLabel}
        labelStyles={{
          fontSize: '14px',
          fontWeight: 500,
          marginLeft: '10px',
          paddingBottom: '4px',
        }}
      />
      <div className={styles.description}>
        <S>{t(TRANSLATION_KEYS.description)}</S>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';

import { Button, Dropdown, Input, Modal, Icon } from 'design-system-web';
import { useTranslation } from 'react-i18next';

import { ValidationErrorContext } from 'lane-shared/contexts';
import sharedConfig from 'lane-shared/config';

import { ChannelIntegrationEditorProps } from '../ChannelIntegrationEditorProps';
import { SSOAddNewProvider } from './SSOAddNewProvider';

import styles from './SSOIntegrationEditor.scss';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { useOIDCEnabled } from 'lane-shared/hooks/useOIDCEnabled';

export interface Provider {
  id?: string;
  customFields: unknown[];
  entityId: string;
  name: string;
  idp?: string;
  auth0ClientId?: string;
}

export type EditorType = 'LaneSAML' | 'Auth0SAML' | 'OIDC';

export type ChannelIntegrationEditorSelectedProps = Pick<
  ChannelIntegrationEditorProps,
  'channelIntegration' | 'onUpdateChannelIntegration'
>;

export type SSOIntegrationEditorProps = ChannelIntegrationEditorSelectedProps & {
  integrationQuery: DocumentNode;
  integrationQueryVariable?: Record<string, any>;
  createIntegrationQuery: DocumentNode;
  defaultMutationValue?: Record<string, any>;
  queryDataKey: string;
  createDataKey: string;
  editorType: EditorType;
};

export function SSOIntegrationEditor({
  channelIntegration,
  onUpdateChannelIntegration,
  integrationQuery,
  integrationQueryVariable,
  queryDataKey,
  createDataKey,
  createIntegrationQuery,
  defaultMutationValue,
  editorType,
}: SSOIntegrationEditorProps) {
  const [isAddProviderOpen, setIsAddProviderOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    channelIntegration.settings?.serviceProvider
  );

  const { dashboard } = sharedConfig.auth0;

  const {
    data: providerList,
    loading: providersLoading,
    refetch: refetchProviders,
  } = useQuery(integrationQuery, {
    variables: integrationQueryVariable,
  });

  const { t } = useTranslation();
  const isOIDCEnabled = useOIDCEnabled();

  const [customFields, setCustomFields] = useState<unknown[]>([]);
  const [metadataObject, setMetadataObject] = useState({});

  const errors = useContext(ValidationErrorContext);

  function updateCustomFieldValue(name: any, value: any) {
    const field = customFields.find(f => (f as any).name === name);
    if (field) {
      const updatedMeta = { ...metadataObject };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      updatedMeta[(field as any).name] = value;
      setMetadataObject(updatedMeta);

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          metadata: updatedMeta,
        },
      });
    }
  }

  function updateSelected(id: string) {
    const provider = providerList?.[queryDataKey].find(
      (item: Provider) => convertToUUID(item.id) === convertToUUID(id)
    );

    if (provider) {
      setSelectedProvider(provider);
      setCustomFields((provider as any).customFields);

      onUpdateChannelIntegration({
        settings: {
          ...channelIntegration.settings,
          serviceProvider: {
            name: (provider as any).name,
            value: (provider as any).name,
            id: (provider as any).id,
          },
        },
      });
    }
  }

  const onCreateProvider = async (provider?: Provider) => {
    if (provider && provider.id) {
      try {
        await refetchProviders();
      } catch (e) {
        console.error(e);
      }
      setCustomFields(provider.customFields);
      setSelectedProvider(provider);
    }
    setIsAddProviderOpen(false);
  };

  useEffect(() => {
    if (selectedProvider) {
      updateSelected(selectedProvider?.id || '');
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (providerList) {
      const settings = channelIntegration?.settings;

      if (settings.metadata) {
        // check for old existing integrations: they used stringified metadata
        const parsedMetadata =
          typeof settings.metadata === 'string'
            ? JSON.parse(settings.metadata)
            : settings.metadata;
        setMetadataObject(parsedMetadata);
      }
    }
  }, [providerList?.[queryDataKey]]);

  return (
    <div>
      <div className={styles.ProviderSelector}>
        <Dropdown
          ariaLabel={t`web.admin.channel.integrations.saml.choose-provider`}
          placeholder={t`web.admin.channel.integrations.saml.choose-provider`}
          onValueChange={value => updateSelected(value)}
          value={selectedProvider?.id}
          items={providerList?.[queryDataKey].map((provider: Provider) => {
            return {
              label: provider.name,
              value: provider.id,
            };
          })}
          id="ProviderSelector"
          doTranslation={false}
        />
        <Button
          loading={providersLoading}
          onClick={() => setIsAddProviderOpen(true)}
          testId="buttonAddProvider"
        >
          {t`web.admin.channel.integrations.saml.choose-provider.add`}
        </Button>
      </div>

      <Modal
        className={styles.modal}
        onClose={() => setIsAddProviderOpen(false)}
        isOpen={isAddProviderOpen}
        title={t`web.admin.channel.integrations.saml.choose-provider.add.title`}
        size="large"
      >
        <SSOAddNewProvider
          onComplete={onCreateProvider}
          onCancel={() => setIsAddProviderOpen(false)}
          createIntegrationQuery={createIntegrationQuery}
          defaultMutationValue={defaultMutationValue}
          createDataKey={createDataKey}
          editorType={editorType}
          existingProviders={providerList?.[queryDataKey].map(
            (provider: Provider) => provider.name
          )}
        />
      </Modal>

      {selectedProvider && !isOIDCEnabled && (
        <Input
          readOnly
          className={styles.editorInput}
          label="web.admin.channel.integrations.saml.page.entityId"
          value={selectedProvider?.entityId}
          onChange={() => null}
        />
      )}

      {isOIDCEnabled &&
        selectedProvider &&
        editorType === 'OIDC' &&
        selectedProvider?.auth0ClientId && (
          <div className={styles.auth0}>
            <Input
              readOnly
              className={styles.editorInput}
              label="web.admin.channel.integrations.saml.page.auth0ClientId"
              value={selectedProvider.auth0ClientId}
              onChange={() => null}
            />
            <a
              href={dashboard.url.replace(
                '${clientId}',
                selectedProvider?.auth0ClientId
              )}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.auth0Link}
            >
              <Icon name="info-circle" />
            </a>
          </div>
        )}

      <Input
        className={styles.editorInput}
        label="web.admin.channel.integrations.saml.page.title"
        value={channelIntegration.settings.pageTitle}
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        error={errors?.inner?.find(error => error.path === 'pageTitle')?.errors}
        onChange={value =>
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              pageTitle: value,
            },
          })
        }
      />
      <Input
        className={styles.editorInput}
        label="web.admin.channel.integrations.saml.page.description"
        value={channelIntegration.settings.description}
        error={
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          errors?.inner?.find(error => error.path === 'description')?.errors
        }
        onChange={value =>
          onUpdateChannelIntegration({
            settings: {
              ...channelIntegration.settings,
              description: value,
            },
          })
        }
      />
      {editorType === 'OIDC' ? (
        <Input
          className={styles.editorInput}
          label="web.admin.channel.integrations.saml.page.redirectUrl"
          value={channelIntegration.settings.redirectURL}
          error={
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            errors?.inner?.find(error => error.path === 'redirectURL')?.errors
          }
          onChange={value =>
            onUpdateChannelIntegration({
              settings: {
                ...channelIntegration.settings,
                redirectURL: value,
              },
            })
          }
        />
      ) : (
        <Input
          className={styles.editorInput}
          label="web.admin.channel.integrations.saml.page.url"
          value={channelIntegration.settings.serviceURL}
          error={
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            errors?.inner?.find(error => error.path === 'serviceURL')?.errors
          }
          onChange={value =>
            onUpdateChannelIntegration({
              settings: {
                ...channelIntegration.settings,
                serviceURL: value,
              },
            })
          }
        />
      )}

      {customFields.map((_, i) => (
        <div key={i}>
          <Input
            className={styles.editorInput}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            label={customFields[i].name}
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            value={metadataObject[customFields[i].name]}
            onChange={value =>
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              updateCustomFieldValue(customFields[i].name, value)
            }
          />
        </div>
      ))}
    </div>
  );
}

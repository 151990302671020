import React, { useMemo } from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';

import { routes } from 'lane-shared/config';
import { CURRENCY_CONVERSION } from 'lane-shared/helpers/constants';
import { currencyFormatter, simpleDate } from 'lane-shared/helpers/formatters';
import {
  ChannelContractLineItemType,
  ChannelContractType,
} from 'lane-shared/types/ChannelContract';

import Label from 'components/general/Label';
import ChannelCircleListView from 'components/lane/ChannelCircleListView';
import CreatedBy from 'components/lane/CreatedBy';
import ShowId from 'components/lane/ShowId';
import UserCircleListView from 'components/lane/UserCircleListView';

import useUserLocale from 'hooks/useUserLocale';

import styles from './ChannelContractDetails.scss';

type Props = {
  className?: string;
  style?: React.CSSProperties;
  contract: ChannelContractType;
};

function totalReducer(total: number, lineItem: ChannelContractLineItemType) {
  return (
    total + (lineItem.pricePerUnit * lineItem.units) / CURRENCY_CONVERSION ** 2
  );
}

export default function ChannelContractDetails({
  className,
  style,
  contract,
}: Props) {
  const locale = useUserLocale();
  const moneyFormat = useMemo(
    () =>
      currencyFormatter({
        locale,
        currency: contract.lineItems[0].currency,
        maximumFractionDigits: 4,
        minimumFractionDigits: 2,
      }),
    [contract._id]
  );

  const numberFormatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format,
    [locale]
  );

  const totalValue = contract.lineItems.reduce(totalReducer, 0);

  const totalLiveValue = contract.lineItems
    .filter(
      lineItem =>
        lineItem.subscriptionStartDate &&
        lineItem.subscriptionStartDate < new Date()
    )
    .reduce(totalReducer, 0);

  const totalConfirmedValue = contract.lineItems
    .filter(lineItem => lineItem.subscriptionStartDate)
    .reduce(totalReducer, 0);

  return (
    <div className={cx(styles.ChannelContractDetails, className)} style={style}>
      <div className={styles.panel}>
        <Label h1>{contract.name}</Label>

        <Label className={styles.label}>Channel</Label>
        <Link to={routes.channelAdmin.replace(':id', contract.channel.slug)}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'ChannelType' is not assignable to type '{ _h... Remove this comment to see the full error message */}
          <ChannelCircleListView channel={contract.channel} />
        </Link>

        <Label className={styles.label}>Client Contact</Label>
        <Link
          to={routes.portalManagementUserEdit.replace(
            ':userId',
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            contract.contactUser._id
          )}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'DocumentType | null' is not assignable to ty... Remove this comment to see the full error message */}
          <UserCircleListView user={contract.contactUser} />
        </Link>
      </div>

      <div className={styles.panel}>
        <Label h1 className={cx(styles.totalValue, styles.totalLiveValue)}>
          Live{' '}
          <strong>
            {moneyFormat(totalLiveValue)} {contract.lineItems[0].currency}
          </strong>
        </Label>
        <Label h1 className={cx(styles.totalValue, styles.totalConfirmedValue)}>
          Confirmed{' '}
          <strong>
            {moneyFormat(totalConfirmedValue)} {contract.lineItems[0].currency}
          </strong>
        </Label>
        <Label h1 className={cx(styles.totalValue)}>
          Estimated {moneyFormat(totalValue)} {contract.lineItems[0].currency}
        </Label>

        <table className={styles.lineItems}>
          <thead>
            <tr>
              <th>Estimated Start Date</th>
              <th>Start Date</th>
              <th>Item</th>
              <th>Units</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {contract.lineItems.map(lineItem => (
              <tr
                key={lineItem._id}
                data-is-confirmed={!!lineItem.subscriptionStartDate}
              >
                <td>{simpleDate(lineItem.estimatedSubscriptionStartDate)}</td>
                <td>{simpleDate(lineItem.subscriptionStartDate)}</td>
                <td>{lineItem.name}</td>
                <td>
                  {numberFormatter(lineItem.units)} {lineItem.unitType}
                </td>
                <td>
                  {moneyFormat(
                    lineItem.pricePerUnit / CURRENCY_CONVERSION ** 2
                  )}
                </td>
                <td>
                  {moneyFormat(
                    (lineItem.units * lineItem.pricePerUnit) /
                      CURRENCY_CONVERSION ** 2
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.panel}>
        <Label className={styles.label}>Account Manager</Label>
        <Link
          to={routes.portalManagementUserEdit.replace(
            ':userId',
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            contract.salesUser._id
          )}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'DocumentType | null' is not assignable to ty... Remove this comment to see the full error message */}
          <UserCircleListView user={contract.salesUser} />
        </Link>

        <Label className={styles.label}>Customer Success Manager</Label>
        <Link
          to={routes.portalManagementUserEdit.replace(
            ':userId',
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            contract.managerUser._id
          )}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'DocumentType | null' is not assignable to ty... Remove this comment to see the full error message */}
          <UserCircleListView user={contract.managerUser} />
        </Link>

        <Label className={styles.label}>Customer Success</Label>
        <Link
          to={routes.portalManagementUserEdit.replace(
            ':userId',
            // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
            contract.supportUser._id
          )}
        >
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'DocumentType | null' is not assignable to ty... Remove this comment to see the full error message */}
          <UserCircleListView user={contract.supportUser} />
        </Link>
      </div>

      <ShowId className={styles.showId} id={contract._id} />
      <CreatedBy
        className={styles.createdBy}
        forAdmin
        object={{
          ...contract,
          _created: contract._created!,
          _updated: contract._updated!,
          _createdBy: { _id: contract._createdBy! },
          _updatedBy: { _id: contract._updatedBy! },
        }}
      />
    </div>
  );
}

import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import { downloadChannelInteractions } from 'helpers';
import { useQueryString } from 'hooks';
import { Link } from 'react-router-dom';

import { pause } from 'lane-shared/helpers';
import {
  SORT_ASC,
  SORT_DESC,
  SORT_START,
} from 'lane-shared/helpers/interactions/types';
import { useInteractionsOnChannelQuery } from 'lane-shared/hooks';

import Dropdown from '../form/Dropdown';
import ControlMenu from '../general/ControlMenu';
import ErrorMessage from '../general/ErrorMessage';
import IconButton from '../general/IconButton';
import Pagination from '../general/Pagination';
import { UCIList } from './UCIList';
import { UCITable } from './UCITable';

import styles from './UCIs.scss';
import { Sort } from 'design-system-web';

enum State {
  Any = 'any',
  Closed = 'closed',
  Open = 'open',
}

enum View {
  // Order matters; corresponds to order in UI
  List = 'list',
  Grid = 'th-large',
}

const stateDropdownItems = [
  {
    label: 'Any state',
    value: State.Any,
  },
  {
    label: 'Open',
    value: State.Open,
  },
  {
    label: 'Closed',
    value: State.Closed,
  },
];

export function UCIs({
  className,
  style,
  paymentAccountId,
  channelId,
  sectionId,
  merchantAccountId,
  additionalHeaders,
}: any) {
  const [downloading, setDownloading] = useState(false);
  const [query, goToUrl, makeUrl] = useQueryString({
    page: 0,
    perPage: 100,
    selectedSort: SORT_START,
    selectedOrder: SORT_DESC,
    selectedState: State.Any,
    view: View.Grid,
  });

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const page = parseInt(query.page, 10) || 0;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | boolean | Date... Remove this comment to see the full error message
  const perPage = parseInt(query.perPage, 10) || 100;
  const view = query.view || View.Grid;

  useEffect(() => {
    setPage(page);
  }, [page]);

  const {
    loading,
    interactions,
    pageInfo,
    error,
    setPage,
    refresh,
    variables,
  } = useInteractionsOnChannelQuery({
    perPage,
    channelId,
    paymentAccountId,
    sectionId,
    merchantAccountId,
    selectedOrder: query.selectedOrder,
    selectedSort: query.selectedSort,
    selectedState: query.selectedState,
  });

  async function onDownload() {
    setDownloading(true);

    window.Alert.loading({
      title: 'Preparing…',
      message: '0%',
    });

    await pause(500);

    try {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ variables: variablesType; }' i... Remove this comment to see the full error message
      await downloadChannelInteractions({ variables });
      window.Alert.hide();
    } catch (err) {
      window.Alert.hide();
      window.Alert.alert({
        title: `An error occurred downloading this file`,
        message: `See the error below and please try again`,
        error: err,
      });
    }

    setDownloading(false);
  }

  function handleSortClick(fieldToSort: any) {
    if (query.selectedSort === fieldToSort) {
      goToUrl({
        selectedOrder: query.selectedOrder === SORT_ASC ? SORT_DESC : SORT_ASC,
      });
    } else {
      goToUrl({ selectedSort: fieldToSort });
    }
  }

  return (
    <div className={cx(styles.UCIs, className)} style={style}>
      <ControlMenu>
        <hr />
        {Object.values(View).map(view => (
          <Link key={view} to={makeUrl({ view })}>
            <IconButton icon={view} selected={query.view === view} inverted />
          </Link>
        ))}

        <Dropdown
          className={styles.dropDown}
          onValueChange={selectedState => goToUrl({ selectedState })}
          items={stateDropdownItems}
          isSearchable={false}
          value={query.selectedState}
        />

        <IconButton
          icon="download"
          loading={loading || downloading}
          onClick={onDownload}
        />
        <IconButton
          icon="sync-alt"
          onClick={refresh}
          loading={loading || downloading}
        />
      </ControlMenu>
      <Pagination
        perPage={perPage}
        page={page}
        onPage={setPage}
        total={pageInfo.total}
      />
      {error && <ErrorMessage error={error} />}
      {view === View.List && (
          <UCITable
            interactions={interactions}
            selectedSort={query.selectedSort as Sort['id']}
            selectedOrder={query.selectedOrder as Sort['direction']}
            onSortClicked={handleSortClick}
            additionalHeaders={additionalHeaders}
          />
        )}
        {view === View.Grid && (
          <UCIList
            selectedSort={query.selectedSort}
            selectedOrder={query.selectedOrder}
            onSortClicked={handleSortClick}
            interactions={interactions}
          />
        )}
    </div>
  );
}

import { DateRangeType } from '../../types/baseTypes/DateRangeType';
import { PresentContentFilterTimeEnum } from '../../types/filters/PresentContentFilterTimeEnum';
import { PresetContentFilterEventDate } from '../../types/filters/PresetContentFilterEventDate';
import { SearchOptions } from '../../types/filters/SearchOptions';

export const eventDateDatePickerHandler = (options: {
  dateRange?: DateRangeType;
  searchOptions?: SearchOptions | null;
  filterByEventDate?: PresetContentFilterEventDate;
  onSearchOptionsUpdated?: (options: Partial<SearchOptions>) => void;
}) => {
  if (
    options.filterByEventDate &&
    options.searchOptions &&
    options.onSearchOptionsUpdated
  ) {
    const isEventDateEnabled =
      Boolean(options.dateRange?.startDate) &&
      Boolean(options.dateRange?.endDate);
    options.filterByEventDate.startDate = options.dateRange?.startDate;
    options.filterByEventDate.endDate = options.dateRange?.endDate;

    if (
      options.filterByEventDate.startDate &&
      options.filterByEventDate.startDate instanceof Date
    )
      options.filterByEventDate.startDate.setHours(0, 0, 0, 0);
    if (
      options.filterByEventDate.endDate &&
      options.filterByEventDate.endDate instanceof Date
    )
      options.filterByEventDate.endDate.setHours(23, 59, 0, 0);

    options.filterByEventDate.enabled = isEventDateEnabled
      ? PresentContentFilterTimeEnum.Enabled
      : PresentContentFilterTimeEnum.AnyTime;

    options.onSearchOptionsUpdated({
      areFiltersApplied: isEventDateEnabled,
      filters: [...options.searchOptions!.filters],
    });
  }
};

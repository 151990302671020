import React, { useState, useEffect } from 'react';
import {
  CurrentChannelIntegration,
  HIDTemplatesMap,
  SavedCredentialTemplates,
  Template,
} from '../types';
import { HIDCredTemplateStrategy } from '../../AccessManagementEditor/credentialTemplate';
import { useAccessManagementIntegration } from './useAccessManagmentIntegration';
import { getTemplateLabel } from '../helpers';

export function useSavedCredentialTemplates(
  strategy: HIDCredTemplateStrategy,
  hidTemplatesMap: HIDTemplatesMap
): [Template[], React.Dispatch<React.SetStateAction<Template[]>>] {
  const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([]);
  const currentChannelIntegration = useAccessManagementIntegration();

  useEffect(() => {
    if (!hidTemplatesMap) return;

    let savedTemplates: Template[] = [];

    if (strategy === HIDCredTemplateStrategy.Both) {
      let partNumberLabels: Template[] = [];
      let transformedCredentialTemplates: Template[] = [];
      partNumberLabels = preparePartNumbers(
        currentChannelIntegration,
        hidTemplatesMap
      );

      transformedCredentialTemplates = prepareCredentialTemplates(
        currentChannelIntegration,
        hidTemplatesMap
      );

      savedTemplates = normalizeCredentialTemplates(
        transformedCredentialTemplates,
        partNumberLabels
      );
    }

    if (strategy === HIDCredTemplateStrategy.HID30) {
      const hid30Templates = prepareCredentialTemplates(
        currentChannelIntegration,
        hidTemplatesMap
      );

      savedTemplates = hid30Templates;
    }

    if (strategy === HIDCredTemplateStrategy.HID22) {
      const hid22Templates = preparePartNumbers(
        currentChannelIntegration,
        hidTemplatesMap
      );

      savedTemplates = hid22Templates;
    }

    setSelectedTemplates(savedTemplates);
  }, [currentChannelIntegration, strategy, hidTemplatesMap]);

  return [selectedTemplates, setSelectedTemplates];
}

function preparePartNumbers(
  currentChannelIntegration: CurrentChannelIntegration,
  hidTemplatesMap: HIDTemplatesMap
) {
  const partNumbers =
    currentChannelIntegration?.channelIntegration?.settings
      ?.identityProviderSettings?.partNumbers || [];
  return partNumbers
    .map(({ partNumberId }: { partNumberId: string }) => {
      const label = getTemplateLabel(
        hidTemplatesMap,
        partNumberId,
        HIDCredTemplateStrategy.HID22
      );
      return {
        label,
        value: label,
      };
    })
    .filter((template: Template) => template.label !== '');
}

function prepareCredentialTemplates(
  currentChannelIntegration: CurrentChannelIntegration,
  hidTemplatesMap: HIDTemplatesMap
) {
  const savedCredentialTemplates: SavedCredentialTemplates[] =
    currentChannelIntegration?.channelIntegration?.settings
      ?.identityProviderSettings?.credentialTemplates || [];
  return savedCredentialTemplates
    .map(({ credentialTemplateId }: { credentialTemplateId: string }) => {
      const label = getTemplateLabel(
        hidTemplatesMap,
        credentialTemplateId,
        HIDCredTemplateStrategy.HID30
      );
      return {
        label,
        value: label,
      };
    })
    .filter((template: Template) => template.label !== '');
}

// return intersection of HID 3.0 and 2.2 templates/part numbers
function normalizeCredentialTemplates(
  savedCredentialTemplates: Template[],
  savedPartNumbers: Template[]
) {
  // if the number of HID 3.0 templates is greater than the number of HID 2.2 templates
  if (savedCredentialTemplates.length > savedPartNumbers.length) {
    const normalizedCredentialTemplates = savedCredentialTemplates;
    savedPartNumbers.forEach(partNumber => {
      if (
        // find the partNumber that is not in the normalizedCredentialTemplates
        // and push it to the normalizedCredentialTemplates
        !normalizedCredentialTemplates.find(
          template => template.value === partNumber.value
        )
      ) {
        normalizedCredentialTemplates.push(partNumber);
      }
    });

    return normalizedCredentialTemplates;
  }

  const normalizedTemplates = savedPartNumbers;
  savedCredentialTemplates.forEach(template => {
    if (
      !normalizedTemplates.find(
        partNumber => partNumber.value === template.value
      )
    ) {
      normalizedTemplates.push(template);
    }
  });

  return normalizedTemplates;
}

import React, { useContext, useState } from 'react';

import BigNumber from 'bignumber.js';
import {
  Button as ButtonV1,
  ErrorMessage,
  Input,
  Line,
  TextArea,
} from 'components';
import { Button } from 'design-system-web';
import { useChannelAdminContext } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { UserDataContext } from 'lane-shared/contexts';
import { useBillingPaymentSettingsForChannel } from 'lane-shared/domains/billingPayments/hooks';
import {
  AddProductToChargeDetails,
  Currency,
  ExternalPayerType,
  TaxPolicy,
  currencyToPaymentCurrency,
  InvoiceeInfo,
} from 'lane-shared/domains/billingPayments/types';

import { AddProductToCharge } from 'lane-web/src/pages/portal/admin/channel/charges-invoices/add-product-to-charge';

import { M } from 'components/typography';

import { ChargeIssueOptions } from './ChargeIssueOptions';
import { InvoiceeSearchBar } from './InvoiceeSearchBar';
import { createChargeMutation } from './helpers/createChargeMutation';
import { debounce } from 'lodash';
import styles from './styles.scss';
import { useFlag } from 'lane-shared/hooks';
import { FeatureFlag } from 'lane-shared/types/FeatureFlag';
import { AddItemsToCharge } from './add-items-to-charge';
import { InvoiceType } from 'graphql-query-contracts';

export const MAX_CHARGE_AMOUNT = 1000000;
const FUNCTION_THROTTLE = 1000;

export interface ChargeDetails {
  invoicee: InvoiceeInfo;
  chargeName: string;
  chargeDescription: string;
  invoiceType: InvoiceType;
  invoiceDueDate?: Date;
  currency: Currency;
  amount: number;
}

export function CreateChargeForm({
  saveAndCancelRedirectLink,
}: {
  saveAndCancelRedirectLink: string;
}) {
  const isCreditBundlesInChargesEnabled = useFlag(
    FeatureFlag.CreditBundlesInCharges,
    false
  );
  const { t } = useTranslation();
  const history = useHistory();
  const channelContext = useChannelAdminContext();
  const { user } = useContext(UserDataContext);
  const {
    currency,
    taxDetails,
    isInvoicingDisabled,
  } = useBillingPaymentSettingsForChannel({
    channelId: channelContext.channel?._id,
  });

  const inclusivePricing = taxDetails?.taxPolicy === TaxPolicy.INCLUSIVE;
  const [newChargeDetails, setNewChargeDetails] = React.useState<ChargeDetails>(
    {
      invoicee: {
        name: '',
        _id: '',
        type: ExternalPayerType.EXTERNAL_PAYER_TYPE_UNKNOWN,
      },
      chargeName: '',
      chargeDescription: '',
      invoiceType: InvoiceType.InvoiceTypeMonthly,
      invoiceDueDate: undefined,
      currency: currency || Currency.CURRENCY_USD,
      amount: 0,
    }
  );
  const [error, setError] = useState<Error | undefined>(undefined);
  const [listOfProducts, setListOfProducts] = useState<
    AddProductToChargeDetails[]
  >([]);

  const handleNewChargeFieldsUpdate = (value: any, fieldname: string) => {
    setNewChargeDetails(prev => ({
      ...prev,
      [fieldname]: value,
    }));
  };

  const handleUpdateInvoicee = (invoicee: InvoiceeInfo) => {
    setNewChargeDetails(prev => ({
      ...prev,
      invoicee,
    }));

    // Reset line items on changing invoicee
    setListOfProducts([]);
  };

  async function handleSaveChargeFunction(): Promise<void> {
    try {
      const totalAmount = listOfProducts.reduce((acc, product) => {
        return new BigNumber(acc).plus(new BigNumber(product.total)).toNumber();
      }, 0);
      if (totalAmount > MAX_CHARGE_AMOUNT) {
        throw new Error(t('abp.charges.createCharge.maxAmountError'));
      }

      await createChargeMutation(
        {
          ...newChargeDetails,
          channelId: channelContext.channel?._id || '',
          currency: currency || Currency.CURRENCY_USD,
          chargeCreatorUserId: user?._id || '',
          amount: totalAmount,
          invoiceType: isInvoicingDisabled
            ? InvoiceType.InvoiceTypeNoInvoice
            : newChargeDetails.invoiceType,
        },
        listOfProducts
      );
      history.push(saveAndCancelRedirectLink);
      window.Toast.show(
        <M className={styles.chargeSuccessToast}>
          {t('abp.charges.chargeCreatedSuccessfully')}
        </M>
      );
    } catch (err) {
      console.error(err);
      setError(err as Error);
    }
  }

  const handleSaveCharge = debounce(
    handleSaveChargeFunction,
    FUNCTION_THROTTLE
  );

  function isCreateButtonDisabled() {
    const {
      chargeName,
      invoicee: { name, _id },
      invoiceType,
      invoiceDueDate,
    } = newChargeDetails;

    const isAtLeastOneProductAdded = listOfProducts.length > 0;

    const isInvoiceDetailsOrProductsMissing = !(
      chargeName &&
      name &&
      _id &&
      isAtLeastOneProductAdded
    );

    const isDueDateMissingForOneOff =
      invoiceType === InvoiceType.InvoiceTypeOneoff && !invoiceDueDate;

    return isInvoiceDetailsOrProductsMissing || isDueDateMissingForOneOff;
  }
  return (
    <div className={styles.createChargeForm}>
      <div className={styles.createChargeFormInputs}>
        <InvoiceeSearchBar handleUpdateInvoicee={handleUpdateInvoicee} />
        <Input
          className={styles.createChargeFieldInput}
          fieldName="chargeName"
          fixedLabel
          isRequired
          label={t('abp.charges.chargeName')}
          onChange={handleNewChargeFieldsUpdate}
          value={newChargeDetails.chargeName}
          maxLength={200}
          testId="ChargeNameInput"
        />
        <div>
          <label
            htmlFor="productDescription"
            className={styles.chargeDescriptionInputLabel}
          >
            {t('abp.charges.chargeDescriptionOptional')}
          </label>
          <TextArea
            className={styles.chargeDescriptionFieldInput}
            onChange={value =>
              handleNewChargeFieldsUpdate(value, 'chargeDescription')
            }
            value={newChargeDetails.chargeDescription}
            minRows={4}
            maxLength={1000}
            showClear={false}
            testId="ChargeDescriptionInput"
          />
        </div>
        {!isInvoicingDisabled && (
          <ChargeIssueOptions
            updateChargeDetails={handleNewChargeFieldsUpdate}
          />
        )}
      </div>

      {isCreditBundlesInChargesEnabled ? (
        <>
          <AddItemsToCharge
            listOfProducts={listOfProducts}
            setListOfProducts={setListOfProducts}
            currency={currencyToPaymentCurrency(currency)}
            inclusivePricing={inclusivePricing}
            payer={{
              id: newChargeDetails.invoicee._id,
              type: newChargeDetails.invoicee.type,
            }}
          />
          <div className={styles.buttonGroup}>
            <Button
              size="large"
              onClick={() => handleSaveCharge()}
              disabled={isCreateButtonDisabled()}
              testId="CreateChargeButton"
            >
              {t('abp.create')}
            </Button>
            <Button
              size="large"
              onClick={() => history.push(saveAndCancelRedirectLink)}
              testId="CreateChargeCancelButton"
              variant="secondary"
            >
              {t('abp.cancel')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <AddProductToCharge
            listOfProducts={listOfProducts}
            setListOfProducts={setListOfProducts}
            currency={currencyToPaymentCurrency(currency)}
            inclusivePricing={inclusivePricing}
            payer={{
              id: newChargeDetails.invoicee._id,
              type: newChargeDetails.invoicee.type,
            }}
          />
          <Line className={styles.buttonGroupLine} />
          <div className={styles.buttonGroup}>
            <ButtonV1
              onClick={() => history.push(saveAndCancelRedirectLink)}
              testId="CreateChargeCancelButton"
            >
              {t('abp.cancel')}
            </ButtonV1>
            <ButtonV1
              variant="contained"
              onClick={() => handleSaveCharge()}
              disabled={isCreateButtonDisabled()}
              testId="CreateChargeButton"
            >
              {t('abp.create')}
            </ButtonV1>
          </div>
        </>
      )}

      {error && <ErrorMessage error={error} />}
    </div>
  );
}

import React, { useState } from 'react';

import { Loading } from 'components';
import { useHistory } from 'react-router';

import { routes } from 'lane-shared/config';
import { explodeFeatures } from 'lane-shared/helpers/features';
import useContentFeatureQRCodeCheckIn from 'lane-shared/hooks/features/qrCodeCheckIn/useContentFeatureQRCodeCheckIn';

import QRCodeCheckInOutUCIReceipt from 'components/lane/userContentInteractionReceipts/QRCodeCheckInOutUCIReceipt';
import { Modal } from 'components/lds';

export default function ContentFeatureQRCodeCheckInOut({ content, mode }: any) {
  const [hasUserCancelled, setHasUserCancelled] = useState(false);
  const history = useHistory();
  const { qrCodeCheckinFeature } = explodeFeatures(content?.features);
  const {
    interaction,
    loading,
    canCheckInOrOut,
  } = useContentFeatureQRCodeCheckIn({
    contentId: content?._id,
    qrCodeCheckinFeature,
    mode,
  });

  async function onInteractionUpdated() {
    history.push(routes.interaction.replace(':id', interaction._id));
  }

  if (!content) {
    return null;
  }

  return (
    <Modal
      isOpen={Boolean(canCheckInOrOut) && !hasUserCancelled}
      onClose={() => setHasUserCancelled(true)}
      size="large"
    >
      {loading ? (
        <Loading />
      ) : (
        <QRCodeCheckInOutUCIReceipt
          interaction={interaction}
          mode={mode}
          onInteractionUpdated={onInteractionUpdated}
        />
      )}
    </Modal>
  );
}

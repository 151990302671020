/**
 * Maps block styles to web styles.
 *
 * @param type ignored on web
 * @param style the style object
 * @returns {null|*}
 */
export default function styleMap(type: any, style: any) {
  if (!style) {
    return null;
  }

  if (typeof style === 'object') {
    const ret = {
      ...style,
    };

    if (style.flex && style.flex === '1') {
      ret.flex = '1 1 auto';
    }

    return ret;
  }
}

import { AccessManagement } from './AccessManagementEditor/AccessManagementEditor';
import { ControlByWeb } from './ControlByWebIntegrationEditor';
import { Entrata } from './EntrataIntegrationEditor';
import FloorMaps from './FloorMaps';
import HID from './HID';
import MailChimp from './MailChimp';
import { RealPage } from './RealPageIntegrationEditor';
import { SAMLIntegrationEditor } from './SAMLIntegrationEditor';
import ShopifyBurst from './ShopifyBurst';
import { Yardi } from './YardiIntegrationEditor/YardiIntegrationEditor';
import { YardiCommercial } from './YardiIntegrationEditor/YardiCommercialIntegrationEditor';
import { OIDCIntegrationEditor } from './OIDCIntegrationEditor';
import { Auth0SAMLIntegrationEditor } from './Auth0SAMLIntegrationEditor';

export default {
  FloorMaps,
  HID,
  SAML: SAMLIntegrationEditor,
  ShopifyBurst,
  AccessManagement,
  Yardi,
  YardiCommercial,
  RealPage,
  Entrata,
  Auth0SAML: Auth0SAMLIntegrationEditor,
  MailChimp,
  OIDC: OIDCIntegrationEditor,
  ControlByWeb,
};

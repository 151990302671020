import { getClient } from 'lane-shared/apollo';
import { createAuth0MembershipInvitation as createAuth0MembershipInvitationMutation } from 'lane-shared/graphql/mutation/createAuth0MembershipInvitation';
import { loginEmailExists } from 'lane-shared/graphql/user';
import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import { getCurrentAuth0Connection } from 'lane-shared/graphql/mutation/getCurrentAuth0Connection';
import { useInviteContext } from '../../contexts/InviteContext';
import { useHideAuth0LoginAlertBox } from '../auth0/useHideAuth0LoginAlertBox';

type QueryData = {
  loginEmailExists: boolean;
};

type QueryVariables = {
  email: string;
};

type MutationResponse = {
  createAuth0MembershipInvitation: {
    id: string;
    ticketId: string;
    organizationId: string;
  };
};

type ApolloClient = ReturnType<typeof getClient>;

type MutationVariables = {
  email: string;
};

export class InviteOnlyError extends Error {
  constructor(message: string) {
    super(message);

    this.name = 'InviteOnlyError';
  }
}

async function fetchLoginEmailExists(
  apolloClient: ApolloClient,
  email: string
) {
  const { data, error } = await apolloClient.query<QueryData, QueryVariables>({
    query: loginEmailExists,
    variables: { email },

    fetchPolicy: 'network-only',
  });

  if (error) {
    throw error;
  }

  return data.loginEmailExists;
}

async function createAuth0MembershipInvitation(
  apolloClient: ApolloClient,
  email: string
) {
  const { errors, data } = await apolloClient.mutate<
    MutationResponse,
    MutationVariables
  >({
    mutation: createAuth0MembershipInvitationMutation,
    variables: { email },
  });

  if (errors) {
    throw errors;
  }

  return data?.createAuth0MembershipInvitation;
}

export const useIdentifyUser = () => {
  const apolloClient = getClient();

  const { whitelabel } = useContext(AppContext);
  const { inviteId } = useInviteContext();

  const [getCurrentAuth0ConnectionMutation] = useMutation(
    getCurrentAuth0Connection
  );

  const isHideLoginAlertBoxEnabled = useHideAuth0LoginAlertBox();

  const identifyUser = async (email: string) => {
    const isLoginExist = await fetchLoginEmailExists(apolloClient, email);

    const areSignUpsEnabled = Boolean(whitelabel.settings?.allowSignUps);
    const isInvitedUser = Boolean(inviteId);

    // If the user does not exist, then it is a sign-up
    // If users try to signup organically or using SSO or passwordless but signups are disabled
    // Block the user from signing up
    if (!isLoginExist && !isInvitedUser && !areSignUpsEnabled) {
      throw new InviteOnlyError('InviteOnlyError');
    }

    if (!isHideLoginAlertBoxEnabled) {
      if (isLoginExist && !whitelabel.isMagicLinkEnabled) {
        return {
          loginEmailExists: true,
        };
      }
    }

    // Step 1/Step 2: HRD connection exists OR Passwordless connection exists
    const connection = await getCurrentAuth0ConnectionMutation({
      variables: { email },
    });

    if (connection && connection.data?.getCurrentAuth0Connection?.connection) {
      if (isHideLoginAlertBoxEnabled) {
        return {
          loginEmailExists: true,
          connection: connection.data.getCurrentAuth0Connection.connection,
        };
      }
      return {
        loginEmailExists: true,
        connection:
          connection.data.getCurrentAuth0Connection.connection === 'email'
            ? 'email'
            : undefined,
      };
    }

    // Step3 : Doesn't found HRD connection or Passwordless connection and it's Existing user then login
    if (isLoginExist) {
      return {
        loginEmailExists: true,
      };
    }

    // Step 4: User doesn't exists and HRD & Magic Link not enabled
    const invitationData = await createAuth0MembershipInvitation(
      apolloClient,
      email
    );

    return {
      loginEmailExists: false,
      invitation: invitationData,
    };
  };

  return {
    identifyUser,
  };
};

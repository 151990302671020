import {
  FilterType,
  CustomFilterType,
  NativeFilterTypes,
} from 'design-system-web';
import { INVOICE_STATUS } from 'lane-shared/domains/billingPayments/types';
import { useTranslation } from 'react-i18next';

const PER_PAGE = 100;

export const DEFAULT_FILTER_PARAMS = {
  page: 0,
  pageSize: PER_PAGE,
  total: 0,
};

export type InvoiceQueryString = {
  status: string;
  dueDate: string;
  invoiceDate: string;
  page?: string;
  pageSize?: string;
  total?: string;
};

export const useGetInvoiceTableFilters = (): (
  | FilterType
  | CustomFilterType<any>
)[] => {
  const { t } = useTranslation();

  const tableFilters: (FilterType | CustomFilterType<any>)[] = [
    {
      key: 'status',
      label: t('abp.invoiceList.status'),
      type: NativeFilterTypes.Multiselect,
      options: getFilterableStatuses() || [],
      isPromoted: true,
    },
    {
      key: 'invoiceDate',
      label: t('abp.invoiceList.issueDate'),
      type: NativeFilterTypes.DateRange,
      isPromoted: true,
    },
    {
      key: 'dueDate',
      label: t('abp.invoiceList.dueDate'),
      type: NativeFilterTypes.DateRange,
      isPromoted: true,
    },
  ];

  return tableFilters;
};

const getFilterableStatuses = (): { label: string; value: string }[] => {
  const statuses = [
    {
      label: INVOICE_STATUS.INVOICE_PENDING,
      value: 'CHARGE_ADDED',
    },
    {
      label: INVOICE_STATUS.INVOICE_UNPAID,
      value: 'INVOICED',
    },
    {
      label: INVOICE_STATUS.INVOICE_OVERDUE,
      value: 'OVERDUE',
    },
    // we dont have a void status, unpaid will show void which is not exactly the correct behavior
    // {
    //   label: INVOICE_STATUS.INVOICE_VOID,
    //   value: 'VOID',
    // },
    {
      label: INVOICE_STATUS.INVOICE_PAID,
      value: 'PAID',
    },
    {
      label: INVOICE_STATUS.INVOICE_PAYMENTFAILED,
      value: 'PAYMENT_FAILED',
    },
  ];

  return statuses;
};

import { useMemo } from 'react';

import { getDisplayName } from 'lane-shared/helpers';
import useThemeColors from 'lane-shared/hooks/useThemeColors';
import { WhiteLabelType } from 'lane-shared/types/WhiteLabelType';

import { imageUrl } from '../helpers/formatters';

/** 
    Helper hook to grab initial theme in Providers
    If we are in the context of a Whitelabel, there is only one color.
    If we are in the context of a channel, use channel primary + secondary colors
*/

export default function useInitialTheme({
  whitelabel,
  channelId,
}: {
  whitelabel: Partial<WhiteLabelType>;
  channelId?: string;
}) {
  const channelTheme = useThemeColors(channelId);
  return useMemo(() => {
    const shouldUseChannelTheme = Boolean(channelId);
    const whitelabelColor = whitelabel?.profile?.backgroundColor;

    return {
      ...(whitelabel?.profile?.theme.palette || {}),
      logo: whitelabel?.profile?.logo,
      name: getDisplayName(whitelabel),
      primary: shouldUseChannelTheme ? channelTheme.primary : whitelabelColor,
      secondary: shouldUseChannelTheme
        ? channelTheme.secondary
        : whitelabelColor,
      instance: whitelabel?.instance,
      accentColor: whitelabelColor,
      backgroundImage: imageUrl(whitelabel?.profile?.backgroundImage),
    };
  }, [whitelabel, channelTheme, channelId]);
}

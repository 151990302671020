import { useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';

import { LaneType } from 'common-types';
import { getChannelGroupRoles } from 'lane-shared/graphql/query';
import { getDisplayName } from 'lane-shared/helpers';
import { PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM } from 'lane-shared/helpers/constants/permissions';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { GroupRole } from 'lane-shared/types/GroupRole';

import getUsersByWorkOrderTeams from '../../../graphql/workOrders/getUsersByWorkOrderTeams';

export type AdminUser = {
  label: string;
  profile: any;
  value: string;
  groupRoleId?: string;
};

export const useAssignableMembers = (
  channelId: string,
  // search assignable members of specific groupRole
  groupRoleIds: LaneType.UUID[] = []
): [AdminUser[], boolean, AdminUser[]] => {
  const { data, loading: groupRolesLoading } = useQuery(getChannelGroupRoles, {
    variables: {
      id: channelId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const groupRoles = cloneDeep(data?.channel?.groupRoles || []);

  const assignableTeams = groupRoles.filter((gr: GroupRole) =>
    gr?.permissions?.includes(
      PERMISSION_WORK_ORDERS_SERVICE_REQUEST_ASSIGNABLE_TEAM
    )
  );

  const groupRoleIdsToFetch =
    groupRoleIds.length > 0
      ? groupRoleIds
      : assignableTeams.map((gr: any) => gr._id);

  const { data: usersData, loading: usersLoading } = useQuery(
    getUsersByWorkOrderTeams,
    {
      skip: !groupRoleIdsToFetch || groupRoleIdsToFetch.length === 0,
      variables: {
        groupRoleIds: groupRoleIdsToFetch,
        search: {},
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const uniqueIDs = new Set();
  const adminUsers: AdminUser[] =
    usersData?.workOrderUsersByGroupRoles?.items
      ?.map(({ user }: any) => {
        if (!uniqueIDs.has(user._id)) {
          uniqueIDs.add(user._id);
          return {
            label: getDisplayName(user.profile),
            profile: user.profile,
            value: user._id,
          };
        }
        return {};
      })
      .filter((obj: any) => Object.keys(obj).length !== 0)
      .sort((a: any, b: any) => {
        return a.label.localeCompare(b.label);
      }) ?? [];

  const adminTeams = assignableTeams
    .map((gr: any) => ({
      label: gr.name,
      profile: {
        name: gr.name,
      },
      value: gr._id ? convertToUUID(gr._id) : gr._id,
    }))
    .sort((a: any, b: any) => {
      return a.label.localeCompare(b.label);
    });

  return [adminUsers, groupRolesLoading || usersLoading, adminTeams];
};

import { useEffect, useState } from 'react';

import gql from 'graphql-tag';

import { useLazyQuery } from '@apollo/client';

import { getClient } from '../apollo';
import ThemeFragment from '../graphql/fragments/ThemeFragment';
import useUpdatedData from './useUpdatedData';

const themeQuery = gql`
  ${ThemeFragment}

  query getTheme($themeId: UUID!) {
    theme(id: $themeId) {
      ...ThemeFragment
    }
  }
`;

const updateThemeMuation = gql`
  mutation updateTheme($theme: ThemeInput) {
    updateTheme(theme: $theme) {
      _id
    }
  }
`;

export default function useThemeEdit({ themeId }: any) {
  const [_loading, setLoading] = useState(false);
  const [_error, setError] = useState<Error | null>(null);

  const [fetchTheme, { data, loading, error }] = useLazyQuery(themeQuery, {
    client: getClient(),
    fetchPolicy: 'cache-and-network',
    variables: {
      themeId,
    },
  });

  const [theme, setTheme, hasChanges, getPatch] = useUpdatedData(null);

  async function saveTheme() {
    setError(null);
    setLoading(true);

    try {
      const update = getPatch();
      update._id = (theme as any)._id;

      await getClient().mutate({
        mutation: updateThemeMuation,
        variables: {
          theme: update,
        },
      });

      refetchTheme();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  function refetchTheme() {
    if (themeId) {
      fetchTheme({
        variables: {
          themeId,
        },
      });
    }
  }

  useEffect(() => {
    if (data?.theme) {
      setTheme(data.theme, true);
    }
  }, [data?.theme]);

  useEffect(() => {
    refetchTheme();
  }, [themeId]);

  return {
    theme,
    setTheme,
    hasChanges,
    saveTheme,
    refetchTheme,
    loading: _loading || loading,
    error: _error || error,
  };
}

import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, Button, Flex } from 'design-system-web';
import { explodePresetContentFilters } from 'lane-shared/helpers/content';
import { SearchOptions } from 'lane-shared/types/filters/SearchOptions';

import ChannelLocationsSelectorDropdown from 'components/lane/ChannelLocationsSelectorDropdown';
import { ChannelSelectorDropdown } from 'components/lane/ChannelSelectorDropdown';

import styles from './SectionSearchBar.scss';
import { SearchBar } from 'components';
import { DateRangeType } from 'lane-shared/types/baseTypes/DateRangeType';
import { EventDatePickerDropdown } from 'components/lane/EventDatePickerDropdown';
import { PresetContentFilterLocation } from 'lane-shared/types/filters/PresetContentFilterLocation';
import { updateSearchOptionsWithNewLocationFilter } from 'lane-shared/helpers/filters/searchBarLocationChangeHandlerHelper';
import { eventDateDatePickerHandler } from 'lane-shared/helpers/sections/EventDateDatePickerHandler';

type Props = {
  searchOptions: SearchOptions | null | undefined;
  hasChannelSelector?: boolean;
  hasChannelLocationsSelector?: boolean;
  hasEventDateSelector?: boolean;
  allLocationsSelected?: boolean;
  channelId?: string;
  selectedChannelId?: string;
  channels?: any[];
  onClear?: () => void;
  showAllLocations?: boolean;
  showFilters?: boolean;
  showSearch: boolean;
  isFiltersEnabled?: boolean;
  className?: string;
  searchInputClassName?: string;
  searchInputPlaceholder?: string;
  onOpenFilters?: () => void;
  style?: React.CSSProperties;
  onSearchChange: (search: string) => void;
  onChannelSelected?: (channel: any) => void;
  onAllLocationsSelected?: () => void;
  onSearchOptionsUpdated?: (options: Partial<SearchOptions>) => void;
  disabledSearch?: boolean;
  errors?: string[];
};

export function SectionSearchBar({
  className,
  searchInputClassName,
  style,
  searchOptions,
  channelId,
  selectedChannelId,
  searchInputPlaceholder,
  hasChannelSelector = false,
  hasChannelLocationsSelector = false,
  hasEventDateSelector = false,
  channels,
  showAllLocations = false,
  showFilters = false,
  showSearch = false,
  onOpenFilters,
  onSearchChange,
  onChannelSelected,
  onAllLocationsSelected,
  allLocationsSelected,
  onSearchOptionsUpdated,
  disabledSearch,
  errors,
}: Props) {
  const { t } = useTranslation();

  const {
    filterByChannelLocations,
    filterByEventDate,
  } = explodePresetContentFilters(searchOptions?.filters);

  function dateRangePickerHandler(dateRange?: DateRangeType) {
    eventDateDatePickerHandler({
      dateRange,
      searchOptions,
      onSearchOptionsUpdated,
      filterByEventDate,
    });
  }

  const locationsChangeHandler = (
    filterToUpdate: PresetContentFilterLocation
  ) => {
    if (searchOptions && onSearchOptionsUpdated) {
      const updatedSearchOptionsFilters = updateSearchOptionsWithNewLocationFilter(
        searchOptions,
        filterToUpdate
      );
      onSearchOptionsUpdated({
        areFiltersApplied: filterToUpdate.enabled,
        filters: [...updatedSearchOptionsFilters],
      });
    }
  };

  const shouldDisplayFilterContainer =
    hasChannelLocationsSelector ||
    hasChannelSelector ||
    (hasEventDateSelector && filterByEventDate) ||
    showFilters;

  return (
    <div
      className={cx(styles.SearchBar, className)}
      style={style}
      data-has-search={showSearch}
      data-has-filters={showFilters}
    >
      {shouldDisplayFilterContainer && (
        <Flex justify="center">
          <Flex
            justify="flex-start"
            gap={3}
            className={styles.searchBarContainer}
          >
            {hasChannelLocationsSelector && (
              <ChannelLocationsSelectorDropdown
                className={styles.channelLocationsSelector}
                filter={filterByChannelLocations}
                channels={channels}
                onFilterUpdated={locationsChangeHandler}
                showAllLocations
              />
            )}
            {hasChannelSelector && (
              <ChannelSelectorDropdown
                wrapperClassName={styles.channelSelector}
                channelId={channelId}
                selectedChannelId={selectedChannelId}
                channels={channels}
                showAllLocations={showAllLocations}
                onChannelSelected={onChannelSelected}
                allLocationsSelected={allLocationsSelected}
                onAllLocationsSelected={onAllLocationsSelected}
              />
            )}
            {hasEventDateSelector && filterByEventDate && (
              <EventDatePickerDropdown
                wrapperClassName={styles.eventDateSelector}
                eventDateSelector={filterByEventDate}
                onEventDateSelected={dateRangePickerHandler}
              />
            )}
            {showFilters && (
              <Button
                startIcon={<Icon name="Filter" />}
                variant="secondary"
                size="medium"
                onClick={onOpenFilters}
              >
                {t('web.components.general.SectionSearchBar')}
              </Button>
            )}
          </Flex>
        </Flex>
      )}

      {showSearch && (
        <Flex>
          <SearchBar
            searchInputClassName={searchInputClassName}
            searchOptions={searchOptions}
            onSearchChange={onSearchChange}
            disabledSearch={disabledSearch}
            searchInputPlaceholder={searchInputPlaceholder}
            errors={errors}
          />
        </Flex>
      )}
    </div>
  );
}

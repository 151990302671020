import styles from './index.scss';
import { H4 } from 'components/typography';
import React from 'react';
import { Trans } from 'react-i18next';

const TRANSLATION_KEYS = {
  header: 'shared.onboarding.channelSelection.header',
};

export function Header({ userName }: { userName: string }) {
  return (
    <div className={styles.header}>
      <H4>
        <Trans
          i18nKey={TRANSLATION_KEYS.header}
          components={[<br key="br" />]}
          values={{
            userName,
          }}
        />
      </H4>
    </div>
  );
}

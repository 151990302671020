import React, { useState, useEffect } from 'react';

import { Button, Icon } from 'design-system-web';
import { Flex } from 'lane-web/src/components';

import { Modal } from 'components/lds';
import { getClient } from 'lane-shared/apollo';
import { useTranslation } from 'react-i18next';

import { MeterReadingValueType } from 'lane-shared/domains/workOrder/constants';
import { UseChannelForAdminQueryResponse } from 'hooks/useChannelForAdminQuery';
import { v4 as uuid } from 'uuid';
import styles from './MeterReadingModal.scss';

import {
  createMultipleMeterReadingValueMutation,
  updateMeterReadingValueMutation,
} from 'graphql-queries';
import MeterReadingInput from './MeterReadingInput';
import { useMutation } from '@apollo/client';

export interface MeterReadingFormProps {
  meterReadingSettings: any[];
  source_type: string;
  channel?: UseChannelForAdminQueryResponse['channel'];
  meterReadingValueModalOpen: boolean;
  setMeterReadingValueModalOpen: (isModalOpen: boolean) => void;
  setEditMeterReadingValue?: (meterReadingValue: any) => void;
  refetch?: () => void;
  startingMeterReadingValues?: any;
  editMode?: boolean;
}

function MeterReadingModal({
  meterReadingSettings,
  source_type,
  channel,
  meterReadingValueModalOpen,
  setMeterReadingValueModalOpen,
  setEditMeterReadingValue,
  refetch,
  startingMeterReadingValues,
  editMode = false,
}: MeterReadingFormProps) {
  const { t } = useTranslation();

  const [meterReadingValues, setMeterReadingValues] = useState<any[]>(
    startingMeterReadingValues || [
      { id: uuid(), source_type: MeterReadingValueType.ADHOC },
    ]
  );
  const [validated, setValidated] = useState<boolean>(false);

  const handleAddMeterReadingValue = () => {
    const newMeterReadingValues = [...meterReadingValues];
    newMeterReadingValues.push({
      id: uuid(),
      source_type: MeterReadingValueType.ADHOC,
    });
    setMeterReadingValues(newMeterReadingValues);
  };

  const removeMeterReadingValue = (index: number) => {
    setMeterReadingValues(meterReadingValues.filter((_, i) => i !== index));
  };

  const updateMeterReadingValue = (index: number, MeterReadingValue: any) => {
    const newMeterReadingValues = [...meterReadingValues];
    newMeterReadingValues[index] = MeterReadingValue;
    setMeterReadingValues(newMeterReadingValues);
  };

  useEffect(() => {
    if (startingMeterReadingValues) {
      setMeterReadingValues(startingMeterReadingValues);
    }
  }, [startingMeterReadingValues]);
  const validate = () => {
    setValidated(true);

    if (meterReadingValues.length > 0) {
      for (const meterReadingValue of meterReadingValues) {
        if (
          meterReadingValue.meterReadingId &&
          meterReadingValue.value !== null &&
          meterReadingValue.value !== undefined
        ) {
          if (
            !meterReadingSettings.some(
              item => item?.id === meterReadingValue.meterReadingId
            ) ||
            typeof meterReadingValue.value !== 'number' ||
            Number.isNaN(meterReadingValue.value)
          ) {
            return false;
          }
        } else {
          return false;
        }
      }
    }
    return true;
  };

  const [createMeterReadings] = useMutation(
    createMultipleMeterReadingValueMutation
  );

  const onSave = async () => {
    if (!validate()) {
      return;
    }
    try {
      if (editMode) {
        await getClient().mutate({
          mutation: updateMeterReadingValueMutation,
          variables: {
            updateMeterReadingValue: {
              id: meterReadingValues[0].id,
              value: meterReadingValues[0].value,
              ...(meterReadingValues[0].notes
                ? { notes: meterReadingValues[0].notes }
                : {}),
              sourceType: MeterReadingValueType.ADHOC,
            },
            channelId: channel?._id || '',
          },
        });

        window.Toast.show(
          t(
            'web.admin.serviceRequest.equipment.MeterReadingInput.update.successToast'
          )
        );
      } else {
        const meterReadingValuesToSave = meterReadingValues.map(element => {
          return {
            meterReadingId: element.meterReadingId,
            sourceType: element.source_type,
            value: element.value,
            notes: element.notes,
          };
        });

        await createMeterReadings({
          variables: {
            createMultipleMeterReadingValue: meterReadingValuesToSave,
            channelId: channel?._id || '',
          },
        });

        window.Toast.show(
          t(
            'web.admin.serviceRequest.equipment.MeterReadingInput.add.successToast'
          )
        );
      }
      setValidated(false);
      if (setEditMeterReadingValue) {
        setEditMeterReadingValue(undefined);
      }
      if (refetch) {
        refetch();
      }
      setMeterReadingValueModalOpen(false);
    } catch (err) {
      window.Toast.show(
        t('web.admin.serviceRequest.equipment.MeterReadingInput.submit.error')
      );
    }
  };

  return (
    <Modal
      isOpen={meterReadingValueModalOpen}
      onClose={() => {
        setValidated(false);
        setMeterReadingValueModalOpen(false);
        if (setEditMeterReadingValue) {
          setEditMeterReadingValue(undefined);
        }
      }}
      className={styles.modalContainer}
      disableContentPadding
      title={t(
        editMode
          ? 'web.admin.serviceRequest.equipment.meterReadingValues.edit.title'
          : 'web.admin.serviceRequest.equipment.meterReadingValues.add.title'
      )}
      actions={
        <Flex className={styles.modalFooter}>
          <Button
            variant="primary"
            onClick={onSave}
            testId="meterReadingModalConfirmation"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.submit')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setValidated(false);
              setMeterReadingValueModalOpen(false);
              if (setEditMeterReadingValue) {
                setEditMeterReadingValue(undefined);
              }
            }}
            testId="meterReadingModalCancel"
          >
            {t('web.admin.serviceRequest.equipment.MeterReadingInput.cancel')}
          </Button>
        </Flex>
      }
    >
      <Flex gap={5} direction="column">
        <Flex direction="column" gap={4}>
          {meterReadingValues.map((meterReadingValue, index) => (
            <MeterReadingInput
              key={meterReadingValue?.id || index}
              meterReadingValue={meterReadingValue}
              index={index}
              onDelete={removeMeterReadingValue}
              onUpdate={updateMeterReadingValue}
              meterReadingSettings={meterReadingSettings}
              addlineBreak={index < meterReadingValues.length - 1}
              enableDelete={!editMode && meterReadingValues.length > 1}
              source_type={source_type}
              channel={channel}
              validated={validated}
              editMode={editMode}
            />
          ))}
          {!editMode && (
            <Flex className={styles.addAnotherButton}>
              <Button
                variant="text"
                startIcon={<Icon name="plus" />}
                onClick={handleAddMeterReadingValue}
                fullWidth={false}
                testId="add-option"
              >
                {t('web.admin.serviceRequest.equipment.MeterReadingInput.add')}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
}

export default MeterReadingModal;

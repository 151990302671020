import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { useAuth0WebRedirectURL } from '../../../hooks/useAuth0WebRedirectURL';
import logout from '../../../helpers/logout';
import { useAuth0Context } from 'lane-shared/contexts/Auth0Context';

export function useAuthClearSession() {
  const { logout: auth0Logout, isAuthenticated } = useAuth0();

  const redirectURL = useAuth0WebRedirectURL();
  const { setIsAuth0LogoutInProgress } = useAuth0Context();

  return useCallback(async () => {
    if (isAuthenticated) {
      setIsAuth0LogoutInProgress(true);
      await auth0Logout({
        openUrl: false,
      });
      await logout({ emitLogout: true, emitAuthTokenInvalid: true });
      auth0Logout({
        logoutParams: { returnTo: redirectURL },
      });
    }
  }, [isAuthenticated, auth0Logout, redirectURL]);
}

import React from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { WebBlockProps } from '../WebBlockProps';
import styleMap from '../styleMap';
import useEditModeProps from './useEditModeProps';

import styles from './VideoBlock.scss';

type BlockProps = WebBlockProps & {
  posterUrl: string;
  videoUrl: string;
};

export default function VideoBlock({
  posterUrl,
  videoUrl,
  style,
  className,
  ...otherProps
}: BlockProps) {
  const { t } = useTranslation();
  const props = useEditModeProps(otherProps);

  return (
    <div
      {...props}
      id={videoUrl}
      className={cx(styles.VideoBlock, className)}
      style={styleMap(false, style)}
    >
      <video
        width="100%"
        height="100%"
        controls
        src={videoUrl}
        poster={posterUrl}
      >
        <track kind="captions" />
        <p>
          {t(
            'web.admin.channel.content.layout.editor.components.videoBlock.helpText'
          )}
        </p>
      </video>
      <div className={styles.wrapper} />
    </div>
  );
}

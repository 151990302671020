import React, { useEffect, useMemo, useState, PropsWithChildren } from 'react';

import LocationContext from '../contexts/LocationContext';
import emitter, {
  EVENT_LOCATION,
  EVENT_LOCATION_ERROR,
  EVENT_LOCATION_STOPPED,
} from '../helpers/emitter';
import { LocationHelperInterface, LocationType } from '../types/LocationType';

type Props = PropsWithChildren<{
  locationHelper: LocationHelperInterface;
}>;
/**
 * Listens for location updates
 */
export default function LocationProvider({ children, locationHelper }: Props) {
  const [location, setLocation] = useState<LocationType>({
    latitude: 0,
    longitude: 0,
    noLocation: true,
    availability: null,
    accuracy: null,
    error: null,
  });

  useEffect(() => {
    function onLocation(location: any) {
      setLocation(location);
    }

    function onError(error: any) {
      setLocation({
        ...location,
        noLocation: true,
        error,
      });
    }

    function onStopped() {
      setLocation({
        ...location,
        noLocation: true,
      });
    }

    emitter.addListener(EVENT_LOCATION, onLocation);
    emitter.addListener(EVENT_LOCATION_ERROR, onError);
    emitter.addListener(EVENT_LOCATION_STOPPED, onStopped);

    return () => {
      emitter.removeListener(EVENT_LOCATION, onLocation);
      emitter.removeListener(EVENT_LOCATION_ERROR, onError);
      emitter.removeListener(EVENT_LOCATION_STOPPED, onStopped);
    };
  }, []);

  const contextValue = useMemo(() => ({ location, locationHelper }), [
    location,
  ]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
}

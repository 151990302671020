import React from 'react';
import { getTaskAuditLogsQuery } from 'graphql-queries';
import { convertToUUID } from 'lane-shared/helpers/convertId';
import { TaskAuditLog } from 'graphql-query-contracts';
import { useQuery } from '@apollo/client';
import { ActivityLog } from 'lane-web/src/domains/workOrder/activityLog/ActivityLog';
import { Loading } from 'components';
import styles from './Activity.scss';

type Props = {
  taskId: string;
  testId?: string;
};

export function ActivityTab({ taskId }: Props) {
  const { data, loading } = useQuery(getTaskAuditLogsQuery, {
    variables: {
      taskId: convertToUUID(taskId),
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loading fullscreen />;

  if (!data) return null;

  const auditLogs: TaskAuditLog[] = data?.taskAuditLogs;

  return (
    <div className={styles.activityTab}>
      {auditLogs.map(auditLog => (
        <ActivityLog key={auditLog.date} auditLog={auditLog} />
      ))}
    </div>
  );
}

type TestableIntegrationDetails = {
  provider: string;
  configFieldsToReplace?: Record<string, string>;
};

export const TESTABLE_INTEGRATIONS_CONFIG: {
  [key: string]: TestableIntegrationDetails;
} = {
  Yardi: {
    provider: 'YARDI_7_S',
    configFieldsToReplace: {
      database: 'databaseName',
    },
  },
  YardiCommercial: {
    provider: 'YARDI_7_S_COMMERCIAL',
    configFieldsToReplace: {
      database: 'databaseName',
    },
  },
  RealPage: {
    provider: 'REALPAGE_1_0',
    configFieldsToReplace: {
      url: 'serviceUrl',
    },
  },
  Entrata: {
    provider: 'ENTRATA_1_0',
  },
};

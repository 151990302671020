import React, { useContext } from 'react';

import { ThemeContext } from 'lane-shared/contexts';

import { WhitelabeledLogo } from 'components/general';

import styles from './WhitelabelInviteWrapper.scss';

type Props = {
  children: React.ReactNode;
};

function WhitelabelInviteWrapper({ children }: Props) {
  const theme = useContext(ThemeContext);

  return (
    <div className={styles.AcceptInvite}>
      <div className={styles.logoRow}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ style: { stroke: string; }; size: number; ... Remove this comment to see the full error message */}
        <WhitelabeledLogo style={{ stroke: theme.primary }} size={65} />
      </div>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
}

export default WhitelabelInviteWrapper;

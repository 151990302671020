import React, { useContext } from 'react';

import { Icon, Table } from 'design-system-web';
import classNames from 'classnames';
import { Loading } from 'components';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';

import { UserDataContext } from 'lane-shared/contexts';
import { getUserBuildingAccess } from 'lane-shared/graphql/accessControl';
import {
  PERMISSION_ACCESS_CONTROL_CCURE,
  PERMISSION_ACCESS_CONTROL_HID,
} from 'lane-shared/helpers/constants/permissions';
import hasPermission from 'lane-shared/helpers/hasPermission';
import { AccessControlServiceEntity } from 'lane-shared/helpers/integrations/AccessManagement/accessControl';
import { HID2 } from 'lane-shared/helpers/integrations/AccessManagement/identityProvider';
import { UserType } from 'lane-shared/types/User';
import type { PillColor } from 'lane-shared/types/general/pill';

import Pill from 'components/general/Pill';
import { H4, H5 } from 'components/typography';

import { CredentialActionCell } from './CredentialActionCell';
import {
  Credential,
  CredentialStatus,
  CredentialType,
  UserMappingStatus,
} from 'graphql-query-contracts';
import sortBy from 'lodash/sortBy';

import styles from './BuildingAccess.scss';

function BuildingAccess({ user }: { user: UserType }) {
  const { t } = useTranslation();
  const { user: loggedInUser } = useContext(UserDataContext);

  const cCureAccessControlPermission = hasPermission(
    loggedInUser?.roles,
    [PERMISSION_ACCESS_CONTROL_CCURE],
    undefined,
    false
  );
  const hidAccessControlPermission = hasPermission(
    loggedInUser?.roles,
    [PERMISSION_ACCESS_CONTROL_HID],
    undefined,
    false
  );

  const {
    data,
    loading: isLoading,
    error,
    refetch: refetchUserBuildingAccess,
  } = useQuery(getUserBuildingAccess, {
    variables: { userId: user._id },
  });

  const channelStatusColor = (status: UserMappingStatus) => {
    let pillColor: PillColor;

    switch (status) {
      case 'UNRECOGNIZED':
      case 'USER_SYNC_FAILED':
        pillColor = 'red';
        break;
      case 'USER_PROCESSING':
      case 'USER_UNMAPPED':
        pillColor = 'honey';
        break;
      case 'USER_MAPPED':
      default:
        pillColor = 'green';
    }

    return pillColor;
  };

  const credentialStatusColor = (status: CredentialStatus) => {
    let pillColor: PillColor;

    switch (status) {
      case 'RESUMING':
      case 'USER_REVOKING':
      case 'REVOKING':
      case 'USER_RESUMING':
      case 'SUSPENDING':
      case 'USER_SUSPENDING':
      case 'USER_SUSPENDED':
      case 'UNKNOWN':
      case 'PROCESSING':
      case 'ISSUING':
      case 'UNRECOGNIZED':
        pillColor = 'honey';
        break;
      case 'REVOKED':
      case 'FAILED':
        pillColor = 'red';
        break;
      case 'SUSPENDED':
        pillColor = 'blue';
        break;
      case 'ACTIVE':
      default:
        pillColor = 'green';
    }

    return pillColor;
  };

  const getColumns = (channelId: string) => {
    return [
      {
        header: t('Credential'),
        key: 'cardNumber',
      },
      {
        header: t('Status'),
        key: 'status',
        renderCell: (status: CredentialStatus) => (
          <Pill
            text={t(`acs_credential_status_${status}`)}
            color={credentialStatusColor(status)}
            size="medium"
          />
        ),
      },
      {
        header: t('System'),
        key: 'provider',
      },
      {
        header: t('Date activated'),
        key: 'activatedAt',
        type: 'date',
      },
      {
        header: t('Date revoked'),
        key: 'revokedAt',
        type: 'date',
      },
      {
        header: t('Type'),
        key: 'credentialType',
        renderCell: (credentialType: CredentialType) =>
          t(`acs_credential_types_${credentialType}`),
      },
      {
        header: t('Action'),
        key: 'mapId',
        renderCell: (_mapId: string, credential: Credential) => {
          return (
            <CredentialActionCell
              credential={credential}
              channelId={channelId}
              refetch={refetchUserBuildingAccess}
            />
          );
        },
      },
    ];
  };

  if (!cCureAccessControlPermission && !hidAccessControlPermission) {
    return (
      <div className={styles.emptyContainer}>
        <H4>
          {t(
            'web.admin.channel.member.buildingAccess.permission.error.message'
          )}
        </H4>
      </div>
    );
  }

  if (!isLoading && !!error) {
    return (
      <div className={styles.emptyContainer}>
        <H4>{t('Error retrieving building access credentials')}</H4>
      </div>
    );
  }

  if (isLoading || !data?.userBuildingAccess) {
    return <Loading />;
  }

  const unsortedBuildingAccessChannels = data?.userBuildingAccess.filter(
    channel => channel?.mappings?.length && channel?.mappings?.length > 0
  );
  const buildingAccessChannels = sortBy(
    unsortedBuildingAccessChannels,
    'channelName'
  );
  const channelsWithNoBuildingAccess = data?.userBuildingAccess.filter(
    channelMapping =>
      !channelMapping?.mappings || channelMapping?.mappings?.length === 0
  );

  const shouldSeeCcureCredentials = cCureAccessControlPermission;
  const shouldSeeHidCredentials = hidAccessControlPermission;

  const hiddenCredentialProviders: string[] = [];
  if (!shouldSeeCcureCredentials)
    hiddenCredentialProviders.push(AccessControlServiceEntity.CCure);
  if (!shouldSeeHidCredentials) hiddenCredentialProviders.push(HID2);

  const filterCredentialsData = (credentials?: Credential[] | null) => {
    return credentials
      ?.filter((credential: Credential) => {
        const doesCredentialExistInProviders = !hiddenCredentialProviders.includes(
          credential.provider
        );
        return doesCredentialExistInProviders;
      })
      .sort((a: Credential, b: Credential) => {
        if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
          return -1;
        }
        if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
          return 1;
        }
        return 0;
      });
  };

  return (
    <>
      {buildingAccessChannels.length > 0 ? (
        buildingAccessChannels.map(channelWithMappings => {
          return (
            <div
              className={styles.container}
              key={channelWithMappings?.channelId}
              data-test="building-access-channel-container"
            >
              <div className={styles.tableHeader}>
                <H4>{channelWithMappings?.channelName}</H4>
                {channelWithMappings?.mappings?.map(
                  channelMapping =>
                    !!channelMapping && (
                      <React.Fragment key={channelMapping.mappingId}>
                        <span
                          className={classNames(styles.ml2, styles.divider)}
                        >
                          |
                        </span>
                        <span className={styles.ml2}>
                          {channelMapping.email}
                        </span>
                        <Pill
                          text={t(
                            `acs_channel_status_${channelMapping.status}`
                          )}
                          className={styles.ml2}
                          color={channelStatusColor(channelMapping.status)}
                          size="medium"
                        />
                      </React.Fragment>
                    )
                )}
              </div>
              <div>
                <Table
                  columns={getColumns(channelWithMappings?.channelId)}
                  data={
                    filterCredentialsData(channelWithMappings?.credentials) ??
                    []
                  }
                />
                {channelWithMappings?.credentials?.length === 0 && (
                  <p className={styles.mt2}>
                    {t('No credentials for this channel.')}
                  </p>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.emptyContainer}>
          <Icon name="IdCardAlt" withGradient size="large" />
          <H5 className={styles.mb2}>
            {t('No building access credentials available')}
          </H5>
          <p className={styles.emptyContainerText}>
            {t(
              'There are currently no building credentials tied to this member.'
            )}
          </p>
        </div>
      )}
      {channelsWithNoBuildingAccess.length > 0 && (
        <div className={styles.mt6}>
          <H5>{t('Buildings with no access mappings')}</H5>
          <ul>
            {channelsWithNoBuildingAccess.map(channel => (
              <li key={channel?.channelId} className={styles.mt2}>
                {channel?.channelName}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default BuildingAccess;
